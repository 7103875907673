import { Completion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { Tick } from '@sparx/sparx-design/icons';
import { PackageLockReason } from 'components/accordion/types';
import { ReactComponent as LockIcon } from 'images/LockSolid.svg';
import { useShouldShowBookworkAccuracy } from 'utils/bookworkAccuracy';

import { BookworkAccuracy } from '../bookwork-accuracy/BookworkAccuracy';
import styles from './PackageStatus.module.css';

export const PackageStatus = ({
  completionPercentage,
  isPackageLocked,
  lockReason,
  completion,
  startDate,
}: {
  completionPercentage: number;
  isPackageLocked: boolean;
  lockReason?: number;
  completion?: Completion;
  startDate?: Timestamp;
}) => {
  const showBookworkAccuracy = useShouldShowBookworkAccuracy(startDate);

  if (isPackageLocked) {
    const lockedText = lockReason === PackageLockReason.Deleted ? 'Cancelled' : 'Locked';

    return (
      <div className={styles.PackageStatusLocked}>
        <span>{lockedText}</span>
        <LockIcon />
      </div>
    );
  }

  if (completionPercentage <= 0) {
    return <span className={styles.NotStarted}>Not started</span>;
  }

  const complete = completionPercentage === 100;
  return (
    <div className={styles.PackageStatus}>
      <span className={styles.CompletionStatus}>
        {complete ? (
          <span>
            <span className={styles.CompletedText}>Completed</span>
            <Tick variant="DarkGreen" className={styles.PackageChipIcon} />
          </span>
        ) : (
          <span className={styles.Started}>{`${completionPercentage}%`}</span>
        )}
      </span>
      {showBookworkAccuracy && <div className={styles.Divider}></div>}
      {showBookworkAccuracy && <BookworkAccuracy completion={completion} />}
    </div>
  );
};

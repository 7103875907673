import { Button, ProgressBar } from '@sparx/sparx-design/components';
import { Page } from 'components/page/Page';
import { useGetStudentRewards } from 'queries/studentrewards';
import { TutorialKey, useIsTutorialComplete, useMarkTutorialComplete } from 'queries/tutorials';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFeatureFlags } from 'utils/feature-flags';

import { MilestoneProgressBar } from './milestone-progress-bar/MilestoneProgressBar';
import { OldRewardsPlaque, RewardsPlaque } from './rewards-plaque/RewardsPlaque';
import { useGetLevelProgressPercentage } from './rewardsUtils';
import styles from './RewardsView.module.css';

export const RewardsView = () => {
  const { data } = useGetStudentRewards({ suspense: true });
  const ff = useFeatureFlags();
  const useNewXp = ff.getBooleanFlag('new-xp-system', false);
  const leaderboardsFF = ff.getBooleanFlag('sparxweb2-leaderboards', false);
  const hasHidden = useIsTutorialComplete(TutorialKey.XPHasChanged);
  const { mutate: markComplete } = useMarkTutorialComplete();

  // if the student has the new ff, 0 xp, and doesn't have the `has clicked hide` label, set the label
  useEffect(() => {
    if (data?.xpPoints === 0 && !hasHidden && useNewXp) {
      markComplete([TutorialKey.XPHasChanged]);
    }
  }, [data?.xpPoints, hasHidden, markComplete, useNewXp]);

  const currentLevel = data?.currentXPLevel?.level || 0;
  const oldLevel = data?.oldLevel || 0;

  const isMaxLevel = !useNewXp && currentLevel === 25;
  const nextLevelXpThreshold = data?.nextXPLevel?.xpRequired || 0;

  const levelPercent = useGetLevelProgressPercentage();

  // TODO - FF: new-xp-system
  return useNewXp ? (
    <Page>
      <div className={styles.PageContainer}>
        <div className={styles.Center}>
          <h1 className={styles.PageTitle}>{'Sparx Maths Rewards'}</h1>
          <div className={styles.RewardsPlaqueContainer}>
            <RewardsPlaque level={currentLevel} />
            <div className={styles.ProgressBarAndLeaderboardsButton}>
              <div className={styles.ProgressBarContainer}>
                <div className={styles.ProgressBarTextContainer}>
                  <span className={styles.CurrentXP}>
                    {data?.xpPoints.toLocaleString()} <span>XP</span>
                  </span>
                  <span className={styles.XpToNext}>
                    {(nextLevelXpThreshold - (data?.xpPoints || 0)).toLocaleString()} XP to next
                    level
                  </span>
                </div>
                <ProgressBar
                  percentComplete={levelPercent}
                  containerClassName={styles.CompletionBarContainer}
                  animateFill
                  animateWobble
                  showHighlight
                />
              </div>
              {leaderboardsFF && (
                <Button
                  size="md"
                  as={Link}
                  to={'/leaderboard'}
                  className={styles.LeaderboardButton}
                >
                  View Leaderboards
                </Button>
              )}
            </div>
          </div>

          <MilestoneProgressBar />
          <div className={styles.InfoPanel}>
            <h3 className={styles.Title}>What is XP?</h3>
            <p>XP is how you are rewarded for your homework.</p>
            <p>
              You can earn XP by completing tasks in your Compulsory, XP Boost, and Target homework,
              and you can reach new levels as you earn more XP.
            </p>
            <p>You reach a new Sparx Level every {useNewXp ? '1,000' : '10,000'} XP.</p>
          </div>
        </div>
      </div>
    </Page>
  ) : (
    <Page>
      <div className={styles.PageContainerOld}>
        <div className={styles.Center}>
          <div className={styles.RewardsPlaqueContainer}>
            <OldRewardsPlaque level={oldLevel} />
          </div>
          <div className={styles.ProgressBarContainer}>
            <ProgressBar
              percentComplete={levelPercent}
              containerClassName={styles.CompletionBarContainer}
              animateFill
              animateWobble
              showHighlight
            />
            <div className={styles.ProgressBarTextContainer}>
              <span className={styles.CurrentXP}>
                {data?.xpPoints.toLocaleString()} <span>XP</span>
              </span>
              {!isMaxLevel && nextLevelXpThreshold > 0 && (
                <span className={styles.XpToNext}>
                  {(nextLevelXpThreshold - (data?.xpPoints || 0)).toLocaleString()} XP to next level
                </span>
              )}
            </div>
          </div>
          <div className={styles.InfoPanel}>
            <h3 className={styles.Title}>What is XP?</h3>
            <p>XP is how you are rewarded for your homework.</p>
            <p>
              You can earn XP by completing tasks in your Compulsory, XP Boost, and Target homework,
              and you can reach new levels as you earn more XP.
            </p>
            <p>You reach a new Sparx Level every {useNewXp ? '1,000' : '10,000'} XP.</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { swclient2EventCategory, useAnalytics } from 'utils/analytics';

import styles from './Video.module.css';

type VideoProps = {
  analyticsCategory?: swclient2EventCategory;
  analyticsVideoName?: string;
  disableAnalytics?: boolean;
  disableForwardSeeking?: boolean;
  posterUrl?: string;
  subtitleUrl?: string;
  videoUrl?: string;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  showPlayButton?: boolean;
  /** videoLabel adds an aria label to the video. If undefined the label will be `video` */
  videoLabel?: string;
};

export const Video = ({
  analyticsCategory,
  analyticsVideoName,
  disableAnalytics,
  disableForwardSeeking,
  onPause,
  onPlay,
  onEnded,
  posterUrl,
  subtitleUrl,
  videoUrl,
  showPlayButton,
  videoLabel,
}: VideoProps) => {
  const sendEvent = useAnalytics();
  const [videoRefState, setVideoRefState] = useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!disableAnalytics && analyticsCategory) {
      sendEvent({
        action: `video open${analyticsVideoName ? ': ' + analyticsVideoName : ''}`,
        category: analyticsCategory,
      });
    }
  }, [disableAnalytics, sendEvent, analyticsVideoName, analyticsCategory]);

  const handlePlay = () => {
    !disableAnalytics &&
      analyticsCategory &&
      sendEvent({
        action: `video play${analyticsVideoName ? ': ' + analyticsVideoName : ''}`,
        category: analyticsCategory,
      });
    onPlay?.();
  };

  const handlePause = () => {
    !disableAnalytics &&
      analyticsCategory &&
      sendEvent({
        action: `video pause${analyticsVideoName ? ': ' + analyticsVideoName : ''}`,
        category: analyticsCategory,
      });
    onPause?.();
  };

  // send an analytic when the video is unmounted so we know how long its been watched for
  useEffect(() => {
    return () => {
      if (videoRefState && !disableAnalytics && analyticsCategory) {
        sendEvent({
          action: `video closed${analyticsVideoName ? ': ' + analyticsVideoName : ''}`,
          category: analyticsCategory,
          labels: {
            currentTime: videoRefState?.currentTime,
          },
        });
      }
    };
  }, [analyticsCategory, analyticsVideoName, sendEvent, videoRefState, disableAnalytics]);

  const handleEnded = () => {
    onEnded?.();
  };

  // shouldShowPlay play stores whether or not we should show the custom play button
  // it is set to true on load, or if the video is paused or ends
  // it is set to false when the video is played, or errors
  const [shouldShowPlay, setShouldShowPlay] = useState(true);

  useEffect(() => {
    const video = videoRefState;
    if (!video) {
      return;
    }

    let currentTime = 0;

    const aborter = new AbortController();

    video.addEventListener(
      'timeupdate',
      () => {
        if (!video.seeking && disableForwardSeeking) {
          currentTime = video.currentTime;
        }
      },
      { signal: aborter.signal },
    );

    video.addEventListener(
      'seeking',
      () => {
        if (disableForwardSeeking) {
          const delta = video.currentTime - currentTime;
          // If delta is negative then we're seeking backwards which is allowed.
          // Otherwise, set the time back to the current time to stop seeking forward.
          if (delta > 0.01) {
            video.currentTime = currentTime;
          }
        }
      },
      { signal: aborter.signal },
    );

    ['play', 'error'].forEach(action =>
      video.addEventListener(
        action,
        () => {
          setShouldShowPlay(false);
        },
        { signal: aborter.signal },
      ),
    );

    ['pause', 'ended'].forEach(action =>
      video.addEventListener(
        action,
        () => {
          setShouldShowPlay(true);
        },
        { signal: aborter.signal },
      ),
    );

    return () => aborter.abort();
  }, [disableForwardSeeking, videoRefState]);

  const onPlayButtonClick = () => {
    videoRefState?.play();
  };

  return (
    <>
      <AnimatePresence>
        {showPlayButton && shouldShowPlay && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
            className={styles.PlayButton}
            onClick={onPlayButtonClick}
          >
            <div />
          </motion.div>
        )}
      </AnimatePresence>
      <video
        aria-label={videoLabel === undefined ? 'video' : videoLabel}
        role="application"
        ref={r => setVideoRefState(r)}
        src={videoUrl}
        crossOrigin="anonymous"
        controls={true}
        controlsList={`nodownload ${disableForwardSeeking && 'noplaybackrate'}`}
        poster={posterUrl}
        className={styles.Video}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
      >
        {subtitleUrl && (
          <track label="English" kind="subtitles" srcLang="en" src={subtitleUrl} default={true} />
        )}
      </video>
    </>
  );
};

import { UserDisplayData } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay';
import { Product } from '@sparx/api/apis/sparx/types/product';

/**
 * isUserOptedOut returns whether the user represented by the display data opted out the given
 * product's leaderboards. If the user display data is undefined, false is returned.
 */
export const isUserOptedOut = (userDisplayData: UserDisplayData | undefined, product: Product) => {
  return !!userDisplayData?.optedOutProducts.includes(product);
};

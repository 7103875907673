import { ProgressWheel } from '@sparx/sparx-design/components';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { NavigationFrame } from 'app/NavigationFrame';
import { useNavLinks } from 'app/NavigationFrame/use-nav-links';
import { AccordionRoot } from 'components/accordion/Accordion';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { MaxWidth, Page } from 'components/page/Page';
import { useOnboardingLockState } from 'context/onboardinglock';
import { useActiveHomeworkPackages } from 'queries/packages';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import {
  getPackageTypeDescription,
  getPkgOrTaskCompletionPercentage,
  isPkgOrTaskComplete,
} from 'utils/package';
import { getEquivalentHomeworkPackageType } from 'utils/training';
import { PackageListItem } from 'views/package-list/package-list-item';

import { useFilteredSortedPackages } from './hooks';
import styles from './PackageListView.module.css';
import { PrimaryPackageListView } from './PrimaryPackageListView';

export const PackageListView = () => {
  const { onboardingLockEnabled } = useOnboardingLockState();

  const sendEvent = useAnalytics();
  const { data: session, isLoading: sessionIsLoading, error: sessionError } = useSession();
  const {
    data: packages,
    isLoading: packagesAreLoading,
    error: packagesError,
  } = useActiveHomeworkPackages();
  const loading = sessionIsLoading || packagesAreLoading;
  const [searchParams, setSearchParams] = useSearchParams();

  // Send a page event if there was an error loading the packages or session
  useEffect(() => {
    if (packagesError) {
      sendEvent({
        action: 'error loading packages',
        category: 'package list',
        labels: {
          error: packagesError.toString(),
        },
      });
      throw packagesError;
    }
  }, [packagesError, sendEvent]);
  useEffect(() => {
    if (sessionError) {
      sendEvent({
        action: 'error loading session',
        category: 'package list',
        labels: {
          error: sessionError.toString(),
        },
      });
      throw sessionError;
    }
  }, [sessionError, sendEvent]);

  const [selectedPackageType, setSelectedPackageTypeState] = useState(
    searchParams.get('open_package_type') || 'homework',
  );

  const setSelectedPackageType = useCallback(
    (pkgType: string) => {
      setSearchParams(prev => ({
        ...prev,
        open_package_type: pkgType,
      }));
      setSelectedPackageTypeState(pkgType);
    },
    [setSearchParams],
  );

  const [openPackageIDs, setOpenPackageIDs] = useState<string[]>([]);
  const [initialPackageOpened, setInitialPackageOpened] = useState(false);

  const isSmallScreen = useBreakpoint('sm');

  // Open a package type if the open_package_type query parameter is set.
  useEffect(() => {
    const openPackageType = searchParams.get('open_package_type');
    if (openPackageType) {
      setSelectedPackageTypeState(
        getEquivalentHomeworkPackageType(openPackageType) || openPackageType,
      );
    }
  }, [searchParams]);

  // Open a package if the open_package_id query parameter is set.
  useEffect(() => {
    if (packages && !initialPackageOpened) {
      const params = new URLSearchParams(document.location.search);
      const openPackageID = params.get('open_package_id');
      const openPackageType =
        packages.find(pkg => pkg.packageID === openPackageID)?.packageType || 'homework';
      if (openPackageID) {
        setOpenPackageIDs([openPackageID]);
        setSelectedPackageType(openPackageType === 'onboarding' ? 'homework' : openPackageType);
        setInitialPackageOpened(true);
      }
    }
  }, [initialPackageOpened, packages, setSelectedPackageType]);

  const packagesShowing = useFilteredSortedPackages(packages, selectedPackageType);
  // don't include incomplete optional only homeworks in the progress wheel
  const packagesTotal = packagesShowing.filter(p => p.deletedTime == undefined).length;
  const packagesComplete = packagesShowing.filter(
    pkg => isPkgOrTaskComplete(pkg) && pkg.deletedTime == undefined,
  ).length;

  useEffect(() => {
    if (!onboardingLockEnabled) {
      return;
    }

    const onboardingPackage = packagesShowing.find(pkg => pkg.packageType === 'onboarding');
    if (!onboardingPackage) {
      return;
    }

    const shouldOpen =
      openPackageIDs.length !== 1 || openPackageIDs[0] !== onboardingPackage.packageID;

    if (shouldOpen) {
      setOpenPackageIDs([onboardingPackage.packageID]);
    }
  }, [openPackageIDs, onboardingLockEnabled, packagesShowing]);

  const packageTypeDescription = getPackageTypeDescription(selectedPackageType);

  const accordionEvent = (selected: string[]) => {
    const opened = selected.length > openPackageIDs.length;
    const pkgId = (opened ? selected : openPackageIDs).find(
      p => !(opened ? openPackageIDs : selected).includes(p),
    );
    const pkg = packagesShowing.find(p => p.packageID === pkgId);
    let pkgCompletionPercentage = 0;
    if (pkg) {
      pkgCompletionPercentage = getPkgOrTaskCompletionPercentage(pkg);
    }

    sendEvent({
      action: `${opened ? 'opened' : 'closed'} package dropdown`,
      category: 'package list',
      labels: {
        'package completion percent': pkgCompletionPercentage,
      },
    });
  };

  // If onboarding lock is on, redirect to Compulsory homework from other package
  // types
  useEffect(() => {
    if (onboardingLockEnabled) {
      setSelectedPackageType('homework');
    }
  }, [onboardingLockEnabled, setSelectedPackageType]);

  const pageContent = (
    <>
      <div className={styles.WelcomeBanner}>
        <div>
          <div className={styles.Hello}>
            {`Hey ${getUsersNamesFromSession(session, 'firstOnly')}`},
          </div>
          <div className={styles.PackageTypeDescription}>{packageTypeDescription}</div>
        </div>
        <div className={styles.ProgressWheelContainer}>
          <ProgressWheel
            completed={packagesComplete}
            total={packagesTotal}
            wheelSize={
              isSmallScreen
                ? { barWidth: 7, outerCircleRadius: 35 }
                : { barWidth: 10, outerCircleRadius: 45 }
            }
          />
        </div>
      </div>
      <AccordionRoot
        value={openPackageIDs}
        onValueChange={v => {
          setOpenPackageIDs(v);
          accordionEvent(v);
        }}
      >
        {packagesShowing.map((pkg, i) => (
          <PackageListItem
            key={pkg.packageID}
            pkg={pkg}
            i={i}
            opened={openPackageIDs.includes(pkg.packageID)}
          />
        ))}
      </AccordionRoot>
    </>
  );

  const navLinks = useNavLinks({
    selectedPackageType,
    setSelectedPackageType,
  });

  const bottomBarItems = navLinks.filter(l => l.text !== 'Independent Learning');

  if (loading) {
    return (
      <Page>
        <LoadingSpinnerWithAnalytics
          componentName="PackageListView"
          sendLongTimeLoadingEvent={true}
        />
      </Page>
    );
  }

  return (
    <Page>
      {session?.student?.isPrimaryStudent ? (
        <PrimaryPackageListView />
      ) : (
        <NavigationFrame bottomBarItems={bottomBarItems} sideBarItems={navLinks}>
          <MaxWidth>{pageContent}</MaxWidth>
        </NavigationFrame>
      )}
    </Page>
  );
};

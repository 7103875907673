import { StudentExperienceTrainingStep } from 'content/training/trainingModules';
import { getTrainingStepID, isCompletable, isVideo, Step } from 'types/training';
import { swclient2Event } from 'utils/analytics';

const EVENT_CATEGORY = 'sparxweb_training';

// The base event which gets fired
const baseEvent = (action: string, labels = {}): swclient2Event => ({
  category: EVENT_CATEGORY,
  action,
  labels,
});

/**
 * Returns if the step is complete. If not a completable step return "undefined"
 */
const stepCompleteStatus = (step: Step): string => {
  if (!isCompletable(step)) {
    return 'undefined';
  }
  return step.isComplete ? 'true' : 'false';
};

export const clickedBackToTeacherPortalEvent = (referrer: string) =>
  baseEvent('back_to_teacher_portal_clicked', { referrer });

export const clickOnContinueToTrainingEvent = () => baseEvent('click_continue_to_training_button');

export const clickedNeedHelpEvent = (step: Step) =>
  baseEvent('clicked_need_help', { step: step.title });

export const clickedViewAllStepsEvent = (step: Step) =>
  baseEvent('clicked_view_all_steps', {
    step: step.title,
    stepCompleteStatus: stepCompleteStatus(step),
  });

export const clickedOnTrainingStepEvent = (step: Step) =>
  baseEvent('selected_training_step', {
    step: step.title,
    stepCompleteStatus: stepCompleteStatus(step),
  });

export const clickedOnNextStepEvent = (step: Step, nextIncompleteStepIndex: number) =>
  baseEvent('selected_next_step', {
    step: step.title,
    nextIncompleteStepIndex,
  });

export const clickedOnSummaryEvent = (step: Step) =>
  baseEvent('clicked_on_summary', {
    step: step.title,
  });

export const clickedOnMenuButtonEvent = (menuOpen: boolean) =>
  baseEvent(menuOpen ? 'menu_opened' : 'menu_closed');

export const clickedViewIntroVideoEvent = () => baseEvent('clicked_view_intro_video');

const viewVideoEventAction = (step: Step) => {
  if (getTrainingStepID(step) === StudentExperienceTrainingStep.BOOKWORK_VIDEO_WATCHED) {
    return 'watching_bookwork_video';
  }
  if (isVideo(step)) {
    return 'watching_video';
  }
  return 'watching_need_help_video';
};

export const clickedViewVideoEvent = (step: Step) =>
  baseEvent(viewVideoEventAction(step), {
    step: step.title,
    stepCompleteStatus: stepCompleteStatus(step),
  });

export const clickedHomeScreenLinkViaIndependentLearningStepEvent = () =>
  baseEvent('clicked_home_screen_link_via_independent_learning_step');

export const clickedReturnToFirstIncompleteStepEvent = (step: Step) =>
  baseEvent('clicked_return_to_first_incomplete_step', {
    firstIncompleteStep: step.title,
  });

export const submitFeedbackEvent = (moduleName: string, isComplete: boolean, isLiked: boolean) =>
  baseEvent('submit_feedback', {
    trainingModule: moduleName,
    moduleState: isComplete ? 'complete' : 'not-complete',
    opinion: isLiked ? 'like' : 'dislike',
  });

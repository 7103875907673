import { FunctionComponent, useState } from 'react';

import { useFeatureFlagsWithoutTyping } from '../../../utils/feature-flags';
import { AlertModal } from '../../times-tables-task/alert-modal/alert-modal';
import {
  canAccessMultiplayerGameInFuture,
  convertMultiplayerMessagingText,
} from '../../times-tables-task/utils';

let globalHasShownMultiplayerAlert = false;

/*
    A modal that will show if the feature flag discovery-upcoming-multiplayer-session-notification is set.
    It will show a message defined in the feature flag, telling the student when the multiplayer game
    is active and how to access it.

    It will show once per session. It will not show again until the student refreshes the page.
 */
export const MultiplayerAlertModal: FunctionComponent = () => {
  const featureFlags = useFeatureFlagsWithoutTyping();

  // cache whether or not this modal has been shown since the last refresh
  const [hasShownMultiplayerAlert, setHasShownMultiplayerAlert] = useState<boolean>(
    globalHasShownMultiplayerAlert || false,
  );
  globalHasShownMultiplayerAlert = hasShownMultiplayerAlert;

  // For the multiplayer call-to-action alert to appear we need to populate it with text.
  // Format it by spacing lines with <br>
  // For example: "Try our new multiplayer game 6pm on Thursdays.<br>Prizes to be won!"
  // TODO: once this is deployed, the old boolean flag discovery-show-multiplayer-session-notification should be removed
  const multiplayerUpcomingSessionTextRaw: string = (
    featureFlags['discovery-upcoming-multiplayer-session-notification'] || ''
  ).toString();
  const multiplayerUpcomingSessionText: string = convertMultiplayerMessagingText(
    multiplayerUpcomingSessionTextRaw,
  );

  return (
    <AlertModal
      gameTitle={'New game: Multiplayer Challenge'}
      messages={multiplayerUpcomingSessionText.split('<br>')}
      targets={[]}
      shouldShow={
        !hasShownMultiplayerAlert &&
        multiplayerUpcomingSessionText.length > 0 &&
        canAccessMultiplayerGameInFuture(featureFlags)
      }
      onClick={() => setHasShownMultiplayerAlert(true)}
    />
  );
};

import { clickedOnMenuButtonEvent } from 'components/training/analytics';
import { useIsStaffUser } from 'queries/session';
import { useTrainingProgress } from 'queries/training';
import { RefObject, useContext, useEffect, useState } from 'react';
import { isCompletable } from 'types/training';
import { useAnalytics } from 'utils/analytics';
import { getKeyPressed } from 'utils/keyboard';

import { TrainingContext } from './provider';

/**
 * Use training provides data and methods from the TrainingContext for dealing with teacher training
 */
export const useTraining = () => {
  const ctx = useContext(TrainingContext);
  if (!ctx) {
    throw new Error('useTraining can only be used inside a TrainingProvider');
  }
  return ctx;
};

export const useGetTrainingModule = (trainingEnabled: boolean) => {
  const moduleData = useTrainingProgress(trainingEnabled);
  return moduleData;
};

/**
 * Hook which sets up opening and closing a menu. Takes the ref of the button that opens/closes the menu. The menu
 * is opened and closed when this button is clicked. Also it is closed when the user clicks on any other element
 * away from the menu, but not the contents of the menu itself. Also handles opening and closing the menu by tabbing
 * to it and then pressing Enter or Space keys.
 * @param menuButtonRef React ref to the menu button that opens and closes the menu
 * @param menuContentsRef React ref to the main contents of the menu
 * @param closeOnClickAway whether to close the menu when the use clicks on an element outside of the dropdown
 */
export const useMenu = (
  // Ref to the button that opens/closes the menu
  menuButtonRef: RefObject<HTMLElement>,
  // Ref for the contents of the menu
  menuContentsRef: RefObject<HTMLElement>,
  // Close the dropdown when clicking on an element outside of the menu
  closeOnClickAway: boolean,
) => {
  const sendEvent = useAnalytics();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClickOrTouch = (evt: MouseEvent) => {
    const sourceIsMenuButton = menuButtonRef.current?.contains(evt.target as Node);
    const sourceIsMenuContents = menuContentsRef.current?.contains(evt.target as Node);

    // Do not toggle menu if clicking on its contents. Open and close the menu with the menu button. Close the menu
    // if clicking away when it is open
    if (!sourceIsMenuContents) {
      if (sourceIsMenuButton) {
        setIsMenuOpen(!isMenuOpen);
        sendEvent(clickedOnMenuButtonEvent(isMenuOpen));
        // Don't focus button:
        evt.preventDefault();
      } else if (isMenuOpen && closeOnClickAway) {
        setIsMenuOpen(false);
        sendEvent(clickedOnMenuButtonEvent(false));
      }
    }
  };

  const handleKey = (evt: KeyboardEvent) => {
    const keyPressed = getKeyPressed(evt);
    const sourceIsMenuButton = menuButtonRef.current === evt.target;
    if (sourceIsMenuButton) {
      switch (keyPressed) {
        case 'Enter':
        case 'Space':
          setIsMenuOpen(!isMenuOpen);
          break;
        default:
          break;
      }
    }
  };

  // Handle clicking away from the dropdown box:
  useEffect(
    () => {
      document.addEventListener('mousedown', handleClickOrTouch);
      document.addEventListener('keydown', handleKey);
      return () => {
        document.removeEventListener('mousedown', handleClickOrTouch);
        document.removeEventListener('keydown', handleKey);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMenuOpen, setIsMenuOpen],
  );

  return { isMenuOpen, setIsMenuOpen };
};

/**
 * Returns true if the training step with the given ID is complete in the Student Experience training module
 * @param stepID
 */
export const useIsStepComplete = (stepID: string): boolean | undefined => {
  const isStaff = useIsStaffUser();
  const { enabled, currentModule } = useTraining();
  if (!enabled || !isStaff) {
    return;
  }

  return (
    !!currentModule &&
    currentModule.steps.some(
      s => isCompletable(s) && s.isComplete && s.id === `${currentModule.name}/${stepID}`,
    )
  );
};

import { MatchingResource, Query, Result } from '@sparx/api/apis/sparx/content/search/v1/search';
import {
  CurriculumSummary,
  ListCurriculumSummariesRequest,
  ListCurriculumSummariesResponse,
  ListTopicSummariesRequest,
  ListTopicSummariesResponse,
  TopicSummary,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { TopicSummariesClient } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum.client';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useAPI } from 'context/api';
import { useCallback, useMemo } from 'react';
import { swclient2EventCategory, useAnalytics } from 'utils/analytics';
import { getCurriculumSummaryWithDisplayNameAlias } from 'utils/content';
import { useFeatureFlags } from 'utils/feature-flags';

// Blank string is used to search all curricula
const ALL_CURRICULA_SEARCH_NAME = '';
const SELECTED_CURRICULA_RESULTS_LIMIT = 10;
const ALL_CURRICULA_RESULTS_LIMIT = 20;

/**
 * useCurriculumSummaries returns a list containing the curriculum summaries for every curriculum.
 * It returns a list and the loading/error states are handled by suspense.
 */
export const useCurriculumSummaries = (): CurriculumSummary[] => {
  const featureFlags = useFeatureFlags();
  const { curriculumSummariesClient } = useAPI();
  return (
    useQuery(
      ['curriculumSummaries'],
      () =>
        curriculumSummariesClient.listCurriculumSummaries(ListCurriculumSummariesRequest.create({}))
          .response,
      {
        select: useCallback(
          (data: ListCurriculumSummariesResponse) => {
            return data.curriculumSummaries.map(currSum =>
              // If the school has a feature flag to use aliases for curricula then swap out the
              // curricula display names for their aliases.
              getCurriculumSummaryWithDisplayNameAlias(
                currSum,
                featureFlags.getStringFlag('use-curriculum-aliases', ''),
              ),
            );
          },
          [featureFlags],
        ),

        // Curriculums rarely change, so we cache them forever.
        cacheTime: Infinity,
        staleTime: Infinity,
        suspense: true,
      },
    ).data || []
  );
};

/**
 * useCurriculumSummary returns the curriculum summary for a given curriculum.
 * The loading/error states are handled by suspense.
 */
export const useCurriculumSummary = (curriculumName: string): CurriculumSummary | undefined => {
  const curriculumSummaries = useCurriculumSummaries();

  return useMemo(() => {
    return curriculumSummaries?.find(cs => cs.curriculum?.name === curriculumName);
  }, [curriculumSummaries, curriculumName]);
};

const createTopicSummariesQuery = (
  topicSummariesClient: TopicSummariesClient,
  topicParent: string,
) => {
  return {
    queryKey: ['topicSummaries', topicParent],
    queryFn: () =>
      topicSummariesClient.listTopicSummaries(
        ListTopicSummariesRequest.create({
          topicParent: topicParent,
          options: {
            includeLearningPaths: true,
            omitKeyQuestions: true,
            omitTopicLinks: false,
            includeAllQuestions: false,
            includeQuestionLayoutJson: false,
          },
        }),
      ).response,
    select: (data: ListTopicSummariesResponse) =>
      data.topicSummaries.reduce((m, v) => {
        if (v.topic) {
          m.set(v.topic.name, v);
        }
        return m;
      }, new Map<string, TopicSummary>()),

    // Topics rarely change, so we cache them forever.
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: true,
  };
};

/**
 * useTopicSummariesMapForCurriculum returns a map of topic summaries for the given curriculums.
 * The loading/error states are handled by suspense.
 */
const useTopicSummariesMapForCurriculums = (curriculumNames: string[]) => {
  const { topicSummariesClient } = useAPI();
  const topicSummariesQueries = curriculumNames.map(curriculumName =>
    createTopicSummariesQuery(topicSummariesClient, curriculumName),
  );
  const queries = useQueries({
    queries: topicSummariesQueries,
  });

  const merged = new Map<string, TopicSummary>();
  queries.forEach(query => {
    if (query.data) {
      query.data.forEach((v, k) => {
        merged.set(k, v);
      });
    }
  });
  return merged;
};

/**
 * useTopicSummariesMap returns a map of topic summaries for every curriculum.
 * The loading/error states are handled by suspense.
 */
export const useTopicSummariesMap = () => {
  const curriculumSummaries = useCurriculumSummaries();
  const curriculumNames = curriculumSummaries.map(cs => cs.curriculum!.name);
  return useTopicSummariesMapForCurriculums(curriculumNames);
};

/**
 * useTopicSummariesMapForCurriculum returns a map of topic summaries for a given curriculum.
 * The loading/error states are handled by suspense.
 */
export const useTopicSummariesMapForCurriculum = (curriculumName: string) => {
  return useTopicSummariesMapForCurriculums([curriculumName]);
};

/**
 * useTopicSearch returns the search results for a given curriculum/search term.
 */
export const useTopicSearch = (
  curriculumName: string,
  searchTerm: string,
  eventCategory?: swclient2EventCategory,
) => {
  const searchQuery = useCreateTopicSearchQuery(searchTerm, eventCategory)(curriculumName);
  return useQuery(searchQuery);
};

/**
 * useAllCurriculumTopicSearch returns the search results for all curricula and the selected curriculum. The results from
 * the all curricula search CAN include topics from the selected curriculum as well, so it is important to filter these out
 * when displaying the results.
 * @param searchTerm
 * @param selectedCurriculum
 * @param eventCategory
 */
export const useAllCurriculumTopicSearch = (
  searchTerm: string,
  selectedCurriculum: string,
  eventCategory: swclient2EventCategory,
) => {
  const createSearchQuery = useCreateTopicSearchQuery(searchTerm, eventCategory);

  return useQueries({
    // Create a query for the selected curriculum and all curricula.
    queries: [
      createSearchQuery(selectedCurriculum, SELECTED_CURRICULA_RESULTS_LIMIT),
      createSearchQuery(ALL_CURRICULA_SEARCH_NAME, ALL_CURRICULA_RESULTS_LIMIT),
    ],
  });
};

/**
 * useCreateTopicSearchQuery returns a query object for searching topics.
 * @param searchTerm
 * @param eventCategory
 */
const useCreateTopicSearchQuery = (searchTerm: string, eventCategory?: swclient2EventCategory) => {
  const { contentSearchClient } = useAPI();
  const sendEvent = useAnalytics();

  return (curriculumName: string, maxResults = 0) => ({
    queryKey: ['topicSearch', curriculumName, searchTerm],
    queryFn: () =>
      contentSearchClient.search(
        Query.create({
          query: searchTerm,
          maxResults,
          omitMatches: false,
          filter: {
            ancestorResourceName: curriculumName,
            resourceType: 'curriculums/topics',
            includeDeleted: false,
            minScore: 0,
          },
        }),
      ).response,
    select: (data: Result) => data.matchingResources,
    // Keep topic search results in the cache for 30 minutes...
    cacheTime: 1800000,
    // ... but treat them as stale after 30 seconds.
    // This means that if the user searches for the same thing a short while later:
    // - They immediately see the cached result.
    // - We rerun the search behind the scenes and update the results if anything is different.
    staleTime: 30000,

    onSuccess: (data: MatchingResource[]) => {
      if (eventCategory && searchTerm.length > 0) {
        sendEvent({
          category: eventCategory,
          action: 'search',
          labels: {
            term: searchTerm,
            results: data.map(r => r.resourceName).join(','),
          },
        });
      }
    },
  });
};

import * as Collapsible from '@radix-ui/react-collapsible';
import { PackageCompletion as Package } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { ErrorMessage } from '@sparx/sparx-design/components';
import { Chip } from '@sparx/sparx-design/components/Chip';
import { Tooltip } from '@sparx/sparx-design/components/tooltip/Tooltip';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/accordion/Accordion';
import { AccordionStatus, PackageLockReason } from 'components/accordion/types';
import { useOnboardingLockState } from 'context/onboardinglock';
import { usePackageTasks } from 'queries/packages';
import { useEffect, useState } from 'react';
import { useAnalytics } from 'utils/analytics';
import { getPkgOrTaskCompletionPercentage, isPkgLate } from 'utils/package';
import { OnboardingPackageTasks } from 'views/package-list/onboarding-package-tasks';
import { Task } from 'views/package-list/task';

import { PackageStatus } from '../package-status/PackageStatus';
import taskStyles from '../task/Task.module.css';
import styles from './PackageListItem.module.css';

// The minimum amount of time the loading spinner should be shown for when opening a package. (Will be
// shown longer if the package takes longer to load.)
const MIN_LOADING_TIME_MS = 1000;

interface PackageListItemProps {
  pkg: Package;
  i: number;
  // Whether the user has opened the package
  opened: boolean;
}

export const PackageListItem = ({ pkg, opened }: PackageListItemProps) => {
  const sendEvent = useAnalytics();

  const { onboardingLockEnabled } = useOnboardingLockState();

  const {
    data: tasks,
    isLoading,
    isSuccess,
    isError,
    error: packageTasksError,
  } = usePackageTasks(pkg.packageID, {
    enabled: opened,
  });

  // When a package is opened, while loading, we show a loading spinner for at least
  // MIN_LOADING_TIME_MS, even if the data from the server loads quicker. This state tracks whether
  // that time has passed. (We don't show the spinner if the item is rendered after the package data
  // has already loaded)
  const [openComplete, setOpenComplete] = useState(opened || isSuccess);

  // Stop showing the loading spinner MIN_LOADING_TIME_MS after the package is opened.
  useEffect(() => {
    let timeout: number | undefined;
    if (opened && !openComplete) {
      timeout = window.setTimeout(() => {
        setOpenComplete(true);
      }, MIN_LOADING_TIME_MS);
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [openComplete, opened]);

  // Send a page event if there was an error
  if (packageTasksError) {
    sendEvent({
      action: 'error loading package tasks',
      category: 'package list',
      labels: {
        error: packageTasksError.toString(),
      },
    });
  }

  const isSmallScreen = useBreakpoint('sm');

  const pkgCompletionPercentage = getPkgOrTaskCompletionPercentage(pkg);

  //  If onboardingLock is active, lock all non-onboarding packages
  const isPackageLockedByOnboarding = onboardingLockEnabled && pkg.packageType !== 'onboarding';

  const isPackageDeleted = pkg.deletedTime !== undefined;

  const isPackageLocked = isPackageLockedByOnboarding || isPackageDeleted;

  const status = isPackageLocked
    ? AccordionStatus.Locked
    : pkgCompletionPercentage === 0
      ? AccordionStatus.NotStarted
      : pkgCompletionPercentage === 100
        ? AccordionStatus.Completed
        : AccordionStatus.InProgress;

  const lockReason = isPackageDeleted
    ? PackageLockReason.Deleted
    : isPackageLockedByOnboarding
      ? PackageLockReason.Onboarding
      : undefined;

  // Note, we don't need to show "late" for "training-homework" packages
  const showLate = pkg.packageType === 'homework' && isPkgLate(pkg) && !isPackageLocked;

  const triggerElement = (
    <AccordionTrigger
      className={classNames(styles.PackageAccordionTrigger, accessibilityStyles.FocusTarget, {
        [styles.LockedPackage]: isPackageLocked,
      })}
      onClick={e => {
        if (isPackageLocked) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className={styles.Package}>
        <div className={styles.PackageLeft}>
          <span>{pkg.title}</span>
          {showLate && (
            <Chip
              className={styles.LateChip}
              colourVariant="Incorrect"
              shapeVariant="Boxy"
              styleVariant="Outlined"
              responsive={true}
            >
              Late
            </Chip>
          )}
        </div>
        <div className={styles.PackageRight}>
          <PackageStatus
            completionPercentage={pkgCompletionPercentage}
            isPackageLocked={isPackageLocked}
            lockReason={lockReason}
            completion={pkg.completion}
            startDate={pkg.startDate}
          />
        </div>
      </div>
    </AccordionTrigger>
  );

  const LockTooltip = ({
    children,
    lockReason,
  }: {
    children: React.ReactNode;
    lockReason: PackageLockReason;
  }) =>
    lockReason === PackageLockReason.Onboarding ? (
      <Tooltip position="top" content="Complete the 'Introducing Sparx Maths' task to unlock">
        {children}
      </Tooltip>
    ) : lockReason === PackageLockReason.Deleted ? (
      <Tooltip
        position="top"
        title="Homework Cancelled"
        content={[
          'This homework has been cancelled by your teacher and does not need to be completed.',
          'You are still able to complete your XP Boost and Target homework if you would like.',
        ]}
      >
        {children}
      </Tooltip>
    ) : null;

  return (
    <AccordionItem value={`${pkg.packageID}`} status={status}>
      {isPackageLocked && lockReason ? (
        <LockTooltip lockReason={lockReason}>{triggerElement}</LockTooltip>
      ) : (
        triggerElement
      )}
      <AccordionContent>
        {/* Loading spinner, shows while loading for a minimum duration */}
        <Collapsible.Root open={!openComplete || isLoading}>
          <Collapsible.Content className={styles.CollapsibleContent}>
            <div className={classNames(taskStyles.Task, styles.TaskLoading)}>
              <LoadingSpinner />
              <div className={styles.TaskLoadingMessage}>
                {pkg.status === 'HW_ON_DEMAND_PLACEHOLDER' ? 'Creating homework...' : 'Loading...'}
              </div>
            </div>
          </Collapsible.Content>
        </Collapsible.Root>

        <Collapsible.Root open={isError}>
          <Collapsible.Content className={styles.CollapsibleContent}>
            <div
              className={classNames(
                taskStyles.Task,
                styles.TaskLoading,
                styles.TaskLoadingMessage,
                styles.TaskLoadingErrorMessage,
              )}
            >
              <ErrorMessage
                message={
                  <>
                    Something went wrong. Please{' '}
                    <a href="" className={styles.RefreshLink}>
                      refresh the page
                    </a>{' '}
                    and try again.
                  </>
                }
              />
            </div>
          </Collapsible.Content>
        </Collapsible.Root>

        <Collapsible.Root open={openComplete && !isLoading && tasks && tasks.length > 0}>
          {/* Contains the tasks, is shown once they're loaded, and the open is complete (minimum time) */}
          <Collapsible.Content className={styles.CollapsibleContent}>
            {openComplete &&
              (pkg.packageType === 'onboarding' ? (
                <OnboardingPackageTasks pkg={pkg} tasks={tasks} />
              ) : (
                tasks?.map(task => (
                  <Task
                    key={task.taskIndex}
                    task={task}
                    packageID={pkg.packageID}
                    isSmallScreen={isSmallScreen}
                    sendEvent={sendEvent}
                  />
                ))
              ))}
          </Collapsible.Content>
        </Collapsible.Root>
      </AccordionContent>
    </AccordionItem>
  );
};

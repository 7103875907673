import { useAPI } from 'context/api';
import { useSession } from 'queries/session';
import { createContext, useEffect, useMemo } from 'react';

import ServerSteamingManager, { Handler, MessageType } from './manager';

type Context = {
  /**
   * Registers a handler function to be called when a message of type messageType is received.
   */
  registerHandler: (messageType: MessageType, handler: Handler) => void;
};

export const ServerStreamingContext = createContext<Context | undefined>(undefined);

export const ServerStreamingProvider = ({ children }: { children: React.ReactNode }) => {
  const session = useSession();
  const sessionId = session.data?.session?.sessionID;
  const { swserverClient } = useAPI();

  const manager = useMemo(() => new ServerSteamingManager(swserverClient), [swserverClient]);

  const registerHandler = (messageType: MessageType, handler: Handler) => {
    manager.addHandler(messageType, handler);
  };

  useEffect(() => {
    if (!sessionId) {
      return;
    }

    manager.startWithSessionId(sessionId);
  }, [sessionId, manager]);

  return (
    <ServerStreamingContext.Provider value={{ registerHandler }}>
      {children}
    </ServerStreamingContext.Provider>
  );
};

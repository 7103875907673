export type VideoUrls = {
  videoUrl: string;
  subtitleUrl: string;
  posterUrl: string;
};

const contentAssetsURL = window.__sparxweb.urls.contentAssets;
export const makeQuestionVideoUrls = (videoId: string): VideoUrls => ({
  posterUrl: `${contentAssetsURL}/${videoId}/poster`,
  subtitleUrl: `${contentAssetsURL}/${videoId}/subtitles`,
  videoUrl: `${contentAssetsURL}/${videoId}`,
});

export const staticAssetsURL = window.__sparxweb.urls.staticAssets;
export const makeStaticVideoUrls = (videoId: string, extendedPath = false) => {
  return {
    posterUrl: `${staticAssetsURL}/${videoId}${extendedPath ? '.poster' : ''}.png`,
    subtitleUrl: `${staticAssetsURL}/${videoId}${extendedPath ? '.subtitles' : ''}.vtt`,
    videoUrl: `${staticAssetsURL}/${videoId}.mp4`,
  };
};

const onboardingAssetBaseUrl = `${window.__sparxweb.urls.staticAssets}/assets/student-onboarding`;
export const makeOnboardingVideoUrls = (key: string): VideoUrls => ({
  videoUrl: `${onboardingAssetBaseUrl}/${key}.mp4`,
  subtitleUrl: `${onboardingAssetBaseUrl}/${key}.subtitles.vtt`,
  posterUrl: `${onboardingAssetBaseUrl}/${key}.poster.png`,
});

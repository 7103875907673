import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { DisruptiveDialog } from 'components/disruptive-dialog';
import { useServerStreaming } from 'context/serverstreaming';
import { useEffect, useState } from 'react';
import { useAnalytics } from 'utils/analytics';

export const UniqueLoginHandler = ({ children }: { children: React.ReactNode }) => {
  const { registerHandler } = useServerStreaming();
  const [otherLogin, setOtherLogin] = useState(false);
  const msgType = 'sparxweb.LogoutResponse';
  const sendEvent = useAnalytics();
  const [sentAnalytic, setSentAnalytic] = useState(false);

  const [deviceFingerprint, setDeviceFingerprint] = useState('');
  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();

      setDeviceFingerprint(visitorId);
    };

    setFp();
  }, []);

  useEffect(() => {
    registerHandler(msgType, {
      id: 'uniqueLoginHandler',
      callback: () => {
        setOtherLogin(true);
        if (!sentAnalytic) {
          sendEvent({
            category: 'navigation',
            action: 'logged in somewhere else',
            labels: { deviceFingerprint },
          });
          setSentAnalytic(true);
        }
      },
    });
  }, [registerHandler, setOtherLogin, sendEvent, deviceFingerprint, sentAnalytic, setSentAnalytic]);

  return (
    <>
      {children}
      {otherLogin && (
        <DisruptiveDialog
          isOpen={otherLogin}
          description="Looks like you've logged in somewhere else"
          onClose={() => location.reload()}
          onAction={() => location.reload()}
          actionButtonContent="Continue"
        />
      )}
    </>
  );
};

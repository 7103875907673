/**
 * Maps Safari Key codes to Keys used in other browsers.
 * Only contains keys used in Sparx Web
 */
const KEY_CODES_TO_KEYS: Partial<Record<number, string>> = {
  13: 'Enter',
  27: 'Escape',
  32: ' ',
  37: 'ArrowLeft',
  38: 'ArrowUp',
  39: 'ArrowRight',
  40: 'ArrowDown',
  65: 'a',
  66: 'b',
  67: 'c',
  70: 'f',
  72: 'h',
  74: 'j',
  76: 'l',
  78: 'n',
  81: 'q',
  86: 'v',
  87: 'w',
  88: 'x',
  90: 'z',
  187: '=',
  189: '-',
};

/**
 * Maps the names of keys in IE and Edge to the names used in Chrome
 * @param keyCode
 * @returns {*}
 */
const KEY_MAPPING: Partial<Record<string, string>> = {
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  Up: 'ArrowUp',
  Down: 'ArrowDown',
  Esc: 'Escape',
};

/**
 * Given a browser key press event, determine which key was pressed in a way
 * which works for both Safari (which uses keyCode) and other browsers
 * (which use key). (This will only work for keys with a mapping defined in
 * KEY_CODES_TO_KEYS above). Also, IE and Edge use a different name for the
 * arrow keys than Chrome, so we map those names into the Chrome terminology
 * @param keyPressEvent
 * @returns {*} A string specifying which key was pressed - e.g. "Enter"
 */
export const getKeyPressed = (
  keyPressEvent: React.KeyboardEvent | KeyboardEvent,
): string | undefined => {
  if (keyPressEvent.key) {
    return KEY_MAPPING[keyPressEvent.key] || keyPressEvent.key;
  }
  if (keyPressEvent.keyCode) {
    return KEY_CODES_TO_KEYS[keyPressEvent.keyCode];
  }
  return undefined;
};

import { SubmitQuizAnswersResponse } from '@sparx/api/apis/sparx/swworker/v1/worker';
import {
  AbortQuizRequest,
  AbortQuizResponse,
  SubmitQuizAnswersRequest,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import { useAPI } from 'context/api';

import { updateWithPackageData } from './packages';
import { ensureTimestamp } from './utils';

export const useSubmitQuizAnswers = (
  options?: UseMutationOptions<SubmitQuizAnswersResponse, Error, SubmitQuizAnswersRequest>,
) => {
  const { swworkerClient } = useAPI();
  const { onSuccess: passedOnSuccess, ...restPassedOptions } = options || {};
  return useMutation(
    (req: SubmitQuizAnswersRequest) =>
      swworkerClient.submitQuizAnswers(ensureTimestamp(req)).response,
    {
      retry: false,
      onSuccess: (data, variables, context) => {
        passedOnSuccess?.(data, variables, context);
        data.packageData && updateWithPackageData(data.packageData);
      },
      ...restPassedOptions,
    },
  );
};

export const quizResultsQueryKey = ['quizResults'];

export const useGetQuizResults = () => {
  const { swworkerClient } = useAPI();
  return useQuery({
    queryKey: quizResultsQueryKey,
    queryFn: async () => swworkerClient.getQuizResults({}).response,
  });
};

export const useAbortQuiz = (
  options?: UseMutationOptions<AbortQuizResponse, Error, AbortQuizRequest>,
) => {
  const { swworkerClient } = useAPI();
  const { onSuccess: passedOnSuccess, ...restPassedOptions } = options || {};
  return useMutation(
    (req: AbortQuizRequest) => swworkerClient.abortQuiz(ensureTimestamp(req)).response,
    {
      retry: false,
      onSuccess: (data, variables, context) => {
        passedOnSuccess?.(data, variables, context);
        data.packageData && updateWithPackageData(data.packageData);
      },
      ...restPassedOptions,
    },
  );
};

import {
  CurriculumSummary,
  StrandSummary,
  SubstrandSummary,
  TopicSummary,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { LearningPath, LearningPathSpec } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { AssignedTopic, RevisionPackage } from '@sparx/api/apis/sparx/revision/v1/revision';
import { getLearningUnitIDFromName, getTopicIDFromName } from '@sparx/resource-names';
import { ProgressBadgeScore } from '@sparx/sparx-design/components';
import { AccordionStatus } from 'components/accordion/types';

export interface CurriculumDetails {
  hierarchy: CurriculumSummary;
}

export interface TopicLocation {
  curriculumDetails: CurriculumDetails;
  strandSummary: StrandSummary;
  substrandSummary: SubstrandSummary;
  orderInSubstrand: number;
}

/**
 * A representation of the progress the student has made on a learning path - i.e. a set of learning units (each of
 * which has 3 tasks - introduce, strengthen, and deepen) that make up a topic at a given level
 */
export interface IProgress {
  // The number of learning units where the user has done at least one task
  done: number;
  // The number of learning units where the user has done at least two tasks
  star1: number;
  // The number of learning units where the user has done at least 3 tasks
  star2: number;
  // The total number of learning units in the learning path
  total: number;
  // The number of learning units where the user has started at least one task
  started: number;
}

/**
 * Returns a ProgressBadgeScore (i.e. state of the ProgressBadge) for a topic given the progress the student has made on
 * a learning path for that topic
 * @param done The number of learning units where the user has done at least one task
 * @param star1 The number of learning units where the user has done at least two tasks
 * @param star2 The number of learning units where the user has done at least 3 tasks
 * @param total The total number of learning units in the learning path
 * @param started The number of learning units where the user has started at least one task
 */
export const getLearningPathProgressScore = ({ done, star1, star2, total, started }: IProgress) => {
  if (started === 0) {
    return ProgressBadgeScore.NEW;
  }
  const almost = (total * 2) / 3;
  if (star2 === total) {
    return ProgressBadgeScore.COMPLETE;
  }
  if (star2 > almost) {
    return ProgressBadgeScore.ALMOST_COMPLETE;
  }
  if (star1 === total) {
    return ProgressBadgeScore.STARRED;
  }
  if (star1 > almost) {
    return ProgressBadgeScore.ALMOST_STARRED;
  }
  if (done === total) {
    return ProgressBadgeScore.TICKED;
  }
  if (done > almost) {
    return ProgressBadgeScore.ALMOST_TICKED;
  }
  return ProgressBadgeScore.STARTED;
};

/**
 * getDefaultLearningPathName returns the middle learning path spec name out of an ordered list of learning paths. This
 * means for e.g. GCSE it will be 3 (of 5), but for e.g. KS3 will be 2 (of 4).
 */
export const getDefaultLearningPathName = (learningPathSpecs: LearningPathSpec[]): string =>
  learningPathSpecs[Math.floor((learningPathSpecs.length - 1) / 2)].name;

/**
 * getPackageProgressByObjectiveID turns active packages from the revision server into a map of objectiveID to package
 * completion for the package that is for that objective.
 * (Note that objective ID is a learning unit ID within a learning path for a substrand)
 * @param revisionPackages
 * @param packageCompletions
 */
export const getPackageProgressByObjectiveID = (
  revisionPackages?: RevisionPackage[],
  packageCompletions?: PackageCompletion[],
) => {
  const map = new Map<string, PackageCompletion>();

  if (!revisionPackages || !packageCompletions) {
    return map;
  }

  // Put package completion into a map by packageID
  const packagesByID = packageCompletions.reduce(
    (acc: Map<string, PackageCompletion>, pkg: PackageCompletion) => {
      acc.set(pkg.packageID, pkg);
      return acc;
    },
    new Map<string, PackageCompletion>(),
  );

  // Create a map of objectiveID to package completion
  return revisionPackages.reduce((acc: Map<string, PackageCompletion>, pkg: RevisionPackage) => {
    const pc = packagesByID.get(pkg.packageId);
    if (pc) {
      acc.set(pkg.objectiveId, pc);
    }
    return acc;
  }, map);
};

/**
 * calculateLearningPathProgress calculates the student's progress on each learning path that any of the topics in the
 * provided topicSummaries are on. The student's progress on a learning path is found by totalling up the progress on
 * each learning unit in the learning path. We can find the progress for a learning unit by finding matching the
 * learning unit ID with the objective id associated with any active packages for the student
 */
export const calculateLearningPathProgress = (
  topicSummaries?: Map<string, TopicSummary>,
  revisionPackages?: RevisionPackage[],
  packageCompletions?: PackageCompletion[],
): Map<string, IProgress> | undefined => {
  if (!topicSummaries || !revisionPackages || !packageCompletions) {
    return undefined;
  }

  // Get a map of objectiveID (aka learning unit ID) to package progress for the package the user has been assigned
  // for that learning unit
  const packageProgressByObjectiveID = getPackageProgressByObjectiveID(
    revisionPackages,
    packageCompletions,
  );

  // Iterate over all topics, and for each topic, iterate over all learning
  // paths that include this topic (i.e. the ones at different levels).
  // For each learning path, get the package progress for each learning unit in
  // the learning path, and calculate the progress for the learning path as a whole.
  // Return a map of topic name to progress for each learning path.
  const lpProgressByLPName = new Map<string, IProgress>();
  for (const topicSummary of topicSummaries.values()) {
    for (const lp of topicSummary.learningPaths) {
      const progress: IProgress = {
        done: 0,
        star1: 0,
        star2: 0,
        total: 0,
        started: 0,
      };

      for (const learningUnitName of lp.learningUnitNames) {
        // Note: an IL package's objective ID is the id of a learning unit within a learning path
        const objectiveID = getLearningUnitIDFromName(learningUnitName);
        if (objectiveID) {
          const packageProgress = packageProgressByObjectiveID.get(objectiveID);
          if (packageProgress) {
            if (packageProgress.numTasksComplete >= 3) {
              progress.star2++;
            }
            if (packageProgress.numTasksComplete >= 2) {
              progress.star1++;
            }
            if (packageProgress.numTasksComplete >= 1) {
              progress.done++;
            }
            progress.started += packageProgress.numTasksInProgress;
            progress.total++;
          }
        }
      }
      if (progress.total > 0) {
        lpProgressByLPName.set(lp.name, progress);
      }
    }
  }

  return lpProgressByLPName;
};

/**
 * Progress on a topic shown on the StrandView
 */
export interface TopicProgress {
  // The status of the progress badge (which has a tick and two stars)
  score: ProgressBadgeScore;
  // Which learning path (aka Level) was picked for the topic progress. If the
  // user has switched levels and worked on learning units within the topic on
  // multiple different learning paths, we pick the learning path that has the
  // greatest amount of progress
  learningPath?: LearningPath;
}

/**
 * Progress on a substrand shown on the StrandView
 */
export interface SubstrandProgress {
  // The number of topics started in the subsstrand
  startedTopics: number;
  // The number of topics done in the substrand
  doneTopics: number;
  // The number of topics included in the substrand
  topicCount: number;
  // The number of topics assigned to the student in homework
  assignedCount: number;
}

/**
 * Calculates the progress for substrands and the topics within them to be used in the StrandView.
 *
 * First, we calculate the progress for each learning path in the curriculum, using "calculateLearningPathProgress".
 * Then, we iterate over the substrands, and the topics within each substrand, and for each topic we find the learning
 * path that the student has made the most progress in. We create a score for the topic to be shown on the badge,
 * and record which learning path was chosen in "topicProgress", keyed by topic name.
 * We also create a progress for the substrand which is an aggregate of the progress of all of the chosen learning paths
 * for topics within the substrand.
 *
 * @param topicSummaries topic summaries map
 * @param activePackages active packages for the student - i.e. packages of work for learning units that have been
 * created
 * @param substrandSummaries details of each substrand
 * @param assignedTopics details of which topics have been covered in homework by the student
 */
export const calculateSubstrandProgress = (
  topicSummaries?: Map<string, TopicSummary>,
  revisionPackages?: RevisionPackage[],
  packageCompletions?: PackageCompletion[],
  substrandSummaries?: SubstrandSummary[],
  assignedTopics?: Map<string, AssignedTopic>,
):
  | {
      topicProgress: Map<string, TopicProgress>;
      substrandProgress: Map<string, SubstrandProgress>;
    }
  | undefined => {
  if (
    !substrandSummaries ||
    !topicSummaries ||
    !revisionPackages ||
    !packageCompletions ||
    !assignedTopics
  ) {
    return undefined;
  }

  const learningPathProgress = calculateLearningPathProgress(
    topicSummaries,
    revisionPackages,
    packageCompletions,
  );

  if (!learningPathProgress) {
    return undefined;
  }

  const topicProgress = new Map<string, TopicProgress>();
  const substrandProgress = new Map<string, SubstrandProgress>();

  for (const substrandSummary of substrandSummaries) {
    const ssProgress = {
      startedTopics: 0,
      doneTopics: 0,
      topicCount: 0,
      assignedCount: 0,
    };
    if (!substrandSummary.substrand) {
      continue;
    }
    substrandProgress.set(substrandSummary.substrand.name, ssProgress);

    for (const topicName of substrandSummary.substrand.topicNames) {
      const topicSummary = topicSummaries.get(topicName);

      if (!topicSummary) {
        continue;
      }

      const tProgress: { score: ProgressBadgeScore; learningPath?: LearningPath } = {
        score: ProgressBadgeScore.NEW,
      };

      for (const lp of topicSummary.learningPaths) {
        const lpProgress = learningPathProgress.get(lp.name);
        if (!lpProgress) {
          continue;
        }

        const lpScore = getLearningPathProgressScore(lpProgress);

        // Choose this lp if it's done or its score is at least as good as the previously chosen lp.
        if (
          lpScore !== ProgressBadgeScore.NEW &&
          (lpScore >= ProgressBadgeScore.TICKED || tProgress.score <= lpScore)
        ) {
          tProgress.score = lpScore;
          tProgress.learningPath = lp;
        }
      }

      ssProgress.topicCount++;
      topicProgress.set(topicName, tProgress);
      if (tProgress.score >= ProgressBadgeScore.STARTED) {
        ssProgress.startedTopics++;
      }
      if (tProgress.score >= ProgressBadgeScore.TICKED) {
        ssProgress.doneTopics++;
      }
      const topicID = getTopicIDFromName(topicName);
      if (topicID && assignedTopics.get(topicID) !== undefined) {
        ssProgress.assignedCount++;
      }
    }
  }

  return { topicProgress, substrandProgress };
};

/**
 * getAccordionStatusFromSubstrandProgress returns the status of the accordion for a substrand based on the student's
 * progress in topics in the substrand.
 * @param ssProgress The student's progress on topics in the substrand
 */
export const getAccordionStatusFromSubstrandProgress = (
  ssProgress: SubstrandProgress | undefined,
): AccordionStatus => {
  if (ssProgress === undefined) {
    return AccordionStatus.NotStarted;
  }
  if (ssProgress.doneTopics === ssProgress.topicCount) {
    return AccordionStatus.Completed;
  }
  if (ssProgress.startedTopics > 0) {
    return AccordionStatus.InProgress;
  }
  return AccordionStatus.NotStarted;
};

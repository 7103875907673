import { HiddenAt } from '@sparx/sparx-design/components';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { MaxWidth } from 'components/page/Page';
import { ReactComponent as AssessmentIcon } from 'images/feature-icons/icon_assessments.svg';
import { useAssessmentSummary } from 'queries/assessments';
import { useMemo } from 'react';

import { AssessmentCard } from './AssessmentCard';
import styles from './AssessmentsOverview.module.css';
import { getCards } from './utils';

export const AssessmentsOverview = () => {
  const { data: summary, isLoading, isError } = useAssessmentSummary();

  // Create the cards
  const cards = useMemo(() => {
    return getCards(summary);
  }, [summary]);

  return (
    <MaxWidth className={styles.PageContainer}>
      <div className={styles.PageHead}>
        <div className={styles.HeadContent}>
          <h1 className={styles.Title}>Revision & Assessments</h1>
          <p>
            When you have an upcoming Sparx Maths assessment you can use this area to revise the
            different questions that you will likely see in the assessment.
          </p>
          <p>
            After you&apos;ve completed an assessment, come back here to see your results and try to
            fix up questions answered incorrectly or that you didn&apos;t have time to complete.
          </p>
        </div>
        <HiddenAt breakpoint="md" className={styles.IconContainer}>
          <AssessmentIcon />
        </HiddenAt>
      </div>

      <h2 className={styles.AssessmentListTitle}>My Assessments</h2>
      {isLoading && <LoadingSpinner />}
      {isError && <p>Something went wrong!</p>}
      {!isLoading &&
        !isError &&
        cards.map(cardProps => (
          <AssessmentCard
            key={cardProps.assessmentGroup?.name ?? cardProps.assessment.name}
            {...cardProps}
          />
        ))}
    </MaxWidth>
  );
};

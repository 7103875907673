import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDown } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { AccordionStatus } from 'components/accordion/types';
import React, { ComponentProps, ReactNode } from 'react';

import styles from './Accordion.module.css';

// export { Item as AccordionItem, Root as AccordionRoot } from '@radix-ui/react-accordion';

export const AccordionTrigger = React.forwardRef(
  (
    { children, className, ...props }: ComponentProps<'button'>,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <Accordion.Header className={styles.AccordionHeader}>
      <Accordion.Trigger
        className={classNames(styles.AccordionTrigger, className)}
        {...props}
        ref={forwardedRef}
      >
        <div className={styles.AccordionChevronContainer}>
          <ChevronDown className={styles.AccordionChevron} />
        </div>
        {children}
      </Accordion.Trigger>
    </Accordion.Header>
  ),
);

export const AccordionContent = React.forwardRef(
  (
    { children, className, ...props }: ComponentProps<'div'>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <Accordion.Content
      className={classNames(styles.AccordionContent, className)}
      {...props}
      ref={forwardedRef}
    >
      <div className={styles.AccordionContentText}>{children}</div>
    </Accordion.Content>
  ),
);

export const AccordionItem = ({
  children,
  status,
  className,
  value,
}: {
  children: ReactNode;
  status?: AccordionStatus;
  className?: string;
  value: string;
}) => (
  <Accordion.Item
    className={classNames(styles.AccordionItem, className, {
      [styles.WithStatus]: status !== undefined,
      [styles.Completed]: status === AccordionStatus.Completed,
      [styles.InProgress]: status === AccordionStatus.InProgress,
      [styles.NotStarted]: status === AccordionStatus.NotStarted,
      [styles.Locked]: status === AccordionStatus.Locked,
    })}
    value={value}
  >
    {children}
  </Accordion.Item>
);

export const AccordionRoot = ({
  className,
  children,
  value,
  onValueChange,
}: {
  className?: string;
  children: ReactNode;
  value: string[];
  onValueChange: (value: string[]) => void;
}) => (
  <Accordion.Root
    className={classNames(styles.AccordionRoot, className)}
    type="multiple"
    value={value}
    onValueChange={onValueChange}
  >
    {children}
  </Accordion.Root>
);

import { Assessment, AssessmentGroup } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { TaskCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { makeAssessmentName } from '@sparx/resource-names';
import { Button } from '@sparx/sparx-design/components';
import { Chip, ChipColourVariant, ChipStyleVariant } from '@sparx/sparx-design/components/Chip';
import { LargeLoading, Tick } from '@sparx/sparx-design/icons';
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'components/accordion/Accordion';
import { AccordionStatus } from 'components/accordion/types';
import { MaxWidth } from 'components/page/Page';
import { useAssessmentPackages, useAssessmentSummary } from 'queries/assessments';
import { usePackageTasks } from 'queries/packages';
import { TutorialKey } from 'queries/tutorials';
import { createContext, ReactNode, useContext, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getPkgOrTaskCompletionPercentage } from 'utils/package';
import { makeTaskPath } from 'utils/paths';

import { AssessmentOnboardingVideoModal } from '../onboarding-video/AssessmentOnboardingVideoModal';
import styles from './AssessmentRevision.module.css';

const AssessmentContext = createContext<Assessment | undefined>(undefined);

const useAssessmentContext = () => {
  const ctx = useContext(AssessmentContext);
  if (!ctx) {
    throw new Error(
      'useAssessmentContext can only be used inside of an AssessmentContext.Provider',
    );
  }

  return ctx;
};

/**
 * Performs all the data loading required to render the page.
 * If any data is loading, the loading spinner will be shown.
 * If any data is undefined or missing after loading then it will redirect back to the assessment
 * overview.
 */
export const AssessmentRevision = () => {
  const { assessmentID } = useParams();
  const assessmentName = assessmentID ? makeAssessmentName(assessmentID) : '';

  // Load the assessment summary for the student and check if there is an active revision task.
  const { data: summary, isFetching: summaryLoading } = useAssessmentSummary();
  const assessment = summary?.assessments.find(a => a.name === assessmentName);
  const revisionTask = assessmentName
    ? summary?.revisionTasks.find(t => t.assessmentName === assessmentName)
    : undefined;

  const assessmentGroup = revisionTask?.assessmentGroupName
    ? summary?.assessmentGroups.find(ag => ag.name === revisionTask?.assessmentGroupName)
    : undefined;

  // Load the student's packages for the assessment and look for the revision package.
  const { data: packages, isFetching: packagesLoading } = useAssessmentPackages(
    revisionTask?.assessmentName ?? '',
  );
  const revisionPackage = packages?.packages?.find(p => p.packageType === 'assessment-revision');

  // Load tasks for the revision package.
  const { data: tasks, isFetching: tasksLoading } = usePackageTasks(
    revisionPackage?.packageID ?? '',
  );

  const activelyLoading = summaryLoading || packagesLoading || tasksLoading;

  if (activelyLoading) {
    return <LargeLoading />;
  }

  const shouldRedirect =
    // Assessment isn't available (this can be because revision and fixup are disabled)
    !assessment ||
    // Revision isn't enabled for the assessment/student group
    !revisionTask ||
    // No revision package was generated for this student
    !revisionPackage ||
    // Generated package has no tasks;
    !tasks;

  if (shouldRedirect) {
    return <Navigate to="/assessments" />;
  }

  return (
    <AssessmentContext.Provider value={assessment}>
      <AssessmentRevisionPage
        assessment={assessment}
        tasks={tasks}
        assessmentGroup={assessmentGroup}
      />
    </AssessmentContext.Provider>
  );
};

const AssessmentRevisionPage = ({
  assessment,
  tasks,
  assessmentGroup,
}: {
  assessment: Assessment;
  tasks: TaskCompletion[];
  assessmentGroup?: AssessmentGroup;
}) => {
  const [reshowOnboardingVideo, setReshowOnboardingVideo] = useState(false);

  const { fluencyQuestions, problemSolvingQuestions } = tasks.reduce(
    (data, task) => {
      if (task.title.toLowerCase().startsWith('mixed')) {
        data.problemSolvingQuestions.push(task);
      } else {
        data.fluencyQuestions.push(task);
      }
      return data;
    },
    { fluencyQuestions: [] as TaskCompletion[], problemSolvingQuestions: [] as TaskCompletion[] },
  );

  return (
    <>
      <MaxWidth>
        <div className={styles.PageTitleContainer}>
          <h1 className={styles.PageTitle}>
            {assessmentGroup?.displayName ?? assessment.displayName} revision
          </h1>
          <Button size="sm" variant="text" onClick={() => setReshowOnboardingVideo(true)}>
            What is this?
          </Button>
        </div>
        <TaskLists fluencyTasks={fluencyQuestions} problemSolvingTasks={problemSolvingQuestions} />
      </MaxWidth>
      {/* If the revision package is from an assessment group then show a different video. The modal
      title and key should remain the same, the latter so that a student isn't forced to watch one
      version of the video if they've already watched the other. */}
      <AssessmentOnboardingVideoModal
        tutorialKey={TutorialKey.AssessmentRevisionOnboardingVideo}
        title="Assessment revision"
        videoPrefix={assessmentGroup ? 'assessment-revision-gcse' : 'assessment-revision'}
        reshow={reshowOnboardingVideo}
        onReshown={() => setReshowOnboardingVideo(false)}
      />
    </>
  );
};

const TaskLists = ({
  fluencyTasks,
  problemSolvingTasks,
}: {
  fluencyTasks: TaskCompletion[];
  problemSolvingTasks: TaskCompletion[];
}) => {
  const [openItems, setOpenItems] = useState<string[]>([]);
  return (
    <AccordionRoot value={openItems} onValueChange={v => setOpenItems(v)}>
      <TaskList
        title="Fluency questions"
        description="This section contains questions split into topics to practise key concepts."
        tasks={fluencyTasks}
      />
      <TaskList
        title="Problem solving questions"
        description="This section contains more challenging reasoning questions and cross-topic questions."
        tasks={problemSolvingTasks}
      />
    </AccordionRoot>
  );
};

const TaskList = ({
  title,
  description,
  tasks,
}: {
  title: string;
  description: string;
  tasks: TaskCompletion[];
}) => {
  const overallCompletion =
    tasks.reduce((acc, task) => acc + getPkgOrTaskCompletionPercentage(task), 0) / tasks.length;

  const status: AccordionStatus =
    overallCompletion === 0
      ? AccordionStatus.NotStarted
      : overallCompletion === 100
        ? AccordionStatus.Completed
        : AccordionStatus.InProgress;

  // Filter out any tasks with no task items
  const filteredTasks = tasks.filter(t => !!t.completion?.size);

  return (
    <AccordionItem value={title} status={status}>
      <AccordionTrigger>
        <div className={styles.TaskListTrigger}>
          <p className={styles.TaskListTitle}>{title}</p>
          <p className={styles.TaskListDescription}>{description}</p>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        {filteredTasks.map(task => (
          <TaskRow key={task.taskIndex} task={task} />
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};

const TaskRow = ({ task }: { task: TaskCompletion }) => {
  const assessment = useAssessmentContext();
  const completionPercentage = getPkgOrTaskCompletionPercentage(task);
  const completeTaskItems = task.completion?.progress['C'] ?? 0;
  const totalTaskItems = task.completion?.size ?? 0;

  const chipSettings = {
    colourVariant: 'Interactable' as ChipColourVariant,
    styleVariant: 'Filled' as ChipStyleVariant,
    text: 'Start',
    icon: null as ReactNode,
  };
  switch (true) {
    case completionPercentage > 0 && completionPercentage < 100:
      chipSettings.text = 'Continue';
      break;
    case completionPercentage === 100:
      chipSettings.colourVariant = 'Complete';
      chipSettings.styleVariant = 'Outlined';
      chipSettings.text = 'Completed';
      chipSettings.icon = <Tick className={styles.ChipIcon} variant="DarkGreen" />;
  }

  const taskPath = makeTaskPath(`/${assessment.name}`, task.packageID, task.taskIndex);

  return (
    <Link className={styles.TaskRowLink} to={taskPath}>
      <article className={styles.TaskRow}>
        <p>{task.title}</p>
        <div className={styles.RightSection}>
          <p>
            {completeTaskItems}/{totalTaskItems}
          </p>
          <Chip
            className={styles.Chip}
            size="md"
            styleVariant={chipSettings.styleVariant}
            colourVariant={chipSettings.colourVariant}
          >
            {chipSettings.text}
            {chipSettings.icon}
          </Chip>
        </div>
      </article>
    </Link>
  );
};

import classNames from 'classnames';
import { Module, Step, SummaryStep } from 'types/training';
import { useAnalytics } from 'utils/analytics';
import { getNextIncompleteTrainingStepIndex, parseSummaryPoint } from 'utils/training';

import { clickedReturnToFirstIncompleteStepEvent } from './analytics';
import { ModuleComplete } from './ModuleComplete';
import { StepComplete } from './StepComplete';
import styles from './TrainingBanner.module.css';
import { TrainingFeedback } from './TrainingFeedback';

/**
 * SummaryStepPage is an additional step which shows a summary with extra information
 */

interface ISummaryStepPageProps {
  // The current summary step
  step: SummaryStep;
  // The current training module being completed by the user
  currentModule: Module;
  // Function to call when "Back to teacher Portal" linked is clicked. Allows referrer to be set
  // for analytics purposes.
  handleBackToTeacherPortal: (referrer: string) => void;
  // All the steps in the current training module
  steps: Step[];
  // Function to handle changing the currently selected step
  setCurrentStep: (stepIndex: number) => void;
  // Whether the step is showing
  showing: boolean;
}

export const SummaryStepPage = ({
  step,
  currentModule,
  handleBackToTeacherPortal,
  steps,
  setCurrentStep,
  showing,
}: ISummaryStepPageProps) => {
  const sendEvent = useAnalytics();
  const { isComplete } = currentModule;
  const summary = isComplete ? step.summaryComplete : step.summaryIncomplete;
  // taskNumber is 1-indexed
  const nextIncompleteStepIndex = getNextIncompleteTrainingStepIndex(
    currentModule,
    step.taskNumber - 1,
  );

  return (
    <div className={styles.TrainingModeStep}>
      {isComplete ? (
        <StepComplete title="Module complete!" />
      ) : (
        <div className={styles.TrainingModeUnfinishedBox}>
          <p className={styles.TrainingModeUnfinishedModule}>You haven't finished this module.</p>
          {nextIncompleteStepIndex !== undefined && (
            <p className={styles.TrainingModeUnfinishedModule}>
              <button
                onClick={() => {
                  setCurrentStep(nextIncompleteStepIndex);
                  sendEvent(
                    clickedReturnToFirstIncompleteStepEvent(steps[nextIncompleteStepIndex]),
                  );
                }}
                className={classNames(
                  styles.TrainingModeUnfinishedLink,
                  styles.TrainingBannerFocusTarget,
                )}
                tabIndex={showing ? 0 : -1}
                aria-description="Return to the first incomplete step"
              >
                Click here
              </button>
              to return to the first incomplete step.
            </p>
          )}
        </div>
      )}
      {summary.map(({ heading, points }) => (
        <div key={heading}>
          <h2 className={styles.TrainingModeSummaryHeading}>{heading}</h2>
          <ul className={styles.TrainingModeSummaryList}>
            {points.map(point => (
              <li
                className={styles.TrainingModeSummaryPoint}
                key={point}
                dangerouslySetInnerHTML={{ __html: parseSummaryPoint(point) }}
              />
            ))}
          </ul>
        </div>
      ))}
      {isComplete && currentModule.moduleComplete && (
        <ModuleComplete
          moduleComplete={currentModule.moduleComplete}
          hideHeading
          handleBackToTeacherPortal={handleBackToTeacherPortal}
          showing={showing}
        />
      )}
      <TrainingFeedback currentModule={currentModule} showing={showing} />
    </div>
  );
};

import { Completion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import dialIconAmber from 'images/bookwork/BW_Dial_Amber.svg';
import dialIconGreen from 'images/bookwork/BW_Dial_Green.svg';
import dialIconPending from 'images/bookwork/BW_Dial_Pending.svg';
import dialIconRed from 'images/bookwork/BW_Dial_Red.svg';
import dialIconYellow from 'images/bookwork/BW_Dial_Yellow.svg';
import moment from 'moment';
import { useSession } from 'queries/session';

import { useFeatureFlags } from './feature-flags';

enum completionType {
  BookworkCheckPassed = 'BCP',
  BookworkCheckIncorrect = 'BCI',
}

export enum BookworkLevel {
  Pending = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export const getBookworkLevel = (completion: Completion | undefined): BookworkLevel => {
  const numWACPassed = completion?.progress[completionType.BookworkCheckPassed] || 0;
  const numWACFailed = completion?.progress[completionType.BookworkCheckIncorrect] || 0;

  const numWACTotal = numWACPassed + numWACFailed;
  if (numWACTotal == 0) {
    return BookworkLevel.Pending;
  }
  const correctWACProportion = numWACPassed / numWACTotal;
  if (correctWACProportion < 0.25) {
    return BookworkLevel.One;
  }
  if (correctWACProportion < 0.5) {
    return BookworkLevel.Two;
  }
  if (correctWACProportion < 0.75) {
    return BookworkLevel.Three;
  }
  return BookworkLevel.Four;
};

export const getBookworkDialIcon = (bookworkLevel: BookworkLevel): string => {
  switch (bookworkLevel) {
    case BookworkLevel.Pending:
      return dialIconPending;
    case BookworkLevel.One:
      return dialIconRed;
    case BookworkLevel.Two:
      return dialIconAmber;
    case BookworkLevel.Three:
      return dialIconYellow;
    case BookworkLevel.Four:
      return dialIconGreen;
  }
};

export const useShouldShowBookworkAccuracy = (packageStartDate?: Timestamp) => {
  const featureFlags = useFeatureFlags();
  const { data: session } = useSession();

  if (!packageStartDate) {
    return false;
  }
  const startDateMoment = moment((packageStartDate?.seconds || 0) * 1000);

  return (
    startDateMoment.isSameOrAfter(moment('2023-10-30')) &&
    featureFlags.getStringFlag('sparxweb-wac-method', '') === 'ask_answer_any_type_not_incorrect' &&
    !session?.student?.disableBookworkChecks
  );
};

import { Tooltip, TooltipProps } from '@sparx/sparx-design/components/tooltip/Tooltip';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { useOnboardingLockState } from 'context/onboardinglock';
import { ReactComponent as LockIcon } from 'images/LockSolid.svg';
import { PropsWithChildren, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavigationFrame.module.css';

const WrapWithOnboardLockTooltip = ({
  position,
  disableTooltip,
  children,
}: {
  disableTooltip?: boolean;
  position: TooltipProps['position'];
  children: React.ReactNode;
}) => {
  const { onboardingLockEnabled } = useOnboardingLockState();

  return !disableTooltip && onboardingLockEnabled ? (
    <Tooltip position={position} content="Complete the 'Introducing Sparx Maths' task to unlock">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export interface NavItem {
  text: string;
  icons: {
    default: ReactNode;
    active: ReactNode;
  };
  isActive: boolean;
  onClick: () => void;
  disableOnboardingLock?: boolean;
}

interface NavigationFrameProps {
  sideBarItems: NavItem[];
  bottomBarItems?: NavItem[];
}

export const NavigationFrame = ({
  bottomBarItems,
  sideBarItems,
  children,
}: PropsWithChildren<NavigationFrameProps>) => {
  const isSmallScreen = useBreakpoint('sm');

  return isSmallScreen ? (
    <div className={styles.FullHeight}>
      <div className={styles.SmallScreenFrame}>
        <TopBar />
        <div className={styles.Content}>{children}</div>
        {bottomBarItems && bottomBarItems.length > 0 && <BottomBar navItems={bottomBarItems} />}
      </div>
    </div>
  ) : (
    <>
      <SideBar navItems={sideBarItems} />
      <div className={styles.Content}>{children}</div>
    </>
  );
};

const NavButton = ({
  text,
  to,
  onboardingLockEnabled,
}: {
  text: string;
  to: string;
  onboardingLockEnabled?: boolean;
}) => {
  return (
    <WrapWithOnboardLockTooltip position="bottom" disableTooltip={!onboardingLockEnabled}>
      <NavLink
        className={classNames(styles.NavButton, accessibilityStyles.FocusTarget, {
          [styles.Locked]: onboardingLockEnabled,
        })}
        to={to}
        onClick={onboardingLockEnabled ? e => e.preventDefault() : undefined}
      >
        {onboardingLockEnabled && <LockIcon className={styles.LockIcon} />}
        {text}
      </NavLink>
    </WrapWithOnboardLockTooltip>
  );
};

const TopBar = () => {
  const { onboardingLockEnabled } = useOnboardingLockState();

  return (
    <div className={styles.TopBar}>
      <NavButton text="My Homework" to="/homework" />
      <NavButton
        text="Independent Learning"
        to="/independentlearning"
        onboardingLockEnabled={onboardingLockEnabled}
      />
    </div>
  );
};

const BottomBar = ({ navItems }: { navItems: NavItem[] }) => {
  const { onboardingLockEnabled } = useOnboardingLockState();

  const NavButton = ({ navItem }: { navItem: NavItem }) => {
    const isLocked = onboardingLockEnabled && navItem.text !== 'Compulsory';

    return (
      <WrapWithOnboardLockTooltip position="top" disableTooltip={navItem.disableOnboardingLock}>
        <div
          className={classNames(styles.NavButton, accessibilityStyles.FocusTarget, {
            [styles.Active]: navItem.isActive,
            [styles.Locked]: isLocked,
          })}
          onClick={e => (isLocked ? e.preventDefault() : navItem.onClick())}
        >
          <span className={styles.NavButtonIcon}>
            {navItem.isActive ? navItem.icons.active : navItem.icons.default}
          </span>

          <span className={styles.NavButtonLabel}>
            {isLocked && <LockIcon className={styles.LockIcon} color="white" />}
            {navItem.text}
          </span>
        </div>
      </WrapWithOnboardLockTooltip>
    );
  };
  return (
    <div
      className={styles.BottomBar}
      style={
        {
          ['--nav-item-count']: navItems.length,
        } as React.CSSProperties
      }
    >
      {navItems.map(navItem => (
        <NavButton key={navItem.text} navItem={navItem} />
      ))}
    </div>
  );
};

const SideBar = ({ navItems }: { navItems: NavItem[] }) => {
  const { onboardingLockEnabled } = useOnboardingLockState();

  const NavButton = ({ navItem }: { navItem: NavItem }) => {
    const isLocked = onboardingLockEnabled && navItem.text !== 'Compulsory';
    return (
      <WrapWithOnboardLockTooltip position="right" disableTooltip={navItem.disableOnboardingLock}>
        <button
          className={classNames(styles.NavButton, accessibilityStyles.FocusTarget, {
            [styles.Selected]: navItem.isActive,
            [styles.Locked]: isLocked,
          })}
          onClick={() => isLocked || navItem.onClick()}
        >
          {isLocked && <LockIcon className={styles.LockIcon} />}
          <span className={styles.NavButtonIcon} aria-hidden>
            {navItem.isActive && navItem.icons.active
              ? navItem.icons.active
              : navItem.icons.default}
          </span>
          <span className={styles.NavButtonLabel}>{navItem.text}</span>
        </button>
      </WrapWithOnboardLockTooltip>
    );
  };

  const links = navItems.map(navItem => <NavButton key={navItem.text} navItem={navItem} />);
  return <div className={styles.Sidebar}>{links}</div>;
};

import classNames from 'classnames';
import background from 'images/background_gradient.svg';
import { ReactNode } from 'react';
import { isTrainingModeEnabled } from 'utils/training';

import styles from './Page.module.css';

/**
 * Simple component for a page of Sparx Web 2
 */

export interface IPageProps {
  children?: ReactNode;
  className?: string;
  withBackground?: boolean;
}

export const Page = ({ children, className, withBackground = true }: IPageProps) => {
  return (
    <div className={classNames(styles.PageBackground, className)}>
      <div className={styles.PageGradient}>
        {withBackground && (
          <img
            aria-hidden
            src={background}
            className={classNames(styles.PageBackgroundImage, {
              [styles.PageBackgroundImageTrainingMode]: isTrainingModeEnabled(),
            })}
          />
        )}
        {children}
      </div>
    </div>
  );
};

/**
 * Simple component for a max width container of Sparx Web 2
 */

export interface IMaxWidthProps {
  children?: ReactNode;
  className?: string;
  maxWidthClassName?: string;
}

export const MaxWidth = ({ children, className, maxWidthClassName }: IMaxWidthProps) => {
  return (
    <div className={classNames(styles.MainContent, className)}>
      <div className={classNames(styles.MaxWidth, maxWidthClassName)}>{children}</div>
    </div>
  );
};

import { RpcTransport } from '@protobuf-ts/runtime-rpc';
import { AssessmentServiceClient } from '@sparx/api/apis/sparx/assessment/v1/assessment.client';
import { SearchClient } from '@sparx/api/apis/sparx/content/search/v1/search.client';
import {
  CurriculumSummariesClient,
  TopicSummariesClient,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum.client';
import { LeaderboardsClient } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards.client';
import { UserDisplayClient } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay.client';
import { SWServerClient } from '@sparx/api/apis/sparx/messaging/server/v1/server.client';
import { NotificationsClient } from '@sparx/api/apis/sparx/notifications/notifications/v1/notifications.client';
import { RevisionClient } from '@sparx/api/apis/sparx/revision/v1/revision.client';
import { SparxwebClient } from '@sparx/api/apis/sparx/swworker/v1/worker.client';
import { TrainingProgressServiceClient } from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress.client';
import { getAccessTokenProvider } from 'auth/authinterceptor';
import { getTransport } from 'auth/transport';
import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

// Load the URL from the global variable
const apiURL = window.__sparxweb.urls.api;

type Context = {
  authenticatedTransport: RpcTransport;
  swserverClient: SWServerClient;
  swworkerClient: SparxwebClient;
  curriculumSummariesClient: CurriculumSummariesClient;
  topicSummariesClient: TopicSummariesClient;
  revisionClient: RevisionClient;
  contentSearchClient: SearchClient;
  assessmentsClient: AssessmentServiceClient;
  notificationsClient: NotificationsClient;
  trainingClient: TrainingProgressServiceClient;
  leaderboardsClient: LeaderboardsClient;
  userDisplayClient: UserDisplayClient;
  getAccessToken: () => Promise<string>;
  loggedOut: boolean;
};

export const APIContext = createContext<Context | undefined>(undefined);

/**
 * Provides a APIContext which can be used to access the various API clients. It also manages the
 * access token provider, and displays the logged out dialog if the access token call fails
 */
export const APIProvider = ({ children }: PropsWithChildren) => {
  const [loggedOut, setLoggedOut] = useState(false);
  const onAccessTokenError = useCallback(() => {
    setLoggedOut(true);
  }, []);
  const accessTokenProvider = useMemo(
    () => getAccessTokenProvider(onAccessTokenError),
    [onAccessTokenError],
  );

  const authenticatedTransport = useMemo(
    () => getTransport(apiURL, accessTokenProvider),
    [accessTokenProvider],
  );
  const swserverClient = useMemo(
    () => new SWServerClient(getTransport(apiURL, accessTokenProvider, { excludeSessionId: true })),
    [accessTokenProvider],
  );
  const swworkerClient = useMemo(
    () => new SparxwebClient(getTransport(apiURL, accessTokenProvider, { withServerOffset: true })),
    [accessTokenProvider],
  );
  const curriculumSummariesClient = useMemo(
    () => new CurriculumSummariesClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );
  const topicSummariesClient = useMemo(
    () => new TopicSummariesClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );
  const revisionClient = useMemo(
    () => new RevisionClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );
  const contentSearchClient = useMemo(
    () => new SearchClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );
  const assessmentsClient = useMemo(
    () => new AssessmentServiceClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );
  const notificationsClient = useMemo(
    () =>
      new NotificationsClient(
        getTransport(apiURL, accessTokenProvider, { excludeSessionId: true }),
      ),
    [accessTokenProvider],
  );
  const trainingClient = useMemo(
    () => new TrainingProgressServiceClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );
  const leaderboardsClient = useMemo(
    () => new LeaderboardsClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );
  const userDisplayClient = useMemo(
    () => new UserDisplayClient(getTransport(apiURL, accessTokenProvider)),
    [accessTokenProvider],
  );

  const ctx = {
    authenticatedTransport,
    swserverClient,
    swworkerClient,
    curriculumSummariesClient,
    topicSummariesClient,
    revisionClient,
    contentSearchClient,
    assessmentsClient,
    notificationsClient,
    trainingClient,
    leaderboardsClient,
    userDisplayClient,
    getAccessToken: () => accessTokenProvider.requestAccessToken(),
    loggedOut,
  };

  return <APIContext.Provider value={ctx}>{children}</APIContext.Provider>;
};

import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { useVideoNudgeState } from 'context/videonudge';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';

import styles from './VideoNudgePopover.module.css';

const videoNudgeMessages: Partial<Record<string, string>> = {
  'variant-1': 'An example like this is covered in the video.',
  'variant-2': 'Watching the video will help you be successful.',
  'variant-3': 'Here is a helpful video to support you to complete this.',
  'variant-4': "Don't miss out on the chance to earn more XP. This video will help.",
  'variant-5': 'Successful students use the video to get help.',
  'variant-6':
    'Your teacher will know if you watched the video. This video will help. Watch it now.',
  'variant-7': 'Watching the video will save you time. Watch it now.',
};

type VideoNudgePopoverProps = {
  open: boolean;
  setVideoModalOpen: (open: boolean) => void;
  posterUrl: string;
};

export const VideoNudgePopover = ({
  open,
  setVideoModalOpen,
  posterUrl,
  children,
}: PropsWithChildren<VideoNudgePopoverProps>) => {
  const sendEvent = useAnalytics();

  const featureFlags = useFeatureFlags();
  const videoNudgeTextFlag = featureFlags.getStringFlag('sparxweb2-video-nudge-text', '');
  const message = useMemo(() => {
    if (videoNudgeTextFlag === 'random') {
      const messages = Object.values(videoNudgeMessages);
      return messages[Math.floor(Math.random() * messages.length)];
    }
    const message = videoNudgeMessages[videoNudgeTextFlag];
    return message ? message : 'This support video may help you to answer this question.';
  }, [videoNudgeTextFlag]);
  const title = videoNudgeTextFlag === '' ? 'Video Support' : 'Watch the video';
  const contentStyle =
    videoNudgeTextFlag === '' ? undefined : styles.VideoNudgeContentAltTitleColor;

  const { handleVideoNudgeClose } = useVideoNudgeState();
  const closeNudge = () => {
    handleVideoNudgeClose();
    sendEvent({
      action: 'video nudge closed',
      category: 'lqd',
      labels: {
        message: message || '',
        title: title,
        flag: videoNudgeTextFlag,
      },
    });
  };

  useEffect(() => {
    if (open) {
      sendEvent({
        action: 'video nudge shown',
        category: 'lqd',
        labels: {
          message: message || '',
          title: title,
          flag: videoNudgeTextFlag,
        },
      });
    }
  }, [open, sendEvent, message, title, videoNudgeTextFlag]);

  return (
    <>
      {createPortal(
        <AnimatePresence>
          {open && (
            <motion.div
              key="overlay"
              className={classNames(styles.Overlay, styles.VideoNudgeOverlay)}
              onClick={closeNudge}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>,
        document.body,
      )}
      <Popover.Root open={open}>
        <Popover.Anchor>
          <div
            className={styles.VideoNudgePopoverChildren}
            onClick={() => {
              if (open) closeNudge();
            }}
          >
            <AnimatePresence>
              {open && (
                <motion.div
                  key="bottom-bar-overlay"
                  className={classNames(styles.Overlay, styles.BottomBarOverlay)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              )}
            </AnimatePresence>
            {children}
          </div>
        </Popover.Anchor>
        <Popover.Portal forceMount>
          <div>
            <AnimatePresence>
              {open && (
                <Popover.Content sideOffset={24} asChild>
                  {/* video nudge container has the box shadow, so it doesn't spill onto the arrow */}
                  <motion.div
                    key="container"
                    className={styles.VideoNudgeContainer}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                  >
                    <div className={classNames(styles.VideoNudgeContent, contentStyle)}>
                      <h3>{title}</h3>
                      <span>{message}</span>
                      <div className={styles.VideoNudgePosterContainer}>
                        <img className={styles.VideoNudgePoster} src={posterUrl}></img>
                        <div
                          className={styles.VideoNudgePlayArrow}
                          onClick={() => {
                            closeNudge();
                            setVideoModalOpen(true);
                            sendEvent({
                              action: 'video nudge play button clicked',
                              category: 'lqd',
                              labels: {
                                message: message || '',
                                title: title,
                                flag: videoNudgeTextFlag,
                              },
                            });
                          }}
                        >
                          {/* this div is the play arrow */}
                          <div />
                        </div>
                      </div>
                      <span className={styles.Link} onClick={closeNudge}>
                        Close
                      </span>
                    </div>
                    <div className={styles.VideoNudgeArrow} />
                  </motion.div>
                </Popover.Content>
              )}
            </AnimatePresence>
          </div>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};

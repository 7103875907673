import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { CaretRight } from '../../icons';
import styles from './Breadcrumb.module.css';

/**
 * Breadcrumb navigation component
 */

export interface IBreadcrumbItem {
  // Text to display on the section of the breadcrumb
  label: string;
  // Path to navigate to when the item is clicked
  path?: string;
}

export interface IBreadcrumbProps {
  // A list of items to show in the breadcrumb, from left to right
  items: IBreadcrumbItem[];
  // Extra css class for the root element
  className?: string;
}

export const Breadcrumb = ({ items, className }: IBreadcrumbProps) => (
  <nav className={className}>
    {items.map((item, idx) => (
      <span key={idx}>
        {item.path ? (
          <Link className={styles.BreadcrumbLink} to={item.path}>
            {item.label}
          </Link>
        ) : (
          <span className={styles.BreadcrumbText}>{item.label}</span>
        )}
        {idx < items.length - 1 && (
          <CaretRight className={classNames(styles.BreadcrumbCaret, 'fa-solid')} />
        )}
      </span>
    ))}
  </nav>
);

import { motion } from 'framer-motion';
import React from 'react';

import styles from './BottomBar.module.css';
import { BottomBarId } from './bottomBarId';

interface IBottomBarProps {
  children?: React.ReactNode;
}

// BottomBar places up to the children in a row. If the a button is not wanted, pass an empty
// element to place in that position.
export const BottomBar = ({ children }: IBottomBarProps) => (
  // give the bottom bar an id so that it can be easily found. Used for sizing the nav bar * in <NavigationBarFixup />
  <div className={styles.BottomBar} id={BottomBarId}>
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {children}
    </motion.div>
  </div>
);

import { createContext, PropsWithChildren, useEffect, useState } from 'react';

type Context = {
  enabled: boolean;
};

export const KeyboardModeContext = createContext<Context | undefined>(undefined);

/**
 * Provides a KeyboardModeContext which tracks whether or not keyboard mode is enabled.
 *
 * This is determined by watching to Tab key events. Once a tab key is detected, keyboard mode is enabled.
 * Keyboard mode will be disabled again on a click event.
 */
export const KeyboardModeProvider = ({ children }: PropsWithChildren) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const aborter = new AbortController();

    if (enabled) {
      document.addEventListener('click', () => setEnabled(false), { signal: aborter.signal });
    } else {
      document.addEventListener('keydown', e => e.key === 'Tab' && setEnabled(true), {
        signal: aborter.signal,
      });
    }
    return () => aborter.abort();
  }, [enabled]);

  const ctx = {
    enabled,
  };

  return <KeyboardModeContext.Provider value={ctx}>{children}</KeyboardModeContext.Provider>;
};

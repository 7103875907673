import { Notification } from '@sparx/api/apis/sparx/notifications/notifications/v1/notifications';
import { getNotificationIDFromNotificationName } from '@sparx/resource-names';

// NotificationType string values should not include hyphens
// the names of these must match the values of the corresponding server side definitions found in
// notifications/notifications/internal/types/notification_types.go
export enum NotificationType {
  WelcomeToNotifications,
  BouncingBack,
  EarliestCompleter,
  WelcomeToSparxWeb2,
  FTQEnabled,
  ColourOverlaysEnabled,
  WhatsNew,
}

export const notificationTypesWithLevel = [NotificationType.EarliestCompleter];

export const notificationTypesWithStringAdditionalInfo = [NotificationType.WhatsNew];

/**
 * getNotificationType returns the notification type of the given notification
 * a notification id is made up of {notificationType}-{additionalInfo}
 * eg. WelcomeToNotifications-2022_06_01
 * we return the notification type along with the parsed level for notifications which have one
 * @param notification
 */
export const getNotificationType = (
  notification: Notification,
): { type?: NotificationType; level?: number; additionalInfoString?: string } => {
  const notificationID = getNotificationIDFromNotificationName(notification.name);
  if (!notificationID) {
    return {};
  }

  // regex to split on the fist instance of '-'
  // notification types will not include a hyphen, so this will split the id into notification
  // and additional info sections
  const splitRegex = /^([^-]*)(?:-(.*))?$/g;

  // first return is the whole match, which we discard, we care about the captured groups
  const [, notificationTypeString, additionalInfo] = splitRegex.exec(notificationID) || [];
  if (!isNotificationType(notificationTypeString)) {
    return {};
  }

  // convert the string into the relevant enum, using the notificationTypeString if the regex
  // matched, otherwise defaulting to the whole notification id (for cases where there is no
  // additional info)
  const notificationType: NotificationType | undefined = NotificationType[notificationTypeString];

  // get the additional info that we care about it
  // other additional info needed by new notifications should be added here
  let level: number | undefined = undefined;
  let additionalInfoString: string | undefined = undefined;
  if (notificationType) {
    if (notificationTypesWithLevel.includes(notificationType)) {
      const additionalInfoParts = additionalInfo.split(':');
      level = parseInt(additionalInfoParts.length === 2 ? additionalInfoParts[0] : additionalInfo);
    }
    if (notificationTypesWithStringAdditionalInfo.includes(notificationType)) {
      additionalInfoString = additionalInfo;
    }
  }

  return {
    type: notificationType,
    level: level,
    additionalInfoString: additionalInfoString,
  };
};

const isNotificationType = (
  maybeNotification: string,
): maybeNotification is keyof typeof NotificationType => {
  return Object.keys(NotificationType).indexOf(maybeNotification) !== -1;
};

import { Button } from '@sparx/sparx-design/components';
import { Tick } from '@sparx/sparx-design/icons';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { makeTaskItemPath, useLQDPath } from 'utils/paths';
import { QuestionAnswerLayout } from 'views/question/QuestionAnswerLayout';

import BottomBar from '../lqd/bottom-bar';
import styles from './CorrectQuestionOverlay.module.css';

interface ICorrectQuestionOverlayProps {
  packageID: string;
  taskIndex: number;
  taskItemIndex: number;
  packageType: string;
  nextTaskItemPath: string;
  isTaskComplete: boolean;
}

export const CorrectQuestionOverlay: FunctionComponent<ICorrectQuestionOverlayProps> = ({
  packageID,
  taskIndex,
  taskItemIndex,
  packageType,
  nextTaskItemPath,
  isTaskComplete,
}) => {
  const { parentPath } = useLQDPath();
  const retryQuestion =
    makeTaskItemPath(parentPath, packageID, taskIndex, taskItemIndex) +
    `?retry_index=${taskItemIndex}`;

  const showRetryButton = !isTaskComplete || packageType === 'revision';

  const content = (
    <>
      <div className={styles.Container}>
        <div className={styles.Section}>
          <Tick variant="White" className={styles.ResultImage} />
          <p className={styles.Message}>You have already got this question correct. </p>
        </div>
        <div className={styles.Buttons}>
          {showRetryButton && (
            <Button
              size="md"
              as={Link}
              to={retryQuestion}
              // BOOL-3095 - page event removed to reduce Cloudflare traffic
              // onClick={() => {
              //   sendEvent({
              //     action: 'clicked retry question from correct question overlay',
              //     category: 'lqd',
              //   });
              // }}
            >
              Retry
            </Button>
          )}
          <Button
            size="md"
            as={Link}
            to={nextTaskItemPath}
            variant="contained"
            // BOOL-3095 - page event removed to reduce Cloudflare traffic
            // onClick={() => {
            //   sendEvent({
            //     action: 'clicked keep going from correct question overlay',
            //     category: 'lqd',
            //   });
            // }}
          >
            Keep going
          </Button>
        </div>
      </div>
    </>
  );

  return <QuestionAnswerLayout content={content} bottomBar={<BottomBar />} />;
};

import { useMemo } from 'react';
import { useFeatureFlags } from 'utils/feature-flags';

import { ReactComponent as SwapIcon } from './icons/icon-swap.svg';
import styles from './QuestionBanner.module.css';

export type BannerData = {
  content: React.ReactNode;
  showAfterBookworkCode?: boolean;
  className?: string;
};

export const useBannerData = (
  isItemAttempted: boolean,
  isItemOnNonInitialChance: boolean,
  isItemSwapped: boolean,
  isCombinedQuestionAnswer: boolean,
): BannerData => {
  const featureFlags = useFeatureFlags();
  const showSecondChanceBanner =
    !featureFlags.getBooleanFlag('sparxweb2-three-attempts', false) &&
    !featureFlags.getBooleanFlag('sparxweb2-question-swaps', false);

  const data = useMemo(() => {
    if (!isItemAttempted) {
      if (isItemSwapped) {
        return {
          content: (
            <p>
              <SwapIcon className={styles.SwapIcon} />
              <b>We have swapped this to an easier question. </b>
            </p>
          ),
          className: styles.QuestionSwapped,
        };
      }
    } else if (isItemOnNonInitialChance) {
      if (showSecondChanceBanner) {
        return {
          content: (
            <p>
              <b>Second Chance!</b> Review your working and see if you can correct your mistakes
            </p>
          ),
        };
      }
    } else if (!isCombinedQuestionAnswer) {
      return {
        content: (
          <p>
            <b>This is a new version of the question.</b> Make sure you start new workings.
          </p>
        ),
        className: showSecondChanceBanner ? undefined : styles.NewQuestion,
        showAfterBookworkCode: !showSecondChanceBanner,
      };
    }
    return { content: null };
  }, [
    isItemAttempted,
    isItemOnNonInitialChance,
    isItemSwapped,
    showSecondChanceBanner,
    isCombinedQuestionAnswer,
  ]);
  return data;
};

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { NotificationAndDisplayData } from '@sparx/api/apis/sparx/notifications/notifications/v1/notifications';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { getNotificationType } from 'components/notification-list/common';
import { NotificationListItem } from 'components/notification-list/notification-list-item';
import { getNotificationDisplayData } from 'components/notification-list/notificationDisplayData';
import { useWhatsNewEntries } from 'components/whats-new/entries';
import { AnimatePresence, motion } from 'framer-motion';
import notificationIcon from 'images/notifications/IconRead.svg';
import notificationIconUnread from 'images/notifications/IconUnread.svg';
import {
  useListFilteredNotificationsAndDisplayData,
  useMarkNotificationsRead,
} from 'queries/notifications';
import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';

import styles from './NotificationList.module.css';

/**
 * Notification List is a component which handles fetching data and rendering of both the
 * notifications icon, and notification list when opened.
 */
export const NotificationList = () => {
  const analytics = useAnalytics();
  const navigate = useNavigate();

  /** open stores what state the dropdown is in, and controls the dropdown's state. */
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: serverNotificationsAndDisplayData,
    isLoading,
    isError,
  } = useListFilteredNotificationsAndDisplayData();

  const { mutate: markNotificationsRead } = useMarkNotificationsRead();

  const flags = useFeatureFlags().getAllFlags();

  const whatsNewEntries = useWhatsNewEntries();
  const notificationsAndDisplayData = useMemo(() => {
    const notificationsAndDisplayData: NotificationAndDisplayData[] = [];
    serverNotificationsAndDisplayData?.forEach(notificationAndDisplayData => {
      if (!notificationAndDisplayData.notification) {
        return;
      }
      if (!notificationAndDisplayData.displayData) {
        const { type, level, additionalInfoString } = getNotificationType(
          notificationAndDisplayData.notification,
        );
        if (type === undefined) {
          return;
        }
        notificationAndDisplayData.displayData = getNotificationDisplayData(
          whatsNewEntries,
          flags,
          type,
          level,
          additionalInfoString,
        );
      }
      if (!notificationAndDisplayData.displayData) {
        return;
      }
      notificationsAndDisplayData.push(notificationAndDisplayData);
    });
    return notificationsAndDisplayData;
  }, [flags, serverNotificationsAndDisplayData, whatsNewEntries]);

  // cap the number of unread to 9 to prevent the number spilling out of the red circle
  const numUnread = Math.min(
    notificationsAndDisplayData?.filter(ndd => !ndd.notification?.read).length || 0,
    9,
  );

  const markNotificationsReadIfNeeded = useRef(() => {
    /* empty */
  });

  markNotificationsReadIfNeeded.current = () => {
    if (isOpen) {
      const notificationNamesToUpdate: string[] = [];
      notificationsAndDisplayData?.forEach(ndd => {
        if (!ndd.notification) {
          return;
        }
        if (!ndd.notification.read) {
          notificationNamesToUpdate.push(ndd.notification.name);
        }
      });
      if (notificationNamesToUpdate.length > 0) {
        markNotificationsRead({ names: notificationNamesToUpdate });
      }
    }
  };

  const notificationListContent: React.ReactElement[] = [];
  if (isLoading) {
    notificationListContent.push(
      <DropdownMenuItem key={'loading'} itemKey={'loading'} className={styles.LoadingContainer}>
        <LoadingSpinner size="lg" />
      </DropdownMenuItem>,
    );
  } else if (isError) {
    notificationListContent.push(
      <DropdownMenuItem key={'error'} itemKey={'error'}>
        <NotificationListItem
          title="Error Loading Notifications"
          message="Please try again later."
          read={true}
        />
      </DropdownMenuItem>,
    );
  } else if (notificationsAndDisplayData.length == 0) {
    notificationListContent.push(
      <DropdownMenuItem key="empty" itemKey="empty">
        <NotificationListItem
          title="No notifications yet"
          message="We'll let you know about your activity here."
          read={true}
        />
      </DropdownMenuItem>,
    );
  } else {
    notificationsAndDisplayData?.forEach((ndd, i, ndds) => {
      const { notification, displayData } = ndd;
      if (!notification || !displayData) {
        return;
      }
      notificationListContent.push(
        <DropdownMenuItem key={notification.name} itemKey={notification.name}>
          <NotificationListItem
            title={displayData.title}
            message={displayData.message}
            createdTime={notification.createdTime}
            read={notification.read}
            image={displayData.image}
            onClick={
              displayData.link
                ? () => {
                    navigate(displayData.link || '');
                    setIsOpen(false);
                    markNotificationsReadIfNeeded.current();
                  }
                : undefined
            }
          />
        </DropdownMenuItem>,
      );
      if (i < ndds.length - 1) {
        notificationListContent.push(
          <DropdownMenu.Separator
            className={classNames({
              [styles.Separator]: true,
            })}
            key={`sep-${notification.name}`}
          />,
        );
      }
    });
  }

  return (
    <DropdownMenu.Root
      modal={false}
      open={isOpen}
      onOpenChange={s => {
        setIsOpen(s);
        analytics({
          category: 'notifications',
          action: s ? 'opened notifications list' : 'closed notifications list',
        });
        if (!s) {
          markNotificationsReadIfNeeded.current();
        }
      }}
    >
      <DropdownMenu.Trigger asChild className={accessibilityStyles.FocusTarget}>
        <button
          className={styles.NotificationsIconContainer}
          role="button"
          aria-label="Show Notifications"
        >
          <img aria-hidden src={numUnread > 0 ? notificationIconUnread : notificationIcon} />
          <NumUnread numUnread={numUnread} />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.NotificationList} align="end">
          {notificationListContent}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const NumUnread = ({ numUnread }: { numUnread: number }) => {
  return (
    <AnimatePresence>
      {numUnread > 0 && (
        <motion.span
          className={styles.UnreadCount}
          key={'number-unread'}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0, opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
        >
          {numUnread}
        </motion.span>
      )}
    </AnimatePresence>
  );
};

/**
 * DropdownMenuItem is a wrapper around the Radix DropdownMenu.Item component. It provides the class
 * name and onclick that all items in the notification list should have.
 */
const DropdownMenuItem = ({
  children,
  className,
  itemKey,
}: PropsWithChildren<{ className?: string; itemKey: string }>) => (
  <DropdownMenu.Item
    key={itemKey}
    className={classNames(styles.MenuItem, className)}
    // stop the notification list from closing on click
    onClick={e => e.preventDefault()}
  >
    {children}
  </DropdownMenu.Item>
);

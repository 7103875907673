import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';

import styles from './BackButton.module.css';

interface IBackButtonProps {
  to: string | (() => void);
}

/**
 * The back button on the Header bar.
 */
export const BackButton = ({ to }: IBackButtonProps) => {
  const sendEvent = useAnalytics();
  const commonProps = {
    className: styles.BackButton,
    onClick: () => {
      if (typeof to !== 'string') {
        to();
      }
      sendEvent({
        action: 'clicked back button',
        category: 'header',
      });
    },
  };

  const icon = <FontAwesomeIcon icon={faChevronLeft} className={styles.BackButtonIcon} />;

  return typeof to === 'string' ? (
    <Link to={to} {...commonProps}>
      {icon}
    </Link>
  ) : (
    <button {...commonProps}>{icon}</button>
  );
};

/**
 * The back button on the Header bar which will animate in and out
 * if to is defined.
 */
export const AnimatedBackButton = ({ to }: Partial<IBackButtonProps>) => (
  <AnimatePresence>
    {to && (
      <motion.div
        initial={{ width: 0, opacity: 0, translateX: -20 }}
        animate={{ width: 'auto', opacity: 1, translateX: 0 }}
        exit={{ width: 0, opacity: 0, translateX: -20 }}
        transition={{ type: 'spring', bounce: 0 }}
      >
        <BackButton to={to} />
      </motion.div>
    )}
  </AnimatePresence>
);

import { NavigationFrame } from 'app/NavigationFrame';
import { useNavLinks } from 'app/NavigationFrame/use-nav-links';
import { Page } from 'components/page/Page';
import { Navigate } from 'react-router-dom';
import { useFeatureFlags } from 'utils/feature-flags';
import { OverlayColourSettings } from 'views/settings-page/components';

import styles from './SettingsPage.module.css';

export const SettingsPage = () => {
  const flags = useFeatureFlags();
  const settingsEnabled = flags.getBooleanFlag('sparxweb2-settings-page', false);
  const navItems = useNavLinks();

  if (!settingsEnabled) {
    return <Navigate to="/" />;
  }

  return (
    <Page>
      <NavigationFrame sideBarItems={navItems} bottomBarItems={navItems}>
        <div className={styles.Container}>
          <h1>Settings</h1>
          <section>
            <h2>Display</h2>
            <p>You can apply a coloured overlay that may help you read questions.</p>
            <div className={styles.SectionContainer}>
              <OverlayColourSettings />
            </div>
          </section>
        </div>
      </NavigationFrame>
    </Page>
  );
};

import { TablesAnswer } from '@sparx/api/apis/sparx/games/progress/tablesanswer/v1/tablesanswer';
import { Task } from '@sparx/api/sparxweb/swmsg/sparxweb';

import { IAvailabilityState } from './utils';

/**
 * Returns the following as an object:
 *  hundredClubAllowed: true if the user should be able to play 100 Club
 *  gamesAllowed:       true if the user should be able to play other games
 *  hundredClubMessage: the string to display below the 100 Club button
 *  hundredClubLockedAdvice: the string that will display in an alert if they try to select
 *                           100 Club but it is locked
 *  gameLockedAdvice:   the string that will display in an alert if they try to select
 *                       a game but games are locked
 *
 *  We have the option to change the advice text in future to tell the
 *  student why 100 Club or the games are locked
 *
 *  Based on the following rules:
 *      - If the player has no targets, they can only play 100 Club
 *      - If the player has not finished first pass, they can only play 100 Club
 *      - If there are no TaL games available (due to token count) the player
 *        can only play 100 Club
 *      - If the player has games awaiting TaL, the player can only play games
 *      - If the player has no games awaiting TaL, they can only play 100 Club,
 *        unless:
 *              a) they cleared their TaL list today
 *              b) they got a perfect score at the top club
 *              In these cases, both games and 100 Club are available
 */

export const getHundredClubAvailability = (
  targets: string[],
  hasClearedFirstPass: boolean,
  hasPendingTalkAndLearn: boolean,
  clearedPendingListToday: boolean,
  hasCompletedAllClubs: boolean,
  hasPendingSpeedChallenge: boolean,
  gamesAvailableForTaL: boolean,
  abilityValidationAllows100Club: boolean,
  abilityValidationIsEnabled: boolean,
) => {
  if (abilityValidationIsEnabled) {
    if (!abilityValidationAllows100Club) {
      return {
        hundredClubAllowed: false,
        gamesAllowed: true,
        hundredClubMessage: 'Answer your times tables quickly to be able to try 100 Club.',
        hundredClubLockedAdvice: 'Play Sticker Collector to learn how the games work.',
      };
    }

    const hasTargets = targets && targets.length > 0;
    if (!hasTargets) {
      return {
        hundredClubAllowed: true,
        gamesAllowed: false,
        gameLockedAdvice: `Click on 100 Club to begin finding your level.`,
        hundredClubMessage: 'Now have a go at 100 Club before continuing the games.',
      };
    }
  } else {
    const hasTargets = targets && targets.length > 0;
    if (!hasTargets) {
      return {
        hundredClubAllowed: true,
        gamesAllowed: false,
        gameLockedAdvice: `Click on 100 Club to begin finding your level.`,
        hundredClubMessage: 'You need to play 100 Club before you can play the games.',
      };
    }
  }

  const hasTargets = targets && targets.length > 0;
  if (!hasTargets) {
    return {
      hundredClubAllowed: true,
      gamesAllowed: false,
      gameLockedAdvice: `Click on 100 Club to begin finding your level.`,
      hundredClubMessage: 'Now have a go at 100 Club before continuing the games.',
    };
  }

  if (!hasClearedFirstPass) {
    return {
      hundredClubAllowed: true,
      gamesAllowed: false,
      gameLockedAdvice: `Click on 100 Club to begin finding your level.`,
      hundredClubMessage: 'You need to find your 100 Club level before you can play the games.',
    };
  }

  if (!gamesAvailableForTaL) {
    return {
      hundredClubAllowed: true,
      gamesAllowed: false,
      gameLockedAdvice: `Click on 100 Club to see if you've improved your level.`,
      hundredClubMessage: 'Keep doing 100 Club to get your first game.',
    };
  }

  if (hasPendingTalkAndLearn) {
    return {
      hundredClubAllowed: false,
      gamesAllowed: true,
      hundredClubMessage:
        'Continue to play games or do exercises to complete your task then come back to do a 100 Club Check again.',
      hundredClubLockedAdvice:
        'You need to play some games before you can try the 100 Club test again. Try playing Sticker Collector.',
    };
  }

  if (clearedPendingListToday || hasCompletedAllClubs) {
    // If the player has cleared 100 Club but not all speed challenges,
    // force them to do 100 Club once per week
    let gamesAllowed = true;
    let hundredClubAllowed = true;
    let hundredClubMessage =
      'Continue to play games or do exercises to complete your task then come back to do a 100 Club Check again.';
    let gameLockedAdvice = null;

    if (hasCompletedAllClubs) {
      if (hasPendingSpeedChallenge) {
        gamesAllowed = false;
        hundredClubAllowed = true;
        hundredClubMessage = 'Try to beat the quiz with a faster timer.';
        gameLockedAdvice = `It's time to test your times tables level. Try doing 100 Club.`;
      }
    }

    return {
      hundredClubAllowed,
      gamesAllowed,
      gameLockedAdvice,
      hundredClubMessage,
    };
  } else {
    return {
      hundredClubAllowed: true,
      gamesAllowed: false,
      gameLockedAdvice: 'Try doing 100 Club.',
      hundredClubMessage: "It's time to test your times tables level. Try doing 100 Club.",
    };
  }
};

// Return true if the student is in a state where they have completed their special assessment
// but the task shows as incomplete.
//
// A student will end up in this state if the message to say the assessment is completed is
// successful but the message to add 25 points of progress is unsuccessful
//
// If we detect this state we should send a questionAnswered message to the server
// with the "isEndOfQuiz" value set to true, so it knows to add progress points for this task
export const shouldEnforeSpecialAssessmentCompletion = (
  task: Task,
  availability: IAvailabilityState,
) => {
  const taskIsIncomplete =
    task &&
    task.completion &&
    task.completion.progress &&
    task.completion.size > (task.completion.progress['C'] || 0);
  const shouldOverrideSpecialAssessmentAccess =
    taskIsIncomplete &&
    availability.taskIsSpecialAssessment &&
    availability.isSpecialAssessmentOnly &&
    availability.hasCompletedSpecialAssessment;
  return shouldOverrideSpecialAssessmentAccess;
};

// When sent to the server this will trigger game_activity_runner.go
// to add enough progress to this user to complete the task
// Must have an answerTime timestamp added to it before use
export const SPECIAL_ASSESSMENT_OVERRIDE_ANSWER: TablesAnswer = {
  // The following data need to be here for the fix to work
  context: 5, // corresponds to 'SPECIAL_ASSESSMENT'
  correct: true,
  isEndOfQuiz: true,
  // The following data are here so typscript doesn't complain
  questionText: 'special-assessment-override',
  answerText: 'special-assessment-override',
  timedOut: false,
  timeTaken: 0,
  game: '100club',
  enterCorrectionPhase: false,
  leaveCorrectionPhase: false,
  inputString: 'special-assessment-override',
  questionGap: 0,
  badData: false,
  questionSetID: 'tables',
  deliveryMechanism: 'special-assessment-override',
  target: false,
  numPendingTalkAndLearns: 0,
  didNotKnow: false,
  indexWithinQuiz: 0,
  talPromptType: '',
  secondChance: false,
  talCycleCount: 0,
  indexWithinGameSession: 0,
};

import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { ModuleCompleteSpec } from 'types/training';

import styles from './TrainingBanner.module.css';

/**
 * ModuleComplete appears in the TrainingModeBox when the current module is complete. It shows
 * extra summary information.
 */

interface IModuleCompleteProps {
  // Module complete informtion such as a header and summary points
  moduleComplete: ModuleCompleteSpec;
  // Whether to hide the heading
  hideHeading?: boolean;
  // Function to call when "Back to teacher Portal" linked is clicked. Allows referrer to be set
  // for analytics purposes.
  handleBackToTeacherPortal: (location: string) => void;
  // Whether this is showing:
  showing: boolean;
}

export const ModuleComplete = ({
  moduleComplete,
  hideHeading,
  handleBackToTeacherPortal,
  showing,
}: IModuleCompleteProps) => (
  <div className={styles.TrainingModeModuleComplete}>
    {!hideHeading && (
      <h2 className={styles.TrainingModeModuleCompleteHeading}>{moduleComplete.heading}</h2>
    )}
    {moduleComplete.points.map(point => (
      <p key={point}>{point}</p>
    ))}
    {moduleComplete.returnToTP && (
      <button
        className={classNames(
          styles.TrainingModeModuleCompleteButton,
          accessibilityStyles.FocusTarget,
        )}
        onClick={() => handleBackToTeacherPortal('menu')}
        tabIndex={showing ? 0 : -1}
      >
        {' '}
        Return to Teacher Portal
      </button>
    )}
  </div>
);

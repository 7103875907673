import { TaskItemCompletion, TaskItemStatus } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './IndependentLearningTopicView.module.css';

interface IIndependentLearningTaskItemProps {
  taskItem: TaskItemCompletion;
}

/**
 * IndependentLearningTaskItem is an Independent Learning Task Item for the current Topic. It shows
 * its current status and allows the student to click on it to attempt it.
 * @param taskItem Information about the student's completion of the Task Item.
 */

export const IndependentLearningTaskItem = ({ taskItem }: IIndependentLearningTaskItemProps) => {
  const navigate = useNavigate();

  const taskItemStatus = taskItem.status;

  let pillText = 'Answer';
  if (taskItemStatus === TaskItemStatus.DONE) {
    pillText = 'Correct';
  } else if (
    taskItemStatus === TaskItemStatus.WRONG ||
    taskItemStatus === TaskItemStatus.WRONGWITHVIDEONUDGE
  ) {
    pillText = 'Retry';
  } else if (taskItemStatus === TaskItemStatus.SEEKHELP) {
    pillText = 'Seek Help';
  }
  const pillClasses = classNames(styles.Pill, {
    [styles.Done]: pillText === 'Correct',
    [styles.Wrong]: pillText === 'Retry',
    [styles.SeeTeacher]: pillText === 'Seek Help',
  });

  return (
    <li
      onClick={() =>
        navigate(
          `package/${taskItem.packageID}/task/${taskItem.taskIndex}/item/${taskItem.taskItemIndex}`,
        )
      }
      className={classNames(styles.IndependentLearningTaskItem, {
        [styles.IndependentLearningTaskItemDone]: taskItemStatus === TaskItemStatus.DONE,
      })}
    >
      <div className={styles.IndependentLearningTaskItemName}>{taskItem.name}</div>
      <div className={pillClasses}>{pillText}</div>
    </li>
  );
};

/**
 * IndependentLearningTaskItemDummy is a dummy task item used for layout purposes.
 * See comment in IndependentLearningTask for more info.
 */
export const IndependentLearningTaskItemDummy = () => (
  <li className={styles.IndependentLearningTaskItemDummy} />
);

import {
  Notification,
  NotificationAndDisplayData,
  NotificationOwnerType,
} from '@sparx/api/apis/sparx/notifications/notifications/v1/notifications';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAPI } from 'context/api';
import { useIsPrimaryStudent } from 'utils/session';

import { getNotificationType } from '../../components/notification-list/common';

export const useListNotificationsKey = 'notifications/listNotifications';
export const useListNotificationsAndDisplayDataKey = 'notifications/getNotificationsAndDisplayData';

/**
 * useListNotifications is a query that fetches the notifications for the currently logged in user.
 */
const useListNotifications = (options?: UseQueryOptions<Notification[], Error>) => {
  const { notificationsClient } = useAPI();
  const schoolID = window.__sparxweb.schoolID;
  return useQuery<Notification[], Error>(
    [useListNotificationsKey],
    () =>
      notificationsClient
        .listNotifications({
          pageSize: 9,
          ownerType: NotificationOwnerType.STUDENT,
          schoolId: schoolID,
        })
        .response.then(res => res.notifications),
    options,
  );
};

/**
 * selectDisplayNotifications transforms the ListNotificationsResponse from Notifications into an
 * array of filtered notifications for display to the user.
 * it removes:
 *  - Notifications with undefined type
 */
const selectDisplayNotifications = (notifications: Notification[]) => {
  return notifications
    .filter(notification => {
      const { type } = getNotificationType(notification);
      if (type === undefined) {
        return false;
      }
      return true;
    })
    .sort((a, b) => (b.createdTime?.seconds || 0) - (a.createdTime?.seconds || 0));
};

/**
 * useListFilteredNotifications returns the a filtered list of notifications for
 * display to the user.
 * This hook should be used when displaying notifications to users, and uses a query with a stale
 * time of 1 hour.
 * Disabled for primary students.
 */
export const useListFilteredNotifications = () => {
  const isPrimaryStudent = useIsPrimaryStudent();

  return useListNotifications({
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    select: selectDisplayNotifications,
    enabled: !isPrimaryStudent,
  });
};

/**
 * useListNotifications is a query that fetches the notifications for the currently logged in user.
 */
const useListNotificationsAndDisplayData = (
  options?: UseQueryOptions<NotificationAndDisplayData[], Error>,
) => {
  const schoolID = window.__sparxweb.schoolID;
  const { notificationsClient } = useAPI();
  return useQuery<NotificationAndDisplayData[], Error>(
    [useListNotificationsAndDisplayDataKey],
    () =>
      notificationsClient
        .listNotificationsAndDisplayData({
          pageSize: 9,
          ownerType: NotificationOwnerType.STUDENT,
          schoolId: schoolID,
        })
        .response.then(res => res.notificationsAndDisplayData),
    options,
  );
};

/**
 * useGetFilteredNotificationsAndDisplayData returns the a filtered list of notifications and display
 * data for display to the user.
 * This hook should be used when displaying notifications to users, and uses a query with a stale
 * time of 1 hour.
 * Disabled for primary students.
 */
export const useListFilteredNotificationsAndDisplayData = () => {
  const isPrimaryStudent = useIsPrimaryStudent();

  return useListNotificationsAndDisplayData({
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    // the select here for list notifications removes ones without a type, this should already be done
    // on the server
    select: ndds => ndds.filter(ndd => ndd.notification !== undefined),
    enabled: !isPrimaryStudent,
  });
};

import * as Dialog from '@radix-ui/react-dialog';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Button } from '@sparx/sparx-design/components';
import { TimesIcon } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { useWhatsNewEntries } from 'components/whats-new/entries';
import {
  getLastSeenIndex,
  lastSeenWhatsNewEntryIDKey,
  makeEntryID,
  useLastSeenEntryId,
  usePromotedWhatsNewEntry,
} from 'components/whats-new/hooks';
import { WhatsNewEntryBody } from 'components/whats-new/WhatsNewEntry';
import { useUpdateStudentLabels } from 'queries/studentlabels';
import { useState } from 'react';
import { useIsPrimaryStudent } from 'utils/session';

import styles from './PromotedWhatsNewModal.module.css';

export const PromotedWhatsNewModal = () => {
  const promotedWhatsNew = usePromotedWhatsNewEntry();
  const lastSeenId = useLastSeenEntryId();
  const isPrimaryStudent = useIsPrimaryStudent();
  const whatsNewEntries = useWhatsNewEntries();

  const { mutate: updateStudentLabels } = useUpdateStudentLabels();

  // store this in a boolean so we have a stable value to use in the effect below
  const promotedWhatsNewPresent = !!promotedWhatsNew;

  // work out if we should show the promoted modal
  // we show it if we have a promoted entry, a last seen id, and the promoted posts index is less than the last
  // seen posts index
  // we don't need to handle this loading, its part of the getSessionDetailsCall
  const lastSeenIndex = lastSeenId ? getLastSeenIndex(whatsNewEntries, lastSeenId) : -1;
  const [dialogOpen, setDialogOpen] = useState<boolean>(
    promotedWhatsNewPresent && lastSeenIndex > promotedWhatsNew.index,
  );

  if (isPrimaryStudent) {
    return null;
  }

  const handleDialogOpenChange = (open: boolean) => {
    setDialogOpen(open);
    if (!open) {
      if (promotedWhatsNewPresent) {
        updateStudentLabels({
          [lastSeenWhatsNewEntryIDKey]: makeEntryID(promotedWhatsNew.entry),
        });
      }
    }
  };

  if (!promotedWhatsNew?.entry.promoted) {
    return null;
  }

  const { title, subtitle, promoted } = promotedWhatsNew.entry;
  const { body } = promoted;
  return (
    <Dialog.Root onOpenChange={handleDialogOpenChange} open={dialogOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullWidth,
            dialogStyles.ContentHeight,
          )}
          onPointerDownOutside={e => e.preventDefault()}
          onInteractOutside={e => e.preventDefault()}
        >
          {title && <Dialog.Title className={dialogStyles.DialogTitle}>{title}</Dialog.Title>}
          {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
          <div className={styles.ContentContainer}>
            <WhatsNewEntryBody body={body} disableVideoForwardSeeking />
          </div>
          <div className={dialogStyles.DialogButtonsContainer}>
            <Button as={Dialog.Close} rightIcon={<TimesIcon />} onKeyDown={simulateClickOnEnter}>
              Close
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { useOnboardingLockState } from 'context/onboardinglock';
import { useTraining } from 'context/training';
import { useMemo } from 'react';

import { getFilteredSortedPackages } from './utils';

export const useFilteredSortedPackages = (
  allPackages: PackageCompletion[] | undefined,
  selectedPackageType: string,
) => {
  const { onboardingLockEnabled } = useOnboardingLockState();
  const { enabled: trainingEnabled } = useTraining();

  const showOnboardingPackages = selectedPackageType === 'homework';

  return useMemo(
    () =>
      getFilteredSortedPackages(
        allPackages,
        selectedPackageType,
        showOnboardingPackages,
        onboardingLockEnabled,
        trainingEnabled,
      ),
    [
      allPackages,
      selectedPackageType,
      showOnboardingPackages,
      onboardingLockEnabled,
      trainingEnabled,
    ],
  );
};

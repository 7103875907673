import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OpinionRating, Rating } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import classNames from 'classnames';
import { useState } from 'react';
import { Module } from 'types/training';
import { useAnalytics } from 'utils/analytics';

import { submitFeedbackEvent } from './analytics';
import styles from './TrainingBanner.module.css';

/**
 * TrainingFeedback shows the users some feedback buttons (thumbs up and thumbs down) which can be
 * clicked to send an analytics event with their feedback information.
 */

interface ITrainingFeedbackProps {
  // The training module being completed by the user
  currentModule: Module;
  // Whether this is showing
  showing: boolean;
}

export const TrainingFeedback = ({ currentModule, showing }: ITrainingFeedbackProps) => {
  const sendEvent = useAnalytics();
  const [rating, setRating] = useState<Rating>({
    opinion: OpinionRating.UNKNOWN,
  });

  const submitFeedback = (isLiked: boolean) => {
    setRating({
      opinion: isLiked ? OpinionRating.LIKE : OpinionRating.DISLIKE,
    });
    window.dispatchEvent(new Event('resize-training-box'));
    sendEvent(submitFeedbackEvent(currentModule.name, currentModule.isComplete, isLiked));
  };

  return (
    <div className={styles.TrainingModeFeedbackContainer}>
      {rating.opinion === OpinionRating.UNKNOWN ? (
        <>
          <div className={styles.TrainingModeFeedbackIsThisModuleHelpful}>
            Is this module helpful?
          </div>
          <div className={styles.TrainingModeFeedbackButtonsContainer}>
            <button
              onClick={() => submitFeedback(true)}
              type="button"
              className={classNames(
                styles.TrainingModeFeedbackButton,
                styles.TrainingBannerFocusTarget,
              )}
              tabIndex={showing ? 0 : -1}
            >
              <span className={styles.TrainingModeFeedbackButtonText}>Yes</span>
              <FontAwesomeIcon icon={faThumbsUp} aria-hidden="true" />
            </button>
            <button
              onClick={() => submitFeedback(false)}
              type="button"
              className={classNames(
                styles.TrainingModeFeedbackButton,
                styles.TrainingBannerFocusTarget,
              )}
              tabIndex={showing ? 0 : -1}
            >
              <span className={styles.TrainingModeFeedbackButtonText}>No</span>
              <FontAwesomeIcon icon={faThumbsDown} aria-hidden="true" />
            </button>
          </div>
        </>
      ) : (
        <div>
          <p className={styles.TrainingModeFeedbackConfirmation}>
            Thanks! Your feedback was submitted
          </p>
        </div>
      )}
    </div>
  );
};

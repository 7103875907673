import {
  PackageCompletion,
  TaskCompletion,
  TaskItemCompletion,
  TaskItemStatus,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { LargeLoading } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { Page } from 'components/page/Page';
import { useOnboardingLockState } from 'context/onboardinglock';
import {
  useActiveHomeworkPackages,
  usePackage,
  usePackageTasks,
  useTaskItems,
} from 'queries/packages';
import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useBackPath } from 'utils/backRoutes';
import { useFeatureFlags } from 'utils/feature-flags';
import { isIndependentLearningTask } from 'utils/independentLearning';
import {
  getLQDTaskItemPaths,
  useLQDTaskInfo,
  useRetryQuestion,
  useReverseAnimation,
  useTaskCompleteOnLoad,
  useTaskItemCompleteOnLoad,
} from 'utils/lqd';
import { makeTaskItemPath, useLQDPath } from 'utils/paths';
import { isTimesTablesTask, isVideoTask } from 'utils/taskTypes';
import { ActivityDisplay } from 'views/activity/ActivityDisplay';
import { TransitionContainer, TransitionPage } from 'views/lqd/TransitionContainer';
import { WACDisplay } from 'views/wac/WACDisplay';

import { FTQEntry } from './ftq-entry/FTQEntry';
import styles from './LQD.module.css';
import { NavigationBar } from './navigation-bar/NavigationBar';
import { PackageCompleteView } from './package-complete/PackageCompleteView';
import { Summary } from './summary/Summary';
import { useLQDFastTrackQuizTask } from './useLQDFastTrackQuiz';

export const LQDContainer = () => {
  const { packageID, taskIndex, isWAC } = useLQDPath();
  const backPath = useBackPath();
  const featureFlags = useFeatureFlags();

  const { onboardingTasksCompleteCount } = useOnboardingLockState();

  const { data: pkg, isFetching: pkgLoading } = usePackage(packageID);
  const { data: tasks, isFetching: tasksLoading } = usePackageTasks(pkg?.packageID ?? '');

  const { data: activePackages, isLoading: packagesAreLoading } = useActiveHomeworkPackages();

  const taskCompleteOnLoad = useTaskCompleteOnLoad(tasks);

  if (pkgLoading || tasksLoading || packagesAreLoading) {
    return <LargeLoading />;
  }

  const currentTask = tasks?.find(t => t.taskIndex === taskIndex);

  const isInvalidTask = currentTask && (isTimesTablesTask(currentTask) || isVideoTask(currentTask));
  const isInaccessibleTask =
    currentTask && !isIndependentLearningTask(currentTask) && taskCompleteOnLoad;

  const canSkipOnboarding = featureFlags.getBooleanFlag('sparxweb2-can-skip-onboarding', false);
  const lockedDueToOnboarding =
    !canSkipOnboarding &&
    pkg?.packageType === 'onboarding' &&
    taskIndex &&
    taskIndex > onboardingTasksCompleteCount + 1;

  const isActivePackage = activePackages?.some(p => p.packageID === pkg?.packageID);

  if (
    !pkg ||
    !tasks ||
    !currentTask ||
    isInvalidTask ||
    isInaccessibleTask ||
    lockedDueToOnboarding ||
    !isActivePackage
  ) {
    return <Navigate to={backPath ?? '/homework'} />;
  }

  if (isWAC) {
    return <WACDisplay packageID={pkg.packageID} taskIndex={currentTask.taskIndex} />;
  }

  return <LQD pkg={pkg} tasks={tasks} currentTask={currentTask} />;
};

const LQD = ({
  pkg,
  tasks,
  currentTask,
}: {
  pkg: PackageCompletion;
  tasks: TaskCompletion[];
  currentTask: TaskCompletion;
}) => {
  const location = useLocation();
  const lqdPath = useLQDPath();
  const { taskItemIndex, isSummary, isCompletionScreen } = lqdPath;

  const featureFlags = useFeatureFlags();
  const wacDisabled = featureFlags.getStringFlag('sparxweb-wac-method', '') === 'disabled';

  const { showFTQSelection, startFTQ, skipFTQ, isSkippingFTQ } =
    useLQDFastTrackQuizTask(currentTask);

  const numSwapsUsed = pkg.numSwapsMade;

  const {
    data: allTaskItems,
    isLoading: taskItemsLoading,
    isError: taskItemsError,
  } = useTaskItems(pkg.packageID, currentTask.taskIndex);

  const taskItemCompleteOnLoad = useTaskItemCompleteOnLoad(allTaskItems);
  const retryQuestion = useRetryQuestion();

  // Filter and sort the task items.
  const { taskItems, taskItemsForNav, taskItemsForSummary } = useMemo(
    () =>
      (allTaskItems ?? []).reduce(
        (data, taskItem) => {
          // Filter out hidden task items
          if (taskItem.status === TaskItemStatus.HIDDEN) {
            return data;
          }

          const replaceTaskItem = (
            taskItemIndex: number,
            taskItems: TaskItemCompletion[],
            taskItem: TaskItemCompletion,
          ) => {
            const index = taskItems.findIndex(ti => ti.taskItemIndex === taskItemIndex);
            if (index !== -1) {
              taskItems[index] = taskItem;
            }
          };

          // Add all non hidden task items to nav and summary lists, replacing any swapped out items
          if (taskItem.swappedInForIndex > 0) {
            replaceTaskItem(taskItem.swappedInForIndex, data.taskItemsForNav, taskItem);
            replaceTaskItem(taskItem.swappedInForIndex, data.taskItemsForSummary, taskItem);
          } else {
            data.taskItemsForNav.push(taskItem);
            data.taskItemsForSummary.push(taskItem);
          }

          // At this point filter out any Badged task items to avoid them being added to the
          // answerable list
          if (taskItem.status === TaskItemStatus.BADGED) {
            return data;
          }

          // Add non-hidden and non-badged task items to answerable list, again replacing any
          // swapped out items
          if (taskItem.swappedInForIndex > 0) {
            replaceTaskItem(taskItem.swappedInForIndex, data.taskItems, taskItem);
          } else {
            data.taskItems.push(taskItem);
          }

          return data;
        },
        {
          taskItems: [] as TaskItemCompletion[],
          taskItemsForNav: [] as TaskItemCompletion[],
          taskItemsForSummary: [] as TaskItemCompletion[],
        },
      ),
    [allTaskItems],
  );

  const reverseAnimation = useReverseAnimation(taskItemsForNav);

  const currentTaskItem = taskItems.find(ti => ti.taskItemIndex === taskItemIndex);

  const {
    isTaskItemOnNonInitialChance,
    isTaskItemOnFinalChance,
    isTaskItemAttempted,
    isTaskItemSwapped,
    isLastTaskItem,
    isTaskComplete,
  } = useLQDTaskInfo(currentTask, taskItems, currentTaskItem);

  // We show the "you've already got this question correct" overlay if the task item was already
  // correct when it was selected and we're not retrying it
  const showCorrectQuestionOverlay = taskItemCompleteOnLoad && !retryQuestion;

  if (taskItemsLoading || isSkippingFTQ) {
    return <LoadingSpinnerWithAnalytics componentName="LQD" sendLongTimeLoadingEvent={true} />;
  }

  if (taskItemsError) {
    return <Navigate to="/homework" replace />;
  }

  // redirect to swapped in task item if the current task item is swapped out
  if (!currentTaskItem) {
    const swappedInTaskItem = taskItems.find(ti => ti.swappedInForIndex === taskItemIndex);
    if (swappedInTaskItem !== undefined) {
      const swappedInItemPath = makeTaskItemPath(
        lqdPath.parentPath,
        pkg.packageID,
        currentTask.taskIndex,
        swappedInTaskItem.taskItemIndex,
      );
      return <Navigate to={swappedInItemPath} replace />;
    }
  }

  const {
    firstIncompleteTaskItemPath,
    nextIncompleteTaskItemPath,
    previousTaskItemPath,
    currentTaskItemPath,
  } = getLQDTaskItemPaths(lqdPath, taskItems);

  // redirect to first incomplete task item if the current task item is not found
  if (!currentTaskItem && !isSummary && !isCompletionScreen) {
    return <Navigate to={firstIncompleteTaskItemPath} replace />;
  }

  const content = (
    <div
      className={classNames(styles.LQDContainer, {
        [styles.DisableBackground]: showFTQSelection,
      })}
    >
      {!isCompletionScreen && (
        <NavigationBar
          taskItems={taskItemsForNav}
          packageID={pkg.packageID}
          taskIndex={currentTask.taskIndex}
          taskItemIndex={taskItemIndex || 0}
          isSummary={!!isSummary}
          disabled={showFTQSelection}
        />
      )}
      <TransitionContainer reverse={reverseAnimation}>
        <div key={taskItemIndex}>
          <TransitionPage k={location.pathname} enterReverse={isSummary ? false : reverseAnimation}>
            {isCompletionScreen ? (
              <PackageCompleteView pkg={pkg} taskIndex={currentTask.taskIndex} />
            ) : isSummary ? (
              <Summary
                tasks={tasks}
                taskIndex={currentTask.taskIndex}
                taskItems={taskItemsForSummary}
              />
            ) : showFTQSelection ? (
              <FTQEntry
                taskTitle={currentTask.title}
                onFTQMode={() => startFTQ()}
                onNormalMode={() => skipFTQ()}
              />
            ) : (
              <ActivityDisplay
                packageID={pkg.packageID}
                taskIndex={currentTask.taskIndex}
                taskItemIndex={taskItemIndex || 0}
                packageType={pkg.packageType}
                isLastItem={isLastTaskItem}
                isTaskComplete={isTaskComplete}
                nextTaskItemPath={nextIncompleteTaskItemPath}
                currentTaskItemPath={currentTaskItemPath}
                previousTaskItemPath={previousTaskItemPath}
                isItemOnNonInitialChance={isTaskItemOnNonInitialChance}
                isTaskItemOnFinalChance={isTaskItemOnFinalChance}
                isItemAttempted={isTaskItemAttempted}
                isItemSwapped={isTaskItemSwapped}
                showCorrectQuestionOverlay={!!showCorrectQuestionOverlay}
                hideBookworkCodes={wacDisabled}
                numSwapsUsed={numSwapsUsed}
                currentTaskItem={currentTaskItem}
              />
            )}
          </TransitionPage>
        </div>
      </TransitionContainer>
    </div>
  );

  return showFTQSelection ? <Page>{content}</Page> : content;
};

import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { GameSelectButton } from '../game-select-button/game-select-button';
import styles from './GameButtonGrid.module.css';

/*
    A grid of game buttons to be shown below the Games/Exercises selector

    It will either show games (e.g. Stickers) or exercises (e.g. Talk and Learn).
    On a wide screen it will show 3 columns. If the screen is narrow (e.g. a phone) it will
    show 2 columns.
 */

export interface IGameButtonGridProps {
  gameIds: string[];
  onSelectGame: (gameId: string) => void;
  currentTargetCount: number;
  gamesAllowed: boolean;
  showLockedAlert: (gameId: string | null, message: string | undefined) => void;
  featureFlags: Record<string, number | string | boolean | undefined>;
  selectedIndex: number;
  tokenCount: number;
  usePrimaryUnlockRates: boolean;
}

export const GameButtonGrid: FunctionComponent<IGameButtonGridProps> = ({
  gameIds,
  onSelectGame,
  currentTargetCount,
  gamesAllowed,
  showLockedAlert,
  featureFlags,
  selectedIndex,
  tokenCount,
  usePrimaryUnlockRates,
}) => {
  // The hundred club button will match this style

  const generateGameButtonFromId = (gameId: string) => {
    const isSeleniumModeOn = !!featureFlags['sparxweb-selenium-mode'];
    return (
      <GameSelectButton
        key={gameId}
        onSelect={(enoughTokens, enoughTargets) => {
          if (!gamesAllowed) {
            showLockedAlert(
              enoughTokens ? gameId : null,
              'You need to do the 100 Club test before you can access this game',
            );
          } else if (!enoughTokens) {
            showLockedAlert(
              null,
              'You need to collect more tokens to play this game. Do times tables every day to gain tokens faster!',
            );
          } else if (!enoughTargets) {
            showLockedAlert(
              gameId,
              'You need to do the 100 Club test before you can access this game',
            );
          } else {
            // This will be called if it is okay for the player to start this game
            onSelectGame(gameId);
          }
        }}
        gameId={gameId}
        enabled={true}
        targetCount={currentTargetCount}
        isSeleniumModeOn={isSeleniumModeOn}
        tokenCount={tokenCount}
        usePrimaryUnlockRates={usePrimaryUnlockRates}
      />
    );
  };

  const gridClass = classNames(
    styles.GameButtonGrid,
    selectedIndex === 0 && styles.SelectedFirstTab,
  );
  const buttonClass = classNames(styles.GameButton, !gamesAllowed && styles.LockedButton);

  // fill up the grid with empty slots if it does not split neatly into 3 columnets
  const gameIdsToShow = gameIds.slice();
  while (gameIdsToShow.length % 3 !== 0) {
    gameIdsToShow.push(`empty-${gameIdsToShow.length}`);
  }

  return (
    <div className={gridClass}>
      {gameIdsToShow.map(gameId =>
        gameId.startsWith('empty') ? (
          <div className={styles.EmptyButtonSlot} key={'game_button_' + gameId} />
        ) : (
          <div key={'game_button_' + gameId} className={buttonClass}>
            {generateGameButtonFromId(gameId)}
          </div>
        ),
      )}
    </div>
  );
};

// GameButtonGrid.propTypes = {
//     gameIds: PropTypes.array.isRequired,
//     onSelectGame: PropTypes.func.isRequired,
//     currentTargetCount: PropTypes.number.isRequired,
//     gamesAllowed: PropTypes.bool.isRequired,
//     showLockedAlert: PropTypes.func.isRequired,
// };

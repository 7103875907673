import * as Popover from '@radix-ui/react-popover';
import { TaskItemCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import {
  Activity,
  ActivityActionResponse,
  GapEvaluation,
  Hint,
  QuestionActivity,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { Button, HiddenAt } from '@sparx/design/components';
import { HintInfo, IInput, IStep, SparxQuestion } from '@sparx/question';
import { Tooltip } from '@sparx/sparx-design/components';
import { ChevronLeft } from '@sparx/sparx-design/icons';
import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import { useKeyboardMode } from 'context/keyboardmode';
import { useTutorialSpotlight } from 'context/tutorialspotlight';
import { motion } from 'framer-motion';
import { useMemo, useRef, useState } from 'react';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';
import { GetCalculatorAllowed } from 'utils/question';
import { HintVideoModal } from 'views/answer/hint-video-modal';
import { hasInputForRefsAnswerPart, resetInputForRefsAnswerPart } from 'views/answer/utils';
import { QuestionAnswerLayout } from 'views/question/QuestionAnswerLayout';
import { QuestionInfo } from 'views/question/QuestionInfo';

import BottomBar from '../lqd/bottom-bar';
import styles from '../question/Question.module.css';
import { Result } from '../result';

interface IAnswerProps {
  activity: Activity;
  question: QuestionActivity;
  input: IInput;
  layout: IStep;
  setInput: (input: IInput) => void;
  goToQuestion: () => void;
  submitAnswer?: () => void;
  submitAnswerResponse?: ActivityActionResponse;
  submitError?: boolean;
  isLastItem: boolean;
  isTaskComplete: boolean;
  numSwapsUsed: number;
  currentTaskItemPath: string;
  nextTaskItemPath: string;
  isItemOnNonInitialChance: boolean;
  retryTask: () => void;
  hideBookworkCodes: boolean;
  onScaleChange?: (value: number) => void;

  // temporary data for analytics on disabled submit button clicks
  disabledSubmitAnswerAnalytics?: Record<string, boolean | string | number>;
  firstChanceGapEvaluationsRef?: React.MutableRefObject<Record<string, GapEvaluation> | undefined>;
  currentTaskItem?: TaskItemCompletion;
  placeholderMap?: Dictionary<string, string>;
  // an array containing an entry for each part in the question. Each entry is an array of refs in
  // that part
  partRefs: string[][];
}

export const Answer = ({
  activity,
  question,
  input,
  layout,
  setInput,
  goToQuestion,
  submitAnswer,
  submitAnswerResponse,
  submitError = false,
  isLastItem,
  isTaskComplete,
  numSwapsUsed,
  nextTaskItemPath,
  currentTaskItemPath,
  isItemOnNonInitialChance,
  retryTask,
  hideBookworkCodes,
  onScaleChange,
  firstChanceGapEvaluationsRef,
  currentTaskItem,
  placeholderMap,
  partRefs,
}: IAnswerProps) => {
  const [hintVideo, setHintVideo] = useState<{ hintType: Hint; videoId: string } | undefined>(
    undefined,
  );
  const { enabled: isKeyboardMode } = useKeyboardMode();
  const { tutorialIsShowing } = useTutorialSpotlight();
  const featureFlags = useFeatureFlags();
  const multiPartMarkingEnabled =
    submitAnswerResponse?.gapEvaluations &&
    Object.keys(submitAnswerResponse.gapEvaluations).length > 0 &&
    featureFlags.getBooleanFlag('sparxweb2-enable-mpa', false);

  const hintGiven = !!submitAnswerResponse?.hints && submitAnswerResponse.status === 'ACTIVE';
  const hintsMap = useMemo(() => {
    if (!submitAnswerResponse?.hints || !placeholderMap) {
      return undefined;
    }
    const hintsMap: Dictionary<string, HintInfo> = {};
    for (const ref in submitAnswerResponse.hints) {
      const partHasInput = hasInputForRefsAnswerPart(ref, input, partRefs);
      const h = submitAnswerResponse.hints[ref];
      switch (h) {
        case Hint.SIMPLIFY_FRACTION:
          hintsMap[ref] = {
            inputPlaceholder: placeholderMap?.[ref],
            hintType: h,
            onReset: () => {
              setInput(resetInputForRefsAnswerPart(ref, input, partRefs));
            },
            partHasInput: !!partHasInput,
          };
          break;
        default:
        // do nothing
      }
    }
    return hintsMap;
  }, [input, partRefs, placeholderMap, setInput, submitAnswerResponse?.hints]);

  // If an answer has been submitted and no hint has been given, the activity has been answered and cannot
  // be reattempted
  const activityAnswered = submitAnswerResponse && !hintGiven;

  const sendEvent = useAnalytics();
  const keyboardEnterFunc = (ev: KeyboardEvent) => {
    if (tutorialIsShowing) {
      return;
    }
    if (submitAnswer !== undefined && (submitAnswerResponse === undefined || hintGiven)) {
      // prevent default when pressing enter to submit. This prevents the enter key from triggering
      // the onClick behavior of the last (if still focussed) button on the keypad
      ev.preventDefault();
      submitAnswer();
      // BOOL-3095 - page event removed to reduce CloudFlare traffic
      // sendEvent({ action: 'pressed enter to submit answer', category: 'lqd' });
    }
  };

  const firstEmptyInputRef = useRef<HTMLElement | null>(null);

  const answerContent = (
    <>
      <KeyboardShortcuts enterFunc={keyboardEnterFunc} source="answer" />
      <QuestionInfo
        bookworkCode={hideBookworkCodes ? undefined : question.bookworkCode}
        calculatorAllowed={GetCalculatorAllowed(layout)}
        showBanner={false}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.QuestionWrapper}
      >
        <SparxQuestion
          layout={layout.layout}
          input={input}
          setInput={setInput}
          mode="answer"
          readOnly={!!activityAnswered}
          fontSize="30"
          lineHeight="1.2em"
          centered={true}
          shuffleSeed={`${activity.activityIndex}`}
          keyboardMode={isKeyboardMode}
          firstEmptyInputRef={firstEmptyInputRef}
          onScaleChange={onScaleChange}
          gapEvaluations={multiPartMarkingEnabled ? submitAnswerResponse.gapEvaluations : undefined}
          questionMarkingMode="part"
          firstChanceGapEvaluationsRef={firstChanceGapEvaluationsRef}
          sendAnalyticEvent={(action, labels) =>
            sendEvent({ category: 'question', action, labels })
          }
          hintsMap={hintsMap}
          openHintVideo={setHintVideo}
        />
      </motion.div>
    </>
  );

  const bottomBarAndResult =
    submitAnswerResponse && !hintGiven ? (
      <Result
        submitAnswerResponse={submitAnswerResponse}
        isLastItem={isLastItem}
        isTaskComplete={isTaskComplete}
        currentTaskItemPath={currentTaskItemPath}
        nextTaskItemPath={nextTaskItemPath}
        retryTask={retryTask}
        isItemInSecondChance={isItemOnNonInitialChance}
        numSwapsUsed={numSwapsUsed}
        currentTaskItem={currentTaskItem}
      />
    ) : (
      <BottomBar>
        <Button
          variant="secondary"
          onClick={() => {
            // BOOL-3095 - page event removed to reduce CloudFlare traffic
            // sendEvent({ action: 'clicked back from answer', category: 'lqd' });
            goToQuestion();
          }}
          leftIcon={<ChevronLeft />}
        >
          Back
        </Button>
        <div />
        <Popover.Root open={submitError}>
          <Popover.Anchor>
            <Tooltip
              content={'Enter the simplified fraction to continue.'}
              position={'top'}
              disabled={!hintGiven || submitAnswer !== undefined}
              onOpenChange={open => {
                if (open) {
                  sendEvent({
                    action: 'fraction hint submit answer tooltip shown',
                    category: 'lqd',
                  });
                }
              }}
            >
              <Button
                variant="primary"
                onClick={() => {
                  // BOOL-3095 - page event removed to reduce CloudFlare traffic
                  // sendEvent({ action: 'clicked submit answer', category: 'lqd' });
                  submitAnswer?.();
                }}
                onDisabledClick={() => {
                  if (firstEmptyInputRef.current) {
                    firstEmptyInputRef.current.scrollIntoView({
                      block: 'start',
                      behavior: 'smooth',
                    });
                  }
                  // BOOL-3095 - page event removed to reduce CloudFlare traffic
                  // sendEvent({
                  //   action: 'clicked disabled submit answer',
                  //   category: 'lqd',
                  //   labels: {
                  //     'answer fields': JSON.stringify(input),
                  //     ...disabledSubmitAnswerAnalytics,
                  //   },
                  // });
                }}
                isDisabled={submitAnswer === undefined}
              >
                Submit
                <HiddenAt as="span" breakpoint="sm">
                  {' '}
                  answer
                </HiddenAt>
              </Button>
            </Tooltip>
          </Popover.Anchor>
          <Popover.Portal>
            <Popover.Content className={styles.SubmitErrorContent}>
              <p className={styles.ErrorMessage}>
                <strong>Something went wrong.</strong>
                <span> Please make sure you have written down your answer and </span>
                <a className={styles.RefreshLink} href="">
                  refresh the page
                </a>
              </p>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </BottomBar>
    );

  return (
    <>
      <QuestionAnswerLayout content={answerContent} bottomBar={bottomBarAndResult} />
      <HintVideoModal
        onOpenChange={open => {
          if (!open) {
            setHintVideo(undefined);
          }
        }}
        hintVideo={hintVideo}
      />
    </>
  );
};

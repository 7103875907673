import { captureException } from '@sentry/react';
import { Button } from '@sparx/design/components';
import { useVerticalBreakpoint } from '@sparx/sparx-design/hooks';
import classNames from 'classnames';
import { Page } from 'components/page/Page';
import { useRouteError } from 'react-router-dom';
import { swclient2Event, useAnalytics } from 'utils/analytics';

import { ReactComponent as ErrorImage } from './error.svg';
import styles from './ErrorPage.module.css';

export const ErrorPage = ({
  withBackground,
  withCard,
  errorMessage,
}: {
  withBackground?: boolean;
  withCard?: boolean;
  errorMessage?: string;
}) => {
  // Send the error that caused this page to be rendered to Sentry
  const error = useRouteError();
  if (error) {
    captureException(error);
  }

  return (
    <SimpleErrorPageWithAnalytics
      withBackground={withBackground}
      withCard={withCard}
      errorMessage={errorMessage}
    />
  );
};

export const SimpleErrorPageWithAnalytics = ({
  withBackground,
  withCard,
  errorMessage,
}: {
  withBackground?: boolean;
  withCard?: boolean;
  errorMessage?: string;
}) => {
  const sendEvent = useAnalytics();
  return (
    <SimpleErrorPage
      withBackground={withBackground}
      withCard={withCard}
      errorMessage={errorMessage}
      sendEvent={sendEvent}
    />
  );
};

export const SimpleErrorPage = ({
  withBackground,
  withCard,
  errorMessage,
  sendEvent,
}: {
  withBackground?: boolean;
  withCard?: boolean;
  errorMessage?: string;
  sendEvent?: (event: swclient2Event) => void;
}) => {
  const onClickRefresh = (source: string) => {
    sendEvent &&
      sendEvent({
        action: 'refresh clicked from error page',
        category: 'other',
        labels: {
          source: source,
        },
      });
    window.location.reload();
  };

  const isShortScreen = useVerticalBreakpoint('md');

  return (
    <Page withBackground={!!withBackground}>
      <div className={styles.Container}>
        <section
          className={classNames(styles.Content, {
            [styles.WithCard]: withCard,
          })}
        >
          <ErrorImage className={styles.Image} />
          <p className={styles.Title}>Something has gone wrong.</p>
          {errorMessage && <p className={styles.ErrorDescription}>{errorMessage}</p>}
          <p className={styles.Message}>
            Please try{' '}
            <Button
              className={styles.InlineButton}
              variant="link"
              size={isShortScreen ? 'sm' : 'md'}
              onClick={() => {
                onClickRefresh('link');
              }}
            >
              refreshing the page
            </Button>
            . If that doesn't do the trick right away, no worries. Take a short break and try again
            in a few minutes.
          </p>
          <Button
            className={styles.Button}
            size={isShortScreen ? 'md' : 'lg'}
            variant="primary"
            onClick={() => onClickRefresh('button')}
          >
            Refresh
          </Button>
        </section>
      </div>
    </Page>
  );
};

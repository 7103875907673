import { HiddenAt } from '@sparx/sparx-design/components';
import { SparxMathsLogo } from '@sparx/sparx-design/components/SparxMathsLogo';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { NotificationList } from 'components/notification-list';
import { StudentExperienceTrainingStep } from 'content/training/trainingModules';
import { useRewards } from 'context/rewards';
import { useTraining } from 'context/training';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { useGetStudentRewards } from 'queries/studentrewards';
import { useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useBackPath } from 'utils/backRoutes';
import { makeTimesTablesTaskPath, useGamePath, useILPath, useLQDPath } from 'utils/paths';
import { useIsPrimaryStudent } from 'utils/session';

import { AnimatedBackButton } from './BackButton';
import styles from './Header.module.css';
import { Menu } from './Menu';

export const Header = () => {
  const sendEvent = useAnalytics();
  const { data: session, isLoading } = useSession();
  const { data: rewards, refetch: refetchRewards } = useGetStudentRewards();
  const { isLQDPath } = useLQDPath();
  const { isILPath } = useILPath();
  const gamePathData = useGamePath();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { completeTask: completeTrainingTask } = useTraining();
  const isPrimaryStudent = useIsPrimaryStudent();

  const backPath = useBackPath();

  const backBehavior = useMemo(() => {
    if (gamePathData.isGamePath) {
      return makeTimesTablesTaskPath(gamePathData.packageId, gamePathData.taskIndex);
    }

    if (pathname === '/rewards' || pathname === '/feedback' || pathname.startsWith('/whatsnew/')) {
      return () => navigate(-1);
    }

    return backPath;
  }, [gamePathData, backPath, pathname, navigate]);

  const ilText = useBreakpoint('sm') ? 'IL' : 'Independent Learning';

  // Hide notifications on LQD and for primary students
  const showNotifications = !isLQDPath && !isPrimaryStudent;

  // refresh rewards if any are pending and the user has navigated away from LQD
  const { rewardsPending } = useRewards();
  useEffect(() => {
    if (isLQDPath) {
      return;
    }
    if (Object.values(rewardsPending).some(rewardPending => rewardPending)) {
      refetchRewards();
    }
  }, [isLQDPath, rewardsPending, refetchRewards]);

  return (
    <div className={styles.TopBanner}>
      <div className={styles.BannerLeft}>
        <AnimatedBackButton to={backBehavior} />
        <Link
          className={styles.SMLogo}
          to={'/'}
          onClick={() => {
            sendEvent({ action: 'clicked sparxmaths logo', category: 'header' });
          }}
        >
          <SparxMathsLogo />
        </Link>
        {isILPath && <span className={styles.ILBanner}>{ilText}</span>}
      </div>
      <div className={styles.BannerSpacing}>
        <span>{window.__sparxweb.variant}</span>
      </div>
      <div className={styles.BannerRight}>
        {isLoading && (
          <div className={styles.LoadingIcon}>
            <LoadingSpinner />
          </div>
        )}
        {session?.student && (
          <>
            <div className={styles.StudentInfoContainer}>
              {rewards && (
                <Link
                  className={styles.XPCount}
                  to="/rewards"
                  onClick={() => completeTrainingTask(StudentExperienceTrainingStep.LEARN_ABOUT_XP)}
                >
                  {rewards.xpPoints.toLocaleString('en-GB')} XP
                </Link>
              )}
              <HiddenAt breakpoint="sm" className={styles.StudentName}>
                {getUsersNamesFromSession(session)}
              </HiddenAt>
            </div>
            {showNotifications && <NotificationList />}
            <Menu />
          </>
        )}
      </div>
    </div>
  );
};

import { PackageCompletion, PackageData } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { PackageDataRequest } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { QueryKey, useQueries, useQuery } from '@tanstack/react-query';
import { queryClient } from 'app/queryClient';
import { useAPI } from 'context/api';
import { produce } from 'immer';
import { isPermissionDeniedError } from 'utils/errors';

import { makeAssessmentPackagesQueryKey } from './assessments';

// gets the package data for the given package ID
// if it doesn't exist it will be fetched
// Other queries can update this ones cache such as useActiveHomeworkPackages and IL usePackagesForObjectives
export const usePackage = (packageID: string) => {
  const { swworkerClient } = useAPI();
  return useQuery(
    ['packages', packageID],
    () =>
      swworkerClient.getPackageData(
        PackageDataRequest.create({
          getPackages: true,
          packageID,
        }),
      ).response,
    {
      enabled: packageID !== '',
      select: data => data.packages[0],
      cacheTime: Infinity,
      staleTime: Infinity,
      // don't retry if the error is a permission denied error, this happens when uers hit urls with
      // containing another users package ID
      retry: (failureCount, error) => {
        return !isPermissionDeniedError(error) && failureCount < 3;
      },
    },
  );
};

// returns all cached packages, these can be ones cached by usePackage, or ones added by the updateWithPackageData function
export const useAllCachedPackages = () => {
  const data: [QueryKey, PackageData | undefined][] = queryClient.getQueriesData({
    queryKey: ['packages'],
    predicate: query => {
      // only return package data
      return query.queryKey.length === 2;
    },
  });
  return data
    .map(keyAndData => keyAndData[1]?.packages[0])
    .filter((pkg): pkg is PackageCompletion => !!pkg);
};

export const useActiveHomeworkPackages = (options?: { enabled?: boolean }) => {
  const { swworkerClient } = useAPI();
  return useQuery(
    ['activeHomeworkPackages'],
    () =>
      swworkerClient.getPackageData(
        PackageDataRequest.create({
          getPackages: true,
          includeAllActivePackages: true,
        }),
      ).response,
    {
      select: data => data.packages,
      onSuccess: data => {
        // Update the shared package data (usePackage hook above)
        data.length > 0 &&
          updateWithPackageData(
            { packages: data, taskItems: [], tasks: [] },
            { excludeActiveHWPackages: true },
          );
      },
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: options?.enabled !== undefined ? options.enabled : true,
    },
  );
};

export const usePackageTasks = (
  packageID: string,
  options?: {
    enabled?: boolean;
  },
) => {
  const { swworkerClient } = useAPI();
  return useQuery(
    ['packages', packageID, 'tasks'],
    () =>
      swworkerClient.getPackageData(
        PackageDataRequest.create({
          getTasks: true,
          packageID,
        }),
      ).response,
    {
      enabled: (options?.enabled ?? true) && packageID !== '',
      select: data => data.tasks,
      cacheTime: Infinity,
      staleTime: Infinity,
      // don't retry if the error is a permission denied error, this happens when uers hit urls with
      // containing another users package ID
      retry: (failureCount, error) => {
        return !isPermissionDeniedError(error) && failureCount < 3;
      },
    },
  );
};

export const useTaskItems = (packageID?: string, taskIndex?: number) => {
  const { swworkerClient } = useAPI();
  return useQuery(
    ['packages', packageID, 'tasks', taskIndex],
    () =>
      swworkerClient.getPackageData(
        PackageDataRequest.create({
          packageID,
          taskIndex,
          getTaskItems: true,
        }),
      ).response,
    {
      enabled:
        packageID !== undefined && packageID !== '' && taskIndex !== undefined && taskIndex > 0,
      select: data => data.taskItems,
      cacheTime: Infinity,
      staleTime: Infinity,
      // don't retry if the error is a permission denied error, this happens when uers hit urls with
      // containing another users package ID
      retry: (failureCount, error) => {
        return !isPermissionDeniedError(error) && failureCount < 3;
      },
    },
  );
};

// updateWithPackageData updates various query caches depending on what the PackageData contains
// Package data is always updated, whilst task and task item data is only updated if the list of
// tasks / list of task items is already present in the query cache (to avoid setting a partial
// list of tasks / items in the query cache that will never be updated to the full list)
//
// It updates the data of the following queries:
// - usePackage
// - useActiveHomeworkPackages
// - usePackageTasks
// - useTaskItems
// - useAssessmentPackages
export const updateWithPackageData = (
  packageData: PackageData,
  opts: {
    excludeActiveHWPackages?: boolean;
  } = {},
) => {
  const newPackages = packageData.packages;

  if (newPackages.length > 0 && !opts.excludeActiveHWPackages) {
    // Update the active homework packages
    queryClient.setQueryData(['activeHomeworkPackages'], (data: PackageData | undefined) => {
      if (!data) {
        return data;
      }

      const newData = produce(data, draftState =>
        // TODO: should we only add/replace homework packages/only allow replacing?
        replaceOrAddByKey(draftState.packages, 'packageID', ...newPackages),
      );

      return newData;
    });
  }

  const assessmentPackages: Record<string, PackageCompletion> = {};

  for (const newPackage of packageData.packages) {
    queryClient.setQueryData(['packages', newPackage.packageID], {
      packages: [newPackage],
      tasks: [],
      taskItems: [],
    });

    const assessmentName = newPackage.labels['assessment.assessment_name'];
    if (assessmentName) {
      assessmentPackages[newPackage.packageID] = newPackage;
      queryClient.setQueryData(
        makeAssessmentPackagesQueryKey(assessmentName),
        (data: PackageData | undefined) => {
          if (!data) {
            return undefined;
          }

          const newData = produce(data, draftState =>
            replaceOrAddByKey(draftState.packages, 'packageID', newPackage),
          );
          return newData;
        },
      );
    }
  }

  for (const newTask of packageData.tasks) {
    queryClient.setQueryData(
      ['packages', newTask.packageID, 'tasks'],
      (data: PackageData | undefined) => {
        // only update the query cache if already loaded from the server
        if (!data) {
          return undefined;
        }

        const newData = produce(data, draftState =>
          replaceOrAddByKey(draftState.tasks, 'taskIndex', newTask),
        );
        return newData;
      },
    );

    const assessmentPackage = assessmentPackages[newTask.packageID];
    const assessmentName = assessmentPackage?.labels['assessment.assessment_name'];
    if (assessmentPackage && assessmentName) {
      queryClient.setQueryData(
        makeAssessmentPackagesQueryKey(assessmentName),
        (data: PackageData | undefined) => {
          if (!data) {
            return undefined;
          }

          const newData = produce(data, draftState =>
            replaceOrAddByMatch(
              draftState.tasks,
              task =>
                task.packageID === assessmentPackage.packageID &&
                task.taskIndex === newTask.taskIndex,
              newTask,
            ),
          );
          return newData;
        },
      );
    }
  }

  for (const newTaskItem of packageData.taskItems) {
    queryClient.setQueryData(
      ['packages', newTaskItem.packageID, 'tasks', newTaskItem.taskIndex],
      (data: PackageData | undefined) => {
        // only update the query cache if already loaded from the server
        if (!data) {
          return undefined;
        }

        const newData = produce(data, draftState =>
          replaceOrAddByKey(draftState.taskItems, 'taskItemIndex', newTaskItem),
        );
        return newData;
      },
    );

    const assessmentPackage = assessmentPackages[newTaskItem.packageID];
    const assessmentName = assessmentPackage?.labels['assessment.assessment_name'];
    if (assessmentPackage && assessmentName) {
      queryClient.setQueryData(
        makeAssessmentPackagesQueryKey(assessmentName),
        (data: PackageData | undefined) => {
          if (!data) {
            return undefined;
          }

          const newData = produce(data, draftState =>
            replaceOrAddByMatch(
              draftState.taskItems,
              taskItem =>
                taskItem.packageID === assessmentPackage.packageID &&
                taskItem.taskIndex === newTaskItem.taskIndex &&
                taskItem.taskItemIndex === newTaskItem.taskItemIndex,
              newTaskItem,
            ),
          );
          return newData;
        },
      );
    }
  }
};

const replaceOrAddByKey = <T extends object>(arr: T[], key: keyof T, ...toAdd: T[]) => {
  for (const add of toAdd) {
    const index = arr.findIndex(item => item[key] === add[key]);
    if (index !== undefined && index !== -1) {
      arr.splice(index, 1, add);
    } else {
      arr.push(add);
    }
  }
};

const replaceOrAddByMatch = <T extends object>(
  arr: T[],
  match: (item: T) => boolean,
  ...toAdd: T[]
) => {
  for (const add of toAdd) {
    const index = arr.findIndex(match);
    if (index !== undefined && index !== -1) {
      arr.splice(index, 1, add);
    } else {
      arr.push(add);
    }
  }
};

/**
 * Returns the package ID of the most recent package with an incomplete times tables task.
 */
export const useMostRecentPackageWithIncompleteTimesTables = (
  enabled: boolean,
): { data: string | undefined; isLoading: boolean; isError: boolean } => {
  const { swworkerClient } = useAPI();
  const {
    data: packages,
    isLoading: isPackagesLoading,
    isError: isPackagesError,
  } = useActiveHomeworkPackages({ enabled });

  // Find to only incomplete homework packages
  const incompleteHomeworkPackages = packages
    ? packages.filter(pkg => pkg.packageType === 'homework' && pkg.numTasksComplete < pkg.numTasks)
    : [];

  // Get the tasks for all incomplete homework packages
  const results = useQueries({
    queries: incompleteHomeworkPackages
      .filter(pkg => pkg.packageType === 'homework' && pkg.numTasksComplete < pkg.numTasks)
      .map(pkg => {
        return {
          queryKey: ['packages', pkg.packageID, 'tasks'],
          queryFn: () =>
            swworkerClient.getPackageData(
              PackageDataRequest.create({
                getTasks: true,
                packageID: pkg.packageID,
              }),
            ).response,
          enabled,
        };
      }),
  });

  if (!enabled) {
    return { data: undefined, isLoading: false, isError: false };
  }

  if (isPackagesError || results.some(result => result.isError)) {
    return { data: undefined, isLoading: false, isError: true };
  }

  // Don't return anything until all task queries are complete
  if (isPackagesLoading || results.some(result => result.isLoading)) {
    return { data: undefined, isLoading: true, isError: false };
  }

  // Make to find the end date of each package, so we can return only the most recent package
  const packageEndDates = new Map<string, number>();
  for (const pkg of incompleteHomeworkPackages) {
    packageEndDates.set(pkg.packageID, pkg.endDate?.seconds || 0);
  }

  // Find the most recent package with an incomplete times tables task, if any
  let mostRecentPackageWithIncompleteTimesTablesTask = undefined;
  for (const result of results) {
    const tasks = result.data?.tasks || [];
    for (const task of tasks) {
      if (task.taskType === 'GAME' && !task.hasBeenComplete) {
        const packageEndDate = packageEndDates.get(task.packageID);
        if (
          packageEndDate &&
          (!mostRecentPackageWithIncompleteTimesTablesTask ||
            packageEndDate >
              (packageEndDates.get(mostRecentPackageWithIncompleteTimesTablesTask) || 0))
        ) {
          mostRecentPackageWithIncompleteTimesTablesTask = task.packageID;
        }
        break;
      }
    }
  }

  return { data: mostRecentPackageWithIncompleteTimesTablesTask, isLoading: false, isError: false };
};

import { DisruptiveDialog } from 'components/disruptive-dialog';
import { useEffect } from 'react';
import { useAnalytics } from 'utils/analytics';

export const LoggedOutDialog = ({ isOpen }: { isOpen: boolean }) => {
  const sendEvent = useAnalytics();
  const onActionOrClose = () => location.reload();

  useEffect(() => {
    if (isOpen) {
      sendEvent({
        action: 'logged out dialog shown',
        category: 'other',
      });
    }
  }, [isOpen, sendEvent]);

  return (
    <DisruptiveDialog
      isOpen={isOpen}
      description="You have been logged out of Sparx as it has been a while since you used it."
      onClose={onActionOrClose}
      showCloseButton={false}
      onAction={onActionOrClose}
      actionButtonContent="Log in"
    />
  );
};

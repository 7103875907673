/**
 * Specification of the teacher training module for Sparx Web including the
 * content of the tasks.
 */

export const HOMEPAGE_LINK = 'homepage-link';
export const CLOSE_TRAINING_MENU = 'close-training-menu';

/**
 * The ids of all steps in the student experience training module
 */
export enum StudentExperienceTrainingStep {
  INTRO_VIDEO_WATCHED = 'intro-video-watched',
  BOOKWORK_VIDEO_WATCHED = 'bookwork-video-watched',
  ANSWER_QUESTION_CORRECTLY = 'answer-question-correctly',
  FINISH_COMPULSORY_TASK = 'finish-compulsory-task',
  FAIL_BOOKWORK_CHECK = 'fail-bookwork-check',
  LEARN_ABOUT_XP = 'learn-about-xp',
  TRY_INDEPENDENT_LEARNING = 'try-independent-learning',

  // Deprecated tasks
  // ANSWER_QUESTION_INCORRECTLY = 'answer-question-incorrectly',
}

// The order and IDs of these tasks must stay in sync with teacher portal
// TODO: They are currently not in order due to expected refactor.
export const TRAINING_MODULE_STUDENT_EXPERIENCE = {
  name: 'TRAINING_MODULE_STUDENT_EXPERIENCE',
  intro: {
    id: StudentExperienceTrainingStep.INTRO_VIDEO_WATCHED,
    title: 'Welcome to the student experience',
    video: 'assets/algenie-web/sw2-training/Step_1_Intro_001',
    videoInstruction: 'Watch the video above to complete Step 1',
    videoContext:
      'This training module aims to show you how your students will experience their homework',
    instruction: 'Reach the end of the video to complete this step.',
    context: (
      <ul>
        <li>Watch this video to learn about the student experience.</li>
      </ul>
    ),
    action: 'Continue with training',
  },
  summary: {
    summaryComplete: [
      {
        heading: 'So in summary students need to:',
        points: [
          'Answer all of their compulsory questions correctly',
          'Know how bookwork checks work',
          'Use support videos if they get stuck',
        ],
      },
      {
        heading: 'And finally',
        points: [
          'Sparx for students works on most mobile devices including mobile phones',
          'To find out how to get students logged in take a look at these [resources](https://support.sparx.co.uk/docs/onboarding-your-students) on our Knowledge base',
        ],
      },
    ],
    summaryIncomplete: [
      {
        heading: 'The steps covered in this training module include:',
        points: [
          'Experience bookwork checks and try answering questions.',
          'Complete a task and understand how XP is rewarded.',
          'Try out our independent learning capabilities.',
        ],
      },
    ],
  },
  moduleComplete: {
    heading: 'Student experience tour complete',
    points: [
      "You've now completed the tour of the Sparx student experience",
      'You may return to Teacher Portal to continue training',
    ],
    returnToTP: true,
  },
  tasks: [
    {
      id: StudentExperienceTrainingStep.BOOKWORK_VIDEO_WATCHED,
      title: 'Watch the bookwork video',
      instruction: 'Reach the end of the video to complete this step.',
      context: (
        <ul>
          <li>
            Click on "Watch Video" below to see how we explain bookwork checks to students during
            their onboarding
          </li>
        </ul>
      ),
      guidanceText: ['Watch this short video to find out about bookwork checks.'],
      video: 'assets/student-onboarding/bookwork',
      // Redux selector to run to determine whether it is appropriate to hide the show video button. It will be
      // hidden if the user has not yet seen the bookwork video in the normal flow of the app (i.e., when clicking
      // on their first ever task)
      // TODO handle this:
      // hideVideoSelector: selectOnboardingTutorialActive,
      // TODO - add guidance video for this step for when the user has not yet seen the onboarding video in the
      // normal flow
      // guidanceVideo: '',
      completeText: 'Students will be shown this video when they first attempt a question.',
    },
    {
      id: StudentExperienceTrainingStep.ANSWER_QUESTION_CORRECTLY,
      title: 'Answer a question correctly',
      instruction: '',
      context: (
        <ul>
          <li>Correctly answer at least 1 question in the compulsory homework</li>
        </ul>
      ),
      guidanceText: [],
      guidanceVideo: 'assets/algenie-web/sw2-training/Step_3_Answer_Question_Correctly',
    },
    {
      id: StudentExperienceTrainingStep.FAIL_BOOKWORK_CHECK,
      title: 'Fail a bookwork check',
      instruction: '',
      context: (
        <ul>
          <li>Answer a few questions correctly until you receive a bookwork check.</li>
          <li>Fail a bookwork check by selecting "I didn't write this down".</li>
        </ul>
      ),
      guidanceText: [],
      guidanceVideo: 'assets/algenie-web/sw2-training/Step_4_Fail_Bookwork_Check',
      completeText: 'Students will need to reattempt questions when they fail bookwork checks.',
    },
    {
      id: StudentExperienceTrainingStep.FINISH_COMPULSORY_TASK,
      title: 'Finish a compulsory homework task',
      instruction: '',
      context: (
        <ul>
          <li>
            Complete one of the compulsory homework tasks by answering all of the questions
            correctly.
          </li>
        </ul>
      ),
      guidanceText: ['A task will typically have 3-6 questions.'],
      guidanceVideo: 'assets/algenie-web/sw2-training/Step_5_Complete_Compulsory_Task',
      completeText: 'A task will typically have 3-6 questions, but will vary by student.',
    },
    {
      id: StudentExperienceTrainingStep.LEARN_ABOUT_XP,
      title: 'Learn about XP',
      instruction: '',
      context: (
        <ul>
          <li>
            <a href="#" id={CLOSE_TRAINING_MENU} className="training-mode-dropdown-link">
              Close this training panel
            </a>{' '}
            and click on the XP total at the top right of the screen to find out more.
          </li>
        </ul>
      ),
      guidanceText: [],
      guidanceVideo: 'assets/algenie-web/sw2-training/Step_6_XP',
      completeText:
        'Many schools use Sparx Experience Points (XP) in their rewards systems to motivate students.',
    },
    {
      id: StudentExperienceTrainingStep.TRY_INDEPENDENT_LEARNING,
      title: 'Try Independent Learning',
      instruction: '',
      context: (
        <ul>
          <li>
            <a href="#" id={HOMEPAGE_LINK} className="training-mode-dropdown-link">
              Return to the homework home screen.
            </a>
          </li>
          <li>Go to Independent Learning and attempt any question.</li>
        </ul>
      ),
      guidanceText: [],
      guidanceVideo: 'assets/algenie-web/sw2-training/Step_7_Independent_Learning',
      completeText: 'Independent learning can be a great tool for revision.',
    },
  ],
} as const;

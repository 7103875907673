import { useEffect, useMemo, useState } from 'react';

import { useAnalytics } from './analytics';

export type FlagValue = boolean | string | number;

const windowFlags = { ...window.__sparxweb.featureFlags };
const iwChecksum = window.__sparxweb.csToken;

const defaultFlags: Record<string, FlagValue> = {};

export const useFeatureFlags = () => {
  const [dynamic, setDynamic] = useState<Record<string, FlagValue>>({});

  const flags = useMemo(
    () => ({
      ...defaultFlags,
      ...windowFlags,
      ...dynamic,
    }),
    [dynamic],
  );

  useEffect(() => {
    window.__sparxweb.setFeatureFlag = (name: string, value: FlagValue) => {
      if (import.meta.env.DEV) {
        console.log(`setting ${name} to ${value}`);
        setDynamic(prev => ({ ...prev, [name]: value }));
      }
    };
  }, [setDynamic]);

  const getFlag = <T extends FlagValue, N extends string>(name: N, defaultValue: T) => {
    const value = flags[name];
    if (value === undefined) return defaultValue;
    return value as T;
  };

  const getAllFlagsAsStrings = () => {
    const flagsAsStrings: Record<string, string> = {};
    Object.keys(flags).forEach(key => {
      flagsAsStrings[key] = flags[key].toString();
    });
    return flagsAsStrings;
  };

  const getAllFlags = () => {
    return flags;
  };

  return {
    getBooleanFlag: getFlag<boolean, booleanFlags>,
    getNumberFlag: getFlag<number, numberFlags>,
    getStringFlag: getFlag<string, string>,
    getAllFlagsAsStrings,
    getAllFlags,
  };
};

type booleanFlags =
  | 'sparxweb-show-separate-bookwork-code'
  | 'sparxweb-a11y-selectable-text'
  | 'sparxweb2-can-skip-onboarding'
  | 'sparxweb2-enable-feedback'
  | 'sparxweb2-enable-mpa'
  | 'sparxweb2-question-swaps'
  | 'sparxweb2-three-attempts'
  | 'sparxweb2-product-switcher'
  | 'sparxweb2-settings-page'
  | 'new-xp-system'
  | 'sparxweb2-leaderboards'
  | 'timestables-allow-independent-access'
  | 'sparxweb2-use-combined-question-and-answer';

type numberFlags =
  | 'sparxweb2-allowed-question-swap-count'
  | 'sparxweb2-video-watch-seconds-required';

/*
  These are used by the games because so much games code accesses feature flags by using
  flags[key].

  TODO: update games code so it can use typed feature flags
 */
export const useFeatureFlagsWithoutTyping = () => {
  const [dynamic, setDynamic] = useState<Record<string, FlagValue>>({});

  const flags = useMemo(
    () => ({
      ...defaultFlags,
      ...windowFlags,
      ...dynamic,
    }),
    [dynamic],
  );

  useEffect(() => {
    window.__sparxweb.setFeatureFlag = (name: string, value: FlagValue) => {
      if (import.meta.env.DEV) {
        console.log(`setting ${name} to ${value}`);
        setDynamic(prev => ({ ...prev, [name]: value }));
      }
    };
  }, [setDynamic]);

  return flags;
};

async function sha256Checksum(jsonBlob: object): Promise<string> {
  // Convert object to JSON string
  const jsonString = JSON.stringify(jsonBlob);

  // Encode as Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(jsonString);

  // Compute SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert ArrayBuffer to string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const checksum = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return checksum;
}

export const useCheckFeatureFlagChecksum = () => {
  const sendEvent = useAnalytics();
  useEffect(() => {
    (async () => {
      const windowFlagsChecksum = await sha256Checksum(windowFlags);
      if (iwChecksum !== windowFlagsChecksum && !import.meta.env.DEV) {
        sendEvent({
          action: 'ff checksum mismatch',
          category: 'debug',
          labels: { ...windowFlags },
        });
      }
    })();
  }, [sendEvent]);
};

export const LevelBadge5 = ({ level }: { level: number }) => {
  return (
    <svg
      width="284"
      height="249"
      viewBox="0 0 284 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M233.071 101.1C245.791 102.3 258.481 104.15 271.091 106.63C273.121 107.03 274.851 105.17 274.231 103.23C271.901 96.0002 269.371 88.7802 266.651 81.5802C266.321 80.7202 266.481 79.7702 267.081 79.0602C272.091 73.2002 277.281 67.4402 282.661 61.7702C284.111 60.2402 283.091 57.8202 280.781 57.3702C266.521 54.5602 252.171 52.4802 237.781 51.1202C236.091 50.9602 234.621 52.0402 234.491 53.5002C233.191 68.4002 231.891 83.3002 230.581 98.2002C230.451 99.6702 231.561 100.96 233.051 101.1H233.071Z"
        fill="#1BB267"
      />
      <path
        d="M50.2799 101.11C51.7699 100.97 52.8799 99.6701 52.7499 98.2101C51.4399 83.3101 50.1399 68.4101 48.8399 53.5101C48.7099 52.0401 47.2399 50.9701 45.5499 51.1301C31.1599 52.4901 16.8199 54.5701 2.54986 57.3801C0.249856 57.8301 -0.780143 60.2501 0.669857 61.7801C6.03986 67.4501 11.2299 73.2201 16.2499 79.0701C16.8499 79.7701 17.0099 80.7301 16.6799 81.5901C13.9599 88.7801 11.4299 96.0101 9.09986 103.24C8.46986 105.18 10.1999 107.04 12.2399 106.64C24.8499 104.16 37.5399 102.31 50.2599 101.11H50.2799Z"
        fill="#1BB267"
      />
      <path
        d="M258.53 89.97C254.41 91.1 247.99 89.91 234.26 94.92C233.78 95.09 233.3 94.63 233.4 94.06C234.23 88.94 235.06 83.83 235.89 78.71C244.27 80.5 252.62 82.59 260.92 84.96C261.62 85.16 259.22 89.78 258.53 89.97Z"
        fill="#127742"
      />
      <path
        d="M28.3893 89.4203C37.8593 90.0603 43.1793 91.6203 52.9193 95.1803C53.2593 95.3003 53.5993 94.9703 53.5393 94.5703C52.7193 89.5003 51.8893 84.4503 51.0793 79.3803C51.0293 79.0903 50.7793 78.9003 50.5093 78.9603C42.0293 80.7903 33.5993 82.9103 25.2093 85.3303C24.7193 85.4703 27.8993 89.2803 28.3993 89.4203H28.3893Z"
        fill="#127742"
      />
      <path
        d="M229.38 168.98C229.38 175.88 224.53 184.41 218.61 187.94L147.61 230.31C144.93 231.91 141.6 231.91 138.92 230.31L67.9204 187.94C62.0004 184.41 57.1504 175.87 57.1504 168.98V21.8398C57.1504 14.9398 62.7904 9.2998 69.6904 9.2998H216.83C223.73 9.2998 229.37 14.9398 229.37 21.8398V168.98H229.38Z"
        fill="#C67B18"
      />
      <path
        d="M143.27 242.21C140.53 242.21 137.79 241.48 135.34 240.02L64.3402 197.65C56.2602 192.83 50.1602 182.09 50.1602 172.67V25.54C50.1602 14.77 58.9302 6 69.7002 6H216.84C227.61 6 236.38 14.77 236.38 25.54V172.68C236.38 182.1 230.28 192.83 222.2 197.66L151.2 240.03C148.75 241.49 146.01 242.22 143.27 242.22V242.21ZM64.1602 14.23C61.1102 14.23 64.1602 22.48 64.1602 25.54V172.68C64.1602 177.08 67.7302 183.38 71.5202 185.63L142.52 228C142.99 228.28 143.57 228.28 144.03 228L215.03 185.63C218.81 183.37 222.38 177.08 222.38 172.68V25.54C222.38 22.49 224.32 14.23 221.26 14.23"
        fill="#A56921"
      />
      <path
        d="M259.729 89.0901C255.609 90.2201 249.189 89.0301 235.459 94.0401C234.979 94.2101 234.499 93.7501 234.599 93.1801C235.429 88.0601 236.259 82.9501 237.089 77.8301C245.469 79.6201 253.819 81.7101 262.119 84.0801C262.819 84.2801 260.419 88.9001 259.729 89.0901Z"
        fill="#127742"
      />
      <path
        d="M143.27 238.21C140.53 238.21 137.79 237.48 135.34 236.02L64.3402 193.65C56.2602 188.83 50.1602 178.09 50.1602 168.67V19.54C50.1602 8.77 58.9302 0 69.7002 0H216.84C227.61 0 236.38 8.77 236.38 19.54V168.68C236.38 178.1 230.28 188.83 222.2 193.66L151.2 236.03C148.75 237.49 146.01 238.22 143.27 238.22V238.21ZM69.7002 16C66.6502 16 64.1602 18.48 64.1602 21.54V168.68C64.1602 173.08 67.7302 179.38 71.5102 181.63L142.51 224C142.97 224.28 143.56 224.28 144.02 224L215.02 181.63C218.8 179.37 222.37 173.08 222.37 168.68V21.54C222.37 18.49 219.89 16 216.83 16H69.7002Z"
        fill="url(#paint0_linear_4519_6816)"
      />
      <path
        opacity="0.15"
        d="M207.239 16.4397C207.239 16.4397 190.019 102.06 92.3594 193.92C92.3594 193.24 143.279 224.62 143.279 224.62L215.689 181.78C215.689 181.78 222.339 176.69 222.339 166.5V22.8097C222.339 22.8097 223.179 16.4297 216.289 16.4297H207.239V16.4397Z"
        fill="url(#paint1_radial_4519_6816)"
      />
      <g opacity="0.7">
        <path
          d="M126.029 115.62V117.71H114.629V98.8096H117.029V115.61H126.019L126.029 115.62Z"
          fill="white"
        />
        <path
          d="M138.94 114.119C138.72 114.599 138.43 115.069 138.09 115.529C137.75 115.989 137.35 116.389 136.89 116.729C136.43 117.069 135.91 117.339 135.33 117.549C134.75 117.759 134.1 117.869 133.39 117.869C132.53 117.869 131.72 117.699 130.94 117.359C130.16 117.019 129.5 116.549 128.93 115.939C128.36 115.329 127.91 114.609 127.58 113.769C127.25 112.929 127.09 111.999 127.09 110.969C127.09 109.939 127.25 109.029 127.58 108.189C127.91 107.349 128.35 106.619 128.91 106.009C129.47 105.399 130.13 104.919 130.88 104.579C131.63 104.239 132.42 104.069 133.24 104.069C134.06 104.069 134.83 104.229 135.56 104.549C136.29 104.869 136.92 105.329 137.46 105.919C138 106.509 138.43 107.219 138.75 108.049C139.07 108.879 139.23 109.799 139.23 110.819C139.23 111.019 139.23 111.179 139.2 111.319C139.17 111.459 139.16 111.579 139.14 111.679C139.12 111.799 139.1 111.909 139.08 112.019H129.49C129.53 112.499 129.66 112.969 129.89 113.429C130.12 113.889 130.41 114.289 130.75 114.629C131.09 114.969 131.5 115.249 131.97 115.459C132.44 115.669 132.91 115.779 133.38 115.779C133.8 115.779 134.17 115.719 134.5 115.599C134.83 115.479 135.13 115.339 135.4 115.179C135.67 115.019 135.9 114.849 136.08 114.659C136.26 114.469 136.41 114.289 136.53 114.129H138.93L138.94 114.119ZM133.24 106.179C132.78 106.179 132.34 106.279 131.93 106.469C131.52 106.659 131.14 106.929 130.8 107.279C130.46 107.629 130.18 108.029 129.95 108.509C129.72 108.989 129.57 109.509 129.49 110.079H136.83C136.67 108.879 136.26 107.929 135.59 107.229C134.92 106.529 134.14 106.179 133.24 106.179Z"
          fill="white"
        />
        <path
          d="M153.318 104.21L147.908 117.72H145.818L140.408 104.21H142.808L146.868 114.57L150.908 104.21H153.308H153.318Z"
          fill="white"
        />
        <path
          d="M166.518 114.119C166.298 114.599 166.008 115.069 165.668 115.529C165.328 115.989 164.928 116.389 164.468 116.729C164.008 117.069 163.488 117.339 162.908 117.549C162.328 117.759 161.678 117.869 160.968 117.869C160.108 117.869 159.298 117.699 158.518 117.359C157.738 117.019 157.078 116.549 156.508 115.939C155.938 115.329 155.488 114.609 155.158 113.769C154.828 112.929 154.668 111.999 154.668 110.969C154.668 109.939 154.828 109.029 155.158 108.189C155.488 107.349 155.928 106.619 156.488 106.009C157.048 105.399 157.708 104.919 158.458 104.579C159.208 104.239 159.998 104.069 160.818 104.069C161.638 104.069 162.408 104.229 163.138 104.549C163.868 104.869 164.498 105.329 165.038 105.919C165.578 106.509 166.008 107.219 166.328 108.049C166.648 108.879 166.808 109.799 166.808 110.819C166.808 111.019 166.808 111.179 166.778 111.319C166.748 111.459 166.738 111.579 166.718 111.679C166.698 111.799 166.678 111.909 166.658 112.019H157.068C157.108 112.499 157.238 112.969 157.468 113.429C157.698 113.889 157.988 114.289 158.328 114.629C158.668 114.969 159.078 115.249 159.548 115.459C160.018 115.669 160.488 115.779 160.958 115.779C161.378 115.779 161.748 115.719 162.078 115.599C162.408 115.479 162.708 115.339 162.978 115.179C163.248 115.019 163.478 114.849 163.658 114.659C163.838 114.469 163.988 114.289 164.108 114.129H166.508L166.518 114.119ZM160.818 106.179C160.358 106.179 159.918 106.279 159.508 106.469C159.098 106.659 158.718 106.929 158.378 107.279C158.038 107.629 157.758 108.029 157.528 108.509C157.298 108.989 157.148 109.509 157.068 110.079H164.408C164.248 108.879 163.838 107.929 163.168 107.229C162.498 106.529 161.718 106.179 160.818 106.179Z"
          fill="white"
        />
        <path d="M172.209 117.719H169.949V98.8193H172.209V117.719Z" fill="white" />
      </g>
      <path
        opacity="0.3"
        d="M233.549 10.7998C229.209 5.30977 222.569 1.94977 215.609 2.36977H212.559L188.129 2.43977C155.209 2.61977 104.879 2.46977 72.0991 2.30977C70.5891 2.30977 67.7591 2.19977 66.2191 2.64977C57.7891 4.36977 52.5291 10.3498 50.2891 18.3498C50.7691 9.62977 57.1991 2.42977 65.7991 0.689773C67.5091 0.219773 70.2491 0.249773 72.0691 0.259773C104.789 0.0997731 155.269 -0.0502318 188.129 0.129768C192.449 0.199768 214.749 0.0897698 218.759 0.27977C225.079 0.91977 231.059 4.97977 233.869 10.5898C233.979 10.7798 233.679 10.9598 233.559 10.7798L233.549 10.7998Z"
        fill="#FFF7E3"
      />
      <path
        opacity="0.48"
        d="M70.1606 180.84L143.761 225.09H142.721C143.081 225.15 215.721 181.28 216.071 181.93C216.141 182.05 216.101 182.2 215.991 182.28L143.761 226.8C143.431 227 143.031 226.99 142.721 226.8C142.931 226.44 68.5506 181.17 70.1706 180.84H70.1606Z"
        fill="#FFEB73"
      />
      <path
        d="M258.469 89.8902C182.339 79.3502 105.129 79.3502 28.9993 89.8902C27.2693 90.1302 25.6093 88.9402 25.2893 87.2202C22.5793 72.5202 19.8693 57.8202 17.1493 43.1302C16.8293 41.4102 18.0893 39.8002 19.9593 39.5402C102.079 28.1702 185.379 28.1702 267.509 39.5402C269.379 39.8002 270.629 41.4102 270.319 43.1302C267.609 57.8302 264.899 72.5302 262.179 87.2202C261.859 88.9402 260.199 90.1302 258.469 89.8902Z"
        fill="url(#paint2_linear_4519_6816)"
      />
      <path
        opacity="0.3"
        d="M236.869 94.7398C174.959 87.7198 112.449 87.7198 50.5389 94.7398C50.4789 92.1998 50.4289 89.6598 50.3789 87.1098C112.379 80.1498 174.969 80.1398 236.979 87.0998C236.949 89.6398 236.909 92.1898 236.879 94.7298L236.869 94.7398Z"
        fill="#0E1935"
      />
      <path
        opacity="0.3"
        d="M19.9805 41.5598C102.1 30.1598 185.41 30.1598 267.53 41.5598"
        stroke="white"
        strokeWidth="2.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.3">
        <path
          d="M56.0389 60.9896C54.9689 60.5096 53.8189 60.1596 52.5989 59.9396C51.3789 59.7196 50.2489 59.5096 49.2089 59.2996C48.1689 59.0896 47.2889 58.8296 46.5689 58.5096C45.8489 58.1896 45.4289 57.6596 45.3289 56.9196C45.2289 56.2296 45.4789 55.5596 46.0889 54.8996C46.6989 54.2396 47.6389 53.8396 48.8889 53.7096C50.1489 53.5696 51.1689 53.7896 51.9489 54.3496C52.7289 54.9096 53.1989 55.7396 53.3389 56.8596C54.9389 56.6896 56.5289 56.5296 58.1289 56.3796C57.9789 55.2396 57.6389 54.2096 57.0989 53.2896C56.5589 52.3696 55.8489 51.5896 54.9689 50.9496C54.0889 50.3096 53.0889 49.8596 51.9489 49.5696C50.8189 49.2896 49.5889 49.2096 48.2689 49.3596C46.8389 49.5196 45.5789 49.8596 44.5089 50.3796C43.4289 50.8996 42.5389 51.5396 41.8389 52.2796C41.1389 53.0196 40.6389 53.8496 40.3389 54.7696C40.0389 55.6896 39.9589 56.6296 40.1089 57.5696C40.3289 59.0196 40.8489 60.1496 41.6489 60.9596C42.4489 61.7596 43.3789 62.3796 44.4589 62.8296C45.5289 63.2696 46.6689 63.5796 47.8889 63.7696C49.0989 63.9596 50.2289 64.1596 51.2589 64.3896C52.2989 64.6096 53.1689 64.8996 53.8789 65.2596C54.5989 65.6096 54.9989 66.1696 55.1089 66.9396C55.2089 67.7096 54.9489 68.4596 54.3289 69.1296C53.6989 69.7996 52.6689 70.2096 51.2289 70.3696C49.8989 70.5096 48.7989 70.2796 47.9389 69.6696C47.0789 69.0496 46.5589 68.1996 46.3989 67.0896C44.7589 67.2796 43.1189 67.4696 41.4789 67.6596C41.6589 68.8396 42.0389 69.9096 42.6189 70.8496C43.1889 71.7896 43.9289 72.5796 44.8189 73.2096C45.7089 73.8496 46.7489 74.2896 47.9289 74.5596C49.1089 74.8296 50.4089 74.8896 51.8489 74.7296C53.2789 74.5696 54.4989 74.2396 55.5989 73.7096C56.6989 73.1796 57.6089 72.5396 58.3289 71.7796C59.0589 71.0196 59.5889 70.1996 59.9289 69.3096C60.2689 68.4196 60.3889 67.5296 60.2689 66.6296C60.0789 65.1196 59.5989 63.9296 58.8189 63.0596C58.0389 62.1896 57.1189 61.5096 56.0489 61.0296L56.0389 60.9896Z"
          fill="#6D320A"
        />
        <path
          d="M77.799 56.11C76.899 55.34 75.879 54.76 74.749 54.38C73.619 54 72.449 53.86 71.249 53.96C70.409 54.03 69.669 54.2 69.029 54.47C68.389 54.74 67.839 55.01 67.389 55.29C66.869 55.63 66.419 56 66.049 56.41C65.979 55.82 65.909 55.22 65.839 54.63C64.379 54.76 62.919 54.89 61.459 55.03C61.599 56.19 61.739 57.34 61.879 58.5C62.739 65.54 63.599 72.58 64.459 79.62C65.999 79.47 67.539 79.33 69.079 79.2C68.779 76.55 68.469 73.91 68.169 71.26C68.589 71.57 69.059 71.84 69.579 72.06C70.039 72.23 70.569 72.39 71.169 72.52C71.769 72.66 72.469 72.69 73.249 72.63C74.409 72.53 75.499 72.21 76.509 71.66C77.519 71.12 78.379 70.39 79.099 69.5C79.819 68.6 80.359 67.57 80.739 66.39C81.109 65.22 81.229 63.95 81.109 62.6C80.979 61.25 80.619 60.02 80.029 58.91C79.439 57.81 78.689 56.87 77.789 56.09L77.799 56.11ZM75.389 66.7C74.579 67.67 73.509 68.21 72.179 68.32C70.849 68.43 69.679 68.08 68.679 67.26C67.679 66.44 67.079 65.27 66.909 63.77C66.739 62.26 67.049 61.02 67.859 60.04C68.669 59.06 69.749 58.51 71.109 58.4C72.469 58.29 73.649 58.64 74.649 59.47C75.649 60.3 76.229 61.47 76.379 62.98C76.529 64.49 76.199 65.73 75.399 66.7H75.389Z"
          fill="#6D320A"
        />
        <path
          d="M95.8884 52.48C95.9284 53.08 95.9684 53.67 96.0184 54.27C95.5484 53.92 95.0384 53.62 94.4884 53.36C93.9984 53.15 93.4084 52.95 92.7284 52.78C92.0484 52.61 91.2784 52.55 90.4484 52.6C89.2484 52.67 88.1284 52.98 87.0784 53.51C86.0284 54.04 85.1384 54.76 84.3884 55.65C83.6384 56.54 83.0784 57.58 82.6984 58.75C82.3184 59.92 82.1884 61.19 82.3184 62.54C82.4384 63.89 82.7984 65.12 83.3784 66.23C83.9584 67.34 84.6884 68.28 85.5684 69.06C86.4384 69.84 87.4284 70.43 88.5284 70.82C89.6284 71.22 90.7484 71.38 91.9184 71.31C92.7284 71.26 93.4484 71.12 94.0884 70.88C94.7284 70.64 95.2584 70.38 95.7084 70.08C96.1984 69.76 96.6584 69.4 97.0584 69.01C97.0984 69.61 97.1384 70.21 97.1884 70.8C98.6084 70.72 100.038 70.65 101.458 70.58C101.068 64.47 100.678 58.36 100.278 52.25C98.8184 52.32 97.3484 52.4 95.8884 52.48ZM95.4384 65.33C94.5984 66.28 93.5184 66.8 92.1784 66.88C90.8484 66.96 89.6884 66.58 88.7084 65.74C87.7284 64.89 87.1784 63.72 87.0484 62.21C86.9184 60.7 87.2684 59.46 88.1084 58.5C88.9484 57.54 90.0484 57.02 91.3984 56.94C92.7584 56.86 93.9284 57.24 94.9084 58.1C95.8884 58.95 96.4184 60.14 96.5284 61.65C96.6384 63.16 96.2684 64.39 95.4384 65.35V65.33Z"
          fill="#6D320A"
        />
        <path
          d="M113.189 51.5395C113.019 51.5195 112.829 51.5195 112.599 51.5195C111.869 51.5495 111.229 51.6795 110.669 51.9295C110.109 52.1795 109.639 52.4595 109.269 52.7595C108.809 53.0995 108.429 53.4695 108.109 53.8895C108.069 53.2295 108.039 52.5695 108.009 51.8995C107.729 51.9095 107.449 51.9195 107.179 51.9295C105.989 51.9795 104.809 52.0295 103.619 52.0895C103.689 53.2495 103.759 54.4095 103.829 55.5795C104.119 60.5295 104.419 65.4695 104.709 70.4195C106.269 70.3495 107.829 70.2795 109.389 70.2195C109.219 66.9595 109.049 63.7095 108.879 60.4495C108.819 59.2595 109.129 58.2795 109.829 57.5195C110.519 56.7595 111.459 56.3595 112.619 56.3195C112.869 56.3195 113.099 56.3195 113.309 56.3395C113.519 56.3595 113.689 56.3795 113.829 56.3995C113.999 56.4195 114.149 56.4395 114.289 56.4695C114.219 54.8795 114.149 53.2795 114.079 51.6895C113.939 51.6695 113.799 51.6395 113.659 51.6195C113.519 51.5995 113.359 51.5795 113.199 51.5495L113.189 51.5395Z"
          fill="#6D320A"
        />
        <path
          d="M132.779 51.2395C131.039 51.2595 129.289 51.2895 127.549 51.3195C126.339 53.2695 125.139 55.2195 123.949 57.1795C122.649 55.2795 121.339 53.3795 120.009 51.4895C118.269 51.5395 116.519 51.5895 114.779 51.6495C116.929 54.5695 119.049 57.5095 121.149 60.4495C119.119 63.6295 117.129 66.8195 115.159 70.0095C116.859 69.9495 118.549 69.8995 120.249 69.8495C121.529 67.7495 122.829 65.6595 124.139 63.5595C125.569 65.5895 126.989 67.6195 128.389 69.6595C130.089 69.6295 131.779 69.5995 133.479 69.5795C131.329 66.4795 129.149 63.3895 126.929 60.2995C128.849 57.2695 130.799 54.2395 132.769 51.2295L132.779 51.2395Z"
          fill="#6D320A"
        />
        <path
          d="M157.078 63.0399C154.258 56.8099 151.348 50.5899 148.358 44.3899C146.878 44.3899 145.398 44.3799 143.918 44.3799C143.918 52.7599 143.918 61.1399 143.918 69.5199C145.548 69.5199 147.178 69.5199 148.798 69.5399C148.838 64.8799 148.868 60.2299 148.908 55.5699C151.088 60.2399 153.228 64.9199 155.318 69.5999C156.408 69.6099 157.488 69.6299 158.578 69.6499C160.858 65.0299 163.178 60.4199 165.548 55.8199C165.398 60.4699 165.248 65.1299 165.098 69.7799C166.728 69.8199 168.358 69.8699 169.988 69.9199C170.318 61.5399 170.658 53.1699 170.988 44.7899C169.508 44.7399 168.028 44.6999 166.548 44.6599C163.318 50.7599 160.158 56.8899 157.088 63.0299L157.078 63.0399Z"
          fill="#6D320A"
        />
        <path
          d="M201.559 48.6694C200.159 48.5794 198.759 48.4894 197.349 48.3994C197.229 49.8594 197.119 51.3194 196.999 52.7894C195.819 52.7194 194.629 52.6494 193.449 52.5794C193.349 53.9694 193.249 55.3594 193.139 56.7594C194.109 56.8194 195.079 56.8694 196.049 56.9294C195.849 59.5194 195.649 62.1094 195.449 64.6894C195.369 65.6994 195.449 66.6094 195.709 67.4294C195.959 68.2494 196.339 68.9594 196.849 69.5794C197.359 70.1994 197.989 70.6894 198.739 71.0394C199.489 71.3994 200.319 71.5994 201.239 71.6694C201.829 71.7094 202.369 71.6694 202.839 71.5794C203.089 71.5394 203.309 71.4894 203.509 71.4294C203.629 70.0994 203.749 68.7794 203.869 67.4494C203.749 67.4994 203.639 67.5294 203.529 67.5494C203.419 67.5694 203.289 67.5794 203.159 67.5794C203.019 67.5794 202.849 67.5794 202.629 67.5594C201.809 67.4994 201.179 67.2294 200.749 66.7294C200.319 66.2294 200.129 65.5894 200.189 64.7894C200.399 62.2694 200.619 59.7494 200.829 57.2294C202.209 57.3194 203.599 57.4194 204.979 57.5094C205.109 56.1194 205.229 54.7294 205.359 53.3294C203.969 53.2294 202.569 53.1394 201.179 53.0494C201.299 51.5894 201.429 50.1294 201.549 48.6694H201.559Z"
          fill="#6D320A"
        />
        <path
          d="M223.509 56.71C222.949 55.98 222.239 55.39 221.379 54.95C220.519 54.51 219.569 54.25 218.539 54.16C217.589 54.08 216.769 54.12 216.059 54.27C215.359 54.43 214.769 54.6 214.299 54.81C213.769 55.06 213.309 55.36 212.899 55.7C213.189 52.85 213.489 50 213.779 47.16C212.159 47.03 210.549 46.91 208.929 46.79C208.129 55.14 207.329 63.49 206.539 71.84C208.099 71.95 209.659 72.07 211.209 72.2C211.559 68.82 211.899 65.44 212.249 62.06C212.369 60.87 212.839 59.94 213.649 59.27C214.459 58.6 215.439 58.31 216.609 58.41C217.769 58.51 218.679 58.95 219.329 59.74C219.979 60.53 220.229 61.52 220.099 62.71C219.709 66.09 219.329 69.46 218.939 72.84C220.499 72.98 222.049 73.12 223.609 73.27C224.039 69.7 224.479 66.13 224.909 62.56C225.049 61.37 224.999 60.28 224.739 59.29C224.479 58.3 224.069 57.44 223.509 56.71Z"
          fill="#6D320A"
        />
        <path
          d="M240.649 66.9394C240.269 66.2194 239.749 65.5994 239.099 65.0994C238.449 64.5994 237.719 64.1894 236.909 63.8794C236.099 63.5694 235.359 63.2694 234.669 62.9994C233.979 62.7294 233.409 62.4494 232.959 62.1594C232.509 61.8694 232.309 61.5194 232.369 61.0994C232.439 60.5994 232.689 60.1994 233.139 59.8894C233.589 59.5894 234.169 59.4794 234.889 59.5494C235.609 59.6294 236.179 59.8894 236.609 60.3394C237.039 60.7894 237.279 61.2194 237.329 61.6194C238.849 61.7794 240.359 61.9494 241.879 62.1194C241.889 60.5094 241.359 59.1094 240.279 57.9194C239.199 56.7294 237.499 56.0094 235.199 55.7594C234.139 55.6494 233.179 55.6994 232.299 55.9194C231.429 56.1394 230.649 56.4694 229.979 56.9094C229.299 57.3494 228.769 57.8894 228.359 58.5394C227.959 59.1794 227.709 59.8794 227.619 60.6194C227.489 61.6794 227.619 62.5494 228.019 63.2494C228.419 63.9394 228.949 64.5294 229.609 65.0094C230.269 65.4894 230.999 65.8794 231.809 66.1794C232.619 66.4794 233.359 66.7594 234.049 67.0394C234.729 67.3094 235.299 67.5894 235.739 67.8794C236.179 68.1694 236.379 68.5194 236.319 68.9494C236.249 69.4494 235.949 69.8794 235.409 70.2394C234.879 70.5994 234.209 70.7294 233.389 70.6494C232.569 70.5594 231.949 70.2894 231.519 69.8094C231.079 69.3394 230.859 68.7894 230.829 68.1694C229.329 68.0194 227.829 67.8694 226.329 67.7194C226.299 69.5394 226.849 71.0494 227.969 72.2594C229.089 73.4694 230.859 74.1994 233.299 74.4494C234.349 74.5594 235.329 74.5094 236.219 74.2994C237.119 74.0894 237.899 73.7694 238.559 73.3294C239.229 72.8894 239.779 72.3594 240.199 71.7194C240.619 71.0794 240.889 70.3994 240.999 69.6594C241.159 68.5794 241.049 67.6694 240.669 66.9494L240.649 66.9394Z"
          fill="#6D320A"
        />
        <path
          d="M187.56 52.2699C187.52 52.8699 187.48 53.4599 187.44 54.0599C187.02 53.6699 186.56 53.3099 186.04 52.9899C185.58 52.7299 185.03 52.4799 184.37 52.2299C183.71 51.9899 182.97 51.8499 182.13 51.8099C180.93 51.7599 179.78 51.9399 178.66 52.3599C177.55 52.7799 176.56 53.3999 175.7 54.1999C174.84 55.0099 174.15 55.9799 173.61 57.0999C173.08 58.2299 172.78 59.4699 172.72 60.8299C172.66 62.1899 172.85 63.4399 173.28 64.5999C173.71 65.7599 174.3 66.7699 175.07 67.6399C175.83 68.5099 176.73 69.1899 177.77 69.6999C178.81 70.2099 179.9 70.4899 181.07 70.5399C181.88 70.5799 182.62 70.5099 183.28 70.3399C183.94 70.1699 184.51 69.9599 184.99 69.7199C185.53 69.4499 186.02 69.1499 186.48 68.7999C186.44 69.3999 186.4 69.9999 186.36 70.5899C187.78 70.6599 189.21 70.7399 190.63 70.8099C191.06 64.6999 191.5 58.5999 191.94 52.4899C190.48 52.4099 189.01 52.3399 187.55 52.2599L187.56 52.2699ZM185.38 64.9799C184.42 65.8399 183.27 66.2399 181.94 66.1799C180.6 66.1199 179.51 65.6199 178.65 64.6799C177.79 63.7399 177.4 62.5099 177.48 60.9999C177.56 59.4899 178.07 58.2899 179.03 57.4299C179.99 56.5599 181.15 56.1599 182.51 56.2199C183.87 56.2799 184.98 56.7899 185.83 57.7299C186.68 58.6799 187.06 59.9099 186.96 61.4199C186.86 62.9299 186.33 64.1199 185.37 64.9799H185.38Z"
          fill="#6D320A"
        />
      </g>
      <path
        d="M55.8201 59.25C54.7501 58.77 53.6001 58.42 52.3801 58.2C51.1501 57.98 50.0201 57.77 48.9801 57.56C47.9401 57.35 47.0501 57.09 46.3301 56.77C45.6101 56.45 45.1901 55.92 45.0801 55.18C44.9801 54.49 45.2301 53.82 45.8501 53.16C46.4601 52.5 47.4001 52.1 48.6601 51.97C49.9201 51.83 50.9401 52.05 51.7301 52.61C52.5101 53.17 52.9801 54 53.1301 55.12C54.7301 54.95 56.3301 54.79 57.9301 54.63C57.7801 53.49 57.4401 52.46 56.9001 51.54C56.3601 50.62 55.6501 49.84 54.7701 49.2C53.8901 48.56 52.8801 48.11 51.7501 47.82C50.6101 47.54 49.3801 47.47 48.0601 47.61C46.6301 47.77 45.3701 48.11 44.2901 48.63C43.2101 49.15 42.3201 49.79 41.6201 50.53C40.9201 51.27 40.4201 52.1 40.1201 53.02C39.8201 53.94 39.7401 54.88 39.8901 55.82C40.1101 57.27 40.6301 58.4 41.4301 59.2C42.2301 60 43.1701 60.62 44.2401 61.07C45.3201 61.51 46.4601 61.82 47.6801 62.01C48.9001 62.2 50.0201 62.4 51.0601 62.63C52.1001 62.85 52.9801 63.14 53.6901 63.5C54.4101 63.85 54.8201 64.42 54.9201 65.18C55.0201 65.95 54.7601 66.7 54.1301 67.37C53.5001 68.04 52.4601 68.45 51.0201 68.61C49.6901 68.75 48.5901 68.52 47.7201 67.91C46.8501 67.29 46.3401 66.44 46.1701 65.33C44.5201 65.52 42.8801 65.71 41.2401 65.9C41.4201 67.08 41.8101 68.15 42.3801 69.09C42.9501 70.03 43.6901 70.82 44.5901 71.45C45.4901 72.09 46.5301 72.53 47.7101 72.8C48.9001 73.07 50.2001 73.13 51.6401 72.97C53.0801 72.81 54.3001 72.48 55.4001 71.95C56.5001 71.42 57.4101 70.78 58.1401 70.02C58.8701 69.26 59.4001 68.44 59.7501 67.55C60.0901 66.66 60.2101 65.77 60.0901 64.87C59.9001 63.36 59.4201 62.17 58.6401 61.3C57.8601 60.43 56.9301 59.75 55.8601 59.27L55.8201 59.25Z"
        fill="white"
      />
      <path
        d="M77.63 54.3697C76.73 53.5997 75.71 53.0197 74.57 52.6397C73.43 52.2597 72.26 52.1197 71.07 52.2197C70.23 52.2897 69.49 52.4597 68.84 52.7297C68.19 52.9997 67.65 53.2697 67.2 53.5497C66.68 53.8897 66.23 54.2597 65.85 54.6697C65.78 54.0797 65.71 53.4797 65.64 52.8897C64.18 53.0197 62.71 53.1497 61.25 53.2897C61.39 54.4497 61.53 55.5997 61.67 56.7597C62.53 63.7997 63.39 70.8397 64.25 77.8797C65.79 77.7297 67.34 77.5897 68.89 77.4597C68.59 74.8097 68.28 72.1697 67.98 69.5197C68.4 69.8297 68.87 70.0997 69.39 70.3197C69.85 70.4897 70.38 70.6497 70.98 70.7797C71.58 70.9197 72.28 70.9497 73.07 70.8897C74.24 70.7897 75.32 70.4697 76.34 69.9197C77.35 69.3797 78.22 68.6497 78.94 67.7597C79.66 66.8597 80.21 65.8297 80.58 64.6497C80.95 63.4797 81.08 62.2097 80.95 60.8597C80.82 59.5097 80.46 58.2797 79.87 57.1697C79.28 56.0697 78.53 55.1297 77.63 54.3497V54.3697ZM75.21 64.9497C74.4 65.9197 73.32 66.4597 71.99 66.5797C70.65 66.6897 69.48 66.3397 68.48 65.5197C67.47 64.6997 66.88 63.5397 66.71 62.0297C66.54 60.5197 66.85 59.2697 67.66 58.2897C68.47 57.3097 69.56 56.7597 70.92 56.6497C72.28 56.5397 73.46 56.8897 74.47 57.7197C75.47 58.5497 76.05 59.7197 76.2 61.2297C76.35 62.7397 76.02 63.9797 75.21 64.9497Z"
        fill="white"
      />
      <path
        d="M95.7605 50.73C95.8005 51.33 95.8405 51.92 95.8905 52.52C95.4205 52.17 94.9105 51.87 94.3605 51.61C93.8705 51.4 93.2805 51.21 92.5905 51.03C91.9005 50.86 91.1405 50.8 90.3005 50.85C89.1005 50.92 87.9705 51.23 86.9205 51.76C85.8705 52.29 84.9705 53.01 84.2205 53.9C83.4705 54.79 82.9105 55.83 82.5305 57C82.1505 58.17 82.0205 59.44 82.1505 60.79C82.2705 62.14 82.6305 63.37 83.2105 64.48C83.7905 65.59 84.5205 66.53 85.4005 67.31C86.2805 68.09 87.2705 68.68 88.3705 69.07C89.4705 69.47 90.6005 69.63 91.7705 69.56C92.5905 69.51 93.3105 69.37 93.9505 69.13C94.5905 68.89 95.1305 68.63 95.5705 68.33C96.0705 68.01 96.5205 67.65 96.9305 67.26C96.9705 67.86 97.0105 68.46 97.0605 69.05C98.4905 68.97 99.9205 68.9 101.34 68.83C100.95 62.72 100.56 56.61 100.16 50.5C98.6905 50.57 97.2205 50.65 95.7605 50.73ZM95.3105 63.59C94.4705 64.54 93.3805 65.06 92.0405 65.14C90.7005 65.22 89.5405 64.84 88.5605 64C87.5805 63.15 87.0205 61.98 86.8905 60.47C86.7605 58.96 87.1105 57.72 87.9605 56.76C88.8005 55.8 89.9005 55.28 91.2605 55.2C92.6205 55.12 93.7905 55.5 94.7705 56.35C95.7505 57.2 96.2905 58.39 96.4005 59.9C96.5105 61.41 96.1405 62.64 95.3005 63.6L95.3105 63.59Z"
        fill="white"
      />
      <path
        d="M113.12 49.7895C112.95 49.7695 112.76 49.7695 112.53 49.7695C111.8 49.7995 111.16 49.9295 110.59 50.1795C110.03 50.4295 109.56 50.7095 109.19 51.0095C108.73 51.3495 108.35 51.7195 108.03 52.1395C108 51.4795 107.96 50.8095 107.93 50.1495C107.65 50.1595 107.37 50.1695 107.09 50.1795C105.9 50.2295 104.71 50.2795 103.52 50.3395C103.59 51.4995 103.66 52.6595 103.73 53.8295C104.02 58.7795 104.32 63.7195 104.61 68.6695C106.17 68.5995 107.74 68.5295 109.3 68.4695C109.13 65.2095 108.96 61.9595 108.79 58.6995C108.73 57.5095 109.04 56.5295 109.74 55.7695C110.44 55.0095 111.37 54.6095 112.54 54.5695C112.79 54.5695 113.02 54.5695 113.23 54.5895C113.44 54.6095 113.61 54.6295 113.75 54.6495C113.92 54.6695 114.07 54.6895 114.21 54.7195C114.14 53.1295 114.07 51.5295 114 49.9395C113.86 49.9195 113.72 49.8895 113.58 49.8695C113.44 49.8495 113.28 49.8195 113.11 49.7995L113.12 49.7895Z"
        fill="white"
      />
      <path
        d="M132.761 49.48C131.011 49.5 129.261 49.53 127.521 49.56C126.311 51.51 125.101 53.46 123.911 55.42C122.601 53.52 121.291 51.62 119.961 49.73C118.211 49.78 116.471 49.83 114.721 49.89C116.871 52.81 119.001 55.75 121.101 58.68C119.071 61.86 117.061 65.05 115.091 68.24C116.791 68.18 118.491 68.13 120.191 68.08C121.481 65.98 122.781 63.89 124.091 61.79C125.521 63.82 126.951 65.85 128.351 67.89C130.051 67.86 131.751 67.83 133.451 67.81C131.291 64.71 129.101 61.62 126.891 58.53C128.821 55.5 130.771 52.47 132.751 49.46L132.761 49.48Z"
        fill="white"
      />
      <path
        d="M157.11 61.2899C154.28 55.0599 151.36 48.8399 148.37 42.6399C146.89 42.6399 145.41 42.6299 143.92 42.6299C143.92 51.0099 143.92 59.3899 143.92 67.7699C145.55 67.7699 147.18 67.7699 148.82 67.7899C148.86 63.1299 148.89 58.4799 148.93 53.8199C151.12 58.4899 153.26 63.1699 155.36 67.8499C156.45 67.8599 157.54 67.8799 158.62 67.8999C160.9 63.2799 163.23 58.6699 165.6 54.0699C165.45 58.7199 165.3 63.3799 165.15 68.0299C166.78 68.0699 168.42 68.1199 170.05 68.1699C170.38 59.7899 170.72 51.4199 171.05 43.0399C169.57 42.9899 168.08 42.9499 166.6 42.9099C163.36 49.0099 160.2 55.1399 157.12 61.2799L157.11 61.2899Z"
        fill="white"
      />
      <path
        d="M201.71 46.9302C200.3 46.8402 198.9 46.7502 197.49 46.6602C197.37 48.1202 197.26 49.5802 197.14 51.0502C195.95 50.9802 194.77 50.9102 193.58 50.8402C193.48 52.2302 193.38 53.6202 193.27 55.0202C194.24 55.0802 195.21 55.1302 196.19 55.1902C195.99 57.7802 195.79 60.3702 195.59 62.9502C195.51 63.9602 195.59 64.8702 195.85 65.6902C196.1 66.5102 196.48 67.2202 197 67.8402C197.51 68.4602 198.14 68.9502 198.89 69.3002C199.64 69.6602 200.48 69.8602 201.4 69.9302C202 69.9702 202.53 69.9302 203.01 69.8402C203.26 69.8002 203.48 69.7502 203.68 69.6902C203.8 68.3602 203.92 67.0402 204.04 65.7102C203.92 65.7602 203.81 65.7902 203.7 65.8102C203.59 65.8302 203.46 65.8402 203.33 65.8402C203.19 65.8402 203.02 65.8402 202.8 65.8202C201.98 65.7602 201.35 65.4902 200.91 64.9902C200.47 64.4902 200.28 63.8502 200.35 63.0502C200.56 60.5302 200.78 58.0102 200.99 55.4902C202.38 55.5802 203.77 55.6802 205.16 55.7702C205.29 54.3802 205.41 52.9902 205.54 51.5902C204.14 51.4902 202.75 51.4002 201.35 51.3002C201.47 49.8402 201.6 48.3802 201.72 46.9202L201.71 46.9302Z"
        fill="white"
      />
      <path
        d="M223.721 54.9698C223.161 54.2398 222.451 53.6498 221.591 53.2098C220.721 52.7698 219.771 52.5098 218.741 52.4198C217.791 52.3398 216.961 52.3798 216.261 52.5298C215.551 52.6898 214.971 52.8598 214.491 53.0698C213.961 53.3198 213.491 53.6198 213.091 53.9598C213.381 51.1098 213.681 48.2598 213.971 45.4198C212.351 45.2898 210.731 45.1698 209.111 45.0498C208.311 53.3998 207.511 61.7498 206.721 70.0998C208.281 70.2198 209.841 70.3398 211.401 70.4598C211.751 67.0798 212.091 63.6998 212.441 60.3198C212.561 59.1298 213.031 58.1998 213.841 57.5298C214.651 56.8598 215.641 56.5798 216.801 56.6698C217.971 56.7698 218.871 57.2098 219.531 57.9998C220.181 58.7898 220.441 59.7798 220.301 60.9698C219.911 64.3498 219.531 67.7198 219.141 71.0998C220.701 71.2398 222.261 71.3798 223.821 71.5298C224.251 67.9598 224.691 64.3898 225.121 60.8198C225.261 59.6298 225.211 58.5398 224.951 57.5498C224.691 56.5598 224.281 55.6998 223.721 54.9698Z"
        fill="white"
      />
      <path
        d="M240.901 65.2002C240.521 64.4802 240.001 63.8602 239.351 63.3602C238.701 62.8602 237.961 62.4502 237.161 62.1302C236.351 61.8202 235.601 61.5202 234.911 61.2502C234.221 60.9802 233.651 60.6902 233.201 60.4102C232.751 60.1202 232.551 59.7702 232.611 59.3502C232.681 58.8502 232.941 58.4502 233.381 58.1402C233.831 57.8402 234.411 57.7302 235.131 57.8002C235.851 57.8802 236.421 58.1402 236.851 58.5902C237.281 59.0402 237.521 59.4702 237.581 59.8702C239.101 60.0302 240.621 60.2002 242.141 60.3802C242.151 58.7702 241.621 57.3702 240.531 56.1802C239.451 54.9902 237.741 54.2602 235.431 54.0202C234.371 53.9102 233.401 53.9602 232.531 54.1802C231.651 54.4002 230.881 54.7302 230.201 55.1702C229.521 55.6102 228.981 56.1502 228.581 56.8002C228.181 57.4402 227.931 58.1402 227.841 58.8802C227.711 59.9402 227.841 60.8102 228.241 61.5102C228.641 62.2002 229.171 62.7902 229.831 63.2702C230.491 63.7502 231.231 64.1402 232.031 64.4402C232.841 64.7402 233.591 65.0202 234.271 65.3002C234.951 65.5702 235.521 65.8502 235.971 66.1402C236.411 66.4302 236.611 66.7802 236.551 67.2102C236.481 67.7102 236.181 68.1402 235.641 68.5002C235.111 68.8602 234.431 68.9902 233.611 68.9102C232.791 68.8202 232.171 68.5502 231.731 68.0702C231.291 67.6002 231.061 67.0502 231.031 66.4302C229.531 66.2802 228.021 66.1302 226.521 65.9802C226.491 67.8002 227.041 69.3102 228.161 70.5202C229.291 71.7302 231.061 72.4602 233.501 72.7202C234.561 72.8302 235.531 72.7802 236.431 72.5702C237.331 72.3602 238.111 72.0402 238.781 71.6002C239.451 71.1602 240.001 70.6302 240.431 69.9902C240.851 69.3502 241.121 68.6702 241.231 67.9302C241.391 66.8502 241.281 65.9402 240.901 65.2202V65.2002Z"
        fill="white"
      />
      <path
        d="M187.67 50.5199C187.63 51.1199 187.59 51.7099 187.55 52.3099C187.13 51.9199 186.66 51.5599 186.15 51.2399C185.69 50.9799 185.13 50.7199 184.47 50.4799C183.81 50.2399 183.06 50.0999 182.23 50.0599C181.03 50.0099 179.87 50.1899 178.75 50.6099C177.64 51.0299 176.65 51.6499 175.79 52.4499C174.93 53.2599 174.23 54.2299 173.69 55.3499C173.15 56.4799 172.86 57.7199 172.8 59.0799C172.74 60.4399 172.93 61.6899 173.36 62.8499C173.79 64.0099 174.39 65.0199 175.15 65.8899C175.91 66.7599 176.82 67.4399 177.85 67.9499C178.89 68.4599 179.99 68.7399 181.16 68.7899C181.98 68.8299 182.71 68.7599 183.38 68.5899C184.04 68.4199 184.62 68.2099 185.1 67.9699C185.64 67.6999 186.13 67.3999 186.59 67.0499C186.55 67.6499 186.51 68.2499 186.47 68.8399C187.9 68.9099 189.32 68.9899 190.75 69.0699C191.19 62.9599 191.62 56.8599 192.06 50.7499C190.59 50.6699 189.13 50.5999 187.66 50.5199H187.67ZM185.49 63.2399C184.53 64.0999 183.38 64.4999 182.04 64.4399C180.7 64.3799 179.6 63.8799 178.74 62.9399C177.88 61.9999 177.49 60.7699 177.56 59.2599C177.64 57.7499 178.15 56.5499 179.12 55.6899C180.08 54.8199 181.25 54.4199 182.61 54.4799C183.97 54.5399 185.08 55.0499 185.94 55.9899C186.8 56.9399 187.17 58.1699 187.07 59.6799C186.97 61.1899 186.44 62.3799 185.48 63.2399H185.49Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4519_6816"
          x1="145.35"
          y1="236.24"
          x2="141.96"
          y2="5.08001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4992C" />
          <stop offset="0.42" stopColor="#F2B12F" />
          <stop offset="1" stopColor="#F4992C" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_4519_6816"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(157.359 120.52) scale(86.78 86.78)"
        >
          <stop stopColor="#5B3303" stopOpacity="0" />
          <stop offset="0.62" stopColor="#5B3303" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_4519_6816"
          x1="17.1093"
          y1="60.4602"
          x2="270.369"
          y2="60.4602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27CE88" />
          <stop offset="0.29" stopColor="#27CE88" />
          <stop offset="0.53" stopColor="#2AF697" />
          <stop offset="0.81" stopColor="#27CE88" />
          <stop offset="1" stopColor="#27CE88" />
        </linearGradient>
      </defs>
      <text
        x="50.5%"
        y="75%"
        textAnchor="middle"
        fill="white"
        fontSize={level < 1000 ? '5rem' : '4rem'}
        fontWeight="500"
      >
        {level}
      </text>
    </svg>
  );
};

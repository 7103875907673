import {
  Assessment,
  QuestionType,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useMemo } from 'react';

export const getStudentMark = (questionName: string, studentAssessment: StudentAssessment) => {
  return studentAssessment.marks.find(m => m.assessmentQuestionName === questionName);
};

export const getAssessmentStatistics = ({
  assessment,
  studentAssessment,
}: {
  assessment: Assessment;
  studentAssessment: StudentAssessment;
}) => {
  const assessmentMarks = {
    totalMarks: 0,
    studentMarks: 0,
  };
  const fluencyMarks = {
    totalMarks: 0,
    studentMarks: 0,
  };
  const problemSolvingMarks = {
    totalMarks: 0,
    studentMarks: 0,
  };

  for (const q of assessment.questions) {
    assessmentMarks.totalMarks += q.availableMarks;
    if (q.type === QuestionType.FLUENCY) {
      fluencyMarks.totalMarks += q.availableMarks;
    } else if (q.type === QuestionType.PROBLEM_SOLVING) {
      problemSolvingMarks.totalMarks += q.availableMarks;
    }

    const studentMark = getStudentMark(q.name, studentAssessment);
    const score = studentMark?.score;
    if (score) {
      assessmentMarks.studentMarks += score;
      if (q.type === QuestionType.FLUENCY) {
        fluencyMarks.studentMarks += score;
      } else if (q.type === QuestionType.PROBLEM_SOLVING) {
        problemSolvingMarks.studentMarks += score;
      }
    }
  }

  return { assessmentMarks, fluencyMarks, problemSolvingMarks };
};

export const useIsFixUpAvailable = (
  assessment?: Assessment,
  studentAssessment?: StudentAssessment,
) =>
  useMemo(() => {
    if (!assessment || !studentAssessment) {
      return false;
    }

    const { fluencyMarks } = getAssessmentStatistics({ assessment, studentAssessment });

    return fluencyMarks.totalMarks > 0 && fluencyMarks.studentMarks < fluencyMarks.totalMarks;
  }, [assessment, studentAssessment]);

import { TaskItemCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { ActivityActionResponse, TaskItemStatus } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { Button } from '@sparx/sparx-design/components';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { Cross, Flag, Tick } from '@sparx/sparx-design/icons';
import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import { useIsAssessmentPackage } from 'context/package/hooks';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';
import { useLQDPath } from 'utils/paths';
import { useTetheringSearchParams } from 'views/tether/TetherHooks';

import { QuestionSwapBar } from './question-swaps/QuestionSwapBar';
import { useIsQuestionSwapsEnabled } from './question-swaps/useIsQuestionSwapsEnabled';
import styles from './Result.module.css';
import { ResultPopoverId } from './resultPopoverId';
import { getRemark } from './resultRemarks';

interface IResultProps {
  // The response from the server from submitting the last answer
  submitAnswerResponse: ActivityActionResponse;

  // Whether the current task item is the last one
  isLastItem: boolean;

  // Whether the current task is complete
  isTaskComplete: boolean;

  // URL to the current task item
  currentTaskItemPath: string;

  // URL to get to the next task item, or the summary page if on the last task item
  nextTaskItemPath: string;

  // URL to retry the task
  retryTask: () => void;

  // Whether the current item is ready for a second chance attempt
  isItemInSecondChance: boolean;

  // The number of question swaps used
  numSwapsUsed: number;

  // current task item
  currentTaskItem?: TaskItemCompletion;
}

export const Result: FunctionComponent<IResultProps> = ({
  submitAnswerResponse,
  isLastItem,
  isTaskComplete,
  currentTaskItemPath,
  nextTaskItemPath,
  retryTask,
  isItemInSecondChance,
  numSwapsUsed,
  currentTaskItem,
}) => {
  const priorIncorrectAttemptCount = currentTaskItem?.numConsecutiveWrongAnswers ?? 0;
  const isVideoWatched = (currentTaskItem?.numVideoWatched ?? 0) > 0;

  const featureFlags = useFeatureFlags();

  const swapsFFEnabled = featureFlags.getBooleanFlag('sparxweb2-question-swaps', false);
  // the logic for showing three attempts is tied to the question swaps feature flag
  // this isn't great, but it's probably best to do this check for now.
  const threeAttemptsFFEnabled =
    swapsFFEnabled || featureFlags.getBooleanFlag('sparxweb2-three-attempts', false);
  const maximumSwaps = featureFlags.getNumberFlag('sparxweb2-allowed-question-swap-count', 0);
  const numSwapsRemaining = maximumSwaps - numSwapsUsed;

  const sendEvent = useAnalytics();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { data: session } = useSession();
  const isMediumScreen = useBreakpoint('md');
  const isAssessmentPackage = useIsAssessmentPackage();

  const resultShim = useRef<HTMLDivElement>(null);
  const buttons = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!resultShim.current || !buttons.current) {
      return;
    }
    const buttonWidth = buttons.current.getBoundingClientRect().width;
    resultShim.current.style.width = `${buttonWidth}px`;
  }, []);

  const answerStatus =
    submitAnswerResponse.taskItemStatus == TaskItemStatus.SEEKHELP
      ? 'SEEKHELP'
      : submitAnswerResponse.status;

  let incorrectAttemptCount = priorIncorrectAttemptCount || 0;
  if (answerStatus !== 'SUCCESS') {
    incorrectAttemptCount++;
  }

  const { packageID, taskIndex, taskItemIndex } = useLQDPath();

  useEffect(() => {
    if (answerStatus === 'SEEKHELP') {
      sendEvent({
        action: 'shown seek help result',
        category: 'lqd',
        labels: {
          'swaps enabled': swapsFFEnabled,
          'swaps remaining': numSwapsRemaining,
          packageID: packageID,
          taskIndex: taskIndex || 0,
          taskItemIndex: taskItemIndex || 0,
        },
      });
    }
  }, [
    sendEvent,
    answerStatus,
    swapsFFEnabled,
    numSwapsRemaining,
    packageID,
    taskIndex,
    taskItemIndex,
  ]);

  const swapOutQuestionEnabled = useIsQuestionSwapsEnabled(
    packageID,
    taskIndex || 0,
    taskItemIndex || 0,
    answerStatus,
  );

  const studentName = getUsersNamesFromSession(session, 'firstOnly');

  const remark = useMemo(
    () =>
      getRemark(
        answerStatus === 'SUCCESS',
        answerStatus === 'SEEKHELP',
        incorrectAttemptCount,
        studentName,
      ),
    [answerStatus, incorrectAttemptCount, studentName],
  );

  // If the answer was correct we want to center the Result Info, so we make
  // a shim on the left side, which is the same size as the button on the right
  const useShimToCenter = answerStatus === 'SUCCESS';
  useEffect(() => {
    if (!resultShim.current || !buttons.current || !useShimToCenter) {
      return;
    }
    const buttonWidth = buttons.current.getBoundingClientRect().width;
    resultShim.current.style.width = `${buttonWidth}px`;
  }, [useShimToCenter]);

  const retry = () => {
    setIsSubmitted(true);
    retryTask();
  };

  const { isTethering, params } = useTetheringSearchParams();
  const tetheringParams = `?${params.toString()}`;
  if (isTethering) {
    nextTaskItemPath = tetheringParams;
  }

  if (isSubmitted) {
    return null;
  }

  const retryText = !threeAttemptsFFEnabled && isItemInSecondChance ? 'Second Chance' : 'Try again';
  let message;
  let image;
  let className;
  let primaryButton;
  let secondaryButton;
  let additionalInfo;
  let keyboardEnterFunc: () => void = () => undefined;
  if (answerStatus === 'SUCCESS') {
    message = 'Correct!';
    image = <Tick variant="Green" className={styles.ResultImage} />;
    className = styles.Correct;
    primaryButton = (
      <Button
        as={Link}
        to={nextTaskItemPath}
        reloadDocument={isTethering}
        variant="contained"
        // BOOL-3095 - page event removed to reduce Cloudflare traffic
        // onClick={() => {
        //   sendEvent({ action: 'clicked continue from correct result', category: 'lqd' });
        // }}
      >
        {isTaskComplete ? 'Summary' : 'Continue'}
      </Button>
    );
    keyboardEnterFunc = () => navigate(nextTaskItemPath);
  } else if (answerStatus === 'FAILURE') {
    message = 'Incorrect';

    const buttonText = isLastItem ? 'Summary' : threeAttemptsFFEnabled ? 'Move on' : 'Continue';
    image = <Cross variant="Red" className={styles.ResultImage} />;
    className = styles.Incorrect;
    secondaryButton = (
      <Button
        as={Link}
        to={nextTaskItemPath}
        reloadDocument={isTethering}
        variant={isLastItem ? 'outlined' : 'text'}
        // BOOL-3095 - page event removed to reduce Cloudflare traffic
        // onClick={() => {
        //   sendEvent({
        //     action: 'clicked continue from incorrect result',
        //     category: 'lqd',
        //     labels: { 'button text': buttonText },
        //   });
        // }}
      >
        {buttonText}
      </Button>
    );
    primaryButton = (
      <Button
        as={Link}
        to={isTethering ? currentTaskItemPath + tetheringParams : currentTaskItemPath}
        reloadDocument={isTethering}
        variant="contained"
        onClick={() => {
          // BOOL-3095 - page event removed to reduce Cloudflare traffic
          // sendEvent({
          //   action: 'clicked retry from incorrect result',
          //   category: 'lqd',
          //   labels: { 'button text': retryText },
          // });
          retry();
        }}
      >
        {retryText}
      </Button>
    );
    keyboardEnterFunc = retry;
  } else if (answerStatus === 'SEEKHELP') {
    message = 'Incorrect';
    image = <Flag variant="Purple" className={`${styles.SeekHelpResultImage}`} />;
    className = styles.SeekHelp;
    primaryButton = (
      <Button
        as={Link}
        to={nextTaskItemPath}
        variant={isLastItem ? 'outlined' : 'text'}
        // BOOL-3095 - page event removed to reduce Cloudflare traffic
        // onClick={() => {
        //   sendEvent({
        //     action: 'clicked continue from seek help result',
        //     category: 'lqd',
        //     labels: { 'button text': isLastItem ? 'Summary' : 'Skip question' },
        //   });
        // }}
      >
        {isLastItem ? 'Summary' : 'Skip question'}
      </Button>
    );
    secondaryButton = (
      <Button
        as={Link}
        to={currentTaskItemPath}
        variant="contained"
        onClick={() => {
          // BOOL-3095 - page event removed to reduce Cloudflare traffic
          // sendEvent({
          //   action: 'clicked retry from seek help result',
          //   category: 'lqd',
          //   labels: { 'button text': retryText },
          // });
          retry();
        }}
      >
        {retryText}
      </Button>
    );
    additionalInfo = isAssessmentPackage
      ? 'Move on to the next question and make sure you get help from your teacher.'
      : 'Move on with your homework and make sure you get help from your teacher before the deadline.';
    keyboardEnterFunc = () => navigate(nextTaskItemPath);
  } else {
    message = '?';
    className = styles.Incorrect;
  }

  // Show the question swap bar if the student has had more than 2 incorrect attempts or
  // if the student has made more than 1 incorrect attempt and has swaps remaining. This means
  // the second incorrect attempt keeps the default bar if there are no swaps remaining.
  const showQSwapBar =
    swapOutQuestionEnabled &&
    (priorIncorrectAttemptCount > 2 || (numSwapsRemaining > 0 && priorIncorrectAttemptCount > 1));

  return (
    <>
      <KeyboardShortcuts enterFunc={keyboardEnterFunc} source="result" />
      {/**
       * give the result popover an id so that it can be easily found. Used for sizing the nav bar
       * in <NavigationBarFixup />
       */}
      <div className={`${styles.ResultFullWidth} ${className}`} id={ResultPopoverId}>
        {showQSwapBar ? (
          <QuestionSwapBar
            packageID={packageID}
            maxSwaps={maximumSwaps}
            numSwapsUsed={numSwapsUsed}
            priorIncorrectAttemptCount={priorIncorrectAttemptCount || 0}
            retryQuestionFn={retry}
            taskIndex={taskIndex || 0}
            taskItemIndex={taskItemIndex || 0}
            currentTaskItemPath={currentTaskItemPath}
            nextTaskItemPath={nextTaskItemPath}
            isLastItem={isLastItem}
            videoWatched={isVideoWatched}
            isSeekHelp={answerStatus === 'SEEKHELP'}
          />
        ) : (
          <div className={`${styles.ResultContainer} ${className}`}>
            {useShimToCenter && <div ref={resultShim} className={styles.ResultShim}></div>}
            <div className={styles.ResultInfoWrapper}>
              <div className={styles.Result}>
                {image}
                <div className={`${styles.Messages} ${className}`}>
                  <span className={styles.ResultMessage}>{message}</span>
                  <span className={styles.Remark}>
                    {isMediumScreen ? remark.shortRemark : remark.longRemark}
                  </span>
                </div>
              </div>
              <div className={`${styles.AdditionalInfo} ${className}`}>
                <span>{additionalInfo}</span>
              </div>
            </div>

            <div ref={buttons} className={`${styles.ButtonsContainer} ${className}`}>
              {secondaryButton}
              {primaryButton}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

import * as Tabs from '@radix-ui/react-tabs';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { NavigationFrame } from 'app/NavigationFrame';
import { navIcons } from 'app/NavigationFrame/icons';
import { useNavLinks } from 'app/NavigationFrame/use-nav-links';
import { MaxWidth, Page } from 'components/page/Page';
import { SuspenseRoute } from 'components/suspense-route/SuspenseRoute';
import { useOnboardingLockState } from 'context/onboardinglock';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsPrimaryStudent } from 'utils/session';

import { Activity } from './activity/Activity';
import { FindTopics } from './find-topics/FindTopics';
import styles from './IndependentLearningView.module.css';

/**
 * The home page for Independent Learning. It has 2 tabs: Topics and Activity.
 */

export const IndependentLearningView = () => {
  const { onboardingLockEnabled } = useOnboardingLockState();
  const isPrimaryStudent = useIsPrimaryStudent();
  const [selectedView, setSelectedView] = useState('topics');

  const isSmallScreen = useBreakpoint('sm');

  const navLinks = useNavLinks();

  const shouldRedirect = isPrimaryStudent || onboardingLockEnabled;

  if (shouldRedirect) {
    return <Navigate to="/" replace />;
  }

  const bottomBarItems = [
    {
      text: 'Topics',
      icons: navIcons.compulsory,
      isActive: selectedView === 'topics',
      onClick: () => setSelectedView('topics'),
    },
    {
      text: 'Activity',
      icons: navIcons.xpBoost,
      isActive: selectedView === 'activity',
      onClick: () => setSelectedView('activity'),
    },
  ];

  return (
    <Page>
      <NavigationFrame bottomBarItems={bottomBarItems} sideBarItems={navLinks}>
        <MaxWidth>
          <SuspenseRoute timeLongLoadTimeApp={'IndependentLearning'}>
            {isSmallScreen ? (
              selectedView === 'topics' ? (
                <FindTopics />
              ) : (
                <Activity />
              )
            ) : (
              <>
                <div className={styles.Title}>Independent Learning</div>
                <Tabs.Root defaultValue={selectedView} onValueChange={v => setSelectedView(v)}>
                  <Tabs.List className={styles.TabList}>
                    <Tabs.Trigger className={styles.Tab} value="topics">
                      Find topics
                    </Tabs.Trigger>
                    <Tabs.Trigger className={styles.Tab} value="activity">
                      My activity
                    </Tabs.Trigger>
                  </Tabs.List>
                  <Tabs.Content value="topics">
                    <FindTopics />
                  </Tabs.Content>
                  <Tabs.Content value="activity">
                    <Activity />
                  </Tabs.Content>
                </Tabs.Root>
              </>
            )}
          </SuspenseRoute>
        </MaxWidth>
      </NavigationFrame>
    </Page>
  );
};

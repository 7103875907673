import classnames from 'classnames';
import failIcon from 'images/icon-bookworkcheck.svg';
import wacIcon from 'images/icon-wac-alert.svg';
import { useFeatureFlags } from 'utils/feature-flags';

import incorrectStyles from './WACIncorrect.module.css';
import styles from './WACResult.module.css';

export const WACIncorrect = () => {
  // Find out if the student has to redo the original question
  const featureFlags = useFeatureFlags();
  const wacNotIncorrect =
    featureFlags.getStringFlag('sparxweb-wac-method', '') === 'ask_answer_any_type_not_incorrect';

  const icon = wacNotIncorrect ? wacIcon : failIcon;

  const incorrectSubHeading = wacNotIncorrect
    ? `That wasn't the correct answer`
    : 'Oops! You will have to do that question again.';

  return (
    <>
      <img width="133" height="133" src={icon} alt="incorrect" />
      <div
        className={wacNotIncorrect ? styles.TextContainer : incorrectStyles.IncorrectTextContainer}
      >
        <h2 className={wacNotIncorrect ? styles.SubHeading : incorrectStyles.IncorrectHeading}>
          {incorrectSubHeading}
        </h2>
        {wacNotIncorrect && (
          <div className={classnames(styles.AccuracyText, styles.Incorrect)}>
            Your bookwork accuracy could go down, which might mean you will get checked more often.
          </div>
        )}
        <div className={wacNotIncorrect ? styles.Message : incorrectStyles.IncorrectMessage}>
          It is important to write down your workings and answers as you do your homework.
        </div>
      </div>
    </>
  );
};

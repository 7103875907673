import { TaskItemCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { makeTaskItemName } from '@sparx/resource-names';
import { createContext, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { useFeatureFlags } from 'utils/feature-flags';

type VideoNudgeStateContextData = {
  shouldShowVideoNudge: (taskItem: TaskItemCompletion) => boolean;
  handleVideoNudgeClose: () => void;
  updateVideoNudgeState: (taskItem: TaskItemCompletion) => void;
};

export const VideoNudgeStateContext = createContext<VideoNudgeStateContextData | undefined>(
  undefined,
);

// VideoNudgeStateProvider tracks incorrect answers for task items, and provides a context which
// contains a function to determine whether a video nudge should be shown for a given task item, as
// well as functions to be called on answering a question, and on closing the video nudge, to keep
// the state up to date.
// The logic for showing the nudge is to be changed alongside the feature flag 'sparxweb2-three-attempts'.
// When the feature flag is off:
// Note the the state is entirely client side, and nothing is persisted between app reloads.
// The video nudge can be shown, at most, once per app reload, and shows for a task item when two
// incorrect answers have been given for that task item since the app was reloaded.
// When the feature flag is on:
// The video nudge will be shown every third attempt as long as the video hasn't been watched.
export const VideoNudgeStateProvider = ({ children }: PropsWithChildren) => {
  const featureFlags = useFeatureFlags();

  // the logic for showing three attempts is tied to the question swaps feature flag
  // this isn't great, but it's probably best to do this check for now.
  const threeAttemptsFFEnabled =
    featureFlags.getBooleanFlag('sparxweb2-question-swaps', false) ||
    featureFlags.getBooleanFlag('sparxweb2-three-attempts', false);

  const lastSeenVideoNudgeTask = useRef<string | undefined>();

  const [videoNudgeSeen, setVideoNudgeSeen] = useState(false);
  const [questionIncorrectCountsState, setQuestionIncorrectCountsState] = useState<
    Record<string, number | undefined>
  >({});

  const shouldShowVideoNudge = useCallback(
    (taskItem: TaskItemCompletion) => {
      const taskItemName = makeTaskItemName(
        taskItem.packageID,
        taskItem.taskIndex,
        taskItem.taskItemIndex,
      );

      if (!threeAttemptsFFEnabled) {
        return !videoNudgeSeen && (questionIncorrectCountsState[taskItemName] || 0) >= 2;
      } else {
        return (
          taskItem.numVideoWatched === 0 &&
          ((questionIncorrectCountsState[taskItemName] || 0) + 1) % 3 === 0
        );
      }
    },
    [questionIncorrectCountsState, threeAttemptsFFEnabled, videoNudgeSeen],
  );

  const updateVideoNudgeState = useCallback(
    (taskItem: TaskItemCompletion) => {
      if (!threeAttemptsFFEnabled && videoNudgeSeen === true) {
        return;
      }
      const taskItemName = makeTaskItemName(
        taskItem.packageID,
        taskItem.taskIndex,
        taskItem.taskItemIndex,
      );
      lastSeenVideoNudgeTask.current = taskItemName;
      setQuestionIncorrectCountsState(prevState => ({
        ...prevState,
        [taskItemName]: taskItem.numConsecutiveWrongAnswers,
      }));
    },
    [threeAttemptsFFEnabled, videoNudgeSeen],
  );

  const resetVideoNudgeState = useCallback((taskItemName: string) => {
    setQuestionIncorrectCountsState(prevState => ({
      ...prevState,
      [taskItemName]: 0,
    }));
  }, []);

  const handleVideoNudgeClose = useCallback(() => {
    setVideoNudgeSeen(true);

    if (lastSeenVideoNudgeTask.current) {
      resetVideoNudgeState(lastSeenVideoNudgeTask.current);
      lastSeenVideoNudgeTask.current = undefined;
      return;
    }

    setQuestionIncorrectCountsState({});
  }, [resetVideoNudgeState]);

  return (
    <VideoNudgeStateContext.Provider
      value={{
        shouldShowVideoNudge,
        updateVideoNudgeState,
        handleVideoNudgeClose,
      }}
    >
      {children}
    </VideoNudgeStateContext.Provider>
  );
};

import {
  Assessment,
  GetAssessmentSummaryResponse,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';

import { AssessmentsOverviewCardProps } from './AssessmentCard';

/**
 * Determine if an assessment is valid based on the validFrom and validUntil dates. An
 * assessment is valid if the current time is before the invalidUntil date and after the
 * validFrom date, if they exist.
 * @param the assessment to check
 */
const assessmentIsValid = (assessment: Assessment) => {
  const now = new Date();

  // If the validFrom date is set and is in the future, the assessment is invalid as we're too early
  if (assessment.validFrom && now < Timestamp.toDate(assessment.validFrom)) {
    return false;
  }

  // If the validUntil date is set and is in the past, the assessment is invalid as we're too late
  if (assessment.validUntil && now > Timestamp.toDate(assessment.validUntil)) {
    return false;
  }

  return true;
};

/**
 * Create the props for the cards for the assessments overview page from the summary
 * We create one card per assessment, except if we have a revision task for an assessment group, we create a separate
 * card which will be given the title of the assessment group.
 * @param summary the assessment summary response from the server
 */
export const getCards = (
  summary?: GetAssessmentSummaryResponse,
): AssessmentsOverviewCardProps[] => {
  const cards: AssessmentsOverviewCardProps[] = [];

  if (!summary) {
    return cards;
  }

  summary.assessments.forEach(a => {
    // Skip assessments which are not valid
    if (!assessmentIsValid(a)) {
      return;
    }

    const fixUpTask = summary.fixUpTasks.find(t => t.assessmentName === a.name);
    const revisionTask = summary.revisionTasks.find(t => t.assessmentName === a.name);

    // If we have a revision task which is for an assessment group, create a separate card for the assessment group:
    if (revisionTask && revisionTask.assessmentGroupName) {
      const assessmentGroup = summary.assessmentGroups.find(
        g => g.name === revisionTask.assessmentGroupName,
      );
      if (assessmentGroup) {
        cards.push({ assessment: a, assessmentGroup, revisionTask });
      }
    }

    // If we have a fix up task, or a revision which is not for an assessment group, create a card for the assessment:
    if (fixUpTask || (revisionTask && !revisionTask.assessmentGroupName)) {
      cards.push({
        assessment: a,
        fixUpTask,
        revisionTask: revisionTask?.assessmentGroupName ? undefined : revisionTask,
      });
    }
  });

  // Sort the cards by name which will be the assessment group name if there is one, or the assessment name if not:
  cards.sort((a, b) => {
    const aName = a.assessmentGroup?.displayName ?? a.assessment.displayName;
    const bName = b.assessmentGroup?.displayName ?? b.assessment.displayName;
    return aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase());
  });

  return cards;
};

import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

// There is a direct copy of this in
// CloudExperiments/teacherportal/tpclient2/src/components/minclientversionmanager/queries.ts.
// Any changes made here should also be made there.
// Once TP2 is moved into the js/apps directory, we should remove that copy

export function useMinClientVersion(assetURL: string, clientType: string) {
  return useQuery(
    ['clientMinVersion'],
    () =>
      fetch(`${assetURL}/min-client-version/${clientType}.json`).then(resp => resp.json()),
    {
      select: data => moment(data.isoString),
      staleTime: 10 * 60 * 1000, // 10 minutes
      cacheTime: 15 * 60 * 1000, // 15 minutes
    },
  );
}

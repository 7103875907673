import { StudentRewards } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { getNowTimestamp } from '@sparx/react-utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'context/api';
import { useRewards } from 'context/rewards';
import { useTraining } from 'context/training';
import { useCallback } from 'react';

const studentRewardsQueryKey = ['studentRewards'];

export const useGetStudentRewards = (opts?: { suspense?: boolean }) => {
  const { swworkerClient } = useAPI();
  const { enabled: trainingEnabled } = useTraining();
  const { setRewardsPending } = useRewards();
  return useQuery(
    studentRewardsQueryKey,
    async () => {
      const response = await swworkerClient.getStudentRewards({}).response;
      setRewardsPending({});
      return response;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      // If in training mode, add 10500 to the user's XP points
      select: data => {
        if (trainingEnabled) {
          const currentXPLevel = data.currentXPLevel || { level: 0, xpRequired: 1000 };
          const nextXPLevel = data.nextXPLevel || { level: 0, xpRequired: 1000 };
          return {
            ...data,
            currentXPLevel: {
              level: currentXPLevel.level + 10,
              xpRequired: currentXPLevel.xpRequired + 10000,
            },
            nextXPLevel: {
              level: nextXPLevel.level + 10,
              xpRequired: nextXPLevel.xpRequired + 10000,
            },
            xpPoints: data.xpPoints + 10500,
            xpLevels: [...data.xpLevels, getNowTimestamp()], // We just need to add today to show the latest level up date here
          };
        }
        return data;
      },
      suspense: opts?.suspense,
    },
  );
};

export const useSetStudentRewardsQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (rewards: StudentRewards | undefined) => {
      if (rewards !== undefined) {
        queryClient.setQueryData(studentRewardsQueryKey, rewards);
      }
    },
    [queryClient],
  );
};

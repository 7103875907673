interface Remark {
  shortRemark: string;
  longRemark: string;
}

export function getRemark(
  isCorrect: boolean,
  isSeekHelp: boolean,
  wrongAnswerCount: number,
  studentName?: string,
): Remark {
  if (isCorrect) {
    if (wrongAnswerCount > 0) {
      return randomRemark([
        {
          shortRemark: `You're doing really well!`,
          longRemark: studentName
            ? `You're doing really well, ${studentName}!`
            : `You're doing really well!`,
        },
        {
          shortRemark: 'Your hard work has paid off!',
          longRemark: studentName
            ? `Your hard work has paid off, ${studentName}!`
            : 'Your hard work has paid off!',
        },
        {
          shortRemark: 'You stuck with it!',
          longRemark: studentName ? `You stuck with it, ${studentName}!` : 'You stuck with it!',
        },
      ]);
    } else {
      return randomRemark([
        {
          shortRemark: studentName ? `Good work, ${studentName}!` : 'Good work!',
          longRemark: studentName ? `Good work, ${studentName}!` : 'Good work!',
        },
        {
          shortRemark: studentName ? `Great work, ${studentName}!` : 'Great work!',
          longRemark: studentName ? `Great work, ${studentName}!` : 'Great work!',
        },
        {
          shortRemark: studentName ? `Fantastic, ${studentName}!` : 'Fantastic!',
          longRemark: studentName ? `Fantastic, ${studentName}!` : 'Fantastic!',
        },
        {
          shortRemark: studentName ? `Excellent, ${studentName}!` : 'Excellent!',
          longRemark: studentName ? `Excellent, ${studentName}!` : 'Excellent!',
        },
        {
          shortRemark: studentName ? `Great effort, ${studentName}!` : 'Great effort!',
          longRemark: studentName ? `Great effort, ${studentName}!` : 'Great effort!',
        },
      ]);
    }
  } else {
    if (isSeekHelp) {
      return {
        shortRemark: `Stay confident.`,
        longRemark: studentName ? `Stay confident, ${studentName}.` : `Stay confident.`,
      };
    } else if (wrongAnswerCount === 3) {
      return randomRemark([
        {
          shortRemark: 'Why not watch the video?',
          longRemark: studentName
            ? `Why not watch the video, ${studentName}?`
            : 'Why not watch the video?',
        },
      ]);
    } else {
      return randomRemark([
        {
          shortRemark: studentName ? `Try again, ${studentName}!` : 'Try again!',
          longRemark: studentName ? `Try again, ${studentName}!` : 'Try again!',
        },
        {
          shortRemark: studentName ? `Have another go, ${studentName}!` : 'Have another go!',
          longRemark: studentName ? `Have another go, ${studentName}!` : 'Have another go!',
        },
        {
          shortRemark: studentName ? `Keep on trying, ${studentName}!` : 'Keep on trying!',
          longRemark: studentName ? `Keep on trying, ${studentName}!` : 'Keep on trying!',
        },
        {
          shortRemark: `Good effort. Next time!`,
          longRemark: studentName
            ? `Good effort, ${studentName}. Next time!`
            : `Good effort. Next time!`,
        },
        {
          shortRemark: `Stay confident. Keep going!`,
          longRemark: studentName
            ? `Stay confident, ${studentName}. Keep going!`
            : `Stay confident. Keep going!`,
        },
      ]);
    }
  }
}

export function randomRemark(possibleRemarks: Remark[]): Remark {
  return possibleRemarks[Math.floor(Math.random() * possibleRemarks.length)];
}

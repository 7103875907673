import { ChevronRightIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { MatchingResource } from '@sparx/api/apis/sparx/content/search/v1/search';
import { LearningPath } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { AssignedTopic } from '@sparx/api/apis/sparx/revision/v1/revision';
import { useClickHandler, useDebounce } from '@sparx/react-utils';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import { renderMixedTextToString } from '@sparx/text-with-maths';
import classNames from 'classnames';
import {
  useAllCurriculumTopicSearch,
  useCurriculumSummaries,
  useTopicSummariesMap,
} from 'queries/content';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { useAnalytics } from 'utils/analytics';
import { closestElement } from 'utils/dom';

import {
  useAllTopicLocationMap,
  useGetAssignedTopic,
  useSelectedCurriculumName,
  useSelectedDefaultLearningPath,
} from '../hooks';
import { SeenInHomework } from '../seen-in-homework/SeenInHomework';
import styles from './FindTopics.module.css';
import { MatchWithLocation, useGroupedSearchResults } from './hooks';
import { prioritiseSearchResults } from './utils';

// The maximum number of results to show from other curricula
const OTHER_CURRICULA_RESULTS_LIMIT = 10;

export type SelectTopicFunc = (args: {
  substrandName?: string;
  strandDisplayName?: string;
  substrandDisplayName?: string;
  topicName: string;
  topicCode?: string;
  // optional, omitted will assume selected stream
  learningPathSpecName?: string;
  level?: string;
}) => void;

export const TopicSearch = ({ selectTopic }: { selectTopic: SelectTopicFunc }) => {
  const sendEvent = useAnalytics();
  const selectedCurriculumName = useSelectedCurriculumName();
  const curriculums = useCurriculumSummaries();
  const curriculumLookup = useMemo(
    () => new Map(curriculums.map(c => [c.curriculum?.name, c])),
    [curriculums],
  );
  const selectedCurriculum = curriculumLookup.get(selectedCurriculumName);
  const topicSummariesMap = useTopicSummariesMap();
  const topicLocationMap = useAllTopicLocationMap();
  const selectedLearningPathSpec = useSelectedDefaultLearningPath();

  const inputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 250);

  const [inFocus, setInFocus] = useState(false);

  // Search for topics in the selected curriculum and all curricula
  const queries = useAllCurriculumTopicSearch(
    debouncedSearchTerm,
    selectedCurriculumName,
    'revision',
  );
  const loading = queries.some(q => q.isLoading);
  const searchResults = !loading && queries.flatMap(q => q.data || []);

  const getAssignedTopic = useGetAssignedTopic();
  // Filter out any topics which have only offline content in the search
  const results = useMemo(() => {
    if (topicSummariesMap && searchResults) {
      return searchResults.filter(result => {
        const topicDetails = topicSummariesMap.get(result.resourceName);
        if (!topicDetails) {
          // This should not happen unless there is a bug as we have checked the curriculum has been
          // loaded, and so the map should definitely include the topic
          // log.error(
          //   `Curriculum details does not contain topic found by search: ${result.resourceName}`,
          // );
          return false;
        }
        return topicDetails.objectiveCount > topicDetails.offlineObjectiveCount;
      });
    }
    return [];
  }, [searchResults, topicSummariesMap]);

  // Handler to show the dropdown when the search container is in focus and hide
  // it when they click away.
  useClickHandler((evt: MouseEvent | TouchEvent) => {
    if (evt.target) {
      const element = evt.target as Element;
      const focussed = closestElement(element, `.${styles.SearchContainer}`);
      setInFocus(!!focussed);
    }
  });

  const { selectedCurriculumResults, otherCurriculaResults } = useGroupedSearchResults(
    results,
    topicLocationMap,
  );

  if (!selectedLearningPathSpec) {
    return <div>Loading...</div>;
  }

  const highlightMatches = (r: MatchingResource): React.ReactNode => {
    const topic = topicSummariesMap?.get(r.resourceName)?.topic;
    if (!topic) {
      return null;
    }
    let highlighted = renderMixedTextToString(topic.displayName);
    let codeMatch = false;
    for (const match of r.matches) {
      if (match.resourceField === 'display_name') {
        highlighted = highlighted.replace(match.occurrence, `[[]]${match.occurrence}[[/]]`);
      }
      if (match.resourceField === 'code') {
        codeMatch = true;
      }
    }

    // Instead of replacing directly with <strong> in the loop we instead replace with something
    // that is very unlikely to be in the matchesList and then replace it afterwards. This is
    // to stop a match of 'on' replacing replacing on in a <strong> tag causing a bug like
    // <str<strong>g>
    highlighted = highlighted.replace(/\[\[\]\]/g, '<strong>');
    highlighted = highlighted.replace(/\[\[\/\]\]/g, '</strong>');

    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: highlighted }} />
        <span
          className={classNames(styles.SearchResultCode, {
            [styles.SearchResultCodeHighlight]: codeMatch,
          })}
        >
          {topic.code}
        </span>
      </>
    );
  };

  const clearResults = () => {
    inputRef.current?.focus();
    setSearchTerm('');
  };

  const showLoading = loading || searchTerm !== debouncedSearchTerm;
  const showDropdown = searchTerm !== '' && inFocus;
  const input = (
    <div className={styles.SearchInputContainer}>
      {showDropdown && (
        <div className={styles.SearchClear} onClick={clearResults}>
          <CrossCircledIcon />
        </div>
      )}
      <input
        type="text"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className={classNames(styles.Search, accessibilityStyles.FocusTarget)}
        placeholder="Enter topic name or code"
        maxLength={50}
        ref={inputRef}
      />
    </div>
  );

  const doSelectTopic: SelectTopicFunc = args => {
    const learningPathName = selectedLearningPathSpec.name;
    const topic = topicSummariesMap?.get(args.topicName);
    const paths = topic?.learningPaths || [];
    const topicPath = paths.find(path => path.specName === learningPathName);

    let learningPathSpecName = topicPath && topicPath.specName;
    let level = topicPath && topicPath.level;
    if (!learningPathSpecName) {
      // We should find the closest learning path to the current selected one
      let closestPath: LearningPath | undefined;
      // Sort the paths from hardest -> easiest
      const sortedPaths = paths.sort((a, b) => (a.level < b.level ? 1 : -1));
      for (const path of sortedPaths) {
        // If we don't break out the loop, the last path we see is the closest path
        // higher than the selected level.
        closestPath = path;
        if (path.level < selectedLearningPathSpec.displayName) {
          // We have found a path with easier level than selected, choose that one
          break;
        }
      }
      if (closestPath) {
        learningPathSpecName = closestPath.specName;
        level = closestPath.level;
      }
    }

    selectTopic({ ...args, learningPathSpecName, level });

    // Dispatch an event so we know they've clicked a result
    sendEvent({
      category: 'revision',
      action: 'search select',
      labels: {
        term: debouncedSearchTerm,
        topic: args.topicName,
        strand: String(args.strandDisplayName),
        substrand: String(args.substrandDisplayName),
        learningpath: String(learningPathSpecName),
      },
    });
  };

  // We only ever show a maximum of 10 results from other curricula
  const otherCurriculaResultsCount =
    otherCurriculaResults.length > OTHER_CURRICULA_RESULTS_LIMIT
      ? OTHER_CURRICULA_RESULTS_LIMIT
      : otherCurriculaResults.length;

  const dropdown = (
    <div className={classNames(styles.SearchResults, !showDropdown && styles.SearchResultsHide)}>
      <div className={styles.SearchSummary}>
        <span>
          {showLoading ? (
            <>
              <LoadingSpinner />
              <span className={styles.SearchLoadingText}>Loading...</span>
            </>
          ) : (
            <div className={styles.SearchContext}>
              <span>
                {selectedCurriculumResults.length} topic
                {selectedCurriculumResults.length === 1 ? '' : 's'} found in{' '}
                {selectedCurriculum?.curriculum?.displayName}
              </span>
              <span className={styles.OtherCurriculaResultsText}>
                <span className={styles.Separator}>|</span>
                {otherCurriculaResultsCount} in other curricula
              </span>
            </div>
          )}
        </span>
        <span className={styles.SearchSummaryClear} onClick={clearResults}>
          Clear search
        </span>
      </div>
      {(selectedCurriculumResults.length > 0 || otherCurriculaResults.length > 0) && (
        <>
          {selectedCurriculumResults.length > 0 && selectedCurriculum !== undefined && (
            <div className={styles.SearchResultsList}>
              <span className={styles.CurriculumText}>
                {selectedCurriculum?.curriculum?.displayName}
              </span>
              {selectedCurriculumResults.map((r, i) => (
                <SearchResult
                  key={i}
                  result={r}
                  highlightMatches={highlightMatches}
                  doSelectTopic={doSelectTopic}
                  getAssignedTopic={getAssignedTopic}
                />
              ))}
            </div>
          )}
          {otherCurriculaResults.length > 0 && (
            <div className={styles.SearchResultsList}>
              <span className={styles.CurriculumText}>Other curricula</span>
              {prioritiseSearchResults(otherCurriculaResults, OTHER_CURRICULA_RESULTS_LIMIT).map(
                (r, i) => (
                  <SearchResult
                    key={i}
                    otherCurriculaResults
                    result={r}
                    highlightMatches={highlightMatches}
                    doSelectTopic={doSelectTopic}
                    getAssignedTopic={getAssignedTopic}
                  />
                ),
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className={classNames(styles.TopicFilterContainer, styles.TopicFilterContainerSearch)}>
      <div className={styles.TopicFilterLabel}>Search for topics:</div>
      <div className={styles.SearchContainer}>
        {input}
        {dropdown}
      </div>
    </div>
  );
};

const SearchResult = ({
  result,
  highlightMatches,
  doSelectTopic,
  getAssignedTopic,
  otherCurriculaResults,
}: {
  result: MatchWithLocation;
  highlightMatches: (m: MatchingResource) => ReactNode;
  doSelectTopic: SelectTopicFunc;
  getAssignedTopic: (n: string) => AssignedTopic | undefined;
  otherCurriculaResults?: boolean;
}) => {
  const topicName = result.match.resourceName;
  const strandDisplayName = result.location.strandSummary.strand?.displayName || 'Error';
  const substrandName = result.location.substrandSummary.substrand?.name || '';
  const substrandDisplayName = result.location.substrandSummary.substrand?.displayName || 'Error';
  let curriculumName;
  if (otherCurriculaResults) {
    curriculumName = result.location.curriculumDetails.hierarchy.curriculum?.displayName;
  }
  return (
    <div
      className={styles.SearchResult}
      onClick={() =>
        doSelectTopic({
          topicName,
          substrandName,
          strandDisplayName,
          substrandDisplayName,
        })
      }
    >
      <span className={styles.SearchResultLocation}>
        {otherCurriculaResults && curriculumName && (
          <>
            {curriculumName}
            <ChevronRightIcon />
          </>
        )}
        {strandDisplayName}
        <ChevronRightIcon />
        {substrandDisplayName}
      </span>
      <span>
        {highlightMatches(result.match)}
        <HomeworkStatus assignedTopic={getAssignedTopic(topicName)} />
      </span>
      <div className={styles.SearchResultChevron}>
        <ChevronRightIcon />
      </div>
    </div>
  );
};

export const HomeworkStatus = ({ assignedTopic }: { assignedTopic?: AssignedTopic }) =>
  assignedTopic ? <SeenInHomework /> : null;

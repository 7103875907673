import * as Dialog from '@radix-ui/react-dialog';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { Video } from 'components/video/Video';
import { useTetherSession } from 'queries/tether';
import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { makeQuestionVideoUrls } from 'utils/urls';
import { TetheringActivityDisplay } from 'views/activity/ActivityDisplay';

import { useTetheringSearchParams } from './TetherHooks';
import styles from './TetherView.module.css';

export const TetherView = () => {
  const { isTethering, sessionName } = useTetheringSearchParams();
  if (!isTethering) {
    return <Navigate to="/" />;
  }

  return sessionName ? (
    <TetherContent sessionName={sessionName} />
  ) : (
    <ErrorPanel message="session parameter is required for tether mode" />
  );
};

export const TetherContent: FunctionComponent<{ sessionName: string }> = ({ sessionName }) => {
  const { data, isLoading, isError, error, restartActivity } = useTetherSession(sessionName);

  if (isLoading) {
    return (
      <LoadingSpinnerWithAnalytics componentName="TetherView" sendLongTimeLoadingEvent={true} />
    );
  }

  if (isError) {
    return <ErrorPanel message={(error as Error).message} />;
  }

  if (data.question) {
    return (
      <TetheringActivityDisplay
        tetherId={data.tetherId}
        tetherData={data.question}
        retryTask={() => restartActivity()}
      />
    );
  }

  if (data.video) {
    return <TetheringVideo videoId={data.video.videoID} />;
  }

  return <ErrorPanel message="didn't get a question or video to tether" />;
};

const TetheringVideo = ({ videoId }: { videoId: string }) => {
  const urls = makeQuestionVideoUrls(videoId);
  return (
    <Dialog.Root defaultOpen>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content className={classNames(dialogStyles.DialogContent, dialogStyles.FullWidth)}>
          <Dialog.Title className={dialogStyles.DialogTitle}>Support video</Dialog.Title>
          <div className={styles.VideoContainer}>
            <Video
              videoUrl={urls.videoUrl}
              posterUrl={urls.posterUrl}
              subtitleUrl={urls.subtitleUrl}
              disableAnalytics
            />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const ErrorPanel: FunctionComponent<{ message: string }> = ({ message }) => {
  return (
    <div className={styles.ErrorPanel}>
      <p>Error: {message}</p>
    </div>
  );
};

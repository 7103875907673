import * as Dialog from '@radix-ui/react-dialog';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Button } from '@sparx/sparx-design/components';
import { ChevronLeft, ChevronRight } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { Video } from 'components/video/Video';
import { TutorialKey, useIsTutorialComplete, useMarkTutorialComplete } from 'queries/tutorials';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { makeOnboardingVideoUrls } from 'utils/urls';

import styles from './AssessmentOnboardingVideoModal.module.css';

interface IAssessmentFixupOnboardingVideoModalProps {
  // The tutorial key to indicate that the user has watched the video
  tutorialKey: TutorialKey;
  // Title for the video modal
  title: string;
  // First part of the file name for each video file (video, poster, subtitles)
  videoPrefix: string;
  // Whether to show this video again after the initial watch
  reshow: boolean;
  // Callback for when the use has finished watching the video after being reshown it
  onReshown: () => void;
}

/**
 * OnboardingTaskVideoModal handles displaying the assessment fixup onboarding video.
 *
 * It shows the video once per user, and marks the tutorial complete on completion of the video.
 */
export const AssessmentOnboardingVideoModal = ({
  title,
  tutorialKey,
  videoPrefix,
  reshow,
  onReshown,
}: IAssessmentFixupOnboardingVideoModalProps) => {
  const sendEvent = useAnalytics();

  const videoUrls = makeOnboardingVideoUrls(videoPrefix);

  const { mutate: markTutorialComplete } = useMarkTutorialComplete();

  const [showOnboardingVideoFirstTime, setShowOnboardingVideoFirstTime] = useState(false);
  const hasWatchedOnboardingVideo = useIsTutorialComplete(tutorialKey);

  useEffect(() => {
    if (!hasWatchedOnboardingVideo && !showOnboardingVideoFirstTime) {
      setShowOnboardingVideoFirstTime(true);
    }
  }, [hasWatchedOnboardingVideo, showOnboardingVideoFirstTime]);

  const navigate = useNavigate();

  return (
    <Dialog.Root open={showOnboardingVideoFirstTime || reshow}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content className={classNames(dialogStyles.DialogContent, dialogStyles.FullWidth)}>
          <Dialog.Title className={dialogStyles.DialogTitle}>{title}</Dialog.Title>
          <div className={styles.VideoContainer}>
            <Video
              disableForwardSeeking
              videoUrl={videoUrls.videoUrl}
              posterUrl={videoUrls.posterUrl}
              subtitleUrl={videoUrls.subtitleUrl}
              onEnded={() => {
                markTutorialComplete([tutorialKey]);
              }}
            />
          </div>
          <div
            className={classNames(styles.VideoButtonsContainer, {
              [styles.VideoButtonsContainerSingleButton]: !showOnboardingVideoFirstTime,
            })}
          >
            {showOnboardingVideoFirstTime && (
              <Button
                leftIcon={<ChevronLeft />}
                onClick={() => {
                  setShowOnboardingVideoFirstTime(false);
                  navigate('/assessments');
                  sendEvent({
                    action: `${videoPrefix} onboarding video: clicked back`,
                    category: 'assessments',
                  });
                }}
                onKeyDown={simulateClickOnEnter}
                variant="outlined"
              >
                Back
              </Button>
            )}
            <Button
              as={Dialog.Close}
              isDisabled={!hasWatchedOnboardingVideo}
              rightIcon={<ChevronRight />}
              onClick={() => {
                if (showOnboardingVideoFirstTime) {
                  setShowOnboardingVideoFirstTime(false);
                  sendEvent({
                    action: `${videoPrefix} onboarding video: clicked next`,
                    category: 'assessments',
                  });
                } else {
                  onReshown();
                }
              }}
              onKeyDown={simulateClickOnEnter}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

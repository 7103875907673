import { Assessment, AssessmentQuestion } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { getSkillIDFromName } from '@sparx/resource-names';
import { useMemo } from 'react';

/**
 * Combine the questions by adjacent questions which share the same skill,
 * allowing us to combine the rows later for the fix up task items
 */
export const useCombinedQuestions = (assessment?: Assessment) =>
  useMemo(() => {
    const questions: AssessmentQuestion[][] = [];
    if (assessment) {
      for (const q of assessment.questions) {
        if (questions.length > 0) {
          const prevSkill = getSkillIDFromName(questions[questions.length - 1][0].onlineSkill);
          const curSkill = getSkillIDFromName(q.onlineSkill);
          if (prevSkill === curSkill) {
            questions[questions.length - 1].push(q);
            continue;
          }
        }
        questions.push([q]);
      }
    }
    return questions;
  }, [assessment]);

import { useContext } from 'react';

import { APIContext } from './provider';

export const useAPI = () => {
  const ctx = useContext(APIContext);
  if (!ctx) {
    throw new Error('useAPI must be used within a APIProvider');
  }
  return ctx;
};

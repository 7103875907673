// NOTE: This file is a port of lib/content/learningpathspec/mapping.go and a clone of teacherportal/tpclient2/src/lib/learningPathSpec.ts
import { Curriculum } from '@sparx/api/apis/sparx/content/v2/curriculum';

// All starter topics have this lp spec name
export const LPS_Starter_None =
  'curriculums/3a84256f-acc7-4293-9ac9-c38cf892353f/learningpathspecs/None';
export const LPS_Fundamentals_A =
  'curriculums/0d1b43f4-ff58-11eb-9c85-1b964e9bbc1e/learningpathspecs/vfhs3tvyu';
export const LPS_Fundamentals_B =
  'curriculums/0d1b43f4-ff58-11eb-9c85-1b964e9bbc1e/learningpathspecs/a9j0p4s9r';
export const LPS_KS3_A = 'curriculums/ba538918-2f6d-4b94-9b24-bd2e0786047e/learningpathspecs/A';
export const LPS_KS3_B = 'curriculums/ba538918-2f6d-4b94-9b24-bd2e0786047e/learningpathspecs/B';
export const LPS_KS3_C = 'curriculums/ba538918-2f6d-4b94-9b24-bd2e0786047e/learningpathspecs/C';
export const LPS_KS3_D = 'curriculums/ba538918-2f6d-4b94-9b24-bd2e0786047e/learningpathspecs/D';
export const LPS_GCSE_Foundation =
  'curriculums/aa7a8538-24af-4789-be80-0e23ff89a2e2/learningpathspecs/F';
export const LPS_GCSE_FoundationPlus =
  'curriculums/aa7a8538-24af-4789-be80-0e23ff89a2e2/learningpathspecs/F+';
export const LPS_GCSE_FoundationHigher =
  'curriculums/aa7a8538-24af-4789-be80-0e23ff89a2e2/learningpathspecs/FH';
export const LPS_GCSE_Higher =
  'curriculums/aa7a8538-24af-4789-be80-0e23ff89a2e2/learningpathspecs/H';
export const LPS_GCSE_HigherPlus =
  'curriculums/aa7a8538-24af-4789-be80-0e23ff89a2e2/learningpathspecs/H+';
export const LPS_IGCSE_Cambridge_Foundation =
  'curriculums/3ccf1dee-6451-11ea-8897-fb1f763ac88f/learningpathspecs/F';
export const LPS_IGCSE_Cambridge_FoundationPlus =
  'curriculums/3ccf1dee-6451-11ea-8897-fb1f763ac88f/learningpathspecs/F+';
export const LPS_IGCSE_Cambridge_FoundationHigher =
  'curriculums/3ccf1dee-6451-11ea-8897-fb1f763ac88f/learningpathspecs/FH';
export const LPS_IGCSE_Cambridge_Higher =
  'curriculums/3ccf1dee-6451-11ea-8897-fb1f763ac88f/learningpathspecs/H';
export const LPS_IGCSE_Cambridge_HigherPlus =
  'curriculums/3ccf1dee-6451-11ea-8897-fb1f763ac88f/learningpathspecs/H+';
export const LPS_IGCSE_OxfordAQA_Foundation =
  'curriculums/3d40dbc4-6392-11ea-a0aa-1fd9434aceb0/learningpathspecs/F';
export const LPS_IGCSE_OxfordAQA_FoundationPlus =
  'curriculums/3d40dbc4-6392-11ea-a0aa-1fd9434aceb0/learningpathspecs/F+';
export const LPS_IGCSE_OxfordAQA_FoundationHigher =
  'curriculums/3d40dbc4-6392-11ea-a0aa-1fd9434aceb0/learningpathspecs/FH';
export const LPS_IGCSE_OxfordAQA_Higher =
  'curriculums/3d40dbc4-6392-11ea-a0aa-1fd9434aceb0/learningpathspecs/H';
export const LPS_IGCSE_OxfordAQA_HigherPlus =
  'curriculums/3d40dbc4-6392-11ea-a0aa-1fd9434aceb0/learningpathspecs/H+';
export const LPS_IGCSE_EdexcelA_Foundation =
  'curriculums/afbad78e-647a-11ea-9cf4-97765456a8bc/learningpathspecs/F';
export const LPS_IGCSE_EdexcelA_FoundationPlus =
  'curriculums/afbad78e-647a-11ea-9cf4-97765456a8bc/learningpathspecs/F+';
export const LPS_IGCSE_EdexcelA_FoundationHigher =
  'curriculums/afbad78e-647a-11ea-9cf4-97765456a8bc/learningpathspecs/FH';
export const LPS_IGCSE_EdexcelA_Higher =
  'curriculums/afbad78e-647a-11ea-9cf4-97765456a8bc/learningpathspecs/H';
export const LPS_IGCSE_EdexcelA_HigherPlus =
  'curriculums/afbad78e-647a-11ea-9cf4-97765456a8bc/learningpathspecs/H+';
export const LPS_IGCSE_EdexcelB_Foundation =
  'curriculums/c9d3e71a-61ef-11ea-8f38-b7688b60ae65/learningpathspecs/F';
export const LPS_IGCSE_EdexcelB_FoundationPlus =
  'curriculums/c9d3e71a-61ef-11ea-8f38-b7688b60ae65/learningpathspecs/F+';
export const LPS_IGCSE_EdexcelB_FoundationHigher =
  'curriculums/c9d3e71a-61ef-11ea-8f38-b7688b60ae65/learningpathspecs/FH';
export const LPS_IGCSE_EdexcelB_Higher =
  'curriculums/c9d3e71a-61ef-11ea-8f38-b7688b60ae65/learningpathspecs/H';
export const LPS_IGCSE_EdexcelB_HigherPlus =
  'curriculums/c9d3e71a-61ef-11ea-8f38-b7688b60ae65/learningpathspecs/H+';
export const LPS_FurtherMaths_1 =
  'curriculums/9e8309cc-4592-11ed-9011-af041fc3aa47/learningpathspecs/pmfs5t1a1';
export const LPS_FurtherMaths_2 =
  'curriculums/9e8309cc-4592-11ed-9011-af041fc3aa47/learningpathspecs/jdp6snf79';
export const LPS_FurtherMaths_3 =
  'curriculums/9e8309cc-4592-11ed-9011-af041fc3aa47/learningpathspecs/bjjjwami6';

// NOTE: This map code is duplicated with an identical version on the server:
//  lib/content/learningpathspec/mapping.go
// These are the resource names for the learning path specs which are equivalent
//
// Some learning path specs don't have direct equivalents so when we search for an equivalent, we have to look in easier
// and harder levels.
const equivalentLevels = [
  [
    LPS_Starter_None,
    LPS_Fundamentals_A,
    LPS_KS3_A,
    LPS_GCSE_Foundation,
    LPS_IGCSE_Cambridge_Foundation,
    LPS_IGCSE_OxfordAQA_Foundation,
    LPS_IGCSE_EdexcelA_Foundation,
    LPS_IGCSE_EdexcelB_Foundation,
  ],
  [
    LPS_Fundamentals_B,
    LPS_KS3_B,
    LPS_GCSE_FoundationPlus,
    LPS_IGCSE_Cambridge_FoundationPlus,
    LPS_IGCSE_OxfordAQA_FoundationPlus,
    LPS_IGCSE_EdexcelA_FoundationPlus,
    LPS_IGCSE_EdexcelB_FoundationPlus,
  ],
  [
    LPS_KS3_C,
    LPS_GCSE_FoundationHigher,
    LPS_IGCSE_Cambridge_FoundationHigher,
    LPS_IGCSE_OxfordAQA_FoundationHigher,
    LPS_IGCSE_EdexcelA_FoundationHigher,
    LPS_IGCSE_EdexcelB_FoundationHigher,
    LPS_FurtherMaths_1,
  ],
  [
    LPS_KS3_D,
    LPS_GCSE_Higher,
    LPS_IGCSE_Cambridge_Higher,
    LPS_IGCSE_OxfordAQA_Higher,
    LPS_IGCSE_EdexcelA_Higher,
    LPS_IGCSE_EdexcelB_Higher,
    LPS_FurtherMaths_2,
  ],
  [
    LPS_GCSE_HigherPlus,
    LPS_IGCSE_Cambridge_HigherPlus,
    LPS_IGCSE_OxfordAQA_HigherPlus,
    LPS_IGCSE_EdexcelA_HigherPlus,
    LPS_IGCSE_EdexcelB_HigherPlus,
    LPS_FurtherMaths_3,
  ],
];

/**
 * GetEquivalentLearningPathSpec takes a learningPathSpecName and returns the equivalent level learning path spec in
 * the provided curriculum.
 * NOTE: This mapping code is duplicated with an identical implementation on the server:
 *  lib/content/learningpathspec/mapping.go
 * @param learningPathSpecName
 * @param curriculumName
 */
export const getEquivalentLearningPathSpec = (
  learningPathSpecName: string,
  curriculumName: string,
): string | undefined => {
  // If the learning path is already in the provided curriculum, just return it.
  if (learningPathSpecName.startsWith(curriculumName)) {
    return learningPathSpecName;
  }

  // Find the learning path in the equivalent levels array.
  for (let levelIndex = 0; levelIndex < equivalentLevels.length; levelIndex++) {
    const equivalentLearningPathSpecs = equivalentLevels[levelIndex];
    for (const lps of equivalentLearningPathSpecs) {
      if (lps === learningPathSpecName) {
        // See if there is a direct equivalent in the provided curriculum.
        for (const equivalentLearningPathSpec of equivalentLearningPathSpecs) {
          if (equivalentLearningPathSpec.startsWith(curriculumName)) {
            return equivalentLearningPathSpec;
          }
        }

        // If not, see if there is an easier level.
        for (let easierLevelIndex = levelIndex - 1; easierLevelIndex >= 0; easierLevelIndex--) {
          const easierLevel = equivalentLevels[easierLevelIndex];
          for (const easierLearningPathSpec of easierLevel) {
            if (easierLearningPathSpec.startsWith(curriculumName)) {
              return easierLearningPathSpec;
            }
          }
        }

        // If not, go for a harder level.
        for (
          let harderLevelIndex = levelIndex + 1;
          harderLevelIndex < equivalentLevels.length;
          harderLevelIndex++
        ) {
          const harderLevel = equivalentLevels[harderLevelIndex];
          for (const harderLearningPathSpec of harderLevel) {
            if (harderLearningPathSpec.startsWith(curriculumName)) {
              return harderLearningPathSpec;
            }
          }
        }
      }
    }
  }

  // If not, give up.
  return undefined;
};

/**
 * mapLearningPathSpecToCurriculum takes the name of a learning path spec and returns the name of the equivalent learning path spec in the given curriculum.
 */
export const mapLearningPathSpecToCurriculum = (
  learningPathSpecName: string,
  curriculum: Curriculum | undefined,
): string => {
  // If we don't have a learning path to map from, don't do any mapping.
  if (!learningPathSpecName || learningPathSpecName === 'none') {
    return learningPathSpecName;
  }

  // If we don't have a curriculum to map to, don't do any mapping.
  if (!curriculum) {
    return learningPathSpecName;
  }

  // Do the mapping.
  const mappedLearningPathSpecName = getEquivalentLearningPathSpec(
    learningPathSpecName,
    curriculum.name,
  );
  if (!mappedLearningPathSpecName) {
    return learningPathSpecName;
  }
  return mappedLearningPathSpecName;
};

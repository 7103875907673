import { AssessmentLQD } from './lqd/AssessmentLQD';
import { AssessmentsOverview } from './overview/AssessmentsOverview';
import { AssessmentResults } from './results/AssessmentResults';
import { AssessmentRevision } from './revision/AssessmentRevision';

// Routes for assessments
export const assessmentResultsRoute = ':assessmentID';
export const assessmentRevisionRoute = ':assessmentID/revision';
export const assessmentLQDRoute = ':assessmentId/package/:packageId/task/:taskIndex/*';

export { AssessmentLQD, AssessmentResults, AssessmentRevision, AssessmentsOverview };

import {
  TaskItemCompletion as TaskItem,
  TaskItemStatus,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { PieChart, ProgressWheel } from '@sparx/sparx-design/components';
import { Tooltip } from '@sparx/sparx-design/components/tooltip/Tooltip';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { ChevronRight, Cross, Flag, Tick } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'components/accordion/Accordion';
import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './AssessmentTaskNavigation.module.css';

export type NavItem = {
  displayName: string;
  taskItem?: TaskItem;
};

type AssessmentTaskSidebarProps = {
  navItems: NavItem[];
  makeNavItemPath: (taskItemIndex: number) => string;
  title: string;
  summaryPath: string;
  activeTaskItemIndex?: number | 'summary';
  isRevision: boolean;
};

export const AssessmentTaskNavigation = ({
  activeTaskItemIndex,
  navItems,
  title,
  makeNavItemPath,
  summaryPath,
  isRevision,
}: AssessmentTaskSidebarProps) => {
  const isSmallScreen = useBreakpoint('sm');
  const [topBarOpen, setTopBarOpen] = useState(false);
  const closeTopBar = isSmallScreen ? () => setTopBarOpen(false) : undefined;

  const totalTaskItems = navItems.filter(n => !!n.taskItem).length;
  const completeTaskItems = navItems.filter(n => n.taskItem?.status === TaskItemStatus.DONE).length;

  const scrollTo = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      node.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, []);

  const topBarTitle =
    navItems.find(n => n.taskItem?.taskItemIndex === activeTaskItemIndex)?.displayName ?? 'Menu';

  const navTabs = navItems.map(item => (
    <NavTab
      key={item.displayName}
      text={item.displayName}
      isSelected={item.taskItem?.taskItemIndex === activeTaskItemIndex}
      href={item.taskItem ? makeNavItemPath(item.taskItem.taskItemIndex) : undefined}
      taskItemStatus={item.taskItem?.status}
      scrollTo={scrollTo}
      onClick={closeTopBar}
      isSmallScreen={isSmallScreen}
    />
  ));
  navTabs.push(
    <NavTab
      key="Summary"
      text="Summary"
      isSelected={activeTaskItemIndex === 'summary'}
      isSummary
      href={summaryPath}
      scrollTo={scrollTo}
      onClick={closeTopBar}
    />,
  );

  return isSmallScreen ? (
    <Topbar
      open={topBarOpen}
      setOpen={v => setTopBarOpen(v)}
      selectedTaskItemName={topBarTitle}
      completeTaskItems={completeTaskItems}
      totalTaskItems={totalTaskItems}
      isRevision={isRevision}
    >
      {navTabs}
    </Topbar>
  ) : (
    <Sidebar title={title} completeTaskItems={completeTaskItems} totalTaskItems={totalTaskItems}>
      {navTabs}
    </Sidebar>
  );
};

const NavTab = ({
  text,
  isSelected,
  href,
  taskItemStatus,
  isSummary,
  scrollTo,
  onClick,
  isSmallScreen,
}: {
  text: string;
  isSelected: boolean;
  href?: string;
  taskItemStatus?: TaskItemStatus;
  isSummary?: boolean;
  scrollTo?: (node: HTMLElement | null) => void;
  onClick?: () => void;
  isSmallScreen?: boolean;
}) => {
  let icon: ReactNode | null = null;
  let statusClass = '';
  switch (taskItemStatus) {
    case TaskItemStatus.DONE:
      icon = <Tick variant="Green" />;
      statusClass = styles.Complete;
      break;
    case TaskItemStatus.SEEKHELP:
      icon = (
        <div className={styles.SeekHelpIconContainer}>
          <Flag className={styles.SeekHelpIcon} variant="Purple" />
        </div>
      );
      break;
    case TaskItemStatus.WRONG:
    case TaskItemStatus.WRONGWITHVIDEONUDGE:
    case TaskItemStatus.WARNING:
      icon = <Cross variant="Red" />;
      statusClass = styles.Incorrect;
      break;
  }

  if (isSelected && !isSummary && !isSmallScreen) {
    icon = <ChevronRight className={styles.Chevron} />;
  }

  const disabled = taskItemStatus === undefined;
  return !isSummary && disabled ? (
    <Tooltip content="You do not need to complete this question." position="right">
      <p className={classNames(styles.NavTab, styles.Disabled, statusClass)}>{text}</p>
    </Tooltip>
  ) : (
    <Link
      ref={isSelected ? scrollTo : undefined}
      className={classNames(
        styles.NavTab,
        !isSelected && styles.Interactive,
        statusClass,
        isSelected && styles.Selected,
      )}
      to={href ?? ''}
      onClick={() => onClick?.()}
    >
      {text}
      {icon}
    </Link>
  );
};

const Sidebar = ({
  title,
  completeTaskItems,
  totalTaskItems,
  children,
}: PropsWithChildren<{ title: string; completeTaskItems: number; totalTaskItems: number }>) => {
  return (
    <aside className={styles.Sidebar}>
      <div className={styles.ProgressWheelContainer}>
        <ProgressWheel
          completed={completeTaskItems}
          total={totalTaskItems}
          wheelSize={{ barWidth: 8, outerCircleRadius: 35 }}
        />
      </div>
      <p className={styles.Title}>{title}</p>
      <nav>{children}</nav>
    </aside>
  );
};

const Topbar = ({
  open,
  setOpen,
  selectedTaskItemName,
  completeTaskItems,
  totalTaskItems,
  isRevision,
  children,
}: PropsWithChildren<{
  open: boolean;
  setOpen: (v: boolean) => void;
  completeTaskItems: number;
  totalTaskItems: number;
  isRevision: boolean;
  selectedTaskItemName: string;
}>) => {
  return (
    <AccordionRoot
      // Set the Accordion's value as either an array with a single string for the item if it's
      // open, or an empty array if it's closed. Accordion uses it's value to determine which
      // AccordionItem is open.
      value={open ? ['dropdown-menu'] : []}
      onValueChange={v => {
        setOpen(!!v.length);
      }}
      className={classNames(styles.Topbar, styles.AccordionRoot, {
        // Allow the menu to be scrollable, but only when open. If the menu always has these
        // styles then it covers the bottom bar, even when closed, which prevents the user from
        // interacting with the bottom bar.
        [styles.AccordionRootScrollable]: open,
      })}
    >
      <AccordionItem value="dropdown-menu">
        <AccordionTrigger className={styles.AccordionTrigger}>
          <div className={styles.AccordionTriggerContent}>
            {selectedTaskItemName}
            <div className={styles.ProgressContainer}>
              {isRevision ? 'Revision' : 'Fix up'} progress
              <PieChart score={completeTaskItems} total={totalTaskItems} />
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent className={styles.AccordionContent}>{children}</AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  );
};

import { QuestionType } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { TaskItemCompletion, TaskItemStatus } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { makeAssessmentName } from '@sparx/resource-names';
import { Button, Chip, ProgressWheel } from '@sparx/sparx-design/components';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { Tick } from '@sparx/sparx-design/icons';
import tableStyles from '@sparx/sparx-design/shared-styles/Table.module.css';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { MaxWidth } from 'components/page/Page';
import { useAssessment, useAssessmentFixUpTask, useFixUpTaskItems } from 'queries/assessments';
import { useTopicSummariesMap } from 'queries/content';
import { useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getStudentMark } from 'utils/assessment';

import { TutorialKey } from '../../../queries/tutorials';
import { useCombinedQuestions } from '../hooks';
import { AssessmentOnboardingVideoModal } from '../onboarding-video/AssessmentOnboardingVideoModal';
import styles from './AssessmentsResults.module.css';

const TaskItemQuestionNameLabel = 'assessment.questions';

const StudentMark = ({
  studentMark,
  availableMark,
}: {
  studentMark?: number;
  availableMark: number;
}) => {
  if (studentMark === undefined) {
    return null;
  }

  let style = styles.SomeMarks;
  if (studentMark === 0) {
    style = styles.ZeroMarks;
  } else if (studentMark === availableMark) {
    style = styles.FullMarks;
  }
  return (
    <div className={classNames(styles.MarkContainer, style)}>
      <div className={classNames(styles.StudentMark)}>{studentMark}</div>
      <div className={classNames(styles.AvailableMark)}>/{availableMark}</div>
    </div>
  );
};

const parseQuestionType = (questionType: QuestionType) => {
  switch (questionType) {
    case QuestionType.FLUENCY:
      return 'Fluency';
    case QuestionType.PROBLEM_SOLVING:
      return 'Problem solving';
    default:
      return 'Unspecified';
  }
};

export const AssessmentResults = () => {
  const { assessmentID } = useParams();
  const navigate = useNavigate();
  const isSmallScreen = useBreakpoint('md');

  const assessmentName = makeAssessmentName(assessmentID || '');
  const assessment = useAssessment(assessmentName);
  const { data: fixUpTask } = useAssessmentFixUpTask(assessmentName);
  const topicSummariesMap = useTopicSummariesMap();

  const { taskItems: fixUpTaskItems } = useFixUpTaskItems(assessmentName);
  const hasFixUpItems = fixUpTaskItems.length > 0;
  const fixUpProgress = fixUpTaskItems.filter(ti => ti.status === TaskItemStatus.DONE).length;

  const combinedQuestions = useCombinedQuestions(assessment);

  const completed = fixUpProgress ? fixUpProgress === fixUpTaskItems.length : false;

  const [reshowOnboardingVideo, setReshowOnboardingVideo] = useState(false);

  // If the assessment name is invalid or can't be found, redirect to the main assessments page.
  if (!assessmentID || !assessment || !fixUpTask?.studentAssessment) {
    console.error('Could not find assessment information', {
      assessmentID,
      assessment,
      studentAssessment: fixUpTask?.studentAssessment,
    });
    return <Navigate to="/assessments" />;
  }

  const studentAssessment = fixUpTask.studentAssessment;

  const handleOnClickFixupAll = () => {
    if (hasFixUpItems) {
      const taskItem = fixUpTaskItems[0];
      navigate(`package/${taskItem.packageID}/task/${taskItem.taskIndex}`);
    }
  };

  return (
    <>
      <MaxWidth className={styles.SmallScreenNoPadding} maxWidthClassName={styles.TableMaxWidth}>
        <div className={styles.Wrapper}>
          <div className={styles.Header}>
            <div className={styles.Title}>{assessment.displayName}</div>
            {hasFixUpItems ? (
              <div className={styles.HeaderButtonContainer}>
                <div>
                  <Button size="sm" variant="text" onClick={() => setReshowOnboardingVideo(true)}>
                    What is this?
                  </Button>
                </div>
                <div>
                  <Button
                    size="md"
                    variant={completed ? 'outlined' : 'contained'}
                    className={styles.Button}
                    onClick={handleOnClickFixupAll}
                    isDisabled={completed}
                  >
                    {isSmallScreen
                      ? completed
                        ? 'Complete'
                        : 'Fix up'
                      : completed
                        ? 'Fix up task complete'
                        : fixUpProgress
                          ? 'Continue fixing up'
                          : 'Fix up all questions'}
                  </Button>
                </div>

                {!!fixUpProgress && !isSmallScreen && (
                  <ProgressWheel
                    completed={fixUpProgress}
                    total={fixUpTaskItems.length}
                    wheelSize={{ barWidth: 8, outerCircleRadius: 35 }}
                  />
                )}
              </div>
            ) : (
              <div className={styles.NothingToFixUp}>
                <p>Nothing to Fix up!</p>
                <p>
                  Why not try some{' '}
                  <Link className={styles.Link} to={'/independentlearning'}>
                    Indepenent Learning?
                  </Link>
                </p>
              </div>
            )}
          </div>
          <table className={classNames(tableStyles.Table, styles.Table)}>
            {!isSmallScreen && (
              <thead>
                <tr className={classNames(tableStyles.TableHead, styles.TableRow)}>
                  <th>Q</th>
                  <th>Topic</th>
                  <th>Unit</th>
                  <th>Topic Code</th>
                  <th>Question Type</th>
                  <th align="center">Mark</th>
                  {hasFixUpItems && <th align="center">Fix up</th>}
                </tr>
              </thead>
            )}
            <tbody className={tableStyles.TableBody}>
              {combinedQuestions.map(qns => {
                // Does this set of questions have a fix up task item?
                const fixUpTaskItem =
                  (hasFixUpItems &&
                    fixUpTaskItems.find(ti =>
                      ti.labels[TaskItemQuestionNameLabel]?.includes(qns[0].name),
                    )) ||
                  undefined;
                // The rows will be combined if we have a fix up task item and there is more than 1 question in this group
                const combineRows = fixUpTaskItem && qns.length > 1;

                return qns.map((qn, i) => (
                  <tr
                    key={qn.name}
                    className={classNames(
                      tableStyles.TableRow,
                      styles.TableRow,
                      combineRows && i < qns.length - 1 && styles.FixUpCombinedStart,
                      combineRows && i > 0 && styles.FixUpCombinedEnd,
                    )}
                  >
                    {isSmallScreen ? (
                      <>
                        <td>{`${isSmallScreen ? 'Q.' : ''}${qn.displayName}`}</td>
                        <td>
                          <ul className={styles.TopicNameList}>
                            {qn.curriculumTopicNames.map(topicName => (
                              <li key={topicName} className={styles.TopicDetails}>
                                <TextWithMaths
                                  text={topicSummariesMap.get(topicName)?.topic?.displayName || ''}
                                  className={styles.TopicNameSmallScreen}
                                />
                                <div className={styles.TopicSubheadingSmallScreen}>
                                  {topicSummariesMap.get(topicName)?.topic?.code}
                                  &nbsp;&nbsp;|&nbsp;&nbsp;{qn.unit}&nbsp;&nbsp;|&nbsp;&nbsp;
                                  {parseQuestionType(qn.type)}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{qn.displayName}</td>
                        <td>
                          <ul className={styles.TopicNameList}>
                            {qn.curriculumTopicNames.map(topicName => (
                              <li key={topicName} className={styles.TopicName}>
                                <TextWithMaths
                                  text={topicSummariesMap.get(topicName)?.topic?.displayName || ''}
                                />
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>{qn.unit}</td>
                        <td>
                          <ul className={styles.TopicNameList}>
                            {qn.curriculumTopicNames.map(topicName => (
                              <li key={topicName} className={styles.TopicCode}>
                                {topicSummariesMap.get(topicName)?.topic?.code}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>{parseQuestionType(qn.type)}</td>
                      </>
                    )}
                    <td>
                      <StudentMark
                        studentMark={getStudentMark(qn.name, studentAssessment)?.score}
                        availableMark={qn.availableMarks}
                      />
                    </td>
                    {hasFixUpItems && (
                      <FixUpCell taskItem={fixUpTaskItem} numQs={qns.length} qIdx={i} />
                    )}
                  </tr>
                ));
              })}
            </tbody>
          </table>
        </div>
      </MaxWidth>
      <AssessmentOnboardingVideoModal
        tutorialKey={TutorialKey.AssessmentFixupOnboardingVideo}
        title="Assessments"
        videoPrefix="assessment-fixup"
        reshow={reshowOnboardingVideo}
        onReshown={() => setReshowOnboardingVideo(false)}
      />
    </>
  );
};

const FixUpCell = ({
  taskItem,
  numQs,
  qIdx,
}: {
  taskItem?: TaskItemCompletion;
  numQs: number;
  qIdx: number;
}) => {
  const navigate = useNavigate();

  if (!taskItem) {
    return <td />;
  }

  if (qIdx > 0) {
    return null;
  }

  const handleOnClickFixup = () => {
    navigate(
      `package/${taskItem.packageID}/task/${taskItem.taskIndex}/item/${taskItem.taskItemIndex}`,
    );
  };

  const cellContents = () => {
    switch (taskItem.status) {
      case TaskItemStatus.DONE:
        return (
          <Chip colourVariant="Correct" className={styles.Tick}>
            <Tick variant="White" />
          </Chip>
        );
      default:
        return (
          <Button
            size="sm"
            variant="contained"
            className={styles.Button}
            onClick={handleOnClickFixup}
          >
            Fix up
          </Button>
        );
    }
  };
  return (
    <td align="center" rowSpan={numQs}>
      {cellContents()}
    </td>
  );
};

import {
  AssignedTopic,
  GetActivePackagesRequest,
  GetAssignedTopicsRequest,
  GetPackagesForObjectivesRequest,
  GetStudentProfileRequest,
} from '@sparx/api/apis/sparx/revision/v1/revision';
import {
  SendIndependentLearningTablesAnswersRequest,
  SendIndependentLearningTablesAnswersResponse,
} from '@sparx/api/apis/sparx/swworker/v1/worker';
import { getCurriculumIDFromName } from '@sparx/resource-names';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import { queryClient } from 'app/queryClient';
import { useAPI } from 'context/api';

import { updateWithPackageData } from './packages';
import { useSession } from './session';

export const useStudentProfile = () => {
  const { data: session } = useSession();
  const { revisionClient } = useAPI();
  return useQuery(
    ['independentlearning', 'studentprofile'],
    () => revisionClient.getStudentProfile(GetStudentProfileRequest.create({})).response,
    {
      select: data => data.profile,
      // Only fetch for normal students
      enabled: session?.student?.studentType === 'Student',
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
    },
  );
};

export const useAssignedTopics = () => {
  const { revisionClient } = useAPI();
  return useQuery(
    ['independentlearning', 'assignedtopics'],
    () => revisionClient.getAssignedTopics(GetAssignedTopicsRequest.create({})).response,
    {
      select: data =>
        data.topics.reduce((m, v) => m.set(v.assignedTopicId, v), new Map<string, AssignedTopic>()),
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
    },
  );
};

export const useActivePackages = (curriculumName: string) => {
  const { revisionClient } = useAPI();
  return useQuery(
    ['independentlearning', 'activepackages', curriculumName],
    () =>
      revisionClient.getActivePackages(
        GetActivePackagesRequest.create({
          curriculumId: getCurriculumIDFromName(curriculumName),
        }),
      ).response,
    {
      onSuccess: data => {
        // Update the shared package queries
        data.packageData && updateWithPackageData(data.packageData);
      },
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
    },
  );
};

export const usePackagesForObjectives = (
  curriculumName: string,
  topicLevelName: string,
  learningPaths: string[],
) => {
  const { revisionClient } = useAPI();
  return useQuery(
    ['independentlearning', 'packagesforobjectives', curriculumName, topicLevelName, learningPaths],
    () =>
      revisionClient.getPackagesForObjectives(
        GetPackagesForObjectivesRequest.create({
          curriculumName,
          topicLevelName,
          objectiveNames: learningPaths,
        }),
      ).response,
    {
      onSuccess: data => {
        // Update the shared package queries
        data.packageData && updateWithPackageData(data.packageData);
        // getPackagesForObjectives can create new packages we need to ensure
        // active packages also knows about this new package so we invalidate the query
        queryClient.invalidateQueries(['independentlearning', 'activepackages', curriculumName]);
      },
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
    },
  );
};

/**
 * React Query mutation to send times tables from independent learning, where
 * there is no activity or package, to the server
 * @param options
 */
export const useSendIndependentLearningTimesTablesAnswer = (
  options?: UseMutationOptions<
    SendIndependentLearningTablesAnswersResponse,
    unknown,
    SendIndependentLearningTablesAnswersRequest,
    unknown
  >,
) => {
  const { swworkerClient } = useAPI();
  return useMutation(
    (req: SendIndependentLearningTablesAnswersRequest) =>
      swworkerClient.sendIndependentLearningTablesAnswers(req).response,
    options,
  );
};

import { useContext } from 'react';

import { RewardsContext } from './provider';

export const useRewards = () => {
  const ctx = useContext(RewardsContext);
  if (!ctx) {
    throw new Error('useRewards can only be used inside a RewardsProvider');
  }
  return ctx;
};

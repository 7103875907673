import {
  Activity,
  ActivityType,
  GetActivityRequest_Method,
  QuestionActivity,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { checkInput, IInput, useLayoutSteps } from '@sparx/question';
import {
  buildClearQuizAnswerAction,
  buildPauseQuizAction,
  buildStoreQuizAnswerAction,
  buildViewQuizAction,
  useActivityAction,
  useNewActivity,
} from 'queries/activity';
import { useSubmitQuizAnswers } from 'queries/ftq';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export enum FTQStep {
  QUESTION = 'question',
  ANSWER = 'answer',
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
  RESULT = 'result',
}

enum InputState {
  VALID = 'valid',
  INVALID = 'invalid',
  UNATTEMPTED = 'unattempted',
}

// useFastTrackQuiz is a hook that manages getting and sending actions for activities in a fast track
// quiz
export const useFastTrackQuiz = (
  packageID: string,
  taskIndex: number,
  taskItemIndex: number,
  ftqStep: FTQStep,
  setFTQStep: (step: FTQStep) => void,
) => {
  // create the mutations to get and process the activity
  const {
    mutate: getNewActivity,
    data: activity,
    isLoading: isGetActivityLoading,
    isError: isGetActivityError,
  } = useNewActivity(
    ActivityType.QUIZ,
    packageID,
    taskIndex,
    taskItemIndex,
    GetActivityRequest_Method.RESUMABLE,
  );

  const {
    mutate: viewActivity,
    isLoading: isViewActivityLoading,
    isError: isViewActivityError,
  } = useActivityAction({ retry: 5 });

  const {
    mutateAsync: storeAnswerForActivity,
    isLoading: isStoreAnswerLoading,
    isError: isStoreAnswerError,
  } = useActivityAction({ retry: 5 });

  const {
    mutateAsync: clearAnswerForActivity,
    isLoading: isClearAnswerLoading,
    isError: isClearAnswerError,
  } = useActivityAction({ retry: 5 });

  const {
    mutateAsync: pauseAnswerForActivity,
    isLoading: isPauseAnswerLoading,
    isError: isPauseAnswerError,
  } = useActivityAction({ retry: 5 });

  const {
    mutate: submitQuizAnswers,
    isLoading: isSubmitQuizAnswersLoading,
    isError: isSubmitQuizAnswersError,
  } = useSubmitQuizAnswers({ retry: 5 });

  // get the question and layout out of the activity
  const question =
    activity && activity?.payload.oneofKind === 'question' ? activity.payload.question : undefined;
  const step = useLayoutSteps(question?.questionSpec || undefined)[0];

  // initialize the input state
  const [input, setInput] = useState<IInput | undefined>(step?.input);

  // keep inputs for each question in a map, so we can remember the answers between activities
  const inputsRef = useRef(new Map<number | undefined, IInput>());

  // also track the state of each input (Valid, Invalid, Unattempted)
  const inputsStateRef = useRef(new Map<string | undefined, InputState>());

  // track the 'lastGotActivity' so that we don't start two activities (as this effect also depends
  // on activity), and we can keep refs in sync correctly
  const lastGotActivity = useRef<string>();

  // track the 'lastViewedActivity' so we don't keep sending view actions for active activities
  const lastViewedActivity = useRef<string>();

  // track the step that the current activity is relating to, so we can use it in effects without
  // depending on it.
  const activityStep = useRef<FTQStep>();

  // calculate the activity key which is used to identify the activity in lastGotActivity and
  // lastViewedActivity
  const activityKey = useMemo(
    () => `${packageID}-${taskIndex}-${taskItemIndex}-${ftqStep}`,
    [ftqStep, packageID, taskIndex, taskItemIndex],
  );

  // Store the activity key in a ref so that we can use it without depending on it.
  const activityKeyRef = useRef(activityKey);
  // and keep it up to date.
  useEffect(() => {
    activityKeyRef.current = activityKey;
  }, [activityKey]);

  // when the task item changes, update the input. Use either a stored input from the inputsRefMap, or
  // the empty input from the layout if not provided
  useEffect(() => {
    if (step?.input && activityKeyRef.current === lastGotActivity.current) {
      setInput((taskItemIndex && inputsRef.current.get(taskItemIndex)) || step?.input);
    }
  }, [step, taskItemIndex]);

  // when the input changes, add it to the inputsRef map, once the new activity is has been fetched
  // also update the inputsStateRef map
  useEffect(() => {
    if (activityKeyRef.current === lastGotActivity.current && input) {
      inputsRef.current.set(taskItemIndex, input);
    }
  }, [input, taskItemIndex]);

  // ref to store when we have got an activity, but we have not yet sent the request to view it. This
  // is used to keep a consistent loading state between getting and viewing an activity.
  const activityGotButNotViewed = useRef(false);

  // work out what cleanup action to take for the previous activity, based on the input state. This is
  // used before getting a new activity, or when submitting answers
  const getCleanupAction = useCallback(
    (
      inputState: InputState | undefined,
      activity: Activity,
      question: QuestionActivity,
      input: IInput,
    ) => {
      switch (inputState) {
        case InputState.VALID:
          // if the answer is valid, store it
          return async () =>
            await storeAnswerForActivity(buildStoreQuizAnswerAction(activity, question, input));
        case InputState.INVALID:
          // if it is invalid, clear it (store with no answer)
          return async () =>
            await clearAnswerForActivity(buildClearQuizAnswerAction(activity, question));
        case InputState.UNATTEMPTED:
        case undefined:
          // if it is unattempted, pause it
          return async () => await pauseAnswerForActivity(buildPauseQuizAction(activity, question));
      }
    },
    [clearAnswerForActivity, pauseAnswerForActivity, storeAnswerForActivity],
  );

  // when we navigate to a new taskItem, or switch to a new step that isn't submission or results, we
  // clean up the previous activity, and then fetch a new one.
  // - in question step, do nothing, as getting a new activity will dismiss the old one
  // - in answer step, store the answer before getting the new activity
  useEffect(() => {
    if (
      ftqStep !== FTQStep.SUBMITTING &&
      ftqStep !== FTQStep.SUBMITTED &&
      ftqStep !== FTQStep.RESULT
    ) {
      if (lastGotActivity.current !== activityKeyRef.current) {
        if (activityStep.current === FTQStep.ANSWER && activity && question && input) {
          // on the answer step, we need to pause, store, or clear the answer depending on the input
          // state
          const doCleanupAndGetNewActivity = async () => {
            try {
              if (input) {
                await getCleanupAction(
                  inputsStateRef.current.get(lastGotActivity.current || ''),
                  activity,
                  question,
                  input,
                )();
              }
            } finally {
              getNewActivity();
            }
          };
          doCleanupAndGetNewActivity();
        } else if (activityStep.current === FTQStep.QUESTION && activity && question) {
          // on the question, we just pause the activity
          const doCleanupAndGetNewActivity = async () => {
            try {
              await pauseAnswerForActivity(buildPauseQuizAction(activity, question));
            } finally {
              getNewActivity();
            }
          };
          doCleanupAndGetNewActivity();
        } else {
          // if theres no activity to cleanup, just get a new one
          getNewActivity();
        }
        activityGotButNotViewed.current = true;
      }
      lastGotActivity.current = activityKeyRef.current;
    }
    activityStep.current = ftqStep;
  }, [
    taskItemIndex,
    ftqStep,
    activity,
    question,
    input,
    getNewActivity,
    storeAnswerForActivity,
    pauseAnswerForActivity,
    clearAnswerForActivity,
    getCleanupAction,
  ]);

  // when the activity changes, view it
  useEffect(() => {
    if (activity && question && activityKeyRef.current !== lastViewedActivity.current) {
      viewActivity(buildViewQuizAction(activity, question));
      lastViewedActivity.current = activityKeyRef.current;
      activityGotButNotViewed.current = false;
    }
  }, [activity, question, viewActivity]);

  // callback for submitting quiz answers.
  // before submitting quiz answers, store the answer for the current activity, then submit the answers
  const onSubmitQuizAnswers = (packageID: string, taskIndex: number, onSuccess: () => void) => {
    if (activity && question && input) {
      const cleanupAndSubmit = async () => {
        setFTQStep(FTQStep.SUBMITTING);
        try {
          await getCleanupAction(
            inputsStateRef.current.get(lastGotActivity.current || ''),
            activity,
            question,
            input,
          )();
        } finally {
          submitQuizAnswers(
            {
              task: {
                packageID: packageID,
                taskIndex: taskIndex,
                taskItemIndex: 0,
                taskState: 0,
              },
              skipFTQ: false,
            },
            {
              onSuccess: () => {
                onSuccess();
                setFTQStep(FTQStep.SUBMITTED);
              },
            },
          );
        }
      };
      cleanupAndSubmit();
    }
  };

  return {
    input: input,
    setInput: (input: IInput) => {
      setInput(input);
      inputsStateRef.current.set(
        activityKey,
        checkInput(input) ? InputState.VALID : InputState.INVALID,
      );
    },
    onSubmitQuizAnswers: onSubmitQuizAnswers,
    step: step,
    isLoading:
      isGetActivityLoading ||
      isViewActivityLoading ||
      isPauseAnswerLoading ||
      isStoreAnswerLoading ||
      isClearAnswerLoading ||
      activityGotButNotViewed.current,
    isError:
      isGetActivityError ||
      isViewActivityError ||
      isPauseAnswerError ||
      isStoreAnswerError ||
      isClearAnswerError ||
      isSubmitQuizAnswersError ||
      (activity?.payload.oneofKind && activity?.payload.oneofKind !== 'question'),
    // separate submit answer loading state so we can show it on the modal
    isSubmitQuizAnswersLoading,
  };
};

import { Dictionary } from '../types';
import { ReactComponent as Trophy1 } from './Trophy_1.svg';
import { ReactComponent as Trophy2 } from './Trophy_2.svg';
import { ReactComponent as Trophy3 } from './Trophy_3.svg';

export const positionToAddon: Dictionary<number, React.ReactNode> = {
  1: <Trophy1 />,
  2: <Trophy2 />,
  3: <Trophy3 />,
};

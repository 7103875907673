import { useEffect, useState } from 'react';

// There is a direct copy of this in
// CloudExperiments/teacherportal/tpclient2/src/components/minclientversionmanager/localStorage.ts.
// Any changes made here should also be made there.
// Once TP2 is moved into the js/apps directory, we should remove that copy

// Browser storage returns a `string` or `null` as the value of a given key.
// A result of `null` indicates the key isn't set; setting to `null` unsets the key.
type StorageItem = string | null;

// Define the return type for useLocalStorage to match that of useState<StorageItem>
type StorageStateHook = [StorageItem, React.Dispatch<React.SetStateAction<StorageItem>>];

// useLocalStorage returns the current value and a setter fn for the given key in local storage
export const useLocalStorage = (key: string): StorageStateHook => {
  const [value, setValue] = useState<StorageItem>(localStorage.getItem(key));

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
};

import { ForwardedRef, forwardRef } from 'react';
import { renderMarkdownAndMathInElement } from './markdown-math';

const transformAnswerXSLTString = `
<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
  <xsl:param name="url" />
  <xsl:param name="withWBR" />
  <xsl:param name="imageSuffix" />

  <xsl:template match="steps">
    <div class="step">
      <xsl:apply-templates/>
    </div>
  </xsl:template>

  <xsl:template match="answer">
    <div class="answer">
      <xsl:apply-templates/>
    </div>
  </xsl:template>

  <xsl:template match="part">
    <div class="answer-part">
      <xsl:apply-templates/>
    </div>
  </xsl:template>

  <xsl:template match="slot|number|choice">
    <xsl:if test="$withWBR != 0">
      <wbr/>
    </xsl:if>
    <span class="answer-block {local-name()}-answer-block">
      <xsl:apply-templates/>
    </span>
    <xsl:if test="$withWBR != 0">
      <wbr/>
    </xsl:if>
  </xsl:template>

  <xsl:template match="image">
    <xsl:choose>
      <xsl:when test="starts-with(., 'https://')">
        <!-- if it looks like a url just use it as is, this should probably only happen during tethering -->
        <img class="image" src="{text()}" style="max-width: 500px; max-height: 500px;"/>
      </xsl:when>
      <xsl:otherwise>
        <img class="image" src="{$url}/{text()}{$imageSuffix}" style="max-width: 500px; max-height: 500px;"/>
      </xsl:otherwise>
    </xsl:choose>
  </xsl:template>

  <xsl:template match="text">
    <span>
      <xsl:apply-templates/>
    </span>
  </xsl:template>
</xsl:stylesheet>
`;

const parseXML = (doc: string): HTMLElement =>
  new DOMParser().parseFromString(doc, 'application/xml').documentElement;

const getTransformAnswer = () => {
  // The tests were failing because XSLTProcessor wasn't defined.  This is a
  // quick fix.
  if (!window.hasOwnProperty('XSLTProcessor')) {
    return (answerElem: HTMLElement): HTMLElement => {
      const out = document.createElement('div');
      out.innerText = answerElem.outerHTML;
      return out;
    };
  }
  const xsltProcessor = new XSLTProcessor();
  const node = parseXML(transformAnswerXSLTString);
  xsltProcessor.importStylesheet(node);

  return (
    answerElem: HTMLElement,
    contentAssetsURL?: string,
    withoutWBR?: boolean,
    useFullResImages?: boolean
  ): HTMLElement => {
    if (contentAssetsURL !== undefined) {
      xsltProcessor.setParameter('', 'url', contentAssetsURL);
    }
    xsltProcessor.setParameter('', 'withWBR', withoutWBR ? '0' : '1');
    xsltProcessor.setParameter(
      '',
      'imageSuffix',
      useFullResImages ? '' : '/thumbnail'
    );
    return xsltProcessor.transformToDocument(answerElem).documentElement;
  };
};

const transformAnswer = getTransformAnswer();

interface AnswerRendererProps {
  markup: string;
  contentAssetsURL?: string;
  noWBR?: boolean;
  useFullResImages?: boolean;

  // Optional scaling factor to apply to transform the answer
  scaleFactor?: number;
}

export const AnswerRenderer = forwardRef(
  (
    {
      markup,
      contentAssetsURL,
      noWBR,
      useFullResImages,
      scaleFactor,
    }: AnswerRendererProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const encodedMarkup = window.btoa(markup.replace(/[^\x20-\x7E]/g, ''));

    if (markup === '') {
      return <div>Unattempted</div>;
    }

    // Check the root element for the markdown attribute
    const parsedMarkup = parseXML(markup);
    const withMarkdown = parsedMarkup.getAttribute('markdown') === 'true';

    const frag = transformAnswer(
      parsedMarkup,
      contentAssetsURL,
      noWBR,
      useFullResImages
    );
    renderMarkdownAndMathInElement(frag, withMarkdown);
    return (
      <div
        ref={ref}
        dangerouslySetInnerHTML={{ __html: frag.innerHTML }}
        data-test-target={encodedMarkup}
        style={
          scaleFactor !== undefined
            ? { transform: `scale(${scaleFactor})` }
            : undefined
        }
      />
    );
  }
);

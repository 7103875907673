import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

import styles from './TransitionContainer.module.css';

export const TransitionContainer = ({
  children,
  reverse,
}: PropsWithChildren<{ reverse?: boolean }>) => {
  return (
    <div className={styles.TransitionContainer}>
      <AnimatePresence custom={reverse} initial={true}>
        {children}
      </AnimatePresence>
    </div>
  );
};

const ease = cubicBezier(0.35, 0.17, 0.3, 0.86);

export const TransitionPage = ({
  children,
  className,
  k,
  enterReverse,
  direction = 'horizontal',
}: PropsWithChildren<{
  className?: string;
  k?: React.Key;
  enterReverse?: boolean;
  direction?: 'horizontal' | 'vertical';
}>) => {
  // If the task is a fix up task then animate questions vertically, otherwise they animate
  // horizontally.
  const key = direction === 'horizontal' ? 'x' : 'y';
  const enter = {
    [key]: enterReverse ? '-100%' : '100%',
  };
  const exit = (reverse: boolean) => ({
    [key]: reverse ? '100%' : '-100%',
  });
  return (
    <motion.div
      key={k}
      variants={{
        enter,
        exit,
      }}
      initial="enter"
      exit="exit"
      animate={{ opacity: 1, x: 0, y: 0 }}
      transition={{ ease, duration: 0.4 }}
      className={className || styles.TransitionPage}
    >
      {children}
    </motion.div>
  );
};

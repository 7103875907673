import { useActiveHomeworkPackages } from 'queries/packages';
import { useIsStaffUser } from 'queries/session';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useFeatureFlags } from 'utils/feature-flags';

export interface OnboardingLockState {
  onboardingLockEnabled: boolean;
  onboardingTasksCompleteCount: number;
}

export const OnboardingLockContext = createContext<OnboardingLockState>({
  onboardingLockEnabled: false,
  onboardingTasksCompleteCount: 0,
});

export const OnboardingLockProvider = ({ children }: { children: ReactNode }) => {
  const featureFlags = useFeatureFlags();

  const [onboardingLockEnabled, setOnboardingLockState] = useState<boolean>(false);
  const [onboardingTasksCompleteCount, setOnboardingTasksCompleteCount] = useState<number>(0);

  const { data: allPackages } = useActiveHomeworkPackages();

  // The onboarding lock isn't used for teachers as they have a different kind of onboarding that it would interfere with
  const isStaffUser = useIsStaffUser();

  // Filter out cancelled homework
  const packages = allPackages?.filter(p => !p.deletedTime);

  // Get onboarding package
  const onboardingPackage = packages?.find(p => p.packageType === 'onboarding');

  const onboardingPackageExists = !!onboardingPackage;

  const numberOfOnboardingTasksComplete = onboardingPackage?.numTasksComplete || 0;

  const onboardingStarted = numberOfOnboardingTasksComplete > 0;

  useEffect(() => {
    setOnboardingLockState(onboardingPackageExists && !onboardingStarted && !isStaffUser);
    setOnboardingTasksCompleteCount(numberOfOnboardingTasksComplete);
  }, [
    featureFlags,
    numberOfOnboardingTasksComplete,
    onboardingPackageExists,
    onboardingStarted,
    isStaffUser,
  ]);

  return (
    <OnboardingLockContext.Provider
      value={{
        onboardingLockEnabled,
        onboardingTasksCompleteCount,
      }}
    >
      {children}
    </OnboardingLockContext.Provider>
  );
};

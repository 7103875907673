import { LargeLoading } from '@sparx/sparx-design/icons';
import { useNavigationContext } from 'context/navigation/hook';
import { PackageProvider } from 'context/package/PackageProvider';
import { useAssessment } from 'queries/assessments';
import { usePackage, usePackageTasks, useTaskItems } from 'queries/packages';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
  getLQDTaskItemPaths,
  useLQDTaskInfo,
  useRetryQuestion,
  useReverseAnimation,
  useTaskCompleteOnLoad,
  useTaskItemCompleteOnLoad,
} from 'utils/lqd';
import { makeTaskItemPath, makeTaskSummaryPath, useLQDPath } from 'utils/paths';
import { ActivityDisplay } from 'views/activity/ActivityDisplay';
import { TransitionContainer, TransitionPage } from 'views/lqd/TransitionContainer';

import { useCombinedQuestions } from '../hooks';
import styles from './AssessmentLQD.module.css';
import { AssessmentLQDSummary } from './AssessmentLQDSummary';
import { AssessmentTaskNavigation, NavItem } from './AssessmentTaskNavigation';

/**
 * AssessmentLQD handles the LQD flow and navigation for assessment packages. If the assessment,
 * package, or task cannot be resolved from the URL, the user will be redirected back to the
 * relevant assessment or revision page for the assessment.
 *
 * If only a package and task are specified, the component will try to find the first incomplete
 * task item in the package and redirect to it.
 *
 * For Fix up tasks, if the task is already complete when the user first navigates to it, they will
 * be redirected back to the assessment page. For revision, they should still be able to access the
 * task so that they can retry new versions of the questions.
 */
export const AssessmentLQD = () => {
  const location = useLocation();

  const lqdPath = useLQDPath();
  const { parentPath, packageID, taskIndex, taskItemIndex, isSummary } = lqdPath;

  const assessmentName = parentPath ? parentPath.replace(/^\//, '') : '';

  const reverseAnimation = useReverseAnimation(undefined);

  const retryQuestion = useRetryQuestion();

  const { setBackPath } = useNavigationContext();

  const assessment = useAssessment(assessmentName);
  const combinedQuestions = useCombinedQuestions(assessment);

  const { data: pkg, isFetching: pkgLoading } = usePackage(packageID ?? '');
  const { data: tasks, isFetching: tasksLoading } = usePackageTasks(pkg?.packageID ?? '');
  const currentTask = tasks?.find(t => t.taskIndex === taskIndex);

  const { data: taskItems, isFetching: taskItemsLoading } = useTaskItems(
    pkg?.packageID ?? '',
    currentTask?.taskIndex,
  );
  const currentTaskItem = taskItems?.find(ti => ti.taskItemIndex === taskItemIndex);

  const taskCompleteOnLoad = useTaskCompleteOnLoad(tasks);
  const taskItemCompleteOnLoad = useTaskItemCompleteOnLoad(taskItems);

  const isRevision = pkg?.packageType === 'assessment-revision';
  const backPath = isRevision ? `${parentPath}/revision` : parentPath ?? '/assessments';
  useEffect(() => {
    setBackPath(backPath);
    return () => setBackPath(undefined);
  }, [backPath, setBackPath]);

  const {
    isTaskComplete,
    isTaskItemAttempted,
    isTaskItemOnNonInitialChance,
    isTaskItemOnFinalChance,
    isLastTaskItem,
  } = useLQDTaskInfo(currentTask, taskItems, currentTaskItem);

  if (pkgLoading || tasksLoading || taskItemsLoading) {
    return <LargeLoading />;
  }

  if (!assessment || !pkg || !tasks || !currentTask || !taskItems) {
    return <Navigate to={backPath} />;
  }

  if (!isRevision && taskCompleteOnLoad) {
    return <Navigate to={backPath} />;
  }

  const {
    firstIncompleteTaskItemPath,
    previousTaskItemPath,
    currentTaskItemPath,
    nextIncompleteTaskItemPath,
  } = getLQDTaskItemPaths(lqdPath, taskItems);

  if (!currentTaskItem && !isSummary) {
    return <Navigate to={firstIncompleteTaskItemPath} replace />;
  }

  const navItems: NavItem[] = isRevision
    ? taskItems.map(t => ({
        taskItem: t,
        displayName: `Q${t.taskItemIndex}`,
      }))
    : combinedQuestions.flatMap(questions =>
        questions.map(q => ({
          displayName: `Q${q.displayName}`,
          taskItem: taskItems.find(ti => ti.labels['assessment.questions'].includes(q.name)),
        })),
      );

  const showCorrectQuestionOverlay = taskItemCompleteOnLoad && !retryQuestion;

  const navTitle = isRevision ? currentTask.title : 'Fix up progress';

  return (
    <div className={styles.AssessmentLQD}>
      <AssessmentTaskNavigation
        isRevision={isRevision}
        title={navTitle}
        navItems={navItems}
        activeTaskItemIndex={isSummary ? 'summary' : currentTaskItem?.taskItemIndex}
        makeNavItemPath={ti => makeTaskItemPath(parentPath, packageID, taskIndex, ti)}
        summaryPath={makeTaskSummaryPath(parentPath, packageID, taskIndex)}
      />
      <TransitionContainer reverse={reverseAnimation}>
        <div key={taskItemIndex}>
          <TransitionPage
            k={location.pathname}
            enterReverse={isSummary ? false : reverseAnimation}
            direction="vertical"
          >
            <div className={styles.ActivityDisplayContainer}>
              {isSummary ? (
                <AssessmentLQDSummary
                  mode={isRevision ? 'revision' : 'fixup'}
                  tasks={tasks}
                  task={currentTask}
                  taskItems={taskItems}
                />
              ) : (
                <PackageProvider pkg={pkg}>
                  <ActivityDisplay
                    packageID={pkg.packageID}
                    taskIndex={currentTask.taskIndex}
                    taskItemIndex={currentTaskItem?.taskItemIndex ?? 0}
                    packageType={pkg.packageType}
                    isItemOnNonInitialChance={isTaskItemOnNonInitialChance}
                    isTaskItemOnFinalChance={isTaskItemOnFinalChance}
                    isLastItem={isLastTaskItem}
                    isTaskComplete={isTaskComplete}
                    isItemAttempted={isTaskItemAttempted}
                    isItemSwapped={false}
                    currentTaskItemPath={currentTaskItemPath}
                    nextTaskItemPath={nextIncompleteTaskItemPath}
                    hideBookworkCodes
                    showCorrectQuestionOverlay={!!showCorrectQuestionOverlay}
                    previousTaskItemPath={previousTaskItemPath}
                    numSwapsUsed={0}
                  />
                </PackageProvider>
              )}
            </div>
          </TransitionPage>
        </div>
      </TransitionContainer>
    </div>
  );
};

import { RewardGroup, RewardGroup_Type } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { createContext, PropsWithChildren, SetStateAction, useState } from 'react';

// RewardsContextData tracks various xp reward state for the student
//
// activityRewards track xp awarded to the student whilst interacting with the activity system -
// typically for completing tasks and packages. The rewards are stored in a map from resource name
// (task / package) to a RewardGroup object.
//
// rewardsPending tracks whether there are any pending rewards yet to be updated in the
// studentRewards query cache
//
// currentLevel tracks the student's current level in order to work out when the student has
// levelled up
//
// disableLevelUpScreen is a flag to disable showing the level up dialog on certain screens
type RewardsContextData = {
  activityRewards: Dictionary<string, RewardGroup>;
  setActivityRewards: (setState: SetStateAction<Dictionary<string, RewardGroup>>) => void;

  rewardsPending: Dictionary<RewardGroup_Type, boolean>;
  setRewardsPending: (setState: SetStateAction<Dictionary<RewardGroup_Type, boolean>>) => void;

  currentLevel?: number;
  setCurrentLevel: (level: number) => void;

  disableLevelUpDialog: boolean;
  setDisableLevelUpDialog: (disable: boolean) => void;
};

export const RewardsContext = createContext<RewardsContextData>({
  activityRewards: {},
  setActivityRewards: () => undefined,
  rewardsPending: {},
  setRewardsPending: () => undefined,
  currentLevel: undefined,
  setCurrentLevel: () => undefined,
  disableLevelUpDialog: false,
  setDisableLevelUpDialog: () => undefined,
});

export const RewardsProvider = ({ children }: PropsWithChildren) => {
  const [activityRewards, setActivityRewards] = useState<Dictionary<string, RewardGroup>>({});
  const [rewardsPending, setRewardsPending] = useState<Dictionary<RewardGroup_Type, boolean>>({});
  const [currentLevel, setCurrentLevel] = useState<number | undefined>(undefined);
  const [disableLevelUpDialog, setDisableLevelUpDialog] = useState(false);
  return (
    <RewardsContext.Provider
      value={{
        activityRewards,
        setActivityRewards,
        rewardsPending,
        setRewardsPending,
        currentLevel,
        setCurrentLevel,
        disableLevelUpDialog,
        setDisableLevelUpDialog,
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
};

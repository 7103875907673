import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { makePackageName } from '@sparx/resource-names';
import { Button } from '@sparx/sparx-design/components';
import { Tooltip } from '@sparx/sparx-design/components/tooltip/Tooltip';
import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import classNames from 'classnames';
import { useRewards } from 'context/rewards';
import img_popper from 'images/icon-popper.svg';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { useGetStudentRewards } from 'queries/studentrewards';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import {
  getBookworkDialIcon,
  getBookworkLevel,
  useShouldShowBookworkAccuracy,
} from 'utils/bookworkAccuracy';
import { useFeatureFlags } from 'utils/feature-flags';
import { isPkgOrTaskComplete } from 'utils/package';
import { makeHomePath, makeTaskSummaryPath } from 'utils/paths';
import { Rewards } from 'views/lqd/package-complete/Rewards';
import summaryStyles from 'views/lqd/summary/Summary.module.css';

import completeViewStyles from './PackageCompleteView.module.css';

interface IPackageCompleteView {
  pkg: PackageCompletion;
  taskIndex: number;
}

export const PackageCompleteView: FunctionComponent<IPackageCompleteView> = ({
  pkg,
  taskIndex,
}) => {
  const { data: session } = useSession();
  const sendEvent = useAnalytics();
  const homePath = makeHomePath({ packageID: pkg.packageID });
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const useNewXp = featureFlags.getBooleanFlag('new-xp-system', false);

  // tooltipOpenedTime stores the time when the tooltip was opened, so we can measure how long
  // it is open for
  const tooltipOpenedTime = useRef<number | undefined>(undefined);

  const showBookworkAccuracy = useShouldShowBookworkAccuracy(pkg.startDate);
  const swapsEnabled = featureFlags.getBooleanFlag('sparxweb2-question-swaps', false);
  const { refetch: refetchRewards } = useGetStudentRewards();
  const { activityRewards, setDisableLevelUpDialog } = useRewards();
  const packageCompletionRewards = useMemo(
    () => activityRewards[makePackageName(pkg.packageID)],
    [activityRewards, pkg.packageID],
  );

  const xpHomework = session?.student?.lessonsEnabled ? 240 : 420;
  // this is what happens in the backend, so just duplicate the logic as we don't persist the xp
  // earned per task elsewhere
  const packageTaskXPEarned = Math.floor(xpHomework / pkg.numTasks) * pkg.numTasks;

  useEffect(() => {
    if (packageCompletionRewards !== undefined) {
      refetchRewards();
    }
  }, [refetchRewards, packageCompletionRewards]);

  // don't show the level up dialog on this screen
  useEffect(() => {
    setDisableLevelUpDialog(true);
    return () => {
      setDisableLevelUpDialog(false);
    };
  }, [setDisableLevelUpDialog]);

  // if the student has not completed this package, redirect them
  if (!isPkgOrTaskComplete(pkg)) {
    const redirectPath = makeTaskSummaryPath('', pkg.packageID, taskIndex);
    return <Navigate to={redirectPath} replace />;
  }

  const bookworkLevel = getBookworkLevel(pkg.completion);
  const dialIcon = getBookworkDialIcon(bookworkLevel);

  return (
    <div className={completeViewStyles.QuestionContainer}>
      <KeyboardShortcuts
        enterFunc={() => {
          navigate(homePath);
        }}
        source="package-complete-view"
      />
      <div
        className={classNames(summaryStyles.SummaryContainer, completeViewStyles.SummaryContainer)}
      >
        <p className={completeViewStyles.PackageCompleteTitle} role="heading">
          Homework Complete
        </p>
        <p className={completeViewStyles.PackageCompleteText}>
          Well done, {getUsersNamesFromSession(session, 'firstOnly')}!
        </p>
        <img className={completeViewStyles.PopperImage} src={img_popper} />
        {showBookworkAccuracy && !useNewXp && (
          <Tooltip
            position="top"
            content="This shows how well you have done on your bookwork checks. Remember that doing well with your bookwork means you will get fewer checks."
            onOpenChange={open => {
              if (open) {
                tooltipOpenedTime.current = new Date().getTime();
                sendEvent({
                  action: 'opened package completion bookwork accuracy tooltip',
                  category: 'lqd',
                  labels: {
                    bookworkLevel: bookworkLevel,
                  },
                });
              } else {
                sendEvent({
                  action: 'closed package completion bookwork accuracy tooltip',
                  category: 'lqd',
                  labels: {
                    timeOpen: (new Date().getTime() - (tooltipOpenedTime.current || 0)) / 1000,
                  },
                });
                tooltipOpenedTime.current = undefined;
              }
            }}
          >
            <p className={completeViewStyles.BookworkAccuracyText}>
              Your bookwork accuracy for this homework:{' '}
              <img className={completeViewStyles.BookworkAccuracyIcon} src={dialIcon} />
            </p>
          </Tooltip>
        )}
        {packageCompletionRewards && (
          <Rewards
            xpRewards={packageCompletionRewards}
            taskXP={packageTaskXPEarned}
            pkgCompletion={pkg}
            showBookworkAccuracy={showBookworkAccuracy}
            swapsEnabled={swapsEnabled}
          />
        )}
        <Button
          size="lg"
          as={Link}
          to={homePath}
          variant="contained"
          onClick={() => {
            sendEvent({ action: 'clicked back to task list from summary', category: 'lqd' });
          }}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

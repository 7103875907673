import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { LearningPath, LearningPathSpec } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { makeCurriculumName } from '@sparx/resource-names';
import {
  Breadcrumb,
  ProgressBadge,
  ProgressBadgeScore,
  Tooltip,
} from '@sparx/sparx-design/components';
import { IBreadcrumbItem } from '@sparx/sparx-design/components/breadcrumb/Breadcrumb';
import { Info } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import workbookIcon from 'images/workbook.svg';
import { useCurriculumSummary, useTopicSummariesMapForCurriculum } from 'queries/content';
import { useActivePackages, usePackagesForObjectives } from 'queries/independentlearning';
import { useAllCachedPackages } from 'queries/packages';
import { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { validateUUID } from 'utils/uuid';

import {
  useSelectedCurriculumName,
  useSelectedDefaultLearningPath,
  useTopicLocationMap,
} from '../hooks';
import { LevelSelect } from '../level-select/LevelSelect';
import { calculateLearningPathProgress, getLearningPathProgressScore } from '../utils';
import { IndependentLearningPackage } from './IndependentLearningPackage';
import { TopicLinks } from './IndependentLearningTopicLinks';
import styles from './IndependentLearningTopicView.module.css';

/**
 * IndependentLearningTopicView shows the student all the packages, tasks and task items in the
 * given Topic, as well as building blocks within their current curriculum and an option for
 * changing the current level.
 */

export const IndependentLearningTopicView = () => {
  const {
    curriculumID: curriculumIDParam,
    strandID,
    substrandID,
    topicID: topicIDParam,
    level,
  } = useParams();
  const curriculumID = validateUUID(curriculumIDParam);
  const topicID = validateUUID(topicIDParam);

  const navigate = useNavigate();
  const sendEvent = useAnalytics();
  const selectedCurriculumName = useSelectedCurriculumName();
  const curriculumName = curriculumID ? makeCurriculumName(curriculumID) : selectedCurriculumName;
  const curriculumSummary = useCurriculumSummary(curriculumName);
  const topicSummariesMap = useTopicSummariesMapForCurriculum(curriculumName);
  const defaultLearningPathSpec = useSelectedDefaultLearningPath();
  const topicLocationMap = useTopicLocationMap(curriculumSummary);
  const { data: activePackages } = useActivePackages(selectedCurriculumName);
  const packageCompletions = useAllCachedPackages();

  const lpSpecName = defaultLearningPathSpec?.name;
  const topicSummary = topicSummariesMap?.get(`curriculums/${curriculumID}/topics/${topicID}`);
  const learningPaths = topicSummary?.learningPaths || [];

  let topicPath: LearningPath | undefined;
  // If a level is specified in the url, try and find the learning path which matches it
  if (level) {
    topicPath = learningPaths.find(path => path.level === level);
  }
  // If there is no level in the url, or a learning path that corresponds to the level provided
  // cannot be found, try and find the learning path which matches the student's default level.
  if (!topicPath) {
    topicPath = learningPaths.find(path => path.specName === lpSpecName);
  }
  const learningPathSpecName = topicPath && topicPath.specName;

  // Fetch the packages for the current topic
  const { data: packages } = usePackagesForObjectives(
    selectedCurriculumName,
    learningPathSpecName || '',
    topicPath?.learningUnitNames || [],
  );

  // Calculate the progress on the learning path for the progress badge in top right:
  const learningPathProgress = useMemo(() => {
    return calculateLearningPathProgress(
      topicSummariesMap,
      activePackages?.packages,
      packageCompletions,
    );
  }, [activePackages, packageCompletions, topicSummariesMap]);

  if (curriculumID === '' || topicID === '') {
    return <Navigate to="/independentlearning" replace />;
  }

  if (
    !topicSummariesMap ||
    !defaultLearningPathSpec ||
    !topicSummary?.topic ||
    !learningPathProgress ||
    !packages?.packageData ||
    !curriculumSummary?.curriculum
  ) {
    return <h1>Could not load this topic</h1>;
  }

  const lpProgresses = learningPaths.reduce<Map<string, ProgressBadgeScore>>((acc, lp) => {
    const progress = learningPathProgress.get(lp.name);
    const progressScore = progress && getLearningPathProgressScore(progress);
    if (progressScore) {
      acc.set(lp.specName, progressScore);
    }
    return acc;
  }, new Map<string, ProgressBadgeScore>());

  // TODO: Test/handle what happens if progress is undefined

  // Build up Breadcrumbs
  const breadcrumbItems: IBreadcrumbItem[] = [
    { label: 'Independent learning', path: '/independentlearning' },
  ];
  const locationDetails = topicLocationMap.get(topicSummary?.topic?.name || '');
  let strandName = '';
  let substrandName = '';
  if (locationDetails?.strandSummary.strand) {
    strandName = locationDetails.strandSummary.strand.name;
    breadcrumbItems.push({
      label: locationDetails.strandSummary.strand.displayName,
      path: `/independentlearning/${strandName}`,
    });
  }
  if (locationDetails?.substrandSummary.substrand) {
    substrandName = locationDetails.substrandSummary.substrand.name;
    breadcrumbItems.push({
      label: locationDetails.substrandSummary.substrand.displayName,
    });
  }

  // Determine if content is available at the current level. We'll show some placeholders in certain
  // places if not.
  const contentAvailable = !!(packages?.packageData && packages.packageData.packages.length);

  // Function to call if the user changes the current level. Both set the selected level but also
  // change the url for this to take effect.
  const handleLevelSelect = (lps: LearningPathSpec) => {
    sendEvent({
      category: 'revision',
      action: 'set_level',
      labels: {
        name: lps.name,
        page: 'Topic View',
      },
    });
    navigate(
      `/independentlearning/curriculums/${curriculumID}/strands/${strandID}/substrands/${substrandID}/topics/${topicID}/levels/${lps.displayName}`,
    );
  };

  const selectedLearningPathSpec =
    curriculumSummary.curriculum.learningPathSpecs.find(lp => lp.name === topicPath?.specName) ||
    defaultLearningPathSpec;

  return (
    <div>
      <div className={styles.BreadcrumbContainer}>
        <Breadcrumb className={styles.Breadcrumb} items={breadcrumbItems} />

        {selectedCurriculumName !== curriculumSummary.curriculum.name && (
          <Tooltip
            content="You have selected a topic from a different Curriculum. You can change the Curriculum from the Independent Learning homepage"
            position="bottom"
          >
            <span className={styles.CurriculumLabel}>
              Curriculum: <strong>{curriculumSummary.curriculum.displayName}</strong>
            </span>
          </Tooltip>
        )}
      </div>
      <div className={styles.Header}>
        <div className={styles.TitleContainer}>
          <TextWithMaths text={topicSummary.topic.displayName} className={styles.Title} />
          <span className={styles.Code}>&nbsp;- {topicSummary.topic.code}</span>
        </div>
        <div className={styles.IndependentLearningProgress}>
          <LevelSelect
            selectedLearningPathSpec={selectedLearningPathSpec}
            defaultLearningPathSpec={defaultLearningPathSpec}
            learningPathSpecs={curriculumSummary.curriculum.learningPathSpecs}
            selectLevel={handleLevelSelect}
            key={defaultLearningPathSpec?.name}
            learningPathProgresses={lpProgresses}
            triggerClassName={styles.Badge}
            enabledLearningPathNames={learningPaths.map(lp => lp.specName)}
            contentAvailable={contentAvailable}
          />
          <ProgressInfo available={contentAvailable} packages={packages.packageData.packages} />
        </div>
      </div>
      <TopicLinks
        topic={topicSummary.topic}
        topicSummariesMap={topicSummariesMap}
        learningPathProgress={learningPathProgress}
        learningPathSpecName={lpSpecName}
        substrandName={substrandName}
      />
      {contentAvailable ? (
        <div className={styles.IndependentLearningPackagesContainer}>
          {packages.packageData?.packages.map(pkg => (
            <IndependentLearningPackage key={pkg.packageID} packageCompletion={pkg} />
          ))}
        </div>
      ) : (
        <div className={styles.IndependentLearningNoContentMessage}>
          <div>
            <img alt="" src={workbookIcon} />
          </div>
          <div>
            There is no content available at this level, please select a different level in the menu
            above.
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * ProgressInfo is a dialog that lists the objectives being viewed and explains what the progress
 * icons mean.
 * @param available Whether content is available and showing. If not we'll show a placeholder
 * message since there are no obejctives to display
 * @param packages The packages/objectives within the current topic.
 */
const ProgressInfo = ({
  available,
  packages,
}: {
  available: boolean;
  packages: PackageCompletion[];
}) => {
  const singleObjective = packages.length === 1;
  const inEachObjective = singleObjective ? <></> : <> in each objective</>;
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Info variant="Blue" className={styles.IndependentLearningProgressInfo} />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            styles.IndependentLearningProgressDialogContent,
          )}
        >
          <Dialog.Title
            className={classNames(
              dialogStyles.DialogTitle,
              styles.IndependentLearningProgressDialogTitle,
            )}
          >
            Progress
          </Dialog.Title>
          {available ? (
            <div className={styles.IndependentLearningProgressDialogContent}>
              This topic has {singleObjective ? 'one objective' : `${packages.length} objectives`}:
              <ul>
                {packages.map(pkg => (
                  <li key={pkg.packageID} className={styles.ProgressDialogObjective}>
                    <TextWithMaths text={pkg.title} />
                  </li>
                ))}
              </ul>
              <div>
                {singleObjective ? 'This' : 'Each'} objective is made up of three tasks: Introduce,
                Strengthen and Deepen. Each task you complete earns you 100XP!
              </div>
              <div>
                In order to get a <ProgressBadge variant={ProgressBadgeScore.TICKED} inline />,
                complete one task (such as Introduce){inEachObjective}. This means you have covered
                all the basics of the topic.
              </div>
              <div>
                In order to get a <ProgressBadge variant={ProgressBadgeScore.STARRED} inline />,
                complete two tasks (such as Introduce and Strengthen)
                {inEachObjective}.
              </div>
              <div>
                In order to get a <ProgressBadge variant={ProgressBadgeScore.COMPLETE} inline />,
                complete all three tasks{inEachObjective}.
              </div>
            </div>
          ) : (
            <div>This level is not available.</div>
          )}
          <Dialog.Close asChild>
            <button className={dialogStyles.IconButton} aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import {
  SwapOutTaskItemRequest,
  SwapOutTaskItemResponse,
} from '@sparx/api/apis/sparx/swworker/v1/worker';
import { useMutation } from '@tanstack/react-query';
import { useAPI } from 'context/api';

import { updateWithPackageData } from './packages';

export const useSwapOutQuestion = () => {
  const { swworkerClient } = useAPI();

  return useMutation(
    async (req: SwapOutTaskItemRequest) => {
      return swworkerClient.swapOutTaskItem(req).response;
    },
    {
      onSuccess: (response: SwapOutTaskItemResponse) => {
        if (response.packageData !== undefined) {
          updateWithPackageData(response.packageData);
        }
      },
    },
  );
};

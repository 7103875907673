import {
  UpdateUserDisplayDataForCurrentUserRequest,
  UserDisplayData,
} from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useLeaderboardContext } from '../context/context';

export const useGetUserDisplayData = () => {
  const { userDisplayClient } = useLeaderboardContext();
  if (!userDisplayClient) {
    throw new Error('userDisplayClient is not defined');
  }
  return useQuery(
    ['userdisplaydata'],
    () => userDisplayClient.getUserDisplayDataForCurrentUser({}).response,
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      suspense: true,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGeneratePositiveNoun = () => {
  const { userDisplayClient } = useLeaderboardContext();
  if (!userDisplayClient) {
    throw new Error('userDisplayClient is not defined');
  }
  return useMutation(() => userDisplayClient.generatePositiveNoun({}).response);
};

export const useUpdateUserDisplay = () => {
  const { userDisplayClient } = useLeaderboardContext();
  if (!userDisplayClient) {
    throw new Error('userDisplayClient is not defined');
  }
  const queryClient = useQueryClient();
  return useMutation(
    (req: UpdateUserDisplayDataForCurrentUserRequest) =>
      userDisplayClient.updateUserDisplayDataForCurrentUser(req).response,
    {
      onSuccess: (res: UserDisplayData) => {
        queryClient.setQueryData(['userdisplaydata'], res);
      },
    },
  );
};

export const useGetUserDisplayName = () => {
  const { data: userDisplayData } = useGetUserDisplayData();
  const { firstName } = useLeaderboardContext();
  const positiveNoun = userDisplayData?.positiveNoun || '';
  return makeUserDisplayName(firstName || '', positiveNoun);
};

export const makeUserDisplayName = (firstName: string, positiveNoun: string) => {
  return firstName + ' the ' + positiveNoun;
};

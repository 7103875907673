import * as Dialog from '@radix-ui/react-dialog';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Button } from '@sparx/sparx-design/components';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { Video } from 'components/video/Video';
import { VideoUrls } from 'utils/urls';

import styles from './ResourceHubVideo.module.css';

/**
 * ResourceHubVideoModal handles displaying resource hub videos
 */
export const ResourceHubVideoModal = ({
  isOpen,
  onClose,
  title,
  urls,
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  urls?: VideoUrls;
}) => {
  const videoName = urls?.videoUrl?.split('/').pop();

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content className={classNames(dialogStyles.DialogContent, dialogStyles.FullWidth)}>
          <Dialog.Title className={dialogStyles.DialogTitle}>{title}</Dialog.Title>
          <div className={styles.VideoContainer}>
            <Video
              videoUrl={urls?.videoUrl}
              posterUrl={urls?.posterUrl}
              subtitleUrl={urls?.subtitleUrl}
              analyticsCategory="resource hub"
              analyticsVideoName={videoName}
              disableAnalytics={videoName === undefined}
            />
          </div>
          <div className={styles.VideoButtonsContainer}>
            <Button
              as={Dialog.Close}
              onClick={onClose}
              onKeyDown={simulateClickOnEnter}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

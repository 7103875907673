import { useLibAnalytics, UserInteractionEventFields } from '@sparx/analytics';

export type swclient2EventCategory =
  | 'header'
  | 'menu'
  | 'package list'
  | 'lqd'
  | 'navigation'
  | 'notifications'
  | 'sparxweb_training'
  | 'assessments'
  | 'revision'
  | 'ftq'
  | 'loading'
  | 'game'
  | 'query'
  | 'question'
  | 'resource hub'
  | 'settings'
  | 'leaderboards'
  | 'debug'
  | 'other';

export type swclient2Event = Omit<UserInteractionEventFields, 'category'> & {
  category: swclient2EventCategory;
};

/** useAnalytics returns a function which may be used to send analytics event.
 * It wraps the useAnalytics exported by \@sparx/analytics to provide better typing.
 */
export const useAnalytics = () => {
  const sendEvent = useLibAnalytics();
  return sendEvent as (event: swclient2Event) => void;
};

// makeClientVersion returns a string containing the commit sha and datetime of the build, to be used
// as the applicationVersion for analytics.
export const makeClientVersion = () =>
  `${import.meta.env.VITE_REACT_APP_RELEASE_DATETIME || 'unset_datetime'}-${
    import.meta.env.VITE_REACT_APP_VERSION || 'unset_version'
  }`;

import { getNow } from '@sparx/react-utils';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { useEffect, useState } from 'react';
import { useAnalytics } from 'utils/analytics';

import styles from './LoadingSpinnerWithAnalytics.module.css';

const LONG_LOAD_TIME_IN_MILLIS = 5000;

interface LoadingSpinnerWithAnalyticsProps {
  componentName: string;
  displayName?: string;
  showLoadingMessage?: boolean;
  showLongTimeLoadingMessage?: boolean;
  sendLoadCompleteEvent?: boolean;
  sendLongTimeLoadingEvent?: boolean;
}

export const LoadingSpinnerWithAnalytics = ({
  componentName,
  displayName,
  showLoadingMessage = false,
  showLongTimeLoadingMessage = false,
  sendLoadCompleteEvent = false,
  sendLongTimeLoadingEvent = false,
}: LoadingSpinnerWithAnalyticsProps) => {
  const sendEvent = useAnalytics();
  const [longTimeLoading, setLongTimeLoading] = useState(false);

  useEffect(() => {
    const start = getNow();
    let interval: ReturnType<typeof setInterval>;
    if (sendLongTimeLoadingEvent || showLongTimeLoadingMessage) {
      interval = setInterval(() => {
        const now = getNow();
        const loadTimeInMillis = now.getTime() - start.getTime();
        setLongTimeLoading(true);
        if (sendLongTimeLoadingEvent) {
          sendEvent({
            category: 'loading',
            action: `Long load of ${componentName}`,
            labels: {
              componentName,
              time: now.toISOString(),
              loadTimeInMillis: `${loadTimeInMillis}`,
            },
          });
        }
      }, LONG_LOAD_TIME_IN_MILLIS);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      const end = getNow();
      const loadTimeInMillis = end.getTime() - start.getTime();
      if (sendLoadCompleteEvent) {
        sendEvent({
          category: 'loading',
          action: `Complete ${componentName}`,
          labels: {
            componentName,
            time: end.toISOString(),
            loadTimeInMillis: `${loadTimeInMillis}`,
          },
        });
      }
    };
  }, [
    componentName,
    sendEvent,
    sendLoadCompleteEvent,
    sendLongTimeLoadingEvent,
    showLongTimeLoadingMessage,
  ]);

  return (
    <div className={styles.Container} key={componentName}>
      <LargeLoading />
      {showLoadingMessage && (
        <div className={styles.Message}>Loading{displayName ? ' ' + displayName : ''}...</div>
      )}
      {showLongTimeLoadingMessage && longTimeLoading && (
        <>
          <div className={styles.LongLoadTimeMessage}>
            If this is taking a long time to load, you might have a slow network connection.
          </div>
          <div className={styles.LongLoadTimeMessage}>You could try refreshing your browser.</div>
          <div className={styles.LongLoadTimeMessage}>
            If this problem persists, please let your teacher know.
          </div>
        </>
      )}
    </div>
  );
};

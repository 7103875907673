import { TaskItemCompletion, TaskItemStatus } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { HiddenAt } from '@sparx/sparx-design/components';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import { useIsMutating } from '@tanstack/react-query';
import classnames from 'classnames';
import { ReactComponent as TickCircle } from 'images/TickCircle.svg';
import { activityMutationKey } from 'queries/activity';
import { TutorialKey } from 'queries/tutorials';
import { useAnalytics } from 'utils/analytics';
import { FTQStep } from 'views/fast-track-quiz/useFastTrackQuiz';

import styles from './Navbar.module.css';

type NavbarProps = {
  ftqStep: FTQStep;
  ftqTaskItems: TaskItemCompletion[] | undefined;
  taskItemIndex: number | undefined;
  setTaskItemIndex: (_: number) => void;
};

export const Navbar = ({ ftqStep, ftqTaskItems, taskItemIndex, setTaskItemIndex }: NavbarProps) => {
  const sendEvent = useAnalytics();
  const activityMutationHappening = useIsMutating({ mutationKey: activityMutationKey }) !== 0;
  return (
    <div
      data-tutorial-spotlight-key={TutorialKey.FTQNavigation}
      className={classnames(
        styles.Navbar,
        ftqStep === FTQStep.QUESTION ? styles.Question : styles.Answer,
      )}
    >
      <HiddenAt breakpoint="md">
        <span className={styles.NavbarLabel}>
          Fast Track {ftqStep === FTQStep.QUESTION ? 'questions' : 'answers'}
        </span>
      </HiddenAt>
      {ftqTaskItems ? (
        ftqTaskItems.map((item, index) => {
          const complete = item.status === TaskItemStatus.DONE;
          const isDisabled = activityMutationHappening && item.taskItemIndex !== taskItemIndex;
          return (
            <button
              tabIndex={complete ? -1 : undefined}
              key={item.taskItemIndex}
              className={classnames(styles.NavbarItem, accessibilityStyles.FocusTarget, {
                [styles.Selected]: item.taskItemIndex === taskItemIndex,
                [styles.Complete]: complete,
                [styles.Disabled]: !complete && isDisabled,
              })}
              onClick={
                !complete
                  ? () => {
                      setTaskItemIndex(item.taskItemIndex);
                      sendEvent({
                        category: 'ftq',
                        action: 'clicked ftq nav item',
                        labels: {
                          text: `${index + 1}`,
                        },
                      });
                    }
                  : undefined
              }
              aria-disabled={isDisabled}
              disabled={isDisabled}
            >
              {index + 1}
              {complete && <TickCircle className={styles.CompleteIcon} />}
            </button>
          );
        })
      ) : (
        <div className={styles.NavbarItem}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

import { TRAINING_MODULE_STUDENT_EXPERIENCE } from 'content/training/trainingModules';
import { valueof } from 'utils/valueof';

/**
 * Types for the teacher training module in Sparx Web which shows a teacher how to use the student
 * experience.
 *
 * These types were copied from tpclient2, 'reducers/training/types.ts', and slightly adated
 */

export type Module = Readonly<{
  name: TrainingModuleName;
  hasIntro: boolean;
  hasSummary: boolean;

  isComplete: boolean;
  moduleComplete?: ModuleCompleteSpec;
  steps: Step[];
  stepMap: { [key: string]: number };

  __spec: ModuleSpec;
}>;

export type Step = TaskStep | IntroStep | SummaryStep | VideoStep;
export type CompletableStep = TaskStep | IntroStep | VideoStep;

// Type guards
export const isTask = (step: Step): step is TaskStep => step.kind === 'task';
export const isIntro = (step: Step): step is IntroStep => step.kind === 'intro';
export const isVideo = (step: Step): step is VideoStep => step.kind === 'video';
export const isCompletable = (step: Step): step is CompletableStep =>
  isTask(step) || isIntro(step) || isVideo(step);
export const isSummary = (step: Step): step is SummaryStep => step.kind === 'summary';

// Get the TrainingStepID from the step ID.
export const getTrainingStepID = (step: Step) => step.id.split('/')[1] as TrainingStepID;

// Internal types for building modules

export type ModuleSpec = Readonly<{
  name: string;
  intro?: IntroSpec;
  tasks: readonly StepSpec[];
  summary?: SummarySpec;
  moduleComplete?: ModuleCompleteSpec;
}>;

type StepSpec = VideoSpec | TaskSpec;

type BaseStep = Readonly<{
  id: string;
  title: string;
  taskNumber: number;
}>;

type BaseCompletableStep = BaseStep &
  Readonly<{
    context?: string | JSX.Element;
    instructions: readonly string[];
    completeText?: string;
    note?: string;

    isComplete: boolean;
  }>;

export type TaskStep = BaseCompletableStep &
  Readonly<{
    kind: 'task';
    guidanceVideo?: string;

    __spec: TaskSpec;
  }>;

export type VideoStep = BaseCompletableStep &
  Readonly<{
    kind: 'video';
    video: string;

    // Redux selector to run to determine whether it is not appropriate to show the video. For example, This is used
    // to only show the "Watch video" button for the bookwork check state if the user has already dismissed the
    // bookwork check video when it appeared in the normal flow
    hideVideoSelector?: (state: unknown) => boolean;

    guidanceVideo?: string;

    __spec: VideoSpec;
  }>;

export type IntroStep = BaseCompletableStep &
  Readonly<{
    kind: 'intro';
    video: string;
    action: string;
    guidanceVideo?: string;
    videoContext?: string | JSX.Element;
    videoInstruction?: string;

    __spec: IntroSpec;
  }>;

export type SummaryStep = BaseStep &
  Readonly<{
    kind: 'summary';
    note?: string;
    summaryComplete: readonly SummaryScreenSpec[];
    summaryIncomplete: readonly SummaryScreenSpec[];

    __spec: SummarySpec;
  }>;

export type TaskSpec = Readonly<
  {
    id: string;
    title: string;
    heading?: string;
    context?: string | JSX.Element;
    instruction: string | readonly string[];
    note?: string;
    completeText?: string;
  } & (
    | {
        // If guidanceVideo is defined, it will be shown.
        guidanceVideo: string;
      }
    | {
        // guidanceText is used as a fallback if there is no video
        guidanceText: readonly string[];
      }
  )
>;

export type VideoSpec = Readonly<{
  id: string;
  title: string;
  heading?: string;
  context?: string | JSX.Element;
  instruction: string | readonly string[];
  note?: string;
  video: string;
  // Redux selector to run to determine whether it is not appropriate to show the video. For example, This is used to
  // only show the "Watch video" button for the bookwork check state if the user has already dismissed the bookwork
  // check video when it appeared in the normal flow
  hideVideoSelector?: (state: unknown) => boolean;
  guidanceVideo?: string;
  completeText?: string;
}>;

export type IntroSpec = Readonly<{
  id: string;
  title: string;
  context?: string | JSX.Element;
  videoContext?: string | JSX.Element;
  videoInstruction?: string;
  instruction: string | readonly string[];
  video: string;
  action: string;
}>;

export type SummarySpec = Readonly<{
  summaryComplete: readonly SummaryScreenSpec[];
  summaryIncomplete: readonly SummaryScreenSpec[];
  note?: string;
}>;

export type ModuleCompleteSpec = Readonly<{
  heading?: string;
  points: readonly string[];
  returnToTP?: boolean;
}>;

export type SummaryScreenSpec = Readonly<{
  heading: string;
  points: readonly string[];
}>;

const buildTaskSpec = (module: ModuleSpec, task: TaskSpec, taskNumber: number): TaskStep => ({
  id: `${module.name}/${task.id}`,
  kind: 'task',
  title: task.title,
  taskNumber,
  context: task.context,
  instructions: typeof task.instruction === 'string' ? [task.instruction] : task.instruction,
  note: task.note,
  __spec: task,
  isComplete: false,
  guidanceVideo: 'guidanceVideo' in task ? task.guidanceVideo : undefined,
  completeText: task.completeText,
});

export const isVideoSpec = (spec: StepSpec): spec is VideoSpec => 'video' in spec;
const buildVideoSpec = (module: ModuleSpec, task: VideoSpec, taskNumber: number): VideoStep => ({
  id: `${module.name}/${task.id}`,
  kind: 'video',
  title: task.title,
  taskNumber,
  context: task.context,
  instructions: typeof task.instruction === 'string' ? [task.instruction] : task.instruction,
  note: task.note,
  __spec: task,
  isComplete: false,
  video: task.video,
  hideVideoSelector: task.hideVideoSelector,
  guidanceVideo: 'guidanceVideo' in task ? task.guidanceVideo : undefined,
  completeText: task.completeText,
});

export const buildModule = (module: ModuleSpec): Module => {
  const steps: Step[] = [];
  if (module.intro !== undefined) {
    steps.push({
      id: `${module.name}/${module.intro.id}`,
      kind: 'intro',
      title: module.intro.title,
      taskNumber: 1,
      context: module.intro.context,
      videoContext: module.intro.videoContext,
      videoInstruction: module.intro.videoInstruction,
      instructions:
        typeof module.intro.instruction === 'string'
          ? [module.intro.instruction]
          : module.intro.instruction,
      action: module.intro.action,
      note: 'Reach the end of the video to complete this step.',
      video: module.intro.video,
      __spec: module.intro,
      isComplete: false,
    });
  }
  module.tasks.forEach(task => {
    steps.push(
      isVideoSpec(task)
        ? buildVideoSpec(module, task, steps.length + 1)
        : buildTaskSpec(module, task, steps.length + 1),
    );
  });
  if (module.summary !== undefined) {
    steps.push({
      id: `${module.name}/summary`,
      kind: 'summary',
      title: 'Training summary',
      taskNumber: steps.length + 1,
      summaryIncomplete: module.summary.summaryIncomplete,
      summaryComplete: module.summary.summaryComplete,
      note: module.summary.note,
      __spec: module.summary,
    });
  }
  return {
    name: module.name as TrainingModuleName,
    __spec: module,
    hasIntro: module.intro !== undefined,
    hasSummary: module.summary !== undefined,
    isComplete: false,
    moduleComplete: module.moduleComplete,
    steps: steps,
    stepMap: steps.reduce(
      (acc, step, i) => {
        acc[step.id] = i;
        acc[step.id.substring(module.name.length + 1)] = i;
        return acc;
      },
      {} as { [key: string]: number },
    ),
  };
};

export const internalTrainingModules = {
  [TRAINING_MODULE_STUDENT_EXPERIENCE.name]: TRAINING_MODULE_STUDENT_EXPERIENCE,
} as const;

export const TrainingModules = Object.keys(internalTrainingModules).reduce(
  (acc: Record<string, string>, k: string) => {
    acc[k] = k;
    return acc;
  },
  {},
) as {
  [K in keyof typeof internalTrainingModules]: K;
};

export type TrainingModuleName = keyof typeof TrainingModules;

export type ModuleCache = Record<string, Module>;

export type TrainingStepID = StepKeys<valueof<typeof internalTrainingModules>>;

type SummaryKey<T extends ModuleSpec> = T extends { summary: SummarySpec } ? 'summary' : never;
type IntroKey<T extends ModuleSpec> = T extends { intro: IntroSpec } ? T['intro']['id'] : never;

type TaskKeys<T extends ModuleSpec> = valueof<{
  [K in Exclude<keyof T['tasks'], keyof unknown[]>]: T['tasks'][K] extends { id: string }
    ? T['tasks'][K]['id']
    : never;
}>;

type StepKeys<T extends ModuleSpec> = IntroKey<T> | TaskKeys<T> | SummaryKey<T>;

// Errors
export class UnknownModuleError extends Error {
  constructor(moduleName: string) {
    super(
      `Programmer error: unknown module ${moduleName}. You may meed to add it to "allModules.ts"`,
    );
  }
}

export class UnknownStepError extends Error {
  constructor(step: string) {
    super(`Programmer error: unknown step ${step}.`);
  }
}

export type CompletePayload = {
  moduleName: TrainingModuleName;
  stepID: TrainingStepID;
};

export type UpdatePayload = {
  module: Module;
  force?: boolean;
};

export type TrainingState = {
  initialProgressLoaded: boolean;
  modules: ModuleCache;
  currentStep?: number;
  watchingHelpVideo: boolean;
};

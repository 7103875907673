import classNames from 'classnames';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { useActiveHomeworkPackages, usePackageTasks } from 'queries/packages';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeTaskPath, makeTimesTablesTaskPath } from 'utils/paths';
import { isTimesTablesTask } from 'utils/taskTypes';

import styles from './PrimaryPackageListView.module.css';
import { getActivePackageData, getFillGradientStyle } from './utils';

/*
  Determines what should be shown in the green progress bar inside a package slot:
  - totalAnswered: how many questions the student has answered right in this package
  - totalRequired: how many questions the student needs to answer right to complete the task

  If totalAnswered === totalRequired then the bar will be full and green
 */
interface IDetailsBarParams {
  totalAnswered: number;
  totalRequired: number;
}

export const DetailsBar: (p: IDetailsBarParams) => JSX.Element | null = ({
  totalAnswered,
  totalRequired,
}) => {
  // User percentage complete for fill
  const barGradient = getFillGradientStyle(totalAnswered, totalRequired, '#27CE88');
  return <div className={styles.ProgressBar} style={{ backgroundImage: barGradient }} />;
};

/*
  Determines what should show inside the clickable slot that, when clicked, will take you
  to the game select screen for that package
 */
export interface IPrimaryPackageSlotProps {
  // The ID of the package that will TT answers will contribute to
  packageId: string;
  // The text that should show for this homework (e.g. "Homework due 10th October)
  title: string;
  // How much XP has been earned in this package
  xpCount: number;
  // Has the player done enought TT to finish this task? (0 = not started, 100 = complete)
  percentageComplete: number;
  // Callback when this slot is clicked. The callback should take you to the game select screen for this
  // package.
  onSelected: (taskIndex: number) => void;
  // If true, do not allow the player to click on this task to continue
  locked: boolean;
}

/*
  A button filled with details and information about a package. Shows the student when it is due
  (in the title) and how much they have done (in a green completion bar)

  Click on this to go to the game select screen
 */
export const PrimaryPackageSlot: FunctionComponent<IPrimaryPackageSlotProps> = ({
  packageId,
  title,
  xpCount,
  percentageComplete,
  onSelected,
  locked,
}) => {
  const { data: tasks, isLoading } = usePackageTasks(packageId, {
    enabled: true,
  });

  if (isLoading) {
    return <></>;
  }

  let gameTaskIndex = -1;
  if (tasks) {
    for (let i = 0; i < tasks.length; i++) {
      if (isTimesTablesTask(tasks[i])) {
        gameTaskIndex = i + 1;
      }
    }
  }

  // If the game is not times tables it will be visible but not clickable in this view
  const isTimesTables = gameTaskIndex >= 0;

  return (
    <div
      className={classNames(
        styles.PrimaryPackageSlot,
        locked && styles.LockedPackageSlot,
        !isTimesTables && styles.NonTablesSlot,
      )}
      onClick={() => !locked && onSelected(gameTaskIndex)}
    >
      <div className={styles.PrimaryPackageTitle}>{title + (isTimesTables ? '' : '*')}</div>
      <div className={styles.PrimaryPackageDetails}>
        {percentageComplete < 100 ? (
          <>
            <div
              className={classNames(styles.ProgressBarHolder, styles.PrimaryPackageDetailsSegment)}
            >
              <DetailsBar totalAnswered={percentageComplete} totalRequired={100} />
            </div>
            <div className={styles.PrimaryPackageDetailsSegment}>{percentageComplete}%</div>
          </>
        ) : (
          <div className={styles.ButtonHolder}>
            <div className={classNames(styles.PrimaryPackageDetailsSegment, styles.CompletedText)}>
              Complete
            </div>
          </div>
        )}
        <div
          className={classNames(
            styles.PrimaryPackageDetailsSegment,
            xpCount > 0 ? styles.XpPositiveText : styles.XpNoneText,
          )}
        >
          +{xpCount}&nbsp;XP
        </div>
        <div className={styles.ButtonHolder}>
          <div
            className={classNames(
              styles.PrimaryPackageStartButton,
              styles.PrimaryPackageDetailsSegment,
              locked && styles.LockedStartButton,
            )}
          >
            <div>{locked ? 'Locked' : percentageComplete > 0 ? 'Continue' : 'Start'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// return the number with the correct suffix, e.g. "1st" for 1, "2nd" for 2, ...
const addOrdinalToNumber = (num: number) => {
  if (num % 100 > 3 && num % 100 < 21) return `${num}th`;
  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
  }
  return `${num}th`;
};

// return a date in the format e.g. "1st January"
const formatDateForTitle = (date: Date) => {
  const monthDisplayFormat = new Intl.DateTimeFormat('en-GB', { month: 'long' });
  return `${addOrdinalToNumber(date.getDate())} ${monthDisplayFormat.format(date)}`;
};

export const PrimaryPackageListView = () => {
  const { data: packages, isLoading: packagesAreLoading } = useActiveHomeworkPackages();
  const navigate = useNavigate();

  if (packagesAreLoading) {
    return (
      <LoadingSpinnerWithAnalytics
        componentName="PrimaryPackageListView"
        sendLongTimeLoadingEvent={true}
      />
    );
  }

  const activePackageData = getActivePackageData(packages || []);

  const slots = [];
  for (const pkg of activePackageData.sortedPackages) {
    const percentageComplete = Math.floor(
      (100 * (pkg.completion?.progress['C'] || 0)) / (pkg.completion?.size || 1),
    );
    // label the homework by when it is due, in the format "Homework due 1st January"
    const endDate = new Date((pkg.endDate?.seconds || 0) * 1000);
    const formattedEndDate = formatDateForTitle(endDate);
    const title = `Homework due ${formattedEndDate}`;
    slots.push(
      <PrimaryPackageSlot
        packageId={pkg.packageID}
        title={title}
        xpCount={pkg.xPoints}
        percentageComplete={percentageComplete}
        onSelected={(taskIndex: number) => {
          if (taskIndex < 0) {
            // a non-times-tables task should go to first task of the package
            navigate(makeTaskPath('', pkg.packageID, 1));
          } else {
            // a times-tables task should go to the game select screen
            navigate(makeTimesTablesTaskPath(pkg.packageID, taskIndex));
          }
        }}
        locked={activePackageData.unlockedPackages.indexOf(pkg) === -1}
        key={`slot-${slots.length}`}
      />,
    );
  }

  if (slots.length > 0) {
    return <div className={styles.PrimaryPackageListView}>{slots}</div>;
  } else {
    return (
      <div className={styles.PrimaryPackageListView}>
        <div className={styles.NoPackagesHeading}>You do not have a homework yet.</div>
        <div className={styles.NoPackagesHeading}>
          Your first homework will begin soon, but in the meantime press the button below to try out
          the Times Tables games.
        </div>
        <div className={styles.PlayButtonHolder}>
          <div
            className={styles.PlayTimesTablesButton}
            onClick={() => navigate(makeTimesTablesTaskPath('no-task', 0))}
          >
            Play Times Tables Games
          </div>
        </div>
      </div>
    );
  }
};

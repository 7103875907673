import { MatchWithLocation } from './hooks';

const GCSE = 'curriculums/aa7a8538-24af-4789-be80-0e23ff89a2e2';
const KS3 = 'curriculums/ba538918-2f6d-4b94-9b24-bd2e0786047e';
export const prioritiseSearchResults = (results: MatchWithLocation[], limit: number) =>
  results
    .sort((a, b) => {
      const aCurriculumName = a.location.curriculumDetails.hierarchy.curriculum?.name;
      const bCurriculumName = b.location.curriculumDetails.hierarchy.curriculum?.name;

      if (!aCurriculumName || !bCurriculumName) {
        return 0;
      }

      // Prioritise GCSE and KS3
      if (aCurriculumName === KS3 && bCurriculumName !== KS3) {
        return -1;
      } else if (aCurriculumName !== KS3 && bCurriculumName === KS3) {
        return 1;
      }

      if (aCurriculumName === GCSE && bCurriculumName !== GCSE) {
        return -1;
      } else if (aCurriculumName !== GCSE && bCurriculumName === GCSE) {
        return 1;
      }

      return aCurriculumName.localeCompare(bCurriculumName);
    })
    .slice(0, limit);

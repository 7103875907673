import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { RewardGroup } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { Tooltip } from '@sparx/sparx-design/components';
import { RibbonBanner } from '@sparx/sparx-design/components/ribbon-banner';
import { Tick } from '@sparx/sparx-design/icons';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { ReactNode, useMemo, useRef } from 'react';
import { useCountUp } from 'react-countup';
import { getBookworkDialIcon, getBookworkLevel } from 'utils/bookworkAccuracy';
import { useFeatureFlags } from 'utils/feature-flags';

import rewardsStyles from './Rewards.module.css';

const getPresentableReason = (reason: string) => {
  switch (reason) {
    case 'package-complete':
      return 'Homework Complete';
    case 'package-swaps-remaining':
      return 'Swaps Remaining';
    case 'package-bookwork-accuracy':
      return 'Bookwork Accuracy';
    default:
      return reason;
  }
};

const getReasons = (showBookworkAccuracy: boolean, swapsEnabled: boolean) => {
  const reasons = ['package-complete'];
  if (swapsEnabled) reasons.push('package-swaps-remaining');
  if (showBookworkAccuracy) reasons.push('package-bookwork-accuracy');
  return reasons;
};

const animationDuration = 3;
const delay = 0.5;
const countDelay = delay + 0.5;

const rewardsItem = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: { duration: animationDuration / 3 },
  },
};

const container = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: animationDuration / 3,
      delayChildren: delay,
    },
  },
};

export const Rewards = ({
  xpRewards,
  taskXP,
  pkgCompletion,
  showBookworkAccuracy,
  swapsEnabled,
}: {
  xpRewards: RewardGroup;
  taskXP: number;
  pkgCompletion: PackageCompletion;
  showBookworkAccuracy: boolean;
  swapsEnabled: boolean;
}) => {
  const bonusXP = useMemo(() => {
    return xpRewards.rewards.reduce((acc, reward) => acc + reward.xPoints, 0);
  }, [xpRewards.rewards]);
  const countupRef = useRef<HTMLElement>(null);

  useCountUp({
    ref: countupRef,
    start: taskXP,
    end: bonusXP + taskXP,
    duration: animationDuration,
    delay: countDelay,
    easingFn: (t: number, b: number, c: number, d: number) =>
      Math.sin(((t / d) * Math.PI) / 2) * c + b,
  });

  return (
    <>
      <p>
        This homework you earned{' '}
        <span className={rewardsStyles.XPCount}>
          <span ref={countupRef}>{taskXP}</span> XP
        </span>
      </p>
      <div className={rewardsStyles.BonusXPContainer}>
        <RibbonBanner
          containerClassName={rewardsStyles.BannerContainer}
          barClassName={rewardsStyles.Banner}
        >
          <h2>Bonus XP</h2>
        </RibbonBanner>
        <motion.div
          className={rewardsStyles.RewardsContainer}
          variants={container}
          initial="hidden"
          animate="show"
        >
          {getReasons(showBookworkAccuracy, swapsEnabled).map((reason, index) => {
            const reward = xpRewards.rewards.find(r => r.reason === reason);
            return (
              <Reward
                xp={reward?.xPoints || 0}
                reason={reason}
                key={index}
                pkgCompletion={pkgCompletion}
              />
            );
          })}
        </motion.div>
      </div>
    </>
  );
};

const Reward = ({
  xp,
  reason,
  pkgCompletion,
}: {
  xp: number;
  reason: string;
  pkgCompletion: PackageCompletion;
}) => {
  const featureFlags = useFeatureFlags();
  const maximumSwaps = featureFlags.getNumberFlag('sparxweb2-allowed-question-swap-count', 0);
  const unearned = xp === 0;
  const swapsMade = pkgCompletion.numSwapsMade;
  const bookworkLevel = getBookworkLevel(pkgCompletion.completion);
  const dialIcon = getBookworkDialIcon(bookworkLevel);
  let extraNode: ReactNode;
  switch (reason) {
    case 'package-swaps-remaining':
      extraNode = (
        <span
          className={classnames({
            [rewardsStyles.SwapsNode]: true,
            [rewardsStyles.Unearned]: unearned,
          })}
        >
          {maximumSwaps - swapsMade}/{maximumSwaps}
        </span>
      );
      break;
    case 'package-bookwork-accuracy':
      extraNode = (
        <Tooltip
          position="top"
          content="This shows how well you have done on your bookwork checks. Remember that doing well with your bookwork means you will get fewer checks."
        >
          <span
            className={classnames({
              [rewardsStyles.BookworkNode]: true,
              [rewardsStyles.Unearned]: unearned,
            })}
          >
            <img src={dialIcon} alt="Bookwork Accuracy" />
          </span>
        </Tooltip>
      );
      break;
    case 'package-complete':
    default:
      break;
  }
  return (
    <motion.div className={rewardsStyles.RewardContainer} variants={rewardsItem}>
      <span>
        <Tick
          variant="White"
          className={classnames({ [rewardsStyles.Tick]: true, [rewardsStyles.Unearned]: unearned })}
        />
        <span
          className={classnames({
            [rewardsStyles.ReasonText]: true,
            [rewardsStyles.Unearned]: unearned,
          })}
        >
          {getPresentableReason(reason)}
          {extraNode ? ':' : ''}
        </span>
        {extraNode}
      </span>
      <div>
        <span
          className={classnames({
            [rewardsStyles.XPAmount]: true,
            [rewardsStyles.Unearned]: unearned,
          })}
        >
          + {xp} XP
        </span>
      </div>
    </motion.div>
  );
};

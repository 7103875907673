import { AccessTokenProvider } from '@sparx/grpcweb';
import { getNow } from '@sparx/react-utils';
import { getCookie } from 'utils/cookies';

// Load the URL from the global variable
const apiURL = window.__sparxweb.urls.api;
const csrfCookieName = window.__sparxweb.csrfCookieName;

const makeTokenFetcher = (onAccessTokenError: () => void) => async () => {
  // TODO don't hardcode this
  const res = await fetch('api.algenie.dev/token', {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.status === 401) {
    if (import.meta.env.DEV) {
      window.location.assign('https://www.test2.test.sparxmaths.uk/student');
    } else {
      onAccessTokenError();
    }
  }
  return res;
};

export const getAccessTokenProvider = (onAccessTokenError: () => void) => {
  const accessTokenProvider = new AccessTokenProvider(makeTokenFetcher(onAccessTokenError));
  accessTokenProvider.start().then(() => console.log('Access Token Provider initialised'));
  return accessTokenProvider;
};

const getCSRFCookie = () => getCookie(csrfCookieName);

export const redirectToLogin = async () => {
  // TODO: If a school isn't selected, need to redirect to select school instead...
  // TODO: Don't hardcode this...
  const schoolID = 'f62ec351-1d44-480f-bec1-557d9b2259de';
  window.location.replace(
    // TODO: app=sparx-maths may need to differentiate between student and teacher
    `https://api.algenie.dev/oauth2/login?school=${schoolID}&app=sparx-maths`,
  );
  return new Promise(() => {
    /*pass*/
  }); // never return
};

let sessionPromise: Promise<{ sessionID: string; serverOffset: number }> | undefined;
export const getSessionId = async () => {
  if (!sessionPromise) {
    // We're converting this from token based to session based, so now we need to fetch the access token first.
    // TODO: Can we share the one that gets set up and memo'd in APIProvider?
    const tokenFetcher = makeTokenFetcher(() =>
      window.alert('Failed to get access token provider'),
    );
    const token = await tokenFetcher();
    console.info('Got a token, yay!', token);

    sessionPromise = fetch(apiURL + '/clientsession', {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'X-CSRF-Token': getCSRFCookie(),
        'Content-Type': 'application/json',
      },
    })
      .then(async response => {
        const { sessionID, serverNow } = await response.json();

        // Work out the timing offset between client and server:
        const serverNowDate = new Date(serverNow);
        const clientNow = getNow();
        const serverOffset = Math.round((serverNowDate.getTime() - clientNow.getTime()) / 1000);

        return { sessionID, serverOffset };
      })
      .catch(async e => {
        console.error('Failed to create session, going to login page', e);
        await redirectToLogin();
        // This will never return as above blocks indefinitely, just satisfies the type checker.
        return { sessionID: '', serverOffset: 0 };
      });
  }
  return sessionPromise;
};

export const makeLogoutRequest = async () => {
  const { sessionID } = await getSessionId();
  return fetch(apiURL + '/logout', {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'X-CSRF-Token': getCSRFCookie(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ SessionID: sessionID }),
  });
};

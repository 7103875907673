import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import styles from './Carousel.module.css';
import { CarouselButtons } from './CarouselButtons';

interface CarouselProps {
  carouselID: string;
  items: ReactElement[];
  numItemsToShow?: number;
  defaultIndex?: number;
  onChangeItem?: (index: number) => void;
}

export const Carousel = ({
  carouselID,
  items,
  numItemsToShow = 1,
  defaultIndex = 0,
  onChangeItem,
}: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);
  const currentIndexNotified = useRef(defaultIndex);

  const getItemID = useCallback((index: number) => `${carouselID}-item-${index}`, [carouselID]);

  // ensure the carousel starts by displaying the default item
  useEffect(() => {
    document.getElementById(getItemID(defaultIndex))?.scrollIntoView({ block: 'nearest' });
  }, [defaultIndex, getItemID]);

  // notify the parent component when the item index changes
  useEffect(() => {
    // only call onChangeItem if the index we're calling it with has changed
    if (onChangeItem && currentIndexNotified.current !== currentIndex) {
      onChangeItem(currentIndex);
    }
    currentIndexNotified.current = currentIndex;
  }, [currentIndex, currentIndexNotified, onChangeItem]);

  // scroll when the next/prev buttons are clicked
  const scrollToIndex = (i: number) => {
    document.getElementById(getItemID(i))?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  };
  const goToNext = () => {
    scrollToIndex(currentIndex + numItemsToShow < items.length ? currentIndex + numItemsToShow : 0);
  };
  const goToPrevious = () => {
    scrollToIndex(currentIndex > 0 ? currentIndex - 1 : items.length - 1);
  };

  // update the current item index when the carousel scrolls
  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // cast the target to a div element, which we know it is
    const target = e.target as HTMLDivElement;
    // how far we have scrolled
    const scrollPosition = target.scrollLeft;
    // total width of carousel (contains all items)
    const elementWidth = target.scrollWidth;
    // work out width of each tab (should be equal)
    const tabWidth = elementWidth / items.length;
    // find tab number. We round so that the this will always be an integer
    setCurrentIndex(Math.round(scrollPosition / tabWidth));
  };

  // set item padding and width %s depending on how many items are shown
  const itemPadding = numItemsToShow > 1 ? 2 : 0;
  const itemWidth = (100 - itemPadding * (numItemsToShow - 1)) / numItemsToShow;

  return (
    <>
      <div className={styles.OuterContainer}>
        <div className={styles.InnerContainer} onScroll={onScroll}>
          {items.map((tab, i) => (
            <div
              key={i}
              id={getItemID(i)}
              className={styles.Item}
              style={{
                width: `${itemWidth}%`,
                marginRight: `${i < items.length - 1 ? itemPadding : 0}%`,
              }}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      {items.length > numItemsToShow && (
        <CarouselButtons
          index={currentIndex}
          numItemsToShow={numItemsToShow}
          total={items.length}
          goToNext={goToNext}
          goToPrevious={goToPrevious}
        />
      )}
    </>
  );
};

import { default as React, useCallback, useEffect } from 'react';
import { getKeyPressed } from 'utils/keyboard';

interface IKeyboardShortcutsProps {
  // A function which will be triggered when "Enter" is hit
  enterFunc: (e: KeyboardEvent) => void;
  // The source name of the component triggering the keyboard shortcuts
  source?: string;
}

export const KeyboardShortcuts: React.FC<IKeyboardShortcutsProps> = ({ enterFunc }) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const key = getKeyPressed(event);
      if (key == 'Enter') {
        enterFunc(event);
      }
    },
    [enterFunc],
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return <></>;
};

export const getAssessmentBack = (currentPath: string): string | undefined => {
  if (!currentPath.startsWith('/assessments')) {
    return;
  }
  if (currentPath.includes('/package')) {
    return currentPath.substring(0, currentPath.indexOf('/package'));
  }

  if (currentPath.match(/^\/assessments\/.+$/)) {
    return '/assessments';
  }
  return undefined;
};

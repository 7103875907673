import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { RpcError, RpcMetadata, RpcTransport, UnaryCall } from '@protobuf-ts/runtime-rpc';
import { AccessTokenProvider, authInterceptor, extensionInterceptor } from '@sparx/grpcweb';
import { getSessionId } from 'auth/authinterceptor';

const variantHeader = 'SPX-Variant-Selector';

const makeGrpcMetadata = () => {
  const meta: RpcMetadata = {};

  const params = new URLSearchParams(window?.location.search);
  const variant = params.get('spx.variant');
  if (variant) {
    meta[variantHeader] = variant;
  }

  return meta;
};

/**
 * @param url
 * @param excludeSessionId
 * @param withServerOffset Whether to include the client-server time offset HTTP header X-Server-Offset - default
 * "false"
 */
export const getTransport = (
  url: string,
  accessTokenProvider: AccessTokenProvider,
  opts?: {
    excludeSessionId?: boolean;
    withServerOffset?: boolean;
  },
): RpcTransport =>
  new GrpcWebFetchTransport({
    baseUrl: url,
    format: 'binary',
    meta: makeGrpcMetadata(),
    fetchInit: {
      credentials: 'include',
    },
    interceptors: [
      authInterceptor(accessTokenProvider, {
        getSessionID: opts?.excludeSessionId ? undefined : getSessionId,
        withServerOffset: opts?.withServerOffset,
      }), // add auth to requests
      extensionInterceptor, // show requests in the gRPC-Web Chrome extension

      // Reload the page if the session is inactive
      {
        interceptUnary(next, method, input, options): UnaryCall {
          const response = next(method, input, options);

          response.response.catch(err => {
            if (err instanceof RpcError && err.message === 'SessionInactive') {
              console.log('session inactive, reloading page...');
              location.reload(); // TODO - we could block the UI and show a message here
            }
          });

          return response;
        },
      },
    ],
  });

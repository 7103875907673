import { CheckIcon, ChevronDownIcon, ChevronUpIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { CurriculumSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { LearningPathSpec } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { getTopicIDFromName } from '@sparx/resource-names';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import selectStyles from '@sparx/sparx-design/shared-styles/Select.module.css';
import classNames from 'classnames';
import { ReactComponent as ChevronRight } from 'images/icon-chevron-right.svg';
import {
  useCurriculumSummaries,
  useCurriculumSummary,
  useTopicSummariesMapForCurriculum,
} from 'queries/content';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';
import {
  makeTimesTablesTaskPath,
  timesTablesNoTaskIndependentLearningPackageID,
} from 'utils/paths';

import {
  useSelectedCurriculumName,
  useSelectedDefaultLearningPath,
  useSetSelectedCurriculumName,
  useSetSelectedDefaultLearningPath,
} from '../hooks';
import { LevelSelect } from '../level-select/LevelSelect';
import { getStrandMetadata } from '../strands';
import styles from './FindTopics.module.css';
import { SelectTopicFunc, TopicSearch } from './TopicSearch';

export const FindTopics = () => {
  const navigate = useNavigate();
  const sendEvent = useAnalytics();
  const curriculums = useCurriculumSummaries();
  const selectedCurriculumName = useSelectedCurriculumName();
  const curriculumSummary = useCurriculumSummary(selectedCurriculumName);
  const setSelectedDefaultLearningPath = useSetSelectedDefaultLearningPath();
  const defaultLearningPathSpec = useSelectedDefaultLearningPath();

  // Whether to show the times tables independent learning button
  const showTimesTables = useFeatureFlags().getBooleanFlag(
    'timestables-allow-independent-access',
    false,
  );

  // Get the topics, we don't need them directly but they are used by other
  // components and we want to show a loading spinner
  useTopicSummariesMapForCurriculum(selectedCurriculumName);

  curriculums?.sort((c1, c2) => {
    if (!c1.curriculum) return 1;
    if (!c2.curriculum) return -1;
    return c1.curriculum.displayName.localeCompare(c2.curriculum.displayName);
  }) || [];

  if (!curriculums || !curriculumSummary || !curriculumSummary.curriculum) {
    return <>Error</>;
  }

  const selectTopic: SelectTopicFunc = args => {
    const topicID = getTopicIDFromName(args.topicName);
    navigate(`/independentlearning/${args.substrandName}/topics/${topicID}/levels/${args.level}`);
  };
  const handleLevelSelect = (lps: LearningPathSpec) => {
    setSelectedDefaultLearningPath(lps);
    sendEvent({
      category: 'revision',
      action: 'set_level',
      labels: {
        name: lps.name,
        page: 'Find topics',
      },
    });
  };

  return (
    <div className={styles.MainContent}>
      <div className={styles.Filters}>
        <TopicSearch selectTopic={selectTopic} />
        <div
          className={classNames(styles.TopicFilterContainer, styles.TopicFilterContainerCurriculum)}
        >
          <div className={styles.TopicFilterLabel}>Your curriculum:</div>
          <CurriculumSetting
            curriculums={curriculums}
            selectedCurriculumName={selectedCurriculumName}
          />
        </div>
        <div className={classNames(styles.TopicFilterContainer, styles.TopicFilterContainerLevel)}>
          <div className={styles.TopicFilterLabel}>Default level:</div>
          <LevelSelect
            selectedLearningPathSpec={defaultLearningPathSpec}
            learningPathSpecs={curriculumSummary.curriculum.learningPathSpecs}
            selectLevel={handleLevelSelect}
            contentAvailable
          />
        </div>
      </div>
      <span className={styles.TopicFilterLabel}>Select a topic:</span>
      <div className={styles.StrandsList}>
        {curriculumSummary.strandSummaries.map(strandSummary => {
          const strandMeta = getStrandMetadata(strandSummary.strand);
          if (!strandSummary.strand || !strandMeta) {
            // Eventually the tags on the topics will determine
            // whether a strand should be shown or not.
            return null;
          }
          return (
            <button
              key={strandMeta.key}
              className={styles.StrandButton}
              onClick={() => {
                // TODO: navigate to strand page, BOOL-2162
                navigate(`/independentlearning/${strandSummary.strand?.name}`);
                return;
              }}
            >
              <span className={styles.StrandName}>
                {strandMeta.displayName || strandSummary.strand.displayName}
              </span>
              {strandMeta.icon && (
                <img alt="" className={styles.StrandIcon} src={strandMeta.icon} />
              )}
              <div className={styles.StrandChevron}>
                <ChevronRight />
              </div>
            </button>
          );
        })}
      </div>
      {showTimesTables && (
        <div className={styles.ExtrasList}>
          <button
            key={'times-tables'}
            className={styles.TimesTablesButton}
            onClick={() =>
              navigate(makeTimesTablesTaskPath(timesTablesNoTaskIndependentLearningPackageID, 0))
            }
          >
            Times tables practice
          </button>
        </div>
      )}
    </div>
  );
};

const CurriculumSetting = ({
  curriculums,
  selectedCurriculumName,
}: {
  curriculums: CurriculumSummary[];
  selectedCurriculumName: string;
}) => {
  const sendEvent = useAnalytics();
  const setSelectedCurriculumName = useSetSelectedCurriculumName();

  const currentCurriculum = curriculums.find(c => c.curriculum?.name === selectedCurriculumName);

  return (
    <Select.Root
      value={currentCurriculum?.curriculum?.name}
      onValueChange={(value: string) => {
        setSelectedCurriculumName(value);
        sendEvent({
          category: 'revision',
          action: 'set_curriculum',
          labels: { name: value },
        });
      }}
    >
      <Select.Trigger
        className={classNames(selectStyles.SelectTrigger, accessibilityStyles.FocusTarget)}
        aria-label="curriculum"
      >
        <Select.Value placeholder="Select a curriculum..." />
        <Select.Icon>
          <TriangleDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className={selectStyles.SelectContent} position="popper">
          <Select.ScrollUpButton className="SelectScrollButton">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {curriculums.map(
              ({ curriculum }) =>
                curriculum && (
                  <Select.Item
                    className={selectStyles.SelectItem}
                    value={curriculum.name}
                    key={curriculum.name}
                  >
                    <Select.ItemText>{curriculum.displayName}</Select.ItemText>
                    <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
                      <CheckIcon />
                    </Select.ItemIndicator>
                  </Select.Item>
                ),
            )}
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

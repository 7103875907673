import classNames from 'classnames';
import { useMemo } from 'react';

import { LevelBadge0, LevelBadge1, LevelBadge5, LevelBadge25, LevelBadge100 } from './Badges';
import styles from './RewardsPlaque.module.css';

export const RewardsPlaque = ({
  level,
  customClassName,
}: {
  level: number;
  customClassName?: string;
}) => {
  const badgeSVG = useMemo(() => {
    if (level === 0) {
      return <LevelBadge0 />;
    }
    return level % 100 === 0 ? (
      <LevelBadge100 level={level} />
    ) : level % 25 === 0 ? (
      <LevelBadge25 level={level} />
    ) : level % 5 === 0 ? (
      <LevelBadge5 level={level} />
    ) : (
      <LevelBadge1 level={level} />
    );
  }, [level]);

  return <div className={classNames(styles.RewardsPlaque, customClassName)}>{badgeSVG}</div>;
};

// TODO - FF: new-xp-system
export const OldRewardsPlaque = ({ level }: { level: number }) => {
  /**
   * SVG as a react component so we can control the text that displays the level number.
   * I've taken the SVG provided by UX, added a title element to make it accessible and a text
   * element at the bottom for the level number. This element scales properly with the rest of
   * the graphic. The only other changes I've made are to make the markup compatible with react,
   * i.e. xlink:href => xlinkHref and changing the style tag to use dangerouslySetInnerHTML.
   */

  return (
    <svg className={styles.OldRewardsPlaque} viewBox="0 0 530.33 544.6">
      <title>Sparx Level Plaque showing level {level}</title>
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.cls-1{opacity:.5;}.cls-1,.cls-2{fill:none;}.cls-3{fill:url(#linear-gradient);}.cls-4{fill:#fff;}.cls-5{fill:#ee9003;}.cls-6{fill:#ce7a15;}.cls-7{fill:#24386b;}.cls-8{fill:#3fef8a;}.cls-9{fill:#14825d;}.cls-10{fill:#0e1935;}.cls-11{fill:#965815;}.cls-12{fill:#29457c;}.cls-13{fill:#2cdd88;}.cls-14{fill:url(#linear-gradient-8);}.cls-15{fill:url(#linear-gradient-9);}.cls-16{fill:url(#linear-gradient-3);}.cls-17{fill:url(#linear-gradient-4);}.cls-18{fill:url(#linear-gradient-2);}.cls-19{fill:url(#linear-gradient-6);}.cls-20{fill:url(#linear-gradient-7);}.cls-21{fill:url(#linear-gradient-5);}.cls-22{fill:url(#linear-gradient-14);}.cls-23{fill:url(#linear-gradient-18);}.cls-24{fill:url(#linear-gradient-22);}.cls-25{fill:url(#linear-gradient-20);}.cls-26{fill:url(#linear-gradient-21);}.cls-27{fill:url(#linear-gradient-23);}.cls-28{fill:url(#linear-gradient-15);}.cls-29{fill:url(#linear-gradient-19);}.cls-30{fill:url(#linear-gradient-16);}.cls-31{fill:url(#linear-gradient-17);}.cls-32{fill:url(#linear-gradient-13);}.cls-33{fill:url(#linear-gradient-12);}.cls-34{fill:url(#linear-gradient-10);}.cls-35{fill:url(#linear-gradient-11);}.cls-36{fill:url(#linear-gradient-24);}.cls-37{fill:url(#linear-gradient-25);}.cls-2{opacity:.15;}',
          }}
        />
        <linearGradient
          id="linear-gradient"
          x1="-316.65"
          y1="1091.61"
          x2="-82.52"
          y2="1206.87"
          gradientTransform="translate(-292.26 -798.75) rotate(-31.38)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fefefe" stopOpacity="0" />
          <stop offset=".05" stopColor="#f4f8fb" stopOpacity=".07" />
          <stop offset=".44" stopColor="#b1d0e7" stopOpacity=".57" />
          <stop offset=".7" stopColor="#86b6da" stopOpacity=".88" />
          <stop offset=".82" stopColor="#76add6" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="238.46"
          y1="355.91"
          x2="472.59"
          y2="471.17"
          gradientTransform="translate(-321.89 -44.15) rotate(-16.38)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fefefe" stopOpacity="0" />
          <stop offset=".13" stopColor="#d4e8fb" stopOpacity=".17" />
          <stop offset=".47" stopColor="#6ab2f5" stopOpacity=".61" />
          <stop offset=".71" stopColor="#2790f1" stopOpacity=".89" />
          <stop offset=".82" stopColor="#0d83f0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="584.15"
          y1="-498.43"
          x2="818.27"
          y2="-383.17"
          gradientTransform="translate(-545.91 677.05) rotate(-1.37)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="696.82"
          y1="-1413.15"
          x2="930.95"
          y2="-1297.89"
          gradientTransform="translate(-949.02 1315.64) rotate(13.64)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="568.8"
          y1="-2325.85"
          x2="802.92"
          y2="-2210.58"
          gradientTransform="translate(-1503.74 1828.08) rotate(28.64)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="208.81"
          y1="-3174.26"
          x2="442.94"
          y2="-3059"
          gradientTransform="translate(-2172.23 2179.4) rotate(43.65)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="-358.58"
          y1="-3900.53"
          x2="-124.45"
          y2="-3785.27"
          gradientTransform="translate(-2908.89 2345.64) rotate(58.66)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="-1094.68"
          y1="-4455.11"
          x2="-860.55"
          y2="-4339.85"
          gradientTransform="translate(-3663.48 2315.47) rotate(73.67)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="-1949.28"
          y1="-4800.18"
          x2="-1715.15"
          y2="-4684.91"
          gradientTransform="translate(-4384.51 2090.94) rotate(88.67)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="-4624.83"
          y1="-4422.91"
          x2="-4390.7"
          y2="-4307.65"
          gradientTransform="translate(-5885.69 463.52) rotate(133.69)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="-5350.7"
          y1="-3855"
          x2="-5116.57"
          y2="-3739.74"
          gradientTransform="translate(-6051.42 -273.26) rotate(148.7)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="-5904.75"
          y1="-3118.5"
          x2="-5670.62"
          y2="-3003.24"
          gradientTransform="translate(-6020.7 -1027.83) rotate(163.71)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="-6249.2"
          y1="-2263.66"
          x2="-6015.07"
          y2="-2148.4"
          gradientTransform="translate(-5795.65 -1748.7) rotate(178.71)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="-6360.55"
          y1="-1348.78"
          x2="-6126.42"
          y2="-1233.52"
          gradientTransform="translate(-5391.61 -2386.72) rotate(-166.28)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-15"
          x1="-6231.21"
          y1="-436.28"
          x2="-5997.08"
          y2="-321.01"
          gradientTransform="translate(-4836.14 -2898.36) rotate(-151.27)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-16"
          x1="-5870"
          y1="411.62"
          x2="-5635.87"
          y2="526.88"
          gradientTransform="translate(-4167.14 -3248.71) rotate(-136.27)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-17"
          x1="-5301.56"
          y1="1137.07"
          x2="-5067.43"
          y2="1252.33"
          gradientTransform="translate(-3430.23 -3413.89) rotate(-121.26)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-18"
          x1="-4564.66"
          y1="1690.59"
          x2="-4330.54"
          y2="1805.85"
          gradientTransform="translate(-2675.69 -3382.63) rotate(-106.25)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-19"
          x1="-3709.57"
          y1="2034.42"
          x2="-3475.45"
          y2="2149.68"
          gradientTransform="translate(-1954.97 -3157.05) rotate(-91.24)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-20"
          x1="-2794.62"
          y1="2145.11"
          x2="-2560.49"
          y2="2260.37"
          gradientTransform="translate(-1317.25 -2752.55) rotate(-76.24)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-21"
          x1="-1882.2"
          y1="2015.11"
          x2="-1648.07"
          y2="2130.37"
          gradientTransform="translate(-806.01 -2196.72) rotate(-61.23)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-22"
          x1="-1034.57"
          y1="1653.29"
          x2="-800.44"
          y2="1768.55"
          gradientTransform="translate(-456.14 -1527.46) rotate(-46.22)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-23"
          x1="-2864.07"
          y1="-4912.19"
          x2="-2629.95"
          y2="-4796.93"
          gradientTransform="translate(-5022.82 1687.36) rotate(103.68)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-24"
          x1="-3776.68"
          y1="-4783.51"
          x2="-3542.55"
          y2="-4668.25"
          gradientTransform="translate(-5534.85 1132.27) rotate(118.69)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-25"
          x1="199.99"
          y1="353.37"
          x2="323.01"
          y2="140.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".01" stopColor="#27ce88" />
          <stop offset=".39" stopColor="#2aff97" />
          <stop offset=".69" stopColor="#27ce88" />
          <stop offset="1" stopColor="#00d46c" />
        </linearGradient>
      </defs>
      <g className="cls-2">
        <polygon
          className="cls-3"
          points="264.56 269.29 -38.79 286.19 -35.05 317.67 264.56 269.29"
        />
        <polygon
          className="cls-18"
          points="264.56 269.29 -32.82 207.07 -37.36 238.44 264.56 269.29"
        />
        <polygon
          className="cls-16"
          points="264.56 269.29 -6.56 132.19 -19.08 161.31 264.56 269.29"
        />
        <polygon className="cls-17" points="264.56 269.29 38.18 66.66 18.55 91.55 264.56 269.29" />
        <polygon className="cls-21" points="264.56 269.29 98.37 14.96 72.97 33.91 264.56 269.29" />
        <polygon
          className="cls-19"
          points="264.56 269.29 169.89 -19.4 140.45 -7.67 264.56 269.29"
        />
        <polygon
          className="cls-20"
          points="264.56 269.29 247.88 -34.07 216.39 -30.36 264.56 269.29"
        />
        <polygon
          className="cls-14"
          points="264.56 269.29 326.99 -28.04 295.63 -32.61 264.56 269.29"
        />
        <polygon
          className="cls-15"
          points="264.56 269.29 401.85 -1.73 372.74 -14.27 264.56 269.29"
        />
        <polygon
          className="cls-34"
          points="264.56 269.29 553.32 174.83 541.6 145.38 264.56 269.29"
        />
        <polygon
          className="cls-35"
          points="264.56 269.29 567.92 252.83 564.24 221.34 264.56 269.29"
        />
        <polygon
          className="cls-33"
          points="264.56 269.29 561.84 331.94 566.43 300.58 264.56 269.29"
        />
        <polygon
          className="cls-32"
          points="264.56 269.29 535.48 406.78 548.04 377.67 264.56 269.29"
        />
        <polygon
          className="cls-22"
          points="264.56 269.29 490.64 472.24 510.3 447.38 264.56 269.29"
        />
        <polygon
          className="cls-28"
          points="264.56 269.29 430.38 523.86 455.81 504.94 264.56 269.29"
        />
        <polygon
          className="cls-30"
          points="264.56 269.29 358.8 558.11 388.27 546.42 264.56 269.29"
        />
        <polygon
          className="cls-31"
          points="264.56 269.29 280.8 572.67 312.29 569.01 264.56 269.29"
        />
        <polygon
          className="cls-23"
          points="264.56 269.29 201.69 566.53 233.05 571.14 264.56 269.29"
        />
        <polygon
          className="cls-29"
          points="264.56 269.29 126.87 540.11 155.97 552.69 264.56 269.29"
        />
        <polygon
          className="cls-25"
          points="264.56 269.29 61.44 495.22 86.29 514.91 264.56 269.29"
        />
        <polygon className="cls-26" points="264.56 269.29 9.87 434.92 28.77 460.37 264.56 269.29" />
        <polygon
          className="cls-24"
          points="264.56 269.29 -24.34 363.33 -12.67 392.8 264.56 269.29"
        />
        <polygon
          className="cls-27"
          points="264.56 269.29 467.35 43.06 442.47 23.41 264.56 269.29"
        />
        <polygon
          className="cls-36"
          points="264.56 269.29 519.01 103.29 500.07 77.87 264.56 269.29"
        />
      </g>
      <path
        className="cls-12"
        d="m348.68,317.88c0,6.9-4.85,15.43-10.77,18.96l-71,42.37c-2.68,1.6-6.01,1.6-8.69,0l-71-42.37c-5.92-3.53-10.77-12.07-10.77-18.96v-147.14c0-6.9,5.64-12.54,12.54-12.54h147.14c6.9,0,12.54,5.64,12.54,12.54v147.14Z"
      />
      <path
        className="cls-9"
        d="m262.57,391.11c-2.74,0-5.48-.73-7.93-2.19l-71-42.37c-8.08-4.82-14.18-15.56-14.18-24.98v-147.14c0-10.77,8.77-19.54,19.54-19.54h147.14c10.77,0,19.54,8.77,19.54,19.54v147.14c0,9.42-6.1,20.15-14.18,24.98l-71,42.37c-2.45,1.46-5.19,2.19-7.93,2.19Zm-79.11-227.98c-3.05,0,0,8.25,0,11.31v147.14c0,4.4,3.57,10.7,7.36,12.95l71,42.37c.47.28,1.05.28,1.51,0l71-42.37c3.78-2.26,7.35-8.55,7.35-12.95v-147.14c0-3.05,1.94-11.31-1.12-11.31"
      />
      <path
        className="cls-37"
        d="m262.57,387.11c-2.74,0-5.48-.73-7.93-2.19l-71-42.37c-8.08-4.82-14.18-15.56-14.18-24.98v-147.14c0-10.77,8.77-19.54,19.54-19.54h147.14c10.77,0,19.54,8.77,19.54,19.54v147.14c0,9.42-6.1,20.15-14.18,24.98l-71,42.37c-2.45,1.46-5.19,2.19-7.93,2.19Zm-73.57-222.21c-3.05,0-5.54,2.48-5.54,5.54v147.14c0,4.4,3.57,10.7,7.35,12.95l71,42.37c.46.28,1.05.28,1.51,0l71-42.37c3.78-2.26,7.35-8.55,7.35-12.95v-147.14c0-3.05-2.48-5.54-5.54-5.54h-147.14Z"
      />
      <path
        className="cls-7"
        d="m326.54,165.34s-17.22,85.62-114.88,177.48c0-.68,50.92,30.7,50.92,30.7l72.41-42.84s6.65-5.09,6.65-15.28v-143.69s.84-6.38-6.05-6.38h-9.04Z"
      />
      <path
        className="cls-6"
        d="m180.36,260.61c-14.11,2.73-28.11,6.18-41.96,10.33-2.23.67-4.37-1.22-3.88-3.46,1.82-8.35,3.87-16.71,6.13-25.06.27-1-.01-2.05-.76-2.77-6.25-6.01-12.69-11.9-19.32-17.66-1.79-1.55-.91-4.37,1.62-5.13,15.66-4.7,31.49-8.59,47.45-11.68,1.87-.37,3.64.68,3.94,2.31,3.08,16.54,6.16,33.07,9.25,49.61.3,1.63-.79,3.2-2.45,3.52Z"
      />
      <path
        className="cls-5"
        d="m303.21,239.04c-49.73-4.53-100.04-.73-148.81,11.41-1.7.42-3.41-.52-3.82-2.13-4.18-16.29-8.36-32.59-12.53-48.88-.41-1.61.8-3.31,2.72-3.79,54.92-13.67,111.57-17.95,167.57-12.85,1.96.18,3.41,1.67,3.24,3.32-1.64,16.74-3.29,33.48-4.93,50.22-.16,1.65-1.7,2.85-3.43,2.69Z"
      />
      <path
        className="cls-11"
        d="m153.28,251.69c10,2.36,19.76,5.07,29.28,8.1.36.11.72-.19.65-.56-.87-4.66-1.74-9.31-2.6-13.97-.05-.27-.32-.44-.6-.39-8.95,1.68-17.85,3.63-26.71,5.86-.52.13-.54.83-.02.96Z"
      />
      <path
        className="cls-6"
        d="m344.79,260.61c14.11,2.73,28.11,6.18,41.96,10.33,2.23.67,4.37-1.22,3.88-3.46-1.82-8.35-3.87-16.71-6.13-25.06-.27-1,.01-2.05.76-2.77,6.25-6.01,12.69-11.9,19.32-17.66,1.79-1.55.91-4.37-1.62-5.13-15.66-4.7-31.49-8.59-47.45-11.68-1.87-.36-3.64.68-3.94,2.31-3.08,16.54-6.16,33.07-9.25,49.61-.3,1.63.8,3.2,2.45,3.52Z"
      />
      <path
        className="cls-5"
        d="m221.94,239.04c49.73-4.53,100.04-.73,148.81,11.41,1.7.42,3.41-.52,3.82-2.13,4.18-16.29,8.36-32.59,12.53-48.88.41-1.61-.8-3.31-2.72-3.79-54.92-13.67-111.57-17.95-167.57-12.85-1.95.18-3.4,1.67-3.24,3.32,1.64,16.74,3.29,33.48,4.93,50.22.17,1.65,1.7,2.85,3.43,2.69Z"
      />
      <path
        className="cls-11"
        d="m371.08,251.87c-9.6,2.29-19,4.9-28.16,7.81-.51.16-1.01-.27-.91-.79.88-4.71,1.75-9.41,2.63-14.12,8.85,1.65,17.66,3.57,26.42,5.75.74.18.75,1.17.02,1.35Z"
      />
      <polygon
        className="cls-13"
        points="341.63 244.2 355.73 247 355.7 267.87 341.63 263.9 341.63 244.2"
      />
      <g className="cls-1">
        <path
          className="cls-10"
          d="m169.42,257.45s98.95-19.53,186.31.27v-10.98c-34.25-5.65-51.94-9.41-92.64-9.41-45.52,0-93.68,9.67-93.68,9.67v10.44Z"
        />
      </g>
      <path
        className="cls-4"
        d="m158.44,215.34c-.16-.78-.14-1.57.07-2.36.21-.79.6-1.54,1.16-2.23.56-.7,1.28-1.3,2.14-1.81.87-.51,1.87-.88,3-1.1,1.09-.21,2.12-.22,3.09-.05.98.17,1.84.51,2.61,1,.77.49,1.41,1.11,1.92,1.86.51.75.84,1.59,1.01,2.54-1.14.2-2.28.4-3.41.61-.21-1.12-.74-1.96-1.6-2.53-.86-.56-1.88-.74-3.06-.51-.62.12-1.17.31-1.66.58-.48.26-.87.59-1.18.96-.3.37-.52.76-.64,1.16-.12.4-.14.8-.06,1.19.15.76.56,1.28,1.25,1.57.68.29,1.49.5,2.42.62.93.13,1.95.24,3.04.34,1.09.1,2.12.31,3.08.65.96.34,1.81.85,2.55,1.55.73.7,1.22,1.7,1.45,3.02.14.79.09,1.57-.15,2.35-.24.78-.64,1.52-1.2,2.2-.56.68-1.28,1.28-2.16,1.78-.88.51-1.9.87-3.08,1.09-1.18.23-2.25.26-3.23.12-.98-.15-1.86-.45-2.62-.9-.77-.46-1.41-1.06-1.91-1.81-.5-.75-.86-1.62-1.06-2.6,1.11-.23,2.22-.46,3.33-.68.25,1.22.78,2.08,1.61,2.59.83.51,1.94.63,3.34.36,1.49-.28,2.53-.78,3.14-1.51.6-.73.83-1.52.67-2.4-.15-.8-.55-1.39-1.23-1.74-.67-.35-1.47-.6-2.41-.74-.94-.14-1.94-.26-3.02-.36-1.08-.1-2.11-.29-3.08-.59-.97-.29-1.83-.76-2.57-1.39-.75-.63-1.25-1.58-1.51-2.84Z"
      />
      <path
        className="cls-4"
        d="m186.23,204.38c1-.15,1.97-.1,2.9.14.94.24,1.78.63,2.52,1.17.74.54,1.36,1.2,1.86,1.99.49.79.81,1.66.93,2.61.13.95.06,1.87-.21,2.77-.27.9-.68,1.7-1.24,2.41-.56.71-1.24,1.31-2.05,1.81-.81.49-1.7.81-2.67.96-1.6.24-3.2.48-4.8.73.42,2.65.84,5.3,1.26,7.95-1.1.17-2.2.35-3.3.53-1.2-7.25-2.39-14.5-3.59-21.75,2.79-.46,5.58-.9,8.38-1.31Zm1.59,10.74c.5-.08.96-.24,1.38-.51.42-.26.78-.59,1.08-.99.3-.4.52-.84.66-1.34.14-.49.17-1,.1-1.53-.07-.51-.25-.98-.51-1.41-.27-.43-.61-.8-1.01-1.09-.4-.29-.85-.51-1.33-.65-.49-.14-.98-.18-1.49-.1-1.63.24-3.27.49-4.9.75.4,2.54.8,5.08,1.2,7.61,1.61-.25,3.22-.5,4.83-.74Z"
      />
      <path
        className="cls-4"
        d="m200.15,224.72c-1.17.15-2.33.3-3.5.45,1.83-7.66,3.78-15.31,5.86-22.95.97-.11,1.94-.22,2.91-.33,3.74,6.98,7.35,14,10.84,21.06-1.17.11-2.34.22-3.51.34-.81-1.62-1.63-3.24-2.46-4.85-2.95.31-5.9.64-8.85,1-.44,1.76-.87,3.52-1.3,5.28Zm8.58-9.27c-1.38-2.79-2.78-5.57-4.2-8.34-.76,3.02-1.5,6.04-2.21,9.07,2.14-.26,4.28-.5,6.42-.73Z"
      />
      <path
        className="cls-4"
        d="m226.61,213.33c-1.31.09-2.62.19-3.93.29.23,2.91.46,5.82.69,8.72-1.11.09-2.22.18-3.33.27-.63-7.32-1.25-14.64-1.88-21.97,3.05-.26,6.11-.49,9.17-.7.95-.06,1.87.05,2.75.34.88.29,1.64.72,2.29,1.28.65.56,1.19,1.24,1.6,2.03.41.79.64,1.65.69,2.55.07,1.28-.14,2.34-.62,3.18-.48.83-1.03,1.5-1.63,2-.7.6-1.51,1.07-2.42,1.42,2.04,2.91,4.05,5.82,6.02,8.75-1.23.06-2.46.14-3.69.21-1.87-2.81-3.78-5.61-5.71-8.39Zm-4.17-2.84c1.86-.15,3.72-.28,5.58-.41.46-.03.88-.15,1.27-.37.39-.21.73-.49,1.03-.82.29-.33.52-.71.69-1.15.17-.43.24-.89.21-1.38-.03-.49-.15-.94-.38-1.35-.22-.41-.5-.76-.83-1.05-.34-.29-.71-.52-1.13-.68-.42-.16-.86-.23-1.33-.19-1.89.13-3.77.27-5.66.41.18,2.33.37,4.66.55,6.99Z"
      />
      <path
        className="cls-4"
        d="m252.02,198.87c1.29-.03,2.57-.05,3.86-.06-2.28,3.65-4.51,7.31-6.7,10.98,2.5,3.67,4.94,7.35,7.34,11.06-1.23.01-2.46.03-3.7.06-1.81-2.77-3.65-5.53-5.53-8.27-1.7,2.85-3.38,5.72-5.02,8.59-1.24.05-2.47.1-3.71.16,2.17-3.84,4.39-7.67,6.66-11.47-2.4-3.54-4.85-7.06-7.34-10.56,1.29-.06,2.57-.12,3.86-.17,1.82,2.61,3.62,5.24,5.39,7.87,1.6-2.73,3.23-5.46,4.89-8.17Z"
      />
      <path
        className="cls-4"
        d="m283.59,218.12c-.05,1.05-.09,2.09-.13,3.14-4.63-.2-9.26-.33-13.9-.4.11-7.35.21-14.7.32-22.04,1.16.02,2.32.04,3.48.06-.13,6.3-.27,12.6-.4,18.9,3.54.07,7.09.19,10.63.34Z"
      />
      <path
        className="cls-4"
        d="m302.79,219.3c-.08,1.04-.17,2.09-.25,3.13-4.97-.4-9.95-.73-14.93-.98.37-7.34.75-14.68,1.12-22.02,5.08.26,10.16.59,15.23,1-.08,1.04-.17,2.09-.25,3.13-3.89-.31-7.79-.58-11.68-.8-.11,1.98-.23,3.96-.34,5.94,3.55.2,7.09.45,10.63.72-.08,1.05-.17,2.1-.25,3.15-3.52-.28-7.04-.52-10.57-.72-.13,2.21-.25,4.43-.38,6.64,3.89.22,7.78.49,11.67.81Z"
      />
      <path
        className="cls-4"
        d="m307.71,200.75c1.22.11,2.45.22,3.67.33,1.61,5.83,3.14,11.68,4.61,17.52,2.67-5.4,5.4-10.79,8.2-16.15,1.22.15,2.44.3,3.66.45-3.73,6.93-7.34,13.91-10.83,20.91-1.05-.12-2.1-.23-3.15-.34-1.93-7.59-3.99-15.17-6.16-22.74Z"
      />
      <path
        className="cls-4"
        d="m344.53,224.46c-.17,1.03-.34,2.07-.52,3.1-4.93-.82-9.88-1.57-14.82-2.24.99-7.28,1.97-14.56,2.96-21.85,5.05.68,10.09,1.44,15.13,2.28-.17,1.03-.34,2.07-.51,3.1-3.86-.64-7.73-1.24-11.6-1.79-.28,1.96-.56,3.93-.84,5.89,3.52.5,7.04,1.04,10.56,1.62-.17,1.04-.34,2.08-.51,3.12-3.49-.57-6.99-1.11-10.49-1.61-.31,2.19-.62,4.39-.94,6.58,3.87.55,7.73,1.15,11.59,1.79Z"
      />
      <path
        className="cls-4"
        d="m363.87,228.07c-.21,1.03-.42,2.05-.64,3.08-4.56-.95-9.13-1.82-13.71-2.64,1.29-7.24,2.58-14.47,3.86-21.7,1.15.2,2.29.41,3.44.62-1.15,6.2-2.29,12.39-3.44,18.59,3.5.65,7,1.33,10.49,2.05Z"
      />
      <polygon
        className="cls-8"
        points="183.51 244.2 169.41 247 169.44 267.87 183.51 263.9 183.51 244.2"
      />
      <text x="49.5%" y="60%" textAnchor="middle" fill="white" fontSize="5rem" fontWeight="500">
        {level}
      </text>
    </svg>
  );
};

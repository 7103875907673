import { Chip, HiddenAt } from '@sparx/sparx-design/components';
import { motion } from 'framer-motion';
import CalculatorAllowedIcon from 'images/CalculatorAllowed.svg';
import CalculatorNotAllowedIcon from 'images/CalculatorNotAllowed.svg';
import { useFeatureFlags } from 'utils/feature-flags';
import { QuestionBanner } from 'views/lqd/question-banner/QuestionBanner';
import { useBannerData } from 'views/lqd/question-banner/useBannerData';

import styles from './QuestionInfo.module.css';

interface QuestionInfoProps {
  bookworkCode?: string;
  calculatorAllowed: boolean;
  showBanner: boolean;
  isItemAttempted?: boolean;
  isItemOnNonInitialChance?: boolean;
  isItemSwapped?: boolean;
  isCombinedQuestionAnswer?: boolean;
  children?: React.ReactNode;
}

/**
 * QuestionInfo is the component that shows at the top of a question and contains the bookwork
 * code and whether a calculator is allowed.
 */
export const QuestionInfo = ({
  bookworkCode,
  calculatorAllowed,
  showBanner,
  isItemAttempted,
  isItemOnNonInitialChance,
  isItemSwapped,
  isCombinedQuestionAnswer,
  children,
}: QuestionInfoProps) => {
  const { getBooleanFlag } = useFeatureFlags();
  const showBookworkCode = getBooleanFlag('sparxweb-show-separate-bookwork-code', true);
  const [calculatorSrc, calculatorText] = calculatorAllowed
    ? [CalculatorAllowedIcon, 'Calculator allowed']
    : [CalculatorNotAllowedIcon, 'Calculator not allowed'];
  const bannerData = useBannerData(
    !!isItemAttempted,
    !!isItemOnNonInitialChance,
    !!isItemSwapped,
    !!isCombinedQuestionAnswer,
  );

  return (
    <>
      {showBanner && bannerData && !bannerData.showAfterBookworkCode && (
        <QuestionBanner data={bannerData} />
      )}
      <div className={styles.QuestionInfo}>
        {showBookworkCode && bookworkCode !== undefined && (
          <motion.div
            animate={{
              scaleX: [1, 1.1, 1],
              transition: { duration: 0.3, delay: 0.2 },
            }}
            className={styles.ProgressContainer}
          >
            <Chip colourVariant="Selected" shapeVariant="Boxy">
              Bookwork code: {bookworkCode}
            </Chip>
          </motion.div>
        )}
        <div className={styles.CalculatorInfoContainer}>
          <img className={styles.CalculatorIcon} src={calculatorSrc} />
          <HiddenAt breakpoint="sm">
            <p>{calculatorText}</p>
          </HiddenAt>
        </div>
        {children}
      </div>
      {showBanner && bannerData && bannerData.showAfterBookworkCode && (
        <QuestionBanner data={bannerData} />
      )}
    </>
  );
};

import { useContext } from 'react';

import { TutorialSpotlightContext } from './provider';

export const useTutorialSpotlight = () => {
  const ctx = useContext(TutorialSpotlightContext);
  if (!ctx) {
    throw new Error('useTutorialSpotlight must be used inside of a TutorialSpotlightProvider');
  }
  return ctx;
};

import { motion } from 'framer-motion';
import { useFeatureFlags } from 'utils/feature-flags';

import styles from './MultiPartMarkingBanner.module.css';

interface IMultiPartMarkingBannerProps {
  isItemAttempted: boolean;
  isMultiPartMarkedQuestion: boolean;
}

export const MultiPartMarkingBanner = ({
  isItemAttempted,
  isMultiPartMarkedQuestion,
}: IMultiPartMarkingBannerProps) => {
  const featureFlags = useFeatureFlags();
  const ff = featureFlags.getBooleanFlag('sparxweb2-enable-mpa', false);
  const showBanner = ff && !isItemAttempted && isMultiPartMarkedQuestion;
  return showBanner ? (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      className={styles.BannerContainer}
    >
      <p>
        <span className={styles.NewBadge}>
          <b>New!</b>
        </span>
        {'  '}
        <b>Multi Part Question</b> - when you answer this question we'll mark each part individually
      </p>
    </motion.div>
  ) : (
    <></>
  );
};

import {
  LeaderboardDateRange,
  LeaderboardType,
  LeaderboardUser,
} from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { getUserIDFromDisplayDataName } from '@sparx/resource-names';
import { useMemo } from 'react';

import { useLeaderboardContext } from '../context/context';
import { useGetLeaderboardForTeacher, useGetLeaderboards } from '../queries/leaderboards';
import { useGetUserDisplayData } from '../queries/userdisplay';
import styles from './Leaderboard.module.css';
import { LeaderboardRow } from './LeaderboardRow';

/**
 * StudentLeaderboardTable shows the current users leaderboard for the given date range and type.
 * It loads the current users display data so it is up to date, and highlights them within the
 * leaderboard.
 * Should be rendered inside a Suspense
 */

export const StudentLeaderboardTable = ({
  leaderboardType,
  leaderboardDateRange,
}: {
  leaderboardType: LeaderboardType;
  leaderboardDateRange: LeaderboardDateRange;
}) => {
  const { data: users } = useGetLeaderboards(leaderboardType, leaderboardDateRange);
  const { data: { positiveNoun } = {} } = useGetUserDisplayData();

  return <LeaderboardTableDisplay users={users} currentUserPositiveNoun={positiveNoun} />;
};

/**
 * TeacherLeaderboardTable shows the leaderboard for the given date range and students. It handles
 * fetching the leaderboard for the given students, and adding the student first names to the
 * leaderboard data.
 * Should be rendered inside a suspense.
 */
export const TeacherLeaderboardTable = ({
  students,
  leaderboardDateRange,
}: {
  students: { id: string; firstName: string; lastName: string }[];
  leaderboardDateRange: LeaderboardDateRange;
}) => {
  // get ids and names into a more useful format
  const { studentNamesMap, studentIdsArr } = useMemo(() => {
    const studentNamesMap: Record<string, { firstName: string; lastName: string }> = {};
    const studentIdsArr: string[] = [];

    students.forEach(student => {
      studentNamesMap[student.id] = { firstName: student.firstName, lastName: student.lastName };
      studentIdsArr.push(student.id);
    });
    return {
      studentNamesMap,
      studentIdsArr,
    };
  }, [students]);

  const { data: users } = useGetLeaderboardForTeacher(studentIdsArr, leaderboardDateRange);

  // add last names to users from leaderboard service
  const usersWithFullNames = useMemo(() => {
    return (users || []).map(user => {
      const userId = getUserIDFromDisplayDataName(user.userDisplayData?.name || '');
      const { firstName, lastName } = studentNamesMap[userId];
      const fullName = `${firstName} ${lastName}`;
      return {
        ...user,
        fullName,
        firstName,
      };
    });
  }, [users, studentNamesMap]);

  return <LeaderboardTableDisplay users={usersWithFullNames} />;
};

export type LeaderboardUserWithFullName = LeaderboardUser & { fullName?: string };

type LeaderboardTableDisplayProps = {
  users?: LeaderboardUserWithFullName[];
  currentUserPositiveNoun?: string;
};

/**
 * LeaderboardTableDisplay displays the given leaderboard users in a leaderboard. It does no data
 * loading.
 * If a currentUserPositiveNoun is supplied, it will be used as the positive noun for any current user
 * in the given users.
 * If the supplied users contain full names, they will be displayed alongside display names.
 */
const LeaderboardTableDisplay = ({
  users,
  currentUserPositiveNoun,
}: LeaderboardTableDisplayProps) => {
  const { navigateToStudent } = useLeaderboardContext();
  const isStudent = currentUserPositiveNoun;
  const tableRows = useMemo(() => {
    if (!users) {
      return null;
    }

    if (users.length === 0 || users[0].xp === 0) {
      return (
        <div>
          {isStudent
            ? 'No XP earned yet. Be the first to get on the leaderboard!'
            : 'No selected students have earned XP in this date range.'}
        </div>
      );
    }

    const rows = users.map((user, i) => (
      <LeaderboardRow
        user={user}
        key={i}
        currentUserPositiveNoun={currentUserPositiveNoun}
        navigate={
          navigateToStudent
            ? () =>
                navigateToStudent(getUserIDFromDisplayDataName(user.userDisplayData?.name || ''))
            : undefined
        }
      />
    ));
    const maxRank = Math.max(...users.filter(u => u.xp > 0).map(u => u.rank));
    if (maxRank < 12) {
      return rows;
    }

    const percentile25Index = calculateWherePercentileShouldGo(users, 25, maxRank);
    const percentile50Index = calculateWherePercentileShouldGo(users, 50, maxRank);

    // it's important to do these in this order
    rows.splice(percentile50Index, 0, PercentileBar(50));
    rows.splice(percentile25Index, 0, PercentileBar(25));

    return rows;
  }, [users, isStudent, currentUserPositiveNoun]);

  return <div className={styles.Table}>{tableRows}</div>;
};

type leaderboardPercentile = 25 | 50;

const calculateWherePercentileShouldGo = (
  users: LeaderboardUser[],
  percentile: leaderboardPercentile,
  maxRank: number,
): number => {
  // find the index of the user with the first rank that is greater than the percentile rank
  const index = users.findIndex(u => u.rank > (percentile / 100) * maxRank);
  return index;
};

const PercentileBar = (percentile: number) => {
  return (
    <div className={styles.PercentileBar} key={`percentile-${percentile}`}>
      Top {percentile}%
    </div>
  );
};

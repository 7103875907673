import moment from 'moment';
import { useEffect } from 'react';

import { useLocalStorage } from './localStorage';
import { useMinClientVersion } from './queries';

// There is a direct copy of this in
// CloudExperiments/teacherportal/tpclient2/src/components/minclientversionmanager/minClientVersionManager.ts.
// Any changes made here should also be made there.
// Once TP2 is moved into the js/apps directory, we should remove that copy

// minimum of 1 hour between refreshes triggered by this component
const minSecondsBetweenRefresh = 60 * 60;

type MinClientVersionManagerProps = {
  // static assets url (for AlgenieSettings repo assets)
  assetURL: string;

  // type of client (e.g. Sparx Web, Teacher Portal etc)
  clientType: 'swclient2' | 'tpclient2';

  // build timestamp in a format that moment can parse
  buildTimestamp: string;

  // optional function that will prevent the app refreshing if it returns true
  disableRefresh?: () => boolean;

  // optional send analytics function that will be called before the app is refreshed
  sendAnalytics?: (action: string, category: 'other') => void;
};

// MinClientVersionManager forces out the app to refresh if the client code is out of date
//
// The client code is deemed to be out of date if the minimum version timestamp returned by the
// clientminversion service for the specified client is later than the specified build timestamp
//
// An app refresh will only be triggered at least an hour after the previously triggered refresh
export const MinClientVersionManager = ({
  assetURL,
  clientType,
  buildTimestamp,
  disableRefresh,
  sendAnalytics,
}: MinClientVersionManagerProps) => {
  const { data: minClientVersion } = useMinClientVersion(assetURL, clientType);
  const currentClientVersion = moment(buildTimestamp);
  const shouldDisable = disableRefresh ? disableRefresh() : false;

  const [lastRefreshTime, setLastRefreshTime] = useLocalStorage(`minClientVersion/lastRefreshTime`);
  const minRefreshTime = lastRefreshTime
    ? moment(lastRefreshTime).add(minSecondsBetweenRefresh, 's')
    : moment('2000-01-01');

  useEffect(() => {
    if (shouldDisable) {
      return;
    }
    if (minClientVersion === undefined || !currentClientVersion.isValid()) {
      return;
    }
    const now = moment.utc();
    if (now.isBefore(minRefreshTime)) {
      return;
    }
    if (currentClientVersion.isBefore(minClientVersion)) {
      setLastRefreshTime(now.toISOString());
      sendAnalytics && sendAnalytics('app-refreshed', 'other');
      window.location.reload();
    }
  }, [
    shouldDisable,
    minClientVersion,
    currentClientVersion,
    minRefreshTime,
    setLastRefreshTime,
    sendAnalytics,
  ]);

  return null;
};

import * as Dialog from '@radix-ui/react-dialog';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Button } from '@sparx/sparx-design/components';
import { TimesIcon } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { Video } from 'components/video/Video';
import { useState } from 'react';
import { makeStaticVideoUrls } from 'utils/urls';

import styles from './TrainingHelpVideo.module.css';

interface TrainingIntroVideoProps {
  // The file name of the video to show
  videoID: string;
  // Callback for when the modal is closed
  onClose: (reachedEnd: boolean) => void;

  isVideo?: boolean;
}

/**
 * A modal for showing the "Need help" videos for the teacher training. Also used to play the video for steps that
 * consist only of a video (at the moment, the bookwork check video for step 2)
 * @constructor
 */
export const TrainingHelpVideo = ({ videoID, onClose, isVideo }: TrainingIntroVideoProps) => {
  const [reachedEnd, setReachedEnd] = useState(false);
  const urls = makeStaticVideoUrls(videoID, isVideo);

  return (
    <Dialog.Root onOpenChange={() => onClose(reachedEnd)} open>
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(dialogStyles.DialogOverlay, styles.TrainingDialogOverlap)}
        />
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullWidth,
            styles.DialogContent,
          )}
        >
          <div className={styles.VideoContainer}>
            <Video
              videoUrl={urls.videoUrl}
              posterUrl={urls.posterUrl}
              subtitleUrl={urls.subtitleUrl}
              onEnded={() => setReachedEnd(true)}
            />
          </div>
          <div className={styles.VideoCloseButton}>
            <Button
              rightIcon={<TimesIcon />}
              onClick={() => onClose(reachedEnd)}
              onKeyDown={simulateClickOnEnter}
            >
              Close Video
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

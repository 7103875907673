import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './ListCard.module.css';

export type ListCardProps = {
  /**
   * Status of  the list card. If set, this adds a visual indicator to the left of the card with a
   * colour that reflects the status.
   */
  status?: 'complete' | 'in-progress' | 'not-started' | 'no-action' | 'disabled';
};

/**
 * A simple, rounded, white card which allows for a coloured status indicator on the left.
 * This is meant to match the Accordian styling where we don't actually need the accordian
 * functionality.
 */
export const ListCard = ({ status, children }: PropsWithChildren<ListCardProps>) => {
  return (
    <div
      className={classNames(styles.ListCard, {
        [styles.WithStatus]: status !== undefined,
        [styles.StatusComplete]: status === 'complete',
        [styles.StatusInProgress]: status === 'in-progress',
        [styles.StatusNotStarted]: status === 'not-started',
      })}
    >
      {children}
    </div>
  );
};

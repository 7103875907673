import { TaskCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import iconChallenge from 'images/icon-challenge.svg';
import iconCore from 'images/icon-core.svg';
import iconExtension from 'images/icon-extension.svg';
import iconGreenCheck from 'images/icon-green-check.svg';
import { useTaskItems } from 'queries/packages';

import {
  IndependentLearningTaskItem,
  IndependentLearningTaskItemDummy,
} from './IndependentLearningTaskItem';
import styles from './IndependentLearningTopicView.module.css';

interface IIndependentLearningTaskProps {
  taskCompletion: TaskCompletion;
}

/**
 * IndependentLearningTask is a task found within a Topic that has TaskItems for the student to
 * complete.
 * @param taskCompletion Information about the student's completion of the Task, such as number
 * of questions correct, incorrect etc. Also contains details about the task such as Title.
 */

export const IndependentLearningTask = ({ taskCompletion }: IIndependentLearningTaskProps) => {
  const taskItems = useTaskItems(taskCompletion.packageID, taskCompletion.taskIndex);
  if (!taskItems.data) {
    return null;
  }

  const complete = taskCompletion.numTaskItems === taskCompletion.numTaskItemsDone;
  const taskTitle = taskCompletion.title;
  let taskTypeIcon;
  switch (taskTitle) {
    case 'Introduce':
      taskTypeIcon = iconCore;
      break;
    case 'Strengthen':
      taskTypeIcon = iconExtension;
      break;
    case 'Deepen':
      taskTypeIcon = iconChallenge;
      break;
    default:
      console.error(`Unexpected task index for revision task: ${taskTitle}`);
      taskTypeIcon = iconChallenge;
  }

  // taskIcon is the icon that indicates the type of task (Introduce, Strengthen, Deepen) and has a
  // tick overlayed when it has been completed.
  const taskIcon = (
    <div className={styles.TaskIconWrapper}>
      <img src={taskTypeIcon} alt="" className={styles.TaskIcon} />
      {complete ? (
        <img
          src={iconGreenCheck}
          alt={`${taskTitle} completed`}
          className={styles.TaskCompleteIconLargeScreen}
        />
      ) : null}
    </div>
  );

  // taskCompleteIconSmallScreen is a separate tick to show that the task has been completed that
  // is only displayed at smaller screen sizes.
  const taskCompleteIconSmallScreen = complete ? (
    <div className={styles.TaskCompleteIconSmallScreenWrapper}>
      <img
        src={iconGreenCheck}
        alt={`${taskTitle} completed`}
        className={styles.TaskCompleteIconSmallScreen}
      />
    </div>
  ) : null;

  return (
    <li className={styles.IndependentLearningTask}>
      <div className={styles.IndependentLearningTaskTitle}>
        {taskIcon}
        {taskTitle}
        {taskCompleteIconSmallScreen}
      </div>
      <ol className={styles.IndependentLearningTaskItems}>
        {taskItems.data.map(taskItem => (
          <IndependentLearningTaskItem key={taskItem.taskItemIndex} taskItem={taskItem} />
        ))}
        {/*
          These invisible task items are necessary for the task item layout.
          There are 6 of them which is the maximum number of task items that appear on one row.
          The first invisible task items "pad out" the the last row of actual task items
            which pushes the actual task items on that row to the left. Without them they
            would be centered.
          The remaining invisible task items form a final invisible row. They expand
            (flex-grow is set on all task items) to fill the entire row which makes them
            larger than the actual task items. If they weren't there then the actual task
            items on the final row would grow and be larger than the rest.
        */}
        <IndependentLearningTaskItemDummy />
        <IndependentLearningTaskItemDummy />
        <IndependentLearningTaskItemDummy />
        <IndependentLearningTaskItemDummy />
        <IndependentLearningTaskItemDummy />
        <IndependentLearningTaskItemDummy />
      </ol>
    </li>
  );
};

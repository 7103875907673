import { Page } from 'components/page/Page';
import { useWhatsNewEntries } from 'components/whats-new/entries';
import { isValidEntry } from 'components/whats-new/hooks';
import { WhatsNewEntryBody } from 'components/whats-new/WhatsNewEntry';
import { Navigate, useParams } from 'react-router-dom';
import { useFeatureFlags } from 'utils/feature-flags';
import { useIsPrimaryStudent } from 'utils/session';

import styles from './WhatsNewDisplay.module.css';

/** WhatsNewDisplay shows the whats new post referenced in the url, ot redirects to '/' */
export const WhatsNewDisplay = () => {
  const { whatsNewID } = useParams();
  const flags = useFeatureFlags().getAllFlags();
  const isPrimaryStudent = useIsPrimaryStudent();

  const whatsNewEntries = useWhatsNewEntries();
  const whatsNewEntry = whatsNewEntries.find(entry => entry.notification?.id === whatsNewID);

  if (!whatsNewEntry || !isValidEntry(whatsNewEntry, flags, isPrimaryStudent)) {
    return <Navigate to={'/'} />;
  }

  return (
    <Page>
      <div className={styles.PageContainer}>
        <div className={styles.ContentContainer}>
          <header className={styles.Header}>
            <h1 className={styles.Title}>{whatsNewEntry?.title}</h1>
            <h2 className={styles.Subtitle}>{whatsNewEntry?.subtitle}</h2>
          </header>
          <WhatsNewEntryBody body={whatsNewEntry.body} />
        </div>
      </div>
    </Page>
  );
};

import { SparxWebFeatureSet } from '@sparx/api/sparxweb/swmsg/sparxweb';
import quizLogo from '@sparx/sparx-design/logos/sparx_quiz_logo_blue.svg';
import readerLogo from '@sparx/sparx-design/logos/sparx_reader_logo_blue.svg';
import scienceLogo from '@sparx/sparx-design/logos/sparx_science_logo_blue.svg';
import classNames from 'classnames';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { Page } from 'components/page/Page';
import assessmentIcon from 'images/feature-icons/icon_assessments.svg';
import classroomIcon from 'images/feature-icons/icon_classroom.svg';
import homeworkIcon from 'images/feature-icons/icon_homework.svg';
import { useSession } from 'queries/session';
import { Navigate, NavLink } from 'react-router-dom';
import { useTetheringSearchParams } from 'views/tether/TetherHooks';

import styles from './ChooseModeView.module.css';

type menuItemType = {
  buttonText: string;
  link: string;
  icon: string;
  mathsProduct: boolean;
};

const useMenuItems = (): Partial<Record<SparxWebFeatureSet, menuItemType>> => {
  return {
    [SparxWebFeatureSet.SparxWebFeatureSet_HOMEWORK]: {
      buttonText: 'My Homework',
      link: '/homework',
      icon: homeworkIcon,
      mathsProduct: true,
    },
    [SparxWebFeatureSet.SparxWebFeatureSet_ASSESSMENT]: {
      buttonText: 'Revision & Assessments',
      link: '/assessments',
      icon: assessmentIcon,
      mathsProduct: true,
    },
    [SparxWebFeatureSet.SparxWebFeatureSet_LESSONS]: {
      buttonText: 'Join a lesson',
      link: '/?sw1=true',
      icon: classroomIcon,
      mathsProduct: true,
    },
    [SparxWebFeatureSet.SparxWebFeatureSet_ENGLISH]: {
      buttonText: 'Sparx Reader',
      link: 'https://app.sparxreader.com/login',
      icon: readerLogo,
      mathsProduct: false,
    },
    [SparxWebFeatureSet.SparxWebFeatureSet_QUIZ]: {
      buttonText: 'Sparx Quiz',
      link: 'https://app.sparxquiz.com/',
      icon: quizLogo,
      mathsProduct: false,
    },
    [SparxWebFeatureSet.SparxWebFeatureSet_SCIENCE]: {
      buttonText: 'Sparx Science',
      link: 'https://app.sparxscience.com/',
      icon: scienceLogo,
      mathsProduct: false,
    },
  };
};

export const ChooseModeView = () => {
  const { data: session, isLoading } = useSession();
  const { isTethering, params } = useTetheringSearchParams();
  const menuItems = useMenuItems();

  if (isLoading || !session) {
    return (
      <Page>
        <LoadingSpinnerWithAnalytics
          componentName="ChooseModeView"
          sendLongTimeLoadingEvent={true}
        />
      </Page>
    );
  }

  if (isTethering) {
    return <Navigate to={`/tethering?${params.toString()}`} />;
  }

  const availableSessionTypes = session.student?.availableSessionTypes;
  if (
    !availableSessionTypes ||
    availableSessionTypes.length === 0 ||
    (availableSessionTypes.length === 1 &&
      availableSessionTypes[0] === SparxWebFeatureSet.SparxWebFeatureSet_HOMEWORK)
  ) {
    return <Navigate to="homework" />;
  }

  const availableSessionTypesMaths = availableSessionTypes.filter(
    sessionType => menuItems[sessionType]?.mathsProduct,
  );
  const availableSessionTypesSubjects = availableSessionTypes.filter(
    sessionType => menuItems[sessionType]?.mathsProduct === false,
  );

  return (
    <Page>
      <div className={styles.Container}>
        <div className={styles.Heading}>
          <p className={styles.Title}>Welcome to Sparx Maths</p>
          <p className={styles.Subtitle}>What would you like to do today?</p>
        </div>
        <div className={styles.MenuItemsContainer}>
          {availableSessionTypesMaths.map(sessionType => (
            <MenuItem
              key={sessionType.valueOf()}
              sessionType={sessionType}
              menuItem={menuItems[sessionType] as menuItemType}
            />
          ))}
        </div>
        {availableSessionTypesSubjects.length > 0 && (
          <>
            <div className={styles.Heading}>
              <p className={styles.Subtitle}>Switch subject:</p>
            </div>
            <div
              className={classNames(
                styles.MenuItemsContainer,
                styles.MenuItemsContainerOtherSubjects,
              )}
            >
              {availableSessionTypesSubjects.map(sessionType => (
                <MenuItem
                  key={sessionType}
                  sessionType={sessionType}
                  menuItem={menuItems[sessionType] as menuItemType}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Page>
  );
};

const MenuItem = ({
  sessionType,
  menuItem,
}: {
  sessionType: SparxWebFeatureSet;
  menuItem: menuItemType;
}) => {
  return (
    <NavLink
      className={classNames(styles.MenuItem, {
        [styles.MenuItemOtherSubject]: !menuItem.mathsProduct,
      })}
      key={sessionType}
      to={menuItem.link}
      reloadDocument={sessionType === SparxWebFeatureSet.SparxWebFeatureSet_LESSONS}
    >
      <img src={menuItem.icon} className={styles.MenuItemIcon} alt={menuItem.buttonText} />
      <div className={styles.MenuItemText}>{menuItem.buttonText}</div>
    </NavLink>
  );
};

import { makeCurriculumName, makeStrandName } from '@sparx/resource-names';
import { Breadcrumb } from '@sparx/sparx-design/components';
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'components/accordion/Accordion';
import { useCurriculumSummaries, useTopicSummariesMapForCurriculum } from 'queries/content';
import { useActivePackages, useAssignedTopics } from 'queries/independentlearning';
import { useAllCachedPackages } from 'queries/packages';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { validateUUID } from 'utils/uuid';

import { useGetAssignedTopic, useSelectedCurriculumName } from '../hooks';
import { calculateSubstrandProgress, getAccordionStatusFromSubstrandProgress } from '../utils';
import styles from './StrandView.module.css';
import { SubstrandTopic } from './SubstrandTopic';

/**
 * Strand page for independent learning - shows all the sub-strands for a strand for the user to navigate to
 */

export const StrandView = () => {
  const sendEvent = useAnalytics();

  const [openSubstrandNames, setOpenSubstrandNames] = useState<string[]>([]);
  const [initialSubstrandOpened, setInitialSubstrandOpened] = useState(false);

  const { curriculumID: curriculumIDParam, strandID } = useParams();
  const curriculumID = validateUUID(curriculumIDParam);

  const curriculums = useCurriculumSummaries();
  const selectedCurriculumName = useSelectedCurriculumName();
  const selectedCurriculum = curriculums?.find(
    curriculum => curriculum.curriculum?.name === selectedCurriculumName,
  );
  const notViewingSelectedCurriculum = makeCurriculumName(curriculumID) !== selectedCurriculumName;

  const getAssignedTopic = useGetAssignedTopic();

  // Get details of the strand from the curriculum summary
  const strandName = makeStrandName(curriculumID || '', strandID || '');

  const strandSummary = selectedCurriculum?.strandSummaries.find(
    strand => strand.strand?.name === strandName,
  );
  const strand = strandSummary?.strand;

  const topicSummariesMap = useTopicSummariesMapForCurriculum(selectedCurriculumName);

  const { data: activePackages } = useActivePackages(selectedCurriculumName);
  const packages = useAllCachedPackages();

  const { data: assignedTopics } = useAssignedTopics();

  const substrandSummaries = strandSummary?.substrandSummaries;

  // Open a substrand if the open_package_id query parameter is set.
  useEffect(() => {
    if (substrandSummaries && !initialSubstrandOpened) {
      const params = new URLSearchParams(document.location.search);
      const openSubstrandID = params.get('open_substrand_id');
      if (openSubstrandID) {
        setOpenSubstrandNames([`${strandName}/substrands/${openSubstrandID}`]);
        setInitialSubstrandOpened(true);
      }
    }
  }, [initialSubstrandOpened, substrandSummaries, strandName]);

  // Work out the progress info to show for each substrand, and each topic within.
  // Memoized as a lot of calculations
  const progress = useMemo(
    () =>
      calculateSubstrandProgress(
        topicSummariesMap,
        activePackages?.packages,
        packages,
        substrandSummaries,
        assignedTopics,
      ),
    [activePackages, packages, assignedTopics, substrandSummaries, topicSummariesMap],
  );

  // If the curriculum ID is not valid or if we're not viewing a topic in the selected curriculum (e.g. a direct link from the search)
  // then redirect to the independent learning page
  if (curriculumID === '' || notViewingSelectedCurriculum) {
    return <Navigate to="/independentlearning" replace />;
  }

  // Note, the SuspenseRoute component will handle showing spinner/error if this happens
  if (!strandSummary || !strand || !topicSummariesMap || !activePackages || !progress) {
    return null;
  }

  const { substrandProgress, topicProgress } = progress;

  /**
   * Handler for when a substrand is expanded or collapsed, including sending a page event
   * @param value
   */
  const onValueChange = (value: string[]) => {
    setOpenSubstrandNames(value);

    // Send page event about whether the strand is expanded or collapsed:
    let open = true;
    let substrands = value.filter(v => !openSubstrandNames.includes(v));
    if (substrands.length === 0) {
      open = false;
      substrands = openSubstrandNames.filter(v => !value.includes(v));

      if (substrands.length === 0) {
        return;
      }
    }

    for (const substrandName of substrands) {
      sendEvent({
        category: 'revision',
        action: 'substrand click',
        labels: {
          substrand_key: substrandName,
          open: open,
        },
      });
    }
  };

  return (
    <div className={styles.StrandView}>
      <Breadcrumb
        className={styles.Breadcrumb}
        items={[
          { label: 'Independent learning', path: '/independentlearning' },
          { label: strand.displayName },
        ]}
      />
      <AccordionRoot value={openSubstrandNames} onValueChange={onValueChange}>
        {strandSummary.substrandSummaries.map(substrandSummary => {
          if (!substrandSummary.substrand) {
            return null;
          }

          const ssProgress = substrandProgress.get(substrandSummary.substrand.name);
          const status = getAccordionStatusFromSubstrandProgress(ssProgress);

          return (
            <AccordionItem
              value={substrandSummary.substrand.name}
              key={substrandSummary.substrand.name}
              status={status}
            >
              <AccordionTrigger>
                <div className={styles.Substrand}>
                  <div className={styles.SubstrandLeft}>
                    {substrandSummary.substrand?.displayName}
                  </div>
                  {ssProgress && ssProgress.startedTopics > 0 && (
                    <div
                      className={styles.SubstrandRight}
                    >{`${ssProgress.startedTopics}/${ssProgress.topicCount} started`}</div>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent>
                {substrandSummary.substrand?.topicNames.map(topicName => {
                  const topicSummary = topicSummariesMap.get(topicName);
                  const topic = topicSummary?.topic;
                  if (!topic) {
                    return null;
                  }
                  const assignedTopic = getAssignedTopic(topic.name);
                  const tProgress = topicProgress.get(topic.name);

                  return (
                    <SubstrandTopic
                      key={topic.name}
                      topic={topic}
                      seenInHomework={!!assignedTopic}
                      topicProgress={tProgress}
                      substrandName={substrandSummary.substrand?.name || ''}
                    />
                  );
                })}
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </AccordionRoot>
    </div>
  );
};

import { useEffect } from 'react';
import { useFeatureFlags } from 'utils/feature-flags';

/** Is used to set up things before initial App Load e.g. accessibility settings*/
export const useInitializeApp = () => {
  useInitAccessibilitySettings();
};

const useInitAccessibilitySettings = () => {
  const featureFlags = useFeatureFlags();
  useEffect(() => {
    if (featureFlags.getBooleanFlag('sparxweb-a11y-selectable-text', false)) {
      const root = document.querySelector('html');
      root?.style.setProperty('--user-select-accessibility-setting', 'default');
    }
  }, [featureFlags]);
};

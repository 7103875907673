import { GetTetherRequest, GetTetherResponse } from '@sparx/api/apis/sparx/swworker/v1/worker';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'context/api';
import { useState } from 'react';

export const useTetherSession = (sessionName: string) => {
  const { swworkerClient } = useAPI();
  const [lastTetherId, setLastTetherId] = useState('');

  const query = useQuery(
    ['tether', sessionName],
    ({ signal }) =>
      swworkerClient.getTether(
        GetTetherRequest.create({
          sessionName,
          lastTetherId,
        }),
        { abort: signal },
      ).response,
    {
      enabled: sessionName !== '',
      retry: true,
      refetchInterval: 100,
      onSuccess: (data: GetTetherResponse) => {
        if (data.tetherId !== lastTetherId) {
          setLastTetherId(data.tetherId);
        }
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const queryClient = useQueryClient();

  const restartActivity = () => {
    setLastTetherId('');
    queryClient.invalidateQueries(['tether', sessionName]);
  };

  return { ...query, restartActivity };
};

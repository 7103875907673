import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as Switch from '@radix-ui/react-switch';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Button } from '@sparx/sparx-design/components';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { produce } from 'immer';
import { Suspense, useState } from 'react';

import { useLeaderboardContext } from '../context/context';
import {
  makeUserDisplayName,
  useGeneratePositiveNoun,
  useGetUserDisplayData,
  useGetUserDisplayName,
  useUpdateUserDisplay,
} from '../queries/userdisplay';
import { isUserOptedOut } from '../utils';
import styles from './NameChooser.module.css';

export const NameChooserBar = () => {
  const { sendAnalyticsEvent, firstName = '', product } = useLeaderboardContext();
  const queryClient = useQueryClient();

  const userDisplayName = useGetUserDisplayName();

  const { data: userDisplayData } = useGetUserDisplayData();
  if (!userDisplayData) {
    // should be rendered inside <Suspense>
    throw new Error('User display data not found');
  }

  const isOptedOut = isUserOptedOut(userDisplayData, product);

  const { mutate: updateUserDisplay } = useUpdateUserDisplay();
  const { mutate: generatePositiveNoun } = useGeneratePositiveNoun();

  const [tempDisplayData, setTempDisplayData] = useState(userDisplayData);
  const tempDisplayName = makeUserDisplayName(firstName, tempDisplayData.positiveNoun);

  const onSave = () => {
    // if the user is opting in, refetch the leaderboard
    const shouldRefetchLeaderboard =
      isUserOptedOut(userDisplayData, product) && !isUserOptedOut(tempDisplayData, product);

    updateUserDisplay(
      {
        userDisplayData: tempDisplayData,
      },
      {
        onSuccess: () => {
          if (shouldRefetchLeaderboard) {
            queryClient.invalidateQueries(['leaderboards']);
          }
        },
      },
    );
    sendAnalyticsEvent({
      action: 'Updated display data',
      labels: {
        positiveNoun: tempDisplayData.positiveNoun,
        optedOut: isUserOptedOut(tempDisplayData, product).toString(),
      },
    });
  };

  const onCancel = () => {
    setTempDisplayData(userDisplayData);
  };

  const onRandomise = () =>
    generatePositiveNoun(undefined, {
      onSuccess: resp => {
        setTempDisplayData(c =>
          produce(c, d => {
            d.positiveNoun = resp.positiveNoun;
          }),
        );
      },
    });

  const onToggleOptOut = () => {
    setTempDisplayData(c =>
      produce(c, d => {
        if (d.optedOutProducts.includes(Product.SPARX_MATHS)) {
          d.optedOutProducts = d.optedOutProducts.filter(p => p !== Product.SPARX_MATHS);
        } else {
          d.optedOutProducts.push(Product.SPARX_MATHS);
        }
      }),
    );
  };

  return (
    <div className={styles.NameChooser}>
      <div className={styles.NameChooserName}>
        {!isOptedOut && <strong>Name:</strong>}
        <Suspense>
          <span className={styles.NameChooserName}>
            {isOptedOut ? 'You have left the Sparx Maths Leaderboards' : userDisplayName}
          </span>
        </Suspense>
      </div>

      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button className={styles.TriggerButton}>Leaderboard Settings</Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className={dialogStyles.DialogOverlay} />
          <Dialog.Content
            className={classNames(
              styles.DialogContent,
              dialogStyles.DialogContent,
              dialogStyles.ContentHeight,
            )}
          >
            <Dialog.Title className={styles.DialogTitle}>Leaderboard Settings</Dialog.Title>
            <Dialog.Description className={styles.DialogDescription}>
              <span className={styles.Subheader}>Choose your name</span>
              <span>
                This will be your public name on all Sparx Leaderboards. You can click to create a
                new random name.
              </span>
            </Dialog.Description>
            <div className={styles.NameRandomiserBar}>
              <span>{tempDisplayName}</span>
              <Button variant="contained" size="sm" onClick={onRandomise}>
                Randomise
              </Button>
            </div>
            <Dialog.Description className={styles.DialogDescription}>
              <span className={styles.Subheader}>Leave the Sparx Maths Leaderboard</span>
              <span>
                If you do not want to appear on Sparx Maths Leaderboards you can choose to leave.
                You will no longer be able to see any of the leaderboards but you can choose to join
                again later.
              </span>
            </Dialog.Description>
            <label className={styles.SwitchContainer}>
              <span
                className={classNames({
                  [styles.Bold]: !isUserOptedOut(tempDisplayData, product),
                })}
              >
                On
              </span>
              <Switch.Root
                className={classNames(
                  styles.SwitchRoot,
                  styles.Switch,
                  accessibilityStyles.FocusTarget,
                )}
                onCheckedChange={onToggleOptOut}
                defaultChecked={isUserOptedOut(userDisplayData, product)}
              >
                <Switch.Thumb className={styles.SwitchThumb} />
              </Switch.Root>
              <span
                className={classNames({
                  [styles.Bold]: isUserOptedOut(tempDisplayData, product),
                })}
              >
                Off
              </span>
            </label>
            <div className={styles.DialogButtonContainer}>
              <Dialog.Close asChild onClick={onCancel}>
                <Button size="sm">Cancel</Button>
              </Dialog.Close>
              <Dialog.Close asChild>
                <Button size="sm" variant="contained" onClick={onSave}>
                  Save Changes
                </Button>
              </Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className={dialogStyles.IconButton} aria-label="Close" onClick={onCancel}>
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

import { NumberBondsAnswer } from '@sparx/api/apis/sparx/games/progress/numberbonds/v1/numberbonds';
import { PhonicsAnswer } from '@sparx/api/apis/sparx/games/progress/phonics/v1/phonics';
import { TablesAnswer } from '@sparx/api/apis/sparx/games/progress/tablesanswer/v1/tablesanswer';
import { ActivityActionResponse } from '@sparx/api/apis/sparx/swworker/v1/worker';
import { AnswerComponent } from '@sparx/api/sparxweb/answer/answer';
import {
  Activity,
  ActivityAction,
  ActivityStatus,
  ActivityType,
  GetActivityRequest,
  GetActivityRequest_Method,
  QuestionAction_ActionType,
  QuestionActivity,
  QuizAction_ActionType,
  RewardGroup_Type,
  VideoAction,
  VideoQuestionAction_ActionType,
  WACAction_ActionType,
  WACActivity,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { answersToAnswerComponents, buildStepAnswer } from '@sparx/question';
import { IInput } from '@sparx/question/src/question/types';
import { getNowTimestamp } from '@sparx/react-utils';
import { makePackageName, makeTaskName } from '@sparx/resource-names';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import { StudentExperienceTrainingStep } from 'content/training/trainingModules';
import { useAPI } from 'context/api';
import { useRewards } from 'context/rewards';
import { useTraining } from 'context/training';
import { useVideoNudgeState } from 'context/videonudge';
import { updateWithPackageData } from 'queries/packages';
import { useSetStudentRewardsQuery } from 'queries/studentrewards';
import { isPermissionDeniedError, isWACError } from 'utils/errors';
import { isPkgOrTaskComplete } from 'utils/package';
import { SelectedWACOption } from 'views/wac/WACDisplay';

import { ensureTimestamp } from './utils';

export const activityMutationKey = ['activity'];

const activityTypeToString = (t: ActivityType): string => ActivityType[t];
const activityStatusToString = (s: ActivityStatus): string => ActivityStatus[s];
export const activityDebug = (t: ActivityType, action: string, s: ActivityStatus): string =>
  `[ACT] ${activityTypeToString(t)} ${action} - ${activityStatusToString(s)}`;

export const useNewActivity = (
  activityType: ActivityType,
  packageID?: string,
  taskIndex?: number,
  taskItemIndex?: number,
  method?: GetActivityRequest_Method,
) => {
  const { swworkerClient } = useAPI();
  return useMutation({
    mutationKey: activityMutationKey,
    mutationFn: () => {
      console.log('[ACT] START', packageID, taskIndex, taskItemIndex, method);
      return swworkerClient.getActivity(
        GetActivityRequest.create({
          activityType: activityType,
          taskItem: {
            packageID,
            taskIndex,
            taskItemIndex,
          },
          method: method,
          timestamp: getNowTimestamp(),
        }),
      ).response;
    },
    retry: (retryCount, error) => {
      // don't retry if we get a wac error, and never retry more than thrice
      // because we wait for activity mutations to finish before re-enabling lqd navigation, we want
      // these to settle fairly quickly
      return !isWACError(error) && !isPermissionDeniedError(error) && retryCount < 3;
    },
    onSuccess: data => {
      console.log(activityDebug(data.activityType, 'STARTED', data.status));
    },
  });
};

// isActionQuestionAnswer returns true if the action is an answer to a question
export const isActionQuestionAnswer = (action: ActivityAction) =>
  action.action.oneofKind === 'question' &&
  action.action.question.actionType === QuestionAction_ActionType.ANSWER;

export const isActionTTAnswer = (action: ActivityAction) =>
  action.action.oneofKind === 'game' && action.action.game.action.oneofKind === 'tablesAnswer';

export const isActionVideoQuestionComplete = (action: ActivityAction) =>
  action.action.oneofKind === 'videoQuestion' &&
  action.action.videoQuestion.actionType === VideoQuestionAction_ActionType.COMPLETE;

/** useActivityAction returns a mutation which can be used to send an activity action to the server
 * On Success it will update the cache with the updated package data, and also refetch rewards to
 * ensure that xp is up to date.
 * Any options passed will overwrite the defaults, with the exception of onError and onSuccess which
 * will be called in addition to default behavior.
 */
export const useActivityAction = (
  options?: UseMutationOptions<ActivityActionResponse, unknown, ActivityAction, unknown>,
) => {
  const { swworkerClient } = useAPI();
  const { setActivityRewards, setRewardsPending } = useRewards();
  const { completeTask: completeTrainingTask } = useTraining();
  const { updateVideoNudgeState } = useVideoNudgeState();

  const { onError: passedOnError, onSuccess: passedOnSuccess, ...passedOptions } = options || {};

  const setStudentRewards = useSetStudentRewardsQuery();

  return useMutation({
    mutationFn: async (action: ActivityAction) =>
      swworkerClient.activityAction(ensureTimestamp(action)).response,
    mutationKey: activityMutationKey,
    retry: false,
    onSuccess: (data, action, ctx) => {
      const pkg = data.packageData?.packages[0];

      passedOnSuccess?.(data, action, ctx);

      // if we got a hint back, only do the passed on success
      if (data.response?.hints && data.response.status === 'ACTIVE') {
        return;
      }

      // in the special case of a video question (only used in onboarding currently), we need to
      // immediately update the xp rewards, as we don't have any task summary screen where it would
      // otherwise happen
      if (isActionVideoQuestionComplete(action)) {
        const task = data.packageData?.tasks[0];
        const taskRewards = data.response?.rewardGroups.find(
          rewardGroup => rewardGroup.type === RewardGroup_Type.TASK,
        );
        if (task && taskRewards && taskRewards.rewards.length) {
          setStudentRewards(taskRewards.xpStateAfterApplying);
        }
      }

      // if we answered a question, perform some specific actions:
      // - check for rewards and update xp were appropriate
      // - complete the "ANSWER_QUESTION_CORRECTLY" step of teacher training if appropriate
      // - update the video nudge tutorial state
      if (isActionQuestionAnswer(action)) {
        // package data should only contain the task & package that we're currently on
        const task = data.packageData?.tasks[0];
        const taskRewards = data.response?.rewardGroups.find(
          rewardGroup => rewardGroup.type === RewardGroup_Type.TASK,
        );
        if (task && taskRewards && taskRewards.rewards.length) {
          const taskName = makeTaskName(task.packageID, task.taskIndex);
          setActivityRewards(existingRewards => ({
            ...existingRewards,
            [taskName]: taskRewards,
          }));
          setRewardsPending(existingPending => ({
            ...existingPending,
            [RewardGroup_Type.TASK]: true,
          }));
        }

        const pkg = data.packageData?.packages[0];
        const packageRewards = data.response?.rewardGroups.find(
          rewardGroup => rewardGroup.type === RewardGroup_Type.PACKAGE,
        );
        if (pkg && packageRewards && packageRewards.rewards.length) {
          const packageName = makePackageName(pkg.packageID);
          setActivityRewards(existingRewards => ({
            ...existingRewards,
            [packageName]: packageRewards,
          }));
          setRewardsPending(existingPending => ({
            ...existingPending,
            [RewardGroup_Type.PACKAGE]: true,
          }));
        }

        if (data.response?.status === 'SUCCESS') {
          // If a correct answer, complete the ANSWER_QUESTION_CORRECTLY step of teacher training if applicable
          completeTrainingTask(StudentExperienceTrainingStep.ANSWER_QUESTION_CORRECTLY);

          // If we just completed a compulsory training package, complete the "FINISH_COMPULSORY_TASK" step of teacher
          // training if applicable:
          if (pkg?.packageType === 'homework-training' && task && isPkgOrTaskComplete(task)) {
            completeTrainingTask(StudentExperienceTrainingStep.FINISH_COMPULSORY_TASK);
          }
        }

        // should only contain the task item just answered, but is an array
        const taskItem = data.packageData?.taskItems[0];
        if (taskItem) {
          updateVideoNudgeState(taskItem);
        }
      }

      // If we got a WAC wrong, complete the "FAIL_BOOKWORK_CHECK" step of teacher training if applicable:
      if (
        action.action.oneofKind === 'wac' &&
        action.action.wac.actionType === WACAction_ActionType.ANSWER &&
        data.response?.status === 'FAILURE'
      ) {
        completeTrainingTask(StudentExperienceTrainingStep.FAIL_BOOKWORK_CHECK);
      }

      // If we completed an independent learning question, complete the "TRY_INDEPENDENT_LEARNING" step of teacher
      // training if applicable:
      if (
        action.action.oneofKind === 'question' &&
        action.action.question.actionType === QuestionAction_ActionType.ANSWER &&
        pkg?.packageType === 'revision'
      ) {
        completeTrainingTask(StudentExperienceTrainingStep.TRY_INDEPENDENT_LEARNING);
      }

      data.packageData && updateWithPackageData(data.packageData);
      console.log(`[ACT] ${action.action.oneofKind} MUTATED - ${data.response?.status}`);
    },
    onError: (error, action, ctx) => {
      console.log(`[ACT] ${action.action.oneofKind} MUTATE ERROR - ${String(error)}`);
      passedOnError?.(error, action, ctx);
    },
    ...passedOptions,
  });
};

const newActivityAction = (activity: Activity, action: ActivityAction['action']): ActivityAction =>
  ActivityAction.create({
    activityIndex: activity.activityIndex,
    timestamp: getNowTimestamp(),
    action,
  });

export const buildViewAction = (activity: Activity, questionActivity: QuestionActivity) =>
  newActivityAction(activity, {
    oneofKind: 'question',
    question: {
      actionType: QuestionAction_ActionType.VIEW,
      questionIndex: questionActivity.questionIndex,
    },
  });

export const buildDismissAction = (activity: Activity, questionActivity: QuestionActivity) =>
  newActivityAction(activity, {
    oneofKind: 'question',
    question: {
      actionType: QuestionAction_ActionType.DISMISS,
      questionIndex: questionActivity.questionIndex,
    },
  });

export const buildAnswerAction = (
  input: IInput,
  activity: Activity,
  questionActivity: QuestionActivity,
) => {
  const answer = buildStepAnswer(input);
  const answerComponents = answersToAnswerComponents(answer);

  const components = Object.entries(answerComponents).map(
    ([key, value]): AnswerComponent => ({
      key,
      value,
    }),
  );

  return newActivityAction(activity, {
    oneofKind: 'question',
    question: {
      actionType: QuestionAction_ActionType.ANSWER,
      questionIndex: questionActivity.questionIndex,
      answer: {
        components,
        hash: '', // TODO?
      },
    },
  });
};

export const buildTablesAnswerAction = (activity: Activity, answers: TablesAnswer[]) =>
  newActivityAction(activity, {
    oneofKind: 'game',
    game: {
      action: {
        oneofKind: 'tablesAnswer',
        tablesAnswer: {
          answers,
        },
      },
    },
  });

export const buildPhonicsAnswerAction = (activity: Activity, answers: PhonicsAnswer[]) =>
  newActivityAction(activity, {
    oneofKind: 'game',
    game: {
      action: {
        oneofKind: 'phonicsAnswer',
        phonicsAnswer: {
          answers,
        },
      },
    },
  });

export const buildNumberBondsRhythmAnswerAction = (
  activity: Activity,
  answers: NumberBondsAnswer[],
) =>
  newActivityAction(activity, {
    oneofKind: 'game',
    game: {
      action: {
        oneofKind: 'numberBondsAnswer',
        numberBondsAnswer: {
          answers,
        },
      },
    },
  });

export const buildVideoAction = (activity: Activity, action: VideoAction) =>
  newActivityAction(activity, {
    oneofKind: 'video',
    video: action,
  });

// TODO: merge with useActivity?
export const useWACActivity = (packageID?: string, taskIndex?: number, taskItemIndex?: number) => {
  const { swworkerClient } = useAPI();
  return useQuery(
    ['wac-activity', packageID, taskIndex, taskItemIndex],
    () => {
      console.log('[ACT] WAC START', packageID, taskIndex, taskItemIndex);
      return swworkerClient.getActivity(
        GetActivityRequest.create({
          activityType: ActivityType.WAC,
          taskItem: {
            packageID,
            taskIndex,
            taskItemIndex,
          },
          timestamp: getNowTimestamp(),
        }),
      ).response;
    },
    {
      staleTime: Infinity, // don't refetch when active
      cacheTime: 0,
      enabled:
        packageID !== undefined &&
        taskIndex !== undefined &&
        taskItemIndex !== undefined &&
        taskItemIndex !== 0,
      retry: false,
      onSuccess: data => console.log(activityDebug(data.activityType, 'WAC STARTED', data.status)),
    },
  );
};

export const buildViewWACAction = (activity: Activity) =>
  newActivityAction(activity, {
    oneofKind: 'wac',
    wac: {
      actionType: WACAction_ActionType.VIEW,
      extraData: {},
    },
  });

export const buildDismissWACAction = (activity: Activity) =>
  newActivityAction(activity, {
    oneofKind: 'wac',
    wac: {
      actionType: WACAction_ActionType.DISMISS,
      extraData: {},
    },
  });

export const buildAnswerWACAction = (
  activity: Activity,
  wacActivity: WACActivity,
  selectedOption: SelectedWACOption,
) => {
  const option =
    selectedOption === 'not-written-down' ? undefined : wacActivity.options[selectedOption];

  return newActivityAction(activity, {
    oneofKind: 'wac',
    wac: {
      actionType: WACAction_ActionType.ANSWER,
      answer: {
        components: option?.wacedAnswer?.components || [],
        hash: '', // TODO?
      },
      extraData: option ? { answerMarkup: option.filledAnswerTemplate } : {},
    },
  });
};

export const useGameActivity = (
  packageID?: string,
  taskIndex?: number,
  gameId?: string,
  options?: {
    enabled?: boolean;
  },
) => {
  const { swworkerClient } = useAPI();
  return useQuery(
    ['game-activity', packageID, taskIndex],
    () => {
      console.log('[ACT] START', packageID, taskIndex, gameId);
      const request = GetActivityRequest.create({
        activityType: ActivityType.QUIZGAME,
        taskItem: {
          packageID,
          taskIndex,
        },
        payload: {
          oneofKind: 'gameID',
          gameID: gameId || '',
        },
        timestamp: getNowTimestamp(),
      });
      return swworkerClient.getActivity(request).response;
    },
    {
      staleTime: Infinity, // don't refetch when active
      cacheTime: 0,
      enabled: (options?.enabled ?? true) && packageID !== undefined && taskIndex !== undefined,
      onSuccess: data => console.log(activityDebug(data.activityType, 'STARTED', data.status)),
    },
  );
};

export const useActivityFeed = () => {
  const { swworkerClient } = useAPI();
  return useQuery(['activityFeed'], () => swworkerClient.getActivityFeed({}).response, {
    suspense: true,
  });
};

// Fast Track quiz actions
export const buildPauseQuizAction = (activity: Activity, questionActivity: QuestionActivity) =>
  newActivityAction(activity, {
    oneofKind: 'quiz',
    quiz: {
      actionType: QuizAction_ActionType.PAUSE,
      questionIndex: questionActivity.questionIndex,
    },
  });

export const buildViewQuizAction = (activity: Activity, questionActivity: QuestionActivity) =>
  newActivityAction(activity, {
    oneofKind: 'quiz',
    quiz: {
      actionType: QuizAction_ActionType.VIEW,
      questionIndex: questionActivity.questionIndex,
    },
  });

export const buildStoreQuizAnswerAction = (
  activity: Activity,
  questionActivity: QuestionActivity,
  input: IInput,
) => {
  const answer = buildStepAnswer(input);
  const answerComponents = answersToAnswerComponents(answer);

  const components = Object.entries(answerComponents).map(
    ([key, value]): AnswerComponent => ({
      key,
      value,
    }),
  );

  return newActivityAction(activity, {
    oneofKind: 'quiz',
    quiz: {
      actionType: QuizAction_ActionType.STORE_ANSWER,
      questionIndex: questionActivity.questionIndex,
      answer: {
        components,
        hash: '', // TODO?,
      },
    },
  });
};

export const buildClearQuizAnswerAction = (
  activity: Activity,
  questionActivity: QuestionActivity,
) => {
  return newActivityAction(activity, {
    oneofKind: 'quiz',
    quiz: {
      actionType: QuizAction_ActionType.STORE_ANSWER,
      questionIndex: questionActivity.questionIndex,
    },
  });
};

import { useContext } from 'react';

import { KeyboardModeContext } from './provider';

export const useKeyboardMode = () => {
  const ctx = useContext(KeyboardModeContext);
  if (!ctx) {
    throw new Error('useKeyboardMode must be used within a KeyboardModeProvider');
  }
  return ctx;
};

/**
 * Polyfill for Element.closest which will fallback to the built in method
 * if we are on a browser where it exists.
 * Adapted from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
 * @param element the element root
 * @param sel the selector to find in element parent tree
 */
export const closestElement = (element: Element, sel: string) => {
  if (element.closest) {
    // Polyfill not required
    return element.closest(sel);
  }
  let iterEl: (Node & ParentNode) | null = element;
  do {
    if (Element.prototype.matches.call(iterEl, sel)) {
      return iterEl;
    }
    iterEl = iterEl.parentElement || iterEl.parentNode;
  } while (iterEl !== null && iterEl.nodeType === 1);
  return null;
};

import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './StatusCard.module.css';

export type StatusCardStatus = 'complete' | 'in-progress' | 'not-started' | 'locked';

type StatusCardProps = {
  /**
   * Additional class names for the card.
   */
  className?: string;
  /**
   * Status of the card. This affects the colour and in the case of locked, the behavior.
   */
  status: StatusCardStatus;
  /**
   * Image src for the icon
   */
  icon?: string;
};

/**
 * Coloured card to display data.
 */
export const StatusCard = ({
  className,
  icon,
  status,
  children,
}: PropsWithChildren<StatusCardProps>) => {
  return (
    <article
      className={classNames(className, styles.StatusCard, {
        [styles.Complete]: status === 'complete',
        [styles.InProgress]: status === 'in-progress',
        [styles.NotStarted]: status === 'not-started',
        [styles.Locked]: status === 'locked',
      })}
    >
      {icon && <img className={styles.Icon} src={icon} />}
      {children}
    </article>
  );
};

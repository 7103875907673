import {
  Assessment,
  GetAssessmentSummaryRequest,
  GetOwnAssessmentPackagesRequest,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { makeSchoolName } from '@sparx/resource-names';
import { useQuery } from '@tanstack/react-query';
import { useAPI } from 'context/api';
import { useIsFixUpAvailable } from 'utils/assessment';

import { updateWithPackageData, useTaskItems } from './packages';
import { useSession } from './session';

/**
 * Load the available assessment tasks and associated assessments for the student.
 */
export const useAssessmentSummary = () => {
  const { data: session } = useSession();
  const { assessmentsClient } = useAPI();
  const schoolID = window.__sparxweb.schoolID;

  return useQuery(
    ['assessments', 'summary'],
    async () =>
      assessmentsClient.getAssessmentSummary(
        GetAssessmentSummaryRequest.create({
          schoolName: makeSchoolName(schoolID),
        }),
      ).response,
    {
      enabled: session?.student?.studentType === 'Student',
      // Assessment data (the assessment itself and the student results) are unlikely to change, so we cache it forever.
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
    },
  );
};

/**
 * useAssessments returns the list of assessments which have active assessment tasks for the user.
 */
export const useAssessments = () => {
  const { data: assessmentSummary, ...rest } = useAssessmentSummary();

  return {
    data: assessmentSummary?.assessments,
    ...rest,
  };
};

/**
 * useAssessmentsAvailable returns true if there are any assessment tasks active for the user.
 */
export const useAssessmentsAvailable = (): boolean => {
  const { data: assessments } = useAssessments();
  return !!assessments;
};

/**
 * useAssessment returns the assessment with the given name.
 * If the assessment is not available for the student, it will return undefined.
 */
export const useAssessment = (assessmentName: string): Assessment | undefined => {
  const { data: assessments } = useAssessments();

  return assessments?.find(assessment => assessment.name === assessmentName);
};

/**
 * Returns the list of active fix up tasks for the user.
 */
export const useAssessmentFixUpTasks = () => {
  const { data: assessmentSummary, ...rest } = useAssessmentSummary();

  return {
    data: assessmentSummary?.fixUpTasks,
    ...rest,
  };
};

/**
 * Returns the fix up task for the given assessment name or undefined if there is no active fix up task for the assessment.
 */
export const useAssessmentFixUpTask = (assessmentName: string) => {
  const { data: fixUpTasks, ...rest } = useAssessmentFixUpTasks();

  return {
    data: fixUpTasks?.find(t => t.assessmentName === assessmentName),
    ...rest,
  };
};

export const makeAssessmentPackagesQueryKey = (assessmentName: string) => [
  'assessments',
  'packages',
  assessmentName,
];

/**
 * useAssessmentPackages returns the packageData available for the given name.
 * Although it retuns all package data, the package hooks (ie. useTaskItems etc.)
 * should be used for progress data. Generally it should only be used to find which
 * packages exist.
 */
export const useAssessmentPackages = (assessmentName: string) => {
  const { assessmentsClient } = useAPI();
  return useQuery(
    makeAssessmentPackagesQueryKey(assessmentName),
    () =>
      assessmentsClient
        .getOwnAssessmentPackages(
          GetOwnAssessmentPackagesRequest.create({
            assessmentName,
            schoolId: window.__sparxweb.schoolID,
          }),
        )
        .response.then(res => res.packageData),
    {
      enabled: assessmentName !== '',
      onSuccess: packageData => {
        // Update the shared package queries
        packageData && updateWithPackageData(packageData);
      },
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
    },
  );
};

/**
 * useFixUpTaskItems returns the FixUp task task items for the given assessment.
 * If there is no fix up package assigned or the student has no questions to fix up it will return
 * an empty array.
 */
export const useFixUpTaskItems = (assessmentName: string) => {
  const assessment = useAssessment(assessmentName);
  const { data: fixUpTask } = useAssessmentFixUpTask(assessmentName);
  const fixupAvailable = useIsFixUpAvailable(assessment, fixUpTask?.studentAssessment);

  assessmentName = fixupAvailable ? assessmentName : '';

  const { data: packageData, isLoading: dataLoading } = useAssessmentPackages(assessmentName);
  // Find the fix up package ID
  const pkg = packageData?.packages.find(pkg => pkg.packageType === 'assessment-fixup');

  const packageID = pkg?.status === 'CONTENTS_GENERATED' ? pkg.packageID : undefined;

  const { data: taskItems, isLoading: taskItemsLoading } = useTaskItems(packageID, 1);

  return {
    taskItems: taskItems ?? [],
    isLoading: !!assessmentName && (dataLoading || taskItemsLoading),
  };
};

import { ChevronLeft } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { CLOSE_TRAINING_MENU, HOMEPAGE_LINK } from 'content/training/trainingModules';
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isCompletable, isIntro, isVideo, Module, Step, TrainingStepID } from 'types/training';
import { useAnalytics } from 'utils/analytics';
import { makeHomePath } from 'utils/paths';

import {
  clickedHomeScreenLinkViaIndependentLearningStepEvent,
  clickedNeedHelpEvent,
  clickedOnTrainingStepEvent,
  clickedViewAllStepsEvent,
  clickedViewIntroVideoEvent,
  clickedViewVideoEvent,
} from './analytics';
import { CompletableStepPage } from './CompletableStepPage';
import { TrainingHelpVideo } from './help-video/TrainingHelpVideo';
import { SummaryStepPage } from './SummaryStepPage';
import { TaskListItem } from './TaskListItem';
import styles from './TrainingBanner.module.css';

/**
 * TrainingDropdownMenu shows a list of all the steps in the current module. These can be clicked on
 * for the menu to show extra information about that step, such as context, list of instructions to
 * complete it and buttons to show help videos.
 */

interface ITrainingDropdownMenuProps {
  // All steps in the current training module
  steps: Step[];
  // The current training module being completed
  currentModule: Module;
  // The currently selected step, if any
  selectedStep?: Step;
  // Whether all steps are being shown.
  // If true then we're showing a list of all steps.
  // If false then we're showing details about a specific step
  showingAllSteps: boolean;
  // Function to set the status of showing all steps.
  setShowingAllSteps: (showAll: boolean) => void;
  // Function to call when "Back to teacher Portal" linked is clicked. Allows referrer to be set
  // for analytics purposes.
  handleBackToTeacherPortal: (referrer: string) => void;
  // Function to call to set the current training step
  setCurrentStep: (stepIndex: number) => void;
  // Function to show the training intro video (if the user wants to reshow it from step 1)
  watchIntroVideo: () => void;
  // Callback for when a training step is completed (used when the user has watched a video for a video step)
  completeTask: (stepID: TrainingStepID) => void;
}

// Banner height - needs to match --training-banner-height in base.css
const BANNER_HEIGHT = 45;

export const TrainingDropdownMenu = forwardRef(
  (
    {
      steps,
      currentModule,
      selectedStep,
      showingAllSteps,
      setShowingAllSteps,
      handleBackToTeacherPortal,
      setCurrentStep,
      watchIntroVideo,
      completeTask,
    }: ITrainingDropdownMenuProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const sendEvent = useAnalytics();
    const navigate = useNavigate();
    // Height of the dropdown, which changes when you move from one page to another with an animation
    const [height, setHeight] = useState<number>(0);
    const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);

    const [helpVideo, setHelpVideo] = useState<string>();

    const measureScreenHeight = () => {
      if (window.innerHeight && window.innerHeight !== screenHeight) {
        setScreenHeight(window.innerHeight);
      }
    };

    const allStepsRef = useRef<HTMLDivElement>(null);
    const stepDetailsRef = useRef<HTMLDivElement>(null);

    useEffect(
      () => {
        window.addEventListener('resize', measureScreenHeight);
        return () => window.removeEventListener('resize', measureScreenHeight);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    // When changing page, reset the scroll bar to top if showing
    useEffect(
      () => {
        // When the user changes page of the dropdown, work out what height to make the dropdown. The height is
        // set explicitly below, so we can do a height transition animation
        const showingPage = showingAllSteps ? allStepsRef.current : stepDetailsRef.current;
        if (showingPage && showingPage.scrollHeight !== height) {
          setHeight(showingPage.scrollHeight);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        showingAllSteps,
        screenHeight,
        height,
        allStepsRef.current,
        stepDetailsRef.current,
        // Recalculate height when steps change - e.g. if current step is completed extra content might be shown
        steps,
        // Recalculate when select step changes - might be a different height if clicking next step
        selectedStep,
      ],
    );

    useEffect(
      () => {
        const resize = () => {
          const showingPage = showingAllSteps ? allStepsRef.current : stepDetailsRef.current;
          if (showingPage && showingPage.scrollHeight !== height) {
            setTimeout(() => {
              setHeight(showingPage.scrollHeight);
            }, 10);
          }
        };

        window.addEventListener('resize-training-box', resize);
        return () => window.removeEventListener('resize-training-box', resize);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    // When changing page, reset the scroll bar to top if showing
    useEffect(() => {
      const showingPage = showingAllSteps ? allStepsRef.current : stepDetailsRef.current;
      if (showingPage) {
        showingPage.scrollTop = 0;
      }
    }, [showingAllSteps]);

    useEffect(
      () => {
        if (!showingAllSteps && selectedStep) {
          sendEvent(clickedOnTrainingStepEvent(selectedStep));
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [showingAllSteps],
    );

    if (!currentModule || !selectedStep || steps.length === 0) {
      return <h2>No training selected</h2>;
    }

    const selectStep = (stepNumber: number) => () => {
      // Step number is 1-indexed.
      setCurrentStep(stepNumber - 1);
      setShowingAllSteps(false);
    };

    const isBoxScrolling = screenHeight - BANNER_HEIGHT < height - 2;

    // Return the user to the home screen during the training
    const goToHomepage = (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.preventDefault();
      navigate(makeHomePath({}));
      sendEvent(clickedHomeScreenLinkViaIndependentLearningStepEvent());
    };

    const closeTrainingMenu = (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.preventDefault();

      const event = new Event(CLOSE_TRAINING_MENU);
      document.dispatchEvent(event);
    };

    // Handle clicking on elements within the content - e.g. links
    const handleClick = (evt: React.MouseEvent<HTMLDivElement>) => {
      if (!evt.target) {
        return;
      }
      switch ((evt.target as HTMLElement).id) {
        case HOMEPAGE_LINK:
          goToHomepage(evt);
          break;
        case CLOSE_TRAINING_MENU:
          closeTrainingMenu(evt);
          break;
      }
    };

    return (
      <>
        <div
          className={styles.TrainingModeBox}
          ref={ref}
          style={{ height: isBoxScrolling ? screenHeight - BANNER_HEIGHT - 2 : height }}
          onClick={handleClick}
        >
          <div
            className={classNames(styles.TrainingModeBoxContents, {
              [styles.AllSteps]: showingAllSteps,
            })}
          >
            <div
              className={styles.TrainingModeBoxAllSteps}
              ref={allStepsRef}
              style={{ overflowY: isBoxScrolling ? 'auto' : 'hidden' }}
              aria-hidden={!showingAllSteps}
            >
              <h2 className={styles.TrainingModeBoxTitle}>Training steps</h2>
              {steps.map(step => (
                <TaskListItem
                  key={step.id}
                  step={step}
                  selected={selectedStep.id === step.id}
                  onSelectTask={selectStep(step.taskNumber)}
                  showing={showingAllSteps}
                />
              ))}
            </div>
            <div
              className={styles.TrainingModeBoxStepDetails}
              ref={stepDetailsRef}
              style={{ overflowY: isBoxScrolling ? 'auto' : 'hidden' }}
              aria-hidden={showingAllSteps}
            >
              <button
                className={classNames(
                  styles.TrainingLinkButton,
                  styles.AllStepsButton,
                  styles.TrainingBannerFocusTarget,
                )}
                onClick={() => {
                  sendEvent(clickedViewAllStepsEvent(selectedStep));
                  setShowingAllSteps(true);
                }}
                tabIndex={showingAllSteps ? -1 : 0}
              >
                <ChevronLeft /> <u>View all steps</u>
              </button>
              {isCompletable(selectedStep) ? (
                <CompletableStepPage
                  step={selectedStep}
                  onWatchVideo={() => {
                    if (isIntro(selectedStep)) {
                      sendEvent(clickedViewIntroVideoEvent());
                      watchIntroVideo();
                      return;
                    }
                    if (isVideo(selectedStep)) {
                      sendEvent(clickedViewVideoEvent(selectedStep));
                      // video steps have a video property, normal task items have guidanceVideos instead
                      setHelpVideo(selectedStep.video);
                      return;
                    }
                    sendEvent(clickedNeedHelpEvent(selectedStep));
                    sendEvent(clickedViewVideoEvent(selectedStep));
                    setHelpVideo(selectedStep.guidanceVideo);
                  }}
                  currentModule={currentModule}
                  handleBackToTeacherPortal={handleBackToTeacherPortal}
                  setCurrentStep={setCurrentStep}
                  steps={steps}
                  showing={!showingAllSteps}
                />
              ) : (
                <SummaryStepPage
                  step={selectedStep}
                  currentModule={currentModule}
                  handleBackToTeacherPortal={handleBackToTeacherPortal}
                  steps={steps}
                  setCurrentStep={setCurrentStep}
                  showing={!showingAllSteps}
                />
              )}
            </div>
          </div>
        </div>
        {helpVideo && (
          <TrainingHelpVideo
            videoID={helpVideo}
            isVideo={isVideo(selectedStep)}
            onClose={(reachedEnd: boolean) => {
              // If this is a video watch task, complete it after closing the video
              if (isVideo(selectedStep) && reachedEnd) {
                completeTask(selectedStep.id as TrainingStepID);
              }
              setHelpVideo(undefined);
            }}
          />
        )}
      </>
    );
  },
);

TrainingDropdownMenu.displayName = 'TrainingDropdownMenu';

import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Button } from '@sparx/sparx-design/components';

import styles from './DisruptiveDialog.module.css';

type DisruptiveDialogProps = {
  isOpen: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
  showCloseButton?: boolean;
  onAction?: () => void;
  actionButtonContent?: string;
  dismissButtonContent?: string;
  dismissible?: boolean;
};

/**
 * DisruptiveDialog shows a modal.
 * It should be used for disruptive dialogs such as logged out due to inactivity.
 */
export const DisruptiveDialog = ({
  isOpen,
  title,
  description,
  onClose,
  showCloseButton,
  onAction,
  actionButtonContent,
  dismissButtonContent,
  dismissible,
}: DisruptiveDialogProps) => {
  return (
    <AlertDialog.Root
      open={isOpen}
      onOpenChange={open => {
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          className={styles.Overlay}
          onClick={dismissible ? onClose : undefined}
        />
        <AlertDialog.Content className={styles.Content}>
          {title && <AlertDialog.Title className={styles.Title}>{title}</AlertDialog.Title>}
          {description && (
            <AlertDialog.Description className={styles.Description}>
              {description}
            </AlertDialog.Description>
          )}
          <div className={styles.ButtonContainer}>
            {showCloseButton && (
              <Button variant="outlined" onClick={onClose}>
                {dismissButtonContent || 'Close'}
              </Button>
            )}
            {onAction && actionButtonContent && (
              <Button variant="contained" onClick={onAction}>
                {actionButtonContent}
              </Button>
            )}
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

import { SessionDetailsRequest, SessionDetailsResponse } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { getNowTimestamp } from '@sparx/react-utils';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import { makeLogoutRequest } from 'auth/authinterceptor';
import { useAPI } from 'context/api';
import { UAParser } from 'ua-parser-js';
import { makeClientVersion } from 'utils/analytics';
import { setTrainingModeEnabled } from 'utils/training';

export const sessionQueryKey = ['session'];

export const useSession = () => {
  const { swworkerClient } = useAPI();
  const parser = new UAParser();

  return useQuery(
    sessionQueryKey,
    () =>
      swworkerClient.getSessionDetails(
        SessionDetailsRequest.create({
          timestamp: getNowTimestamp(),
          applicationName: 'sparxweb2',
          applicationVersion: makeClientVersion(),
          browser: parser.getBrowser().name,
          browserVersion: parser.getBrowser().version,
          os: parser.getOS().name,
          osVersion: parser.getOS().version,
        }),
      ).response,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

/** String value which marks a 'student' record as a staff user */
const STAFF_USER = 'StaffUser';

/**
 * Returns true if there is a user session and it is for a StaffUser (e.g. a teacher)
 */
export const useIsStaffUser = () => {
  const session = useSession();
  if (!session.data?.student) {
    return false;
  }
  return session.data.student.studentType === STAFF_USER;
};

type DisplayMode = 'full' | 'firstOnly' | 'lastOnly';

/**
 * Get the user's name for displaying in product from the current session (by default, show first and last names)
 */
export const getUsersNamesFromSession = (
  session?: SessionDetailsResponse,
  mode: DisplayMode = 'full',
): string => {
  if (!session?.student) {
    return '';
  }

  if (session.student.studentType === STAFF_USER) {
    // Never display an actual name if this is a staff user
    return 'Teacher';
  }

  return {
    firstOnly: session.student.firstName,
    lastOnly: session.student.lastName,
    full: `${session.student.firstName} ${session.student.lastName}`,
  }[mode];
};

export const useLogout = (options?: UseMutationOptions) =>
  useMutation(makeLogoutRequest, {
    onSuccess: () => {
      setTrainingModeEnabled(false);
      location.replace('/student');
    },
    ...options,
  });

export const useUserID = () => {
  const session = useSession();
  return session.data?.student?.studentID ?? '';
};

import { Product } from '@sparx/api/apis/sparx/types/product';
import { StudentLeaderboard } from '@sparx/leaderboards';
import { NavigationFrame } from 'app/NavigationFrame';
import { useNavLinks } from 'app/NavigationFrame/use-nav-links';
import { Page } from 'components/page/Page';
import { useAPI } from 'context/api';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { Navigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';

export const LeaderboardPage = () => {
  const navItems = useNavLinks();

  const { data: session } = useSession();
  const firstName = getUsersNamesFromSession(session, 'firstOnly');
  const studentID = session?.student?.studentID || '';
  const schoolID = session?.student?.schoolID || '';
  const sendEvent = useAnalytics();
  const { leaderboardsClient, userDisplayClient } = useAPI();

  const leaderboardsFFenabled = useFeatureFlags().getBooleanFlag('sparxweb2-leaderboards', false);
  const leaderboardEnabled = leaderboardsFFenabled;
  if (!leaderboardEnabled) {
    return <Navigate to="/" />;
  }

  return (
    <Page>
      <NavigationFrame sideBarItems={navItems} bottomBarItems={navItems}>
        <StudentLeaderboard
          title="Sparx Maths Leaderboard"
          product={Product.SPARX_MATHS}
          studentID={studentID}
          schoolID={schoolID}
          firstName={firstName}
          sendAnalyticsEvent={e => sendEvent({ category: 'leaderboards', ...e })}
          leaderboardsClient={leaderboardsClient}
          userDisplayClient={userDisplayClient}
        />
      </NavigationFrame>
    </Page>
  );
};

import { TaskCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Button } from '@sparx/sparx-design/components';
import { ChevronLeft } from '@sparx/sparx-design/icons';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { Page } from 'components/page/Page';
import { Video } from 'components/video/Video';
import { useOnboardingLockState } from 'context/onboardinglock';
import { usePackage, usePackageTasks, useTaskItems } from 'queries/packages';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useFeatureFlags } from 'utils/feature-flags';
import { isPkgOrTaskComplete } from 'utils/package';
import { makeHomePath, makeTaskPath, makeVideoTaskPath, useLQDPath } from 'utils/paths';
import { isVideoTask } from 'utils/taskTypes';
import { makeOnboardingVideoUrls, VideoUrls } from 'utils/urls';
import { validateUUID } from 'utils/uuid';

import { useVideoActivity } from './useVideoActivity';
import styles from './VideoTaskView.module.css';

const videoUrlMap: Partial<Record<string, VideoUrls>> = {
  'student-onboarding-1': makeOnboardingVideoUrls('welcome'),
  'student-onboarding-2': makeOnboardingVideoUrls('tips'),
};

const videoLabelMap: Partial<Record<string, string>> = {
  'student-onboarding-1': 'About Sparx Maths',
  'student-onboarding-2': 'Sparx Maths Tips',
};

export const VideoTaskView = () => {
  const { parentPath } = useLQDPath();
  const featureFlags = useFeatureFlags();

  const { onboardingTasksCompleteCount } = useOnboardingLockState();

  // get package id and task index from query params
  const { packageID: packageIDParam, taskIndex: taskIndexParam } = useParams();
  const taskIndex = parseInt(taskIndexParam || '');
  const packageID = validateUUID(packageIDParam);

  // load data
  const { data: pkg, isLoading: packageLoading, isError: packageError } = usePackage(packageID);
  const { data: tasks, isLoading: tasksLoading, isError: tasksError } = usePackageTasks(packageID);
  const {
    data: taskItems,
    isLoading: taskItemsLoading,
    isError: taskItemsError,
  } = useTaskItems(packageID, taskIndex);

  // get video url
  const task = tasks?.find(t => t.taskIndex === taskIndex);
  const taskItem = taskItems?.find(ti => ti.taskItemIndex === 1);
  const videoID = taskItem?.labels.videoID || '';
  const videoUrls = videoUrlMap[videoID];
  const videoLabel = videoLabelMap[videoID];

  const nextTask =
    tasks && taskIndex < tasks.length
      ? tasks.slice(taskIndex, tasks.length).find(task => !isPkgOrTaskComplete(task))
      : undefined;

  // create and manage activity
  const {
    isLoading: newActivityLoading,
    isError: activityError,
    onComplete,
    isComplete,
    isViewed,
  } = useVideoActivity(packageID, taskIndex, 1);

  if (packageID === '') {
    return <Navigate to="/homework" replace />;
  }

  if (packageLoading || tasksLoading || taskItemsLoading || newActivityLoading) {
    return (
      <LoadingSpinnerWithAnalytics componentName="VideoTaskView" sendLongTimeLoadingEvent={true} />
    );
  }

  const shouldRedirect =
    !pkg ||
    !task ||
    !videoUrls ||
    (task && !isVideoTask(task)) ||
    packageError ||
    tasksError ||
    taskItemsError ||
    activityError ||
    (pkg?.packageType === 'onboarding' && taskIndex > onboardingTasksCompleteCount + 1);

  // navigate back to the package list on error or if task is complete, excluding time tables tasks
  if (shouldRedirect) {
    return <Navigate to="/homework" replace />;
  }

  const homePath = makeHomePath({ packageID: task?.packageID });

  const getNextTaskPath = (nextTask: TaskCompletion | undefined) => {
    if (!nextTask) {
      return homePath;
    }
    if (isVideoTask(nextTask)) {
      return makeVideoTaskPath(parentPath, nextTask.packageID, nextTask.taskIndex);
    }
    return makeTaskPath(parentPath, nextTask.packageID, nextTask.taskIndex);
  };

  const taskPath = getNextTaskPath(nextTask);

  // Allow onboarding videos to be skippable
  const isOnboardingPackage = pkg && pkg.packageType === 'onboarding';
  const canSkipOnboarding = featureFlags.getBooleanFlag('sparxweb2-can-skip-onboarding', false);
  const btnEnabled = isComplete || (isOnboardingPackage && canSkipOnboarding && isViewed);
  const btnClick = () => {
    if (!isComplete) {
      onComplete();
    }
  };

  return (
    <Page>
      <div className={styles.VideoTaskContainer}>
        <div className={styles.VideoContainer}>
          <div className={styles.Video}>
            <Video
              videoUrl={videoUrls.videoUrl}
              subtitleUrl={videoUrls.subtitleUrl}
              posterUrl={videoUrls.posterUrl}
              disableForwardSeeking
              onEnded={onComplete}
              videoLabel={videoLabel}
            />
          </div>
        </div>
        <div className={styles.BottomBar}>
          <Button as={Link} to={homePath} variant="outlined">
            <ChevronLeft /> Back
          </Button>
          <Button
            as={btnEnabled ? Link : undefined}
            to={btnEnabled ? taskPath : undefined}
            variant="contained"
            isDisabled={!btnEnabled}
            onClick={btnClick}
          >
            {videoID === 'student-onboarding-1' && nextTask && !isVideoTask(nextTask)
              ? 'Try some questions'
              : 'Continue'}
          </Button>
        </div>
      </div>
    </Page>
  );
};

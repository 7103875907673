import classNames from 'classnames';

import { Star, StarVariant, Tick, TickVariant } from '../../icons';
import styles from './ProgressBadge.module.css';

/**
 * A Progress Badge - currently used for topics in independent learning. It shows three levels of possible progress
 * represented by a tick followed by two stars.
 *
 * There are 8 levels of progress represented by ProgressBadgeScore
 */

export enum ProgressBadgeScore {
  NEW = 0, // Not started
  STARTED = 1, // Some progress
  ALMOST_TICKED = 2, // at least 2/3rds of objectives "ticked", i.e. 1 task complete
  TICKED = 3, // all objectives done
  ALMOST_STARRED = 4, // at least 2/3rds of objective "starred", i.e. 2 tasks complete
  STARRED = 5, // all objectives starred
  ALMOST_COMPLETE = 6, // at least 2/3rds of objectives "complete", i.e. all 3 tasks complete
  COMPLETE = 7, // all objectives complete
}

const ProgressIconVariants: {
  [K in ProgressBadgeScore]: [TickVariant, StarVariant, StarVariant];
} = {
  [ProgressBadgeScore.NEW]: ['Grey', 'Grey', 'Grey'],
  [ProgressBadgeScore.STARTED]: ['Green', 'Grey', 'Grey'],
  [ProgressBadgeScore.ALMOST_TICKED]: ['Green', 'Grey', 'Grey'],
  [ProgressBadgeScore.TICKED]: ['Green', 'Grey', 'Grey'],
  [ProgressBadgeScore.ALMOST_STARRED]: ['Green', 'GoldHalf', 'Grey'],
  [ProgressBadgeScore.STARRED]: ['Green', 'Gold', 'Grey'],
  [ProgressBadgeScore.ALMOST_COMPLETE]: ['Green', 'Gold', 'GoldHalf'],
  [ProgressBadgeScore.COMPLETE]: ['Green', 'Gold', 'Gold'],
};

export const ProgressBadge = ({
  inline,
  variant,
}: {
  inline?: boolean;
  variant?: ProgressBadgeScore;
}) => {
  const [tick, star1, star2] =
    ProgressIconVariants[variant || ProgressBadgeScore.NEW];
  return (
    <div className={inline ? styles.ContainerInline : styles.Container}>
      <Tick
        variant={tick}
        className={classNames({
          [styles.Started]: variant === ProgressBadgeScore.STARTED,
          [styles.AlmostTicked]: variant === ProgressBadgeScore.ALMOST_TICKED,
        })}
      />
      <Star variant={star1} />
      <Star variant={star2} />
    </div>
  );
};

import {
  LeaderboardDateRange,
  LeaderboardType,
} from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { UserDisplayClient } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay.client';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { Suspense, useState } from 'react';

import {
  LeaderboardContextProvider,
  LeaderboardContextType,
  useLeaderboardContext,
} from '../context/context';
import { LeaderboardDateSelector } from '../DateSelector/LeaderboardDateSelector';
import { LeaderboardGroupTypeSelector } from '../GroupTypeSelector/LeaderboardGroupTypeSelector';
import { NameChooserBar } from '../NameChooser/NameChooser';
import { useGetUserDisplayData } from '../queries/userdisplay';
import { RibbonBanner } from '../RibbonBanner/RibbonBanner';
import { isUserOptedOut } from '../utils';
import styles from './Leaderboard.module.css';
import { LeaderboardContainer } from './LeaderboardContainer';
import { StudentLeaderboardTable } from './LeaderboardTable';
import sampleLeaderboard from './SampleLeaderboard.png';

export const StudentLeaderboard = ({ title }: { title: string }) => {
  const { product } = useLeaderboardContext();
  const { data: displayData } = useGetUserDisplayData();

  const isOptedOut = isUserOptedOut(displayData || undefined, product);

  const [leaderboardType, setLeaderboardType] = useState<LeaderboardType>(LeaderboardType.CLASS);
  const [leaderboardDateRange, setLeaderboardDateRange] = useState<LeaderboardDateRange>(
    LeaderboardDateRange.THIS_WEEK,
  );

  let content = undefined;
  switch (isOptedOut) {
    case true:
      content = (
        <>
          <NameChooserBar />
          <img
            className={styles.OptedOutLeaderboardImage}
            src={sampleLeaderboard}
            alt="example leaderboard image"
          />
        </>
      );
      break;
    case false:
      content = (
        <>
          <NameChooserBar />
          <div className={styles.SelectorContainer}>
            <LeaderboardGroupTypeSelector
              leaderboardType={leaderboardType}
              setLeaderboardType={setLeaderboardType}
            />
            <LeaderboardDateSelector
              leaderboardDateRange={leaderboardDateRange}
              setLeaderboardDateRange={setLeaderboardDateRange}
            />
          </div>
          <RibbonBanner leaderboardDateRange={leaderboardDateRange} />

          <Suspense fallback={<LargeLoading />}>
            <StudentLeaderboardTable
              leaderboardType={leaderboardType}
              leaderboardDateRange={leaderboardDateRange}
            />
          </Suspense>
        </>
      );
      break;
  }

  return (
    <LeaderboardContainer>
      <h1 className={styles.Title}>{title}</h1>
      {content}
    </LeaderboardContainer>
  );
};

// for the student leaderboard, studentID and userDisplayClient are both required
type StudentLeaderboardContextType = LeaderboardContextType & {
  studentID: string;
  userDisplayClient: UserDisplayClient;
};

/**
 * WrappedStudentLeaderboard handles data fetching and rendering of the student leaderboard
 * component.
 */
export const WrappedStudentLeaderboard = (
  props: StudentLeaderboardContextType & { title: string },
) => {
  const { title, ...context } = props;
  return (
    <LeaderboardContextProvider value={context}>
      <Suspense fallback={<LargeLoading />}>
        <StudentLeaderboard title={title} />
      </Suspense>
    </LeaderboardContextProvider>
  );
};

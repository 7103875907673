import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Hint } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { Button } from '@sparx/sparx-design/components';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { Video } from 'components/video/Video';
import { useRef } from 'react';
import { makeQuestionVideoUrls } from 'utils/urls';

import styles from '../../question/Question.module.css';

export const HintVideoModal = ({
  hintVideo,
  onOpenChange,
}: {
  hintVideo?: {
    videoId: string;
    hintType: Hint;
  };
  onOpenChange: (open: boolean) => void;
}) => {
  // remember the last hintVideo, so we can show that while we animate the dialog out
  const hintVideoRef = useRef(hintVideo);
  if (hintVideo) {
    hintVideoRef.current = hintVideo;
  }
  const urls = makeQuestionVideoUrls(hintVideoRef.current?.videoId || '');
  return (
    <Dialog.Root onOpenChange={onOpenChange} open={!!hintVideo}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullWidth,
            dialogStyles.WithZIndex,
            dialogStyles.ContentHeight,
          )}
        >
          <Dialog.Title className={dialogStyles.DialogTitle}>
            Here is how to simplify a fraction
          </Dialog.Title>
          <div className={styles.VideoContainer}>
            <Video
              videoUrl={urls.videoUrl}
              posterUrl={urls.posterUrl}
              subtitleUrl={urls.subtitleUrl}
              analyticsCategory="question"
              analyticsVideoName={`hint-${hintVideoRef.current ? Hint[hintVideoRef.current.hintType] : Hint[Hint.UNSPECIFIED]}`}
            />
          </div>
          <div className={dialogStyles.DialogButtonsContainer}>
            <Button as={Dialog.Close}>Fix your answer</Button>
          </div>
          <Dialog.Close asChild>
            <button className={dialogStyles.IconButton}>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { SessionDetailsResponse } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { lastSeenWhatsNewEntryIDKey } from 'components/whats-new/hooks';
import { useAPI } from 'context/api';
import { produce } from 'immer';
import { sessionQueryKey, useSession } from 'queries/session';

export const useUpdateStudentLabels = () => {
  const { swworkerClient } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (labels: Partial<Record<studentLabelKey, string>>) =>
      swworkerClient.updateStudentLabels({ labels: labels }).response,
    onSuccess: resp => {
      queryClient.setQueryData<SessionDetailsResponse>(sessionQueryKey, sessionData => {
        if (!sessionData) {
          return undefined;
        }
        const newStudent = produce(sessionData.student, draft => {
          if (draft) {
            draft.labels = resp.labels;
          }
        });
        return {
          session: sessionData.session,
          student: newStudent,
        };
      });
    },
    retry: 3,
  });
};

type StudentLabelResponse = [string | undefined, true] | [undefined, false];

// useStudentLabel returns the value associated with the given label for the current student, and a
// boolean indicating whether the student was loaded correctly, and so the label value is correct.
export const useStudentLabel = (label: studentLabelKey): StudentLabelResponse => {
  const { data: session, isLoadingError } = useSession();
  if (isLoadingError || !session?.student) {
    return [undefined, false];
  }
  return [session?.student?.labels[label], true];
};

export type studentLabelKey = 'settings.overlay_colour' | typeof lastSeenWhatsNewEntryIDKey;

import {
  TaskCompletion,
  TaskItemCompletion,
  TaskItemStatus,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Button } from '@sparx/sparx-design/components';
import { Flag } from '@sparx/sparx-design/icons';
import { Link } from 'react-router-dom';
import { isPkgOrTaskComplete } from 'utils/package';
import { makeTaskItemPath, makeTaskPath, useLQDPath } from 'utils/paths';

import styles from './AssessmentLQDSummary.module.css';

export const AssessmentLQDSummary = ({
  task,
  tasks,
  taskItems,
  mode,
}: {
  task: TaskCompletion;
  tasks: TaskCompletion[];
  taskItems: TaskItemCompletion[];
  mode: 'fixup' | 'revision';
}) => {
  const { parentPath } = useLQDPath();
  const { completeCount, seekHelpCount } = taskItems.reduce(
    (data, taskItem) => {
      switch (taskItem.status) {
        case TaskItemStatus.DONE:
          data.completeCount += 1;
          break;
        case TaskItemStatus.SEEKHELP:
          data.seekHelpCount += 1;
          break;
      }
      return data;
    },
    {
      completeCount: 0,
      seekHelpCount: 0,
    },
  );

  const total = taskItems.length;
  const taskComplete = isPkgOrTaskComplete(task);
  const firstIncompleteTask = tasks.find(t => !isPkgOrTaskComplete(t));
  const firstIncompleteTaskItem = taskItems.find(ti => ti.status !== TaskItemStatus.DONE);

  const title = mode === 'fixup' ? 'Fluency Fix Up' : task.title;

  const forwardPath = taskComplete
    ? firstIncompleteTask &&
      makeTaskPath(parentPath, firstIncompleteTask.packageID, firstIncompleteTask.taskIndex)
    : firstIncompleteTaskItem &&
      makeTaskItemPath(
        parentPath,
        task.packageID,
        task.taskIndex,
        firstIncompleteTaskItem.taskItemIndex,
      );
  const forwardButtonText = taskComplete && firstIncompleteTask ? 'Next task' : 'Next question';

  const backPath = mode === 'fixup' ? parentPath : `${parentPath}/revision`;

  return (
    <div className={styles.Container}>
      <p className={styles.TaskTitle}>{title}</p>
      <div className={styles.Completion}>
        <p className={styles.Stat}>
          {completeCount}/{total}
        </p>
        <p className={styles.Title}>Complete</p>
      </div>
      {seekHelpCount > 0 && (
        <div className={styles.SeekHelpMessage}>
          <div className={styles.IconContainer}>
            <Flag className={styles.Icon} variant="White" />
          </div>
          <p className={styles.Title}>
            You need help with {seekHelpCount} question{seekHelpCount > 1 && 's'} in this task.
          </p>
          <p>
            Make sure you ask your teacher for help{mode === 'revision' && ' before the assessment'}
          </p>
        </div>
      )}
      {taskComplete && <p className={styles.TaskCompleteMessage}>Well done!</p>}
      <div className={styles.Buttons}>
        <Button as={Link} to={backPath} className={styles.Button}>
          Back to Assessment{mode === 'revision' && ' revision'}
        </Button>
        {forwardPath && (
          <Button as={Link} to={forwardPath} variant="contained" className={styles.Button}>
            {forwardButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

/**
 * Basic helper function to compare two arrays. If each element of the first array matches the
 * corresponding element in the second array using ===, the array is considered equal.
 */
export const arrayEquals = <T>(a: T[], b: T[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

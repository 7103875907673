// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/notifications/notifications/v1/notifications.proto" (package "sparx.notifications.notifications.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Product } from '../../../types/product';
import { PraiseData } from '../../../teacherportal/dashboarddata/v1/dashboarddata';
import { HomeworkCreated } from '../../../personalisation/hwgmsg/v1/homeworkgen';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.Notification
 */
export interface Notification {
  /**
   * The name of the notification in the format
   * `notificationowners/{notificationowner_id}/notifications/{notification_id}`
   *
   * notificationowner_id is the user id followed by a dot and then the
   * NotificationOwnerType string, e.g. `{user_id}.TEACHER`. The exception to
   * this rule is for students, where the notificationowner_id is simply
   * `{user_id}` (this is required for backwards compatibility)
   *
   * notification_id is the type of the notification and any additional data
   * required (separated by the first dash) e.g.
   * WhatsNew-2023-02-28-hand-in-summary, or
   * EarliestCompleter-1:18a1cff3-6d19-45cc-acfc-7488b20577bd
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Whether the notification has been read
   *
   * @generated from protobuf field: bool read = 2;
   */
  read: boolean;
  /**
   * The time and date that the notification was created
   *
   * @generated from protobuf field: google.protobuf.Timestamp created_time = 3;
   */
  createdTime?: Timestamp;
  /**
   * The time and date that the notification will expire
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry_time = 4;
   */
  expiryTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.CreateNotificationsRequest
 */
export interface CreateNotificationsRequest {
  /**
   * The notifications to create
   *
   * @generated from protobuf field: repeated sparx.notifications.notifications.v1.Notification notifications = 1;
   */
  notifications: Notification[];
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.CreateNotificationsResponse
 */
export interface CreateNotificationsResponse {
  /**
   * The created notifications
   *
   * @generated from protobuf field: repeated sparx.notifications.notifications.v1.Notification notifications = 1;
   */
  notifications: Notification[];
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.ListNotificationsRequest
 */
export interface ListNotificationsRequest {
  /**
   * The maximum number of notifications to return
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * The type of notifications to return
   *
   * @generated from protobuf field: sparx.notifications.notifications.v1.NotificationOwnerType owner_type = 2;
   */
  ownerType: NotificationOwnerType;
  /**
   * The school id of the user
   *
   * @generated from protobuf field: string school_id = 3;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.ListNotificationsResponse
 */
export interface ListNotificationsResponse {
  /**
   * List of notifications matching the request filters, ordered by descending
   * created time
   *
   * @generated from protobuf field: repeated sparx.notifications.notifications.v1.Notification notifications = 1;
   */
  notifications: Notification[];
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.MarkNotificationsReadRequest
 */
export interface MarkNotificationsReadRequest {
  /**
   * The names of the notifications to mark as read, in the format
   * `notificationowners/{notificationowner_id}/notifications/{notification_id}`
   *
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.MarkNotificationsReadResponse
 */
export interface MarkNotificationsReadResponse {
  /**
   * The updated notifications
   *
   * @generated from protobuf field: repeated sparx.notifications.notifications.v1.Notification notifications = 1;
   */
  notifications: Notification[];
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.NotificationUpdateMessage
 */
export interface NotificationUpdateMessage {
  /**
   * @generated from protobuf field: sparx.notifications.notifications.v1.Notification notification = 1;
   */
  notification?: Notification;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;
}
/**
 * GenerateNotificationsEvent represents an event to generate notifictions
 *
 * @generated from protobuf message sparx.notifications.notifications.v1.GenerateNotificationsEvent
 */
export interface GenerateNotificationsEvent {
  /**
   * @generated from protobuf oneof: event
   */
  event:
    | {
        oneofKind: 'homeworkDue';
        /**
         * @generated from protobuf field: sparx.personalisation.hwgmsg.HomeworkCreated homework_due = 1;
         */
        homeworkDue: HomeworkCreated;
      }
    | {
        oneofKind: 'homeworkSet1DayAgo';
        /**
         * @generated from protobuf field: sparx.personalisation.hwgmsg.HomeworkCreated homework_set_1_day_ago = 3;
         */
        homeworkSet1DayAgo: HomeworkCreated;
      }
    | {
        oneofKind: 'homeworkSet3DaysAgo';
        /**
         * @generated from protobuf field: sparx.personalisation.hwgmsg.HomeworkCreated homework_set_3_days_ago = 4;
         */
        homeworkSet3DaysAgo: HomeworkCreated;
      }
    | {
        oneofKind: 'praiseDataUpdated';
        /**
         * @generated from protobuf field: sparx.notifications.notifications.v1.PraiseDataUpdatedEvent praise_data_updated = 2;
         */
        praiseDataUpdated: PraiseDataUpdatedEvent;
      }
    | {
        oneofKind: 'keyContactSetupNudge';
        /**
         * @generated from protobuf field: sparx.notifications.notifications.v1.KeyContactSetupNudge key_contact_setup_nudge = 5;
         */
        keyContactSetupNudge: KeyContactSetupNudge;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * PraiseDataUpdatedEvent is an event which is emitted by the praise data
 * service and used to generate notifications
 *
 * @generated from protobuf message sparx.notifications.notifications.v1.PraiseDataUpdatedEvent
 */
export interface PraiseDataUpdatedEvent {
  /**
   * Whether this was the first praise data for the school
   *
   * @generated from protobuf field: bool is_first_praise_data = 1;
   */
  isFirstPraiseData: boolean;
  /**
   * The praise data which was set
   *
   * @generated from protobuf field: sparx.teacherportal.dashboarddata.v1.PraiseData praise_data = 2;
   */
  praiseData?: PraiseData;
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.KeyContactSetupNudge
 */
export interface KeyContactSetupNudge {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: repeated sparx.types.Product only_products = 2;
   */
  onlyProducts: Product[];
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataRequest
 */
export interface ListNotificationsAndDisplayDataRequest {
  /**
   * The maximum number of notifications to return
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * The type of notifications to return
   *
   * @generated from protobuf field: sparx.notifications.notifications.v1.NotificationOwnerType owner_type = 2;
   */
  ownerType: NotificationOwnerType;
  /**
   * The school id of the user
   *
   * @generated from protobuf field: string school_id = 3;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataResponse
 */
export interface ListNotificationsAndDisplayDataResponse {
  /**
   * @generated from protobuf field: repeated sparx.notifications.notifications.v1.NotificationAndDisplayData notifications_and_display_data = 1;
   */
  notificationsAndDisplayData: NotificationAndDisplayData[];
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.NotificationAndDisplayData
 */
export interface NotificationAndDisplayData {
  /**
   * @generated from protobuf field: sparx.notifications.notifications.v1.Notification notification = 1;
   */
  notification?: Notification;
  /**
   * @generated from protobuf field: sparx.notifications.notifications.v1.NotificationDisplayData display_data = 2;
   */
  displayData?: NotificationDisplayData;
}
/**
 * @generated from protobuf message sparx.notifications.notifications.v1.NotificationDisplayData
 */
export interface NotificationDisplayData {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: string message = 2;
   */
  message: string;
  /**
   * @generated from protobuf field: string image = 3;
   */
  image: string;
  /**
   * @generated from protobuf field: optional string link = 4;
   */
  link?: string;
}
/**
 * NotificationOwnerType lists the different types of user that can have a
 * notification
 *
 * @generated from protobuf enum sparx.notifications.notifications.v1.NotificationOwnerType
 */
export enum NotificationOwnerType {
  /**
   * @generated from protobuf enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: STUDENT = 1;
   */
  STUDENT = 1,
  /**
   * @generated from protobuf enum value: TEACHER = 2;
   */
  TEACHER = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType<Notification> {
  constructor() {
    super('sparx.notifications.notifications.v1.Notification', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'read', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'created_time', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'expiry_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.Notification
 */
export const Notification = new Notification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNotificationsRequest$Type extends MessageType<CreateNotificationsRequest> {
  constructor() {
    super('sparx.notifications.notifications.v1.CreateNotificationsRequest', [
      {
        no: 1,
        name: 'notifications',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Notification,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.CreateNotificationsRequest
 */
export const CreateNotificationsRequest = new CreateNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNotificationsResponse$Type extends MessageType<CreateNotificationsResponse> {
  constructor() {
    super('sparx.notifications.notifications.v1.CreateNotificationsResponse', [
      {
        no: 1,
        name: 'notifications',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Notification,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.CreateNotificationsResponse
 */
export const CreateNotificationsResponse =
  new CreateNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationsRequest$Type extends MessageType<ListNotificationsRequest> {
  constructor() {
    super('sparx.notifications.notifications.v1.ListNotificationsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'owner_type',
        kind: 'enum',
        T: () => [
          'sparx.notifications.notifications.v1.NotificationOwnerType',
          NotificationOwnerType,
        ],
      },
      { no: 3, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.ListNotificationsRequest
 */
export const ListNotificationsRequest = new ListNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationsResponse$Type extends MessageType<ListNotificationsResponse> {
  constructor() {
    super('sparx.notifications.notifications.v1.ListNotificationsResponse', [
      {
        no: 1,
        name: 'notifications',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Notification,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.ListNotificationsResponse
 */
export const ListNotificationsResponse = new ListNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkNotificationsReadRequest$Type extends MessageType<MarkNotificationsReadRequest> {
  constructor() {
    super('sparx.notifications.notifications.v1.MarkNotificationsReadRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.MarkNotificationsReadRequest
 */
export const MarkNotificationsReadRequest =
  new MarkNotificationsReadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkNotificationsReadResponse$Type extends MessageType<MarkNotificationsReadResponse> {
  constructor() {
    super(
      'sparx.notifications.notifications.v1.MarkNotificationsReadResponse',
      [
        {
          no: 1,
          name: 'notifications',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Notification,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.MarkNotificationsReadResponse
 */
export const MarkNotificationsReadResponse =
  new MarkNotificationsReadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationUpdateMessage$Type extends MessageType<NotificationUpdateMessage> {
  constructor() {
    super('sparx.notifications.notifications.v1.NotificationUpdateMessage', [
      { no: 1, name: 'notification', kind: 'message', T: () => Notification },
      { no: 2, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.NotificationUpdateMessage
 */
export const NotificationUpdateMessage = new NotificationUpdateMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateNotificationsEvent$Type extends MessageType<GenerateNotificationsEvent> {
  constructor() {
    super('sparx.notifications.notifications.v1.GenerateNotificationsEvent', [
      {
        no: 1,
        name: 'homework_due',
        kind: 'message',
        oneof: 'event',
        T: () => HomeworkCreated,
      },
      {
        no: 3,
        name: 'homework_set_1_day_ago',
        kind: 'message',
        oneof: 'event',
        T: () => HomeworkCreated,
      },
      {
        no: 4,
        name: 'homework_set_3_days_ago',
        kind: 'message',
        oneof: 'event',
        T: () => HomeworkCreated,
      },
      {
        no: 2,
        name: 'praise_data_updated',
        kind: 'message',
        oneof: 'event',
        T: () => PraiseDataUpdatedEvent,
      },
      {
        no: 5,
        name: 'key_contact_setup_nudge',
        kind: 'message',
        oneof: 'event',
        T: () => KeyContactSetupNudge,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.GenerateNotificationsEvent
 */
export const GenerateNotificationsEvent = new GenerateNotificationsEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PraiseDataUpdatedEvent$Type extends MessageType<PraiseDataUpdatedEvent> {
  constructor() {
    super('sparx.notifications.notifications.v1.PraiseDataUpdatedEvent', [
      {
        no: 1,
        name: 'is_first_praise_data',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 2, name: 'praise_data', kind: 'message', T: () => PraiseData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.PraiseDataUpdatedEvent
 */
export const PraiseDataUpdatedEvent = new PraiseDataUpdatedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KeyContactSetupNudge$Type extends MessageType<KeyContactSetupNudge> {
  constructor() {
    super('sparx.notifications.notifications.v1.KeyContactSetupNudge', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'only_products',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.KeyContactSetupNudge
 */
export const KeyContactSetupNudge = new KeyContactSetupNudge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationsAndDisplayDataRequest$Type extends MessageType<ListNotificationsAndDisplayDataRequest> {
  constructor() {
    super(
      'sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataRequest',
      [
        { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
        {
          no: 2,
          name: 'owner_type',
          kind: 'enum',
          T: () => [
            'sparx.notifications.notifications.v1.NotificationOwnerType',
            NotificationOwnerType,
          ],
        },
        {
          no: 3,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataRequest
 */
export const ListNotificationsAndDisplayDataRequest =
  new ListNotificationsAndDisplayDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationsAndDisplayDataResponse$Type extends MessageType<ListNotificationsAndDisplayDataResponse> {
  constructor() {
    super(
      'sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataResponse',
      [
        {
          no: 1,
          name: 'notifications_and_display_data',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => NotificationAndDisplayData,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataResponse
 */
export const ListNotificationsAndDisplayDataResponse =
  new ListNotificationsAndDisplayDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationAndDisplayData$Type extends MessageType<NotificationAndDisplayData> {
  constructor() {
    super('sparx.notifications.notifications.v1.NotificationAndDisplayData', [
      { no: 1, name: 'notification', kind: 'message', T: () => Notification },
      {
        no: 2,
        name: 'display_data',
        kind: 'message',
        T: () => NotificationDisplayData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.NotificationAndDisplayData
 */
export const NotificationAndDisplayData = new NotificationAndDisplayData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationDisplayData$Type extends MessageType<NotificationDisplayData> {
  constructor() {
    super('sparx.notifications.notifications.v1.NotificationDisplayData', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'image', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'link',
        kind: 'scalar',
        opt: true,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.notifications.notifications.v1.NotificationDisplayData
 */
export const NotificationDisplayData = new NotificationDisplayData$Type();
/**
 * @generated ServiceType for protobuf service sparx.notifications.notifications.v1.Notifications
 */
export const Notifications = new ServiceType(
  'sparx.notifications.notifications.v1.Notifications',
  [
    {
      name: 'CreateNotifications',
      options: {},
      I: CreateNotificationsRequest,
      O: CreateNotificationsResponse,
    },
    {
      name: 'ListNotifications',
      options: {},
      I: ListNotificationsRequest,
      O: ListNotificationsResponse,
    },
    {
      name: 'MarkNotificationsRead',
      options: {},
      I: MarkNotificationsReadRequest,
      O: MarkNotificationsReadResponse,
    },
    {
      name: 'ListNotificationsAndDisplayData',
      options: {},
      I: ListNotificationsAndDisplayDataRequest,
      O: ListNotificationsAndDisplayDataResponse,
    },
  ],
);

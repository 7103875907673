import { Button } from '@sparx/sparx-design/components';
import buttonStyles from '@sparx/sparx-design/components/button/Button.module.css';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { ChevronLeft } from '@sparx/sparx-design/icons';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { TutorialKey, useIsTutorialComplete, useMarkTutorialComplete } from 'queries/tutorials';
import { useNavigate } from 'react-router-dom';

import BottomBar from '../bottom-bar';
import styles from './FTQEntry.module.css';
import ftqIcon from './icon_fasttrack.svg';
import ftqModeImage from './image_fasttrackmode.svg';
import normalModeImage from './image_normalmode.svg';

export const FTQEntry = ({
  taskTitle,
  onFTQMode,
  onNormalMode,
}: {
  taskTitle: string;
  onFTQMode: () => void;
  onNormalMode: () => void;
}) => {
  const navigate = useNavigate();
  const welcomeComplete = useIsTutorialComplete(TutorialKey.FTQWelcome);
  const { mutate: markTutorialComplete } = useMarkTutorialComplete();
  const isMediumScreen = useBreakpoint('md');

  const markWelcomeAsSeen = () => {
    if (!welcomeComplete) {
      markTutorialComplete([TutorialKey.FTQWelcome]);
    }
  };

  return (
    <>
      <div className={styles.FTQContainer}>
        {!welcomeComplete && (
          <p className={styles.FTQWelcomeBanner}>
            Well done! As you've completed three homeworks in a row, you've unlocked Fast Track
            mode!
          </p>
        )}
        <p className={styles.FTQTitle}>
          How would you like to complete <TextWithMaths text={taskTitle} />?
        </p>
        <div className={styles.FTQCardContainer}>
          <div
            className={classNames(styles.FTQCard, styles.Blue, buttonStyles.ButtonHoverTrigger)}
            onClick={() => {
              markWelcomeAsSeen();
              onFTQMode();
            }}
          >
            <img src={ftqModeImage} />
            <p>Answer fewer questions and challenge yourself without videos.</p>
            <Button
              variant="plain"
              colour="darkblue"
              leftIcon={<img src={ftqIcon} />}
              size={isMediumScreen ? 'sm' : 'md'}
            >
              Fast Track mode
            </Button>
          </div>
          <div
            className={classNames(styles.FTQCard, buttonStyles.ButtonHoverTrigger)}
            onClick={() => {
              markWelcomeAsSeen();
              onNormalMode();
            }}
          >
            <img src={normalModeImage} />
            <p>Answer all questions with access to video support if you need it.</p>
            <Button variant="contained" size={isMediumScreen ? 'sm' : 'md'}>
              Normal mode
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.FTQBottomBar}>
        <BottomBar>
          <Button leftIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </BottomBar>
      </div>
    </>
  );
};

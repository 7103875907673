import { ReactComponent as CompulsoryIcon } from './icons/Outline/Compulsory.svg';
import { ReactComponent as IndependentLearningIcon } from './icons/Outline/Independent Learning.svg';
import { ReactComponent as PracticeIcon } from './icons/Outline/Practice.svg';
import { ReactComponent as TargetsIcon } from './icons/Outline/Target.svg';
import { ReactComponent as XPBoostIcon } from './icons/Outline/XP Boost.svg';
import { ReactComponent as CompulsoryActiveIcon } from './icons/Solid/Compulsory.svg';
import { ReactComponent as IndependentLearningActiveIcon } from './icons/Solid/Independent Learning.svg';
import { ReactComponent as PracticeActiveIcon } from './icons/Solid/Practice.svg';
import { ReactComponent as TargetsActiveIcon } from './icons/Solid/Target.svg';
import { ReactComponent as XPBoostActiveIcon } from './icons/Solid/XP Boost.svg';

export const navIcons = {
  compulsory: {
    default: <CompulsoryIcon />,
    active: <CompulsoryActiveIcon />,
  },
  xpBoost: {
    default: <XPBoostIcon />,
    active: <XPBoostActiveIcon />,
  },
  targets: {
    default: <TargetsIcon />,
    active: <TargetsActiveIcon />,
  },
  practice: {
    default: <PracticeIcon />,
    active: <PracticeActiveIcon />,
  },
  independentLearning: {
    default: <IndependentLearningIcon />,
    active: <IndependentLearningActiveIcon />,
  },
};

import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import classnames from 'classnames';
import { formatDistance } from 'date-fns';
import { useAnalytics } from 'utils/analytics';

import styles from './NotificationListItem.module.css';

interface INotificationListItemProps {
  title: string;
  message: string;
  onClick?: () => void;
  createdTime?: Timestamp;
  read: boolean;
  image?: string;
}

export const NotificationListItem = ({
  title,
  message,
  onClick,
  createdTime,
  read = true,
  image,
}: INotificationListItemProps) => {
  const analytics = useAnalytics();
  const onClickItem = () => {
    analytics({
      action: 'clicked notification',
      category: 'notifications',
      labels: {
        title,
        message,
        createdTime: createdTime?.toString() || '',
        read: read.toString(),
        interactable: !!onClick,
      },
    }),
      onClick?.();
  };

  let createdTimeString = '';
  if (createdTime) {
    const createdTimeDate = new Date(createdTime.seconds * 1000);
    createdTimeString = formatDistance(createdTimeDate, new Date(), { addSuffix: true });
    createdTimeString.trim().replace(/^\w/, m => m.toUpperCase());
  }

  return (
    <div
      onClick={onClickItem}
      className={classnames({
        [styles.NotificationListItem]: true,
        [styles.Read]: read,
        [styles.Interactive]: !!onClick,
      })}
    >
      {image && (
        <img className={styles.NotificationListItemImage} src={image} role="presentation" alt="" />
      )}
      <div className={styles.NotificationListItemTextContainer}>
        <span className={styles.Title}>{title}</span>
        <span className={styles.Message}>{message}</span>
        {createdTimeString && <span className={styles.Date}>{createdTimeString}</span>}
      </div>
    </div>
  );
};

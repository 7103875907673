import { CurriculumSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { getTopicIDFromName } from '@sparx/resource-names';
import { useIndependentLearningState } from 'context/independentlearning';
import {
  useCurriculumSummaries,
  useCurriculumSummary,
  useTopicSummariesMapForCurriculum,
} from 'queries/content';
import { useAssignedTopics, useStudentProfile } from 'queries/independentlearning';
import { useMemo } from 'react';
import { getEquivalentLearningPathSpec } from 'utils/learningPathSpec';

import { getDefaultLearningPathName, TopicLocation } from './utils';

export const KS3_CURRICULUM_NAME = 'curriculums/ba538918-2f6d-4b94-9b24-bd2e0786047e';

export const useSelectedCurriculumName = () => {
  const { selectedCurriculumName } = useIndependentLearningState();
  const { data: profile } = useStudentProfile();

  if (selectedCurriculumName) {
    return selectedCurriculumName;
  }

  // TODO: something to do with server stream? I feel like it's no longer needed?
  // https://github.com/supersparks/CloudExperiments/blob/1da040cda551bb6712d268945862c2b43685c0ab/sparxweb/swclient/phoenix2/state/revision/selectors.ts#L73

  return profile?.defaultCurriculumName || KS3_CURRICULUM_NAME;
};

export const useSetSelectedCurriculumName = () =>
  useIndependentLearningState().setSelectedCurriculumName;

export const useSelectedDefaultLearningPath = () => {
  const { selectedDefaultLearningPath } = useIndependentLearningState();
  const curriculumName = useSelectedCurriculumName();
  const curriculumSummary = useCurriculumSummary(curriculumName);
  const studentLearningPathSpec = useSelectedLearningPathSpec();

  // If the student has selected a learning path in Independent Learning, use that as their learning
  // path
  if (selectedDefaultLearningPath) {
    return selectedDefaultLearningPath;
  }

  // If the student hasn't yet selected a learning path, use their default learning path
  if (studentLearningPathSpec) {
    return studentLearningPathSpec;
  }

  if (!curriculumSummary?.curriculum) {
    return undefined;
  }

  // The selected level name might be from a previous curriculum. If so,
  // we default to level 2 for KS3 and level 3 for GCSE curricula.
  const defaultLevel = curriculumSummary.curriculum.name === KS3_CURRICULUM_NAME ? '2' : '3';
  return curriculumSummary.curriculum.learningPathSpecs.find(lp => lp.displayName === defaultLevel);
};

export const useSetSelectedDefaultLearningPath = () =>
  useIndependentLearningState().setSelectedDefaultLearningPath;

export const useSelectedLearningPathSpec = () => {
  const { data: profile } = useStudentProfile();
  const curriculumName = useSelectedCurriculumName();
  const curriculumSummary = useCurriculumSummary(curriculumName);

  if (!curriculumSummary?.curriculum) {
    return undefined;
  }

  let lpSpecName = getDefaultLearningPathName(curriculumSummary.curriculum.learningPathSpecs);
  if (profile) {
    if (profile.defaultLearningPathSpecName.startsWith(curriculumName)) {
      // If the student's default learning path is for the selected curriculum, use that.
      lpSpecName = profile.defaultLearningPathSpecName;
    } else {
      // Otherwise, convert the student's default level to a learning path within the selected curriculum.
      const equivalentLearningPathSpec = getEquivalentLearningPathSpec(
        profile.defaultLearningPathSpecName,
        curriculumName,
      );
      if (equivalentLearningPathSpec) {
        lpSpecName = equivalentLearningPathSpec;
      }
    }
  }

  const lps = curriculumSummary.curriculum.learningPathSpecs.find(lps => lps.name == lpSpecName);
  return lps;
};

export const useTopicLocationMap = (curriculumSummary: CurriculumSummary | undefined) =>
  useMemo(() => {
    const locationMap = new Map<string, TopicLocation>();
    if (curriculumSummary) {
      for (const strand of curriculumSummary.strandSummaries) {
        for (const substrand of strand.substrandSummaries) {
          if (substrand.substrand) {
            for (const [i, topicName] of substrand.substrand.topicNames.entries()) {
              locationMap.set(topicName, {
                curriculumDetails: {
                  hierarchy: curriculumSummary,
                },
                strandSummary: strand,
                substrandSummary: substrand,
                orderInSubstrand: i,
              });
            }
          }
        }
      }
    }
    return locationMap;
  }, [curriculumSummary]);

export const useAllTopicLocationMap = () => {
  const curriculums = useCurriculumSummaries();
  return useMemo(() => {
    const locationMap = new Map<string, TopicLocation>();
    if (curriculums) {
      for (const curriculumSummary of curriculums) {
        for (const strand of curriculumSummary.strandSummaries) {
          for (const substrand of strand.substrandSummaries) {
            if (substrand.substrand) {
              for (const [i, topicName] of substrand.substrand.topicNames.entries()) {
                locationMap.set(topicName, {
                  curriculumDetails: {
                    hierarchy: curriculumSummary,
                  },
                  strandSummary: strand,
                  substrandSummary: substrand,
                  orderInSubstrand: i,
                });
              }
            }
          }
        }
      }
    }
    return locationMap;
  }, [curriculums]);
};

export const useGetAssignedTopic = () => {
  const { data: assignedTopics } = useAssignedTopics();
  return useMemo(
    () => (name: string) => {
      const topicID = getTopicIDFromName(name);
      if (topicID !== undefined) {
        return assignedTopics?.get(topicID);
      }
      return undefined;
    },
    [assignedTopics],
  );
};

// Regex to extract `/curriculums/{uuid}` from a string
const curriculumNameMatch = /curriculums\/[^\s/]+/;
// Regex to extract `/curriculums/{uuid}/topics/{uuid}` from a string
const topicNameMatch = /curriculums\/[^\s/]+\/topics\/[^\s/]+/;

export const useGetCurriculumTopicLevel = (
  curriculumTopicName: string,
): { displayName?: string; level?: string; topic?: string } => {
  const curriculumName = curriculumTopicName.match(curriculumNameMatch) || '';
  const topicName = curriculumTopicName.match(topicNameMatch) || '';
  const curriculumSummary = useCurriculumSummary(curriculumName[0]);
  const topicSummaries = useTopicSummariesMapForCurriculum(curriculumName[0]);
  const topic = topicSummaries.get(topicName[0]);
  const level = topic?.learningPaths.find(lp => lp.name === curriculumTopicName);

  return {
    displayName: curriculumSummary?.curriculum?.displayName,
    level: level?.level,
    topic: topic?.topic?.name,
  };
};

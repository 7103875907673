import { CurriculumSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';

/**
 * Returns a curriculum with its display name change to be an alias for the given aliasSet if
 * it has the correct tag. For example, if a curriculum has the tag
 * "alias-title/australia:Australia (Year 7 and 8)" and the alias set is "australia" the display
 * name will be converted to "Australia (Year 7 and 8)"
 * @param curriculumSummary the curriculum summary to convert
 * @param aliasSet the alias set - e.g. "australia" or "ireland"
 */
export const getCurriculumSummaryWithDisplayNameAlias = (
  curriculumSummary: CurriculumSummary,
  aliasSet: string | undefined,
): CurriculumSummary => {
  if (!aliasSet || !curriculumSummary.curriculum) {
    return curriculumSummary;
  }
  const key = `alias-title/${aliasSet}:`;
  let alias;
  for (const tag of curriculumSummary.curriculum.tags) {
    if (tag.startsWith(key)) {
      alias = tag.substring(key.length);
      break;
    }
  }
  return alias
    ? {
        ...curriculumSummary,
        curriculum: { ...curriculumSummary.curriculum, displayName: alias },
      }
    : curriculumSummary;
};

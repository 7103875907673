import { SubmitStudentSentimentFeedbackRequest } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'context/api';
import { sessionQueryKey } from 'queries/session';

export const useSubmitFeedback = () => {
  const { swworkerClient } = useAPI();
  const queryClient = useQueryClient();
  return useMutation(
    async (req: SubmitStudentSentimentFeedbackRequest) =>
      swworkerClient.submitStudentSentimentFeedback(req).response,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sessionQueryKey);
      },
    },
  );
};

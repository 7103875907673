export const LevelBadge0 = () => {
  return (
    <svg
      width="284"
      height="249"
      viewBox="0 0 284 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M228.169 168.98C228.169 175.88 223.319 184.41 217.399 187.94L146.399 230.31C143.719 231.91 140.389 231.91 137.709 230.31L66.7094 187.94C60.7894 184.41 55.9395 175.87 55.9395 168.98V21.8398C55.9395 14.9398 61.5795 9.2998 68.4795 9.2998H215.619C222.519 9.2998 228.159 14.9398 228.159 21.8398V168.98H228.169Z"
        fill="#CBCBCB"
      />
      <path
        d="M142.059 242.21C139.319 242.21 136.579 241.48 134.129 240.02L63.1292 197.65C55.0492 192.83 48.9492 182.09 48.9492 172.67V25.54C48.9492 14.77 57.7192 6 68.4892 6H215.629C226.399 6 235.169 14.77 235.169 25.54V172.68C235.169 182.1 229.069 192.83 220.989 197.66L149.989 240.03C147.539 241.49 144.799 242.22 142.059 242.22V242.21ZM62.9492 14.23C59.8992 14.23 62.9492 22.48 62.9492 25.54V172.68C62.9492 177.08 66.5192 183.38 70.3092 185.63L141.309 228C141.779 228.28 142.359 228.28 142.819 228L213.819 185.63C217.599 183.37 221.169 177.08 221.169 172.68V25.54C221.169 22.49 223.109 14.23 220.049 14.23"
        fill="#CBD0CE"
      />
      <path
        d="M233.059 100.22C245.779 101.42 258.469 103.27 271.079 105.75C273.109 106.15 274.839 104.29 274.219 102.35C271.889 95.1203 269.359 87.9003 266.639 80.7003C266.309 79.8403 266.469 78.8903 267.069 78.1803C272.079 72.3203 277.269 66.5603 282.649 60.8903C284.099 59.3603 283.079 56.9403 280.769 56.4903C266.509 53.6803 252.159 51.6003 237.769 50.2403C236.079 50.0803 234.609 51.1603 234.479 52.6203C233.179 67.5203 231.879 82.4203 230.569 97.3203C230.439 98.7903 231.549 100.08 233.039 100.22H233.059Z"
        fill="#ADADAD"
      />
      <path
        d="M50.2799 100.23C51.7699 100.09 52.8799 98.7902 52.7499 97.3302C51.4399 82.4302 50.1399 67.5302 48.8399 52.6302C48.7099 51.1602 47.2399 50.0902 45.5499 50.2502C31.1599 51.6102 16.8199 53.6902 2.54986 56.5002C0.249856 56.9502 -0.780143 59.3702 0.669857 60.9002C6.03986 66.5702 11.2299 72.3402 16.2499 78.1902C16.8499 78.8902 17.0099 79.8502 16.6799 80.7102C13.9599 87.9002 11.4299 95.1302 9.09986 102.36C8.46986 104.3 10.1999 106.16 12.2399 105.76C24.8499 103.28 37.5399 101.43 50.2599 100.23H50.2799Z"
        fill="#ADADAD"
      />
      <path
        d="M258.52 89.0901C254.4 90.2201 247.98 89.0301 234.25 94.0401C233.77 94.2101 233.29 93.7501 233.39 93.1801C234.22 88.0601 235.05 82.9501 235.88 77.8301C244.26 79.6201 252.61 81.7101 260.91 84.0801C261.61 84.2801 259.21 88.9001 258.52 89.0901Z"
        fill="#828282"
      />
      <path
        d="M26.3807 89.5404C35.8807 90.1804 43.8907 92.0004 52.9107 95.3004C53.2507 95.4204 53.5907 95.0904 53.5307 94.6904C52.7107 89.6204 51.8807 84.5704 51.0707 79.5004C51.0207 79.2104 50.7707 79.0204 50.5007 79.0804C42.0207 80.9104 33.5907 83.0304 25.2007 85.4504C24.7107 85.5904 25.8907 89.4004 26.3907 89.5404H26.3807Z"
        fill="#828282"
      />
      <path
        d="M142.059 238.21C139.319 238.21 136.579 237.48 134.129 236.02L63.1292 193.65C55.0492 188.83 48.9492 178.09 48.9492 168.67V19.54C48.9492 8.77 57.7192 0 68.4892 0H215.629C226.399 0 235.169 8.77 235.169 19.54V168.68C235.169 178.1 229.069 188.83 220.989 193.66L149.989 236.03C147.539 237.49 144.799 238.22 142.059 238.22V238.21ZM68.4892 16C65.4392 16 62.9492 18.48 62.9492 21.54V168.68C62.9492 173.08 66.5192 179.38 70.2992 181.63L141.299 224C141.759 224.28 142.349 224.28 142.809 224L213.809 181.63C217.589 179.37 221.159 173.08 221.159 168.68V21.54C221.159 18.49 218.679 16 215.619 16H68.4892Z"
        fill="#DFE4E2"
      />
      <path
        opacity="0.2"
        d="M206.03 16.4397C206.03 16.4397 188.81 102.06 91.1504 193.92C91.1504 193.24 142.07 224.62 142.07 224.62L214.48 181.78C214.48 181.78 221.13 176.69 221.13 166.5V22.8097C221.13 22.8097 221.97 16.4297 215.08 16.4297H206.03V16.4397Z"
        fill="#A8A8A8"
      />
      <g opacity="0.6">
        <path
          d="M124.83 114.62V116.71H113.43V97.8096H115.83V114.61H124.82L124.83 114.62Z"
          fill="white"
        />
        <path
          d="M137.729 113.11C137.509 113.59 137.219 114.06 136.879 114.52C136.539 114.98 136.139 115.38 135.679 115.72C135.219 116.06 134.699 116.33 134.119 116.54C133.539 116.75 132.889 116.85 132.179 116.85C131.319 116.85 130.509 116.68 129.729 116.35C128.949 116.02 128.289 115.54 127.719 114.93C127.149 114.32 126.699 113.59 126.369 112.75C126.039 111.91 125.879 110.98 125.879 109.95C125.879 108.92 126.039 108.01 126.369 107.17C126.699 106.33 127.139 105.61 127.699 105C128.259 104.39 128.919 103.91 129.669 103.57C130.419 103.23 131.209 103.06 132.029 103.06C132.849 103.06 133.619 103.22 134.349 103.54C135.079 103.86 135.709 104.32 136.249 104.91C136.789 105.5 137.219 106.21 137.539 107.04C137.859 107.87 138.019 108.79 138.019 109.81C138.019 110.01 138.019 110.17 137.989 110.32C137.959 110.47 137.949 110.58 137.929 110.68C137.909 110.8 137.889 110.91 137.869 111.02H128.279C128.319 111.5 128.449 111.97 128.679 112.43C128.909 112.89 129.199 113.29 129.539 113.63C129.879 113.97 130.289 114.25 130.759 114.46C131.229 114.67 131.699 114.77 132.169 114.77C132.589 114.77 132.959 114.71 133.289 114.59C133.619 114.47 133.919 114.33 134.189 114.17C134.459 114.01 134.689 113.84 134.869 113.65C135.049 113.46 135.199 113.28 135.319 113.11H137.719H137.729ZM132.029 105.18C131.569 105.18 131.129 105.28 130.719 105.47C130.309 105.66 129.929 105.93 129.589 106.28C129.249 106.63 128.969 107.03 128.739 107.51C128.509 107.99 128.359 108.51 128.279 109.08H135.619C135.459 107.88 135.049 106.93 134.379 106.23C133.709 105.53 132.929 105.18 132.029 105.18Z"
          fill="white"
        />
        <path
          d="M152.119 103.21L146.709 116.72H144.619L139.209 103.21H141.609L145.669 113.57L149.709 103.21H152.109H152.119Z"
          fill="white"
        />
        <path
          d="M165.309 113.11C165.089 113.59 164.799 114.06 164.459 114.52C164.119 114.98 163.719 115.38 163.259 115.72C162.799 116.06 162.279 116.33 161.699 116.54C161.119 116.75 160.469 116.85 159.759 116.85C158.899 116.85 158.089 116.68 157.309 116.35C156.529 116.02 155.869 115.54 155.299 114.93C154.729 114.32 154.279 113.59 153.949 112.75C153.619 111.91 153.459 110.98 153.459 109.95C153.459 108.92 153.619 108.01 153.949 107.17C154.279 106.33 154.719 105.61 155.279 105C155.839 104.39 156.499 103.91 157.249 103.57C157.999 103.23 158.789 103.06 159.609 103.06C160.429 103.06 161.199 103.22 161.929 103.54C162.659 103.86 163.289 104.32 163.829 104.91C164.369 105.5 164.799 106.21 165.119 107.04C165.439 107.87 165.599 108.79 165.599 109.81C165.599 110.01 165.599 110.17 165.569 110.32C165.539 110.47 165.529 110.58 165.509 110.68C165.489 110.8 165.469 110.91 165.449 111.02H155.859C155.899 111.5 156.029 111.97 156.259 112.43C156.489 112.89 156.779 113.29 157.119 113.63C157.459 113.97 157.869 114.25 158.339 114.46C158.809 114.67 159.269 114.77 159.749 114.77C160.169 114.77 160.539 114.71 160.869 114.59C161.199 114.47 161.499 114.33 161.769 114.17C162.039 114.01 162.269 113.84 162.449 113.65C162.629 113.46 162.779 113.28 162.899 113.11H165.299H165.309ZM159.619 105.18C159.159 105.18 158.719 105.28 158.309 105.47C157.899 105.66 157.519 105.93 157.179 106.28C156.839 106.62 156.559 107.03 156.329 107.51C156.099 107.99 155.949 108.51 155.869 109.08H163.209C163.049 107.88 162.639 106.93 161.969 106.23C161.299 105.53 160.519 105.18 159.619 105.18Z"
          fill="white"
        />
        <path d="M171 116.719H168.74V97.8193H171V116.719Z" fill="white" />
      </g>
      <path
        d="M256.79 89.3902C180.66 78.8502 103.45 78.8502 27.3196 89.3902C25.5896 89.6302 23.9296 88.4402 23.6096 86.7202C20.8996 72.0202 18.1896 57.3202 15.4696 42.6302C15.1496 40.9102 16.4096 39.3002 18.2796 39.0402C100.4 27.6702 183.7 27.6702 265.83 39.0402C267.7 39.3002 268.95 40.9102 268.64 42.6302C265.93 57.3302 263.22 72.0302 260.5 86.7202C260.18 88.4402 258.52 89.6302 256.79 89.3902Z"
        fill="#DDDBD8"
      />
      <path
        opacity="0.3"
        d="M235.199 94.2398C173.289 87.2198 110.779 87.2198 48.869 94.2398C48.809 91.6998 48.759 89.1598 48.709 86.6098C110.709 79.6498 173.299 79.6398 235.309 86.5998C235.279 89.1398 235.239 91.6898 235.209 94.2298L235.199 94.2398Z"
        fill="#838383"
      />
      <path
        opacity="0.3"
        d="M18.3008 41.0598C100.421 29.6598 183.731 29.6598 265.851 41.0598"
        stroke="white"
        strokeWidth="2.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.3">
        <path
          d="M56.6795 61.0304C55.6095 60.5504 54.4695 60.1904 53.2495 59.9704C52.0295 59.7504 50.8995 59.5304 49.8595 59.3204C48.8195 59.1104 47.9395 58.8404 47.2195 58.5204C46.4995 58.2004 46.0895 57.6704 45.9795 56.9304C45.8795 56.2404 46.1395 55.5704 46.7495 54.9104C47.3595 54.2504 48.2995 53.8604 49.5495 53.7204C50.8095 53.5904 51.8195 53.8104 52.6095 54.3704C53.3895 54.9304 53.8495 55.7704 53.9995 56.8804C55.5895 56.7204 57.1895 56.5604 58.7795 56.4104C58.6395 55.2704 58.2995 54.2404 57.7595 53.3204C57.2195 52.4004 56.5195 51.6104 55.6395 50.9804C54.7595 50.3404 53.7595 49.8804 52.6295 49.5904C51.4995 49.3004 50.2695 49.2304 48.9595 49.3704C47.5295 49.5204 46.2795 49.8604 45.1995 50.3804C44.1195 50.9004 43.2295 51.5304 42.5295 52.2704C41.8295 53.0104 41.3295 53.8304 41.0295 54.7604C40.7295 55.6804 40.6495 56.6104 40.7895 57.5604C41.0095 59.0104 41.5195 60.1404 42.3095 60.9504C43.0995 61.7504 44.0395 62.3804 45.1095 62.8204C46.1795 63.2604 47.3195 63.5804 48.5295 63.7704C49.7395 63.9604 50.8595 64.1704 51.8895 64.4004C52.9195 64.6304 53.7995 64.9204 54.5095 65.2804C55.2195 65.6404 55.6295 66.2004 55.7295 66.9604C55.8295 67.7304 55.5695 68.4804 54.9395 69.1504C54.3095 69.8204 53.2695 70.2304 51.8395 70.3804C50.5095 70.5204 49.4195 70.2804 48.5495 69.6704C47.6895 69.0504 47.1795 68.1904 47.0195 67.0804C45.3795 67.2604 43.7395 67.4504 42.0995 67.6404C42.2795 68.8304 42.6595 69.8904 43.2295 70.8304C43.7995 71.7704 44.5295 72.5604 45.4195 73.1904C46.3095 73.8304 47.3495 74.2804 48.5195 74.5504C49.6995 74.8204 50.9995 74.8904 52.4295 74.7304C53.8595 74.5804 55.0795 74.2404 56.1795 73.7204C57.2795 73.1904 58.1895 72.5604 58.9095 71.8004C59.6395 71.0504 60.1695 70.2304 60.5195 69.3404C60.8595 68.4504 60.9795 67.5604 60.8695 66.6604C60.6895 65.1504 60.2095 63.9604 59.4295 63.0804C58.6495 62.2104 57.7295 61.5204 56.6695 61.0404L56.6795 61.0304Z"
          fill="#626262"
        />
        <path
          d="M78.4294 56.2198C77.5294 55.4398 76.5194 54.8698 75.3894 54.4798C74.2594 54.0998 73.0994 53.9598 71.8994 54.0498C71.0594 54.1198 70.3294 54.2798 69.6794 54.5498C69.0394 54.8198 68.4894 55.0898 68.0394 55.3698C67.5194 55.7098 67.0694 56.0798 66.6894 56.4898C66.6194 55.8998 66.5594 55.2998 66.4894 54.7098C65.0294 54.8298 63.5694 54.9598 62.1094 55.0998C62.2494 56.2598 62.3794 57.4098 62.5194 58.5698C63.3594 65.6098 64.1894 72.6598 65.0294 79.6998C66.5694 79.5598 68.1094 79.4198 69.6494 79.2898C69.3494 76.6398 69.0594 73.9898 68.7594 71.3498C69.1794 71.6598 69.6494 71.9298 70.1594 72.1498C70.6194 72.3298 71.1494 72.4798 71.7494 72.6198C72.3494 72.7598 73.0394 72.7998 73.8294 72.7298C74.9894 72.6398 76.0794 72.3198 77.0894 71.7698C78.0994 71.2298 78.9594 70.5098 79.6894 69.6098C80.4094 68.7198 80.9594 67.6898 81.3294 66.5098C81.6994 65.3398 81.8294 64.0698 81.7094 62.7198C81.5894 61.3698 81.2294 60.1398 80.6494 59.0298C80.0594 57.9198 79.3194 56.9798 78.4194 56.2098L78.4294 56.2198ZM75.9894 66.7998C75.1794 67.7698 74.1094 68.3098 72.7794 68.4198C71.4494 68.5298 70.2794 68.1698 69.2794 67.3498C68.2794 66.5198 67.6894 65.3598 67.5194 63.8498C67.3494 62.3398 67.6694 61.0998 68.4794 60.1198C69.2894 59.1398 70.3794 58.5998 71.7294 58.4898C73.0794 58.3798 74.2594 58.7398 75.2594 59.5698C76.2594 60.3998 76.8294 61.5798 76.9794 63.0898C77.1294 64.5998 76.7894 65.8398 75.9894 66.8098V66.7998Z"
          fill="#626262"
        />
        <path
          d="M96.4987 52.6302C96.5387 53.2302 96.5787 53.8202 96.6187 54.4202C96.1487 54.0702 95.6387 53.7702 95.0887 53.5002C94.5987 53.2902 94.0187 53.0902 93.3287 52.9202C92.6487 52.7502 91.8887 52.6802 91.0487 52.7302C89.8487 52.8002 88.7287 53.1002 87.6787 53.6302C86.6287 54.1602 85.7387 54.8702 84.9887 55.7602C84.2387 56.6502 83.6787 57.6802 83.2887 58.8502C82.9087 60.0202 82.7787 61.2902 82.8987 62.6402C83.0187 63.9902 83.3687 65.2202 83.9487 66.3302C84.5187 67.4402 85.2487 68.3802 86.1287 69.1702C86.9987 69.9502 87.9787 70.5402 89.0787 70.9402C90.1687 71.3402 91.2987 71.5102 92.4687 71.4402C93.2787 71.3902 93.9987 71.2502 94.6387 71.0202C95.2787 70.7802 95.8087 70.5202 96.2587 70.2302C96.7487 69.9102 97.2087 69.5602 97.6187 69.1602C97.6587 69.7602 97.6987 70.3602 97.7387 70.9502C99.1587 70.8802 100.589 70.8102 102.009 70.7402C101.639 64.6302 101.269 58.5202 100.889 52.4102C99.4287 52.4802 97.9587 52.5502 96.4987 52.6302ZM96.0087 65.4902C95.1687 66.4402 94.0787 66.9502 92.7487 67.0302C91.4187 67.1102 90.2587 66.7202 89.2887 65.8802C88.3187 65.0302 87.7587 63.8502 87.6387 62.3402C87.5187 60.8302 87.8687 59.5902 88.7087 58.6302C89.5487 57.6702 90.6487 57.1502 91.9987 57.0702C93.3587 56.9902 94.5187 57.3802 95.4987 58.2302C96.4687 59.0902 97.0087 60.2702 97.1087 61.7802C97.2087 63.2902 96.8387 64.5202 95.9987 65.4702L96.0087 65.4902Z"
          fill="#626262"
        />
        <path
          d="M113.789 51.7397C113.619 51.7197 113.429 51.7097 113.199 51.7197C112.469 51.7397 111.829 51.8797 111.269 52.1197C110.709 52.3697 110.239 52.6497 109.869 52.9497C109.409 53.2897 109.029 53.6597 108.709 54.0697C108.679 53.4097 108.639 52.7397 108.609 52.0797C108.329 52.0897 108.049 52.0997 107.779 52.1097C106.599 52.1597 105.409 52.1997 104.229 52.2597C104.299 53.4197 104.359 54.5797 104.429 55.7497C104.709 60.6997 104.979 65.6497 105.259 70.5897C106.819 70.5197 108.379 70.4597 109.929 70.3997C109.769 67.1397 109.609 63.8897 109.449 60.6297C109.389 59.4397 109.709 58.4597 110.409 57.6997C111.109 56.9397 112.039 56.5397 113.199 56.5097C113.449 56.5097 113.679 56.5097 113.889 56.5297C114.099 56.5497 114.269 56.5697 114.409 56.5897C114.579 56.6097 114.729 56.6297 114.869 56.6597C114.809 55.0697 114.739 53.4697 114.679 51.8797C114.539 51.8597 114.399 51.8297 114.259 51.8097C114.119 51.7897 113.959 51.7597 113.799 51.7397H113.789Z"
          fill="#626262"
        />
        <path
          d="M133.36 51.4902C131.62 51.5102 129.88 51.5302 128.14 51.5602C126.93 53.5102 125.72 55.4602 124.53 57.4102C123.24 55.5102 121.93 53.6102 120.62 51.7102C118.88 51.7502 117.14 51.8002 115.4 51.8502C117.54 54.7802 119.65 57.7202 121.73 60.6602C119.69 63.8402 117.69 67.0202 115.71 70.2102C117.4 70.1602 119.1 70.1102 120.79 70.0702C122.08 67.9702 123.38 65.8802 124.69 63.7902C126.11 65.8202 127.52 67.8602 128.92 69.9002C130.61 69.8702 132.31 69.8502 134 69.8402C131.86 66.7302 129.69 63.6402 127.49 60.5502C129.42 57.5202 131.37 54.5002 133.35 51.4902H133.36Z"
          fill="#626262"
        />
        <path
          d="M157.59 63.3502C154.79 57.1102 151.91 50.8902 148.94 44.6802C147.46 44.6702 145.99 44.6602 144.51 44.6602C144.48 53.0402 144.45 61.4202 144.42 69.8002C146.05 69.8002 147.67 69.8102 149.3 69.8302C149.35 65.1702 149.4 60.5202 149.45 55.8602C151.61 60.5402 153.73 65.2202 155.81 69.9102C156.89 69.9302 157.98 69.9502 159.06 69.9702C161.35 65.3602 163.68 60.7602 166.06 56.1602C165.89 60.8102 165.73 65.4702 165.56 70.1202C167.19 70.1602 168.81 70.2102 170.44 70.2702C170.8 61.9002 171.16 53.5202 171.52 45.1502C170.04 45.1002 168.57 45.0602 167.09 45.0102C163.84 51.1102 160.67 57.2202 157.58 63.3502H157.59Z"
          fill="#626262"
        />
        <path
          d="M202.069 49.1003C200.669 49.0003 199.269 48.9103 197.869 48.8203C197.749 50.2803 197.629 51.7403 197.509 53.2103C196.329 53.1403 195.149 53.0603 193.959 52.9903C193.849 54.3803 193.749 55.7703 193.639 57.1603C194.609 57.2203 195.579 57.2803 196.539 57.3403C196.329 59.9303 196.119 62.5203 195.909 65.1003C195.829 66.1103 195.909 67.0203 196.159 67.8403C196.409 68.6603 196.789 69.3703 197.289 69.9903C197.799 70.6103 198.419 71.1003 199.169 71.4603C199.919 71.8203 200.749 72.0303 201.669 72.0903C202.259 72.1303 202.799 72.1003 203.269 72.0003C203.519 71.9603 203.739 71.9103 203.939 71.8503C204.059 70.5203 204.189 69.2003 204.309 67.8703C204.189 67.9203 204.079 67.9503 203.969 67.9703C203.859 67.9903 203.729 68.0003 203.599 68.0003C203.459 68.0003 203.289 68.0003 203.069 67.9803C202.249 67.9203 201.619 67.6503 201.189 67.1503C200.759 66.6503 200.569 66.0103 200.639 65.2103C200.859 62.6903 201.089 60.1703 201.309 57.6503C202.689 57.7403 204.079 57.8403 205.459 57.9403C205.589 56.5503 205.719 55.1603 205.849 53.7603C204.459 53.6603 203.069 53.5603 201.679 53.4603C201.809 52.0003 201.939 50.5403 202.069 49.0803V49.1003Z"
          fill="#626262"
        />
        <path
          d="M223.969 57.1905C223.409 56.4605 222.709 55.8705 221.849 55.4305C220.989 54.9905 220.039 54.7305 219.019 54.6405C218.069 54.5605 217.249 54.5905 216.549 54.7405C215.849 54.8905 215.259 55.0705 214.789 55.2705C214.259 55.5205 213.799 55.8205 213.389 56.1505C213.689 53.3005 213.989 50.4605 214.299 47.6105C212.689 47.4805 211.069 47.3505 209.459 47.2305C208.629 55.5805 207.809 63.9305 206.979 72.2805C208.539 72.4005 210.089 72.5205 211.649 72.6505C212.009 69.2705 212.369 65.8905 212.729 62.5105C212.859 61.3205 213.319 60.3905 214.129 59.7205C214.939 59.0505 215.919 58.7705 217.089 58.8705C218.249 58.9705 219.149 59.4205 219.799 60.2105C220.439 61.0105 220.699 62.0005 220.559 63.1805C220.159 66.5605 219.759 69.9305 219.369 73.3105C220.929 73.4505 222.479 73.6005 224.029 73.7505C224.479 70.1805 224.919 66.6105 225.369 63.0405C225.519 61.8505 225.469 60.7605 225.209 59.7705C224.959 58.7805 224.549 57.9205 223.989 57.1905H223.969Z"
          fill="#626262"
        />
        <path
          d="M241.049 67.4603C240.679 66.7403 240.159 66.1203 239.509 65.6103C238.859 65.1103 238.129 64.7003 237.329 64.3803C236.519 64.0703 235.779 63.7703 235.089 63.4903C234.399 63.2103 233.839 62.9303 233.389 62.6403C232.939 62.3503 232.739 62.0003 232.799 61.5703C232.869 61.0703 233.129 60.6703 233.569 60.3703C234.019 60.0703 234.599 59.9603 235.319 60.0303C236.039 60.1103 236.609 60.3703 237.039 60.8203C237.459 61.2703 237.699 61.7003 237.759 62.1003C239.269 62.2703 240.789 62.4403 242.299 62.6203C242.319 61.0103 241.789 59.6103 240.719 58.4103C239.649 57.2103 237.949 56.4903 235.649 56.2403C234.589 56.1303 233.629 56.1803 232.759 56.3903C231.889 56.6103 231.109 56.9403 230.429 57.3803C229.749 57.8203 229.219 58.3603 228.809 59.0003C228.409 59.6403 228.159 60.3303 228.059 61.0703C227.919 62.1303 228.059 63.0003 228.449 63.7003C228.849 64.4003 229.369 64.9803 230.029 65.4703C230.689 65.9503 231.419 66.3403 232.219 66.6403C233.029 66.9403 233.769 67.2303 234.449 67.5003C235.129 67.7703 235.689 68.0603 236.139 68.3503C236.579 68.6403 236.779 69.0003 236.709 69.4203C236.639 69.9203 236.329 70.3503 235.799 70.7103C235.269 71.0703 234.589 71.2003 233.779 71.1103C232.959 71.0203 232.339 70.7403 231.909 70.2703C231.479 69.8003 231.249 69.2503 231.219 68.6303C229.719 68.4703 228.219 68.3203 226.719 68.1703C226.679 69.9903 227.219 71.5003 228.339 72.7103C229.459 73.9203 231.219 74.6503 233.649 74.9203C234.699 75.0303 235.679 74.9903 236.569 74.7803C237.459 74.5703 238.249 74.2503 238.909 73.8203C239.579 73.3803 240.129 72.8503 240.559 72.2203C240.979 71.5903 241.259 70.9003 241.369 70.1603C241.529 69.0803 241.429 68.1703 241.049 67.4503V67.4603Z"
          fill="#626262"
        />
        <path
          d="M188.07 52.6502C188.03 53.2502 187.99 53.8402 187.95 54.4402C187.53 54.0502 187.07 53.6902 186.56 53.3702C186.1 53.1102 185.55 52.8502 184.89 52.6102C184.24 52.3702 183.49 52.2202 182.66 52.1802C181.46 52.1202 180.31 52.3002 179.19 52.7302C178.08 53.1502 177.09 53.7602 176.23 54.5702C175.37 55.3802 174.67 56.3402 174.13 57.4702C173.59 58.5902 173.29 59.8402 173.23 61.1902C173.17 62.5502 173.35 63.8002 173.77 64.9602C174.19 66.1202 174.79 67.1302 175.55 68.0002C176.31 68.8702 177.2 69.5602 178.24 70.0702C179.27 70.5802 180.37 70.8602 181.53 70.9202C182.34 70.9602 183.08 70.8902 183.74 70.7302C184.4 70.5602 184.97 70.3602 185.45 70.1102C185.99 69.8402 186.48 69.5402 186.94 69.1902C186.9 69.7902 186.86 70.3902 186.82 70.9802C188.24 71.0502 189.66 71.1302 191.09 71.2202C191.55 65.1202 192 59.0102 192.46 52.9102C191 52.8302 189.54 52.7502 188.08 52.6702L188.07 52.6502ZM185.85 65.3702C184.89 66.2302 183.74 66.6202 182.41 66.5602C181.08 66.5002 179.98 66.0002 179.13 65.0502C178.28 64.1102 177.89 62.8802 177.97 61.3702C178.05 59.8602 178.57 58.6602 179.54 57.8002C180.5 56.9402 181.66 56.5302 183.02 56.6002C184.38 56.6602 185.48 57.1702 186.33 58.1202C187.18 59.0702 187.55 60.3002 187.45 61.8202C187.35 63.3302 186.81 64.5202 185.85 65.3702Z"
          fill="#626262"
        />
      </g>
      <path
        d="M56.4597 59.2902C55.3897 58.8102 54.2397 58.4502 53.0197 58.2302C51.7997 58.0102 50.6697 57.7902 49.6197 57.5802C48.5797 57.3702 47.6997 57.1002 46.9797 56.7802C46.2597 56.4602 45.8397 55.9302 45.7397 55.1902C45.6397 54.5002 45.8997 53.8302 46.5097 53.1702C47.1197 52.5102 48.0597 52.1202 49.3197 51.9802C50.5797 51.8502 51.5997 52.0602 52.3797 52.6302C53.1597 53.1902 53.6197 54.0302 53.7697 55.1402C55.3697 54.9802 56.9697 54.8202 58.5697 54.6702C58.4297 53.5302 58.0897 52.5002 57.5497 51.5802C57.0097 50.6602 56.2997 49.8702 55.4297 49.2402C54.5497 48.6002 53.5497 48.1402 52.4097 47.8502C51.2797 47.5602 50.0497 47.4902 48.7297 47.6302C47.2997 47.7802 46.0397 48.1202 44.9597 48.6402C43.8797 49.1602 42.9897 49.7902 42.2797 50.5302C41.5797 51.2702 41.0797 52.0902 40.7697 53.0202C40.4697 53.9402 40.3897 54.8802 40.5297 55.8202C40.7497 57.2702 41.2597 58.4002 42.0597 59.2102C42.8597 60.0202 43.7897 60.6402 44.8597 61.0802C45.9297 61.5202 47.0697 61.8402 48.2897 62.0302C49.4997 62.2202 50.6297 62.4302 51.6597 62.6502C52.6997 62.8702 53.5697 63.1702 54.2797 63.5202C54.9897 63.8802 55.3997 64.4402 55.4997 65.2002C55.5997 65.9702 55.3397 66.7202 54.7097 67.3902C54.0797 68.0602 53.0397 68.4702 51.5997 68.6202C50.2697 68.7602 49.1697 68.5202 48.3097 67.9102C47.4497 67.2902 46.9297 66.4302 46.7697 65.3202C45.1297 65.5002 43.4797 65.6902 41.8397 65.8802C42.0197 67.0702 42.3997 68.1302 42.9697 69.0702C43.5397 70.0102 44.2697 70.8002 45.1697 71.4302C46.0597 72.0702 47.0997 72.5202 48.2797 72.7902C49.4597 73.0602 50.7697 73.1202 52.1997 72.9702C53.6397 72.8202 54.8597 72.4802 55.9597 71.9602C57.0597 71.4302 57.9697 70.8002 58.6997 70.0402C59.4297 69.2902 59.9697 68.4702 60.3097 67.5802C60.6597 66.6902 60.7697 65.8002 60.6597 64.9002C60.4797 63.3902 59.9997 62.2002 59.2197 61.3202C58.4397 60.4502 57.5197 59.7702 56.4497 59.2802L56.4597 59.2902Z"
        fill="white"
      />
      <path
        d="M78.2699 54.4695C77.3699 53.6895 76.3599 53.1195 75.2199 52.7395C74.0899 52.3595 72.9199 52.2195 71.7199 52.3095C70.8799 52.3795 70.1399 52.5395 69.4999 52.8095C68.8499 53.0795 68.3099 53.3495 67.8599 53.6295C67.3399 53.9695 66.8899 54.3395 66.5099 54.7495C66.4399 54.1595 66.3799 53.5595 66.3099 52.9695C64.8499 53.0895 63.3799 53.2195 61.9199 53.3595C62.0599 54.5195 62.1899 55.6695 62.3299 56.8295C63.1699 63.8695 63.9999 70.9196 64.8399 77.9596C66.3799 77.8196 67.9299 77.6795 69.4699 77.5495C69.1699 74.8995 68.8799 72.2495 68.5799 69.6095C68.9999 69.9195 69.4699 70.1895 69.9899 70.4095C70.4499 70.5895 70.9799 70.7395 71.5799 70.8795C72.1799 71.0195 72.8799 71.0595 73.6599 70.9895C74.8299 70.8995 75.9099 70.5795 76.9299 70.0295C77.9399 69.4895 78.8099 68.7695 79.5399 67.8695C80.2599 66.9795 80.8099 65.9395 81.1899 64.7695C81.5599 63.5995 81.6899 62.3295 81.5699 60.9795C81.4499 59.6295 81.0899 58.3995 80.4999 57.2895C79.9099 56.1795 79.1699 55.2395 78.2699 54.4695ZM75.8099 65.0495C74.9999 66.0195 73.9199 66.5595 72.5899 66.6695C71.2599 66.7795 70.0799 66.4195 69.0799 65.5996C68.0799 64.7696 67.4899 63.6096 67.3199 62.0996C67.1499 60.5896 67.4699 59.3495 68.2899 58.3695C69.0999 57.3895 70.1899 56.8495 71.5499 56.7395C72.9099 56.6295 74.0899 56.9895 75.0899 57.8195C76.0899 58.6495 76.6599 59.8195 76.8099 61.3295C76.9599 62.8395 76.6199 64.0795 75.8099 65.0495Z"
        fill="white"
      />
      <path
        d="M96.3803 50.8802C96.4203 51.4802 96.4603 52.0702 96.5003 52.6702C96.0303 52.3202 95.5203 52.0202 94.9703 51.7502C94.4803 51.5402 93.8903 51.3402 93.2103 51.1702C92.5203 51.0002 91.7603 50.9302 90.9303 50.9802C89.7303 51.0502 88.6003 51.3502 87.5503 51.8802C86.5003 52.4102 85.6003 53.1202 84.8503 54.0102C84.1003 54.9002 83.5303 55.9302 83.1503 57.1002C82.7703 58.2702 82.6303 59.5402 82.7603 60.8902C82.8803 62.2402 83.2303 63.4702 83.8103 64.5802C84.3903 65.6902 85.1103 66.6302 85.9903 67.4202C86.8603 68.2002 87.8503 68.7902 88.9503 69.1902C90.0503 69.5902 91.1803 69.7602 92.3403 69.6902C93.1603 69.6402 93.8803 69.5002 94.5103 69.2702C95.1503 69.0302 95.6903 68.7702 96.1303 68.4802C96.6303 68.1602 97.0803 67.8102 97.4903 67.4102C97.5303 68.0102 97.5703 68.6102 97.6103 69.2002C99.0403 69.1302 100.46 69.0502 101.89 68.9902C101.52 62.8802 101.15 56.7702 100.77 50.6602C99.3003 50.7302 97.8403 50.8102 96.3703 50.8802H96.3803ZM95.8903 63.7402C95.0503 64.6902 93.9603 65.2002 92.6203 65.2802C91.2803 65.3602 90.1303 64.9702 89.1503 64.1302C88.1703 63.2802 87.6203 62.1002 87.5003 60.5902C87.3803 59.0802 87.7303 57.8402 88.5803 56.8802C89.4203 55.9202 90.5203 55.4002 91.8803 55.3202C93.2403 55.2402 94.4103 55.6302 95.3903 56.4802C96.3603 57.3402 96.9003 58.5202 97.0003 60.0302C97.1003 61.5402 96.7303 62.7702 95.8903 63.7302V63.7402Z"
        fill="white"
      />
      <path
        d="M113.719 49.98C113.549 49.96 113.359 49.95 113.129 49.96C112.399 49.98 111.759 50.12 111.189 50.36C110.629 50.61 110.159 50.89 109.779 51.19C109.319 51.53 108.929 51.9 108.619 52.31C108.589 51.65 108.549 50.98 108.519 50.32C108.239 50.33 107.959 50.34 107.679 50.35C106.489 50.4 105.309 50.45 104.119 50.5C104.189 51.66 104.249 52.82 104.309 53.99C104.589 58.94 104.859 63.89 105.139 68.83C106.699 68.76 108.259 68.7 109.829 68.64C109.669 65.38 109.509 62.13 109.349 58.87C109.289 57.68 109.609 56.7 110.309 55.94C111.009 55.18 111.939 54.78 113.109 54.75C113.359 54.75 113.589 54.75 113.799 54.77C114.009 54.79 114.179 54.81 114.319 54.83C114.489 54.85 114.639 54.87 114.779 54.9C114.719 53.31 114.649 51.71 114.589 50.12C114.449 50.1 114.309 50.07 114.169 50.05C114.029 50.03 113.869 50 113.709 49.98H113.719Z"
        fill="white"
      />
      <path
        d="M133.34 49.7305C131.59 49.7505 129.85 49.7705 128.1 49.8005C126.88 51.7505 125.67 53.7005 124.48 55.6505C123.18 53.7505 121.87 51.8505 120.56 49.9505C118.81 49.9905 117.07 50.0405 115.32 50.0905C117.46 53.0205 119.58 55.9605 121.67 58.9005C119.63 62.0805 117.62 65.2605 115.64 68.4505C117.34 68.4005 119.04 68.3505 120.74 68.3105C122.03 66.2105 123.34 64.1205 124.65 62.0305C126.08 64.0605 127.49 66.1005 128.89 68.1405C130.59 68.1105 132.29 68.0905 133.99 68.0805C131.85 64.9705 129.67 61.8805 127.47 58.7905C129.4 55.7605 131.36 52.7405 133.35 49.7305H133.34Z"
        fill="white"
      />
      <path
        d="M157.63 61.6002C154.82 55.3602 151.93 49.1402 148.96 42.9302C147.48 42.9202 146 42.9102 144.52 42.9102C144.49 51.2902 144.46 59.6702 144.43 68.0502C146.06 68.0502 147.69 68.0602 149.32 68.0802C149.37 63.4202 149.42 58.7702 149.47 54.1102C151.64 58.7902 153.76 63.4702 155.84 68.1602C156.93 68.1802 158.01 68.2002 159.1 68.2202C161.39 63.6102 163.74 59.0102 166.12 54.4102C165.95 59.0602 165.79 63.7202 165.62 68.3702C167.25 68.4102 168.88 68.4602 170.51 68.5202C170.87 60.1402 171.23 51.7702 171.59 43.4002C170.11 43.3502 168.63 43.3002 167.15 43.2602C163.89 49.3602 160.71 55.4702 157.62 61.6002H157.63Z"
        fill="white"
      />
      <path
        d="M202.22 47.3503C200.82 47.2503 199.41 47.1603 198.01 47.0703C197.89 48.5303 197.77 49.9903 197.65 51.4603C196.46 51.3803 195.28 51.3103 194.09 51.2403C193.98 52.6303 193.88 54.0203 193.77 55.4103C194.74 55.4703 195.71 55.5303 196.68 55.5903C196.47 58.1803 196.26 60.7703 196.05 63.3503C195.97 64.3603 196.05 65.2703 196.3 66.0903C196.55 66.9103 196.93 67.6203 197.44 68.2403C197.95 68.8603 198.58 69.3503 199.32 69.7103C200.07 70.0703 200.9 70.2803 201.83 70.3403C202.43 70.3803 202.96 70.3503 203.44 70.2503C203.69 70.2103 203.91 70.1603 204.11 70.1003C204.23 68.7703 204.36 67.4503 204.48 66.1203C204.36 66.1703 204.25 66.2003 204.14 66.2203C204.03 66.2403 203.9 66.2503 203.77 66.2503C203.63 66.2503 203.46 66.2503 203.24 66.2303C202.42 66.1703 201.79 65.9003 201.35 65.4003C200.92 64.9003 200.73 64.2603 200.8 63.4603C201.02 60.9403 201.25 58.4203 201.47 55.9003C202.86 55.9903 204.24 56.0903 205.63 56.1903C205.76 54.8003 205.89 53.4103 206.02 52.0103C204.62 51.9103 203.23 51.8103 201.83 51.7103C201.96 50.2503 202.09 48.7903 202.22 47.3303V47.3503Z"
        fill="white"
      />
      <path
        d="M224.179 55.4505C223.619 54.7205 222.909 54.1305 222.049 53.6805C221.189 53.2405 220.239 52.9805 219.209 52.8905C218.259 52.8005 217.439 52.8405 216.729 52.9905C216.019 53.1405 215.439 53.3205 214.959 53.5205C214.429 53.7705 213.959 54.0705 213.559 54.4005C213.859 51.5505 214.159 48.7105 214.469 45.8605C212.849 45.7305 211.229 45.6005 209.609 45.4805C208.779 53.8305 207.959 62.1805 207.129 70.5305C208.689 70.6505 210.249 70.7705 211.809 70.9005C212.169 67.5205 212.529 64.1405 212.889 60.7605C213.019 59.5705 213.479 58.6405 214.299 57.9705C215.109 57.3005 216.099 57.0205 217.259 57.1205C218.429 57.2205 219.329 57.6705 219.979 58.4605C220.629 59.2505 220.879 60.2505 220.739 61.4405C220.339 64.8205 219.939 68.1905 219.549 71.5705C221.109 71.7105 222.669 71.8605 224.229 72.0105C224.679 68.4405 225.119 64.8705 225.569 61.3005C225.719 60.1105 225.669 59.0205 225.409 58.0305C225.159 57.0405 224.749 56.1805 224.189 55.4505H224.179Z"
        fill="white"
      />
      <path
        d="M241.31 65.7203C240.93 65.0003 240.42 64.3803 239.77 63.8703C239.12 63.3703 238.39 62.9603 237.58 62.6403C236.77 62.3203 236.02 62.0303 235.34 61.7503C234.65 61.4703 234.08 61.1903 233.63 60.9003C233.18 60.6103 232.98 60.2603 233.04 59.8303C233.11 59.3303 233.37 58.9303 233.82 58.6303C234.27 58.3303 234.85 58.2203 235.57 58.3003C236.29 58.3803 236.86 58.6403 237.29 59.0903C237.71 59.5403 237.95 59.9703 238.01 60.3703C239.53 60.5403 241.04 60.7103 242.56 60.8903C242.58 59.2803 242.05 57.8803 240.97 56.6803C239.89 55.4803 238.19 54.7603 235.88 54.5103C234.82 54.4003 233.85 54.4403 232.98 54.6603C232.1 54.8803 231.33 55.2103 230.65 55.6503C229.97 56.0903 229.43 56.6303 229.02 57.2703C228.62 57.9103 228.37 58.6003 228.27 59.3403C228.13 60.4003 228.27 61.2703 228.66 61.9703C229.06 62.6703 229.58 63.2503 230.24 63.7403C230.9 64.2203 231.63 64.6103 232.44 64.9103C233.25 65.2103 233.99 65.5003 234.68 65.7703C235.36 66.0403 235.93 66.3303 236.37 66.6203C236.81 66.9103 237.01 67.2703 236.95 67.6903C236.88 68.1903 236.57 68.6203 236.04 68.9803C235.51 69.3403 234.83 69.4703 234.01 69.3803C233.19 69.2903 232.57 69.0103 232.14 68.5403C231.7 68.0703 231.48 67.5203 231.45 66.9003C229.95 66.7403 228.45 66.5903 226.94 66.4403C226.9 68.2603 227.45 69.7703 228.56 70.9803C229.68 72.1903 231.45 72.9203 233.89 73.1903C234.95 73.3003 235.92 73.2603 236.82 73.0503C237.72 72.8403 238.5 72.5203 239.17 72.0903C239.84 71.6603 240.39 71.1203 240.82 70.4903C241.25 69.8603 241.52 69.1703 241.63 68.4303C241.79 67.3503 241.69 66.4403 241.31 65.7203Z"
        fill="white"
      />
      <path
        d="M188.19 50.9099C188.15 51.5099 188.11 52.0999 188.07 52.6999C187.65 52.3099 187.19 51.9499 186.67 51.6299C186.21 51.3699 185.66 51.1099 185 50.8699C184.34 50.6299 183.6 50.4799 182.76 50.4399C181.56 50.3799 180.4 50.5599 179.29 50.9899C178.18 51.4099 177.19 52.0199 176.32 52.8299C175.46 53.6399 174.76 54.5999 174.22 55.7299C173.68 56.8499 173.38 58.0999 173.32 59.4499C173.26 60.8099 173.44 62.0599 173.86 63.2199C174.28 64.3799 174.88 65.3899 175.64 66.2599C176.4 67.1299 177.3 67.8199 178.33 68.3299C179.37 68.8399 180.46 69.1299 181.63 69.1799C182.45 69.2199 183.18 69.1499 183.85 68.9899C184.52 68.8199 185.09 68.6199 185.57 68.3699C186.11 68.0999 186.6 67.7999 187.06 67.4499C187.02 68.0499 186.98 68.6499 186.94 69.2399C188.37 69.3199 189.79 69.3899 191.22 69.4799C191.68 63.3799 192.13 57.2699 192.59 51.1699C191.12 51.0899 189.66 51.0099 188.19 50.9299V50.9099ZM185.97 63.6199C185.01 64.4799 183.86 64.8699 182.52 64.8099C181.18 64.7499 180.09 64.2499 179.23 63.2999C178.38 62.3599 177.98 61.1299 178.07 59.6199C178.15 58.1099 178.67 56.9099 179.64 56.0499C180.6 55.1899 181.77 54.7899 183.13 54.8499C184.49 54.9099 185.6 55.4199 186.45 56.3699C187.3 57.3199 187.67 58.5499 187.57 60.0699C187.47 61.5799 186.93 62.7699 185.97 63.6199Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M232.34 10.7998C228 5.30977 221.36 1.94977 214.4 2.36977H211.35L186.92 2.43977C154 2.61977 103.67 2.46977 70.8901 2.30977C69.3801 2.30977 66.5501 2.19977 65.0101 2.64977C56.5801 4.36977 51.3201 10.3498 49.0801 18.3498C49.5601 9.62977 55.9901 2.42977 64.5901 0.689773C66.3001 0.219773 69.0401 0.249773 70.8601 0.259773C103.58 0.0997731 154.06 -0.0502318 186.92 0.129768C191.24 0.199768 213.54 0.0897698 217.55 0.27977C223.87 0.91977 229.85 4.97977 232.66 10.5898C232.77 10.7798 232.47 10.9598 232.35 10.7798L232.34 10.7998Z"
        fill="#DFE3E1"
      />
      <path
        opacity="0.48"
        d="M68.9497 180.84L142.55 225.09H141.51C141.87 225.15 214.51 181.28 214.86 181.93C214.93 182.05 214.89 182.2 214.78 182.28L142.55 226.8C142.22 227 141.82 226.99 141.51 226.8C141.72 226.44 67.3397 181.17 68.9597 180.84H68.9497Z"
        fill="#DFE3E1"
      />
      <text x="50.5%" y="73%" textAnchor="middle" fill="white" fontSize="5rem" fontWeight="500">
        0
      </text>
    </svg>
  );
};

import {
  analyticsProviderConfig,
  DomainCheck,
  LibAnalyticsProvider,
  pumpConfigs,
} from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { useIsStaffUser, useSession } from 'queries/session';
import { PropsWithChildren } from 'react';
import { makeClientVersion } from 'utils/analytics';
import { getCookiePreferences } from 'utils/cookies';
import { isTrainingModeEnabled } from 'utils/training';

/** AnalyticsProvider wraps the @sparx/Analytics Provider and provides it with configuration. */
export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const { data: session, isLoading } = useSession();
  const isStaffUser = useIsStaffUser();

  // Determine if it is a teacher doing training, to set the application differently. (Note unfortunately, we can't use
  // analytics provide because this provider is higher up in the stack of providers than the TrainingProvider, as that
  // provider sends analytics events)
  const trainingEnabledInLocalStorage = isTrainingModeEnabled();
  const trainingSearchParameterPresent = window.location.search.includes('training=true');
  const trainingEnabled =
    (trainingEnabledInLocalStorage || trainingSearchParameterPresent) && isStaffUser;

  const cookiePreferences = getCookiePreferences();

  const pumps: pumpConfigs = {
    interactionGateway: {
      URL: window.__sparxweb.urls.interactionGateway,
    },
  };

  if (cookiePreferences?.GA) {
    pumps.ga = {
      gaPropertyID: window.__sparxweb.GAProperty,
    };
  }

  const config: analyticsProviderConfig = {
    application: trainingEnabled ? 'sparxweb2_training' : 'sparxweb2',
    schoolID: window.__sparxweb.schoolID,
    userID: session?.student?.studentID || '',
    sessionID: session?.session?.sessionID || '',
    version: makeClientVersion(),
    pumps: pumps,
    debugLogs: import.meta.env.DEV,
  };

  return !isLoading ? (
    <LibAnalyticsProvider config={config}>
      <DomainCheck
        schoolId={`schools/${window.__sparxweb.schoolID}`}
        product={Product.SPARX_MATHS}
        userType="student"
      />
      {children}
    </LibAnalyticsProvider>
  ) : (
    // wait for the session to load - we cant do much without it anyway
    // todo: make this look better
    <LargeLoading />
  );
};

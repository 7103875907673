import { useNavigationContext } from 'context/navigation/hook';
import { useLocation } from 'react-router-dom';

import { getAssessmentBack } from './assessmentNavigation';
import { getIndependentLearningBack } from './indepdendentLearningNavigation';
import { getLQDPath, getTimesTablesPath, getVideoTaskPath, makeHomePath } from './paths';

/**
 * PathMatcher is a function that takes the current path and returns the path to go back to. If the matcher doesn't
 * support the current path, returns undefined
 */
type PathMatcher = (currentPath: string) => string | undefined;

/**
 * A set of functions that given the current path will return what the back path should be. The back button will use the
 * first matcher that returns a path.
 */
const matchers: PathMatcher[] = [
  // Independent learning paths:
  (currentPath: string) => getIndependentLearningBack(currentPath),
  (currentPath: string) => getAssessmentBack(currentPath),
  // LQD paths:
  (currentPath: string) => {
    const lqdPath = getLQDPath(currentPath);
    if (!lqdPath.isLQDPath) {
      return undefined;
    }
    return makeHomePath({ packageID: lqdPath.packageID });
  },
  // Video task paths:
  (currentPath: string) => {
    const videoTaskPath = getVideoTaskPath(currentPath);
    if (!videoTaskPath.isVideoTaskPath) {
      return undefined;
    }
    return makeHomePath({ packageID: videoTaskPath.packageID });
  },
  // Time tables paths:
  (currentPath: string) => {
    const timesTablesPath = getTimesTablesPath(currentPath);
    if (!timesTablesPath.isTimesTablesPath) {
      return undefined;
    }
    if (timesTablesPath.isIndependentLearningNoPackage) {
      return '/independentlearning';
    }
    return makeHomePath({ packageID: timesTablesPath.packageID });
  },
];

/**
 * Gets the path for the back button. Returns undefined if we can't go back any further.
 */
export const useBackPath = (): string | undefined => {
  const path = useLocation().pathname;

  const { backPath } = useNavigationContext();
  if (backPath) {
    return backPath;
  }

  for (const matcher of matchers) {
    const backPath = matcher(path);
    if (backPath) {
      return backPath;
    }
  }
  return undefined;
};

import { LearningPathSpec } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { createContext, ReactNode, useState } from 'react';

export interface IndependentLearningState {
  selectedCurriculumName: string | null;
  setSelectedCurriculumName: (selectedCurriculumName: string) => void;
  selectedDefaultLearningPath: LearningPathSpec | null;
  setSelectedDefaultLearningPath: (selectedDefaultLearningPath: LearningPathSpec) => void;
}

export const IndependentLearningContext = createContext<IndependentLearningState>({
  selectedCurriculumName: null,
  setSelectedCurriculumName: () => {
    return;
  },
  selectedDefaultLearningPath: null,
  setSelectedDefaultLearningPath: () => {
    return;
  },
});

export const IndependentLearningProvider = ({ children }: { children: ReactNode }) => {
  const [selectedCurriculumName, setSelectedCurriculumName] = useState<string | null>(null);
  const [selectedDefaultLearningPath, setSelectedDefaultLearningPath] =
    useState<LearningPathSpec | null>(null);

  return (
    <IndependentLearningContext.Provider
      value={{
        selectedCurriculumName,
        setSelectedCurriculumName,
        selectedDefaultLearningPath,
        setSelectedDefaultLearningPath,
      }}
    >
      {children}
    </IndependentLearningContext.Provider>
  );
};

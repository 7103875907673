import { TaskCompletion as Task } from '@sparx/api/apis/sparx/packages/v1/spxpkg';

// Returns whether the FTQ quiz has been completed given the task state. Skipped quizzes
// are also considered complete meaning this function will return true for skipped quizzes.
export const isFTQQuizComplete = (task: Task) =>
  // FTQt (total number of questions) will only be set if the quiz has been completed.
  (task?.completion?.progress['FTQt'] || 0) > 0;

// Returns whether the FTQ has been skipped. It has been skipped if it has been
// completed (submitted) but the number of correct and wrong answers is 0.
export const isFTQQuizSkipped = (task: Task) =>
  isFTQQuizComplete(task) &&
  (task?.completion?.progress['FTQc'] || 0) + (task?.completion?.progress['FTQw'] || 0) === 0;

import { useContext } from 'react';

import { ServerStreamingContext } from './provider';

export const useServerStreaming = () => {
  const ctx = useContext(ServerStreamingContext);
  if (!ctx) {
    throw new Error('useServerStreaming can only be used inside a StreamingProvider');
  }
  return ctx;
};

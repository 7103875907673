import { renderMixedTextToString } from '@sparx/text-with-maths';
import { marked } from 'marked';

const renderKatexInMarkdown = (text: string): string => {
  const replace = (replacement: RegExp): void => {
    const blockExprArray = text.match(replacement);
    for (const expr of blockExprArray || []) {
      // Regex replaces multiple leading and trailing $ symbols with a single one
      // For example $$...$$ becomes $...$ and $...$ becomes $...$.
      const result = renderMixedTextToString(expr.replace(/(^\$+|\$+$)/g, '$'));
      text = text.replace(expr, result);
    }
  };
  replace(/\$\$[^$]*\$\$/g); // Matches $$...$$
  replace(/\$[^$]*\$/g); // Matches $...$
  return text.replace(/\\n/g, '\n');
};

// Render the katex and markdown in the given text, returning a string of the resulting HTML
export const renderMixedMarkdownTextToString = (
  text: string,
  inline?: boolean,
  markedOptions?: marked.MarkedOptions
): string =>
  (inline ? marked.parseInline : marked)(renderKatexInMarkdown(text), {
    gfm: true,
    breaks: true,
    ...markedOptions
  });

// This function is a copy of renderMathInElement from `spx-katex-wrapper`
// except it also supports markdown rendering.
export const renderMarkdownAndMathInElement = (
  elem: Node,
  withMarkdown?: boolean
): void => {
  const ignoredTags = [
    'script',
    'noscript',
    'style',
    'textarea',
    'pre',
    'code'
  ];
  for (let i = 0; i < elem.childNodes.length; i++) {
    const childNode = elem.childNodes[i];
    if (childNode.nodeType === 3) {
      // Text node
      const text = childNode.textContent || '';
      let math = '';
      if (withMarkdown) {
        math = renderMixedMarkdownTextToString(text, true);
      } else {
        math = renderMixedTextToString(text);
      }
      // Make a temporary span to render the content
      const s = document.createElement('span');
      s.innerHTML = math;
      // Copy the spans children to make a document fragment
      const frag = document.createDocumentFragment();
      while (s.childNodes.length) {
        frag.appendChild(s.childNodes[0]);
      }
      // replace the text node with the document fragment
      i += frag.childNodes.length - 1;
      elem.replaceChild(frag, childNode);
    } else if (childNode.nodeType === 1) {
      // Element node
      const shouldRender =
        ignoredTags.indexOf(childNode.nodeName.toLowerCase()) === -1;

      if (shouldRender) {
        renderMarkdownAndMathInElement(childNode, withMarkdown);
      }
    }
    // Otherwise, it's something else, and ignore it.
  }
};

import { StrandView } from 'views/independent-learning/strand/StrandView';
import { IndependentLearningTopicView } from 'views/independent-learning/topic/IndependentLearningTopicView';

import { IndependentLearningView } from './IndependentLearningView';

export const strandRoute = 'curriculums/:curriculumID/strands/:strandID';
export const topicRoute =
  'curriculums/:curriculumID/strands/:strandID/substrands/:substrandID/topics/:topicID/levels?/:level?';
export const ilLQDRoute = `${topicRoute}/package/:packageID/task/:taskIndex/*`;

export { IndependentLearningTopicView, IndependentLearningView, StrandView };

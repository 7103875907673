import { useContext } from 'react';

import { LandscapeModalContext } from './provider';

export const useLandscapeModal = () => {
  const ctx = useContext(LandscapeModalContext);
  if (!ctx) {
    throw new Error('useLandscapeModal must be used within a LandscapeModalProvider');
  }
  return ctx;
};

import {
  ActivityAction,
  ActivityType,
  VideoQuestionAction_ActionType,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { getNowTimestamp } from '@sparx/react-utils';
import { useActivityAction, useNewActivity } from 'queries/activity';
import { useCallback, useEffect, useMemo, useRef } from 'react';

const createAction = (
  action: VideoQuestionAction_ActionType,
  activityIndex: number,
  questionIndex: number,
) =>
  ActivityAction.create({
    activityIndex: activityIndex,
    timestamp: getNowTimestamp(),
    action: {
      oneofKind: 'videoQuestion',
      videoQuestion: {
        actionType: action,
        questionIndex: questionIndex,
      },
    },
  });

export const useVideoActivity = (packageID: string, taskIndex: number, taskItemIndex: number) => {
  // create activity mutations
  const {
    mutate: createActivity,
    data: activity,
    isLoading,
    isError,
  } = useNewActivity(ActivityType.VIDEO, packageID, taskIndex, taskItemIndex);
  const { mutate: viewActivity, isSuccess: isViewed } = useActivityAction();
  const { mutate: dismissActivity } = useActivityAction();
  const {
    mutate: completeActivity,
    isLoading: isCompleting,
    isSuccess: isComplete,
  } = useActivityAction();

  const isCompleteRef = useRef(false);
  isCompleteRef.current = isCompleting || isComplete;

  const videoActivity = useMemo(() => {
    if (!activity) {
      return undefined;
    }
    if (activity.payload.oneofKind !== 'video') {
      return undefined;
    }
    return activity.payload.video;
  }, [activity]);

  // create activity on mount, dismiss on unmount
  useEffect(() => {
    if (!activity) {
      createActivity();
    }
    return () => {
      if (activity && videoActivity && !isCompleteRef.current) {
        dismissActivity(
          createAction(
            VideoQuestionAction_ActionType.DISMISS,
            activity.activityIndex,
            videoActivity.questionIndex,
          ),
        );
      }
    };
  }, [activity, videoActivity, createActivity, dismissActivity]);

  // view activity as soon as its created
  useEffect(() => {
    if (activity && videoActivity) {
      viewActivity(
        createAction(
          VideoQuestionAction_ActionType.VIEW,
          activity.activityIndex,
          videoActivity.questionIndex,
        ),
      );
    }
  }, [activity, videoActivity, viewActivity]);

  // create on complete handler
  const onComplete = useCallback(() => {
    if (activity && videoActivity) {
      // Set the complete ref to true as we are going to complete the video activity.
      // This avoids submitting an Abort immediately after on component unmount
      isCompleteRef.current = true;
      completeActivity(
        createAction(
          VideoQuestionAction_ActionType.COMPLETE,
          activity.activityIndex,
          videoActivity.questionIndex,
        ),
      );
    }
  }, [activity, videoActivity, completeActivity]);

  return {
    isLoading,
    isError,
    onComplete,
    isComplete,
    isViewed,
  };
};

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { dispatchInteractionEvents } from '@sparx/analytics/src/pumps';
import { loadProductUrls, ProductWithUrl } from '@sparx/api/utils/products';
import { getNowTimestamp } from '@sparx/react-utils';
import { productIcons } from '@sparx/sparx-design/icons/ProductIcons';
import { useQuery } from '@tanstack/react-query';
import styles from 'app/Menu.module.css';
import classNames from 'classnames';
import { DisruptiveDialog } from 'components/disruptive-dialog';
import { useAPI } from 'context/api';
import { motion } from 'framer-motion';
import { useSession } from 'queries/session';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeClientVersion } from 'utils/analytics';
import { v4 as uuid } from 'uuid';

// List of paths that should not prompt the user whether they want to switch app
const noPromptPaths = ['', '/homework', '/independentlearning', '/resourcehub', '/rewards'];

export const ProductSwitch = () => {
  const api = useAPI();
  const { data, isFetchedAfterMount } = useQuery({
    queryKey: ['school', 'products'],
    queryFn: async () =>
      loadProductUrls({
        // For Maths the selectSchoolURL is the same as the current URL
        indexweaverApiURL: window.location.origin,
        schoolID: window.__sparxweb.schoolID,
        authorizationToken: await api.getAccessToken(),
        product: 'SPARX_MATHS',
      }),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  // Custom behaviour to send a page event.
  //
  // This is here to ensure that we send an event immediately using fetch and with the
  // keepalive parameter set to true. The keepalive parameter ensures that the event is sent
  // even if the page navigates away before the event is sent.
  //
  // The fields in the metrics are copied from AnalyticsProvider.
  //
  // Note that the connectionId will not match the other events sent by the AnalyticsProvider,
  // but the field is not accessible.
  //
  // This approach is unfortunate but I could not see a simpler approach without a larger
  // refactor of the AnalyticsProvider.
  const { data: session } = useSession();
  const sendImmediatePageEvent = (
    action: 'switched product' | 'prompt switch',
    product: string,
    labels?: Record<string, string>,
  ) => {
    const now = getNowTimestamp();
    dispatchInteractionEvents(
      window.__sparxweb.urls.interactionGateway,
      {
        timestamp: now,
        metrics: [
          {
            application: 'sparxweb2',
            page: location.pathname,
            action,
            category: 'product switch',
            eventIndex: 0,
            connectionId: uuid(),
            schoolId: window.__sparxweb.schoolID,
            userId: session?.student?.studentID || '',
            sessionId: session?.session?.sessionID || '',
            version: makeClientVersion(),
            timestamp: now,
            serverTimestamp: undefined,
            labels: { product, ...labels },
          },
        ],
      },
      {
        keepalive: true, // ensure send as page navigate away
      },
    ).catch(e => {
      console.error('Failed to send page event', e);
    });
  };

  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<ProductWithUrl | undefined>();
  const clickProduct = (product: ProductWithUrl) => {
    // Ignore pathname starting in /student/
    const pathname = location.pathname.replace(/^\/student\//, '/').replace(/\/$/, '');
    if (noPromptPaths.includes(pathname)) {
      // Redirect instantly
      sendImmediatePageEvent('switched product', product.key, { confirmed: 'no' });
      window.location.href = product.url;
    } else {
      // Open modal to prompt the user before redirecting
      setSelectedProduct(product);
      setShowModal(true);
      sendImmediatePageEvent('prompt switch', product.key);
    }
  };
  const gotoSelectedProduct = async () => {
    if (selectedProduct) {
      sendImmediatePageEvent('switched product', selectedProduct.key, { confirmed: 'yes' });
      window.location.href = selectedProduct.url;
    }
  };

  if (!data || data.length === 0) {
    return <></>;
  }

  const alertDialog = (
    <DisruptiveDialog
      isOpen={showModal}
      title={`Switch to ${selectedProduct?.name || 'another app'}`}
      description={`Are you sure you want to switch to ${selectedProduct?.name || 'another app'}?`}
      onClose={() => setShowModal(false)}
      onAction={gotoSelectedProduct}
      actionButtonContent="Switch"
      dismissButtonContent="Cancel"
      showCloseButton={true}
      dismissible={true}
    />
  );

  const children = (
    <>
      {alertDialog}
      <DropdownMenu.Separator className={styles.DropdownMenuSeparator} />
      <DropdownMenu.Group>
        <DropdownMenu.Label className={styles.DropdownMenuLabel}>Switch to:</DropdownMenu.Label>
        {data.map(product => (
          <DropdownMenu.Item
            className={classNames(styles.DropdownMenuItem, styles.ProductMenuItem)}
            key={product.key}
            onClick={e => {
              e.preventDefault();
              clickProduct(product);
            }}
          >
            <img
              alt=""
              width={20}
              src={productIcons[product.key]}
              className={styles.DropdownMenuItemIcon}
            />
            {product.name}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Group>
    </>
  );

  if (isFetchedAfterMount) {
    // Only animate in if loaded products after mounting list
    return (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto' }}
        style={{ overflow: 'hidden' }}
      >
        {children}
      </motion.div>
    );
  }
  return children;
};

import {
  PackageCompletion as Package,
  TaskCompletion as Task,
  TaskCompletion,
  TaskItemCompletion,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { isAfter } from 'date-fns';

import { getEquivalentHomeworkPackageType } from './training';

export const sortPackages = (pkgA: Package, pkgB: Package): number => {
  if (!pkgA.endDate || !pkgB.endDate) {
    return 0;
  } else {
    return pkgB.endDate.seconds - pkgA.endDate.seconds;
  }
};

export const getPackageTypeDescription = (packageType: string) => {
  switch (getEquivalentHomeworkPackageType(packageType) || packageType) {
    case 'homework':
      return 'This is your personalised Compulsory homework. You need to answer every question correctly to complete it.';
    case 'optional-homework':
      return 'This is your personalised XP Boost homework. Collect lots of XP and learn more with every question you answer.';
    case 'targets':
      return 'This is your personalised Target homework. Stretch yourself with questions chosen to challenge you.';
  }
  return '';
};

/**
 * getPkgOrTaskCompletionPercentage uses the completion object on a package/task to work out what percentage it is complete.
 * @param task
 * @returns an integer between 0 and 100 (rounds down so that 99.5% becomes 99%)
 */
export const getPkgOrTaskCompletionPercentage = (pkgOrTask: Package | Task): number => {
  const completion = pkgOrTask.completion;
  if (!completion) {
    return 0;
  }

  const progress = completion.progress;
  if (!progress) {
    return 0;
  }

  const complete = progress['C'];
  if (!complete) {
    return 0;
  }

  const size = completion.size;
  if (!size) {
    return 0;
  }

  const percentage = Math.round((complete / size) * 100);
  // We don't want to display 100% if not truly complete, so handle potential 100% due to rounding
  return percentage === 100 && complete !== size ? 99 : percentage;
};

export const isPkgOrTaskComplete = (pkgOrTask: Package | Task): boolean => {
  return getPkgOrTaskCompletionPercentage(pkgOrTask) === 100;
};

/**
 * Returns true if the given package is late. If the package has no endDate, this
 * returns false as we can't safely check if the package is late.
 */
export const isPkgLate = (pkg: Package) => {
  if (isPkgOrTaskComplete(pkg) || pkg.endDate === undefined) {
    return false;
  }

  return isAfter(new Date(), new Date(pkg.endDate.seconds * 1000));
};

/**
 * Checks if a task item is in second chance delivery and on a non initial chance. If second chance
 * delivery is disabled on the task or if either the task or taskItem are undefined, this will
 * return false.
 */
export const isTaskItemOnNonInitialChance = (
  task: TaskCompletion | undefined,
  taskItem: TaskItemCompletion | undefined,
  maxNumAttempts: number,
): boolean =>
  (task?.secondChanceDelivery ?? false) &&
  !!taskItem &&
  taskItem.numConsecutiveWrongAnswers % maxNumAttempts !== 0;

/**
 * Checks if a task item is in second chance delivery and on its final chance. If second chance
 * delivery is disabled on the task or if either the task or taskItem are undefined, this will
 * return false.
 */
export const isTaskItemOnFinalChance = (
  task: TaskCompletion | undefined,
  taskItem: TaskItemCompletion | undefined,
  maxNumAttempts: number,
): boolean =>
  (task?.secondChanceDelivery ?? false) &&
  !!taskItem &&
  taskItem.numConsecutiveWrongAnswers % maxNumAttempts === maxNumAttempts - 1;

/**
 * Checks if a task item has been attempted by summing the correct and incorrect answers on the task
 * item.
 */
export const isTaskItemAttempted = (taskItem: TaskItemCompletion | undefined): boolean =>
  !!taskItem && taskItem.numCorrectAnswers + taskItem.numWrongAnswers > 0;

import { StudentRewards } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { ProgressBar } from '@sparx/sparx-design/components';
import { useFeatureFlags } from 'utils/feature-flags';
import { OldRewardsPlaque, RewardsPlaque } from 'views/rewards/rewards-plaque/RewardsPlaque';

import styles from './NextXPLevel.module.css';

interface NextXPLevelProps {
  rewards?: StudentRewards;
}

export const NextXPLevel = ({ rewards }: NextXPLevelProps) => {
  const currentLevelXP = rewards?.currentXPLevel?.xpRequired || 0;
  const nextLevelXP = rewards?.nextXPLevel?.xpRequired || 0;
  const xpToNextLevel = nextLevelXP - (rewards?.xpPoints || 0);
  const xpFromCurrentLevel = (rewards?.xpPoints || 0) - currentLevelXP;
  const proportionToNextLevel = nextLevelXP
    ? xpFromCurrentLevel / (nextLevelXP - currentLevelXP)
    : 0;
  const limitedProportion = Math.min(0.97, Math.max(0.03, proportionToNextLevel));
  const currentLevel = rewards?.currentXPLevel?.level || 0;
  const nextLevel = rewards?.nextXPLevel?.level || 0;

  const ff = useFeatureFlags();
  const useNewXp = ff.getBooleanFlag('new-xp-system', false);

  // TODO - FF: new-xp-system
  return (
    <div className={useNewXp ? styles.OuterContainer : styles.OldOuterContainer}>
      <div className={styles.RewardsPlaqueContainer}>
        {useNewXp ? (
          <RewardsPlaque level={currentLevel} />
        ) : (
          <OldRewardsPlaque level={currentLevel} />
        )}
      </div>
      <div className={styles.Container}>
        <div className={styles.ProgressBarContainer}>
          <ProgressBar
            percentComplete={limitedProportion * 100}
            animateFill
            animateWobble
            showHighlight
          />
          <span>
            only {xpToNextLevel} more XP to Level {nextLevel}!
          </span>
        </div>
      </div>
    </div>
  );
};

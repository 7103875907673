import { DisruptiveDialog } from 'components/disruptive-dialog';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';

type Context = {
  trigger: () => void;
};

export const LandscapeModalContext = createContext<Context | undefined>(undefined);

export const LandscapeModalProvider = ({ children }: PropsWithChildren) => {
  const [show, setShow] = useState(false);
  const [hasBeenShown, setHasBeenShown] = useState(false);
  useEffect(() => {
    if (show) setHasBeenShown(true);
  }, [show]);

  const [isLandscape, setIsLandscape] = useState(false);
  useEffect(() => {
    // We can skip adding the event listener if we've already shown the modal.
    if (hasBeenShown && !show) {
      return;
    }

    const aborter = new AbortController();

    window.addEventListener(
      'resize',
      () => {
        const oldValue = isLandscape;
        const newValue = window.innerWidth > window.innerHeight;
        setIsLandscape(newValue);

        // If we've switched to landscape and the modal is open, close it
        if (!oldValue && newValue && show) {
          setShow(false);
        }
      },
      { signal: aborter.signal },
    );

    return () => aborter.abort();
  }, [isLandscape, hasBeenShown, show]);

  const ctx = {
    trigger: () => {
      // If we're in landscape mode or we've already shown the modal, we don't want to show it again.
      if (isLandscape || hasBeenShown) {
        return;
      }
      setShow(true);
    },
  };

  return (
    <LandscapeModalContext.Provider value={ctx}>
      {children}
      <DisruptiveDialog
        isOpen={show}
        description="For the best experience, we recommend you switch to landscape."
        actionButtonContent="Dismiss"
        onAction={() => {
          setShow(false);
        }}
      />
    </LandscapeModalContext.Provider>
  );
};

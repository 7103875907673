// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/dashboarddata/v1/dashboarddata.proto" (package "sparx.teacherportal.dashboarddata.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { FloatValue } from '../../../../../google/protobuf/wrappers';
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.GetDashboardDataRequest
 */
export interface GetDashboardDataRequest {
  /**
   * Resource name of the school for which to get data, in the form `schools/{school_id}`
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
// Usage data

/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.UsageData
 */
export interface UsageData {
  /**
   * Resource name of the data in the form `schools/{school_id}/usagedata`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The percentage difference between SparxMaths and HegartyMaths usage. Examples:
   *  - 1 indicates the same amount of questions have been answered in both products
   *  - 1.2 reflects a 20% increase in usage of SparxMaths compared to HegartyMaths
   *  - 0.8 reflects a 20% decrease in usage of SparxMaths compared to HegartyMaths
   * Big query only selects former-hegarty schools where this has a value greater than 1.4.
   * A null value is returned for non-hegarty schools.
   *
   * @generated from protobuf field: google.protobuf.FloatValue hm_activity_diff_percentage = 2;
   */
  hmActivityDiffPercentage?: FloatValue;
  /**
   * The timestamp that the associated data was computed by BigQuery.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;
  /**
   * The total number of questions answered in Sparx for the school in the current academic year.
   *
   * @generated from protobuf field: int32 total_questions_answered = 4;
   */
  totalQuestionsAnswered: number;
  /**
   * The total number of hours spent in Sparx for the school in the current academic year.
   *
   * @generated from protobuf field: int32 total_hours_done = 5;
   */
  totalHoursDone: number;
  /**
   * The total number of hours spent in Sparx Maths for the school during the previous calendar week.
   *
   * @generated from protobuf field: int32 week_hours_done = 7;
   */
  weekHoursDone: number;
  /**
   * The number of students who have improved their Club within times tables since the start of the academic year.
   *
   * @generated from protobuf field: int32 students_times_tables_improved_club = 6;
   */
  studentsTimesTablesImprovedClub: number;
  /**
   * Data relating to the viewing of the hand in page in TP
   *
   * @generated from protobuf field: sparx.teacherportal.dashboarddata.v1.HomeworkHandInData homework_hand_in_data = 8;
   */
  homeworkHandInData?: HomeworkHandInData;
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.HomeworkHandInData
 */
export interface HomeworkHandInData {
  /**
   * The number of classes with homework due in the relevant week
   *
   * @generated from protobuf field: int32 due_count = 1;
   */
  dueCount: number;
  /**
   * The number of classes who had their hand in page viewed
   *
   * @generated from protobuf field: int32 viewed_count = 2;
   */
  viewedCount: number;
  /**
   * The ids of the classes who were not viewed
   *
   * @generated from protobuf field: repeated string not_viewed_group_ids = 3;
   */
  notViewedGroupIds: string[];
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.SaveUsageDataRequest
 */
export interface SaveUsageDataRequest {
  /**
   * List of UsageData to store.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.dashboarddata.v1.UsageData usage_datas = 1;
   */
  usageDatas: UsageData[];
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.SaveUsageDataResponse
 */
export interface SaveUsageDataResponse {}
// Praise data

/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.ClassStatistic
 */
export interface ClassStatistic {
  /**
   * The id of the class that achieved the statistic
   *
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
  /**
   * The value for this statistic
   *
   * @generated from protobuf field: float statistic_value = 2;
   */
  statisticValue: number;
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.ClassImprovementStatistic
 */
export interface ClassImprovementStatistic {
  /**
   * The id of the class that achieved the statistic
   *
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
  /**
   * The value for this statistic
   *
   * @generated from protobuf field: float statistic_value = 2;
   */
  statisticValue: number;
  /**
   * Represents the increase of this statistic vs previous value
   * e.g. 0.2 represents an increase of 20%
   *
   * @generated from protobuf field: float improvement = 3;
   */
  improvement: number;
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.LevelUpStudent
 */
export interface LevelUpStudent {
  /**
   * The student who levelled up
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The level the student achieved
   *
   * @generated from protobuf field: int32 level = 2;
   */
  level: number;
  /**
   * The class of the student who levelled up
   *
   * @generated from protobuf field: string group_id = 3;
   */
  groupId: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.PraiseData
 */
export interface PraiseData {
  /**
   * Resource name of the data in the form `schools/{school_id}/praisedata`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The timestamp that the associated data was computed by BigQuery.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 2;
   */
  updateTime?: Timestamp;
  /**
   * The monday date for the related praise data
   *
   * @generated from protobuf field: google.protobuf.Timestamp monday_date = 3;
   */
  mondayDate?: Timestamp;
  /**
   * The best homework completion. If more than one class has the best homework completion
   * then this lists all of those classes.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.dashboarddata.v1.ClassStatistic best_completion = 4;
   */
  bestCompletion: ClassStatistic[];
  /**
   * The highest homework completion improvement. If more than one class has
   * the best homework completion improvement then this lists all of those classes.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.dashboarddata.v1.ClassImprovementStatistic best_completion_improvement = 5;
   */
  bestCompletionImprovement: ClassImprovementStatistic[];
  /**
   * The students who acheived a new XP level.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.dashboarddata.v1.LevelUpStudent level_up_students = 6;
   */
  levelUpStudents: LevelUpStudent[];
  /**
   * The best average xp gain in the week. If more than one class has the best XP avereage
   * then this lists all of those classes.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.dashboarddata.v1.ClassStatistic best_xp_average = 7;
   */
  bestXpAverage: ClassStatistic[];
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.SavePraiseDataRequest
 */
export interface SavePraiseDataRequest {
  /**
   * List of PraiseData to store
   *
   * @generated from protobuf field: repeated sparx.teacherportal.dashboarddata.v1.PraiseData praise_datas = 1;
   */
  praiseDatas: PraiseData[];
}
/**
 * @generated from protobuf message sparx.teacherportal.dashboarddata.v1.SavePraiseDataResponse
 */
export interface SavePraiseDataResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class GetDashboardDataRequest$Type extends MessageType<GetDashboardDataRequest> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.GetDashboardDataRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.GetDashboardDataRequest
 */
export const GetDashboardDataRequest = new GetDashboardDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsageData$Type extends MessageType<UsageData> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.UsageData', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'hm_activity_diff_percentage',
        kind: 'message',
        T: () => FloatValue,
      },
      { no: 3, name: 'update_time', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'total_questions_answered',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'total_hours_done',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'week_hours_done',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'students_times_tables_improved_club',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'homework_hand_in_data',
        kind: 'message',
        T: () => HomeworkHandInData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.UsageData
 */
export const UsageData = new UsageData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkHandInData$Type extends MessageType<HomeworkHandInData> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.HomeworkHandInData', [
      { no: 1, name: 'due_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'viewed_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'not_viewed_group_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.HomeworkHandInData
 */
export const HomeworkHandInData = new HomeworkHandInData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveUsageDataRequest$Type extends MessageType<SaveUsageDataRequest> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.SaveUsageDataRequest', [
      {
        no: 1,
        name: 'usage_datas',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UsageData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.SaveUsageDataRequest
 */
export const SaveUsageDataRequest = new SaveUsageDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveUsageDataResponse$Type extends MessageType<SaveUsageDataResponse> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.SaveUsageDataResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.SaveUsageDataResponse
 */
export const SaveUsageDataResponse = new SaveUsageDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClassStatistic$Type extends MessageType<ClassStatistic> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.ClassStatistic', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'statistic_value',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.ClassStatistic
 */
export const ClassStatistic = new ClassStatistic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClassImprovementStatistic$Type extends MessageType<ClassImprovementStatistic> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.ClassImprovementStatistic', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'statistic_value',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 3, name: 'improvement', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.ClassImprovementStatistic
 */
export const ClassImprovementStatistic = new ClassImprovementStatistic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LevelUpStudent$Type extends MessageType<LevelUpStudent> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.LevelUpStudent', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'level', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'group_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.LevelUpStudent
 */
export const LevelUpStudent = new LevelUpStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PraiseData$Type extends MessageType<PraiseData> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.PraiseData', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'update_time', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'monday_date', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'best_completion',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ClassStatistic,
      },
      {
        no: 5,
        name: 'best_completion_improvement',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ClassImprovementStatistic,
      },
      {
        no: 6,
        name: 'level_up_students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LevelUpStudent,
      },
      {
        no: 7,
        name: 'best_xp_average',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ClassStatistic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.PraiseData
 */
export const PraiseData = new PraiseData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SavePraiseDataRequest$Type extends MessageType<SavePraiseDataRequest> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.SavePraiseDataRequest', [
      {
        no: 1,
        name: 'praise_datas',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PraiseData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.SavePraiseDataRequest
 */
export const SavePraiseDataRequest = new SavePraiseDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SavePraiseDataResponse$Type extends MessageType<SavePraiseDataResponse> {
  constructor() {
    super('sparx.teacherportal.dashboarddata.v1.SavePraiseDataResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.dashboarddata.v1.SavePraiseDataResponse
 */
export const SavePraiseDataResponse = new SavePraiseDataResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.teacherportal.dashboarddata.v1.DashboardData
 */
export const DashboardData = new ServiceType(
  'sparx.teacherportal.dashboarddata.v1.DashboardData',
  [
    {
      name: 'GetUsageData',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: '{school_name}',
        },
      },
      I: GetDashboardDataRequest,
      O: UsageData,
    },
    {
      name: 'SaveUsageData',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school:usagedata',
          domain: 'schools',
        },
      },
      I: SaveUsageDataRequest,
      O: SaveUsageDataResponse,
    },
    {
      name: 'GetPraiseData',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: '{school_name}',
        },
      },
      I: GetDashboardDataRequest,
      O: PraiseData,
    },
    {
      name: 'SavePraiseData',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school:praisedata',
          domain: 'schools',
        },
      },
      I: SavePraiseDataRequest,
      O: SavePraiseDataResponse,
    },
  ],
);

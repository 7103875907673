import { VideoNudgeStateContext } from 'context/videonudge/provider';
import { useContext } from 'react';

export const useVideoNudgeState = () => {
  const ctx = useContext(VideoNudgeStateContext);
  if (!ctx) {
    throw new Error('useVideoNudgeState can only be used inside a VideoNudgeStateProvider');
  }
  return ctx;
};

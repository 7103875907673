import { TutorialStep } from 'context/tutorialspotlight/provider';
import { useSession } from 'queries/session';
import { TutorialKey } from 'queries/tutorials';
import { useEffect, useRef, useState } from 'react';
import { arrayEquals } from 'utils/array';

const button: Partial<TutorialStep> = {
  // This is a kind of hacky value as it makes the width round down and the height round up
  spotlightPadding: -0.2,
  // This makes the spotlight rounded to match the buttons
  styles: { spotlight: { borderRadius: 'var(--spx-radius-full)' } },
};

const navTab: Partial<TutorialStep> = {
  placement: 'bottom',
  spotlightPadding: 0,
  // This makes the spotlight fit the task item tabs properly
  styles: {
    spotlight: {
      borderRadius: `0 0 var(--spx-radius-md) var(--spx-radius-md)`,
      border: '1px solid rgba(0, 0, 0, 0.5)',
      borderTop: 'none',
    },
  },
};

const allSteps: Record<string, TutorialStep> = {
  [TutorialKey.FTQNavigation]: {
    tutorialKey: TutorialKey.FTQNavigation,
    content: (
      <>
        <p>
          <strong>Welcome to Fast Track Mode!</strong> Follow these steps to find out how it works.
        </p>
        <p>First, write down the question number, your workings and answer for each question.</p>
      </>
    ),
    placement: 'bottom',
    spotlightPadding: 0,
    offset: 0,
  },
  [TutorialKey.FTQNextQuestion]: {
    tutorialKey: TutorialKey.FTQNextQuestion,
    content:
      'Once you have written down the question number, your working and answer for this question, move onto the next question.',
    ...button,
  },
  [TutorialKey.FTQEnterAllAnswers]: {
    tutorialKey: TutorialKey.FTQEnterAllAnswers,
    content:
      'After you have finished writing down all of your answers you can enter them to be marked here.',
    ...button,
  },
  [TutorialKey.FTQNextAnswer]: {
    tutorialKey: TutorialKey.FTQNextAnswer,
    content: 'Once you have entered your answer for this question, move onto the next answer.',
    ...button,
  },
  [TutorialKey.FTQFinishEnteringAnswers]: {
    tutorialKey: TutorialKey.FTQFinishEnteringAnswers,
    content:
      'Once you have entered all of your answers click on the ‘Finish entering answers’ button to submit them.',
    ...button,
  },
  [TutorialKey.FTQTaskItemStillToDo]: {
    tutorialKey: TutorialKey.FTQTaskItemStillToDo,
    content: 'There are some questions still left to do in this task.',
    ...navTab,
  },
  [TutorialKey.FTQTaskItemCorrect]: {
    tutorialKey: TutorialKey.FTQTaskItemCorrect,
    content:
      'These are questions you answered correctly in the Fast Track. You don’t need to answer them again',
    ...navTab,
  },
  [TutorialKey.FTQTaskItemIncorrect]: {
    tutorialKey: TutorialKey.FTQTaskItemIncorrect,
    content:
      'You answered this question incorrectly in the Fast Track and need to try it again, use the video support to help you if you need it.',
    ...navTab,
  },
  [TutorialKey.FTQTaskItemFastTracked]: {
    tutorialKey: TutorialKey.FTQTaskItemFastTracked,
    content: 'This question has been Fast Tracked! You don’t need to answer it.',
    ...navTab,
  },
};

/**
 * useFTQTutorialSteps checks which of the provided keys have not yet been completed and returns the TutorialSteps for those keys.
 */
export const useFTQTutorialSteps = (keys: TutorialKey[]): TutorialStep[] => {
  const { data: session } = useSession();
  const [filtered, setFiltered] = useState<TutorialStep[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const keysRef = useRef(keys);
  if (!arrayEquals(keys, keysRef.current)) {
    keysRef.current = keys;
  }

  useEffect(() => {
    if (!session?.session) {
      return;
    }

    const filteredKeys = keysRef.current.filter(
      key => !session.session?.tutorialsCompleted.includes(key),
    );
    const steps = filteredKeys.map(key => allSteps[key]);
    if (
      keysRef.current.length === 1 ||
      firstLoad ||
      filteredKeys.length === 0 ||
      filteredKeys.length === keysRef.current.length
    ) {
      setFiltered(steps);
      setFirstLoad(false);
    }
  }, [firstLoad, session?.session]);

  return filtered;
};

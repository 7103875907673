import { SparxWebContext } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { makeSchoolName } from '@sparx/resource-names';
import { FeedbackForm, SubmitData } from '@sparx/shared-components/feedback-form/FeedbackForm';
import { NavigationFrame } from 'app/NavigationFrame';
import { useNavLinks } from 'app/NavigationFrame/use-nav-links';
import { Page } from 'components/page/Page';
import { useSubmitFeedback } from 'queries/feedback';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { Navigate } from 'react-router-dom';
import { useFeatureFlags } from 'utils/feature-flags';
import { useIsPrimaryStudent } from 'utils/session';

import { ReactComponent as FeedbackImage } from './feedback-icon.svg';
import styles from './FeedbackView.module.css';
import { checkIsThrottled } from './utils';

// lastSentimentSubmitMondayKey needs to be kept up to date with the version used on the server, which
// is in sparxweb/handle_submit_student_sentiment_feedback.go
const lastSentimentSubmitMondayKey = 'last_sentiment_submit_monday';

export const FeedbackView = () => {
  const { data: session } = useSession();
  const isPrimaryStudent = useIsPrimaryStudent();
  const navItems = useNavLinks();
  const featureFlags = useFeatureFlags();
  const shouldRedirect =
    !featureFlags.getBooleanFlag('sparxweb2-enable-feedback', false) || isPrimaryStudent;

  const storedLastSubmitDate = session?.student?.labels[lastSentimentSubmitMondayKey];

  const isThrottled = checkIsThrottled(new Date(), storedLastSubmitDate);

  const {
    mutate: submitFeedback,
    isLoading: isSubmitting,
    isError: isSubmitError,
    isSuccess: isSubmitSuccess,
  } = useSubmitFeedback();

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  const onSubmit = ({ sentiment, comment }: SubmitData) => {
    submitFeedback({
      sentiment,
      comment,
      labels: {},
      sw: SparxWebContext.create({
        feedbackType: 'menu-feedback',
        schoolName: makeSchoolName(window.__sparxweb.schoolID),
        applicationVersion: import.meta.env.VITE_REACT_APP_VERSION,
      }),
      product: Product.SPARX_MATHS,
    });
  };

  const feedbackPrompt = (
    <p className={styles.PromptText}>
      Hi {getUsersNamesFromSession(session, 'firstOnly')}. You can use this page to tell us what you
      do or don’t like about using Sparx Maths Homework.
    </p>
  );

  return (
    <Page>
      <NavigationFrame sideBarItems={navItems} bottomBarItems={navItems}>
        <div className={styles.Container}>
          <section className={styles.Header}>
            <FeedbackImage className={styles.Image} />
            <h1 className={styles.Title}>Give feedback</h1>
          </section>
          <FeedbackForm
            prompt={feedbackPrompt}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitSuccess}
            consentText={`I will use this form responsibly. I understand that any feedback I share may be shown to my teachers at ${window.__sparxweb.schoolDisplayName} and with my parents/carers.`}
            submitError={
              isSubmitError
                ? 'Oops! Something went wrong whilst sending your feedback, please try again.'
                : undefined
            }
            isFeedbackThrottled={isThrottled}
            additionalSuccessText="You can give more feedback next week."
          />
        </div>
      </NavigationFrame>
    </Page>
  );
};

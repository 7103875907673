import {
  GetTrainingProgressForCurrentUserRequest,
  UpdateTrainingProgressForCurrentUserRequest,
} from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress';
import { GetTrainingPackagesRequest } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'app/queryClient';
import { useAPI } from 'context/api';

/**
 * useTrainingPackages returns the packageData for teacher training packages.
 * Although it returns all package data, the package hooks (i.e. useTaskItems etc.)
 * should be used for progress data.
 */
export const useTrainingPackages = (trainingEnabled: boolean) => {
  const { swworkerClient } = useAPI();
  return useQuery(
    ['trainingPackages'],
    () => swworkerClient.getTrainingPackages(GetTrainingPackagesRequest.create({})).response,
    {
      select: data => data.packages,
      // Invalidate activeHomeworkPackages when training packages are updated - this will ensure they are loaded on the
      // package screen
      onSuccess: () => queryClient.invalidateQueries(['activeHomeworkPackages']),
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
      enabled: trainingEnabled,
    },
  );
};

export const useTrainingProgress = (trainingEnabled: boolean) => {
  const { trainingClient } = useAPI();
  return useQuery(
    ['getTrainingProgress'],
    () =>
      trainingClient.getTrainingProgressForCurrentUser(
        GetTrainingProgressForCurrentUserRequest.create({}),
      ).response,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      suspense: true,
      enabled: trainingEnabled,
    },
  );
};

/**
 * Mutation to update training progress for the current user
 */
export const useUpdateTrainingProgressForCurrentUser = () => {
  const { trainingClient } = useAPI();
  return useMutation({
    mutationFn: async (req: UpdateTrainingProgressForCurrentUserRequest) =>
      trainingClient.updateTrainingProgressForCurrentUser(req).response,
    retry: 5,
  });
};

/**
 * For a URL path under independent learning, determine which path the back button should go to. Returns undefined if
 * the current path is not under independent learning.
 * @param currentPath
 */
export const getIndependentLearningBack = (currentPath: string): string | undefined => {
  if (!currentPath.startsWith('/independentlearning')) {
    return undefined;
  }

  // If in the LQD view, go back to the IL topic view:
  if (currentPath.includes('/package')) {
    return currentPath.substring(0, currentPath.indexOf('/package'));
  }

  // If on the topic view, if you have come via a strand, go back to the strand page, otherwise go back to the IL home:
  if (
    currentPath.includes('/topics') &&
    currentPath.includes('/strands') &&
    currentPath.includes('/substrands')
  ) {
    const substrandID = currentPath.substring(
      currentPath.indexOf('/substrands/') + '/substrands'.length + 1,
      currentPath.indexOf('/topics'),
    );
    return `${currentPath.substring(
      0,
      currentPath.indexOf('/substrands'),
    )}?open_substrand_id=${substrandID}`;
  }

  // If on the strand page, go back to the IL home:
  if (currentPath.includes('/strands')) {
    return '/independentlearning';
  }

  // Otherwise (i.e. root IL page), hide back button
  return undefined;
};

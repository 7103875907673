import { LargeLoading } from '@sparx/sparx-design/icons';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import React, { PropsWithChildren } from 'react';

/**
 * Component that wraps a page to display a loading spinner when any calls to the server that use react-query in
 * suspense mode are still loading.
 * (This used to show an error page, but we now use the better ErrorPage component which is displayed by React Router)
 * @param children
 * @constructor
 */

interface SuspenseRouteProps extends PropsWithChildren {
  timeLongLoadTimeApp?: string;
}

export const SuspenseRoute = ({
  children,
  timeLongLoadTimeApp = undefined,
}: SuspenseRouteProps) => {
  return (
    <React.Suspense
      fallback={
        timeLongLoadTimeApp ? (
          <LoadingSpinnerWithAnalytics
            componentName={`SuspenseRoute-${timeLongLoadTimeApp}`}
            sendLongTimeLoadingEvent={true}
          />
        ) : (
          <LargeLoading />
        )
      }
    >
      {children}
    </React.Suspense>
  );
};

import { MatchingResource } from '@sparx/api/apis/sparx/content/search/v1/search';
import { useMemo } from 'react';

import { useSelectedCurriculumName } from '../hooks';
import { TopicLocation } from '../utils';

export interface MatchWithLocation {
  match: MatchingResource;
  location: TopicLocation;
}

// Convert the server protobuf result objects into js objects with the topic
// strand and substrand included. We also filter out topics which we don't
// know about in the current curriculum.
const useTopicWithLocation = (
  results: MatchingResource[],
  topicLocationMap: Map<string, TopicLocation>,
) =>
  useMemo(
    () =>
      results
        ?.map(match => ({ match, location: topicLocationMap?.get(match.resourceName) }))
        .filter(
          (r): r is MatchWithLocation =>
            r.location !== undefined && r.location.strandSummary.strand?.displayName !== 'Revision',
        )
        .sort((a, b) => b.match.score - a.match.score),
    [results, topicLocationMap],
  );

type GroupedResults = {
  selectedCurriculumResults: MatchWithLocation[];
  otherCurriculaResults: MatchWithLocation[];
};

// Group the search results into two groups: those that are in the selected curriculum
// and those that are in other curricula.
export const useGroupedSearchResults = (
  results: MatchingResource[],
  topicLocationMap: Map<string, TopicLocation>,
) => {
  const selectedCurriculumName = useSelectedCurriculumName();
  const resultsWithStrand = useTopicWithLocation(results, topicLocationMap);
  return useMemo(() => {
    // We need to keep track of which topics we've already allocated to a curriculum.
    const allocatedTopicNames = new Set<string>();
    return resultsWithStrand.reduce<GroupedResults>(
      (groupedResults, result) => {
        const { match, location } = result;
        const key = `${match.resourceName}-${match.resourceDisplayName}`;
        // If we've already allocated this topic to a curriculum, don't allocate it again. We do this
        // as the search _may_ return the same topic for both the current curriculum search and all curricula search.
        if (allocatedTopicNames.has(key)) {
          return groupedResults;
        }
        // If the topic is in the selected curriculum, add it to the selectedCurriculumResults list.
        if (location.curriculumDetails.hierarchy.curriculum?.name === selectedCurriculumName) {
          groupedResults.selectedCurriculumResults.push(result);
        } else {
          groupedResults.otherCurriculaResults.push(result);
        }
        // Mark the topic as allocated.
        allocatedTopicNames.add(key);
        return groupedResults;
      },
      { otherCurriculaResults: [], selectedCurriculumResults: [] },
    );
  }, [resultsWithStrand, selectedCurriculumName]);
};

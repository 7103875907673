import { Button } from '@sparx/sparx-design/components';
import { ChevronRight } from '@sparx/sparx-design/icons';
import { TooltipRenderProps } from 'react-joyride';

import styles from './TutorialTooltip.module.css';

export const TutorialTooltip = ({
  tooltipProps,
  step,
  isLastStep,
  primaryProps,
}: TooltipRenderProps) => {
  return (
    <div ref={tooltipProps.ref} className={styles.JoyrideTooltip}>
      {typeof step.content === 'object' ? step.content : <p>{step.content}</p>}
      <Button
        className={styles.TooltipButton}
        variant="contained"
        onClick={primaryProps.onClick}
        rightIcon={isLastStep ? undefined : <ChevronRight />}
      >
        {primaryProps.title}
      </Button>
    </div>
  );
};

import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { TaskItemCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import {
  Activity,
  QuestionActivity,
  TaskItemStatus,
  VideoAction_ActionType,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { Button, HiddenAt } from '@sparx/design/components';
import { IInput, IStep, SparxQuestion } from '@sparx/question';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { ChevronLeft, TimesIcon, VideoIcon } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import classNames from 'classnames';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { Video } from 'components/video/Video';
import { useTutorialSpotlight } from 'context/tutorialspotlight';
import { useVideoNudgeState } from 'context/videonudge';
import { motion } from 'framer-motion';
import { buildVideoAction, useActivityAction } from 'queries/activity';
import { useTaskItems } from 'queries/packages';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useLQDPath } from 'utils/paths';
import { GetCalculatorAllowed } from 'utils/question';
import { makeQuestionVideoUrls } from 'utils/urls';
import { v4 as uuid } from 'uuid';
import { MultiPartMarkingBanner } from 'views/lqd/multi-part-marking-banner/MultiPartMarkingBanner';
import { OnboardingTaskVideoModal } from 'views/question/OnboardingTaskVideoModal';
import { VideoNudgePopover } from 'views/question/VideoNudgePopover';

import BottomBar from '../lqd/bottom-bar';
import styles from './Question.module.css';
import { QuestionAnswerLayout } from './QuestionAnswerLayout';
import { QuestionInfo } from './QuestionInfo';

interface IQuestionProps {
  activity: Activity;
  question: QuestionActivity;
  input: IInput;
  layout: IStep;
  setInput: (input: IInput) => void;
  goToAnswer: () => void;
  previousTaskItemPath: string | undefined;
  isItemOnNonInitialChance: boolean;
  isItemAttempted: boolean;
  isItemSwapped: boolean;
  hideBookworkCodes: boolean;
  onScaleChange?: (value: number) => void;
  currentTaskItem?: TaskItemCompletion;
}

export const Question: FunctionComponent<IQuestionProps> = ({
  activity,
  question,
  input,
  layout,
  setInput,
  goToAnswer,
  previousTaskItemPath,
  isItemOnNonInitialChance,
  isItemAttempted,
  isItemSwapped,
  hideBookworkCodes,
  onScaleChange,
  currentTaskItem,
}) => {
  const sendEvent = useAnalytics();
  const { tutorialIsShowing } = useTutorialSpotlight();

  const questionContent = (
    <>
      <KeyboardShortcuts enterFunc={() => !tutorialIsShowing && goToAnswer()} source="question" />
      <MultiPartMarkingBanner
        isItemAttempted={isItemAttempted}
        isMultiPartMarkedQuestion={question.multiPartMarking}
      />
      <QuestionInfo
        bookworkCode={hideBookworkCodes ? undefined : question.bookworkCode}
        calculatorAllowed={GetCalculatorAllowed(layout)}
        showBanner={true}
        isItemAttempted={isItemAttempted}
        isItemOnNonInitialChance={isItemOnNonInitialChance}
        isItemSwapped={isItemSwapped}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.QuestionWrapper}
      >
        <SparxQuestion
          layout={layout.layout}
          input={input}
          setInput={setInput}
          mode="question"
          fontSize="30"
          lineHeight="1.2em"
          centered={true}
          onScaleChange={onScaleChange}
          sendAnalyticEvent={(action, labels) =>
            sendEvent({ category: 'question', action, labels })
          }
        />
      </motion.div>
    </>
  );

  const { watchVideoButton, helpVideo } = useGetVideoElements(
    question.videoID,
    activity,
    currentTaskItem,
  );

  const bottomBar = (
    <BottomBar>
      {previousTaskItemPath ? (
        <Button
          as={Link}
          to={previousTaskItemPath}
          variant="secondary"
          className={styles.PreviousButton}
          // BOOL-3095 - page event removed to reduce CloudFlare traffic
          // onClick={() => {
          //   sendEvent({
          //     action: 'clicked previous',
          //     category: 'lqd',
          //   });
          // }}
        >
          <ChevronLeft />
          <HiddenAt as="span" breakpoint="sm">
            {' '}
            Previous
          </HiddenAt>
        </Button>
      ) : (
        <div />
      )}
      {watchVideoButton}
      <Button
        variant="primary"
        onClick={() => {
          // BOOL-3095 - page event removed to reduce CloudFlare traffic
          // sendEvent({
          //   action: 'clicked answer',
          //   category: 'lqd',
          // });
          goToAnswer();
        }}
      >
        Answer
      </Button>
    </BottomBar>
  );

  return (
    <>
      <QuestionAnswerLayout content={questionContent} bottomBar={bottomBar} />
      <OnboardingTaskVideoModal />
      {helpVideo}
    </>
  );
};

const useGetVideoElements = (
  videoId: string,
  activity: Activity,
  currentTaskItem?: TaskItemCompletion,
) => {
  const urls = makeQuestionVideoUrls(videoId);
  const { mutate } = useActivityAction();
  const [watchID] = useState(uuid());

  const videoAction = (actionType: VideoAction_ActionType) => {
    mutate(
      buildVideoAction(activity, {
        videoID: videoId,
        watchID,
        actionType,
      }),
    );
  };

  // get the task, and check its status
  const { packageID, taskIndex, taskItemIndex } = useLQDPath();
  const { data: taskItems, isSuccess } = useTaskItems(packageID, taskIndex);

  let taskItemStatus: TaskItemStatus | undefined = undefined;
  if (taskItems && taskItemIndex && isSuccess) {
    taskItemStatus = taskItems[taskItemIndex - 1].status;
  }

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const toggleVideoModal = (open: boolean) => {
    setVideoModalOpen(open);
    videoAction(open ? VideoAction_ActionType.VIEW : VideoAction_ActionType.DISMISS);
  };

  const [nudgeOpen, setNudgeOpen] = useState(false);

  const { shouldShowVideoNudge } = useVideoNudgeState();

  const timeoutRef = useRef<number | undefined>(undefined);
  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentTaskItem && shouldShowVideoNudge(currentTaskItem)) {
      // timeout so that we don't show the nudge until the animation has completed
      timeoutRef.current = window.setTimeout(() => setNudgeOpen(true), 300);
    } else {
      setNudgeOpen(false);
    }
  }, [taskItemStatus, shouldShowVideoNudge, currentTaskItem, setNudgeOpen]);

  const watchVideoButton = videoId ? (
    <VideoNudgePopover
      open={nudgeOpen}
      setVideoModalOpen={toggleVideoModal}
      posterUrl={urls.posterUrl}
    >
      <Button
        variant="secondary"
        onClick={() => toggleVideoModal(true)}
        leftIcon={<VideoIcon />}
        hideLeftIconAt="xs"
        // BOOL-3095 - page event removed to reduce CloudFlare traffic
        // onClick={() => sendEvent({ action: 'clicked watch video', category: 'lqd' })}
        onKeyDown={simulateClickOnEnter}
      >
        Watch video
      </Button>
    </VideoNudgePopover>
  ) : (
    <div />
  );

  const helpVideo = videoId && (
    <Dialog.Root onOpenChange={toggleVideoModal} open={videoModalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullWidth,
            dialogStyles.WithZIndex,
          )}
        >
          <Dialog.Title className={dialogStyles.DialogTitle}>Support video</Dialog.Title>
          <Dialog.Description className={dialogStyles.DialogSubtitle}>
            <span className={styles.TipBadge}>Tip!</span> Watching all of the video will help you
            get it right. Pause it if you need.
          </Dialog.Description>
          <div className={styles.VideoContainer}>
            <Video
              // BOOL-3035 - disable analytics for lqd video to reduce CloudFlare traffic
              disableAnalytics={true}
              videoUrl={urls.videoUrl}
              posterUrl={urls.posterUrl}
              subtitleUrl={urls.subtitleUrl}
              onPlay={() => videoAction(VideoAction_ActionType.PLAY)}
              onPause={() => videoAction(VideoAction_ActionType.PAUSE)}
            />
          </div>
          <div className={styles.VideoCloseButton}>
            <Button
              as={Dialog.Close}
              rightIcon={<TimesIcon />}
              // BOOL-3095 - page event removed to reduce CloudFlare traffic
              // onClick={() => sendEvent({ action: 'clicked close video', category: 'lqd' })}
              onKeyDown={simulateClickOnEnter}
            >
              Close video
            </Button>
          </div>
          <Dialog.Close asChild>
            <button
              className={classNames(dialogStyles.IconButton, styles.VideoCloseX)}
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
  return { watchVideoButton, helpVideo };
};

export const QuestionLoading = () => (
  <QuestionAnswerLayout
    content={
      <LoadingSpinnerWithAnalytics
        componentName="QuestionLoading"
        sendLongTimeLoadingEvent={true}
      />
    }
    bottomBar={<BottomBar />}
  />
);

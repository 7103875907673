export enum AccordionStatus {
  Completed,
  InProgress,
  NotStarted,
  Locked,
}

export enum PackageLockReason {
  Onboarding,
  Deleted,
}

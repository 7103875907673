import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import {
  TaskCompletion,
  TaskItemCompletion,
  TaskItemStatus,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Button } from '@sparx/sparx-design/components';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { useSubmitQuizAnswers } from 'queries/ftq';
import { useEffect, useState } from 'react';
import { useAnalytics } from 'utils/analytics';
import { isFTQQuizComplete } from 'utils/ftqtask';
import { FTQStep } from 'views/fast-track-quiz/useFastTrackQuiz';

import styles from './Dialogs.module.css';

type SubmittedDialogProps = {
  ftqStep: FTQStep;
  setFTQStep: (ftqStep: FTQStep) => void;
  setTaskItemIndex: (taskItemIndex: number | undefined) => void;
  task: TaskCompletion;
  allFTQItems: TaskItemCompletion[];
};

/** Submitted Dialog shows a dialog when the user submits the quiz, and has the opportunity to
 * improve their score (i.e. first attempt and has got at least 1 question incorrect).
 * It expects that submit answer has been successfully called.
 **/
export const SubmittedDialog = ({
  ftqStep,
  setFTQStep,
  setTaskItemIndex,
  task,
  allFTQItems,
}: SubmittedDialogProps) => {
  const sendEvent = useAnalytics();

  // mutation to skip the quiz if the user doesn't want to improve their score
  const { mutate: skipQuiz } = useSubmitQuizAnswers();

  const isSmall = useBreakpoint('sm');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    // work out how many ftq items are correct
    const numberCorrect = allFTQItems.reduce(
      (acc, item) => (item.status === TaskItemStatus.DONE ? acc + 1 : acc),
      0,
    );

    // if the quiz is submitted, and the user is able to retry, show the dialog
    if (
      ftqStep === FTQStep.SUBMITTED &&
      !(isFTQQuizComplete(task) || numberCorrect === allFTQItems.length)
    ) {
      setDialogOpen(true);
      setDialogContent(
        <div className={styles.ContentContainer}>
          <span className={styles.Title}>
            Fast Track Score: {numberCorrect}/{allFTQItems.length}
          </span>
          <span className={styles.Body}>
            Check that you entered your answers correctly and retry questions to improve your score.
          </span>
          <div className={styles.ButtonsContainer}>
            {/* to end the quiz, submit it again, and move onto the results screen */}
            <Button
              size={isSmall ? 'sm' : 'md'}
              onClick={() => {
                sendEvent({
                  category: 'ftq',
                  action: 'clicked skip quiz from submitted modal',
                  labels: {
                    score: `${numberCorrect}/${allFTQItems.length}`,
                  },
                });
                skipQuiz({
                  task: {
                    packageID: task.packageID,
                    taskIndex: task.taskIndex,
                    taskItemIndex: 0,
                    taskState: 0,
                  },
                  skipFTQ: false,
                });
                setFTQStep(FTQStep.RESULT);
              }}
            >
              End quiz
            </Button>
            {/* to retry the quiz, we just reset the ftq step to question */}
            <Button
              size={isSmall ? 'sm' : 'md'}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              variant="contained"
              onClick={() => {
                sendEvent({
                  category: 'ftq',
                  action: 'clicked retry quiz from submitted modal',
                  labels: {
                    score: `${numberCorrect}/${allFTQItems.length}`,
                  },
                });
                setFTQStep(FTQStep.QUESTION);
                setTaskItemIndex(undefined);
              }}
            >
              Improve your score
            </Button>
          </div>
        </div>,
      );
    } else {
      // if we don't want to show the dialog, clear and close it, and, if we are on the submitted
      // step, move onto the result step
      if (ftqStep === FTQStep.SUBMITTED) {
        setFTQStep(FTQStep.RESULT);
      }
      setDialogOpen(false);
      setDialogContent(undefined);
    }
  }, [allFTQItems, ftqStep, setFTQStep, skipQuiz, task, setTaskItemIndex, isSmall, sendEvent]);

  return (
    <Dialog.Root open={dialogOpen}>
      <Dialog.Portal>
        {/* vuli todo zindex of 9 ??? */}
        <Dialog.Overlay className={dialogStyles.DialogOverlay} style={{ zIndex: 9 }} />
        <Dialog.Content
          className={classNames(dialogStyles.DialogContent, dialogStyles.ContentHeight)}
          style={{ zIndex: 9 }}
        >
          {dialogContent}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

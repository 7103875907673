import { useContext } from 'react';

import { NavigationContext } from './NavigationProvider';

/**
 * useNavigationContext allows setting an override for the back path used by the back button in the
 * header. This allows components to set a more appropriate back path when it can't be figured out
 * from the current URL.
 */
export const useNavigationContext = () => {
  const ctx = useContext(NavigationContext);
  if (!ctx) {
    throw new Error('useNavigationContext must be called within a NavigationProvider');
  }
  return ctx;
};

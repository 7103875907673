import classNames from 'classnames';

import styles from './RibbonBanner.module.css';
import { ReactComponent as TasselLeft } from './TasselLeft.svg';
import { ReactComponent as TasselRight } from './TasselRight.svg';

export const RibbonBanner = ({
  barClassName,
  containerClassName,
  children,
}: {
  barClassName?: string;
  containerClassName?: string;
  children: React.ReactNode;
}) => (
  <div className={classNames(containerClassName, styles.RibbonContainer)}>
    <TasselLeft className={classNames(styles.Tassel, styles.Left)} />
    <TasselRight className={classNames(styles.Tassel, styles.Right)} />
    <div className={classNames(barClassName, styles.RibbonBar)}>{children}</div>
  </div>
);

import { Carousel } from '@sparx/sparx-design/components/carousel';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { NavigationFrame } from 'app/NavigationFrame';
import { useNavLinks } from 'app/NavigationFrame/use-nav-links';
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'components/accordion/Accordion';
import { Page } from 'components/page/Page';
import chatIcon from 'images/icon_chat.svg';
import settingsIcon from 'images/icon_settings.svg';
import videoIcon from 'images/icon_video.svg';
import bookworkVideoImage from 'images/resource-hub/bookwork_video.png';
import bookworkChangeImage from 'images/resource-hub/bw_change.png';
import gettingStartedImage from 'images/resource-hub/getting_started_video.png';
import tipsImage from 'images/resource-hub/tips.png';
import { ReactNode, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';
import { useIsPrimaryStudent } from 'utils/session';
import { makeOnboardingVideoUrls, makeStaticVideoUrls, VideoUrls } from 'utils/urls';

import styles from './ResourceHub.module.css';
import { ResourceHubVideoModal } from './ResourceHubVideoModal';

export const ResourceHub = () => {
  const isPrimaryStudent = useIsPrimaryStudent();
  const featureFlags = useFeatureFlags();
  const sendEvent = useAnalytics();
  const navItems = useNavLinks();
  const isSmallScreen = useBreakpoint('sm');
  const isMediumScreen = useBreakpoint('md');
  const [openVideoURLs, setOpenVideoURLs] = useState<VideoUrls | undefined>(undefined);
  const [openFAQs, setOpenFAQs] = useState<string[]>([]);

  const onFAQValueChange = (newOpenFAQs: string[]) => {
    setOpenFAQs(newOpenFAQs);

    const opened = newOpenFAQs.length > openFAQs.length;
    const faq = opened
      ? newOpenFAQs.find(faq => !openFAQs.includes(faq))
      : openFAQs.find(faq => !newOpenFAQs.includes(faq));

    sendEvent({
      action: `faq ${opened ? 'opened' : 'closed'}`,
      category: 'resource hub',
      labels: {
        faq: faq || 'unknown',
      },
    });
  };

  const showFeedback =
    featureFlags.getBooleanFlag('sparxweb2-enable-feedback', false) && !isPrimaryStudent;

  const showSettings = featureFlags.getBooleanFlag('sparxweb2-settings-page', false);

  const hasThreeAttempts = featureFlags.getBooleanFlag('sparxweb2-three-attempts', false);

  // redirect primary students to the home page
  if (isPrimaryStudent) {
    return <Navigate to="/" />;
  }

  return (
    <Page>
      <NavigationFrame sideBarItems={navItems} bottomBarItems={navItems}>
        <div className={styles.Container}>
          <h1>Resource Hub</h1>
          <section className={styles.SupportTipsContainer}>
            <h4>Support and top tips</h4>
            <Carousel
              carouselID="support-tips"
              numItemsToShow={isSmallScreen ? 1 : isMediumScreen ? 2 : 3}
              items={[
                <SupportTipsItem
                  key="getting-started"
                  title="Getting started"
                  img={gettingStartedImage}
                  onClick={() => setOpenVideoURLs(makeOnboardingVideoUrls('welcome'))}
                />,
                <SupportTipsItem
                  key="bookwork"
                  title="How bookwork checks work"
                  img={bookworkVideoImage}
                  onClick={() => setOpenVideoURLs(makeOnboardingVideoUrls('bookwork'))}
                />,
                <SupportTipsItem
                  key="tips"
                  title="Sparx tips"
                  img={tipsImage}
                  onClick={() => setOpenVideoURLs(makeOnboardingVideoUrls('tips'))}
                />,
                <SupportTipsItem
                  key="bw-check-changes"
                  title="Changes to bookwork checks"
                  img={bookworkChangeImage}
                  onClick={() =>
                    setOpenVideoURLs(makeStaticVideoUrls('assets/whats-new/sw2/bw-check-changes'))
                  }
                />,
              ]}
            />
          </section>
          <section className={styles.LinksContainer}>
            {showSettings && (
              <Link className={styles.Link} to="/settings">
                <section className={styles.LinkContainer}>
                  <div>
                    <h4>Settings</h4>
                    <p>Screen settings and tips</p>
                  </div>
                  <img src={settingsIcon} role="presentation" aria-hidden />
                </section>
              </Link>
            )}
            {showFeedback && (
              <Link className={styles.Link} to="/feedback">
                <section className={styles.LinkContainer}>
                  <div>
                    <h4>Give feedback</h4>
                    <p>Help us make Sparx Maths better</p>
                  </div>
                  <img src={chatIcon} aria-hidden />
                </section>
              </Link>
            )}
          </section>
          <section>
            <AccordionRoot
              onValueChange={onFAQValueChange}
              value={openFAQs}
              className={styles.FAQs}
            >
              <h4>Your questions</h4>
              <p>Here are the answers to some of the questions we get about Sparx</p>
              <h5>The basics</h5>
              <FAQItem
                question="What is Sparx Maths Homework?"
                answer={
                  <div>
                    Sparx Maths helps you get the most from the time you spend learning, by:
                    <ul>
                      <li>Picking questions that are just right for you</li>
                      <li>Adjusting over time to keep up with your progress</li>
                      <li>Giving support videos for every question, so help is always on hand</li>
                      <li>Bringing back topics for practice before you forget them</li>
                    </ul>
                  </div>
                }
              />
              <FAQItem
                question="How much maths homework will I get?"
                answer="Your teacher sets the amount of homework you get, so the length of each homework assignment can vary slightly from week to week."
              />
              <h5>Getting setup</h5>
              <FAQItem
                question="What kind of computer or tablet do I need to use?"
                answer="Sparx Homework will work on most computers, tablets and phones. They must be connected to the internet."
              />
              <FAQItem
                question="What if I don’t have a computer, tablet or phone at home?"
                answer="Please tell your teacher and they will let you know what to do."
              />
              <FAQItem
                question="What will I need to do my homework?"
                answer="As well as a computer, tablet or phone, you will need a pen/pencil and something to write on such as a workbook or a piece of paper. For some questions you might also need a calculator, ruler, protractor, pair of compasses and graph paper."
              />
              <h5>Doing your homework</h5>
              <FAQItem
                question="What’s the difference between Compulsory, XP Boost and Target questions?"
                answer={
                  <>
                    <p>
                      <strong>Compulsory</strong> homework contains questions from topics set by
                      your teacher and topics that you’ve recently covered so that you don’t forget
                      them. The questions are chosen just for you.
                    </p>
                    <p>
                      <strong>XP Boost</strong> allows you to earn loads of extra XP! XP Boost is
                      tailored to you and contains questions which will help you to further
                      strengthen your understanding.
                    </p>
                    <p>
                      <strong>Target</strong> homework contains questions from topics you have
                      previously struggled with and extensions of topics that you have done well on.
                      You might find these questions slightly more difficult than your Compulsory
                      and XP Boost.
                    </p>
                  </>
                }
              />
              <FAQItem
                question="What are bookwork checks? (and how do they work?)"
                answer="It is really important to write down your working. To help remember, Sparx will occasionally ask you to pass a bookwork check. A bookwork check presents you with a bookwork code from a question you’ve recently answered, and then asks you to enter the correct answer that you gave. You must provide the correct answer that you wrote down for that bookwork code. The more bookwork checks you pass, the less often they will be asked."
              />
              <FAQItem
                question="What if I get stuck on a question?"
                answer="If you have attempted a question but are stuck, the first step is to watch the support video for that question. If you are still finding a question difficult then we suggest that you ask your maths teacher for help. Please start your homework early so that you have enough time to get help with any questions that you are stuck on."
              />
              <FAQItem
                question="What are Experience Points (XP)?"
                answer={
                  <>
                    <p>
                      XP is given to you for completing your homework. You will gain XP by
                      completing Compulsory, XP Boost and Target tasks, and Independent Learning.
                    </p>
                    <p>
                      You will reach a new Sparx Level every 1,000 XP to recognise your hard work.
                      XP Boost and Target will earn you loads of extra XP as a reward!
                    </p>
                  </>
                }
              />
              <FAQItem
                question="What happens if I have got some answers wrong?"
                answer={
                  hasThreeAttempts
                    ? 'If you attempt a question and get it wrong, you will have two more chances to answer the same question. If you still answer incorrectly after these attempts, you will be given a similar but new question. If after watching the video you have attempted the question at least six times and are stuck, we recommend asking your maths teacher for help.'
                    : 'If you attempt a question and get it wrong, you will get a second chance at the same question. If you get it wrong again you will be given a similar but new question. If after watching the video you have attempted the question at least four times and are stuck, we recommend asking your maths teacher for help.'
                }
              />
              <FAQItem
                question="What happens if I am sick or off school?"
                answer="Your parent or carer must tell the school and your teacher will let you know what you need to do."
              />
            </AccordionRoot>
          </section>
        </div>
      </NavigationFrame>
      <ResourceHubVideoModal
        isOpen={openVideoURLs !== undefined}
        onClose={() => setOpenVideoURLs(undefined)}
        title="Help video"
        urls={openVideoURLs}
      />
    </Page>
  );
};

const SupportTipsItem = ({
  title,
  img,
  onClick,
}: {
  title: string;
  img: string;
  onClick: () => void;
}) => (
  <div className={styles.SupportTipsItem} onClick={onClick}>
    <img src={img} aria-hidden />
    <span>
      {title}
      <img src={videoIcon} aria-hidden />
    </span>
  </div>
);

const FAQItem = ({ question, answer }: { question: string; answer: ReactNode }) => (
  <AccordionItem className={styles.FAQItem} value={question}>
    <AccordionTrigger className={styles.FAQItemHeader}>{question}</AccordionTrigger>
    <AccordionContent className={styles.FAQItemContent}>
      <div>{answer}</div>
    </AccordionContent>
  </AccordionItem>
);

import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import { TaskItemCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import {
  Activity,
  ActivityActionResponse,
  GapEvaluation,
  Hint,
  QuestionActivity,
  TaskItemStatus,
  VideoAction_ActionType,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { HintInfo, IInput, IStep, SparxQuestion } from '@sparx/question';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Button, HiddenAt, Tooltip } from '@sparx/sparx-design/components';
import { ChevronLeft, TimesIcon, VideoIcon } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import classNames from 'classnames';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { Video } from 'components/video/Video';
import { useKeyboardMode } from 'context/keyboardmode';
import { useTutorialSpotlight } from 'context/tutorialspotlight';
import { useVideoNudgeState } from 'context/videonudge';
import { motion } from 'framer-motion';
import { buildVideoAction, useActivityAction } from 'queries/activity';
import { useTaskItems } from 'queries/packages';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';
import { useLQDPath } from 'utils/paths';
import { GetCalculatorAllowed } from 'utils/question';
import { makeQuestionVideoUrls } from 'utils/urls';
import { v4 as uuid } from 'uuid';
import { HintVideoModal } from 'views/answer/hint-video-modal';
import { hasInputForRefsAnswerPart, resetInputForRefsAnswerPart } from 'views/answer/utils';
import { MultiPartMarkingBanner } from 'views/lqd/multi-part-marking-banner/MultiPartMarkingBanner';
import { OnboardingTaskVideoModal } from 'views/question/OnboardingTaskVideoModal';
import styles from 'views/question/Question.module.css';
import { QuestionAnswerLayout } from 'views/question/QuestionAnswerLayout';
import { QuestionInfo } from 'views/question/QuestionInfo';
import { VideoNudgePopover } from 'views/question/VideoNudgePopover';
import { Result } from 'views/result';

import BottomBar from '../lqd/bottom-bar';

interface IQuestionAndAnswerProps {
  activity: Activity;
  question: QuestionActivity;
  input: IInput;
  layout: IStep;
  setInput: (input: IInput) => void;
  previousTaskItemPath: string | undefined;
  isItemOnNonInitialChance: boolean;
  isItemAttempted: boolean;
  isItemSwapped: boolean;
  hideBookworkCodes: boolean;
  onScaleChange?: (value: number) => void;
  currentTaskItem?: TaskItemCompletion;

  submitAnswerResponse?: ActivityActionResponse;
  firstChanceGapEvaluationsRef?: React.MutableRefObject<Record<string, GapEvaluation> | undefined>;
  placeholderMap?: Dictionary<string, string>;
  // an array containing an entry for each part in the question. Each entry is an array of refs in
  // that part
  partRefs: string[][];
  isLastItem: boolean;
  isTaskComplete: boolean;
  currentTaskItemPath: string;
  nextTaskItemPath: string;
  retryTask: () => void;
  numSwapsUsed: number;

  submitError?: boolean;
  submitAnswer?: () => void;
}

export const QuestionAndAnswer: FunctionComponent<IQuestionAndAnswerProps> = ({
  activity,
  question,
  input,
  layout,
  setInput,
  previousTaskItemPath,
  isItemOnNonInitialChance,
  isItemAttempted,
  isItemSwapped,
  hideBookworkCodes,
  onScaleChange,
  currentTaskItem,
  submitAnswerResponse,
  firstChanceGapEvaluationsRef,
  placeholderMap,
  partRefs,
  isLastItem,
  isTaskComplete,
  currentTaskItemPath,
  nextTaskItemPath,
  retryTask,
  numSwapsUsed,
  submitError = false,
  submitAnswer,
}) => {
  const [hintVideo, setHintVideo] = useState<{ hintType: Hint; videoId: string } | undefined>(
    undefined,
  );
  const sendEvent = useAnalytics();
  const { enabled: isKeyboardMode } = useKeyboardMode();
  const { tutorialIsShowing } = useTutorialSpotlight();
  const firstEmptyInputRef = useRef<HTMLElement | null>(null);
  const featureFlags = useFeatureFlags();
  const multiPartMarkingEnabled =
    submitAnswerResponse?.gapEvaluations &&
    Object.keys(submitAnswerResponse.gapEvaluations).length > 0 &&
    featureFlags.getBooleanFlag('sparxweb2-enable-mpa', false);

  const hintGiven = !!submitAnswerResponse?.hints && submitAnswerResponse.status === 'ACTIVE';
  const hintsMap = useMemo(() => {
    if (!submitAnswerResponse?.hints || !placeholderMap) {
      return undefined;
    }
    const hintsMap: Dictionary<string, HintInfo> = {};
    for (const ref in submitAnswerResponse.hints) {
      const partHasInput = hasInputForRefsAnswerPart(ref, input, partRefs);
      const h = submitAnswerResponse.hints[ref];
      switch (h) {
        case Hint.SIMPLIFY_FRACTION:
          hintsMap[ref] = {
            inputPlaceholder: placeholderMap?.[ref],
            hintType: h,
            onReset: () => {
              setInput(resetInputForRefsAnswerPart(ref, input, partRefs));
            },
            partHasInput: !!partHasInput,
          };
          break;
        default:
        // do nothing
      }
    }
    return hintsMap;
  }, [input, partRefs, placeholderMap, setInput, submitAnswerResponse?.hints]);

  // If an answer has been submitted and no hint has been given, the activity has been answered and cannot
  // be reattempted
  const activityAnswered = submitAnswerResponse && !hintGiven;

  const keyboardEnterFunc = (ev: KeyboardEvent) => {
    if (tutorialIsShowing) {
      return;
    }
    if (submitAnswer !== undefined && (submitAnswerResponse === undefined || hintGiven)) {
      // prevent default when pressing enter to submit. This prevents the enter key from triggering
      // the onClick behavior of the last (if still focussed) button on the keypad
      ev.preventDefault();
      submitAnswer();
      // BOOL-3095 - page event removed to reduce CloudFlare traffic
      // sendEvent({ action: 'pressed enter to submit answer', category: 'lqd' });
    }
  };

  const content = (
    <>
      <KeyboardShortcuts enterFunc={keyboardEnterFunc} source="questionandanswer" />
      <MultiPartMarkingBanner
        isItemAttempted={isItemAttempted}
        isMultiPartMarkedQuestion={question.multiPartMarking}
      />
      <QuestionInfo
        bookworkCode={hideBookworkCodes ? undefined : question.bookworkCode}
        calculatorAllowed={GetCalculatorAllowed(layout)}
        showBanner={true}
        isItemAttempted={isItemAttempted}
        isItemOnNonInitialChance={isItemOnNonInitialChance}
        isItemSwapped={isItemSwapped}
        isCombinedQuestionAnswer={true}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.QuestionWrapper}
      >
        <SparxQuestion
          layout={layout.layout}
          input={input}
          setInput={setInput}
          mode="combined"
          fontSize="30"
          lineHeight="1.2em"
          centered={true}
          onScaleChange={onScaleChange}
          sendAnalyticEvent={(action, labels) =>
            sendEvent({ category: 'question', action, labels })
          }
          // From Answer component
          readOnly={!!activityAnswered}
          shuffleSeed={`${activity.activityIndex}`}
          keyboardMode={isKeyboardMode}
          firstEmptyInputRef={firstEmptyInputRef}
          gapEvaluations={multiPartMarkingEnabled ? submitAnswerResponse.gapEvaluations : undefined}
          questionMarkingMode="part"
          firstChanceGapEvaluationsRef={firstChanceGapEvaluationsRef}
          hintsMap={hintsMap}
          openHintVideo={setHintVideo}
        />
      </motion.div>
    </>
  );

  const { watchVideoButton, helpVideo } = useGetVideoElements(
    question.videoID,
    activity,
    currentTaskItem,
  );

  const bottomBarAndResult =
    submitAnswerResponse && !hintGiven ? (
      <Result
        submitAnswerResponse={submitAnswerResponse}
        isLastItem={isLastItem}
        isTaskComplete={isTaskComplete}
        currentTaskItemPath={currentTaskItemPath}
        nextTaskItemPath={nextTaskItemPath}
        retryTask={retryTask}
        isItemInSecondChance={isItemOnNonInitialChance}
        numSwapsUsed={numSwapsUsed}
        currentTaskItem={currentTaskItem}
      />
    ) : (
      <BottomBar>
        {/* <Button
          onClick={() => {
            // BOOL-3095 - page event removed to reduce CloudFlare traffic
            // sendEvent({ action: 'clicked back from answer', category: 'lqd' });
            goToQuestion();
          }}
          leftIcon={<ChevronLeft />}
        >
          Back
        </Button> */}
        {previousTaskItemPath ? (
          <Button
            as={Link}
            to={previousTaskItemPath}
            variant="outlined"
            className={styles.PreviousButton}
            // BOOL-3095 - page event removed to reduce CloudFlare traffic
            // onClick={() => {
            //   sendEvent({
            //     action: 'clicked previous',
            //     category: 'lqd',
            //   });
            // }}
          >
            <ChevronLeft />
            <HiddenAt as="span" breakpoint="sm">
              {' '}
              Previous
            </HiddenAt>
          </Button>
        ) : (
          <div />
        )}
        {watchVideoButton}
        <Popover.Root open={submitError}>
          <Popover.Anchor>
            <Tooltip
              content={'Enter the simplified fraction to continue.'}
              position={'top'}
              disabled={!hintGiven || submitAnswer !== undefined}
              onOpenChange={open => {
                if (open) {
                  sendEvent({
                    action: 'fraction hint submit answer tooltip shown',
                    category: 'lqd',
                  });
                }
              }}
            >
              <Button
                onClick={() => {
                  // BOOL-3095 - page event removed to reduce CloudFlare traffic
                  // sendEvent({ action: 'clicked submit answer', category: 'lqd' });
                  submitAnswer?.();
                }}
                onDisabledClick={() => {
                  if (firstEmptyInputRef.current) {
                    firstEmptyInputRef.current.scrollIntoView({
                      block: 'start',
                      behavior: 'smooth',
                    });
                  }
                  // BOOL-3095 - page event removed to reduce CloudFlare traffic
                  // sendEvent({
                  //   action: 'clicked disabled submit answer',
                  //   category: 'lqd',
                  //   labels: {
                  //     'answer fields': JSON.stringify(input),
                  //     ...disabledSubmitAnswerAnalytics,
                  //   },
                  // });
                }}
                isDisabled={submitAnswer === undefined}
                variant="contained"
              >
                Submit
                <HiddenAt as="span" breakpoint="sm">
                  {' '}
                  answer
                </HiddenAt>
              </Button>
            </Tooltip>
          </Popover.Anchor>
          <Popover.Portal>
            <Popover.Content className={styles.SubmitErrorContent}>
              <p className={styles.ErrorMessage}>
                <strong>Something went wrong.</strong>
                <span> Please make sure you have written down your answer and </span>
                <a className={styles.RefreshLink} href="">
                  refresh the page
                </a>
              </p>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </BottomBar>
    );

  return (
    <>
      <QuestionAnswerLayout content={content} bottomBar={bottomBarAndResult} />
      <OnboardingTaskVideoModal />
      {helpVideo}
      <HintVideoModal
        onOpenChange={open => {
          if (!open) {
            setHintVideo(undefined);
          }
        }}
        hintVideo={hintVideo}
      />
    </>
  );
};

const useGetVideoElements = (
  videoId: string,
  activity: Activity,
  currentTaskItem?: TaskItemCompletion,
) => {
  const urls = makeQuestionVideoUrls(videoId);
  const { mutate } = useActivityAction();
  const [watchID] = useState(uuid());

  const videoAction = (actionType: VideoAction_ActionType) => {
    mutate(
      buildVideoAction(activity, {
        videoID: videoId,
        watchID,
        actionType,
      }),
    );
  };

  // get the task, and check its status
  const { packageID, taskIndex, taskItemIndex } = useLQDPath();
  const { data: taskItems, isSuccess } = useTaskItems(packageID, taskIndex);

  let taskItemStatus: TaskItemStatus | undefined = undefined;
  if (taskItems && taskItemIndex && isSuccess) {
    taskItemStatus = taskItems[taskItemIndex - 1].status;
  }

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const toggleVideoModal = (open: boolean) => {
    setVideoModalOpen(open);
    videoAction(open ? VideoAction_ActionType.VIEW : VideoAction_ActionType.DISMISS);
  };

  const [nudgeOpen, setNudgeOpen] = useState(false);

  const { shouldShowVideoNudge } = useVideoNudgeState();

  const timeoutRef = useRef<number | undefined>(undefined);
  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentTaskItem && shouldShowVideoNudge(currentTaskItem)) {
      // timeout so that we don't show the nudge until the animation has completed
      timeoutRef.current = window.setTimeout(() => setNudgeOpen(true), 300);
    } else {
      setNudgeOpen(false);
    }
  }, [taskItemStatus, shouldShowVideoNudge, currentTaskItem, setNudgeOpen]);

  const watchVideoButton = videoId ? (
    <VideoNudgePopover
      open={nudgeOpen}
      setVideoModalOpen={toggleVideoModal}
      posterUrl={urls.posterUrl}
    >
      <Button
        onClick={() => toggleVideoModal(true)}
        leftIcon={<VideoIcon />}
        hideLeftIconAt="xs"
        // BOOL-3095 - page event removed to reduce CloudFlare traffic
        // onClick={() => sendEvent({ action: 'clicked watch video', category: 'lqd' })}
        onKeyDown={simulateClickOnEnter}
      >
        Watch video
      </Button>
    </VideoNudgePopover>
  ) : (
    <div />
  );

  const helpVideo = videoId && (
    <Dialog.Root onOpenChange={toggleVideoModal} open={videoModalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullWidth,
            dialogStyles.WithZIndex,
          )}
        >
          <Dialog.Title className={dialogStyles.DialogTitle}>Support video</Dialog.Title>
          <Dialog.Description className={dialogStyles.DialogSubtitle}>
            <span className={styles.TipBadge}>Tip!</span> Watching all of the video will help you
            get it right. Pause it if you need.
          </Dialog.Description>
          <div className={styles.VideoContainer}>
            <Video
              // BOOL-3035 - disable analytics for lqd video to reduce CloudFlare traffic
              disableAnalytics={true}
              videoUrl={urls.videoUrl}
              posterUrl={urls.posterUrl}
              subtitleUrl={urls.subtitleUrl}
              onPlay={() => videoAction(VideoAction_ActionType.PLAY)}
              onPause={() => videoAction(VideoAction_ActionType.PAUSE)}
            />
          </div>
          <div className={styles.VideoCloseButton}>
            <Button
              as={Dialog.Close}
              rightIcon={<TimesIcon />}
              // BOOL-3095 - page event removed to reduce CloudFlare traffic
              // onClick={() => sendEvent({ action: 'clicked close video', category: 'lqd' })}
              onKeyDown={simulateClickOnEnter}
            >
              Close video
            </Button>
          </div>
          <Dialog.Close asChild>
            <button
              className={classNames(dialogStyles.IconButton, styles.VideoCloseX)}
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
  return { watchVideoButton, helpVideo };
};

export const QuestionLoading = () => (
  <QuestionAnswerLayout
    content={
      <LoadingSpinnerWithAnalytics
        componentName="QuestionLoading"
        sendLongTimeLoadingEvent={true}
      />
    }
    bottomBar={<BottomBar />}
  />
);

import {
  TaskItemCompletion as TaskItem,
  TaskItemStatus as TaskItem_TaskItemStatus,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Cross, Flag, Skip, Tick } from '@sparx/sparx-design/icons';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import { useIsMutating } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTutorialSpotlight } from 'context/tutorialspotlight';
import { activityMutationKey } from 'queries/activity';
import { usePackageTasks } from 'queries/packages';
import { TutorialKey } from 'queries/tutorials';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isFTQQuizComplete, isFTQQuizSkipped } from 'utils/ftqtask';
import { makeTaskItemPath, makeTaskSummaryPath, useLQDPath } from 'utils/paths';
import { useFTQTutorialSteps } from 'views/fast-track-quiz/useFTQTutorialSteps';

import styles from './NavigationBar.module.css';

const getClass = (status: TaskItem_TaskItemStatus) => {
  switch (status) {
    case TaskItem_TaskItemStatus.DONE:
      return styles.Correct;
    case TaskItem_TaskItemStatus.SEEKHELP:
      return styles.SeekHelp;
    case TaskItem_TaskItemStatus.WRONG:
    case TaskItem_TaskItemStatus.WRONGWITHVIDEONUDGE: // TODO: support this
    case TaskItem_TaskItemStatus.WARNING: // TODO: what is this?
      return styles.Incorrect;
    case TaskItem_TaskItemStatus.BADGED:
      return styles.Badged;
    case TaskItem_TaskItemStatus.HIDDEN: // TODO: support this
    case TaskItem_TaskItemStatus.UNATTEMPTED:
    default:
      return styles.Unattempted;
  }
};

const getIcon = (status: TaskItem_TaskItemStatus) => {
  switch (status) {
    case TaskItem_TaskItemStatus.DONE:
      return <Tick variant="White" className={styles.QuestionNavigationIcon} />;
    case TaskItem_TaskItemStatus.SEEKHELP:
      return <Flag variant="Purple" className={styles.QuestionNavigationIcon} />;
    case TaskItem_TaskItemStatus.WRONG:
    case TaskItem_TaskItemStatus.WRONGWITHVIDEONUDGE: // TODO: support this
    case TaskItem_TaskItemStatus.WARNING: // TODO: what is this?
      return <Cross variant="Red" className={styles.QuestionNavigationIcon} />;
    case TaskItem_TaskItemStatus.BADGED:
      return <Skip className={styles.QuestionNavigationIcon} />;
    case TaskItem_TaskItemStatus.HIDDEN: // TODO: support this
    case TaskItem_TaskItemStatus.UNATTEMPTED:
    default:
      return undefined;
  }
};

interface INavigationBarProps {
  taskItems?: TaskItem[];
  packageID: string;
  taskIndex: number;
  taskItemIndex: number;
  isSummary: boolean;
  disabled?: boolean;
}

const getFTQTutorialKeyFromTaskStatus = (status: TaskItem_TaskItemStatus) => {
  switch (status) {
    case TaskItem_TaskItemStatus.DONE:
      return TutorialKey.FTQTaskItemCorrect;
    case TaskItem_TaskItemStatus.UNATTEMPTED:
      return TutorialKey.FTQTaskItemStillToDo;
    case TaskItem_TaskItemStatus.WRONG:
      return TutorialKey.FTQTaskItemIncorrect;
    case TaskItem_TaskItemStatus.BADGED:
      return TutorialKey.FTQTaskItemFastTracked;
    default:
      return undefined;
  }
};

export const NavigationBar = ({
  taskItems,
  packageID,
  taskIndex,
  taskItemIndex,
  isSummary,
  disabled = false,
}: INavigationBarProps) => {
  const { parentPath } = useLQDPath();

  const [selectedRef, setSelectedRef] = useState<HTMLElement | null>(null);
  useEffect(() => {
    selectedRef?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [selectedRef]);

  const { data: packageTasks } = usePackageTasks(packageID);
  const task = packageTasks?.find(t => t.taskIndex === taskIndex);

  // we prevent switching task items using the ui while an activity mutation is happening. This
  // prevents all sorts of weirdness around the user sending too many activity actions (causing
  // datastore contention), or actions on activities which are in the wrong state.
  const activityMutationHappening = useIsMutating({ mutationKey: activityMutationKey }) !== 0;

  const tutorialKeys = (taskItems ?? []).reduce<TutorialKey[]>((list, item) => {
    let newKey;
    switch (item.status) {
      case TaskItem_TaskItemStatus.DONE:
        newKey = TutorialKey.FTQTaskItemCorrect;
        break;
      case TaskItem_TaskItemStatus.WRONG:
        newKey = TutorialKey.FTQTaskItemIncorrect;
        break;
      case TaskItem_TaskItemStatus.UNATTEMPTED:
        newKey = TutorialKey.FTQTaskItemStillToDo;
        break;
      case TaskItem_TaskItemStatus.BADGED:
        newKey = TutorialKey.FTQTaskItemFastTracked;
        break;
    }
    if (newKey && !list.includes(newKey)) {
      list.push(newKey);
    }
    return list;
  }, []);

  const ftqTutorialSteps = useFTQTutorialSteps(tutorialKeys);
  const shouldShowFTQTutorials = task && !isFTQQuizSkipped(task) && isFTQQuizComplete(task);

  const { showTutorials, hideTutorials } = useTutorialSpotlight();
  useEffect(() => {
    showTutorials(ftqTutorialSteps, { disabled: !shouldShowFTQTutorials });
  }, [ftqTutorialSteps, shouldShowFTQTutorials, showTutorials]);
  useEffect(() => () => hideTutorials(), [hideTutorials]);

  return (
    <div className={styles.TaskItemsContainer}>
      {(taskItems || [])?.map((taskItem, i) => {
        const selected = taskItem.taskItemIndex === taskItemIndex;
        const text = taskItem.taskIndex + makeAlphabeticCode(i);
        const ftqTutorialKey = getFTQTutorialKeyFromTaskStatus(taskItem.status);

        return (
          <NavigationTab
            setRef={selected ? setSelectedRef : undefined}
            selected={selected}
            cls={getClass(taskItem.status)}
            link={makeTaskItemPath(
              parentPath,
              packageID,
              taskIndex,
              taskItems ? taskItems[i].taskItemIndex : 1,
            )}
            text={text}
            key={i}
            icon={getIcon(taskItem.status)}
            loading={activityMutationHappening}
            disabled={disabled || taskItem.status === TaskItem_TaskItemStatus.BADGED}
            ftqTutorialKey={ftqTutorialKey}
          />
        );
      })}
      <NavigationTab
        setRef={isSummary ? setSelectedRef : undefined}
        selected={isSummary}
        cls={styles.Summary}
        link={makeTaskSummaryPath(parentPath, packageID, taskIndex)}
        text="Summary"
        key={taskItems?.length || 0}
        loading={activityMutationHappening}
        disabled={disabled}
      />
    </div>
  );
};

interface NavigationTabProps {
  selected: boolean;
  setRef?: (ref: HTMLElement | null) => void;
  cls: string;
  link: string;
  text: string;
  icon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  ftqTutorialKey?: TutorialKey;
}

const NavigationTab = ({
  selected,
  setRef,
  cls,
  link,
  text,
  icon,
  loading,
  disabled = false,
  ftqTutorialKey,
}: NavigationTabProps) => {
  const location = useLocation();

  return (
    <Link
      data-tutorial-spotlight-key={ftqTutorialKey}
      className={classNames(styles.TaskItemLink, accessibilityStyles.FocusTarget, cls, {
        [styles.Selected]: !disabled && selected,
        [styles.Loading]: !disabled && loading,
        [styles.Disabled]: disabled,
      })}
      to={disabled || loading ? location : link}
      onClick={e => {
        if (disabled) {
          e.preventDefault();
          e.stopPropagation();
        }
        // BOOL-3095 - page event removed to reduce Cloudflare traffic
        // else {
        //   sendEvent({
        //     action: 'clicked navigation tab',
        //     category: 'lqd',
        //     labels: {
        //       text: text,
        //     },
        //   });
        // }
      }}
      aria-disabled={loading || disabled}
      tabIndex={0}
    >
      <div ref={setRef}>
        <span>{text}</span>
        {icon}
      </div>
    </Link>
  );
};

// this is a copy of the logic in swworker - we would ideally use the bookwork code from
// the swworker, but that is a property of the activity rather than the task item, so we only
// have access to the current task item's - we would have to start an activity for each task
// item just to get the bookwork code.
// We could potentially add it to the task, but this seems fine for now.
const makeAlphabeticCode = (n: number): string => {
  const lastDigit = n % 26;
  const remaining = Math.floor(n / 26);

  if (remaining >= 1) {
    return makeAlphabeticCode(remaining - 1) + String.fromCharCode(lastDigit + 65);
  }
  return String.fromCharCode(lastDigit + 65);
};

import { CheckIcon, ChevronDownIcon, ChevronUpIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { LearningPathSpec } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { ProgressBadge, ProgressBadgeScore } from '@sparx/sparx-design/components';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import selectStyles from '@sparx/sparx-design/shared-styles/Select.module.css';
import classNames from 'classnames';

import styles from './LevelSelect.module.css';

interface ILevelSelectProps {
  learningPathSpecs: LearningPathSpec[];
  enabledLearningPathNames?: string[];
  learningPathProgresses?: Map<string, ProgressBadgeScore>;
  contentAvailable: boolean;
  selectedLearningPathSpec?: LearningPathSpec;
  defaultLearningPathSpec?: LearningPathSpec;
  selectLevel: (level: LearningPathSpec) => void;
  triggerClassName?: string;
}

/**
 * LevelSelect displays a clickable Level Badge which shows a Level Select dialog. Here the student
 * is presented with all levels in their curriculum and can select a new default learning path.
 * @param learningPathSpecs The learning paths in the current curriculum, for which we'll show
 * options in the dialog.
 * @param enabledLearningPathNames The learning paths in the curriculum for which we have content
 * for the current topic. Any paths that don't have content for the topic will display as disabled.
 * If this isn't provided at all then we consider all learning paths to be enabled.
 * @param learningPathProgresses A map of learning path names to ProgressBadgeScores. If this is not
 * provided then we don't show ProgressBadges for the learning paths. If it is provided but there is
 * not an entry for the given topic then the student hasn't started it yet, so show the relevant
 * badge.
 * @param contentAvailable Whether content at the selected level is available. If not, show text
 * rather than progress.
 * @param selectedLearningPathSpec The level selected.
 * @param defaultLearningPathSpec The student's default level - this is highlighted with a border in the dropdown if
 * different from the current level.
 * @param selectLevel Function to call when a level is selected.
 * @param triggerClassName Optional class name to apply to the trigger element.
 */
export const LevelSelect = ({
  learningPathSpecs,
  enabledLearningPathNames,
  learningPathProgresses,
  contentAvailable,
  selectedLearningPathSpec,
  defaultLearningPathSpec,
  selectLevel,
  triggerClassName,
}: ILevelSelectProps) => {
  const progressScore = learningPathProgresses?.get(selectedLearningPathSpec?.name || '');
  return (
    <Select.Root
      value={selectedLearningPathSpec?.name}
      onValueChange={(value: string) => {
        const selectedLevel = learningPathSpecs.find(lps => lps.name === value);
        if (selectedLevel) {
          selectLevel(selectedLevel);
        }
      }}
    >
      <Select.Trigger
        className={classNames(
          selectStyles.SelectTrigger,
          accessibilityStyles.FocusTarget,
          triggerClassName,
        )}
        aria-label="level"
      >
        <Select.Value asChild={true} placeholder="Default level">
          <div className={styles.LevelBadgeContainer}>
            <LevelBadge level={selectedLearningPathSpec?.displayName} />
            {contentAvailable
              ? learningPathProgresses && <ProgressBadge variant={progressScore} />
              : 'unavailable'}
          </div>
        </Select.Value>
        <Select.Icon>
          <TriangleDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className={classNames(selectStyles.SelectContent, styles.LevelSelectMenu)}
          position="popper"
          align="end"
        >
          <Select.ScrollUpButton className="SelectScrollButton">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {learningPathSpecs.map(lps => {
              const progressScore = learningPathProgresses?.get(lps.name);
              const levelEnabled = enabledLearningPathNames
                ? enabledLearningPathNames.includes(lps.name)
                : true;
              return (
                lps && (
                  <Select.Item
                    className={classNames(
                      selectStyles.SelectItem,
                      !levelEnabled && styles.IndependentLearningLevelChoiceUnavailable,
                    )}
                    value={lps.name}
                    key={lps.name}
                    asChild={true}
                    disabled={!levelEnabled}
                  >
                    <div
                      className={classNames(styles.LevelSelectItem, {
                        [styles.SelectItemDefault]: lps.name === defaultLearningPathSpec?.name,
                      })}
                    >
                      <LevelBadge level={lps.displayName} />
                      {learningPathProgresses && <ProgressBadge variant={progressScore} />}
                      <span className={styles.LevelSelectDescription}>
                        <Select.ItemText>{lps.studentDescription}</Select.ItemText>
                      </span>
                      <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
                        <CheckIcon />
                      </Select.ItemIndicator>
                    </div>
                  </Select.Item>
                )
              );
            })}
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export const LevelBadge = ({ level }: { level?: string }) =>
  level !== undefined ? (
    <div
      className={classNames(styles.LevelBadge, {
        [styles.LevelStyle1]: level === '1' || level === 'A',
        [styles.LevelStyle2]: level === '2' || level === 'B',
        [styles.LevelStyle3]: level === '3' || level === 'C',
        [styles.LevelStyle4]: level === '4' || level === 'D',
        [styles.LevelStyle5]: level === '5' || level === 'E',
      })}
    >
      Level {level}
    </div>
  ) : (
    <></>
  );

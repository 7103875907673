import {
  Assessment,
  AssessmentGroup,
  FixUpTaskStatus,
  GetAssessmentSummaryResponse_FixUpTask,
  GetAssessmentSummaryResponse_RevisionTask,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Chip } from '@sparx/sparx-design/components';
import { ChipColourVariant, ChipStyleVariant } from '@sparx/sparx-design/components/Chip';
import { ChevronRight } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { ListCard, ListCardProps } from 'components/list-card/ListCard';
import { StatusCard, StatusCardStatus } from 'components/status-card/StatusCard';
import fixUpIcon from 'images/assessments/icon-desat-assessment.png';
import revisionIcon from 'images/assessments/icon-desat-revision.png';
import { useAssessmentPackages } from 'queries/assessments';
import { usePackageTasks } from 'queries/packages';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { getAssessmentStatistics } from 'utils/assessment';
import { getPkgOrTaskCompletionPercentage } from 'utils/package';

import styles from './AssessmentCard.module.css';

export interface AssessmentsOverviewCardProps {
  // The assessment the fix up task or revision task is for
  assessment: Assessment;
  // The assessment group, if the card is for a revision that has been created for an assessment group, rather than an
  // individual assessment
  assessmentGroup?: AssessmentGroup;
  // The fix up task for the assessment, if there is one
  fixUpTask?: GetAssessmentSummaryResponse_FixUpTask;
  // The revision task for the assessment, if there is one
  revisionTask?: GetAssessmentSummaryResponse_RevisionTask;
}

const getAssessmentStatus = (
  revisionPackage?: PackageCompletion,
  fixUpPackage?: PackageCompletion,
): ListCardProps['status'] => {
  if (!revisionPackage && !fixUpPackage) {
    return 'no-action';
  }

  const revisionCompletion = revisionPackage
    ? getPkgOrTaskCompletionPercentage(revisionPackage)
    : 0;
  const fixUpCompletion = fixUpPackage ? getPkgOrTaskCompletionPercentage(fixUpPackage) : 0;
  const combinedCompletion = revisionCompletion + fixUpCompletion;

  const targetFullCompletion = [revisionPackage, fixUpPackage].filter(p => !!p).length * 100;

  if (combinedCompletion === targetFullCompletion) {
    return 'complete';
  }

  if (combinedCompletion > 0) {
    return 'in-progress';
  }

  return 'not-started';
};

export const AssessmentCard = ({
  assessment,
  fixUpTask,
  revisionTask,
  assessmentGroup,
}: AssessmentsOverviewCardProps) => {
  const { data } = useAssessmentPackages(assessment.name);
  const revisionPackage = data?.packages.find(p => p.packageType === 'assessment-revision');
  const fixUpPackage = data?.packages.find(p => p.packageType === 'assessment-fixup');

  const assessmentStatus = getAssessmentStatus(revisionPackage, fixUpPackage);

  return (
    <section className={styles.AssessmentCardContainer}>
      <ListCard status={assessmentStatus}>
        <div className={styles.AssessmentCard}>
          <h3 className={styles.Title}>{assessmentGroup?.displayName ?? assessment.displayName}</h3>
          <div className={styles.Content}>
            {revisionTask && <RevisionCard assessment={assessment} pkg={revisionPackage} />}
            {fixUpTask && (
              <FixUpCard assessment={assessment} fixUpTask={fixUpTask} pkg={fixUpPackage} />
            )}
          </div>
        </div>
      </ListCard>
    </section>
  );
};

const PackageCard = ({
  title,
  chipText,
  linkTo,
  status,
  icon,
  children,
}: PropsWithChildren<{
  title: string;
  chipText: string;
  linkTo: string;
  status: StatusCardStatus;
  icon: string;
}>) => {
  const chipColourMap: Record<string, ChipColourVariant> = {
    complete: 'Complete',
    'in-progress': 'InProgress',
    'not-started': 'Interactable',
    locked: 'Locked',
  };

  const isLocked = status === 'locked';

  const chipStyle: ChipStyleVariant = isLocked ? 'Filled' : 'Outlined';

  const card = (
    <StatusCard
      className={classNames(styles.PackageCard, isLocked && styles.Locked)}
      status={status}
      icon={icon}
    >
      <div className={styles.Header}>
        <p className={styles.Title}>{title}</p>
        <Chip
          className={classNames(styles.Chip, isLocked && styles.ChipLocked)}
          size="lg"
          styleVariant={chipStyle}
          colourVariant={chipColourMap[status]}
        >
          {isLocked ? 'Locked' : chipText}
          {!isLocked && <ChevronRight className={styles.ChipIcon} />}
        </Chip>
      </div>
      <div>{children}</div>
    </StatusCard>
  );

  return status === 'locked' ? (
    <div className={styles.PackageCardContainer}>{card}</div>
  ) : (
    <Link className={classNames(styles.PackageCardContainer, styles.Clickable)} to={linkTo}>
      {card}
    </Link>
  );
};

const getPackageCardStatus = (pkg?: PackageCompletion) => {
  if (!pkg) {
    return 'locked';
  }
  const completion = getPkgOrTaskCompletionPercentage(pkg);
  return completion === 0 ? 'not-started' : completion === 100 ? 'complete' : 'in-progress';
};

const RevisionCard = ({ assessment, pkg }: { assessment: Assessment; pkg?: PackageCompletion }) => {
  const { data: tasks } = usePackageTasks(pkg?.packageID ?? '');

  const completed = (tasks ?? []).reduce(
    (acc, task) => {
      const isProblemSolving = task.title.toLowerCase().startsWith('mixed');
      return {
        fluency: acc.fluency + (!isProblemSolving ? task.completion?.progress['C'] ?? 0 : 0),
        problemSolving:
          acc.problemSolving + (isProblemSolving ? task.completion?.progress['C'] ?? 0 : 0),
      };
    },
    { fluency: 0, problemSolving: 0 },
  );

  return (
    <PackageCard
      title="Revision"
      status={getPackageCardStatus(pkg)}
      chipText="Do some revision"
      linkTo={`/${assessment.name}/revision`}
      icon={revisionIcon}
    >
      <div className={styles.StatsContainer}>
        <p className={styles.RevisionStat}>{completed.fluency}</p>
        <p className={styles.RevisionStat}>{completed.problemSolving}</p>
        <p>Fluency questions completed</p>
        <p>Problem solving questions completed</p>
      </div>
    </PackageCard>
  );
};

const FixUpCard = ({
  assessment,
  fixUpTask,
  pkg,
}: {
  assessment: Assessment;
  fixUpTask?: GetAssessmentSummaryResponse_FixUpTask;
  pkg?: PackageCompletion;
}) => {
  const totalTaskItems = pkg?.numTaskItems ?? '-';
  const completedTaskItems = pkg?.numTaskItemsDone ?? '-';

  const stats = fixUpTask?.studentAssessment
    ? getAssessmentStatistics({ assessment, studentAssessment: fixUpTask.studentAssessment })
    : undefined;

  const marksAvailable =
    stats?.assessmentMarks.totalMarks ??
    assessment.questions.reduce((acc, q) => acc + q.availableMarks, 0);

  const studentGotFullMarks = stats?.assessmentMarks.studentMarks === marksAvailable;

  const status = studentGotFullMarks ? 'complete' : getPackageCardStatus(pkg);
  const buttonText = studentGotFullMarks ? 'View assessment' : 'Fluency Fix Up';
  const link = `/${assessment.name}`;

  return (
    <PackageCard
      title="Assessment"
      status={status}
      chipText={buttonText}
      linkTo={link}
      icon={fixUpIcon}
    >
      <div className={styles.StatsContainer}>
        <p className={styles.FixUpStat}>
          <span>{stats?.assessmentMarks.studentMarks ?? '-'}</span>
          <span>/{marksAvailable}</span>
        </p>
        {fixUpTask?.status === FixUpTaskStatus.FULL_MARKS ? (
          <p className={styles.Message}>Nothing to Fix up!</p>
        ) : (
          <p className={styles.FixUpStat}>
            <span>{completedTaskItems}</span>
            <span>/{totalTaskItems}</span>
          </p>
        )}

        <p>Overall mark</p>
        <p>Fluency Fix up</p>
      </div>
    </PackageCard>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/swworker/v1/worker.proto" (package "sparx.swworker.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Sparxweb } from './worker';
import type { SendIndependentLearningTablesAnswersResponse } from './worker';
import type { SendIndependentLearningTablesAnswersRequest } from './worker';
import type { SwapOutTaskItemResponse } from './worker';
import type { SwapOutTaskItemRequest } from './worker';
import type { UpdateStudentLabelsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { UpdateStudentLabelsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { SubmitStudentSentimentFeedbackResponse } from '../../interaction/feedback/v1/feedback';
import type { SubmitStudentSentimentFeedbackRequest } from '../../interaction/feedback/v1/feedback';
import type { StudentRewards } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetStudentRewardsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetActivityFeedResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetActivityFeedRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetTetherResponse } from './worker';
import type { GetTetherRequest } from './worker';
import type { Ack } from '../../../../sparxweb/swmsg/sparxweb';
import type { UpdateTutorialsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetTrainingPackagesRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { AbortQuizResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { AbortQuizRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetQuizResultsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetQuizResultsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { SubmitQuizAnswersResponse } from './worker';
import type { SubmitQuizAnswersRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { SetLeagueAvatarResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { SetLeagueAvatarRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetLeagueMedalsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetLeagueMedalsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetLeagueAvatarResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetLeagueAvatarRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { SaveLeagueGameScoreResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { SaveLeagueGameScoreRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetLeagueResultsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetLeagueResultsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { ActivityActionResponse } from './worker';
import type { ActivityAction } from '../../../../sparxweb/swmsg/sparxweb';
import type { Activity } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetActivityRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { PackageData } from '../../packages/v1/spxpkg';
import type { PackageDataRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SessionDetailsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { SessionDetailsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.swworker.v1.Sparxweb
 */
export interface ISparxwebClient {
  /**
   * @generated from protobuf rpc: GetSessionDetails(sparxweb.SessionDetailsRequest) returns (sparxweb.SessionDetailsResponse);
   */
  getSessionDetails(
    input: SessionDetailsRequest,
    options?: RpcOptions,
  ): UnaryCall<SessionDetailsRequest, SessionDetailsResponse>;
  /**
   * @generated from protobuf rpc: GetPackageData(sparxweb.PackageDataRequest) returns (sparx.packages.v1.PackageData);
   */
  getPackageData(
    input: PackageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<PackageDataRequest, PackageData>;
  /**
   * @generated from protobuf rpc: GetActivity(sparxweb.GetActivityRequest) returns (sparxweb.Activity);
   */
  getActivity(
    input: GetActivityRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityRequest, Activity>;
  /**
   * @generated from protobuf rpc: ActivityAction(sparxweb.ActivityAction) returns (sparx.swworker.v1.ActivityActionResponse);
   */
  activityAction(
    input: ActivityAction,
    options?: RpcOptions,
  ): UnaryCall<ActivityAction, ActivityActionResponse>;
  /**
   * League table
   *
   * @generated from protobuf rpc: GetLeagueResults(sparxweb.GetLeagueResultsRequest) returns (sparxweb.GetLeagueResultsResponse);
   */
  getLeagueResults(
    input: GetLeagueResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeagueResultsRequest, GetLeagueResultsResponse>;
  /**
   * @generated from protobuf rpc: SaveLeagueGameScore(sparxweb.SaveLeagueGameScoreRequest) returns (sparxweb.SaveLeagueGameScoreResponse);
   */
  saveLeagueGameScore(
    input: SaveLeagueGameScoreRequest,
    options?: RpcOptions,
  ): UnaryCall<SaveLeagueGameScoreRequest, SaveLeagueGameScoreResponse>;
  /**
   * @generated from protobuf rpc: GetLeagueAvatar(sparxweb.GetLeagueAvatarRequest) returns (sparxweb.GetLeagueAvatarResponse);
   */
  getLeagueAvatar(
    input: GetLeagueAvatarRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeagueAvatarRequest, GetLeagueAvatarResponse>;
  /**
   * @generated from protobuf rpc: GetLeagueMedals(sparxweb.GetLeagueMedalsRequest) returns (sparxweb.GetLeagueMedalsResponse);
   */
  getLeagueMedals(
    input: GetLeagueMedalsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeagueMedalsRequest, GetLeagueMedalsResponse>;
  /**
   * @generated from protobuf rpc: SetLeagueAvatar(sparxweb.SetLeagueAvatarRequest) returns (sparxweb.SetLeagueAvatarResponse);
   */
  setLeagueAvatar(
    input: SetLeagueAvatarRequest,
    options?: RpcOptions,
  ): UnaryCall<SetLeagueAvatarRequest, SetLeagueAvatarResponse>;
  /**
   * Quiz
   *
   * @generated from protobuf rpc: SubmitQuizAnswers(sparxweb.SubmitQuizAnswersRequest) returns (sparx.swworker.v1.SubmitQuizAnswersResponse);
   */
  submitQuizAnswers(
    input: SubmitQuizAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<SubmitQuizAnswersRequest, SubmitQuizAnswersResponse>;
  /**
   * @generated from protobuf rpc: GetQuizResults(sparxweb.GetQuizResultsRequest) returns (sparxweb.GetQuizResultsResponse);
   */
  getQuizResults(
    input: GetQuizResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetQuizResultsRequest, GetQuizResultsResponse>;
  /**
   * @generated from protobuf rpc: AbortQuiz(sparxweb.AbortQuizRequest) returns (sparxweb.AbortQuizResponse);
   */
  abortQuiz(
    input: AbortQuizRequest,
    options?: RpcOptions,
  ): UnaryCall<AbortQuizRequest, AbortQuizResponse>;
  /**
   * Training
   *
   * @generated from protobuf rpc: GetTrainingPackages(sparxweb.GetTrainingPackagesRequest) returns (sparx.packages.v1.PackageData);
   */
  getTrainingPackages(
    input: GetTrainingPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTrainingPackagesRequest, PackageData>;
  /**
   * Tutorals
   *
   * @generated from protobuf rpc: UpdateTutorials(sparxweb.UpdateTutorialsRequest) returns (sparxweb.Ack);
   */
  updateTutorials(
    input: UpdateTutorialsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateTutorialsRequest, Ack>;
  /**
   * Misc
   *
   * @generated from protobuf rpc: GetTether(sparx.swworker.v1.GetTetherRequest) returns (sparx.swworker.v1.GetTetherResponse);
   */
  getTether(
    input: GetTetherRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTetherRequest, GetTetherResponse>;
  /**
   * @generated from protobuf rpc: GetActivityFeed(sparxweb.GetActivityFeedRequest) returns (sparxweb.GetActivityFeedResponse);
   */
  getActivityFeed(
    input: GetActivityFeedRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityFeedRequest, GetActivityFeedResponse>;
  /**
   * @generated from protobuf rpc: GetStudentRewards(sparxweb.GetStudentRewardsRequest) returns (sparxweb.StudentRewards);
   */
  getStudentRewards(
    input: GetStudentRewardsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentRewardsRequest, StudentRewards>;
  /**
   * @generated from protobuf rpc: SubmitStudentSentimentFeedback(sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackResponse);
   */
  submitStudentSentimentFeedback(
    input: SubmitStudentSentimentFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitStudentSentimentFeedbackRequest,
    SubmitStudentSentimentFeedbackResponse
  >;
  /**
   * @generated from protobuf rpc: UpdateStudentLabels(sparxweb.UpdateStudentLabelsRequest) returns (sparxweb.UpdateStudentLabelsResponse);
   */
  updateStudentLabels(
    input: UpdateStudentLabelsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStudentLabelsRequest, UpdateStudentLabelsResponse>;
  /**
   * SwapOutTaskItem swaps out the specified task item for a new easier one.
   *
   * @generated from protobuf rpc: SwapOutTaskItem(sparx.swworker.v1.SwapOutTaskItemRequest) returns (sparx.swworker.v1.SwapOutTaskItemResponse);
   */
  swapOutTaskItem(
    input: SwapOutTaskItemRequest,
    options?: RpcOptions,
  ): UnaryCall<SwapOutTaskItemRequest, SwapOutTaskItemResponse>;
  /**
   * SendIndependentLearningTablesAnswers sends student answers for times tables done in Independent Learning to pubsub
   * to be added to BigQuery by msgelephant and the recall answer store by recallanswercollector
   *
   * @generated from protobuf rpc: SendIndependentLearningTablesAnswers(sparx.swworker.v1.SendIndependentLearningTablesAnswersRequest) returns (sparx.swworker.v1.SendIndependentLearningTablesAnswersResponse);
   */
  sendIndependentLearningTablesAnswers(
    input: SendIndependentLearningTablesAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SendIndependentLearningTablesAnswersRequest,
    SendIndependentLearningTablesAnswersResponse
  >;
}
/**
 * @generated from protobuf service sparx.swworker.v1.Sparxweb
 */
export class SparxwebClient implements ISparxwebClient, ServiceInfo {
  typeName = Sparxweb.typeName;
  methods = Sparxweb.methods;
  options = Sparxweb.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSessionDetails(sparxweb.SessionDetailsRequest) returns (sparxweb.SessionDetailsResponse);
   */
  getSessionDetails(
    input: SessionDetailsRequest,
    options?: RpcOptions,
  ): UnaryCall<SessionDetailsRequest, SessionDetailsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SessionDetailsRequest, SessionDetailsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetPackageData(sparxweb.PackageDataRequest) returns (sparx.packages.v1.PackageData);
   */
  getPackageData(
    input: PackageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<PackageDataRequest, PackageData> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<PackageDataRequest, PackageData>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetActivity(sparxweb.GetActivityRequest) returns (sparxweb.Activity);
   */
  getActivity(
    input: GetActivityRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityRequest, Activity> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetActivityRequest, Activity>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ActivityAction(sparxweb.ActivityAction) returns (sparx.swworker.v1.ActivityActionResponse);
   */
  activityAction(
    input: ActivityAction,
    options?: RpcOptions,
  ): UnaryCall<ActivityAction, ActivityActionResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ActivityAction, ActivityActionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * League table
   *
   * @generated from protobuf rpc: GetLeagueResults(sparxweb.GetLeagueResultsRequest) returns (sparxweb.GetLeagueResultsResponse);
   */
  getLeagueResults(
    input: GetLeagueResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeagueResultsRequest, GetLeagueResultsResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetLeagueResultsRequest, GetLeagueResultsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SaveLeagueGameScore(sparxweb.SaveLeagueGameScoreRequest) returns (sparxweb.SaveLeagueGameScoreResponse);
   */
  saveLeagueGameScore(
    input: SaveLeagueGameScoreRequest,
    options?: RpcOptions,
  ): UnaryCall<SaveLeagueGameScoreRequest, SaveLeagueGameScoreResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SaveLeagueGameScoreRequest,
      SaveLeagueGameScoreResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetLeagueAvatar(sparxweb.GetLeagueAvatarRequest) returns (sparxweb.GetLeagueAvatarResponse);
   */
  getLeagueAvatar(
    input: GetLeagueAvatarRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeagueAvatarRequest, GetLeagueAvatarResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetLeagueAvatarRequest, GetLeagueAvatarResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetLeagueMedals(sparxweb.GetLeagueMedalsRequest) returns (sparxweb.GetLeagueMedalsResponse);
   */
  getLeagueMedals(
    input: GetLeagueMedalsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeagueMedalsRequest, GetLeagueMedalsResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetLeagueMedalsRequest, GetLeagueMedalsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SetLeagueAvatar(sparxweb.SetLeagueAvatarRequest) returns (sparxweb.SetLeagueAvatarResponse);
   */
  setLeagueAvatar(
    input: SetLeagueAvatarRequest,
    options?: RpcOptions,
  ): UnaryCall<SetLeagueAvatarRequest, SetLeagueAvatarResponse> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SetLeagueAvatarRequest, SetLeagueAvatarResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Quiz
   *
   * @generated from protobuf rpc: SubmitQuizAnswers(sparxweb.SubmitQuizAnswersRequest) returns (sparx.swworker.v1.SubmitQuizAnswersResponse);
   */
  submitQuizAnswers(
    input: SubmitQuizAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<SubmitQuizAnswersRequest, SubmitQuizAnswersResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SubmitQuizAnswersRequest, SubmitQuizAnswersResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetQuizResults(sparxweb.GetQuizResultsRequest) returns (sparxweb.GetQuizResultsResponse);
   */
  getQuizResults(
    input: GetQuizResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetQuizResultsRequest, GetQuizResultsResponse> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetQuizResultsRequest, GetQuizResultsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: AbortQuiz(sparxweb.AbortQuizRequest) returns (sparxweb.AbortQuizResponse);
   */
  abortQuiz(
    input: AbortQuizRequest,
    options?: RpcOptions,
  ): UnaryCall<AbortQuizRequest, AbortQuizResponse> {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AbortQuizRequest, AbortQuizResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Training
   *
   * @generated from protobuf rpc: GetTrainingPackages(sparxweb.GetTrainingPackagesRequest) returns (sparx.packages.v1.PackageData);
   */
  getTrainingPackages(
    input: GetTrainingPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTrainingPackagesRequest, PackageData> {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetTrainingPackagesRequest, PackageData>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Tutorals
   *
   * @generated from protobuf rpc: UpdateTutorials(sparxweb.UpdateTutorialsRequest) returns (sparxweb.Ack);
   */
  updateTutorials(
    input: UpdateTutorialsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateTutorialsRequest, Ack> {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateTutorialsRequest, Ack>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Misc
   *
   * @generated from protobuf rpc: GetTether(sparx.swworker.v1.GetTetherRequest) returns (sparx.swworker.v1.GetTetherResponse);
   */
  getTether(
    input: GetTetherRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTetherRequest, GetTetherResponse> {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetTetherRequest, GetTetherResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetActivityFeed(sparxweb.GetActivityFeedRequest) returns (sparxweb.GetActivityFeedResponse);
   */
  getActivityFeed(
    input: GetActivityFeedRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityFeedRequest, GetActivityFeedResponse> {
    const method = this.methods[15],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetActivityFeedRequest, GetActivityFeedResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetStudentRewards(sparxweb.GetStudentRewardsRequest) returns (sparxweb.StudentRewards);
   */
  getStudentRewards(
    input: GetStudentRewardsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentRewardsRequest, StudentRewards> {
    const method = this.methods[16],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentRewardsRequest, StudentRewards>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SubmitStudentSentimentFeedback(sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackRequest) returns (sparx.interaction.feedback.v1.SubmitStudentSentimentFeedbackResponse);
   */
  submitStudentSentimentFeedback(
    input: SubmitStudentSentimentFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitStudentSentimentFeedbackRequest,
    SubmitStudentSentimentFeedbackResponse
  > {
    const method = this.methods[17],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitStudentSentimentFeedbackRequest,
      SubmitStudentSentimentFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateStudentLabels(sparxweb.UpdateStudentLabelsRequest) returns (sparxweb.UpdateStudentLabelsResponse);
   */
  updateStudentLabels(
    input: UpdateStudentLabelsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStudentLabelsRequest, UpdateStudentLabelsResponse> {
    const method = this.methods[18],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateStudentLabelsRequest,
      UpdateStudentLabelsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * SwapOutTaskItem swaps out the specified task item for a new easier one.
   *
   * @generated from protobuf rpc: SwapOutTaskItem(sparx.swworker.v1.SwapOutTaskItemRequest) returns (sparx.swworker.v1.SwapOutTaskItemResponse);
   */
  swapOutTaskItem(
    input: SwapOutTaskItemRequest,
    options?: RpcOptions,
  ): UnaryCall<SwapOutTaskItemRequest, SwapOutTaskItemResponse> {
    const method = this.methods[19],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SwapOutTaskItemRequest, SwapOutTaskItemResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * SendIndependentLearningTablesAnswers sends student answers for times tables done in Independent Learning to pubsub
   * to be added to BigQuery by msgelephant and the recall answer store by recallanswercollector
   *
   * @generated from protobuf rpc: SendIndependentLearningTablesAnswers(sparx.swworker.v1.SendIndependentLearningTablesAnswersRequest) returns (sparx.swworker.v1.SendIndependentLearningTablesAnswersResponse);
   */
  sendIndependentLearningTablesAnswers(
    input: SendIndependentLearningTablesAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SendIndependentLearningTablesAnswersRequest,
    SendIndependentLearningTablesAnswersResponse
  > {
    const method = this.methods[20],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SendIndependentLearningTablesAnswersRequest,
      SendIndependentLearningTablesAnswersResponse
    >('unary', this._transport, method, opt, input);
  }
}

import classNames from 'classnames';
import { FunctionComponent } from 'react';

import gameButtonStyles from '../game-button-grid/GameButtonGrid.module.css';
import { GameSelectButton } from '../game-select-button/game-select-button';
import hundredClubStyles from '../hundred-club-access-banner/HundredClubAccessBanner.module.css';
import styles from './MultiplayerGameBanner.module.css';

/*
    The area containing the button to access the multiplayer game.
    It is surrounded by a border, and the description text next to the button
    will tell the player either:
      - When the game will be accessible (if it will be available in future)
      - When the game will be accessible until (if it is available now)
      - That the game is no longer accessible (if no longer available)
    Availability dates are set with the feature flag `discovery-multiplayer-game-available-dates`
 */

export interface IMultiplayerGameBannerProps {
  allowAccess: boolean;
  supportingText: string;
  onClickGameButton: () => void;
}

export const MultiplayerGameBanner: FunctionComponent<IMultiplayerGameBannerProps> = ({
  allowAccess,
  supportingText,
  onClickGameButton,
}) => {
  return (
    <div
      className={classNames(
        styles.MultiplayerGameBoundingBox,
        !allowAccess && styles.LockedBoundingBox,
      )}
    >
      <div className={classNames(hundredClubStyles.HundredClubAccessBanner)}>
        <div className={classNames(!allowAccess && gameButtonStyles.LockedButton)}>
          <GameSelectButton
            onSelect={onClickGameButton}
            gameId={'MultiplayerTestGame'}
            enabled={allowAccess}
            tokenCount={0}
            targetCount={0}
            isSeleniumModeOn={false}
            usePrimaryUnlockRates={false}
          />
        </div>

        <div className={hundredClubStyles.HundredClubSupportingText}>
          <div className={styles.MultiplayerInteriorBox}>{supportingText}</div>
        </div>
      </div>
    </div>
  );
};

import { useEffect } from 'react';

/**
 * Simple hook which sets up mousedown and touchstart event listeners which
 * will call the provided handler. It will also manage removing the event
 * listeners when the component is unmounted or the handler changes.
 * @param handler callback on a click event
 */
export const useClickHandler = (handler: (evt: MouseEvent | TouchEvent) => void) => {
  useEffect(() => {
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [handler]);
};

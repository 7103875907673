import classNames from 'classnames';
import { useGetStudentRewards } from 'queries/studentrewards';
import { useMemo } from 'react';

import { useGetLevelProgressPercentage } from '../rewardsUtils';
import {
  Level_5_off,
  Level_5_on,
  Level_25_off,
  Level_25_on,
  Level_100_off,
  Level_100_on,
  XPLevelTickOff,
  XPLevelTickOn,
} from './icons';
import styles from './MilestoneProgressBar.module.css';

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
};

export const MilestoneProgressBar = () => {
  const { data } = useGetStudentRewards({ suspense: true });

  const levelPercent = useGetLevelProgressPercentage();

  const currentLevel = data?.currentXPLevel?.level || 0;
  const lowerLevel = currentLevel - (currentLevel % 5);
  const upperLevel = lowerLevel + 5;
  const showPreProgressBar = lowerLevel !== 0;

  const templateLevelArray = Array.from({ length: 6 }, (_, i) => i + lowerLevel);

  const levelsTimeEarnedArray = data?.xpLevels?.map(level => level.seconds) || [];
  const lastLevelUpDateSeconds =
    levelsTimeEarnedArray.length > 0 ? levelsTimeEarnedArray[levelsTimeEarnedArray.length - 1] : 0;

  const lastLevelUpDate = new Date(lastLevelUpDateSeconds * 1000).toLocaleDateString(
    'en-GB',
    dateOptions,
  );
  const lastLevelUpDateText = `You reached level ${currentLevel} on ${lastLevelUpDate}`;

  return (
    <div
      className={classNames({
        [styles.MilestoneBarContainer]: true,
      })}
    >
      <div className={styles.MilestoneBar}>
        {showPreProgressBar && <ProgressBar percentageComplete={100} halfWidth />}
        {templateLevelArray.map(l => {
          const percentageComplete = l === currentLevel ? levelPercent : l < currentLevel ? 100 : 0;
          return (
            <LevelBadgeAndProgressBar
              badgeLevel={l}
              isUpperMilestoneLevel={l === upperLevel}
              isLowerMilestoneLevel={l === lowerLevel}
              usersCurrentLevel={currentLevel}
              percentageComplete={percentageComplete}
              key={l}
            />
          );
        })}
      </div>
      {lastLevelUpDateSeconds > 0 && (
        <div className={styles.RecentLevelUpDateContainer}>
          <span>{lastLevelUpDateText}</span>
        </div>
      )}
    </div>
  );
};

const LevelBadgeAndProgressBar = ({
  badgeLevel,
  isUpperMilestoneLevel,
  isLowerMilestoneLevel,
  usersCurrentLevel,
  percentageComplete,
}: {
  badgeLevel: number;
  isUpperMilestoneLevel: boolean;
  isLowerMilestoneLevel: boolean;
  usersCurrentLevel: number;
  percentageComplete: number;
}) => {
  return (
    <>
      <LevelBadge
        badgeLevel={badgeLevel}
        isUpperMilestoneLevel={isUpperMilestoneLevel}
        isLowerMilestoneLevel={isLowerMilestoneLevel}
        currentXPLevel={usersCurrentLevel}
      />
      <ProgressBar percentageComplete={percentageComplete} halfWidth={isUpperMilestoneLevel} />
    </>
  );
};

type MilestoneProgressBarProps = {
  percentageComplete: number;
  halfWidth?: boolean;
};

const ProgressBar = ({ percentageComplete, halfWidth = false }: MilestoneProgressBarProps) => {
  return (
    <div className={classNames({ [styles.ProgressBar]: true, [styles.HalfWidth]: halfWidth })}>
      <div className={styles.ProgressBarAchieved} style={{ width: `${percentageComplete}%` }} />
    </div>
  );
};

const LevelBadge = ({
  badgeLevel,
  isUpperMilestoneLevel,
  isLowerMilestoneLevel,
  currentXPLevel,
}: {
  badgeLevel: number;
  isUpperMilestoneLevel: boolean;
  isLowerMilestoneLevel: boolean;
  currentXPLevel: number;
}) => {
  const isMilestoneLevel = isUpperMilestoneLevel || isLowerMilestoneLevel;

  const badge = useMemo(() => {
    if (badgeLevel === 0) {
      return null;
    }

    if (!isMilestoneLevel) {
      return currentXPLevel >= badgeLevel ? <XPLevelTickOn /> : <XPLevelTickOff />;
    }

    if (isUpperMilestoneLevel) {
      return badgeLevel % 100 === 0 ? (
        <Level_100_off />
      ) : badgeLevel % 25 === 0 ? (
        <Level_25_off />
      ) : (
        <Level_5_off />
      );
    }

    return badgeLevel % 100 === 0 ? (
      <Level_100_on />
    ) : badgeLevel % 25 === 0 ? (
      <Level_25_on />
    ) : (
      <Level_5_on />
    );
  }, [badgeLevel, isMilestoneLevel, isUpperMilestoneLevel, currentXPLevel]);

  return (
    <div
      className={classNames({
        [styles.BadgeAndLevelText]: true,
        [styles.Milestone]: isUpperMilestoneLevel || isLowerMilestoneLevel,
      })}
    >
      {badge && (
        <div
          className={classNames({
            [styles.Badge]: true,
            [styles.HasWings]: badgeLevel % 25 === 0,
            [styles.HasCrown]: badgeLevel % 100 === 0 && isLowerMilestoneLevel,
          })}
        >
          {badge}
        </div>
      )}

      <div>
        {
          <span
            className={classNames({
              [styles.LevelText]: true,
              [styles.Zero]: badgeLevel === 0,
            })}
          >
            {badgeLevel}
          </span>
        }
      </div>
    </div>
  );
};

import { ChevronDownIcon } from '@radix-ui/react-icons';
import { ChevronLeft } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { CLOSE_TRAINING_MENU } from 'content/training/trainingModules';
import { useMenu, useTraining } from 'context/training/hook';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isIntro, TrainingStepID } from 'types/training';
import { useAnalytics } from 'utils/analytics';
import { filterCompletableTrainingSteps, setTrainingModeEnabled } from 'utils/training';

import { clickedBackToTeacherPortalEvent, clickedOnNextStepEvent } from './analytics';
import { TrainingIntroVideo } from './intro-video/TrainingIntroVideo';
import styles from './TrainingBanner.module.css';
import { TrainingDropdownMenu } from './TrainingDropdownMenu';

/**
 * Training Banner is the React component for the teacher training banner that appears at the top of the page when
 * a staff member user is doing training on the student interface.
 */
export const TrainingBanner = () => {
  const { enabled, currentModule, currentStep, setCurrentStep, watchingHelpVideo, completeTask } =
    useTraining();

  const sendEvent = useAnalytics();

  const steps = useMemo(() => {
    return currentModule?.steps || [];
  }, [currentModule]);

  const completableSteps = filterCompletableTrainingSteps(steps);

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  // Hidden element before the menu contents which keyboard focus is set back to when the page changes, so the user
  // can change pages and then tab through its contents
  const keyboardFocusRef = useRef<HTMLDivElement>(null);

  // Hook which implements closing the dropdown menu when you click away:
  const { isMenuOpen, setIsMenuOpen } = useMenu(menuButtonRef, boxRef, false);

  // Whether the intro video is showing
  const [introVideoShowing, setIntroVideoShowing] = useState<boolean | undefined>(undefined);

  // When the training first loads (after user progress has loaded), if the first step is an intro video that has not
  // been completed, show the intro video
  useEffect(() => {
    if (enabled && introVideoShowing === undefined) {
      setIsMenuOpen(false);
      setIntroVideoShowing(steps.length > 0 && isIntro(steps[0]) && !steps[0].isComplete);
    }
  }, [enabled, introVideoShowing, setIsMenuOpen, steps]);

  useEffect(
    () => {
      const onCloseTrainingMenu = () => setIsMenuOpen(false);

      document.addEventListener(CLOSE_TRAINING_MENU, onCloseTrainingMenu);

      return () => {
        document.removeEventListener(CLOSE_TRAINING_MENU, onCloseTrainingMenu);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // State for whether we are on the "all steps" page or the "step details" page of the dropdown
  const [showingAllSteps, setShowingAllSteps] = useState(true);

  // Track whether a task has just completed. If so, open the menu and go to that task so the user will see the
  // completion message for the task
  const stepsCompletedRef = useRef<boolean[]>([]);
  useEffect(() => {
    if (stepsCompletedRef.current && enabled) {
      for (let i = 0; i < stepsCompletedRef.current.length; i++) {
        if (
          !stepsCompletedRef.current[i] &&
          completableSteps.length > i &&
          completableSteps[i].isComplete
        ) {
          // task number is 1-indexed.
          // If the step isn't the intro and it gets completed, open the menu to
          // the newly completed step. The intro step gets handled by the video modal
          if (!isIntro(completableSteps[i])) {
            setCurrentStep(completableSteps[i].taskNumber - 1);
          }
          setIsMenuOpen(true);
          setShowingAllSteps(false);
          break;
        }
      }
    }
  }, [completableSteps, setCurrentStep, setIsMenuOpen, enabled]);

  useEffect(() => {
    stepsCompletedRef.current = enabled ? completableSteps.map(step => step.isComplete) : [];
  });

  // If the user has just finished watching a help video re-open the menu
  useEffect(() => {
    if (!watchingHelpVideo) {
      setIsMenuOpen(true);
    }
  }, [watchingHelpVideo, setIsMenuOpen]);

  useEffect(() => {
    // When the user changes pages, set the keyboard focus to the hidden element before the menu contents
    keyboardFocusRef.current?.focus();
  }, [showingAllSteps]);

  const handleBackToTeacherPortal = (referrer: string) => {
    sendEvent(clickedBackToTeacherPortalEvent(referrer));
    setTrainingModeEnabled(false);
    window.location.href = window.__sparxweb.teacherPortalPath
      ? window.__sparxweb.teacherPortalPath + 'training'
      : '/teacher/training';
  };

  if (!enabled || !currentModule) {
    return null;
  }

  const animatedBox = isMenuOpen && (
    <TrainingDropdownMenu
      ref={boxRef}
      showingAllSteps={showingAllSteps}
      setShowingAllSteps={setShowingAllSteps}
      handleBackToTeacherPortal={handleBackToTeacherPortal}
      steps={steps}
      currentModule={currentModule}
      selectedStep={currentStep !== undefined ? currentModule.steps[currentStep] : undefined}
      setCurrentStep={setCurrentStep}
      watchIntroVideo={() => setIntroVideoShowing(true)}
      completeTask={completeTask}
    />
  );

  /**
   * When the intro video is closed, if the user watched it to the end, mark the first step as complete and
   * automatically go to the next step
   * @param watchedToEnd
   */
  const onCloseIntroVideo = (watchedToEnd: boolean) => {
    setIntroVideoShowing(false);
    setIsMenuOpen(true);
    if (watchedToEnd) {
      completeTask(steps[0].id as TrainingStepID, true);
      // Analytic for going to next step:
      sendEvent(clickedOnNextStepEvent(steps[0], 1));
    }
  };

  return (
    <>
      <div className={styles.TrainingBanner}>
        <div className={styles.TrainingBannerLeft}>
          <button
            className={classNames(styles.ExitTrainingButton, styles.TrainingBannerFocusTarget)}
            role="button"
            onClick={() => handleBackToTeacherPortal('header')}
          >
            <ChevronLeft />{' '}
            <span className={styles.ExitTrainingButtonText}>Return to Teacher Portal</span>
            <span className={styles.ExitTrainingButtonTextNarrow}>Exit</span>
          </button>
          <span className={styles.ExitTrainingButtonSeparator} aria-hidden={true} />
        </div>

        <nav
          role="button"
          ref={menuButtonRef}
          className={classNames(styles.TrainingDropdownButton, styles.TrainingBannerFocusTarget)}
          tabIndex={0}
        >
          <div className={styles.TrainingIndicators} aria-labelledby="progress">
            {completableSteps.map((step, index) => (
              <div
                key={index}
                className={classNames(styles.Indicator, {
                  [styles.IndicatorComplete]: step.isComplete,
                })}
              />
            ))}
          </div>

          <div id="progress" className={styles.TrainingProgress}>
            {completableSteps.filter(step => step.isComplete).length}/{completableSteps.length}{' '}
            steps completed
          </div>

          <div>
            <ChevronDownIcon
              className={classNames(styles.TrainingModeAnimate, {
                [styles.TrainingModeMenuArrow]: isMenuOpen,
              })}
            />
          </div>

          <div ref={keyboardFocusRef} tabIndex={-1} />

          {animatedBox}
        </nav>
      </div>
      {steps.length > 0 && isIntro(steps[0]) && (
        <TrainingIntroVideo
          step={steps[0]}
          completed={steps[0].isComplete}
          showing={!!introVideoShowing}
          onClose={onCloseIntroVideo}
        />
      )}
    </>
  );
};

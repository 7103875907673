import { ChevronUpIcon, TriangleDownIcon, TriangleLeftIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { LeaderboardDateRange } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { useLeaderboardContext } from '../context/context';
import styles from './LeaderboardDateSelector.module.css';

export const LeaderboardDateSelector = ({
  leaderboardDateRange,
  setLeaderboardDateRange,
  showWeekDays,
}: {
  leaderboardDateRange: LeaderboardDateRange;
  setLeaderboardDateRange: React.Dispatch<React.SetStateAction<LeaderboardDateRange>>;
  showWeekDays?: boolean;
}) => {
  const { sendAnalyticsEvent } = useLeaderboardContext();
  return (
    <Select.Root
      value={`${leaderboardDateRange}`}
      onValueChange={s => {
        sendAnalyticsEvent({
          action: 'Change Leaderboard Date Range',
          labels: {
            range: LeaderboardDateRange[Number(s)],
          },
        });
        setLeaderboardDateRange(Number(s));
      }}
    >
      <Select.Trigger
        className={classNames({ [styles.SelectTrigger]: true, [styles.Wide]: showWeekDays })}
        aria-label="time range"
      >
        <Select.Value></Select.Value>
        <TriangleDownIcon className={styles.TriangleDownIcon} />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className={styles.SelectContent}>
          <Select.ScrollUpButton className={styles.SelectScrollButton}>
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className={classNames({ [styles.SelectViewport]: true })}>
            <SelectItem value={`${LeaderboardDateRange.THIS_WEEK}`}>
              This week {showWeekDays && ' (Monday to Sunday)'}
            </SelectItem>
            <SelectItem value={`${LeaderboardDateRange.LAST_WEEK}`}>
              Last week {showWeekDays && ' (Monday to Sunday)'}
            </SelectItem>
            <SelectItem value={`${LeaderboardDateRange.THIS_MONTH}`}>This month</SelectItem>
            <SelectItem value={`${LeaderboardDateRange.LAST_MONTH}`}>Last month</SelectItem>
            <SelectItem value={`${LeaderboardDateRange.THIS_YEAR}`}>This year</SelectItem>
          </Select.Viewport>
          <Select.ScrollDownButton className={styles.SelectScrollButton}>
            <TriangleDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = ({ value, children }: { value: string; children: ReactNode }) => {
  return (
    <Select.Item value={value} className={styles.SelectItem}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className={styles.SelectItemIndicator}>
        <TriangleLeftIcon />
      </Select.ItemIndicator>
    </Select.Item>
  );
};

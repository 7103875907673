import { FunctionComponent, ReactElement } from 'react';

import styles from './Question.module.css';
import { useIsFixedWidthContainer } from './useIsFixedWidthContainer';

interface IQuestionAnswerLayoutProps {
  content: ReactElement;
  bottomBar: ReactElement;
}

export const QuestionAnswerLayout: FunctionComponent<IQuestionAnswerLayoutProps> = ({
  content,
  bottomBar,
}) => {
  const isFixedWidthContainer = useIsFixedWidthContainer();
  return isFixedWidthContainer ? (
    <div className={styles.QuestionContainer}>
      <div className={styles.QuestionScrollableContent}>{content}</div>
      {bottomBar}
    </div>
  ) : (
    <>
      <div className={styles.QuestionScrollableContent}>
        <div className={styles.QuestionContainer}>{content}</div>
      </div>
      {bottomBar}
    </>
  );
};

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faCommentDots,
  faCrown,
  faFolderOpen,
  faSignOut,
  faStar,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ShownUntil } from '@sparx/sparx-design/components/hidden-at/HiddenAt';
import { Bars } from '@sparx/sparx-design/icons';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import { ProductSwitch } from 'app/ProductSwitch';
import classNames from 'classnames';
import { StudentExperienceTrainingStep } from 'content/training/trainingModules';
import { useTraining } from 'context/training';
import { getUsersNamesFromSession, useLogout, useSession } from 'queries/session';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';
import { useIsPrimaryStudent } from 'utils/session';

import styles from './Menu.module.css';

export const Menu = () => {
  const sendEvent = useAnalytics();
  const logout = useLogout();
  const { data: session } = useSession();
  const isPrimaryStudent = useIsPrimaryStudent();
  const { completeTask: completeTrainingTask } = useTraining();
  const featureFlags = useFeatureFlags();

  // Show the theme toggle in development only.
  const showThemeToggle = import.meta.env.DEV;
  let themeToggle: React.ReactNode | undefined;
  if (showThemeToggle) {
    themeToggle = (
      <DropdownMenu.Item
        className={styles.DropdownMenuItem}
        onClick={() => {
          const currentTheme = document.documentElement.getAttribute('data-theme');
          if (currentTheme === 'test-theme') {
            document.documentElement.setAttribute('data-theme', 'regular-theme');
          } else {
            document.documentElement.setAttribute('data-theme', 'test-theme');
          }
        }}
      >
        <DropdownMenuIcon icon={faVial} />
        Toggle Theme
      </DropdownMenu.Item>
    );
  }

  const showFeedback =
    featureFlags.getBooleanFlag('sparxweb2-enable-feedback', false) && !isPrimaryStudent;
  const showProductSwitch = featureFlags.getBooleanFlag('sparxweb2-product-switcher', false);

  const showResourceHub = !isPrimaryStudent;

  const showSettingsPage = featureFlags.getBooleanFlag('sparxweb2-settings-page', false);
  const showLeaderboard = featureFlags.getBooleanFlag('sparxweb2-leaderboards', true);

  return (
    <DropdownMenu.Root
      modal={false}
      onOpenChange={open =>
        sendEvent({ action: `menu ${open ? 'opened' : 'closed'}`, category: 'menu' })
      }
    >
      <DropdownMenu.Trigger asChild className={accessibilityStyles.FocusTarget}>
        <button aria-label="Customise options" className={styles.MenuButton}>
          <span className={styles.MenuText}>Menu</span>
          <Bars className={styles.MenuIcon} />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.DropdownMenuContent}>
          <ShownUntil breakpoint="sm">
            <DropdownMenu.Item className={classNames(styles.DropdownMenuItem, styles.UserRow)}>
              <span>{getUsersNamesFromSession(session)}</span>
            </DropdownMenu.Item>
            <DropdownSeparator />
          </ShownUntil>
          <Link
            className={styles.Link}
            to="/rewards"
            onClick={() => completeTrainingTask(StudentExperienceTrainingStep.LEARN_ABOUT_XP)}
          >
            <DropdownMenuItem icon={faStar}>Rewards</DropdownMenuItem>
          </Link>
          {showFeedback && (
            <Link className={styles.Link} to="/feedback">
              <DropdownMenuItem icon={faCommentDots}>Feedback</DropdownMenuItem>
            </Link>
          )}
          {showResourceHub && (
            <Link className={styles.Link} to="/resourcehub">
              <DropdownMenuItem icon={faFolderOpen}>Resource Hub</DropdownMenuItem>
            </Link>
          )}
          {showSettingsPage && (
            <Link className={styles.Link} to="/settings">
              <DropdownMenuItem icon={faCog}>Settings</DropdownMenuItem>
            </Link>
          )}
          {showLeaderboard && (
            <Link className={styles.Link} to="/leaderboard">
              <DropdownMenuItem icon={faCrown}>Leaderboards</DropdownMenuItem>
            </Link>
          )}
          {themeToggle}
          {showProductSwitch && <ProductSwitch />}
          <DropdownSeparator />
          <DropdownMenu.Item
            className={styles.DropdownMenuItem}
            onClick={() => {
              sendEvent({ action: 'clicked logout', category: 'menu' });
              logout.mutate();
            }}
          >
            <DropdownMenuIcon icon={faSignOut} />
            Sign out
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const DropdownMenuItem = ({ children, icon }: { children: ReactNode; icon: IconDefinition }) => (
  <DropdownMenu.Item className={styles.DropdownMenuItem}>
    <DropdownMenuIcon icon={icon} />
    {children}
  </DropdownMenu.Item>
);

const DropdownMenuIcon = ({ icon }: { icon: IconDefinition }) => (
  <span className={styles.DropdownMenuItemIcon}>
    <FontAwesomeIcon icon={icon} fixedWidth={true} />
  </span>
);

const DropdownSeparator = () => <DropdownMenu.Separator className={styles.DropdownMenuSeparator} />;

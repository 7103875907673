import { IndependentLearningProvider } from 'context/independentlearning';
import { KeyboardModeProvider } from 'context/keyboardmode';
import { LandscapeModalProvider } from 'context/landscapemodal';
import { NavigationProvider } from 'context/navigation/NavigationProvider';
import { OnboardingLockProvider } from 'context/onboardinglock';
import { RewardsProvider } from 'context/rewards';
import { TutorialSpotlightProvider } from 'context/tutorialspotlight';
import { VideoNudgeStateProvider } from 'context/videonudge';
import { PropsWithChildren } from 'react';

/** AppStateProvider combines different contexts which contain the local app state */
export const AppStateProvider = ({ children }: PropsWithChildren) => (
  <NavigationProvider>
    <KeyboardModeProvider>
      <TutorialSpotlightProvider>
        <OnboardingLockProvider>
          <IndependentLearningProvider>
            <RewardsProvider>
              <VideoNudgeStateProvider>
                <LandscapeModalProvider>{children}</LandscapeModalProvider>
              </VideoNudgeStateProvider>
            </RewardsProvider>
          </IndependentLearningProvider>
        </OnboardingLockProvider>
      </TutorialSpotlightProvider>
    </KeyboardModeProvider>
  </NavigationProvider>
);

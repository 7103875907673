import { ActivityFeedWork } from '@sparx/api/apis/sparx/dataanalysis/aggserver/v1/aggserver';
import { PackageType } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { GetActivityFeedResponse } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { curriculumNameFromName, getTopicIDFromName } from '@sparx/resource-names';
import { ChevronRight, Star } from '@sparx/sparx-design/icons';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useActivityFeed } from 'queries/activity';
import { useCurriculumSummaries } from 'queries/content';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGetCurriculumTopicLevel } from 'views/independent-learning/hooks';

import styles from './Activity.module.css';

/**
 * Activity feed for independent learning - includes activity for IL and homework
 */
export const Activity = () => {
  const { data: activityFeed } = useActivityFeed();
  return (
    <div className={styles.ActivityFeed}>
      {activityFeed?.days
        .sort((a, b) => sortByDate(a.date, b.date))
        .filter(d => d.work.some(w => !!w.curriculumTopicName))
        .map((d, i) => {
          if (!d.date) {
            return null;
          }
          return (
            <div key={d.date.nanos} className={styles.ActivitySection}>
              <div className={styles.Timeline}>
                <span className={styles.StarIcon}>
                  <Star variant="White" />
                </span>
              </div>
              <div
                className={classNames(styles.ActivityDay, {
                  [styles.LastDay]: i + 1 === activityFeed.days.length,
                })}
              >
                <span className={styles.Date}>
                  {format(new Date(d.date.seconds * 1000), 'dd MMM yyyy')}
                </span>
                <div>
                  {d.work
                    .filter(w => w.curriculumTopicName)
                    .sort((a, b) => sortByDate(a.latestAttempt, b.latestAttempt))
                    .map(activity => (
                      <ActivityRow
                        key={activity.latestAttempt?.seconds}
                        activity={activity}
                        activityFeed={activityFeed}
                      />
                    ))}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

// Returns the independent learning link for a given activity
const useILLink = (activity: ActivityFeedWork): string => {
  const curriculumTopicLevel = useGetCurriculumTopicLevel(activity.curriculumTopicName);
  const summaries = useCurriculumSummaries();
  return useMemo(() => {
    const topicName = activity.curriculumTopicName.split('/learningpaths')[0];
    const curriculumName = curriculumNameFromName(topicName);
    const curriculumSummary = summaries.find(s => s.curriculum?.name === curriculumName);

    if (!curriculumSummary || !curriculumTopicLevel.level || !curriculumTopicLevel.topic) {
      return '';
    }

    // Find the substrand name for the given topic name
    for (const strandSummary of curriculumSummary.strandSummaries) {
      for (const { substrand } of strandSummary.substrandSummaries) {
        if (substrand?.topicNames.includes(topicName)) {
          return `/independentlearning/${substrand.name}/topics/${getTopicIDFromName(
            curriculumTopicLevel.topic,
          )}/levels/${curriculumTopicLevel.level}`;
        }
      }
    }

    return '';
  }, [
    activity.curriculumTopicName,
    curriculumTopicLevel.level,
    curriculumTopicLevel.topic,
    summaries,
  ]);
};

const ActivityRow = ({
  activity,
  activityFeed,
}: {
  activity: ActivityFeedWork;
  activityFeed: GetActivityFeedResponse;
}) => {
  const learningUnit = activityFeed.learningUnits[`learningunits/${activity.learningUnitID}`];
  const independentLearningActivity = activity.packageType === PackageType.REVISION;
  const curriculumTopicLevel = useGetCurriculumTopicLevel(activity.curriculumTopicName);
  const to = useILLink(activity);

  return (
    <Link
      key={activity.learningUnitID}
      to={to}
      className={classNames(styles.Activity, { [styles.Disabled]: !to })}
    >
      <div className={styles.ActivityLeft}>
        <span
          className={classNames(styles.Chip, {
            [styles.IlChip]: independentLearningActivity,
            [styles.HwkChip]: !independentLearningActivity,
          })}
        >
          {independentLearningActivity ? 'INDEPENDENT' : 'HOMEWORK'}
        </span>
        <span className={styles.ActivityText}>
          <TextWithMaths text={learningUnit.title} />
          {curriculumTopicLevel.displayName && (
            <span className={styles.CurriculumLevelChip}>
              {curriculumTopicLevel.displayName} {curriculumTopicLevel.level}
            </span>
          )}
        </span>
      </div>
      <div className={styles.ActivityRight}>
        {activity.correctAnswers}/{activity.attempts} correct answers
        {to && (
          <span className={styles.Chevron}>
            <ChevronRight />
          </span>
        )}
      </div>
    </Link>
  );
};

const sortByDate = (a: Timestamp | undefined, b: Timestamp | undefined) => {
  if (!a || !b) {
    return -1;
  }
  return a.seconds > b.seconds ? -1 : 1;
};

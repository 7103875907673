import classNames from 'classnames';

import styles from './CarouselButtons.module.css';

interface CarouselButtonsProps {
  index: number;
  numItemsToShow: number;
  total: number;
  goToNext: () => void;
  goToPrevious: () => void;
}

export const CarouselButtons = ({
  index,
  numItemsToShow,
  total,
  goToNext,
  goToPrevious,
}: CarouselButtonsProps) => (
  <div className={styles.CarouselButtonGroup}>
    <button className={styles.CarouselButton} onClick={goToPrevious}>
      <span>{'<'}</span>
    </button>
    <span className={styles.CarouselPositionIndicator}>
      {[...Array(total)].map((_, i) => (
        <div
          className={classNames(styles.CarouselPositionDot, {
            [styles.CarouselPositionDotVisible]: i >= index && i < index + numItemsToShow,
          })}
          key={i}
        ></div>
      ))}
    </span>
    <button className={styles.CarouselButton} onClick={goToNext}>
      <span>{'>'}</span>
    </button>
  </div>
);

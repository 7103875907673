import * as Dialog from '@radix-ui/react-dialog';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Button } from '@sparx/sparx-design/components';
import { ChevronLeft, ChevronRight } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { Video } from 'components/video/Video';
import { useActiveHomeworkPackages } from 'queries/packages';
import { TutorialKey, useIsTutorialComplete, useMarkTutorialComplete } from 'queries/tutorials';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useFeatureFlags } from 'utils/feature-flags';
import { makeTaskItemPath, useLQDPath } from 'utils/paths';
import { makeOnboardingVideoUrls } from 'utils/urls';

import styles from './Question.module.css';

const videoUrls = makeOnboardingVideoUrls('bookwork');

/**
 * OnboardingTaskVideoModal handles displaying the bookwork check onboarding video during the onboarding
 * task.
 * It shows the video once per user, and marks the tutorial complete on completion of the video
 */
export const OnboardingTaskVideoModal = () => {
  const sendEvent = useAnalytics();
  const featureFlags = useFeatureFlags();
  const { mutate: markTutorialComplete } = useMarkTutorialComplete();

  // decide whether or not to show the onboarding video
  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);
  const hasWatchedOnboardingVideo = useIsTutorialComplete(TutorialKey.WacOnboardingVideo);
  const { parentPath, packageID, taskIndex, taskItemIndex } = useLQDPath();
  // if this is loading or errored, we just don't show the video - its not needed for the question
  const { data: packages } = useActiveHomeworkPackages();
  const canSkipOnboarding = featureFlags.getBooleanFlag('sparxweb2-can-skip-onboarding', false);

  useEffect(() => {
    const pkg = packages?.find(p => p.packageID === packageID);
    const packageType = pkg?.packageType;

    if (
      !hasWatchedOnboardingVideo &&
      packageType === 'onboarding' &&
      taskItemIndex !== 1 &&
      !showOnboardingVideo
    ) {
      setShowOnboardingVideo(true);
    }
  }, [hasWatchedOnboardingVideo, packageID, packages, showOnboardingVideo, taskItemIndex]);

  const firstQuestionLink = makeTaskItemPath(parentPath, packageID, taskIndex, 1);
  const navigate = useNavigate();

  useEffect(() => {
    if (canSkipOnboarding && !hasWatchedOnboardingVideo && showOnboardingVideo) {
      markTutorialComplete([TutorialKey.WacOnboardingVideo]);
    }
  }, [canSkipOnboarding, hasWatchedOnboardingVideo, markTutorialComplete, showOnboardingVideo]);

  return (
    <Dialog.Root open={showOnboardingVideo}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content className={classNames(dialogStyles.DialogContent, dialogStyles.FullWidth)}>
          <Dialog.Title className={dialogStyles.DialogTitle}>Bookwork Checks</Dialog.Title>
          <div className={styles.VideoContainer}>
            <Video
              disableForwardSeeking
              videoUrl={videoUrls.videoUrl}
              posterUrl={videoUrls.posterUrl}
              subtitleUrl={videoUrls.subtitleUrl}
              onEnded={() => {
                markTutorialComplete([TutorialKey.WacOnboardingVideo]);
              }}
            />
          </div>
          <div className={styles.VideoButtonsContainer}>
            <Button
              leftIcon={<ChevronLeft />}
              onClick={() => {
                setShowOnboardingVideo(false);
                navigate(firstQuestionLink);
                sendEvent({ action: 'wac onboarding video: clicked back', category: 'lqd' });
              }}
              onKeyDown={simulateClickOnEnter}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              as={Dialog.Close}
              isDisabled={!hasWatchedOnboardingVideo}
              rightIcon={<ChevronRight />}
              onClick={() => {
                setShowOnboardingVideo(false);
                sendEvent({ action: 'wac onboarding video: clicked next', category: 'lqd' });
              }}
              onKeyDown={simulateClickOnEnter}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import {
  MarkNotificationsReadRequest,
  Notification,
  NotificationAndDisplayData,
} from '@sparx/api/apis/sparx/notifications/notifications/v1/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'context/api';
import { useListNotificationsAndDisplayDataKey } from 'queries/notifications/queries';

/**
 * useMarkNotificationsRead creates a mutation which marks the specified notifications as read.
 */
export const useMarkNotificationsRead = () => {
  const { notificationsClient } = useAPI();
  const queryClient = useQueryClient();
  return useMutation(
    async (req: MarkNotificationsReadRequest) =>
      notificationsClient.markNotificationsRead(req).response,
    {
      onSuccess: data => {
        queryClient.setQueryData(
          [useListNotificationsAndDisplayDataKey],
          (existingNotificationDisplayDatas: NotificationAndDisplayData[] | undefined) => {
            return existingNotificationDisplayDatas?.map(endd => {
              const updatedNotification = data.notifications.find(
                n => n.name === endd.notification?.name,
              );
              return updatedNotification
                ? { displayData: endd.displayData, notification: updatedNotification }
                : endd;
            });
          },
        );
      },
    },
  );
};

/**
 * useCreateNotifications creates a mutation which creates multiple notifications
 */
export const useCreateNotifications = () => {
  const { notificationsClient } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<NotificationAndDisplayData[], Error, Notification[]>(
    async (notifications: Notification[]) => {
      const ns = await notificationsClient.createNotifications({ notifications }).response;
      return ns.notifications.map(n => {
        return {
          notification: n,
        };
      });
    },
    {
      onSuccess: response => {
        // update the query cache with the server response
        queryClient.setQueryData(
          [useListNotificationsAndDisplayDataKey],
          // add the created notification to the existing data
          (existingNotifications: NotificationAndDisplayData[] | undefined) => {
            return [...(existingNotifications || []), ...response];
          },
        );
      },
    },
  );
};

import { overlayColourOptions } from '@sparx/accessibility';
import { TriangleExclamationIcon } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import contentPreview from 'images/SettingsPreviewSample.png';
import { useStudentLabel, useUpdateStudentLabels } from 'queries/studentlabels';
import { useAnalytics } from 'utils/analytics';

import styles from './OverlayColourSettings.module.css';

export const OverlayColourSettings = () => {
  const sendEvent = useAnalytics();
  const [labelValue = '#FFFFFF', ok] = useStudentLabel('settings.overlay_colour');
  // the session should be loaded at this point - the app can't get this far without it, so just die
  if (!ok) {
    throw new Error('Session data not loaded');
  }

  const { mutate: updateStudentLabels } = useUpdateStudentLabels();

  return (
    <div className={styles.OverlaySettingsContainer}>
      <div className={styles.OverlaySelector}>
        <h3>Overlay colour</h3>
        <div className={styles.ColorOptionsContainer}>
          {Object.entries(overlayColourOptions).map(([name, value]) => {
            const selected = value === labelValue;
            return (
              <label
                key={name}
                htmlFor={name}
                className={classNames({ [styles.ColorOption]: true, [styles.Selected]: selected })}
              >
                <div className={styles.ColorSample} style={{ backgroundColor: value }} />
                <div className={styles.LabelContainer}>
                  <input
                    className={styles.RadioButton}
                    type="radio"
                    name="bg-colour"
                    value={value}
                    id={name}
                    checked={selected}
                    onChange={() => {
                      sendEvent({
                        action: 'overlay colour updated',
                        category: 'settings',
                        labels: { newColourHex: value, newColourLabel: name },
                      });
                      updateStudentLabels({ 'settings.overlay_colour': value });
                    }}
                  />
                  <span className={styles.Label}>{name}</span>
                </div>
              </label>
            );
          })}
        </div>
      </div>
      <div className={styles.PreviewSectionContainer}>
        <h3>Preview</h3>
        <div className={styles.PreviewContainer}>
          <div className={styles.Preview}>
            <p>
              Work out the size of the angle labelled <i>h</i>.
            </p>
            <img src={contentPreview} alt="Preview of the overlay colour" role="presentation" />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {labelValue !== overlayColourOptions.None && (
          <motion.div
            className={styles.WarningContainer}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className={styles.Warning}>
              <TriangleExclamationIcon />
              <span>
                If you use a coloured overlay some images may not appear as originally intended.
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { getTopicIDFromName } from '@sparx/resource-names';
import { ProgressBadge } from '@sparx/sparx-design/components';
import { ChevronRight } from '@sparx/sparx-design/icons';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { LevelBadge } from '../level-select/LevelSelect';
import { SeenInHomework } from '../seen-in-homework/SeenInHomework';
import { TopicProgress } from '../utils';
import styles from './StrandView.module.css';

interface ISubstrandTopicProps {
  topic?: Topic;
  substrandName: string;
  seenInHomework: boolean;
  topicProgress?: TopicProgress;
}

/**
 * SubstrandTopic shows information for a substrand and is itself a link to the Topic View for it
 * @param topic Topic to show information for
 * @param seenInHomework Whether the topic has been seen by the student in homework, that is if it
 * is an assigned topic
 * @param topicProgress Progress made by the student for the topic in Independent Learning
 * @param substrandName Resource name of the substrand the topic is being viewed in
 */

export const SubstrandTopic = ({
  topic,
  seenInHomework,
  topicProgress,
  substrandName,
}: ISubstrandTopicProps) => {
  if (!topic) {
    return null;
  }

  // Include the strand in the path if we know it
  const topicID = getTopicIDFromName(topic.name);
  let to = `/independentlearning/${substrandName}/topics/${topicID}`;

  if (topicProgress?.learningPath) {
    to += `/levels/${topicProgress.learningPath.level}`;
  }

  return (
    <Link className={styles.Topic} to={to} key={topic.name}>
      <div className={styles.TopicLeft}>
        <div className={styles.TopicTitle}>
          <TextWithMaths text={topic.displayName} />
          {seenInHomework && <SeenInHomework />}
        </div>
      </div>
      <div className={styles.TopicRight}>
        <div
          className={classNames(styles.Badge, {
            [styles.BadgeWithLevel]: topicProgress?.learningPath?.level,
          })}
        >
          <LevelBadge level={topicProgress?.learningPath?.level} />
          <ProgressBadge variant={topicProgress?.score} />
        </div>
        <span className={styles.Chevron}>
          <ChevronRight />
        </span>
      </div>
    </Link>
  );
};

// TODO: Revert this change to SW2 to get it to build on the PR!
import './index.css';
// Polyfills
// Event listener signal aborting
import '@sparx/polyfills/eventAborterPolyfill';
// PointerEvents (for old Safari)
import 'pepjs';
// IntersectionObserver (copied from Reader, not 100% sure we need it)
import 'intersection-observer/intersection-observer';

// ResizeObserver (for old Safari)
import ResizeObserverPolyfill from 'resize-observer-polyfill';
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

import App from 'app/App';
import ReactDOM from 'react-dom/client';
import { FlagValue } from 'utils/feature-flags';

declare global {
  interface Window {
    __sparxweb: {
      setFeatureFlag: (name: string, value: FlagValue) => void;
      urls: {
        api: string;
        contentAssets: string;
        staticAssets: string;
        interactionGateway: string;
        feedbackGateway: string;
      };
      csrfCookieName: string;
      csToken: string;
      serveRoot: string;
      schoolID: string;
      schoolName: string;
      schoolDisplayName: string;
      environment: string;
      GAProperty: string;
      teacherPortalPath: string;
      featureFlags: Record<string, FlagValue>;
      variant: string;
    };
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);

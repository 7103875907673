import * as Dialog from '@radix-ui/react-dialog';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Button } from '@sparx/sparx-design/components';
import { ChevronRight, Tick, TimesIcon } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { Video } from 'components/video/Video';
import { useState } from 'react';
import { IntroStep } from 'types/training';
import { makeStaticVideoUrls } from 'utils/urls';

import styles from './TrainingIntroVideo.module.css';

interface TrainingIntroVideoProps {
  // The intro video training step, containing information about the video and text
  step: IntroStep;
  // Whether the video modal is showing/open
  showing: boolean;
  // Whether the step has previously been completed (i.e. re-watching it after completion):
  completed: boolean;
  // Callback for when the video modal is closed, providing whether the video was watched to the end
  onClose: (watchedToEnd: boolean) => void;
}

/**
 * Modal for showing the teacher training introduction video
 * @constructor
 */
export const TrainingIntroVideo = ({
  step,
  showing,
  completed,
  onClose,
}: TrainingIntroVideoProps) => {
  const urls = makeStaticVideoUrls(step.video || '');
  const [reachedEnd, setReachedEnd] = useState(false);

  const readyForNextStep = completed || reachedEnd;

  return (
    <Dialog.Root onOpenChange={() => onClose(readyForNextStep)} open={showing}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(dialogStyles.DialogOverlay, styles.TrainingDialogOverlap)}
        />
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullWidth,
            styles.DialogContent,
          )}
        >
          <Dialog.Title className={dialogStyles.DialogTitle}>{step.title}</Dialog.Title>
          <div className={styles.VideoContainer}>
            <Video
              videoUrl={urls.videoUrl}
              posterUrl={urls.posterUrl}
              subtitleUrl={urls.subtitleUrl}
              onEnded={() => setReachedEnd(true)}
            />
          </div>
          <div className={styles.VideoContext}>{step.videoContext}</div>
          {readyForNextStep ? (
            <div className={styles.StepComplete}>
              <span className={classNames(styles.CheckIcon, styles.TrainingModeCheckAnimation)}>
                <Tick variant="White" />
              </span>{' '}
              Step complete
            </div>
          ) : (
            <div className={styles.VideoInstruction}>{step.videoInstruction}</div>
          )}
          <div className={styles.VideoCloseButton}>
            <Button
              rightIcon={readyForNextStep ? <ChevronRight /> : <TimesIcon />}
              onClick={() => onClose(readyForNextStep)}
              onKeyDown={simulateClickOnEnter}
            >
              {readyForNextStep ? 'Next step' : 'Close video'}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { ChevronRight, VideoIcon } from '@sparx/sparx-design/icons';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { useMemo } from 'react';
import { CompletableStep, isIntro, isSummary, isTask, isVideo, Module, Step } from 'types/training';
import { useAnalytics } from 'utils/analytics';

import { clickedOnNextStepEvent, clickedOnSummaryEvent } from './analytics';
import { ModuleComplete } from './ModuleComplete';
import { StepComplete } from './StepComplete';
import styles from './TrainingBanner.module.css';

/**
 * CompletableStepPage is a page within the Training box that shows details about the selected step.
 * It shows information like the step title, context, completion status and help buttons.
 */

interface ICompletableStepProps {
  // The current step to show information for
  step: CompletableStep;
  // The current training module the user is completing
  currentModule: Module;
  // Function to call when video watch button is clicked
  onWatchVideo: () => void;
  // Function to call when "Back to teacher Portal" linked is clicked. Allows referrer to be set
  // for analytics purposes.
  handleBackToTeacherPortal: (referrer: string) => void;
  // Function to handle changing the currently selected step
  setCurrentStep: (stepIndex: number) => void;
  // All steps in the training module
  steps: Step[];
  // Whether the page is showing
  showing: boolean;
}

export const CompletableStepPage = ({
  step,
  currentModule,
  onWatchVideo,
  handleBackToTeacherPortal,
  setCurrentStep,
  steps,
  showing,
}: ICompletableStepProps) => {
  const sendEvent = useAnalytics();
  const stepIndex = step.taskNumber - 1;

  const showVideo = isIntro(step) || isVideo(step);

  // The extra guidance video will be shown if the task has one, unless the Show Video button is also being displayed
  const showGuidanceVideo = (isTask(step) || isVideo(step)) && step.guidanceVideo && !showVideo;

  const nextStep = useMemo(() => {
    if (!step.isComplete) {
      return;
    }
    return steps[stepIndex + 1];
  }, [step, stepIndex, steps]);

  const onNextButton = (nextStep: Step) => {
    if (isSummary(nextStep)) {
      sendEvent(clickedOnSummaryEvent(step));
    } else {
      sendEvent(clickedOnNextStepEvent(step, nextStep.taskNumber - 1));
    }
    setCurrentStep(nextStep.taskNumber - 1);
  };

  return (
    <div className={styles.TrainingModeStep}>
      <h3 className={styles.TrainingModeStepDetailsTitle}>
        <strong>Step {step.taskNumber}</strong>: {step.title}
      </h3>
      {step.context && <span className={styles.TrainingModeStepContext}>{step.context}</span>}
      {step.instructions && (
        <span className={styles.TrainingModeStepInstructions}>{step.instructions}</span>
      )}
      {step.isComplete && <StepComplete title="Step complete!" text={step.completeText} />}
      <div className={styles.TrainingModeStepCompleteActionButtons}>
        {showGuidanceVideo && (
          <button
            className={classNames(
              styles.TrainingLinkButton,
              styles.TrainingModeShowMe,
              accessibilityStyles.FocusTarget,
            )}
            onClick={onWatchVideo}
            tabIndex={showing ? 0 : -1}
          >
            <VideoIcon className={styles.ShowMeHowIcon} />
            Show me how
          </button>
        )}
        {showVideo && (
          <button
            onClick={onWatchVideo}
            className={classNames(
              styles.ActionButton,
              styles.WatchVideoButton,
              accessibilityStyles.FocusTarget,
            )}
            tabIndex={showing ? 0 : -1}
          >
            <VideoIcon className={styles.VideoIconBlue} />
            Watch video
          </button>
        )}
        {nextStep && (
          <button
            onClick={() => onNextButton(nextStep)}
            className={classNames(
              styles.ActionButton,
              styles.NextStepButton,
              accessibilityStyles.FocusTarget,
            )}
            tabIndex={showing ? 0 : -1}
          >
            {isSummary(nextStep) ? 'Summary' : 'Next step'} <ChevronRight />
          </button>
        )}
      </div>
      {currentModule.isComplete && currentModule.moduleComplete && (
        <ModuleComplete
          moduleComplete={currentModule.moduleComplete}
          handleBackToTeacherPortal={handleBackToTeacherPortal}
          showing={showing}
        />
      )}
    </div>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/swworker/v1/worker.proto" (package "sparx.swworker.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { UpdateStudentLabelsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { UpdateStudentLabelsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { SubmitStudentSentimentFeedbackResponse } from '../../interaction/feedback/v1/feedback';
import { SubmitStudentSentimentFeedbackRequest } from '../../interaction/feedback/v1/feedback';
import { StudentRewards } from '../../../../sparxweb/swmsg/sparxweb';
import { GetStudentRewardsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetActivityFeedResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { GetActivityFeedRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { Ack } from '../../../../sparxweb/swmsg/sparxweb';
import { UpdateTutorialsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetTrainingPackagesRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { AbortQuizResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { AbortQuizRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetQuizResultsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { GetQuizResultsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { SubmitQuizAnswersRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { SetLeagueAvatarResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { SetLeagueAvatarRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetLeagueMedalsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { GetLeagueMedalsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetLeagueAvatarResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { GetLeagueAvatarRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { SaveLeagueGameScoreResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { SaveLeagueGameScoreRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetLeagueResultsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { GetLeagueResultsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { ActivityAction } from '../../../../sparxweb/swmsg/sparxweb';
import { Activity } from '../../../../sparxweb/swmsg/sparxweb';
import { GetActivityRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { PackageDataRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { SessionDetailsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { SessionDetailsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { TablesAnswer } from '../../games/progress/tablesanswer/v1/tablesanswer';
import { PackagePosition } from '../../../../sparxweb/swmsg/sparxweb';
import { Reward } from '../../../../sparxweb/swmsg/sparxweb';
import { TetherVideo } from '../../../../sparxweb/swmsg/sparxweb';
import { TetherQuestion } from '../../../../sparxweb/swmsg/sparxweb';
import { PackageData } from '../../packages/v1/spxpkg';
import { ActivityActionResponse as ActivityActionResponse$ } from '../../../../sparxweb/swmsg/sparxweb';
/**
 * @generated from protobuf message sparx.swworker.v1.ActivityActionResponse
 */
export interface ActivityActionResponse {
  /**
   * @generated from protobuf field: sparxweb.ActivityActionResponse response = 1;
   */
  response?: ActivityActionResponse$;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageData package_data = 2;
   */
  packageData?: PackageData;
}
/**
 * @generated from protobuf message sparx.swworker.v1.GetTetherRequest
 */
export interface GetTetherRequest {
  /**
   * @generated from protobuf field: string session_name = 1;
   */
  sessionName: string;
  /**
   * @generated from protobuf field: string last_tether_id = 2;
   */
  lastTetherId: string;
}
/**
 * @generated from protobuf message sparx.swworker.v1.GetTetherResponse
 */
export interface GetTetherResponse {
  /**
   * @generated from protobuf field: string tether_id = 1;
   */
  tetherId: string;
  /**
   * TODO maybe this could be a oneof
   *
   * @generated from protobuf field: sparxweb.TetherQuestion question = 2;
   */
  question?: TetherQuestion;
  /**
   * @generated from protobuf field: sparxweb.TetherVideo video = 3;
   */
  video?: TetherVideo;
}
/**
 * @generated from protobuf message sparx.swworker.v1.SubmitQuizAnswersResponse
 */
export interface SubmitQuizAnswersResponse {
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageData package_data = 1;
   */
  packageData?: PackageData;
  /**
   * @generated from protobuf field: repeated sparxweb.Reward rewards = 2;
   */
  rewards: Reward[];
}
/**
 * @generated from protobuf message sparx.swworker.v1.SwapOutTaskItemRequest
 */
export interface SwapOutTaskItemRequest {
  /**
   * The package position of the task item to be swapped out
   *
   * @generated from protobuf field: sparxweb.PackagePosition position = 1;
   */
  position?: PackagePosition;
}
/**
 * @generated from protobuf message sparx.swworker.v1.SwapOutTaskItemResponse
 */
export interface SwapOutTaskItemResponse {
  /**
   * Data about packages, tasks and task items that have changed.
   *
   * @generated from protobuf field: sparx.packages.v1.PackageData packageData = 1;
   */
  packageData?: PackageData;
}
/**
 * @generated from protobuf message sparx.swworker.v1.SendIndependentLearningTablesAnswersRequest
 */
export interface SendIndependentLearningTablesAnswersRequest {
  /**
   * @generated from protobuf field: repeated sparx.games.progress.tablesanswer.v1.TablesAnswer answers = 1;
   */
  answers: TablesAnswer[];
}
/**
 * @generated from protobuf message sparx.swworker.v1.SendIndependentLearningTablesAnswersResponse
 */
export interface SendIndependentLearningTablesAnswersResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionResponse$Type extends MessageType<ActivityActionResponse> {
  constructor() {
    super('sparx.swworker.v1.ActivityActionResponse', [
      {
        no: 1,
        name: 'response',
        kind: 'message',
        T: () => ActivityActionResponse$,
      },
      { no: 2, name: 'package_data', kind: 'message', T: () => PackageData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.ActivityActionResponse
 */
export const ActivityActionResponse = new ActivityActionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTetherRequest$Type extends MessageType<GetTetherRequest> {
  constructor() {
    super('sparx.swworker.v1.GetTetherRequest', [
      {
        no: 1,
        name: 'session_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'last_tether_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.GetTetherRequest
 */
export const GetTetherRequest = new GetTetherRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTetherResponse$Type extends MessageType<GetTetherResponse> {
  constructor() {
    super('sparx.swworker.v1.GetTetherResponse', [
      { no: 1, name: 'tether_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'question', kind: 'message', T: () => TetherQuestion },
      { no: 3, name: 'video', kind: 'message', T: () => TetherVideo },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.GetTetherResponse
 */
export const GetTetherResponse = new GetTetherResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitQuizAnswersResponse$Type extends MessageType<SubmitQuizAnswersResponse> {
  constructor() {
    super('sparx.swworker.v1.SubmitQuizAnswersResponse', [
      { no: 1, name: 'package_data', kind: 'message', T: () => PackageData },
      {
        no: 2,
        name: 'rewards',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Reward,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.SubmitQuizAnswersResponse
 */
export const SubmitQuizAnswersResponse = new SubmitQuizAnswersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SwapOutTaskItemRequest$Type extends MessageType<SwapOutTaskItemRequest> {
  constructor() {
    super('sparx.swworker.v1.SwapOutTaskItemRequest', [
      { no: 1, name: 'position', kind: 'message', T: () => PackagePosition },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.SwapOutTaskItemRequest
 */
export const SwapOutTaskItemRequest = new SwapOutTaskItemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SwapOutTaskItemResponse$Type extends MessageType<SwapOutTaskItemResponse> {
  constructor() {
    super('sparx.swworker.v1.SwapOutTaskItemResponse', [
      { no: 1, name: 'packageData', kind: 'message', T: () => PackageData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.SwapOutTaskItemResponse
 */
export const SwapOutTaskItemResponse = new SwapOutTaskItemResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendIndependentLearningTablesAnswersRequest$Type extends MessageType<SendIndependentLearningTablesAnswersRequest> {
  constructor() {
    super('sparx.swworker.v1.SendIndependentLearningTablesAnswersRequest', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TablesAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.SendIndependentLearningTablesAnswersRequest
 */
export const SendIndependentLearningTablesAnswersRequest =
  new SendIndependentLearningTablesAnswersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendIndependentLearningTablesAnswersResponse$Type extends MessageType<SendIndependentLearningTablesAnswersResponse> {
  constructor() {
    super('sparx.swworker.v1.SendIndependentLearningTablesAnswersResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.swworker.v1.SendIndependentLearningTablesAnswersResponse
 */
export const SendIndependentLearningTablesAnswersResponse =
  new SendIndependentLearningTablesAnswersResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.swworker.v1.Sparxweb
 */
export const Sparxweb = new ServiceType('sparx.swworker.v1.Sparxweb', [
  {
    name: 'GetSessionDetails',
    options: {},
    I: SessionDetailsRequest,
    O: SessionDetailsResponse,
  },
  {
    name: 'GetPackageData',
    options: {},
    I: PackageDataRequest,
    O: PackageData,
  },
  { name: 'GetActivity', options: {}, I: GetActivityRequest, O: Activity },
  {
    name: 'ActivityAction',
    options: {},
    I: ActivityAction,
    O: ActivityActionResponse,
  },
  {
    name: 'GetLeagueResults',
    options: {},
    I: GetLeagueResultsRequest,
    O: GetLeagueResultsResponse,
  },
  {
    name: 'SaveLeagueGameScore',
    options: {},
    I: SaveLeagueGameScoreRequest,
    O: SaveLeagueGameScoreResponse,
  },
  {
    name: 'GetLeagueAvatar',
    options: {},
    I: GetLeagueAvatarRequest,
    O: GetLeagueAvatarResponse,
  },
  {
    name: 'GetLeagueMedals',
    options: {},
    I: GetLeagueMedalsRequest,
    O: GetLeagueMedalsResponse,
  },
  {
    name: 'SetLeagueAvatar',
    options: {},
    I: SetLeagueAvatarRequest,
    O: SetLeagueAvatarResponse,
  },
  {
    name: 'SubmitQuizAnswers',
    options: {},
    I: SubmitQuizAnswersRequest,
    O: SubmitQuizAnswersResponse,
  },
  {
    name: 'GetQuizResults',
    options: {},
    I: GetQuizResultsRequest,
    O: GetQuizResultsResponse,
  },
  { name: 'AbortQuiz', options: {}, I: AbortQuizRequest, O: AbortQuizResponse },
  {
    name: 'GetTrainingPackages',
    options: {},
    I: GetTrainingPackagesRequest,
    O: PackageData,
  },
  { name: 'UpdateTutorials', options: {}, I: UpdateTutorialsRequest, O: Ack },
  { name: 'GetTether', options: {}, I: GetTetherRequest, O: GetTetherResponse },
  {
    name: 'GetActivityFeed',
    options: {},
    I: GetActivityFeedRequest,
    O: GetActivityFeedResponse,
  },
  {
    name: 'GetStudentRewards',
    options: {},
    I: GetStudentRewardsRequest,
    O: StudentRewards,
  },
  {
    name: 'SubmitStudentSentimentFeedback',
    options: {},
    I: SubmitStudentSentimentFeedbackRequest,
    O: SubmitStudentSentimentFeedbackResponse,
  },
  {
    name: 'UpdateStudentLabels',
    options: {},
    I: UpdateStudentLabelsRequest,
    O: UpdateStudentLabelsResponse,
  },
  {
    name: 'SwapOutTaskItem',
    options: {},
    I: SwapOutTaskItemRequest,
    O: SwapOutTaskItemResponse,
  },
  {
    name: 'SendIndependentLearningTablesAnswers',
    options: {},
    I: SendIndependentLearningTablesAnswersRequest,
    O: SendIndependentLearningTablesAnswersResponse,
  },
]);

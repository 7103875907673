import { GapEvaluation, Hint } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { IElement, IGroupElement, IInput } from '@sparx/question';
import { useEffect } from 'react';

/**
 * useFillFirstChancePartRefsCorrectRef fills the set in the given ref with the refs of gaps where all
 * parts in the part containing the gap are correct, according to the given gap evaluations and part
 * refs
 */
export const useFillFirstChancePartRefsCorrectRef = (
  firstChancePartRefsCorrectRef: React.MutableRefObject<Set<string>> | undefined,
  gapEvaluations: { [key: string]: GapEvaluation } | undefined,
  partRefs: string[][],
) => {
  useEffect(() => {
    if (firstChancePartRefsCorrectRef === undefined || gapEvaluations === undefined) {
      return;
    }
    // for each gap evaluation, check if the whole part is correct and, if so, add the refs to the set
    for (const ref in gapEvaluations) {
      const refsInPart = partRefs.find(part => part.includes(ref));
      if (!refsInPart) {
        continue;
      }
      let incorrectRefFound = false;
      for (const partRef of refsInPart) {
        const gapEvaluation = gapEvaluations[partRef];
        if (!gapEvaluation.correct) {
          incorrectRefFound = true;
          break;
        }
      }
      if (!incorrectRefFound) {
        refsInPart.forEach(ref => firstChancePartRefsCorrectRef.current.add(ref));
      }
      if (incorrectRefFound) {
        refsInPart.forEach(ref => firstChancePartRefsCorrectRef.current.delete(ref));
      }
    }
  }, [firstChancePartRefsCorrectRef, gapEvaluations, partRefs]);
};

/**
 * getRefsInParts returns an array of arrays of refs in the parts of the given element
 */
export const getRefsInParts = (element?: IElement, partArr?: string[][]) => {
  if (!partArr) {
    partArr = [];
  }
  if (!element) {
    return partArr;
  }
  if (elementIsGroup(element)) {
    if (element.type.includes('answer-part')) {
      partArr.push(
        element.content.reduce((acc, el) => {
          if ('ref' in el) {
            acc.push(el.ref);
          }
          return acc;
        }, [] as string[]),
      );
      return partArr;
    }
    if (element.type.includes('question')) {
      return partArr;
    }
    element.content.forEach(el => getRefsInParts(el, partArr));
  }
  return partArr;
};

const elementIsGroup = (element: IElement): element is IGroupElement => element.element === 'group';

/**
 * hasInputForRefsAnswerPart checks if input has any values for any refs within the answer part which
 * contains the given ref
 */
export const hasInputForRefsAnswerPart = (ref: string, input: IInput, partRefs: string[][]) => {
  // get the refs in the part containing the given ref
  const refs = partRefs.find(part => part.includes(ref));
  // check if any of the refs in the part have a value in the input
  // flatten the input object
  const refValueMap = flattenInput(input);
  return refs?.some(ref => refValueMap[ref] !== undefined && refValueMap[ref] !== '');
};

/**
 * resetInputForRefsAnswerPart returns a new input which is a copy of the given input with the values
 * reset for any refs within the answer part which contains the given ref. It currently only works on
 * number fields.
 */
export const resetInputForRefsAnswerPart = (ref: string, input: IInput, partRefs: string[][]) => {
  const refsToClear = partRefs.find(part => part.includes(ref));
  const newInput = JSON.parse(JSON.stringify(input)) as IInput;
  refsToClear?.forEach(ref => {
    if (newInput.number_fields) {
      newInput.number_fields[ref] = {
        value: '',
        sign: 'positive',
      };
    }
  });
  return newInput;
};

const flattenInput = (input: IInput) => {
  const refValueMap: Record<string, string | undefined> = {};
  Object.keys(input.number_fields || {}).forEach(ref => {
    refValueMap[ref] = input.number_fields?.[ref].value;
  });
  // Object.keys(input.slots || {}).forEach(ref => {
  //   refValueMap[ref] = input.slots?.[ref].card_ref;
  // });
  // Object.keys(input.choices || {}).forEach(ref => {
  //   refValueMap[ref] = input.choices?.[ref].
  // });
  return refValueMap;
};

/**
 * getPlaceholdersFromInput returns a map of ref to value for the refs in the input which have a hint.
 * It currently only works for number fields.
 */
export const getPlaceholdersFromInput = (
  input: IInput,
  hints: Dictionary<string, Hint> | undefined,
) => {
  if (!hints) {
    return undefined;
  }

  const placeholderMap: Dictionary<string, string> = {};

  Object.entries(input.number_fields || {}).forEach(kv => {
    const [ref, value] = kv;
    if (hints[ref]) {
      placeholderMap[ref] = value.value;
    }
  });
  // Object.entries(input.slots || {}).forEach(kv => {
  //   const [ref, value] = kv;
  //   if (hints[ref]) {
  //     placeholderMap[ref] = value.card_ref;
  //   }
  // });
  // Object.entries(input.choices || {}).forEach(kv => {
  //   const [ref, value] = kv;
  //   if (hints[ref]) {
  //     placeholderMap[ref] = value.selected;
  //   }
  // });
  return placeholderMap;
};

import {
  TrainingModuleState,
  TrainingProgress,
  UpdateTrainingProgressForCurrentUserRequest,
} from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { getNowTimestamp } from '@sparx/react-utils';
import styles from 'components/training/TrainingBanner.module.css';
import { castDraft, Draft, produce } from 'immer';
import {
  CompletableStep,
  isCompletable,
  isSummary,
  Module,
  ModuleCache,
  Step,
  UnknownModuleError,
  UnknownStepError,
} from 'types/training';

/**
 * Utilities relating to teacher training
 */

const TRAINING_MODE_LOCAL_STORAGE_KEY = 'sparxweb2/training_mode_enabled';

const TRAINING_PACKAGE_TYPES_MAP: Record<string, string> = {
  'homework-training': 'homework',
  'optional-homework-training': 'optional-homework',
  'targets-training': 'targets',
};

const TRAINING_PACKAGE_TYPES = Object.keys(TRAINING_PACKAGE_TYPES_MAP);
const NON_TRAINING_HOMEWORK_PACKAGE_TYPES = Object.values(TRAINING_PACKAGE_TYPES_MAP);

export const isTrainingPackageType = (pkgType: string) => TRAINING_PACKAGE_TYPES.includes(pkgType);

export const isNonTrainingHomeworkPackageType = (pkgType: string) =>
  NON_TRAINING_HOMEWORK_PACKAGE_TYPES.includes(pkgType);

/**
 * For a training package returns the equivalent normal homework package type - e.g. optional-homework-training becomes
 * optional-homework
 * @param trainingPkgType
 */
export const getEquivalentHomeworkPackageType = (trainingPkgType: string) =>
  TRAINING_PACKAGE_TYPES_MAP[trainingPkgType];

/**
 * Get whether training mode is enabled for teachers. This is set to true if the user follows a link from Teacher Portal
 * including ?training=true as a search parameter, and is set back to false if the user logs out or returns to TP.
 */
export const isTrainingModeEnabled = () =>
  localStorage.getItem(TRAINING_MODE_LOCAL_STORAGE_KEY) === 'true';

/**
 * Store in local storage whether training mode is enabled for teachers
 * @param enabled
 */
export const setTrainingModeEnabled = (enabled: boolean) =>
  localStorage.setItem(TRAINING_MODE_LOCAL_STORAGE_KEY, enabled ? 'true' : 'false');

/**
 * Returns whether or not the given module is completed
 * @param module
 */
const isModuleComplete = (module: Module): boolean =>
  module.isComplete ||
  (!!module.steps.length &&
    module.steps.reduce((c, s) => c && (!isCompletable(s) || s.isComplete), true));

/**
 * Syncs the given module with the given training progress to return an updated module cache.
 * Information in the training progress includes completion data for each step and the module.
 * @param modules
 * @param trainingProgress
 */
export const resyncTrainingProgress = (
  modules: ModuleCache,
  trainingProgress: TrainingProgress,
): ModuleCache => produce(modules, draft => doResyncTrainingProgress(draft, trainingProgress));

/**
 * Do the syncing between module cache and training progress
 * @param modules
 * @param trainingProgress
 */
const doResyncTrainingProgress = (
  modules: Draft<ModuleCache>,
  trainingProgress: TrainingProgress,
) => {
  // First clear all current completion
  for (const m in modules) {
    const module = modules[m];
    module.isComplete = false;
    for (const s of module.steps) {
      if (isCompletable(s)) {
        castDraft(s).isComplete = false;
      }
    }
  }
  // Then set all completion
  for (const m in trainingProgress.moduleData) {
    const md = trainingProgress.moduleData[m];
    for (const stepName of md.completedSteps) {
      const module = modules[m];
      if (module) {
        module.isComplete = !!md.completedTime;
        const step = module.steps[module.stepMap[stepName]];
        if (step && isCompletable(step)) {
          castDraft(step).isComplete = true;
        }
      }
    }
  }
  // Then calculate module completions
  for (const m in modules) {
    const module = modules[m];
    module.isComplete = module.isComplete || isModuleComplete(module);
  }
};

/**
 * Returns the index of the first incomplete step from the list of steps provided. If all steps are
 * completed then the index of the last step is returned.
 */
export const firstIncompleteStep = (steps: Step[]): number | undefined => {
  const step = steps.findIndex(s => isCompletable(s) && !s.isComplete);

  // If all steps are completed, just set to the last step:
  if (step === -1 && steps.length > 0) {
    return steps.length - 1;
  }

  return step === -1 ? undefined : step;
};

/**
 * Takes a list of steps and returns just those that are completable (that is, not a summary step)
 * @param steps
 */
export const filterCompletableTrainingSteps = (steps: Step[]) =>
  steps.filter((step): step is CompletableStep => isCompletable(step));

/**
 * Apply simple parsing to a string
 * @param point
 */
export const parseSummaryPoint = (point: string) => {
  const linkRegExp = /\[(.*?)]\((.*?)\)/gim;
  return point.replace(
    linkRegExp,
    `<a href="$2" target="_blank" rel="noopener" class=${styles.TrainingModeLink}>$1</a>`,
  );
};

/**
 * getNextIncompleteTrainingStepIndex returns the index of the next training step after the one
 * provided that is not complete. If currentStepIndex is undefined then return undefined. If there
 * are no incomplete steps after the one provided then instead return the first incomplete step. If
 * there is no such step then return undefined.
 */
export const getNextIncompleteTrainingStepIndex = (
  module: Module,
  currentStepIndex: number | undefined,
) => {
  if (currentStepIndex === undefined) {
    return undefined;
  }
  const completableSteps = filterCompletableTrainingSteps(module.steps);
  // Return the index of the first incomplete step after the index provided
  for (let i = currentStepIndex + 1; i < completableSteps.length; i++) {
    if (!completableSteps[i].isComplete) {
      return i;
    }
  }
  // If there are no incomplete steps after the one provided then return the index of the
  // first incomplete step
  const incompleteStepIndex = completableSteps.findIndex(s => !s.isComplete);
  return incompleteStepIndex !== -1 ? incompleteStepIndex : undefined;
};

/**
 * Returns whether the given step of the given training module is complete in the module cache
 * @param modules
 * @param moduleName
 * @param stepID
 */
export const isTaskComplete = (
  modules: ModuleCache,
  moduleName: string,
  stepID: string,
): boolean => {
  const module = modules[moduleName];
  const step = module.steps[module.stepMap[stepID]];
  return isSummary(step) || step.isComplete;
};

/**
 * Mark a task as complete in the module with the given name
 * @param modules all training modules
 * @param moduleName the training module to update
 * @param stepID the id of the step to complete
 */
export const completeTrainingTask = (
  modules: ModuleCache,
  moduleName: string,
  stepID: string,
): ModuleCache =>
  produce(modules, (draft: Draft<ModuleCache>) =>
    doCompleteTrainingTask(draft, moduleName, stepID),
  );

/**
 * Implementation of completeTrainingTask using an immer draft
 * @param modules
 * @param moduleName
 * @param stepID
 */
const doCompleteTrainingTask = (
  modules: Draft<ModuleCache>,
  moduleName: string,
  stepID: string,
) => {
  const module = modules[moduleName];
  const step = module.steps[module.stepMap[stepID]];
  if (step === undefined) {
    throw new UnknownStepError(stepID);
  }
  if (isCompletable(step)) {
    castDraft(step).isComplete = true;
  }
};

/**
 * Calculate whether all tasks in a module are complete, and if so mark the module as complete
 * @param modules training modules
 * @param moduleName the name of the module to check for completion
 */
export const calculateModuleCompletion = (modules: ModuleCache, moduleName: string): ModuleCache =>
  produce(modules, (draft: Draft<ModuleCache>) => doCalculateModuleCompletion(draft, moduleName));

/**
 * Implementation of calculateModuleCompletion using an immer draft
 * @param modules
 * @param moduleName
 */
const doCalculateModuleCompletion = (modules: Draft<ModuleCache>, moduleName: string) => {
  if (!(moduleName in modules)) {
    throw new UnknownModuleError(moduleName);
  }
  const module = modules[moduleName];
  module.isComplete = isModuleComplete(module);
};

/**
 * Creates the server request to update training progress given the current training module progress
 * @param modules all modules
 * @param moduleName the name of the module to update
 * @param addCompletedTime whether to add the module completed time to the request
 */
export const createUpdateTrainingProgressForCurrentUserRequest = (
  modules: ModuleCache,
  moduleName: string,
  addCompletedTime: boolean,
): UpdateTrainingProgressForCurrentUserRequest => {
  const moduleState = modules[moduleName];
  const data: TrainingModuleState = {
    completedSteps: moduleState.steps
      .filter(s => isCompletable(s) && s.isComplete)
      .map(s => s.id.split('/')[1]),
    annotations: { 'sparx.schools/name': `schools/${window.__sparxweb.schoolID}` },
    name: '', // Not needed - just for typescript
  };
  const mask: FieldMask = { paths: ['module_data.completed_steps', 'annotations'] };

  if (addCompletedTime) {
    data.completedTime = moduleState.isComplete ? getNowTimestamp() : undefined;
    mask.paths.push('module_data.completed_time');
  }
  return {
    trainingProgress: {
      moduleData: {
        [moduleName]: data,
      },
      // The following fields are not needed, just included for typescript
      name: '',
      aliases: [],
      annotations: { 'sparx.schools/name': `schools/${window.__sparxweb.schoolID}` },
    },
    updateMask: mask,
  };
};

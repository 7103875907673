import { NotificationDisplayData } from '@sparx/api/apis/sparx/notifications/notifications/v1/notifications';
import { NotificationType } from 'components/notification-list/common';
import { isValidEntry, shouldDisplayPromotedEntry } from 'components/whats-new/hooks';
import { WhatsNewEntry } from 'components/whats-new/types';
import ImageBouncing from 'images/notifications/ImageBouncing.svg';
import ImageEarly from 'images/notifications/ImageEarly.svg';
import ImageFTQ from 'images/notifications/ImageFTQ.png';
import ImageSW2 from 'images/notifications/ImageSW2.svg';
import ImageWelcome from 'images/notifications/ImageWelcome.svg';
import { FlagValue } from 'utils/feature-flags';

const levelToEarliestCompleterText = (level?: number): string => {
  switch (level) {
    case 1:
      return 'the first';
    case 2:
      return 'the second';
    case 3:
      return 'the third';
  }
  return 'one of the first';
};

// getNotificationData returns display data for the specified notification type and level
export const getNotificationDisplayData = (
  whatsNewEntries: WhatsNewEntry[],
  flags: Record<string, FlagValue>,
  type: NotificationType,
  level?: number,
  additionalInfoString?: string,
  isPrimaryStudent = false,
): NotificationDisplayData | undefined => {
  switch (type) {
    case NotificationType.WhatsNew:
      return getWhatsNewNotificationDisplayData(
        flags,
        whatsNewEntries,
        isPrimaryStudent,
        additionalInfoString,
      );
    case NotificationType.WelcomeToNotifications:
      return {
        title: 'Notifications are here',
        message:
          'You can find notifications about your achievements, what’s new in Sparx and more here',
        image: ImageWelcome,
      };
    case NotificationType.BouncingBack:
      return {
        title: 'You bounced back',
        message:
          'Great effort! You completed your Compulsory homework this week after not completing it the week before',
        image: ImageBouncing,
      };
    case NotificationType.EarliestCompleter:
      return {
        title: 'Early Completer',
        message: `Well done! You were ${levelToEarliestCompleterText(
          level,
        )} in your class to complete your Compulsory homework last week.`,
        image: ImageEarly,
      };
    case NotificationType.WelcomeToSparxWeb2:
      return {
        title: 'Exciting Update: Discover the new look Sparx Maths!',
        message: `We have fantastic news to share with you! We've upgraded Sparx Maths to help you succeed and increase your confidence.`,
        image: ImageSW2,
      };
    case NotificationType.FTQEnabled:
      return {
        title: 'Fast Track enabled',
        message:
          'Fast Track is now enabled. Remember, Fast Track is available when you complete three compulsory homeworks in a row.',
        image: ImageFTQ,
      };
    case NotificationType.ColourOverlaysEnabled:
      return {
        title: 'Colour Overlays',
        message:
          'We have added colour overlays to Sparx Maths which may help you to read questions more easily. You can try different colours using the Settings page',
        image: ImageSW2,
        link: 'settings',
      };
    default:
      return undefined;
  }
};

const getWhatsNewNotificationDisplayData = (
  flags: Record<string, FlagValue>,
  whatsNewEntries: WhatsNewEntry[],
  isPrimaryStudent: boolean,
  whatsNewID?: string,
): NotificationDisplayData | undefined => {
  if (whatsNewID === undefined || whatsNewEntries === undefined) {
    return undefined;
  }
  const entry = whatsNewEntries.find(e => e.notification?.id === whatsNewID);

  if (
    entry === undefined ||
    entry.notification === undefined ||
    !isValidEntry(entry, flags, isPrimaryStudent) ||
    !shouldDisplayPromotedEntry(entry)
  ) {
    return undefined;
  }
  return {
    title: entry.notification.title,
    message: entry.notification.message,
    // vuli todo: could be part of whats new post
    image: ImageSW2,
    link: entry.notification.linkToWhatsNew ? `whatsnew/${entry.notification.id}` : undefined,
  };
};

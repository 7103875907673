import hundredClub from 'images/games/icons/100club-square.png';
import chantandlearn from 'images/games/icons/chantandlearn-square.png';
import compoundquestions from 'images/games/icons/compound_questions_thumb.png';
import eggbaskets from 'images/games/icons/egg_baskets_thumb.png';
import findittables from 'images/games/icons/find_it_tables_thumb.png';
import gardengame from 'images/games/icons/garden_game_thumb.png';
import gemsmash from 'images/games/icons/gemsmash-square.png';
import gridpathgame from 'images/games/icons/grid_path_game_thumb.png';
import marblesmasher from 'images/games/icons/marble_smasher_thumb.png';
import missingphoneme from 'images/games/icons/missing_phoneme_thumb.png';
import multiplayergame from 'images/games/icons/multiplayer_game_thumb.png';
import peggrid from 'images/games/icons/peg_grid_thumb.png';
import pictureshuffle from 'images/games/icons/pictureshuffle-square.png';
import rhythmgame from 'images/games/icons/rhythm_game_thumb.png';
import slidetables from 'images/games/icons/slidetables-square.png';
import stickercollector from 'images/games/icons/stickercollector-square.png';
import tabletoppers from 'images/games/icons/table_topper_thumb.png';
import talktome from 'images/games/icons/talk_to_me_thumb.png';
import typeyourtargets from 'images/games/icons/type_your_targets_thumb.png';
import wordforpicture from 'images/games/icons/word_for_picture_thumb.png';
import { exists } from 'utils/math';

// the special assessment adds this many progress points to the task
export const SPECIAL_ASSESSMENT_PROGRESS_SIZE = 25;

// We disable eslint for the below because we need "any" type when lazily importing
// from the games framework, but eslint does not let us use "any" type

// eslint-disable-next-line
const QuizAlgorithmLazy: () => any = () =>
  import(
    '@sparx/games/quiz-games/algorithm-interface/algorithm-interface' /* webpackChunkName: "games" */
  );
// eslint-disable-next-line
const DailyTokensLazy: () => any = () =>
  import(
    '@sparx/games/quiz-games/daily-tokens/daily-token-handler' /* webpackChunkName: "games" */
  );
// eslint-disable-next-line
const TalkAndLearnGatekeeperLazy: () => any = () =>
  import(
    '@sparx/games/quiz-games/talk-and-learn-control/talk-and-learn-control-handler' /* webpackChunkName: "games" */
  );
// eslint-disable-next-line
const AbilityValidationGatekeeperLazy: () => any = () =>
  import(
    '@sparx/games/quiz-games/ability-validation/ability-validation-handler' /* webpackChunkName: "games" */
  );
// eslint-disable-next-line
const TablesTaskMonitorLazy: () => any = () =>
  import(
    '@sparx/games/quiz-games/tables-task-monitor/tables-task-monitor' /* webpackChunkName: "games" */
  );
// eslint-disable-next-line
const SpecialAssessmentLazy: () => any = () =>
  import(
    '@sparx/games/quiz-games/special-assessment/special-assessment-handler' /* webpackChunkName: "games" */
  );
// eslint-disable-next-line
const ErrorHandlerLazy: () => any = () =>
  import('@sparx/games/quiz-games/error-handler/error-handler' /* webpackChunkName: "games" */);

export async function GetQuizAlgorithmLazy(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { QuizAlgorithm } = await QuizAlgorithmLazy();
  if (featureFlags) {
    QuizAlgorithm.applyFeatureFlags(featureFlags);
  }
  return { QuizAlgorithm };
}

export async function GetTalkAndLearnGatekeeperLazy(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { TalkAndLearnGatekeeper } = await TalkAndLearnGatekeeperLazy();
  if (featureFlags) {
    TalkAndLearnGatekeeper.applyFeatureFlags(featureFlags);
  }
  return { TalkAndLearnGatekeeper };
}

export async function GetDailyTokensLazy() {
  const { DailyTokens } = await DailyTokensLazy();
  return { DailyTokens };
}

export async function GetSpecialAssessmentLazy() {
  const { SpecialAssessmentGatekeeper } = await SpecialAssessmentLazy();
  return { SpecialAssessmentGatekeeper };
}

export async function GetErrorHandlerLazy() {
  const { ErrorHandler } = await ErrorHandlerLazy();
  return { ErrorHandler };
}

export async function GetAbilityValidationGatekeeperLazy(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { AbilityValidationGatekeeper } = await AbilityValidationGatekeeperLazy();
  if (featureFlags) {
    AbilityValidationGatekeeper.applyFeatureFlags(featureFlags);
  }
  return { AbilityValidationGatekeeper };
}

export async function GetTablesTaskMonitorLazy() {
  const { TablesTaskMonitor } = await TablesTaskMonitorLazy();
  return { TablesTaskMonitor };
}

export const GAME_CATEGORIES = {
  QUIZ: 'quiz',
  REWARD: 'reward',
  ASSESSMENT: 'assessment',
};

export interface IGameParameters {
  name: string;
  image: string;
  hideInList?: boolean;
  gameInfoId: string;
  usesPendingTaL?: boolean;
  isPracticeApp?: boolean;
  allowedSets?: string[];
  tokenRequirement?: number;
  isMultiplayerApp?: boolean;
}

export const TimesTablesGameParameters: Record<string, IGameParameters> = {
  HundredClub: {
    name: '100 Club',
    image: hundredClub,
    hideInList: true,
    gameInfoId: '100club',
  },
  GemSmash: {
    name: 'Gem Smash',
    image: gemsmash,
    // usesPendingTaL - true if and only if this game allows the player to
    //                  remove questions from their "pending TaL" list
    usesPendingTaL: true,
    gameInfoId: 'gemsmash',
  },
  PictureShuffle: {
    name: 'Build a Zoo',
    image: pictureshuffle,
    usesPendingTaL: true,
    gameInfoId: 'pictureshuffle',
  },
  StickerCollector: {
    name: 'Sticker Collector',
    image: stickercollector,
    usesPendingTaL: true,
    gameInfoId: 'stickercollector',
  },
  SlideTables: {
    name: 'Slide Tables',
    image: slidetables,
    allowedSets: [''], // only allow times tables
    gameInfoId: 'slidetables',
  },
  ChantAndLearn: {
    name: 'Talk and Learn',
    image: chantandlearn,
    allowedSets: [''], // only allow times tables
    // Practice apps should be selected from a separate section to games
    isPracticeApp: true,
    gameInfoId: 'chantandlearn',
  },
  PegGrid: {
    name: 'Place the Pegs',
    image: peggrid,
    allowedSets: [''], // only allow times tables
    isPracticeApp: true,
    gameInfoId: 'peggrid',
  },
  EggBaskets: {
    name: 'Fill the Baskets',
    image: eggbaskets,
    allowedSets: [''], // only allow times tables
    isPracticeApp: true,
    gameInfoId: 'eggbaskets',
  },
  TalkToMe: {
    name: 'Talk to Me',
    image: talktome,
    allowedSets: [''], // only allow times tables
    tokenRequirement: 0,
    isPracticeApp: true,
    gameInfoId: 'talktome',
  },
  CompoundQuestion: {
    name: 'Questions with Brackets',
    image: compoundquestions,
    allowedSets: [''], // only allow times tables
    isPracticeApp: true,
    gameInfoId: 'compoundquestion',
  },
  TypeYourTargets: {
    name: 'Type Your Targets',
    image: typeyourtargets,
    allowedSets: [''], // only allow times tables
    isPracticeApp: true,
    gameInfoId: 'typeyourtargets',
  },
  LeagueTableGame: {
    name: 'Table Toppers',
    image: tabletoppers,
    allowedSets: [''], // only allow times tables
    usesPendingTaL: true,
    gameInfoId: 'leaguetablegame',
  },
  FindItTables: {
    name: 'Find It!',
    image: findittables,
    allowedSets: [''], // only allow times tables
    usesPendingTaL: true,
    gameInfoId: 'findittables',
  },
  GardenGame: {
    name: 'Gardens in the Sky',
    image: gardengame,
    allowedSets: [''], // only allow times tables
    usesPendingTaL: true,
    gameInfoId: 'gardengame',
  },
  GridPathGame: {
    name: 'Cross the River',
    image: gridpathgame,
    allowedSets: [''], // only allow times tables
    usesPendingTaL: true,
    gameInfoId: 'gridpathgame',
  },
  MarbleSmasher: {
    name: 'Path of Olympus',
    image: marblesmasher,
    allowedSets: [''], // only allow times tables
    usesPendingTaL: true,
    gameInfoId: 'marblesmasher',
  },
  RhythmGame: {
    name: 'Rhythm Game',
    image: rhythmgame,
    allowedSets: [''], // only allow times tables
    usesPendingTaL: true,
    gameInfoId: 'rhythmgame',
  },
  MissingPhoneme: {
    name: 'Letter Sounds',
    image: missingphoneme,
    allowedSets: [''], // only allow times tables
    isPracticeApp: true,
    gameInfoId: 'missingphoneme',
  },
  WordForPicture: {
    name: 'Words and Pictures',
    image: wordforpicture,
    allowedSets: [''], // only allow times tables
    isPracticeApp: true,
    gameInfoId: 'wordforpicture',
  },
  MultiplayerTestGame: {
    name: 'Multiplayer Challenge',
    image: multiplayergame,
    usesPendingTaL: true,
    gameInfoId: 'multiplayertestgame',
    isMultiplayerApp: true,
  },
};

export interface QuizGameInfoListing {
  // Determine which screen the game should be shown on
  category: string;
  // Text that will appear inside the "select this game" box
  description?: string[];
  // Text that will appear inside the "select this game" box and doesnt promise anything!
  noXpDescription?: string[];
  // Title that will appear on the "select this game" box
  title: string;
  // Text that appears if this game is locked, or a function that
  // returns such text
  blocked_text?:
    | string
    | ((featureFlags: Record<string, number | string | boolean | undefined> | undefined) => string)
    | ((
        featureFlags: Record<string, number | string | boolean | undefined> | undefined,
      ) => Promise<string>)
    | null;
  // Boolean or function that returns true is this game is blocked
  // (e.g. because it requires 100 Club to be played first)
  blocked?:
    | boolean
    | ((featureFlags: Record<string, number | string | boolean | undefined> | undefined) => boolean)
    | ((
        featureFlags: Record<string, number | string | boolean | undefined> | undefined,
      ) => Promise<boolean>);
  // ID used to reference the game on the cloud. Matches variable names
  // given in CloudExperiements/apis/game.proto
  cloudId: string | null;
  // How many tokens are required to access this game
  // 0 if it should always be accessible
  tokenRequirement?: { primary: number; secondary: number };
  // How many targets are required to access this game
  // 0 if it should always be accessible
  targetRequirement?: number;
}

/*
    The data, for each game, that defines which games can be accessed from the
    "Play Games" menu
 */
export const QUIZ_GAME_INFO: Record<string, QuizGameInfoListing> = {
  minigamehilo: {
    // Determine which screen the game should be shown on
    category: GAME_CATEGORIES.REWARD,
    // Text that will appear inside the "select this game" box
    description: ['Win extra XP by guessing whether the next number is higher or lower.'],
    // Text that will appear inside the "select this game" box and doesnt promise anything!
    noXpDescription: ['Guess whether the next number is higher or lower.'],
    // Title that will appear on the "select this game" box
    title: 'Higher or Lower?',
    // Text that appears if this game is locked, or a function that
    // returns such text
    blocked_text: null,
    // Boolean or function that returns true is this game is blocked
    // (e.g. because it requires 100 Club to be played first)
    blocked: false,
    // ID used to reference the game on the cloud. Matches variable names
    // given in CloudExperiements/apis/game.proto
    cloudId: 'HiLo',
  },
  minigameautohilo: {
    category: GAME_CATEGORIES.REWARD,
    description: [
      'Win extra XP if the computer can ' + 'guess whether the next number is higher or lower.',
    ],
    noXpDescription: ['Computer will guess whether the next number is higher or lower.'],
    title: 'Auto Higher or Lower?',
    blocked_text: null,
    blocked: false,
    cloudId: 'AutoHiLo',
  },
  pictureshuffle: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Complete puzzles to unlock new animals for your zoo!'],
    noXpDescription: ['Complete puzzles to unlock new animals for your zoo!'],
    title: 'Build a Zoo',
    blocked_text: 'Play 100 Club to unlock',
    blocked: areQuizGamesBlocked,
    cloudId: 'PictureShuffle',
    tokenRequirement: { primary: 20, secondary: 0 },
  },
  gemsmash: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Connect matching gems in ever-growing chains!'],
    noXpDescription: ['Connect matching gems in ever-growing chains!'],
    title: 'Gem Smash',
    blocked_text: 'Play 100 Club to unlock',
    blocked: areQuizGamesBlocked,
    cloudId: 'GemSmash',
    tokenRequirement: { primary: 40, secondary: 0 },
  },
  pebbles: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Place 4 pebbles in a row before your opponent! (two-player game)'],
    noXpDescription: ['Place 4 pebbles in a row before your opponent! (two-player game)'],
    title: 'Pebbles',
    blocked_text: 'Play 100 Club to unlock',
    blocked: areQuizGamesBlocked,
    // Pebbles is not yet implemented on cloud
    cloudId: null,
  },
  minigamefindit: {
    category: GAME_CATEGORIES.REWARD,
    description: ['Win extra XP by finding all of the target shapes on the screen.'],
    noXpDescription: ['Find all of the target shapes on the screen.'],
    title: 'Find It!',
    blocked_text: null,
    blocked: false,
    cloudId: 'FindIt',
  },
  '100club': {
    category: GAME_CATEGORIES.ASSESSMENT,
    description: [
      'Show your ability in the times tables challenges! Can you make ' +
        'your way into the elite 100 Club?',
    ],
    noXpDescription: [
      'Show your ability in the times tables challenges! Can you make ' +
        'your way into the elite 100 Club?',
    ],
    title: '100 Club',
    blocked_text: blockedAssessmentText,
    blocked: areGameAssessmentsBlocked,
    cloudId: 'HundredClub',
  },
  blocksgame: {
    category: GAME_CATEGORIES.REWARD,
    description: ['Repair the broken dance floor to win extra XP'],
    noXpDescription: ['Repair the broken dance floor to win'],
    title: 'Block Party',
    blocked_text: null,
    blocked: false,
    cloudId: 'BlocksGame',
  },
  dicegame: {
    category: GAME_CATEGORIES.REWARD,
    description: ['Win extra XP by finding the dice that add up to the total.'],
    noXpDescription: ['Find the dice that add up to the total.'],
    title: 'Roll With It',
    blocked_text: null,
    blocked: false,
    cloudId: 'DiceGame',
  },

  slidetables: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['How many ways can you rearrange these times tables equations?'],
    noXpDescription: ['How many ways can you rearrange these times tables equations?'],
    title: 'Slide Tables',
    blocked_text: null,
    blocked: false,

    // TODO: we currently don't need server side state but it currently is required
    //  for the game to load. We should change this to be a proper store when this
    //  game is no longer a prototype, or allow games to be created without a cloudId.
    cloudId: 'SlideTables',
    tokenRequirement: { primary: 60, secondary: 60 },
  },

  chantandlearn: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Listen to the times table and see if you can remember it!'],
    noXpDescription: ['Listen to the times table and see if you can remember it!'],
    title: 'Talk and Learn',
    blocked_text: null,
    blocked: false,
    cloudId: 'ChantAndLearn',
  },

  peggrid: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Fill the grid with pegs to make a rectangle of the right size'],
    noXpDescription: ['Fill the grid with pegs to make a rectangle of the right size'],
    title: 'Place the Pegs',
    blocked_text: null,
    blocked: false,
    cloudId: 'PegGrid',
  },

  eggbaskets: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Place the items evenly into the baskets'],
    noXpDescription: ['Place the items evenly into the baskets'],
    title: 'Fill the Baskets',
    blocked_text: null,
    blocked: false,
    cloudId: 'EggBaskets',
  },

  talktome: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Talk to me please'],
    title: 'Talk to me',
    blocked_text: null,
    blocked: false,
    cloudId: 'TalkToMe',
    // Do not allow the user to access this until they have 5 targets
    targetRequirement: 5,
  },

  compoundquestion: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Answer difficult questions containing brackets'],
    noXpDescription: ['Answer difficult questions containing brackets'],
    title: 'Questions with Brackets',
    blocked_text: null,
    blocked: false,
    cloudId: 'CompoundQuestion',
  },

  typeyourtargets: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['For testing only: type out your targets in the boxes'],
    noXpDescription: ['For testing only: type out your targets in the boxes'],
    title: 'Type Your Targets',
    blocked_text: null,
    blocked: false,
    cloudId: 'TypeYourTargets',
  },

  stickercollector: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Answer times tables to earn stickers'],
    noXpDescription: ['Answer times tables to earn stickers'],
    title: 'Sticker Collector',
    blocked_text: null,
    blocked: false,
    cloudId: 'StickerCollector',
  },

  leaguetablegame: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Answer times tables to climb to the top of the leaderboard'],
    noXpDescription: ['Answer times tables to climb to the top of the leaderboard'],
    title: 'Table Toppers',
    blocked_text: null,
    blocked: false,
    cloudId: 'LeagueTableGame',
    tokenRequirement: { primary: 5, secondary: 0 },
  },

  findittables: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Find all the hidden shapes within the time limit'],
    noXpDescription: ['Find all the hidden shapes within the time limit'],
    title: 'Find It!',
    blocked_text: null,
    blocked: false,
    cloudId: 'FindItTables',
    tokenRequirement: { primary: 15, secondary: 20 },
  },

  gardengame: {
    category: GAME_CATEGORIES.QUIZ,
    description: ["It's a garden"],
    noXpDescription: ["It's a garden"],
    title: 'Garden',
    blocked_text: null,
    blocked: false,
    cloudId: 'GardenGame',
    tokenRequirement: { primary: 10, secondary: 5 },
  },
  gridpathgame: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['None'],
    noXpDescription: ['None'],
    title: 'Cross the River',
    blocked_text: null,
    blocked: false,
    cloudId: 'GridPathGame',
    tokenRequirement: { primary: 15, secondary: 0 },
  },
  marblesmasher: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Connect the marbles to smash them'],
    noXpDescription: ['Connect the marbles to smash them'],
    title: 'MarbleSmasher',
    blocked_text: null,
    blocked: false,
    cloudId: 'MarbleSmasher',
  },
  rhythmgame: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['A rhythm game about number bonds'],
    noXpDescription: ['A rhythm game about number bonds'],
    title: 'RhythmGame',
    blocked_text: null,
    blocked: false,
    cloudId: 'RhythmGame',
  },

  missingphoneme: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Practise your phonics'],
    noXpDescription: ['Practise your phonics'],
    title: 'Letter Sounds',
    blocked_text: null,
    blocked: false,
    cloudId: 'MissingPhoneme',
  },
  wordforpicture: {
    category: GAME_CATEGORIES.QUIZ,
    description: ['Practise your phonics'],
    noXpDescription: ['Practise your phonics'],
    title: 'Words and Pictures',
    blocked_text: null,
    blocked: false,
    cloudId: 'WordForPicture',
  },
};

export function gameRequiresTokens(gameId: string) {
  const gameData = TimesTablesGameParameters[gameId];
  if (gameData && gameData.gameInfoId && QUIZ_GAME_INFO[gameData.gameInfoId]) {
    return exists(QUIZ_GAME_INFO[gameData.gameInfoId].tokenRequirement);
  }
  return false;
}

export function gameRequiresTargets(gameId: string) {
  const gameData = TimesTablesGameParameters[gameId];
  if (gameData && gameData.gameInfoId && QUIZ_GAME_INFO[gameData.gameInfoId]) {
    return exists(QUIZ_GAME_INFO[gameData.gameInfoId].targetRequirement);
  }
  return false;
}

export function tokenRequirementForGame(gameId: string, usePrimaryUnlockRates: boolean) {
  if (gameRequiresTokens(gameId)) {
    const infoId = TimesTablesGameParameters[gameId].gameInfoId;
    if (usePrimaryUnlockRates) {
      return QUIZ_GAME_INFO[infoId].tokenRequirement?.primary || 0;
    } else {
      return QUIZ_GAME_INFO[infoId].tokenRequirement?.secondary || 0;
    }
  }
  return 0;
}

export function targetRequirementForGame(gameId: string) {
  if (gameRequiresTargets(gameId)) {
    const infoId = TimesTablesGameParameters[gameId].gameInfoId;
    return QUIZ_GAME_INFO[infoId].targetRequirement || 0;
  }
  return 0;
}

export function tokenCountIsSufficient(
  gameId: string,
  tokenCount: number,
  usePrimaryUnlockRates: boolean,
) {
  const requiredCount = tokenRequirementForGame(gameId, usePrimaryUnlockRates) || 0;
  return tokenCount >= requiredCount;
}

export function targetCountIsSufficient(gameId: string, targetCount: number) {
  const requiredCount = targetRequirementForGame(gameId) || 0;
  return targetCount >= requiredCount;
}

// Length of time student must wait between successive uses of 100 Club
const HUNDRED_CLUB_BLOCK_DURATION = 10;

/*
    Returns true if student has not yet played 100 Club.
    Prevents students from accessing games before playing 100 Club
 */
async function areQuizGamesBlocked(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  return !QuizAlgorithm.shouldAllowGameAccess();
}

/*
    Returns true if student has played 100 Club too recently and should not
    be allowed to play it at this time.
 */
async function areGameAssessmentsBlocked(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  // If first pass not cleared (i.e. after 100 Club progress was reset)
  // then always allow access to 100 Club
  if (!QuizAlgorithm.shouldAllowGameAccess()) {
    return false;
  }
  const minutes = QuizAlgorithm.minutesSinceLastAssessment();
  return minutes < HUNDRED_CLUB_BLOCK_DURATION;
}

/*
    Shown while 100 Club is blocked from student use. Shows how long they
    have to wait to try again
 */
async function blockedAssessmentText(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  const minutes = QuizAlgorithm.minutesSinceLastAssessment();
  const minutesRemaining = HUNDRED_CLUB_BLOCK_DURATION - minutes;

  if (minutesRemaining > 1) {
    const minutesText = Math.ceil(minutesRemaining);
    return 'Will be available in ' + minutesText + ' minutes';
  } else {
    return 'Will be available in 1 minute';
  }
}

export async function setShouldUseSpecialAssessment(
  shouldUseSpecialAssessment: boolean,
  assessmentPackageId: string,
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  const { SpecialAssessmentGatekeeper } = await GetSpecialAssessmentLazy();
  QuizAlgorithm.setShouldUseSpecialAssessment(shouldUseSpecialAssessment);
  SpecialAssessmentGatekeeper.flagAssessmentPackageId(assessmentPackageId);
}

export async function initialiseGameErrorHandler(
  callbackOnError: (errorDescription: string) => void,
) {
  const { ErrorHandler } = await GetErrorHandlerLazy();
  ErrorHandler.initialiseForNewView(callbackOnError);
}

import { createContext, PropsWithChildren, useCallback, useState } from 'react';

type NavigationContextValue = {
  backPath: string | undefined;
  setBackPath: (path: string | undefined) => void;
};

export const NavigationContext = createContext<NavigationContextValue | undefined>(undefined);

/**
 * NavigationProvider exposes a method of overriding the back path used by the back button in the
 * header. This is to enable more context specific logic for the back button in cases where it can't
 * be derived from the current path.
 */
export const NavigationProvider = ({ children }: PropsWithChildren) => {
  const [backPath, setBackPathState] = useState<string | undefined>(undefined);

  const setBackPath = useCallback((path: string | undefined) => setBackPathState(path), []);

  const value = {
    backPath,
    setBackPath,
  };

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

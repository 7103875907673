import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import { AnswerRenderer } from '@sparx/answer-renderer';
import { Activity, WACActivity, WACOption } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { useDimensions } from '@sparx/react-utils';
import { handleKeyPress } from '@sparx/react-utils/keyboard';
import { Button, Chip, ZoomableDialog } from '@sparx/sparx-design/components';
import { ZoomableDialogChoice } from '@sparx/sparx-design/components/zoomable-dialog/ZoomableDialog';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { StudentExperienceTrainingStep } from 'content/training/trainingModules';
import { useIsStepComplete } from 'context/training/hook';
import {
  buildAnswerWACAction,
  buildDismissWACAction,
  buildViewWACAction,
  useActivityAction,
  useWACActivity,
} from 'queries/activity';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useFeatureFlags } from 'utils/feature-flags';
import { makeTaskItemPath, makeTaskPath, useLQDPath } from 'utils/paths';
import { WACIncorrect } from 'views/wac/WACIncorrect';

import { WACCorrect } from './WACCorrect';
import styles from './WACDisplay.module.css';

interface IWACModalProps {
  packageID: string;
  taskIndex: number;
}

const numberWords = ['', 'one', 'two', 'three', 'four', 'five', 'six'];

export const WACDisplay: FunctionComponent<IWACModalProps> = ({ packageID, taskIndex }) => {
  // Get the task item index out of the URL:
  const { parentPath, taskItemIndex } = useLQDPath();

  const {
    data: activity,
    isLoading,
    isError,
  } = useWACActivity(packageID, taskIndex, taskItemIndex);

  if (!taskItemIndex) {
    return <Navigate to={makeTaskPath(parentPath, packageID, taskIndex)} />;
  }

  if (isError) {
    return <Navigate to={makeTaskItemPath(parentPath, packageID, taskIndex, taskItemIndex ?? 0)} />;
  }
  if (isLoading) {
    return (
      <LoadingSpinnerWithAnalytics componentName="WACDisplay" sendLongTimeLoadingEvent={true} />
    );
  }
  if (!activity?.payload) {
    return <p>No activity payload</p>;
  }
  if (activity?.payload.oneofKind !== 'wac') {
    return <p>Unknown activity type: {activity?.payload.oneofKind}</p>;
  }

  return (
    <WACDisplayImpl
      activity={activity}
      wacActivity={activity.payload.wac}
      parentPath={parentPath}
      packageID={packageID}
      taskIndex={taskIndex}
      taskItemIndex={taskItemIndex}
    />
  );
};

/**
 * WAC Choice scaled down to fit the available space if too wide or too tall
 */
const WACDisplayChoice = ({
  selected,
  onClick,
  option,
  choices,
  initialDisplayedChoice,
  selectedChoice,
}: {
  selected: boolean;
  onClick: () => void;
  option: WACOption;
  choices: ZoomableDialogChoice[];
  initialDisplayedChoice: number;
  selectedChoice: number | undefined;
}) => {
  const [scale, setScale] = useState(1);
  const [ref, { width: containerWidth, height: containerHeight }] = useDimensions<HTMLDivElement>();
  const [answerRef, { offsetWidth: answerWidth, offsetHeight: answerHeight }] =
    useDimensions<HTMLDivElement>({
      detectImagesLoading: true,
    });

  const scaleContents = useCallback(() => {
    if (ref.current && answerRef.current) {
      const newScale = Math.min(
        ref.current.offsetWidth / (answerRef.current.offsetWidth + 10),
        ref.current.offsetHeight / (answerRef.current.offsetHeight + 10),
      );
      if (scale !== newScale) {
        setScale(newScale);
      }
    }
  }, [ref, answerRef, scale]);

  useEffect(() => {
    scaleContents();
  }, [containerWidth, containerHeight, answerWidth, answerHeight, scaleContents]);
  return (
    <div className={styles.WACGridOption}>
      <div className={styles.Item}>
        <ZoomableDialog
          containerClassName={styles.WACZoomContainer}
          showZoomControls
          choices={choices}
          initialDisplayedChoice={initialDisplayedChoice}
          selectedChoice={selectedChoice}
        >
          <div
            onKeyUp={handleKeyPress({ ' ': onClick, Enter: onClick })}
            className={classNames(styles.WACOption, accessibilityStyles.FocusTarget, {
              [styles.Selected]: selected,
            })}
            onClick={onClick}
            tabIndex={0}
            role="radio"
            ref={ref}
          >
            {/* Ideally we would only add zoom controls when the WAC option is an image */}

            <AnswerRenderer
              markup={option.filledAnswerTemplate}
              contentAssetsURL={window.__sparxweb.urls.contentAssets}
              scaleFactor={scale}
              ref={answerRef}
              useFullResImages
            />
          </div>
        </ZoomableDialog>
      </div>
    </div>
  );
};

export type SelectedWACOption = number | 'not-written-down';

const WACDisplayImpl = ({
  activity,
  wacActivity,
  parentPath,
  packageID,
  taskIndex,
  taskItemIndex,
}: {
  activity: Activity;
  wacActivity: WACActivity;
  parentPath: string | undefined;
  packageID: string;
  taskIndex: number;
  taskItemIndex: number;
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectedWACOption | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(true);

  // Check if the WAC step has been completed in training. If it hasn't we disable the submit button and show
  // an additional message to the teacher. This will not appear for students.
  const trainingWACCheckPassed = useIsStepComplete(
    StudentExperienceTrainingStep.FAIL_BOOKWORK_CHECK,
  );
  const forceBookworkFailure = trainingWACCheckPassed === false;

  // create activity mutations
  // viewWac is retried, as theres no way for the user to retry this if it fails
  const { mutate: viewWAC, isSuccess: isViewed } = useActivityAction({ retry: true });
  const { mutate: dismissWAC } = useActivityAction();
  const {
    data: submitAnswerData,
    isLoading: isSubmittingAnswer,
    isSuccess: isAnswerSubmitted,
    mutate: submitWAC,
  } = useActivityAction();
  const isCorrect = !!submitAnswerData?.response && submitAnswerData.response.status === 'SUCCESS';

  const isSubmittingAnswerRef = useRef(false);
  isSubmittingAnswerRef.current = isSubmittingAnswer;
  const isAnswerSubmittedRef = useRef(false);
  isAnswerSubmittedRef.current = isAnswerSubmitted;

  // call start activity on mount and dismiss on unmount if we didn't submit an answer
  useEffect(() => {
    viewWAC(buildViewWACAction(activity));
    return () => {
      if (!isSubmittingAnswerRef.current && !isAnswerSubmittedRef.current) {
        dismissWAC(buildDismissWACAction(activity));
      }
    };
  }, [activity, viewWAC, dismissWAC]);

  const submitAnswer = (option: SelectedWACOption | undefined) => {
    if (option !== undefined) {
      submitWAC(buildAnswerWACAction(activity, wacActivity, option));
    }
  };

  // make choices to pass to <ZoomableDialog>
  // we make them at this level as we have all the information here
  const choices = wacActivity.options.map((option, i) => {
    return {
      element: (
        <AnswerRenderer
          markup={option.filledAnswerTemplate}
          contentAssetsURL={window.__sparxweb.urls.contentAssets}
          useFullResImages
        />
      ),
      onSelect: () => setSelectedOption(i),
    };
  });

  // handle bottom shadow when scrollable
  const buttonSentinelRef = useRef<HTMLDivElement>(null);

  const [showShadow, setShowShadow] = useState<boolean>(false);

  useEffect(() => {
    if (!buttonSentinelRef.current) {
      return;
    }
    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      setShowShadow(!isIntersecting);
    });

    observer.observe(buttonSentinelRef.current);

    return () => observer.disconnect();
  }, []);

  const featureFlags = useFeatureFlags();
  const wacNotIncorrect =
    featureFlags.getStringFlag('sparxweb-wac-method', '') === 'ask_answer_any_type_not_incorrect';

  // navigate back to the task item after closing the dialog to prevent a bug in Chrome where the
  // focus goes to the address bar when navigating without first closing the dialog (RED-999)
  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };
  if (!isOpen) {
    return <Navigate to={makeTaskItemPath(parentPath, packageID, taskIndex, taskItemIndex)} />;
  }

  return (
    <Dialog.Root defaultOpen={true} modal={true} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} />
        <Dialog.Content
          className={dialogStyles.DialogContent}
          onPointerDownOutside={e => e.preventDefault()}
        >
          <div className={styles.WACContainer}>
            <Dialog.Title className={dialogStyles.DialogTitle}>Bookwork check</Dialog.Title>
            {!isAnswerSubmitted && (
              <>
                <span className={styles.Subtitle}>
                  Which of these<strong> {numberWords[wacActivity.options.length]}</strong> options
                  is the right answer for this question?
                </span>
                <Chip colourVariant="Selected" shapeVariant="Boxy" className={styles.Bookwork}>
                  Bookwork {wacActivity.bookworkCode}
                </Chip>
              </>
            )}
            <div className={styles.WACContent}>
              {isAnswerSubmitted ? (
                isCorrect ? (
                  <WACCorrect />
                ) : (
                  <WACIncorrect />
                )
              ) : (
                <>
                  <div className={styles.WACInput}>
                    <div className={styles.ChoiceWACOptions}>
                      <div className={styles.ChoiceWACOptionsGrid}>
                        {wacActivity.options.map((option, i) => (
                          <WACDisplayChoice
                            key={i}
                            selected={i === selectedOption}
                            onClick={() => setSelectedOption(i)}
                            option={option}
                            choices={choices}
                            initialDisplayedChoice={i}
                            selectedChoice={isNumber(selectedOption) ? selectedOption : undefined}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.ButtonSentinel} ref={buttonSentinelRef}></div>
          </div>
          <div className={classNames(styles.ButtonsContainer, showShadow && styles.BoxShadow)}>
            <div className={styles.Buttons}>
              {isAnswerSubmitted ? (
                <Button
                  variant="contained"
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                  as={Dialog.Close}
                >
                  {wacNotIncorrect
                    ? isCorrect
                      ? 'Continue'
                      : 'Next question'
                    : 'Continue to question'}
                </Button>
              ) : (
                <>
                  <Button
                    className={styles.Button}
                    onClick={() => submitAnswer('not-written-down')}
                    isDisabled={isSubmittingAnswer || !isViewed}
                    variant="outlined"
                  >
                    I didn't write this down
                  </Button>
                  <Button
                    onClick={() => submitAnswer(selectedOption)}
                    isDisabled={
                      selectedOption === undefined ||
                      isSubmittingAnswer ||
                      forceBookworkFailure ||
                      !isViewed
                    }
                    variant="contained"
                    rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>

          {forceBookworkFailure && (
            <p className={styles.TrainingWarning}>
              Click "I didn't write this down" to experience how failing a bookwork check impacts a
              student's homework. This has exactly the same result as selecting an incorrect answer
              option above.
            </p>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const isNumber = (value: SelectedWACOption | undefined): value is number =>
  typeof value === 'number';

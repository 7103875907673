import { IndependentLearningContext } from 'context/independentlearning/provider';
import { useContext } from 'react';

export const useIndependentLearningState = () => {
  const ctx = useContext(IndependentLearningContext);
  if (!ctx) {
    throw new Error(
      'useIndependentLearningState can only be used inside an IndependentLearningProvider',
    );
  }
  return ctx;
};

import { ChevronRight, Tick } from '@sparx/sparx-design/icons';
import { isCompletable, Step } from 'types/training';

import styles from './TrainingBanner.module.css';

/**
 * Component for an individual training step in the training dropdown menu
 */

interface ITaskListItemProps {
  // Current training step
  step: Step;
  // Whether the task list item is for the step currently selected
  selected: boolean;
  // Function to call when this task item is selected
  onSelectTask: () => void;
  // Whether the all steps page is showing - i.e. this item will be visible
  showing: boolean;
}

export const TaskListItem = ({ step, selected, onSelectTask, showing }: ITaskListItemProps) => (
  <button
    className={styles.TrainingStep}
    onClick={onSelectTask}
    tabIndex={showing ? 0 : -1}
    aria-hidden={!showing}
  >
    <div className={styles.TrainingStepLeft}>
      <div className={styles.TrainingStepTitle}>
        {isCompletable(step) && (
          <span className={styles.TrainingStepNumber}>{step.taskNumber}.</span>
        )}
        {selected ? <strong>{step.title}</strong> : step.title}
      </div>
      {isCompletable(step) && step.isComplete && (
        <div className={styles.CompleteChip}>
          <Tick variant="White" className={styles.CompleteChipIcon} />
          Complete
        </div>
      )}
    </div>
    <ChevronRight className={styles.TrainingStepArrow} />
  </button>
);

import { GroupElementType, IGroupElement, IStep } from '@sparx/question';

export const GetCalculatorAllowed = (step: IStep): boolean => {
  const layoutElement = step.layout;
  // TODO: There might be a nicer way of getting at this but for now I've just put this hacky code
  // in a separate method.
  // IGroupElement is the only place I can see calculator-allowed in the types.
  // In the old code we do skillInfo.getIn(['pc', 'steps', 0, 'layout', 'type']);
  const groupElement = layoutElement as IGroupElement;
  const groupElementType: GroupElementType[] = groupElement.type || [];
  return groupElementType.includes('calculator-allowed');
};

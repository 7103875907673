import { TaskCompletion as Task, TaskItemStatus } from '@sparx/api/apis/sparx/packages/v1/spxpkg';

export const TASK_ITEM_STATUS = {
  done: 'done',
  wrong: 'wrong',
  seekHelp: 'seek-help',
  unattempted: 'unattempted',
  badged: 'badged',
  hidden: 'hidden',
  wrongWithVideoNudge: 'wrong-with-video-nudge',
  swappedOut: 'swapped-out',
};

export const statusMap = {
  [TaskItemStatus.UNATTEMPTED]: TASK_ITEM_STATUS.unattempted,
  [TaskItemStatus.DONE]: TASK_ITEM_STATUS.done,
  [TaskItemStatus.WRONG]: TASK_ITEM_STATUS.wrong,
  [TaskItemStatus.WARNING]: TASK_ITEM_STATUS.wrong,
  [TaskItemStatus.SEEKHELP]: TASK_ITEM_STATUS.seekHelp,
  [TaskItemStatus.BADGED]: TASK_ITEM_STATUS.badged,
  [TaskItemStatus.HIDDEN]: TASK_ITEM_STATUS.hidden,
  [TaskItemStatus.WRONGWITHVIDEONUDGE]: TASK_ITEM_STATUS.wrongWithVideoNudge,
  [TaskItemStatus.SWAPPEDOUT]: TASK_ITEM_STATUS.swappedOut,
};

export const itemStatus = (taskItemStatus: TaskItemStatus) => {
  return statusMap[taskItemStatus] || TASK_ITEM_STATUS.unattempted;
};

export const isIndependentLearningTask = (task: Task) => task.taskType === 'NORMAL';

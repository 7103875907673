import classNames from 'classnames';
import { forwardRef, MouseEventHandler, ReactNode, useMemo } from 'react';

import { ComponentWithAs } from '../../ComponentWithAs';
import { useTheme } from '../../context';
import { Breakpoint, HiddenAt } from '../hidden-at/HiddenAt';
import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';
import styles from './Button.module.css';

export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonVariant = 'primary' | 'secondary' | 'ghost' | 'link';
export type ButtonColourScheme = 'default' | 'positive' | 'negative' | 'neutral' | 'custom';

export interface ButtonProps {
  onClick?: MouseEventHandler<HTMLElement>;
  onDisabledClick?: MouseEventHandler<HTMLElement>;

  size?: ButtonSize;
  colourScheme?: ButtonColourScheme;
  variant?: ButtonVariant;

  leftIcon?: ReactNode;
  hideLeftIconAt?: Breakpoint;
  rightIcon?: ReactNode;
  hideRightIconAt?: Breakpoint;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const Button: ComponentWithAs<'button', ButtonProps> = forwardRef(
  (
    {
      as: Component = 'button',
      className,
      onClick,
      onDisabledClick,
      children,
      size = 'md',
      colourScheme = 'default',
      variant = 'primary',
      leftIcon,
      hideLeftIconAt,
      rightIcon,
      hideRightIconAt,
      isLoading,
      isDisabled,
      tabIndex = 0,
      ...props
    },
    forwardedRef,
  ) => {
    const { themeStyles } = useTheme();
    const classes = useMemo(
      () =>
        classNames(
          styles.ButtonBase,
          themeStyles['ButtonBase'],
          getButtonSizeStyle(size, themeStyles),
          getButtonColourStyle(colourScheme, themeStyles),
          getButtonVariantStyle(variant, themeStyles),
          isLoading && styles.Loading,
          isDisabled && styles.Disabled,
          className,
        ),
      [size, colourScheme, variant, isLoading, isDisabled, className, themeStyles],
    );

    const renderIcon = (icon: ReactNode, className: string, hideAt?: Breakpoint) =>
      hideAt ? (
        <HiddenAt breakpoint={hideAt} className={className}>
          {icon}
        </HiddenAt>
      ) : (
        <div className={className}>{icon}</div>
      );

    return (
      <Component
        ref={forwardedRef}
        aria-disabled={isDisabled || isLoading}
        className={classes}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (!isDisabled && !isLoading && onClick) {
            onClick(e);
          } else if (isDisabled && onDisabledClick) {
            onDisabledClick(e);
          }
        }}
        tabIndex={!isDisabled && !isLoading ? tabIndex : -1}
        {...props}
      >
        {leftIcon && renderIcon(leftIcon, styles.LeftIcon, hideLeftIconAt)}
        <div className={styles.Content}>{children}</div>
        {rightIcon && renderIcon(rightIcon, styles.RightIcon, hideRightIconAt)}
        {isLoading && (
          <div className={styles.LoadingOverlay}>
            <LoadingSpinner />
          </div>
        )}
      </Component>
    );
  },
);

// add displayName for better debugging (/to appease linter)
Button.displayName = 'Button';

const getButtonSizeStyle = (size: ButtonSize, themeStyles: Partial<Record<string, string>>) => {
  const sizeStyles: Record<ButtonSize, string> = {
    sm: themeStyles['ButtonSmall'] || styles.ButtonSmall,
    md: themeStyles['ButtonMedium'] || styles.ButtonMedium,
    lg: themeStyles['ButtonLarge'] || styles.ButtonLarge,
  };
  return sizeStyles[size];
};

const getButtonColourStyle = (
  colourScheme: ButtonColourScheme,
  themeStyles: Partial<Record<string, string>>,
) => {
  const colourSchemeStyles: Record<ButtonColourScheme, string> = {
    default: themeStyles['ButtonDefault'] || styles.ButtonDefault,
    positive: themeStyles['ButtonPositive'] || styles.ButtonPositive,
    negative: themeStyles['ButtonNegative'] || styles.ButtonNegative,
    neutral: themeStyles['ButtonNeutral'] || styles.ButtonNeutral,
    custom: '', // no style set
  };
  return colourSchemeStyles[colourScheme];
};

const getButtonVariantStyle = (
  variant: ButtonVariant,
  themeStyles: Partial<Record<string, string>>,
) => {
  const variantStyles: Record<ButtonVariant, string> = {
    primary: themeStyles['ButtonPrimary'] || styles.ButtonPrimary,
    secondary: themeStyles['ButtonSecondary'] || styles.ButtonSecondary,
    ghost: themeStyles['ButtonGhost'] || styles.ButtonGhost,
    link: themeStyles['ButtonLink'] || styles.ButtonLink,
  };
  return variantStyles[variant];
};

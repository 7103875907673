// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/revision/v1/revision.proto" (package "sparx.revision.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { PackageData } from '../../packages/v1/spxpkg';
/**
 * @generated from protobuf message sparx.revision.v1.GetStudentProfileRequest
 */
export interface GetStudentProfileRequest {}
/**
 * @generated from protobuf message sparx.revision.v1.GetStudentProfileResponse
 */
export interface GetStudentProfileResponse {
  /**
   * @generated from protobuf field: sparx.revision.v1.Profile profile = 1;
   */
  profile?: Profile;
}
/**
 * @generated from protobuf message sparx.revision.v1.Profile
 */
export interface Profile {
  /**
   * @generated from protobuf field: string default_curriculum_name = 1;
   */
  defaultCurriculumName: string;
  /**
   * @generated from protobuf field: string default_learning_path_spec_name = 2;
   */
  defaultLearningPathSpecName: string;
}
/**
 * @generated from protobuf message sparx.revision.v1.GetActivePackagesRequest
 */
export interface GetActivePackagesRequest {
  /**
   * @generated from protobuf field: string curriculum_id = 1;
   */
  curriculumId: string;
}
/**
 * @generated from protobuf message sparx.revision.v1.GetActivePackagesResponse
 */
export interface GetActivePackagesResponse {
  /**
   * @generated from protobuf field: repeated sparx.revision.v1.RevisionPackage packages = 1;
   */
  packages: RevisionPackage[];
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageData package_data = 2;
   */
  packageData?: PackageData;
}
/**
 * @generated from protobuf message sparx.revision.v1.GetPackagesForObjectivesRequest
 */
export interface GetPackagesForObjectivesRequest {
  /**
   * @generated from protobuf field: string curriculum_name = 1;
   */
  curriculumName: string;
  /**
   * @generated from protobuf field: string topic_level_name = 2;
   */
  topicLevelName: string;
  /**
   * @generated from protobuf field: repeated string objective_names = 3;
   */
  objectiveNames: string[];
}
/**
 * @generated from protobuf message sparx.revision.v1.GetPackagesForObjectivesResponse
 */
export interface GetPackagesForObjectivesResponse {
  /**
   * @generated from protobuf field: repeated sparx.revision.v1.RevisionPackage packages = 1;
   */
  packages: RevisionPackage[];
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageData package_data = 2;
   */
  packageData?: PackageData;
}
/**
 * @generated from protobuf message sparx.revision.v1.RevisionPackage
 */
export interface RevisionPackage {
  /**
   * @generated from protobuf field: string curriculum_id = 1;
   */
  curriculumId: string;
  /**
   * @generated from protobuf field: string objective_id = 2;
   */
  objectiveId: string;
  /**
   * @generated from protobuf field: string package_id = 3;
   */
  packageId: string;
}
/**
 * @generated from protobuf message sparx.revision.v1.GetAssignedTopicsRequest
 */
export interface GetAssignedTopicsRequest {}
/**
 * @generated from protobuf message sparx.revision.v1.GetAssignedTopicsResponse
 */
export interface GetAssignedTopicsResponse {
  /**
   * @generated from protobuf field: repeated sparx.revision.v1.AssignedTopic topics = 1;
   */
  topics: AssignedTopic[];
}
/**
 * @generated from protobuf message sparx.revision.v1.AssignedTopic
 */
export interface AssignedTopic {
  /**
   * @generated from protobuf field: string assigned_topic_id = 1;
   */
  assignedTopicId: string;
  /**
   * @generated from protobuf field: string selected_topic_id = 2;
   */
  selectedTopicId: string;
  /**
   * @generated from protobuf field: string selected_topic_level = 3;
   */
  selectedTopicLevel: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentProfileRequest$Type extends MessageType<GetStudentProfileRequest> {
  constructor() {
    super('sparx.revision.v1.GetStudentProfileRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetStudentProfileRequest
 */
export const GetStudentProfileRequest = new GetStudentProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentProfileResponse$Type extends MessageType<GetStudentProfileResponse> {
  constructor() {
    super('sparx.revision.v1.GetStudentProfileResponse', [
      { no: 1, name: 'profile', kind: 'message', T: () => Profile },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetStudentProfileResponse
 */
export const GetStudentProfileResponse = new GetStudentProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Profile$Type extends MessageType<Profile> {
  constructor() {
    super('sparx.revision.v1.Profile', [
      {
        no: 1,
        name: 'default_curriculum_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'default_learning_path_spec_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.Profile
 */
export const Profile = new Profile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivePackagesRequest$Type extends MessageType<GetActivePackagesRequest> {
  constructor() {
    super('sparx.revision.v1.GetActivePackagesRequest', [
      {
        no: 1,
        name: 'curriculum_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetActivePackagesRequest
 */
export const GetActivePackagesRequest = new GetActivePackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivePackagesResponse$Type extends MessageType<GetActivePackagesResponse> {
  constructor() {
    super('sparx.revision.v1.GetActivePackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RevisionPackage,
      },
      { no: 2, name: 'package_data', kind: 'message', T: () => PackageData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetActivePackagesResponse
 */
export const GetActivePackagesResponse = new GetActivePackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackagesForObjectivesRequest$Type extends MessageType<GetPackagesForObjectivesRequest> {
  constructor() {
    super('sparx.revision.v1.GetPackagesForObjectivesRequest', [
      {
        no: 1,
        name: 'curriculum_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'topic_level_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'objective_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetPackagesForObjectivesRequest
 */
export const GetPackagesForObjectivesRequest =
  new GetPackagesForObjectivesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackagesForObjectivesResponse$Type extends MessageType<GetPackagesForObjectivesResponse> {
  constructor() {
    super('sparx.revision.v1.GetPackagesForObjectivesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RevisionPackage,
      },
      { no: 2, name: 'package_data', kind: 'message', T: () => PackageData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetPackagesForObjectivesResponse
 */
export const GetPackagesForObjectivesResponse =
  new GetPackagesForObjectivesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevisionPackage$Type extends MessageType<RevisionPackage> {
  constructor() {
    super('sparx.revision.v1.RevisionPackage', [
      {
        no: 1,
        name: 'curriculum_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'objective_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.RevisionPackage
 */
export const RevisionPackage = new RevisionPackage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssignedTopicsRequest$Type extends MessageType<GetAssignedTopicsRequest> {
  constructor() {
    super('sparx.revision.v1.GetAssignedTopicsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetAssignedTopicsRequest
 */
export const GetAssignedTopicsRequest = new GetAssignedTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssignedTopicsResponse$Type extends MessageType<GetAssignedTopicsResponse> {
  constructor() {
    super('sparx.revision.v1.GetAssignedTopicsResponse', [
      {
        no: 1,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssignedTopic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.GetAssignedTopicsResponse
 */
export const GetAssignedTopicsResponse = new GetAssignedTopicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignedTopic$Type extends MessageType<AssignedTopic> {
  constructor() {
    super('sparx.revision.v1.AssignedTopic', [
      {
        no: 1,
        name: 'assigned_topic_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'selected_topic_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'selected_topic_level',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.revision.v1.AssignedTopic
 */
export const AssignedTopic = new AssignedTopic$Type();
/**
 * @generated ServiceType for protobuf service sparx.revision.v1.Revision
 */
export const Revision = new ServiceType('sparx.revision.v1.Revision', [
  {
    name: 'GetStudentProfile',
    options: {
      'google.api.http': { get: '/v1/studentprofile' },
      'sparx.api.auth': {
        action: 'read',
        resource: 'revision:studentprofile',
        domain: 'revision',
      },
    },
    I: GetStudentProfileRequest,
    O: GetStudentProfileResponse,
  },
  {
    name: 'GetActivePackages',
    options: {
      'google.api.http': { get: '/v1/activepackages' },
      'sparx.api.auth': {
        action: 'read',
        resource: 'revision:package',
        domain: 'revision',
      },
    },
    I: GetActivePackagesRequest,
    O: GetActivePackagesResponse,
  },
  {
    name: 'GetPackagesForObjectives',
    options: {
      'google.api.http': { post: '/v1/objectivepackages', body: '*' },
      'sparx.api.auth': {
        action: 'create',
        resource: 'revision:package',
        domain: 'revision',
      },
    },
    I: GetPackagesForObjectivesRequest,
    O: GetPackagesForObjectivesResponse,
  },
  {
    name: 'GetAssignedTopics',
    options: {
      'google.api.http': { get: '/v1/assignedtopics' },
      'sparx.api.auth': {
        action: 'read',
        resource: 'revision:package',
        domain: 'revision',
      },
    },
    I: GetAssignedTopicsRequest,
    O: GetAssignedTopicsResponse,
  },
]);

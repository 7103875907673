import { LeaderboardDateRange } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { RibbonBanner as LibRibbonBanner } from '@sparx/sparx-design/components/ribbon-banner';

import { ReactComponent as Crown } from './Crown.svg';
import styles from './RibbonBanner.module.css';

export const RibbonBanner = ({
  leaderboardDateRange,
}: {
  leaderboardDateRange: LeaderboardDateRange;
}) => {
  const { heading, subHeading } = getRibbonHeadings(leaderboardDateRange);
  return (
    <LibRibbonBanner barClassName={styles.BannerHeading}>
      <Crown className={styles.Crown} />
      <h1>{heading}</h1>
      <p>{subHeading}</p>
    </LibRibbonBanner>
  );
};

const getRibbonHeadings = (
  dateRange: LeaderboardDateRange,
): { heading: string; subHeading: string } => {
  switch (dateRange) {
    case LeaderboardDateRange.THIS_MONTH:
      return { heading: 'Monthly Leaderboard', subHeading: 'This month' };
    case LeaderboardDateRange.LAST_MONTH:
      return { heading: 'Monthly Leaderboard', subHeading: 'Last month' };
    case LeaderboardDateRange.THIS_YEAR:
      return { heading: 'Yearly Leaderboard', subHeading: 'This academic year' };
    case LeaderboardDateRange.THIS_WEEK:
    case LeaderboardDateRange.LAST_WEEK:
    default:
      return { heading: 'Weekly Leaderboard', subHeading: 'Monday to Sunday' };
  }
};

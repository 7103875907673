import { UserInteractionEventFields } from '@sparx/analytics';
import {
  ILeaderboardsClient,
  LeaderboardsClient,
} from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards.client';
import { UserDisplayClient } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay.client';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { createContext, useContext } from 'react';

export type LeaderboardContextType = {
  product: Product;
  schoolID: string;
  studentID?: string;
  firstName?: string;
  sendAnalyticsEvent: (e: Omit<UserInteractionEventFields, 'category'>) => void;
  leaderboardsClient: LeaderboardsClient | ILeaderboardsClient;
  userDisplayClient?: UserDisplayClient;
  navigateToStudent?: (studentID: string) => void;
};

const leaderboardContext = createContext<LeaderboardContextType | undefined>(undefined);
export const LeaderboardContextProvider = leaderboardContext.Provider;

export const useLeaderboardContext = () => {
  const context = useContext(leaderboardContext);
  if (context === undefined) {
    throw new Error('useLeaderboardContext must be used within a leaderboardContextProvider');
  }
  return context;
};

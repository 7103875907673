import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import { TutorialKey, useMarkTutorialComplete } from 'queries/tutorials';
import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import Joyride, { CallBackProps, Step, TooltipRenderProps } from 'react-joyride';

import { TutorialTooltip } from './TutorialTooltip';

export type TutorialStep = {
  tutorialKey: TutorialKey;
} & Omit<Step, 'target' | 'title' | 'disableBeacon'>;

type ShowTutorialsOptions = {
  disabled?: boolean;
  scrollOffset?: number;
  tooltipComponentOverride?: React.ElementType<TooltipRenderProps> | undefined;
};

type TutorialSpotlight = {
  showTutorials: (steps: TutorialStep[], opts?: ShowTutorialsOptions) => void;
  hideTutorials: () => void;
  tutorialIsShowing?: boolean;
};

export const TutorialSpotlightContext = createContext<TutorialSpotlight | undefined>(undefined);

export const TutorialSpotlightProvider = ({ children }: PropsWithChildren) => {
  const [disabled, setDisabled] = useState(false);
  const [tutorialSteps, setTutorialSteps] = useState<TutorialStep[]>([]);
  const [scrollOffset, setScrollOffset] = useState(0);

  const [TooltipComponentOverride, setTooltipComponentOverride] = useState<
    React.ElementType<TooltipRenderProps> | undefined
  >(undefined);

  const showTutorials = useCallback((steps: TutorialStep[], opts?: ShowTutorialsOptions) => {
    setDisabled(!!opts?.disabled);
    setTutorialSteps(steps);
    // This needs to be a function so that the state-setter doesn't call the component
    // as a function.
    setTooltipComponentOverride(() => opts?.tooltipComponentOverride);
    opts?.scrollOffset && setScrollOffset(opts.scrollOffset);
  }, []);

  const hideTutorials = useCallback(() => setTutorialSteps([]), []);

  const steps = useMemo(() => {
    const steps = tutorialSteps.map(
      step =>
        ({
          target: `[data-tutorial-spotlight-key="${step.tutorialKey}"]`,
          disableBeacon: true,
          title: step.tutorialKey,
          ...step,
          placement: step?.placement ?? 'top',
        }) as Step,
    );
    return steps;
  }, [tutorialSteps]);

  const { mutate: markTutorialComplete } = useMarkTutorialComplete();
  const callback = useCallback(
    ({ action, lifecycle, step }: CallBackProps) => {
      if (action === 'next' && lifecycle === 'complete') {
        markTutorialComplete([step.title as TutorialKey]);
      }
    },
    [markTutorialComplete],
  );

  const tutorialIsShowing = !disabled && steps.length > 0;

  return (
    <TutorialSpotlightContext.Provider value={{ showTutorials, hideTutorials, tutorialIsShowing }}>
      {children}
      {tutorialIsShowing && (
        <>
          <KeyboardShortcuts enterFunc={e => e.stopImmediatePropagation()} />
          <Joyride
            scrollOffset={scrollOffset}
            continuous
            disableCloseOnEsc
            disableOverlayClose
            disableScrollParentFix
            hideBackButton
            hideCloseButton
            tooltipComponent={TooltipComponentOverride || TutorialTooltip}
            locale={{ last: 'Close', next: 'Next' }}
            steps={steps}
            callback={callback}
          />
        </>
      )}
    </TutorialSpotlightContext.Provider>
  );
};

import {
  TaskCompletion,
  TaskItemCompletion,
  TaskItemStatus,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { TaskItem } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFeatureFlags } from './feature-flags';
import {
  isPkgOrTaskComplete,
  isTaskItemAttempted,
  isTaskItemOnFinalChance,
  isTaskItemOnNonInitialChance,
} from './package';
import { LQDPathData, makeTaskItemPath, makeTaskSummaryPath, useLQDPath } from './paths';

/**
 * getLQDTaskItemPaths returns the navigation links for the first incomplete task item, next
 * incomplete task item and the previous task item when in LQD. In the case where all task items are
 * complete, the summary path will be used for the first and next incomplete task item paths.
 *
 * The LQDPathData can be obtained from the useLQDPath hook.
 */
export const getLQDTaskItemPaths = (
  { parentPath, packageID, taskIndex, taskItemIndex }: LQDPathData,
  taskItems: TaskItemCompletion[],
) => {
  const paths = {
    previousTaskItemPath: '',
    currentTaskItemPath: '',
    firstIncompleteTaskItemPath: '',
    nextIncompleteTaskItemPath: '',
  };

  // If we have no task items, just return empty paths.
  if (!taskItems) {
    return paths;
  }

  const { previousTaskItem, firstIncompleteTaskItem, nextIncompleteTaskItem } = getTaskItems(
    taskItems,
    taskItemIndex,
  );

  const summaryPath = makeTaskSummaryPath(parentPath, packageID, taskIndex);

  return {
    previousTaskItemPath: previousTaskItem
      ? makeTaskItemPath(parentPath, packageID, taskIndex, previousTaskItem.taskItemIndex)
      : '',
    currentTaskItemPath: makeTaskItemPath(parentPath, packageID, taskIndex, taskItemIndex || 1),
    firstIncompleteTaskItemPath: firstIncompleteTaskItem
      ? makeTaskItemPath(parentPath, packageID, taskIndex, firstIncompleteTaskItem.taskItemIndex)
      : summaryPath,
    nextIncompleteTaskItemPath: nextIncompleteTaskItem
      ? makeTaskItemPath(parentPath, packageID, taskIndex, nextIncompleteTaskItem.taskItemIndex)
      : summaryPath,
  };
};

const isIncomplete = ({ status }: TaskItem) => {
  return status !== TaskItemStatus.DONE && status !== TaskItemStatus.BADGED;
};

type TaskItems = {
  firstIncompleteTaskItem: TaskItem | undefined;
  nextIncompleteTaskItem: TaskItem | undefined;
  previousTaskItem: TaskItem | undefined;
};

/**
 * getTaskItems loops through the task items and tries to find the current task item. On the way
 * through, it will set the first incomplete task item. If it finds the current task item, the
 * previous task item and next incomplete task item will be set if they exist.
 */
const getTaskItems = (taskItems: TaskItem[], currentTaskItemIndex?: number): TaskItems => {
  const results: TaskItems = {
    nextIncompleteTaskItem: undefined,
    firstIncompleteTaskItem: undefined,
    previousTaskItem: undefined,
  };

  if (!taskItems) {
    return results;
  }

  let afterCurrent = false;
  for (let i = 0; i < taskItems.length; i++) {
    const taskItem = taskItems[i];
    const taskItemIsIncomplete = isIncomplete(taskItem);

    // store first incomplete task item
    if (!results.firstIncompleteTaskItem && taskItemIsIncomplete) {
      results.firstIncompleteTaskItem = taskItem;
    }

    // store the previous task item if the next task item is the current item
    if (i + 1 < taskItems.length && taskItems[i + 1].taskItemIndex === currentTaskItemIndex) {
      results.previousTaskItem = taskItem;
    }

    // store the next incomplete task item
    if (taskItemIsIncomplete && afterCurrent) {
      results.nextIncompleteTaskItem = taskItem;
      break;
    }

    // set afterCurrent to true if we've found the current task item
    if (taskItem.taskItemIndex === currentTaskItemIndex) {
      afterCurrent = true;
    }
  }

  return results;
};

const SummaryTaskItemIndex = 1000;

/**
 * Tracks the current and previous task item index and returns true if the current task item index
 * is less than the previous index, indicating that animations should play in reverse.
 *
 * If visibleTaskItems is provided, the position of the task item within the visible task items
 * will be used
 */
export const useReverseAnimation = (
  visibleTaskItems: TaskItemCompletion[] | undefined,
): boolean => {
  const { taskItemIndex } = useLQDPath();
  const [previous, setPrevious] = useState(taskItemIndex || 0);
  useEffect(() => setPrevious(taskItemIndex || SummaryTaskItemIndex), [taskItemIndex]);

  if (taskItemIndex !== undefined && visibleTaskItems !== undefined) {
    const getVisibleIndex = (taskItemIndex: number) => {
      if (taskItemIndex === SummaryTaskItemIndex) {
        return SummaryTaskItemIndex;
      }
      for (let i = 0; i < visibleTaskItems.length; i++) {
        if (visibleTaskItems[i].taskItemIndex === taskItemIndex) {
          return i + 1;
        }
      }
      return 0;
    };
    const visibleIndex = getVisibleIndex(taskItemIndex);
    const previousVisibleIndex = getVisibleIndex(previous);

    return (visibleIndex || SummaryTaskItemIndex) < previousVisibleIndex;
  }

  return (taskItemIndex || SummaryTaskItemIndex) < previous;
};

/**
 * Compares the retry_index search parameter against the current task item index. If they are both
 * the same valid number then true will be returned, indicating that the question should be retried.
 */
export const useRetryQuestion = (): boolean => {
  const { taskItemIndex } = useLQDPath();
  const [searchParams] = useSearchParams();

  const retryParam = searchParams.get('retry_index');
  if (!retryParam || !taskItemIndex) {
    return false;
  }

  const retryIndex = parseInt(retryParam);
  if (!retryIndex) {
    return false;
  }

  return taskItemIndex === retryIndex;
};

/**
 * Checks to see if the current task is already complete on page load.
 */
export const useTaskCompleteOnLoad = (tasks: TaskCompletion[] | undefined) => {
  const { packageID, taskIndex } = useLQDPath();

  const [completeOnLoad, setCompleteOnLoad] = useState<boolean | undefined>(undefined);
  useEffect(() => setCompleteOnLoad(undefined), [packageID, taskIndex]);

  const task = tasks?.find(t => t.taskIndex === taskIndex);

  if (task && completeOnLoad === undefined) {
    setCompleteOnLoad(isPkgOrTaskComplete(task));
  }

  return !!completeOnLoad;
};

/**
 * Checks to see if the current task item is complete on page load.
 */
export const useTaskItemCompleteOnLoad = (taskItems: TaskItemCompletion[] | undefined) => {
  const { packageID, taskIndex, taskItemIndex } = useLQDPath();

  const [completeOnLoad, setCompleteOnLoad] = useState<boolean | undefined>(undefined);
  useEffect(() => setCompleteOnLoad(undefined), [packageID, taskIndex, taskItemIndex]);

  const taskItem = taskItems?.find(ti => ti.taskItemIndex === taskItemIndex);
  if (taskItem && completeOnLoad === undefined) {
    setCompleteOnLoad(taskItem.status === TaskItemStatus.DONE);
  }

  return !!completeOnLoad;
};

/**
 * Derives some common information about task and task items.
 */
export const useLQDTaskInfo = (
  task: TaskCompletion | undefined,
  taskItems: TaskItemCompletion[] | undefined,
  currentTaskItem: TaskItemCompletion | undefined,
) => {
  const featureFlags = useFeatureFlags();
  const maxNumAttempts =
    featureFlags.getBooleanFlag('sparxweb2-question-swaps', false) ||
    featureFlags.getBooleanFlag('sparxweb2-three-attempts', false)
      ? 3
      : 2;

  return {
    isTaskItemOnNonInitialChance: isTaskItemOnNonInitialChance(
      task,
      currentTaskItem,
      maxNumAttempts,
    ),
    isTaskItemOnFinalChance: isTaskItemOnFinalChance(task, currentTaskItem, maxNumAttempts),
    isTaskItemAttempted: isTaskItemAttempted(currentTaskItem),
    isTaskItemSwapped: !!currentTaskItem?.swappedInForIndex,
    isLastTaskItem:
      !!currentTaskItem &&
      !!taskItems &&
      taskItems[taskItems.length - 1]?.taskItemIndex === currentTaskItem.taskItemIndex,
    isTaskComplete: !!task && isPkgOrTaskComplete(task),
  };
};

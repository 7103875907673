import { OnboardingLockContext } from 'context/onboardinglock/provider';
import { useContext } from 'react';

export const useOnboardingLockState = () => {
  const ctx = useContext(OnboardingLockContext);
  if (!ctx) {
    throw new Error('useOnboardingLockState can only be used inside an OnboardingLockProvider');
  }
  return ctx;
};

import { useGetStudentRewards } from 'queries/studentrewards';
import { useFeatureFlags } from 'utils/feature-flags';

export const useGetLevelProgressPercentage = (): number => {
  const { data } = useGetStudentRewards({ suspense: true });
  const ff = useFeatureFlags();
  const useNewXp = ff.getBooleanFlag('new-xp-system', false);
  const currentXp = data?.xpPoints || 0;
  const currentLevel = data?.currentXPLevel?.level || 0;
  // TODO - FF: new-xp-system
  if (!useNewXp && currentLevel === 25) {
    return 100;
  }

  const currentLevelXpThreshold = data?.currentXPLevel?.xpRequired || 0;
  const nextLevelXpThreshold = data?.nextXPLevel?.xpRequired || 0;

  const xpLevelInterval = nextLevelXpThreshold - currentLevelXpThreshold;
  const xpIntoCurrentLevel = currentXp - currentLevelXpThreshold;
  return (100 * xpIntoCurrentLevel) / xpLevelInterval;
};

import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { getNowTimestamp } from '@sparx/react-utils';

interface RequestWithTimestamp {
  timestamp?: Timestamp;
}

/**
 * ensureTimestamp makes sure that the timestamp field on a request is set.
 * This timestamp is important for swworker.
 */
export const ensureTimestamp = <T extends RequestWithTimestamp>(req: T): T => {
  if (!req.timestamp) {
    req.timestamp = getNowTimestamp();
  }

  return req;
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/notifications/notifications/v1/notifications.proto" (package "sparx.notifications.notifications.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Notifications } from './notifications';
import type { ListNotificationsAndDisplayDataResponse } from './notifications';
import type { ListNotificationsAndDisplayDataRequest } from './notifications';
import type { MarkNotificationsReadResponse } from './notifications';
import type { MarkNotificationsReadRequest } from './notifications';
import type { ListNotificationsResponse } from './notifications';
import type { ListNotificationsRequest } from './notifications';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { CreateNotificationsResponse } from './notifications';
import type { CreateNotificationsRequest } from './notifications';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.notifications.notifications.v1.Notifications
 */
export interface INotificationsClient {
  /**
   * CreateNotifications creates multiple notifications for the authenticated
   * user
   *
   * @generated from protobuf rpc: CreateNotifications(sparx.notifications.notifications.v1.CreateNotificationsRequest) returns (sparx.notifications.notifications.v1.CreateNotificationsResponse);
   */
  createNotifications(
    input: CreateNotificationsRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateNotificationsRequest, CreateNotificationsResponse>;
  /**
   * ListNotifications lists all notifications for the authenticated user
   *
   * Notification are returned in created date order, most recent first, and are
   * limited to 100 most recent notifications
   *
   * @generated from protobuf rpc: ListNotifications(sparx.notifications.notifications.v1.ListNotificationsRequest) returns (sparx.notifications.notifications.v1.ListNotificationsResponse);
   */
  listNotifications(
    input: ListNotificationsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListNotificationsRequest, ListNotificationsResponse>;
  /**
   * MarkNotificationsRead marks the specified notifications as read
   *
   * Only notifications belonging to the authenticated user can be modified
   *
   * @generated from protobuf rpc: MarkNotificationsRead(sparx.notifications.notifications.v1.MarkNotificationsReadRequest) returns (sparx.notifications.notifications.v1.MarkNotificationsReadResponse);
   */
  markNotificationsRead(
    input: MarkNotificationsReadRequest,
    options?: RpcOptions,
  ): UnaryCall<MarkNotificationsReadRequest, MarkNotificationsReadResponse>;
  /**
   * ListNotificationsAndDisplayData returns the notifications for the authenticated user and their display
   * data
   *
   * @generated from protobuf rpc: ListNotificationsAndDisplayData(sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataRequest) returns (sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataResponse);
   */
  listNotificationsAndDisplayData(
    input: ListNotificationsAndDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListNotificationsAndDisplayDataRequest,
    ListNotificationsAndDisplayDataResponse
  >;
}
/**
 * @generated from protobuf service sparx.notifications.notifications.v1.Notifications
 */
export class NotificationsClient implements INotificationsClient, ServiceInfo {
  typeName = Notifications.typeName;
  methods = Notifications.methods;
  options = Notifications.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * CreateNotifications creates multiple notifications for the authenticated
   * user
   *
   * @generated from protobuf rpc: CreateNotifications(sparx.notifications.notifications.v1.CreateNotificationsRequest) returns (sparx.notifications.notifications.v1.CreateNotificationsResponse);
   */
  createNotifications(
    input: CreateNotificationsRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateNotificationsRequest, CreateNotificationsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateNotificationsRequest,
      CreateNotificationsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListNotifications lists all notifications for the authenticated user
   *
   * Notification are returned in created date order, most recent first, and are
   * limited to 100 most recent notifications
   *
   * @generated from protobuf rpc: ListNotifications(sparx.notifications.notifications.v1.ListNotificationsRequest) returns (sparx.notifications.notifications.v1.ListNotificationsResponse);
   */
  listNotifications(
    input: ListNotificationsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListNotificationsRequest, ListNotificationsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListNotificationsRequest, ListNotificationsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * MarkNotificationsRead marks the specified notifications as read
   *
   * Only notifications belonging to the authenticated user can be modified
   *
   * @generated from protobuf rpc: MarkNotificationsRead(sparx.notifications.notifications.v1.MarkNotificationsReadRequest) returns (sparx.notifications.notifications.v1.MarkNotificationsReadResponse);
   */
  markNotificationsRead(
    input: MarkNotificationsReadRequest,
    options?: RpcOptions,
  ): UnaryCall<MarkNotificationsReadRequest, MarkNotificationsReadResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      MarkNotificationsReadRequest,
      MarkNotificationsReadResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListNotificationsAndDisplayData returns the notifications for the authenticated user and their display
   * data
   *
   * @generated from protobuf rpc: ListNotificationsAndDisplayData(sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataRequest) returns (sparx.notifications.notifications.v1.ListNotificationsAndDisplayDataResponse);
   */
  listNotificationsAndDisplayData(
    input: ListNotificationsAndDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListNotificationsAndDisplayDataRequest,
    ListNotificationsAndDisplayDataResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListNotificationsAndDisplayDataRequest,
      ListNotificationsAndDisplayDataResponse
    >('unary', this._transport, method, opt, input);
  }
}

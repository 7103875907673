import { useWhatsNewEntries } from 'components/whats-new/entries';
import { ValidatedPromotedWhatsNewEntry, WhatsNewEntry } from 'components/whats-new/types';
import { addMonths, isBefore } from 'date-fns';
import { useSession } from 'queries/session';
import { useMemo } from 'react';
import { FlagValue, useFeatureFlags } from 'utils/feature-flags';
import { useIsPrimaryStudent } from 'utils/session';

export const lastSeenWhatsNewEntryIDKey = 'last_seen_whats_new_entry_id';

export const useLastSeenEntryId = () => {
  const { data: sessionData } = useSession();
  return sessionData?.student?.labels[lastSeenWhatsNewEntryIDKey];
};

/**
 * usePromotedWhatsNewEntry returns the latest promoted entry which should be displayed, and its
 * index within the array returned by useWhatsNewEntries. It doesn't consider the last seen entry, so
 * may return an old entry which has been seen.
 */
export const usePromotedWhatsNewEntry = (): { entry: WhatsNewEntry; index: number } | undefined => {
  const flags = useFeatureFlags().getAllFlags();
  const isPrimaryStudent = useIsPrimaryStudent();
  const whatsNewEntries = useWhatsNewEntries();

  // get newest promoted entry
  const promotedEntryIndex = whatsNewEntries.findIndex(entry =>
    isValidPromotedEntry(entry, flags, isPrimaryStudent),
  );

  if (promotedEntryIndex === -1) {
    return undefined;
  }

  const promotedEntry = whatsNewEntries[promotedEntryIndex];
  // TODO: if adding a whats new page - check if the promoted entry is in the whats new entries

  if (shouldDisplayPromotedEntry(promotedEntry)) {
    return { entry: promotedEntry, index: promotedEntryIndex };
  }

  return undefined;
};

/**
 * makeEntryId returns a string to identify the given entry.
 */
export const makeEntryID = (entry: WhatsNewEntry) =>
  entry ? entry.date + '#' + entry.title : 'NO_ENTRY';

/**
 * shouldDisplayPromotedEntry determines whether a promoted entry should be displayed. If a
 * promotedEndDate is present, the entry will be displayed until that date. If not, the entry
 * will be displayed for 2 months.
 */
export const shouldDisplayPromotedEntry = (entry: WhatsNewEntry) => {
  const now = new Date();
  let expiryDate: Date | undefined;
  if (entry.promotedEndDate) {
    expiryDate = new Date(entry.promotedEndDate);
  } else {
    expiryDate = addMonths(new Date(entry.date), 2);
  }
  return isBefore(now, expiryDate);
};

/**
 * isValidEntry determines whether an entry is valid for use, based on feature flags and date.
 */
export const isValidEntry = (
  entry: WhatsNewEntry,
  flags: Record<string, FlagValue>,
  isPrimaryStudent: boolean,
) =>
  !!entry.isForPrimaryStudents === isPrimaryStudent &&
  // entries that specify require that feature flag to be specified and truthy
  (!entry.featureFlag ||
    (typeof entry.featureFlag === 'string'
      ? !!flags[entry.featureFlag]
      : !!entry.featureFlag.showEntry(flags[entry.featureFlag.key]))) &&
  // Only posts with dates today or in past are valid
  Date.parse(entry.date) <= new Date().valueOf();

/**
 * isValidPromotedEntry restricts entries to only those marked promoted.
 */
export const isValidPromotedEntry = (
  entry: WhatsNewEntry,
  flags: Record<string, FlagValue>,
  isPrimaryStudent: boolean,
): entry is ValidatedPromotedWhatsNewEntry =>
  isValidEntry(entry, flags, isPrimaryStudent) &&
  // if entry has a promoted with content the post is a promoted entry.
  !!entry.promoted?.body &&
  entry.promoted.body.length > 0;

/**
 * getLastSeenIndex returns the index of the last seen entry, or the index of the first entry that is
 * older than the last seen one if the last seen entry is not in the entries.
 * If all entries are newer than the last seen id, returns the length of the entries array.
 */
export const getLastSeenIndex = (entries: WhatsNewEntry[], lastSeenId: string) => {
  // if we have the entry, return its index
  let index = entries.findIndex(entry => makeEntryID(entry) === lastSeenId);
  if (index >= 0) {
    return index;
  }

  // if we don't have the entry we return the index of the first entry that is less than (before)
  // the lastSeen one. The id's start with a YYYY-mm-dd date, so comparing like this works.
  index = entries.findIndex(entry => lastSeenId > makeEntryID(entry));
  if (index >= 0) {
    return index;
  }

  // if the last seen entry is older than the oldest in the entries, return the length of the
  // entries array so all entries are considered unseen
  return entries.length;
};

export const useValidWhatsNewEntries = () => {
  const flags = useFeatureFlags().getAllFlags();
  const isPrimaryStudent = useIsPrimaryStudent();
  const whatsNewEntries = useWhatsNewEntries();
  const validEntries = useMemo(
    () => whatsNewEntries.filter(entry => isValidEntry(entry, flags, isPrimaryStudent)),
    [flags, isPrimaryStudent, whatsNewEntries],
  );
  return validEntries;
};

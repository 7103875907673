// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/messaging/server/v1/server.proto" (package "sparx.messaging.server.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SWServer } from './server';
import type { Empty } from '../../../../../google/protobuf/empty';
import type { AckServerMessageRequest } from './server';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ServerMessages } from './server';
import type { ServerMessageStreamingRequest } from './server';
import type { ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * SWServer describes the GRPC endpoints that are served by swserver.
 *
 * @generated from protobuf service sparx.messaging.server.v1.SWServer
 */
export interface ISWServerClient {
  /**
   * ServerMessageStreaming handles streaming messages from the server to the client.
   * The client makes a single request to this endpoint and a stream is established.
   * It replaces the message pushing functionality of the WebSocket.
   *
   * @generated from protobuf rpc: ServerMessageStreaming(sparx.messaging.server.v1.ServerMessageStreamingRequest) returns (stream sparx.messaging.server.v1.ServerMessages);
   */
  serverMessageStreaming(
    input: ServerMessageStreamingRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<ServerMessageStreamingRequest, ServerMessages>;
  /**
   * AckServerMessage handles acknowledgements that the client sends when it has received a message pushed by the
   * streaming endpoint above.
   *
   * @generated from protobuf rpc: AckServerMessage(sparx.messaging.server.v1.AckServerMessageRequest) returns (google.protobuf.Empty);
   */
  ackServerMessage(
    input: AckServerMessageRequest,
    options?: RpcOptions,
  ): UnaryCall<AckServerMessageRequest, Empty>;
}
/**
 * SWServer describes the GRPC endpoints that are served by swserver.
 *
 * @generated from protobuf service sparx.messaging.server.v1.SWServer
 */
export class SWServerClient implements ISWServerClient, ServiceInfo {
  typeName = SWServer.typeName;
  methods = SWServer.methods;
  options = SWServer.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * ServerMessageStreaming handles streaming messages from the server to the client.
   * The client makes a single request to this endpoint and a stream is established.
   * It replaces the message pushing functionality of the WebSocket.
   *
   * @generated from protobuf rpc: ServerMessageStreaming(sparx.messaging.server.v1.ServerMessageStreamingRequest) returns (stream sparx.messaging.server.v1.ServerMessages);
   */
  serverMessageStreaming(
    input: ServerMessageStreamingRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<ServerMessageStreamingRequest, ServerMessages> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ServerMessageStreamingRequest, ServerMessages>(
      'serverStreaming',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * AckServerMessage handles acknowledgements that the client sends when it has received a message pushed by the
   * streaming endpoint above.
   *
   * @generated from protobuf rpc: AckServerMessage(sparx.messaging.server.v1.AckServerMessageRequest) returns (google.protobuf.Empty);
   */
  ackServerMessage(
    input: AckServerMessageRequest,
    options?: RpcOptions,
  ): UnaryCall<AckServerMessageRequest, Empty> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AckServerMessageRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}

import { TaskState } from '@sparx/api/apis/sparx/sparxweb/v1/taskstate';
import { SwapOutTaskItemRequest } from '@sparx/api/apis/sparx/swworker/v1/worker';
import { Button } from '@sparx/sparx-design/components';
import { Cross, Flag } from '@sparx/sparx-design/icons';
import { useSwapOutQuestion } from 'queries/swapquestion';
import { Link } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';

import resultsStyles from '../Result.module.css';
import { ReactComponent as CardSwapIcon } from './icons/icon-card-swap-active.svg';
import { ReactComponent as InactiveCardSwapIcon } from './icons/icon-card-swap-inactive.svg';
import styles from './QuestionSwapBar.module.css';

type QuestionSwapBarProps = {
  packageID: string;
  maxSwaps: number;
  numSwapsUsed: number;
  priorIncorrectAttemptCount: number;
  taskIndex: number;
  taskItemIndex: number;
  currentTaskItemPath: string;
  nextTaskItemPath: string;
  retryQuestionFn: () => void;
  isLastItem: boolean;
  videoWatched: boolean;
  isSeekHelp: boolean;
};

export const QuestionSwapBar = ({
  packageID,
  maxSwaps,
  numSwapsUsed,
  priorIncorrectAttemptCount,
  taskIndex,
  taskItemIndex,
  currentTaskItemPath,
  nextTaskItemPath,
  retryQuestionFn,
  isLastItem,
  videoWatched,
  isSeekHelp,
}: QuestionSwapBarProps) => {
  const numberOfSwapsRemaining = maxSwaps - numSwapsUsed;

  const showSwapWidget =
    priorIncorrectAttemptCount > 2 && (videoWatched || numberOfSwapsRemaining === 0);

  return (
    <div className={styles.QBarResultsContainer}>
      <div className={resultsStyles.ResultInfoWrapper}>
        <div className={resultsStyles.Result}>
          {isSeekHelp ? (
            <Flag variant="Purple" className={resultsStyles.SeekHelpResultImage} />
          ) : (
            <Cross variant="Red" className={resultsStyles.ResultImage} />
          )}
          <div className={resultsStyles.Messages}>
            {isSeekHelp ? (
              <span className={resultsStyles.SeekHelpResultMessage}>
                Looks like you might be stuck
              </span>
            ) : (
              <span className={resultsStyles.ResultMessage}>Finding this one tricky?</span>
            )}
          </div>
        </div>
        {!showSwapWidget && numberOfSwapsRemaining > 0 && (
          <div className={styles.AdditionalInfo}>
            <b>{`Have another go${!videoWatched ? ' and watch the video' : ''}.`}</b>{' '}
            <span className={styles.Remark}>
              If you still can't do it you'll be given the choice to swap this to an easier
              question.
            </span>
          </div>
        )}

        {showSwapWidget && (
          <>
            <div className={styles.AdditionalInfo}>
              <b>
                {isSeekHelp ? (
                  <span>Well done for trying. Try moving on and asking your teacher for help.</span>
                ) : (
                  <>
                    <span className={resultsStyles.Remark}>Looks like you've tried hard.</span>{' '}
                    {numberOfSwapsRemaining > 0 && (
                      <span>You might want to swap this question for an easier one.</span>
                    )}
                  </>
                )}
              </b>
            </div>
            <QSwapIconWidget maxSwaps={maxSwaps} numSwapsUsed={numSwapsUsed} />
          </>
        )}
      </div>
      <div className={resultsStyles.ButtonsContainer}>
        {/* in SeekHelp, swap Retry & Continue buttons */}
        {isSeekHelp ? (
          <>
            <RetryButton
              currentTaskItemPath={currentTaskItemPath}
              retryFn={retryQuestionFn}
              isSeekHelp={isSeekHelp}
            />
            <ContinueButton
              nextTaskItemPath={nextTaskItemPath}
              isLastItem={isLastItem}
              isTethering={false}
              isSeekHelp={isSeekHelp}
            />
          </>
        ) : (
          <>
            <ContinueButton
              nextTaskItemPath={nextTaskItemPath}
              isLastItem={isLastItem}
              isTethering={false}
              isSeekHelp={isSeekHelp}
            />
            <RetryButton
              currentTaskItemPath={currentTaskItemPath}
              retryFn={retryQuestionFn}
              isSeekHelp={isSeekHelp}
            />
          </>
        )}
        {showSwapWidget && numberOfSwapsRemaining > 0 && (
          <SwapQuestionButton
            packageID={packageID}
            taskIndex={taskIndex}
            taskItemIndex={taskItemIndex}
            isSeekHelp={isSeekHelp}
          />
        )}
      </div>
    </div>
  );
};

const ContinueButton = ({
  nextTaskItemPath,
  isLastItem,
  isTethering,
  isSeekHelp,
}: {
  nextTaskItemPath: string;
  isLastItem: boolean;
  isTethering: boolean;
  isSeekHelp: boolean;
}) => {
  const sendEvent = useAnalytics();

  const buttonText = isLastItem ? 'Summary' : 'Move on';
  return (
    <Button
      as={Link}
      to={nextTaskItemPath}
      reloadDocument={isTethering}
      variant={isSeekHelp ? 'contained' : isLastItem ? 'outlined' : 'text'}
      onClick={() => {
        sendEvent({
          action: 'clicked continue from incorrect result',
          category: 'lqd',
          labels: { 'button text': buttonText, 'in seek help': isSeekHelp },
        });
      }}
    >
      {buttonText}
    </Button>
  );
};

const RetryButton = ({
  currentTaskItemPath,
  retryFn,
  isSeekHelp,
}: {
  currentTaskItemPath: string;
  retryFn: () => void;
  isSeekHelp: boolean;
}) => {
  const sendEvent = useAnalytics();
  return (
    <Button
      as={Link}
      to={currentTaskItemPath}
      variant={isSeekHelp ? 'text' : 'contained'}
      onClick={() => {
        sendEvent({
          action: 'clicked retry from incorrect result',
          category: 'lqd',
          labels: { 'button text': 'Try again', 'in seek help': isSeekHelp },
        });
        retryFn();
      }}
    >
      Try again
    </Button>
  );
};

export const SwapQuestionButton = ({
  packageID,
  taskIndex,
  taskItemIndex,
  isSeekHelp,
}: {
  packageID: string;
  taskIndex: number;
  taskItemIndex: number;
  isSeekHelp: boolean;
}) => {
  const sendEvent = useAnalytics();
  const { mutate: swapOutQuestionMutation, isLoading } = useSwapOutQuestion();

  return (
    <Button
      isLoading={isLoading}
      className={styles.SwapQuestionButton}
      variant={isSeekHelp ? 'contained' : 'outlined'}
      onClick={() => {
        sendEvent({
          action: 'clicked swap out question',
          category: 'lqd',
          labels: {
            packageID: packageID,
            taskIndex: taskIndex || 0,
            taskItemIndex: taskItemIndex || 0,
            'in seek help': isSeekHelp,
          },
        });
        const req: SwapOutTaskItemRequest = {
          position: {
            packageID: packageID,
            taskIndex: taskIndex || 0,
            taskItemIndex: taskItemIndex || 0,
            taskState: TaskState.STATE_UNSPECIFIED,
          },
        };
        swapOutQuestionMutation(req);
      }}
    >
      Swap question
    </Button>
  );
};

const QSwapIconWidget = ({
  maxSwaps,
  numSwapsUsed,
}: {
  maxSwaps: number;
  numSwapsUsed: number;
}) => {
  const swapsRemaining = maxSwaps - numSwapsUsed;
  const rotationSpread = 30;
  const startRotation = 0 + rotationSpread / 2;
  const rotationIncrement = rotationSpread / (maxSwaps - 1);
  return (
    <div className={styles.QSwapIconWidget}>
      <div className={styles.SwapCardIconWrapper}>
        {Array.from({ length: maxSwaps }).map((_, i) => {
          const rotation = `${startRotation + i * -rotationIncrement}deg`;
          const transformOrigin = i > (maxSwaps - 1) / 2 ? 'bottom right' : 'bottom left';
          return (
            <div
              key={i}
              className={styles.SwapCardIcon}
              style={{
                rotate: rotation,
                transformOrigin: transformOrigin,
              }}
            >
              {numSwapsUsed <= i ? <CardSwapIcon /> : <InactiveCardSwapIcon />}
            </div>
          );
        })}
      </div>
      <span>
        You have{' '}
        <b>
          {`${swapsRemaining || 'no'} swaps remaining
    `}
        </b>
        for this homework
      </span>
    </div>
  );
};

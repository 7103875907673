import styles from './PieChart.module.css';

/**
 * PieChart shows a simple pie chart of progress, one green slice for amount of percentage completed
 * and the rest in blue.
 */
export const PieChart = ({
  score,
  total,
}: {
  score?: number;
  total?: number;
}) => {
  if (!total) {
    return null;
  }
  const percentCorrect = ((score || 0) * 100) / total;
  const cssVars = {
    ['--percent-correct']: `${percentCorrect}%`,
  } as React.CSSProperties;
  return <div className={styles.PieChart} style={cssVars} />;
};

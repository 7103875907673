import classNames from 'classnames';
import { motion } from 'framer-motion';

import styles from './QuestionBanner.module.css';
import { BannerData } from './useBannerData';

interface IQuestionBannerProps {
  data: BannerData;
}

export const QuestionBanner = ({ data }: IQuestionBannerProps) => {
  return (
    data && (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto' }}
        exit={{ opacity: 0, height: 0 }}
        className={classNames(styles.BannerContainer, data.className)}
      >
        {data.content}
      </motion.div>
    )
  );
};

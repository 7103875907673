// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/personalisation/hwgmsg/v1/homeworkgen.proto" (package "sparx.personalisation.hwgmsg", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { BoolValue } from '../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { PackageDates } from '../../../packages/v1/spxpkg';
import { TaskItem } from '../../../packages/v1/spxpkg';
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.Target
 */
export interface Target {
  /**
   * @generated from protobuf field: sparx.packages.v1.TaskItem taskItem = 1;
   */
  taskItem?: TaskItem;
}
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.HomeworkSummary
 */
export interface HomeworkSummary {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string schoolID = 2;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string homeworkID = 3;
   */
  homeworkID: string;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageDates mainPackageDates = 4;
   */
  mainPackageDates?: PackageDates;
  /**
   * @generated from protobuf field: sparx.personalisation.hwgmsg.Target target = 5;
   */
  target?: Target;
  /**
   * @generated from protobuf field: bool hasCompulsory = 6;
   */
  hasCompulsory: boolean;
  /**
   * @generated from protobuf field: bool isFirstHomework = 7;
   */
  isFirstHomework: boolean;
}
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.HomeworkPackageInfo
 */
export interface HomeworkPackageInfo {
  /**
   * @generated from protobuf field: string package_id = 1;
   */
  packageId: string;
  /**
   * @generated from protobuf field: string package_type = 2;
   */
  packageType: string;
  /**
   * @generated from protobuf field: string student_id = 3;
   */
  studentId: string;
  /**
   * @generated from protobuf field: float requested_length = 4;
   */
  requestedLength: number;
}
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.TimeOfDay
 */
export interface TimeOfDay {
  /**
   * @generated from protobuf field: int32 hour = 1;
   */
  hour: number;
  /**
   * @generated from protobuf field: int32 minute = 2;
   */
  minute: number;
  /**
   * @generated from protobuf field: int32 second = 3;
   */
  second: number;
}
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.HomeworkCreated
 */
export interface HomeworkCreated {
  /**
   * Tag is an indication of whether this is a real run or not. If the tag does not equal "" then
   * this is a dry run, and should likely be ignored
   *
   * @generated from protobuf field: string tag = 1;
   */
  tag: string;
  /**
   * @generated from protobuf field: string homeworkID = 2;
   */
  homeworkID: string;
  /**
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string groupID = 4;
   */
  groupID: string;
  /**
   * @generated from protobuf field: repeated string studentIDs = 5;
   */
  studentIDs: string[];
  /**
   * @generated from protobuf field: int64 weekNumber = 6;
   */
  weekNumber: number;
  /**
   * @generated from protobuf field: sparx.personalisation.hwgmsg.RequestedHomeworkLength length = 7;
   */
  length: RequestedHomeworkLength;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp setDate = 8;
   */
  setDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp dueDate = 9;
   */
  dueDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp createdTime = 10;
   */
  createdTime?: Timestamp;
  /**
   * @generated from protobuf field: repeated sparx.personalisation.hwgmsg.HomeworkPackageInfo packages = 11;
   */
  packages: HomeworkPackageInfo[];
  /**
   * localSetTime is the time of day the homework was set at in the local timezone
   *
   * @generated from protobuf field: sparx.personalisation.hwgmsg.TimeOfDay localSetTime = 12;
   */
  localSetTime?: TimeOfDay;
  /**
   * compulsoryMinutes is the requested length of the class' compulsory homework
   * when the length is 'full'. It will be the default value set for the class in Class Manager unless the teacher
   * has overridden it for the specific homework week in Planner. This does not take into account any override for
   * the specific student.
   *
   * @generated from protobuf field: int64 compulsoryMinutes = 13;
   */
  compulsoryMinutes: number;
  /**
   * @generated from protobuf field: repeated string inFocusTopicIDs = 14;
   */
  inFocusTopicIDs: string[];
  /**
   * localDueTime is the time of day the homework was due at in the local timezone
   *
   * @generated from protobuf field: sparx.personalisation.hwgmsg.TimeOfDay localDueTime = 15;
   */
  localDueTime?: TimeOfDay;
  /**
   * hasTablesTask is true if the homework contained a Times Tables task
   *
   * @generated from protobuf field: bool hasTablesTask = 16;
   */
  hasTablesTask: boolean;
  /**
   * tablesTaskOverrideStudents contains the IDs of students who tables task
   * setting different than the groups/
   * If hasTablesTask is true, students in this list did not have a tables task
   * If hasTablesTask is false, students in the list did have a tables task (assuming they also had a compulsory package)
   *
   * @generated from protobuf field: repeated string tablesTaskOverrideStudents = 17;
   */
  tablesTaskOverrideStudents: string[];
  /**
   * The requested length of the compulsory homeworks set for the class when the length is 'full', ignoring whether
   * it has been overridden for the specific homework week in planner
   *
   * @generated from protobuf field: int64 defaultCompulsoryMinutes = 18;
   */
  defaultCompulsoryMinutes: number;
}
/**
 * Received by msgelephant to persist how topic assignments have been interpreted
 * into selections by hwgen's topic level initialiser.
 *
 * @generated from protobuf message sparx.personalisation.hwgmsg.TopicsSelected
 */
export interface TopicsSelected {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp assignmentTime = 2;
   */
  assignmentTime?: Timestamp;
  /**
   * @generated from protobuf field: repeated sparx.personalisation.hwgmsg.TopicSelection topicSelections = 3;
   */
  topicSelections: TopicSelection[];
  /**
   * @generated from protobuf field: string tag = 4;
   */
  tag: string;
}
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.TopicSelection
 */
export interface TopicSelection {
  /**
   * @generated from protobuf field: string assignedTopicID = 3;
   */
  assignedTopicID: string;
  /**
   * @generated from protobuf field: repeated string selectedTopicIDs = 4;
   */
  selectedTopicIDs: string[];
  /**
   * @generated from protobuf field: sparx.personalisation.hwgmsg.AssignedTopicType assignedTopicType = 5;
   */
  assignedTopicType: AssignedTopicType;
}
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.CurriculumTopicsInitialised
 */
export interface CurriculumTopicsInitialised {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp initialisationTime = 2;
   */
  initialisationTime?: Timestamp;
  /**
   * @generated from protobuf field: repeated sparx.personalisation.hwgmsg.CurriculumTopicInitialisation curriculumTopicInitialisations = 3;
   */
  curriculumTopicInitialisations: CurriculumTopicInitialisation[];
  /**
   * @generated from protobuf field: string tag = 4;
   */
  tag: string;
}
/**
 * @generated from protobuf message sparx.personalisation.hwgmsg.CurriculumTopicInitialisation
 */
export interface CurriculumTopicInitialisation {
  /**
   * @generated from protobuf field: string curriculumTopicID = 1;
   */
  curriculumTopicID: string;
  /**
   * @generated from protobuf field: string curriculumTopicStreamID = 2;
   */
  curriculumTopicStreamID: string;
  /**
   * @generated from protobuf field: bool allUnlikely = 3;
   */
  allUnlikely: boolean;
  /**
   * @generated from protobuf field: int64 topicSource = 5;
   */
  topicSource: number;
  /**
   * @generated from protobuf field: google.protobuf.BoolValue fastTrack = 6;
   */
  fastTrack?: BoolValue;
}
/**
 * @generated from protobuf enum sparx.personalisation.hwgmsg.RequestedHomeworkLength
 */
export enum RequestedHomeworkLength {
  /**
   * @generated from protobuf enum value: LENGTH_UNKNOWN = 0;
   */
  LENGTH_UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: LENGTH_FULL = 1;
   */
  LENGTH_FULL = 1,
  /**
   * @generated from protobuf enum value: LENGTH_HALF = 2;
   */
  LENGTH_HALF = 2,
  /**
   * @generated from protobuf enum value: LENGTH_OPTIONAL = 3;
   */
  LENGTH_OPTIONAL = 3,
}
/**
 * @generated from protobuf enum sparx.personalisation.hwgmsg.AssignedTopicType
 */
export enum AssignedTopicType {
  /**
   * @generated from protobuf enum value: HOMEWORK_TOPIC = 0;
   */
  HOMEWORK_TOPIC = 0,
  /**
   * @generated from protobuf enum value: CURRICULUM_TOPIC = 1;
   */
  CURRICULUM_TOPIC = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class Target$Type extends MessageType<Target> {
  constructor() {
    super('sparx.personalisation.hwgmsg.Target', [
      { no: 1, name: 'taskItem', kind: 'message', T: () => TaskItem },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.Target
 */
export const Target = new Target$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkSummary$Type extends MessageType<HomeworkSummary> {
  constructor() {
    super('sparx.personalisation.hwgmsg.HomeworkSummary', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'mainPackageDates',
        kind: 'message',
        T: () => PackageDates,
      },
      { no: 5, name: 'target', kind: 'message', T: () => Target },
      {
        no: 6,
        name: 'hasCompulsory',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 7,
        name: 'isFirstHomework',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.HomeworkSummary
 */
export const HomeworkSummary = new HomeworkSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkPackageInfo$Type extends MessageType<HomeworkPackageInfo> {
  constructor() {
    super('sparx.personalisation.hwgmsg.HomeworkPackageInfo', [
      { no: 1, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'package_type',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'requested_length',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.HomeworkPackageInfo
 */
export const HomeworkPackageInfo = new HomeworkPackageInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeOfDay$Type extends MessageType<TimeOfDay> {
  constructor() {
    super('sparx.personalisation.hwgmsg.TimeOfDay', [
      { no: 1, name: 'hour', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'minute', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'second', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.TimeOfDay
 */
export const TimeOfDay = new TimeOfDay$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCreated$Type extends MessageType<HomeworkCreated> {
  constructor() {
    super('sparx.personalisation.hwgmsg.HomeworkCreated', [
      { no: 1, name: 'tag', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'homeworkID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'groupID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'studentIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'weekNumber',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 7,
        name: 'length',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.hwgmsg.RequestedHomeworkLength',
          RequestedHomeworkLength,
        ],
      },
      { no: 8, name: 'setDate', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'dueDate', kind: 'message', T: () => Timestamp },
      { no: 10, name: 'createdTime', kind: 'message', T: () => Timestamp },
      {
        no: 11,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkPackageInfo,
      },
      { no: 12, name: 'localSetTime', kind: 'message', T: () => TimeOfDay },
      {
        no: 13,
        name: 'compulsoryMinutes',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 14,
        name: 'inFocusTopicIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 15, name: 'localDueTime', kind: 'message', T: () => TimeOfDay },
      {
        no: 16,
        name: 'hasTablesTask',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 17,
        name: 'tablesTaskOverrideStudents',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: 'defaultCompulsoryMinutes',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.HomeworkCreated
 */
export const HomeworkCreated = new HomeworkCreated$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicsSelected$Type extends MessageType<TopicsSelected> {
  constructor() {
    super('sparx.personalisation.hwgmsg.TopicsSelected', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'assignmentTime', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'topicSelections',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TopicSelection,
      },
      { no: 4, name: 'tag', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.TopicsSelected
 */
export const TopicsSelected = new TopicsSelected$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicSelection$Type extends MessageType<TopicSelection> {
  constructor() {
    super('sparx.personalisation.hwgmsg.TopicSelection', [
      {
        no: 3,
        name: 'assignedTopicID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'selectedTopicIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'assignedTopicType',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.hwgmsg.AssignedTopicType',
          AssignedTopicType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.TopicSelection
 */
export const TopicSelection = new TopicSelection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurriculumTopicsInitialised$Type extends MessageType<CurriculumTopicsInitialised> {
  constructor() {
    super('sparx.personalisation.hwgmsg.CurriculumTopicsInitialised', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'initialisationTime',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 3,
        name: 'curriculumTopicInitialisations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CurriculumTopicInitialisation,
      },
      { no: 4, name: 'tag', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.CurriculumTopicsInitialised
 */
export const CurriculumTopicsInitialised =
  new CurriculumTopicsInitialised$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurriculumTopicInitialisation$Type extends MessageType<CurriculumTopicInitialisation> {
  constructor() {
    super('sparx.personalisation.hwgmsg.CurriculumTopicInitialisation', [
      {
        no: 1,
        name: 'curriculumTopicID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'curriculumTopicStreamID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'allUnlikely', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'topicSource',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 6, name: 'fastTrack', kind: 'message', T: () => BoolValue },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.personalisation.hwgmsg.CurriculumTopicInitialisation
 */
export const CurriculumTopicInitialisation =
  new CurriculumTopicInitialisation$Type();

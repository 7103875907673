import { useContext } from 'react';

import { PackageContext } from './PackageProvider';

/**
 * useIsAssessmentPackage checks if the package in the context is an assessment package. This
 * handles the case where there is no package context and will still return false.
 */
export const useIsAssessmentPackage = (): boolean => {
  const pkg = useContext(PackageContext);
  return !!pkg && pkg.packageType.includes('assessment');
};

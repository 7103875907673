import { isEqual, isMonday, previousMonday } from 'date-fns';

export const checkIsThrottled = (today: Date, storedDate?: string) => {
  if (storedDate === undefined) {
    return false;
  }
  // js dates are utc
  const lastSentimentSubmitMonday = new Date(storedDate);

  // get the start of the day (in utc)
  today.setUTCHours(0, 0, 0, 0);
  const lastMonday = isMonday(today) ? today : previousMonday(today);
  return isEqual(lastSentimentSubmitMonday, lastMonday);
};

import { pageChangeEventAction } from '@sparx/analytics';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';

/** PageViewAnalytics sends a page view event containing the old and new url's pathnames whenever the url changes. */
export const PageViewAnalytics = () => {
  const storedPathname = useRef('uninitialised');
  const { pathname } = useLocation();
  const sendEvent = useAnalytics();
  const uuidRegex = useMemo(
    () => /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
    [],
  );

  useEffect(() => {
    if (pathname !== storedPathname.current) {
      sendEvent({
        action: pageChangeEventAction,
        category: 'navigation',
        labels: {
          'old url': storedPathname.current,
          'old url generic': storedPathname.current.replace(uuidRegex, '<uuid>'),
          'new url': pathname,
          'new url generic': pathname.replace(uuidRegex, '<uuid>'),
        },
      });
      storedPathname.current = pathname;
    }
  }, [pathname, sendEvent, storedPathname, uuidRegex]);

  return null;
};

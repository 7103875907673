// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/search/v1/search.proto" (package "sparx.content.search.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Search } from './search';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { Result } from './search';
import type { Query } from './search';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.content.search.v1.Search
 */
export interface ISearchClient {
  /**
   * @generated from protobuf rpc: Search(sparx.content.search.v1.Query) returns (sparx.content.search.v1.Result);
   */
  search(input: Query, options?: RpcOptions): UnaryCall<Query, Result>;
}
/**
 * @generated from protobuf service sparx.content.search.v1.Search
 */
export class SearchClient implements ISearchClient, ServiceInfo {
  typeName = Search.typeName;
  methods = Search.methods;
  options = Search.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: Search(sparx.content.search.v1.Query) returns (sparx.content.search.v1.Result);
   */
  search(input: Query, options?: RpcOptions): UnaryCall<Query, Result> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<Query, Result>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}

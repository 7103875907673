import { PackageCompletion, TaskCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Chip } from '@sparx/sparx-design/components';
import { ChipColourVariant, ChipStyleVariant } from '@sparx/sparx-design/components/Chip';
import { ChevronRight, Tick } from '@sparx/sparx-design/icons';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classnames from 'classnames';
import { useOnboardingLockState } from 'context/onboardinglock';
import { ReactComponent as LockIcon } from 'images/LockSolid.svg';
import onboardingTaskImage1 from 'images/onboarding/onboarding-task-1.png';
import onboardingTaskImage2 from 'images/onboarding/onboarding-task-2.png';
import onboardingTaskImage3 from 'images/onboarding/onboarding-task-3.png';
import { ReactComponent as VideoIcon } from 'images/Video.svg';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { getPkgOrTaskCompletionPercentage, isPkgOrTaskComplete } from 'utils/package';
import { makeTaskPath, makeVideoTaskPath } from 'utils/paths';
import { isVideoTask } from 'utils/taskTypes';

import styles from './OnboardingPackageTasks.module.css';

type OnboardingPackageTasksProps = {
  pkg: PackageCompletion;
  tasks: TaskCompletion[] | undefined;
};

type OnboardingTaskCardData = {
  description: string;
  image: string;
};

const onboardingTaskData: Array<OnboardingTaskCardData> = [
  { description: '(1:35)', image: onboardingTaskImage1 },
  { description: '(about 5 minutes)', image: onboardingTaskImage2 },
  { description: '(0:39)', image: onboardingTaskImage3 },
];

/** OnboardingPackageTasks displays the given onboarding package's tasks in a more interesting format */
export const OnboardingPackageTasks = ({ pkg, tasks }: OnboardingPackageTasksProps) => {
  const { onboardingTasksCompleteCount } = useOnboardingLockState();
  const location = useLocation();
  const sendEvent = useAnalytics();

  if (!tasks || tasks.length === 0 || pkg.packageType !== 'onboarding') {
    return null;
  }

  return (
    <div className={styles.TasksContainer}>
      {tasks.map(task => {
        const taskCompletionPercentage = getPkgOrTaskCompletionPercentage(task);
        const taskCompleted = isPkgOrTaskComplete(task);
        const taskStarted = 0 < taskCompletionPercentage && taskCompletionPercentage < 100;

        // Onboarding tasks are locked if previous tasks in the package are not complete
        const isLocked = task.taskIndex - 1 > onboardingTasksCompleteCount;

        const getTaskPath = () => {
          if (isLocked) {
            return location;
          }
          if (isVideoTask(task)) {
            return makeVideoTaskPath('', pkg.packageID, task.taskIndex);
          }
          return makeTaskPath('', pkg.packageID, task.taskIndex);
        };
        const taskPath = getTaskPath();

        // Default to available but not started
        let completionStyle = styles.Available;
        let completionNode: ReactNode = null;
        let chipContent: ReactNode = (
          <>
            <span>Start</span> <ChevronRight />
          </>
        );
        let chipColourVariant: ChipColourVariant = 'Interactable';
        let chipStyleVariant: ChipStyleVariant = 'Outlined';

        switch (true) {
          // Locked
          case isLocked:
            completionStyle = styles.Locked;
            chipContent = (
              <>
                <span>Locked</span> <LockIcon />
              </>
            );
            chipColourVariant = 'Locked';
            chipStyleVariant = 'Filled';
            break;
          // In Progress
          case taskStarted && !taskCompleted:
            completionStyle = styles.Started;
            completionNode = `${taskCompletionPercentage}%`;
            chipContent = (
              <>
                <span>Continue</span> <ChevronRight />
              </>
            );
            chipColourVariant = 'InProgress';
            chipStyleVariant = 'Outlined';
            break;
          // Complete
          case taskCompleted:
            completionStyle = classnames(styles.Complete);
            // complete tick only appears on the first onboarding task
            completionNode = <Tick variant="White" />;
            chipContent = 'Completed';
            chipColourVariant = 'Complete';
            chipStyleVariant = 'Outlined';
            break;
        }

        return (
          <Link
            to={taskPath}
            key={task.title}
            onClick={() => {
              if (!isLocked) {
                sendEvent({
                  action: 'clicked onboarding task',
                  category: 'package list',
                });
              }
            }}
            tabIndex={0}
            aria-disabled={isLocked}
            className={classnames(
              styles.TaskContainer,
              completionStyle,
              accessibilityStyles.FocusTarget,
            )}
          >
            <div className={styles.BackgroundContainer} />
            <img
              src={onboardingTaskData[task.taskIndex - 1].image}
              className={styles.BackgroundImage}
            />

            <div className={styles.LeftContainer}>
              <div className={styles.InfoContainer}>
                <span>{task.title}</span>
                <span className={styles.Description}>
                  {task.taskType === 'VIDEO' && <VideoIcon className={styles.VideoIcon} />}
                  {onboardingTaskData[task.taskIndex - 1].description}
                </span>
              </div>
              <Chip
                size="md"
                colourVariant={chipColourVariant}
                styleVariant={chipStyleVariant}
                className={styles.Chip}
              >
                {chipContent}
              </Chip>
            </div>
            <div className={styles.RightContainer}>
              <span>{completionNode}</span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@sparx/sparx-design/components';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { LargeLoading } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { useAnalytics } from 'utils/analytics';
import { FTQStep } from 'views/fast-track-quiz/useFastTrackQuiz';

import styles from './Dialogs.module.css';

type ConfirmationDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (dialogOpen: boolean) => void;
  ftqStep: FTQStep;
  goToAnswerStep: () => void;
  onSubmitQuizAnswers: (onSuccess: () => void) => void;
  isSubmitQuizAnswersLoading: boolean;
};

// ConfirmationDialog shows a warning dialog when the user moves from the question to answer steps, or
// submits quiz answers.
export const ConfirmationDialog = ({
  dialogOpen,
  setDialogOpen,
  ftqStep,
  goToAnswerStep,
  onSubmitQuizAnswers,
}: ConfirmationDialogProps) => {
  let dialogContent: JSX.Element | undefined = undefined;
  switch (ftqStep) {
    case FTQStep.QUESTION:
      dialogContent = (
        <Content
          title="Have you finished your working?"
          body="Make sure you have written down all of your questions, workings and answers. Once you start entering your answers, you won't be able to see the questions again."
          backText="Back to questions"
          onBack={() => setDialogOpen(false)}
          confirmText="I've finished my working"
          onConfirm={() => {
            goToAnswerStep();
            setDialogOpen(false);
          }}
          ftqStep={ftqStep}
        />
      );
      break;
    case FTQStep.ANSWER:
      dialogContent = (
        <Content
          title="Finish entering answers"
          body="This will submit your answers and mark them, are you sure you have entered all of your answers?"
          backText="Change answers"
          onBack={() => setDialogOpen(false)}
          confirmText="Submit answers"
          onConfirm={() => {
            onSubmitQuizAnswers(() => setDialogOpen(false));
          }}
          ftqStep={ftqStep}
        />
      );
      break;
    case FTQStep.SUBMITTING:
      dialogContent = <SubmittingContent />;
      break;
    case FTQStep.SUBMITTED:
    case FTQStep.RESULT:
      dialogContent = undefined;
      break;
  }
  return (
    <Dialog.Root open={dialogOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} style={{ zIndex: 9 }} />
        <Dialog.Content
          className={classNames(dialogStyles.DialogContent, dialogStyles.ContentHeight)}
          style={{ zIndex: 9 }}
        >
          {dialogContent}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

type ContentProps = {
  title: string;
  body: string;
  backText: string;
  onBack: () => void;
  confirmText: string;
  onConfirm: () => void;
  ftqStep: FTQStep;
};

const Content = ({
  title,
  body,
  backText,
  onBack,
  confirmText,
  onConfirm,
  ftqStep,
}: ContentProps) => {
  const sendEvent = useAnalytics();
  const isSmall = useBreakpoint('sm');
  return (
    <div className={styles.ContentContainer}>
      <span className={styles.Title}>{title}</span>
      <span className={styles.Body}>{body}</span>
      <div className={styles.ButtonsContainer}>
        <Button
          size={isSmall ? 'sm' : 'md'}
          onClick={() => {
            sendEvent({
              category: 'ftq',
              action: 'clicked back from confirm modal',
              labels: {
                text: backText,
                ftqStep: ftqStep,
              },
            });
            onBack();
          }}
        >
          {backText}
        </Button>
        <Button
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          variant="contained"
          onClick={() => {
            sendEvent({
              category: 'ftq',
              action: 'clicked confirm from confirm modal',
              labels: {
                text: backText,
                ftqStep: ftqStep,
              },
            });
            onConfirm();
          }}
          size={isSmall ? 'sm' : 'md'}
        >
          {confirmText}
        </Button>
      </div>
    </div>
  );
};

const SubmittingContent = () => {
  return (
    <div className={styles.ContentContainer}>
      <span className={styles.Title}>Submitting</span>
      <span className={styles.Body}>
        <LargeLoading />
      </span>
    </div>
  );
};

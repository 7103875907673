import { useSearchParams } from 'react-router-dom';

export const useTetheringSearchParams = () => {
  const [params] = useSearchParams();
  const mode = params.get('mode');
  const sessionName = params.get('session');

  return {
    isTethering: mode === 'tether',
    sessionName: sessionName,
    params: params,
  };
};

import classnames from 'classnames';
import passIcon from 'images/wac-correct.svg';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { useFeatureFlags } from 'utils/feature-flags';

import correctStyles from './WACCorrect.module.css';
import styles from './WACResult.module.css';

export const WACCorrect = () => {
  const { data: session } = useSession();

  const featureFlags = useFeatureFlags();
  const wacNotIncorrect =
    featureFlags.getStringFlag('sparxweb-wac-method', '') === 'ask_answer_any_type_not_incorrect';

  return (
    <>
      <img width="133" height="133" src={passIcon} alt="correct" />
      <div className={wacNotIncorrect ? styles.TextContainer : correctStyles.CorrectTextContainer}>
        <h2 className={wacNotIncorrect ? styles.Heading : correctStyles.CorrectHeading}>Passed</h2>
        <div className={wacNotIncorrect ? styles.SubHeading : correctStyles.CorrectMessage}>
          Well done, {getUsersNamesFromSession(session, 'firstOnly')}!
        </div>
        {wacNotIncorrect && (
          <div className={classnames(styles.AccuracyText, styles.Correct)}>
            Well done! This means you’ll get fewer bookwork checks.
          </div>
        )}
      </div>
    </>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/messaging/server/v1/server.proto" (package "sparx.messaging.server.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.messaging.server.v1.ServerMessageStreamingRequest
 */
export interface ServerMessageStreamingRequest {
  /**
   * @generated from protobuf field: string session_id = 1;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message sparx.messaging.server.v1.ServerMessage
 */
export interface ServerMessage {
  /**
   * The type of message being pushed to the client, e.g. "sparxweb.TetheringResponse". The client uses this to know which handler to call.
   *
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * The payload of the message. Sent as a JSON string as it's easy for the client to unmarshal.
   *
   * @generated from protobuf field: string payload = 2;
   */
  payload: string;
}
/**
 * @generated from protobuf message sparx.messaging.server.v1.ServerMessages
 */
export interface ServerMessages {
  /**
   * @generated from protobuf field: repeated sparx.messaging.server.v1.ServerMessage messages = 1;
   */
  messages: ServerMessage[];
}
/**
 * AckServerMessageRequest is the request the client sends to acknowledge that it has received a message from the server.
 *
 * @generated from protobuf message sparx.messaging.server.v1.AckServerMessageRequest
 */
export interface AckServerMessageRequest {
  /**
   * @generated from protobuf field: string message_id = 1;
   */
  messageId: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ServerMessageStreamingRequest$Type extends MessageType<ServerMessageStreamingRequest> {
  constructor() {
    super('sparx.messaging.server.v1.ServerMessageStreamingRequest', [
      { no: 1, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.messaging.server.v1.ServerMessageStreamingRequest
 */
export const ServerMessageStreamingRequest =
  new ServerMessageStreamingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerMessage$Type extends MessageType<ServerMessage> {
  constructor() {
    super('sparx.messaging.server.v1.ServerMessage', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'payload', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.messaging.server.v1.ServerMessage
 */
export const ServerMessage = new ServerMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerMessages$Type extends MessageType<ServerMessages> {
  constructor() {
    super('sparx.messaging.server.v1.ServerMessages', [
      {
        no: 1,
        name: 'messages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ServerMessage,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.messaging.server.v1.ServerMessages
 */
export const ServerMessages = new ServerMessages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AckServerMessageRequest$Type extends MessageType<AckServerMessageRequest> {
  constructor() {
    super('sparx.messaging.server.v1.AckServerMessageRequest', [
      { no: 1, name: 'message_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.messaging.server.v1.AckServerMessageRequest
 */
export const AckServerMessageRequest = new AckServerMessageRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.messaging.server.v1.SWServer
 */
export const SWServer = new ServiceType('sparx.messaging.server.v1.SWServer', [
  {
    name: 'ServerMessageStreaming',
    serverStreaming: true,
    options: {
      'google.api.http': { get: '/v1/servermessagestreaming' },
      'sparx.api.auth': {
        action: 'write',
        resource: 'sw:student',
        domain: 'sw',
      },
    },
    I: ServerMessageStreamingRequest,
    O: ServerMessages,
  },
  {
    name: 'AckServerMessage',
    options: { 'google.api.http': { post: '/v1/ackservermessage' } },
    I: AckServerMessageRequest,
    O: Empty,
  },
]);

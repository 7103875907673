// see: https://gist.github.com/samthor/2e11de5976fe673557b0ee14a3cb621a

/**
 * Checks whether the current environment supports the `signal` argument to addEventListener.
 */
export function hasEventListenerSignalSupport() {
  if (typeof AbortController === 'undefined') {
    return false; // doesn't even support AbortController :(
  }

  const c = new AbortController();
  c.abort();

  let signalOnListenerSupport = true;

  globalThis.addEventListener(
    '_test',
    () => {
      // If this fires even though the controller is aborted, there's no support
      signalOnListenerSupport = false;
    },
    { signal: c.signal },
  );

  globalThis.dispatchEvent(new CustomEvent('_test'));

  return signalOnListenerSupport;
}

/**
 * Adds support for the `signal` argument to addEventListener. Throws error if
 * {@link AbortController} is not supported at all.
 */
(function maybeAddEventListenerSignalPolyfill() {
  if (typeof AbortController === 'undefined') {
    throw new Error(`can't add, AbortController not supported`);
  }

  if (hasEventListenerSignalSupport()) {
    return;
  }

  const orig = EventTarget.prototype.addEventListener;

  EventTarget.prototype.addEventListener = function (eventName, fn, options) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    if (typeof options === 'object' && options?.signal) {
      if (!(options.signal instanceof AbortSignal)) {
        throw new Error(`unexpected type (not AbortSignal) for signal arg`);
      }

      if (options.signal.aborted) {
        return; // do nothing, already aborted
      }

      // copy so user can't change us: unlike the fn, the options arg can change, as
      // long as it has the same values
      const localOptions = { ...options };

      options.signal.addEventListener('abort', () => {
        self.removeEventListener(eventName, fn, localOptions);
      });
    }

    return orig.call(this, eventName, fn, options);
  };
})();

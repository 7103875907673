export const resourcePrefixes = {
  packagePrefix: 'packages',
  taskPrefix: 'tasks',
  taskItemPrefix: 'taskitems',
  notificationPrefix: 'notifications',
  assessmentPrefix: 'assessments',
  curriculumsPrefix: 'curriculums',
  strandPrefix: 'strands',
  topicPrefix: 'topics',
  learningUnitPrefix: 'learningunits',
  schoolPrefix: 'schools',
  studentPrefix: 'students',
  userPrefix: 'users',
  staffPrefix: 'staff',
};

type ResourcePrefix = (typeof resourcePrefixes)[keyof typeof resourcePrefixes];

export const resourceNameToIDs = (resourceName: string) => {
  const splitName = resourceName.split('/');

  const out: Record<string, string> = {};
  for (let i = 1; i < splitName.length; i += 2) {
    out[splitName[i - 1]] = splitName[i];
  }

  return out;
};

export const getIDFromResourceName = (
  resource: ResourcePrefix,
  resourceName: string,
): string | undefined => {
  const resourceNameMap = resourceNameToIDs(resourceName);
  return resourceNameMap[resource];
};

// Packages

export const makePackageName = (packageID: string): string =>
  `${resourcePrefixes.packagePrefix}/${packageID}`;
export const makeTaskName = (packageID: string, taskIndex: number) =>
  `${resourcePrefixes.packagePrefix}/${packageID}/${resourcePrefixes.taskPrefix}/${taskIndex}`;
export const makeTaskItemName = (packageID: string, taskIndex: number, taskItemIndex: number) =>
  `${resourcePrefixes.packagePrefix}/${packageID}/${resourcePrefixes.taskPrefix}/${taskIndex}/${resourcePrefixes.taskItemPrefix}/${taskItemIndex}`;

// Notifications

export const getNotificationIDFromNotificationName = (notificationName: string) =>
  getIDFromResourceName(resourcePrefixes.notificationPrefix, notificationName);

// Assessments

export const makeAssessmentName = (assessmentID: string): string =>
  `${resourcePrefixes.assessmentPrefix}/${assessmentID}`;

// Content

// makeCurriculumName goes from cu_id to curriculums/{cu_id}.
export const makeCurriculumName = (curriculumID: string): string =>
  `${resourcePrefixes.curriculumsPrefix}/${curriculumID}`;

// makeStrandName goes from cu_id and s_id to curriculums/{cu_id}/strands/{s_id}.
export const makeStrandName = (curriculumID: string, strandID: string) =>
  `${makeCurriculumName(curriculumID)}/${resourcePrefixes.strandPrefix}/${strandID}`;

// getCurriculumIDFromName goes from curriculums/{cu_id} to cu_id.
export const getCurriculumIDFromName = (curriculumName: string): string =>
  getIDFromResourceName(resourcePrefixes.curriculumsPrefix, curriculumName) ?? 'malformed';

// getLearningUnitIDFromName goes from learningunits/{lu_id} to lu_id.
export const getLearningUnitIDFromName = (learningUnitName: string): string =>
  getIDFromResourceName(resourcePrefixes.learningUnitPrefix, learningUnitName) ?? 'malformed';

export const getTopicIDFromName = (name: string) =>
  getIDFromResourceName(resourcePrefixes.topicPrefix, name);

export const curriculumNameFromName = (name: string) => {
  const match = name.match(/^curriculums\/[^/]+/);
  return match === null ? undefined : match[0];
};

export const getSkillIDFromName = (name: string) => {
  const match = name.match(/^skills\/[^/]+/);
  return match === null ? undefined : match[0];
};

// Schools

export const makeSchoolName = (schoolId: string) => `${resourcePrefixes.schoolPrefix}/${schoolId}`;

// Students

export const makeStudentName = (studentId: string) =>
  `${resourcePrefixes.studentPrefix}/${studentId}`;

// Userdisplaydata

export const getUserIDFromDisplayDataName = (displayDataName: string) =>
  resourceNameToIDs(displayDataName)[resourcePrefixes.userPrefix];

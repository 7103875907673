import { TRAINING_MODULE_STUDENT_EXPERIENCE } from 'content/training/trainingModules';
import { useIsStaffUser } from 'queries/session';
import { useTrainingPackages, useUpdateTrainingProgressForCurrentUser } from 'queries/training';
import {
  createContext,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  buildModule,
  internalTrainingModules,
  isSummary,
  Module,
  ModuleCache,
  TrainingState,
  TrainingStepID,
} from 'types/training';
import {
  calculateModuleCompletion,
  completeTrainingTask,
  createUpdateTrainingProgressForCurrentUserRequest,
  firstIncompleteStep,
  getNextIncompleteTrainingStepIndex,
  isTaskComplete,
  isTrainingModeEnabled,
  resyncTrainingProgress,
  setTrainingModeEnabled,
} from 'utils/training';

import { useGetTrainingModule } from './hook';

type TrainingContextData = {
  // Whether training is enabled (and the user must be a staff user)
  enabled: boolean;
  // Set training to be enabled or disabled
  setEnabled: (enabledUpdate: SetStateAction<boolean>) => void;
  // The module being worked on by the user.
  currentModule?: Module;
  // The current (next incomplete) step in the training. Is the last step if all are complete.
  currentStep?: number;
  // Set the current step
  setCurrentStep: (step: number) => void;
  // Whether a help video is currently open
  watchingHelpVideo: boolean;
  // Function to call when a training step is completed - will update the training progress and notify the server.
  // If goToNextStep is true, then the training will move onto the next incomplete step if there is one.
  completeTask: (stepID: TrainingStepID, goToNextStep?: boolean) => void;
};

export const TrainingContext = createContext<TrainingContextData>({
  enabled: false,
  setEnabled: () => undefined,
  currentModule: undefined,
  currentStep: undefined,
  setCurrentStep: () => undefined,
  watchingHelpVideo: false,
  completeTask: () => undefined,
});

const buildModules = (): ModuleCache =>
  Object.keys(internalTrainingModules).reduce((acc, moduleName) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    acc[moduleName] = buildModule(internalTrainingModules[moduleName]);
    return acc;
  }, {} as ModuleCache);

/**
 * TrainingProvider provides the training context to all child elements (they can use the useTraining hook)
 * @param children
 * @constructor
 */
export const TrainingProvider = ({ children }: PropsWithChildren) => {
  // We track separately whether training mode is enabled and if the user is a staff user as we won't know
  // until a bit later when the session has loaded whether the user is a staff user. Down below, in the provider,
  // whether training is enabled will be a combination of being enabled and the user being a staff user.
  const [enabled, setEnabled] = useState<boolean>(isTrainingModeEnabled());
  const [trainingState, setTrainingState] = useState<TrainingState>({
    initialProgressLoaded: false,
    modules: buildModules(),
    watchingHelpVideo: false,
  });
  const isStaffUser = useIsStaffUser();
  const currentModuleName = TRAINING_MODULE_STUDENT_EXPERIENCE.name;

  const [searchParams, _] = useSearchParams();

  // Fetch training progress for the current user
  const { data: trainingProgress, isLoading } = useGetTrainingModule(enabled && isStaffUser);

  // Mutation for saving training progress
  const updateTrainingProgressForCurrentUser = useUpdateTrainingProgressForCurrentUser();

  // If training is enabled, then make sure the training packages have been created - do this by calling the swworker
  // GetTrainingPackages method. Note: this will create the training packages, then the normal packages query will
  // return them, so we don't actually need to use the response from this query.
  useTrainingPackages(enabled && isStaffUser);

  // Sync the loaded training progress with the current modules and update the current step.
  useEffect(() => {
    if (!isLoading && trainingProgress !== undefined && !trainingState.initialProgressLoaded) {
      setTrainingState(prevState => {
        const modules = resyncTrainingProgress(prevState.modules, trainingProgress);
        // Once we've resynced the modules, set the current step based on the updated modules
        const currentStep = firstIncompleteStep(modules[currentModuleName].steps);

        return {
          ...prevState,
          initialProgressLoaded: true,
          modules,
          currentStep,
        };
      });
    }
  }, [currentModuleName, isLoading, trainingProgress, trainingState.initialProgressLoaded]);

  // If the user loads the app with the ?training=true query param, then enable training mode, and remember that
  useEffect(() => {
    if (searchParams.get('training') === 'true') {
      setEnabled(true);
      // Remember that training mode was enabled in local storage in case the user reloads the page
      setTrainingModeEnabled(true);
    }
  }, [searchParams]);

  // The current module is the module from the state which has the current module name. If this
  // can't be found then something has gone wrong and we disable training
  const currentModule = trainingState.modules[currentModuleName];

  // To be called when the user completes a step of the training, to update the state and notify the server
  const completeTask = useCallback(
    (stepID: TrainingStepID, goToNextStep?: boolean) => {
      // No need to do anything if training isn't enabled or the step is already complete (note, we do this so we don't
      // have to worry about whether training is enabled or already done at the places where a step would be completed
      // elsewhere in the app)
      if (
        !enabled ||
        !isStaffUser ||
        isTaskComplete(trainingState.modules, currentModuleName, stepID)
      ) {
        return;
      }

      const moduleCompletedBefore = currentModule.isComplete;
      const updatedModules = calculateModuleCompletion(
        completeTrainingTask(trainingState.modules, currentModuleName, stepID),
        currentModuleName,
      );
      const updatedModule = updatedModules[currentModuleName];
      const moduleCompletedAfter = updatedModule.isComplete;

      // If goToNext step was set, then update the current step to be the next incomplete one, or the summary page if
      // all steps are complete
      let nextStepIndex = trainingState.currentStep;
      if (goToNextStep) {
        nextStepIndex = getNextIncompleteTrainingStepIndex(
          updatedModule,
          trainingState.currentStep,
        );
        if (nextStepIndex === undefined) {
          nextStepIndex = currentModule.steps.findIndex(m => isSummary(m));
        }
      }

      setTrainingState(prevState => ({
        ...prevState,
        modules: updatedModules,
        currentStep: nextStepIndex,
      }));

      // Send the results to the server (errors and loading handled by the Suspense Route)
      const updateReq = createUpdateTrainingProgressForCurrentUserRequest(
        updatedModules,
        currentModuleName,
        moduleCompletedAfter && !moduleCompletedBefore,
      );
      updateTrainingProgressForCurrentUser.mutate(updateReq);
    },
    [
      enabled,
      isStaffUser,
      trainingState.modules,
      trainingState.currentStep,
      currentModuleName,
      currentModule.isComplete,
      currentModule.steps,
      updateTrainingProgressForCurrentUser,
    ],
  );

  const setCurrentStep = (step: number) =>
    setTrainingState(prevState => ({ ...prevState, currentStep: step }));

  return (
    <TrainingContext.Provider
      value={{
        enabled: enabled && isStaffUser && !!currentModule && trainingState.initialProgressLoaded,
        setEnabled,
        currentModule,
        currentStep: trainingState.currentStep,
        setCurrentStep,
        watchingHelpVideo: trainingState.watchingHelpVideo,
        completeTask,
      }}
    >
      {children}
    </TrainingContext.Provider>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/revision/v1/revision.proto" (package "sparx.revision.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Revision } from './revision';
import type { GetAssignedTopicsResponse } from './revision';
import type { GetAssignedTopicsRequest } from './revision';
import type { GetPackagesForObjectivesResponse } from './revision';
import type { GetPackagesForObjectivesRequest } from './revision';
import type { GetActivePackagesResponse } from './revision';
import type { GetActivePackagesRequest } from './revision';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetStudentProfileResponse } from './revision';
import type { GetStudentProfileRequest } from './revision';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.revision.v1.Revision
 */
export interface IRevisionClient {
  /**
   * @generated from protobuf rpc: GetStudentProfile(sparx.revision.v1.GetStudentProfileRequest) returns (sparx.revision.v1.GetStudentProfileResponse);
   */
  getStudentProfile(
    input: GetStudentProfileRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentProfileRequest, GetStudentProfileResponse>;
  /**
   * @generated from protobuf rpc: GetActivePackages(sparx.revision.v1.GetActivePackagesRequest) returns (sparx.revision.v1.GetActivePackagesResponse);
   */
  getActivePackages(
    input: GetActivePackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivePackagesRequest, GetActivePackagesResponse>;
  /**
   * @generated from protobuf rpc: GetPackagesForObjectives(sparx.revision.v1.GetPackagesForObjectivesRequest) returns (sparx.revision.v1.GetPackagesForObjectivesResponse);
   */
  getPackagesForObjectives(
    input: GetPackagesForObjectivesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetPackagesForObjectivesRequest,
    GetPackagesForObjectivesResponse
  >;
  /**
   * @generated from protobuf rpc: GetAssignedTopics(sparx.revision.v1.GetAssignedTopicsRequest) returns (sparx.revision.v1.GetAssignedTopicsResponse);
   */
  getAssignedTopics(
    input: GetAssignedTopicsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssignedTopicsRequest, GetAssignedTopicsResponse>;
}
/**
 * @generated from protobuf service sparx.revision.v1.Revision
 */
export class RevisionClient implements IRevisionClient, ServiceInfo {
  typeName = Revision.typeName;
  methods = Revision.methods;
  options = Revision.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetStudentProfile(sparx.revision.v1.GetStudentProfileRequest) returns (sparx.revision.v1.GetStudentProfileResponse);
   */
  getStudentProfile(
    input: GetStudentProfileRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentProfileRequest, GetStudentProfileResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentProfileRequest, GetStudentProfileResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetActivePackages(sparx.revision.v1.GetActivePackagesRequest) returns (sparx.revision.v1.GetActivePackagesResponse);
   */
  getActivePackages(
    input: GetActivePackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivePackagesRequest, GetActivePackagesResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetActivePackagesRequest, GetActivePackagesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetPackagesForObjectives(sparx.revision.v1.GetPackagesForObjectivesRequest) returns (sparx.revision.v1.GetPackagesForObjectivesResponse);
   */
  getPackagesForObjectives(
    input: GetPackagesForObjectivesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetPackagesForObjectivesRequest,
    GetPackagesForObjectivesResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetPackagesForObjectivesRequest,
      GetPackagesForObjectivesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetAssignedTopics(sparx.revision.v1.GetAssignedTopicsRequest) returns (sparx.revision.v1.GetAssignedTopicsResponse);
   */
  getAssignedTopics(
    input: GetAssignedTopicsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssignedTopicsRequest, GetAssignedTopicsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetAssignedTopicsRequest, GetAssignedTopicsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}

import { LeaderboardType } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import classNames from 'classnames';

import { useLeaderboardContext } from '../context/context';
import styles from './LeaderboardGroupTypeSelector.module.css';

export const LeaderboardGroupTypeSelector = ({
  leaderboardType,
  setLeaderboardType,
}: {
  leaderboardType: LeaderboardType;
  setLeaderboardType: React.Dispatch<React.SetStateAction<LeaderboardType>>;
}) => {
  const isSmallish = useBreakpoint('md');
  const isSmall = useBreakpoint('sm');
  const { sendAnalyticsEvent } = useLeaderboardContext();

  const onSelect = (type: LeaderboardType) => {
    setLeaderboardType(type);
    sendAnalyticsEvent({
      action: 'Change Leaderboard Type',
      labels: {
        type: LeaderboardType[type],
      },
    });
  };
  return (
    <div className={classNames({ [styles.Tabs]: !isSmall, [styles.Pills]: isSmall })}>
      <button
        className={classNames({
          [styles.Active]: leaderboardType === LeaderboardType.CLASS,
        })}
        onClick={() => onSelect(LeaderboardType.CLASS)}
      >
        {isSmallish ? 'Class' : 'Your Class'}
      </button>
      <button
        className={classNames({
          [styles.Active]: leaderboardType === LeaderboardType.YEARGROUP,
        })}
        onClick={() => onSelect(LeaderboardType.YEARGROUP)}
      >
        {isSmallish ? 'Year Group' : 'Your Year Group'}
      </button>
      <button
        className={classNames({
          [styles.Active]: leaderboardType === LeaderboardType.SCHOOL,
        })}
        onClick={() => onSelect(LeaderboardType.SCHOOL)}
      >
        {isSmallish ? 'School' : 'Your School'}
      </button>
    </div>
  );
};

import {
  LeaderboardDateRange,
  LeaderboardType,
} from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { makeSchoolName, makeStudentName } from '@sparx/resource-names';
import { useQuery } from '@tanstack/react-query';

import { useLeaderboardContext } from '../context/context';

export const useGetLeaderboards = (
  leaderboardType: LeaderboardType,
  leaderboardDateRange: LeaderboardDateRange,
) => {
  const { schoolID, studentID, leaderboardsClient, product } = useLeaderboardContext();
  const staleTime =
    leaderboardDateRange === LeaderboardDateRange.LAST_MONTH ||
    leaderboardDateRange === LeaderboardDateRange.LAST_WEEK
      ? 1000 * 60 * 60 * 12
      : // 5 seconds, so we don't make this call multiple times on initial render.
        1000 * 5;

  return useQuery(
    ['leaderboards', leaderboardType, leaderboardDateRange],
    () =>
      leaderboardsClient.getLeaderboard({
        schoolName: makeSchoolName(schoolID),
        studentName: makeStudentName(studentID || ''),
        leaderboardType: leaderboardType,
        dateRange: leaderboardDateRange,
        product: product,
      }).response,
    {
      staleTime: staleTime,
      cacheTime: 1000 * 60 * 60,
      suspense: true,
      refetchOnWindowFocus: false,
      select: data => data.users,
    },
  );
};

export const useGetLeaderboardForTeacher = (
  studentIds: string[],
  dateRange: LeaderboardDateRange,
) => {
  const { schoolID, leaderboardsClient, product } = useLeaderboardContext();
  return useQuery(['leaderboards', dateRange, ...studentIds], {
    queryFn: () =>
      leaderboardsClient.getLeaderboardForTeacher({
        dateRange: dateRange,
        product: product,
        schoolName: makeSchoolName(schoolID),
        studentIds: studentIds,
      }).response,
    select: data => data.users,
    enabled: studentIds.length > 0,
    suspense: true,
  });
};

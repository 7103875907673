import * as Dialog from '@radix-ui/react-dialog';
import { Button, ProgressBar } from '@sparx/sparx-design/components';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { useRewards } from 'context/rewards';
import { motion } from 'framer-motion';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { useGetStudentRewards } from 'queries/studentrewards';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { useFeatureFlags } from 'utils/feature-flags';

import { RadialGradient } from '../rewards-plaque/Badges';
import { RewardsPlaque } from '../rewards-plaque/RewardsPlaque';
import styles from './LevelUpDialog.module.css';

export const LevelUpDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentLevel, setCurrentLevel, disableLevelUpDialog } = useRewards();
  const { data: studentRewards } = useGetStudentRewards();
  const { data: session } = useSession();
  const [confettiThrown, setConfettiThrown] = useState(false);

  const featureFlags = useFeatureFlags();
  const useNewXp = featureFlags.getBooleanFlag('new-xp-system', false);

  const isMilestoneLevel = !!(currentLevel && currentLevel % 5 === 0);

  useEffect(() => {
    if (!useNewXp) {
      return;
    }
    const studentRewardsLevel = studentRewards?.currentXPLevel?.level;
    if (studentRewardsLevel === undefined) {
      return;
    }
    if (currentLevel === undefined) {
      setCurrentLevel(studentRewardsLevel);
      return;
    }
    if (disableLevelUpDialog) {
      return;
    }
    if (studentRewardsLevel > currentLevel) {
      setCurrentLevel(studentRewardsLevel);
      setIsOpen(true);
    }
  }, [currentLevel, setCurrentLevel, studentRewards, disableLevelUpDialog, useNewXp]);

  // The Confetti package checks to see when the active prop changes to true
  // to show confetti. We explicitly set it to true when the progress bar fills up,
  // and let it implicitly revert to false when the LevelUpDialog is remounted.
  const throwConfetti = useCallback(() => {
    setConfettiThrown(true);
  }, []);

  const confettiConfig = useMemo(() => generateConfettiConfig(currentLevel || 0), [currentLevel]);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Content
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullScreen,
            styles.Container,
          )}
        >
          <div className={styles.PlaqueContainer}>
            <div className={styles.RadialBackground}>
              <motion.div
                animate={{
                  rotate: [0, 360],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 30,
                  ease: 'linear',
                }}
                className={styles.RadialBackgroundAnimateWrapper}
              >
                <RadialGradient />
              </motion.div>
            </div>

            {isMilestoneLevel && (
              <div className={styles.ConfettiContainer}>
                <Confetti active={confettiThrown} config={confettiConfig} />
              </div>
            )}
            <div className={styles.PlaqueBadge}>
              <motion.div
                initial={{ scale: 0, rotate: -5 }}
                animate={{
                  scale: 1,
                  rotate: 0,
                  transition: {
                    duration: 0.6,
                    delay: 1,
                    bounce: 0.7,
                    damping: 7,
                    type: 'spring',
                  },
                }}
              >
                <RewardsPlaque level={currentLevel || 0} customClassName={styles.LevelUpBadge} />
              </motion.div>
            </div>
          </div>
          <div className={styles.InfoContainer}>
            <ProgressBar
              percentComplete={100}
              containerClassName={styles.ProgressBarContainer}
              animateFill
              animateWobble
              showHighlight
              onBarFillAnimateComplete={throwConfetti}
            />
            <h1>You levelled-up!</h1>
            <span>Well done, {getUsersNamesFromSession(session, 'firstOnly')}.</span>
            <Button
              variant="plain"
              onClick={() => {
                setIsOpen(false);
                setConfettiThrown(false);
              }}
            >
              Continue
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const generateConfettiConfig = (level: number) => {
  const milestoneLevel = level % 100 === 0 ? 3 : level % 25 === 0 ? 2 : level % 5 === 0 ? 1 : 0;

  return {
    angle: 90,
    spread: 360,
    startVelocity: 20,
    elementCount: 200 * milestoneLevel ** 2,
    dragFriction: 0.06,
    duration: 1000 * milestoneLevel ** 2,
    stagger: 10,
    width: '15px',
    height: '15px',
    perspective: '1000px',
    colors: ['#A4C451', '#2179de', '#f2af2f', '#27ce88', '#b29500', '#eecf34', '#eecf34'],
  };
};

import {
  GetAbilityValidationGatekeeperLazy,
  GetDailyTokensLazy,
  GetQuizAlgorithmLazy,
  GetSpecialAssessmentLazy,
  GetTablesTaskMonitorLazy,
  GetTalkAndLearnGatekeeperLazy,
} from './game-parameters';

export async function getTimeLastPlayed100Club(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  // Get times tables targets for the current user
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  return parseInt(QuizAlgorithm.targetsProgress.getLastAssessmentTime());
}

/*
    Returns true if the student has had a session of 100 Club today where they
    have got over 45% of questions right, and false otherwise.
 */
export async function getTodays100ClubSuccess(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  // Get times tables targets for the current user
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  return QuizAlgorithm.playerHasHadSuccessToday();
}

/*
    Returns an array of targets in a format that can be displayed on-screen.
 */
export async function getTargets(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  // Get times tables targets for the current user
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  return await QuizAlgorithm.requestTargetStrings();
}

/*
    Returns true if the student has had a session of 100 Club today where they
    have got over 45% of questions right, and false otherwise.
 */
export async function getHasCleared100ClubFirstPass(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  // Get times tables targets for the current user
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  return QuizAlgorithm.playerHasCleared100ClubFirstPass();
}

/*
    Returns true if the student has had a session of 100 Club today where they
    have got over 45% of questions right, and false otherwise.
 */
export async function getHasPendingTalkAndLearn(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { TalkAndLearnGatekeeper } = await GetTalkAndLearnGatekeeperLazy(featureFlags);
  await TalkAndLearnGatekeeper.initialiseProgress();
  return TalkAndLearnGatekeeper.hasRemainingRequirements();
}

export async function getHasClearedTaLRequirementsToday(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { TalkAndLearnGatekeeper } = await GetTalkAndLearnGatekeeperLazy(featureFlags);
  await TalkAndLearnGatekeeper.initialiseProgress();
  return TalkAndLearnGatekeeper.hasClearedTaLRequirementsToday();
}

export async function getHasCompletedAllClubs(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  // Get times tables targets for the current user
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  return QuizAlgorithm.hasCompletedAllClubs();
}

export async function getHasPendingSpeedChallenge(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  // Get times tables targets for the current user
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();
  return QuizAlgorithm.hasPendingSpeedChallenge();
}

export async function getDailyTokenCount() {
  const { DailyTokens } = await GetDailyTokensLazy();
  await DailyTokens.initialiseProgress();
  return DailyTokens.getTotalTokens();
}

export async function getAbilityValidationAllows100Club(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { AbilityValidationGatekeeper } = await GetAbilityValidationGatekeeperLazy(featureFlags);
  await AbilityValidationGatekeeper.initialiseProgress(featureFlags);
  return AbilityValidationGatekeeper.shouldAllowAccessTo100Club();
}

export async function getCompletedSpecialAssessments() {
  const { SpecialAssessmentGatekeeper } = await GetSpecialAssessmentLazy();
  await SpecialAssessmentGatekeeper.initialiseProgress();
  return SpecialAssessmentGatekeeper.getCompletedSpecialAssessmentIds();
}

export async function getPackageIdsWithPracticeDone() {
  const { SpecialAssessmentGatekeeper } = await GetSpecialAssessmentLazy();
  await SpecialAssessmentGatekeeper.initialiseProgress();
  return SpecialAssessmentGatekeeper.getPackageIdsWithPracticeDone();
}

export async function getAbilityValidationIsEnabled(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { AbilityValidationGatekeeper } = await GetAbilityValidationGatekeeperLazy(featureFlags);
  await AbilityValidationGatekeeper.initialiseProgress(featureFlags);
  return AbilityValidationGatekeeper.featureFlagIsEnabled();
}

export async function applyTablesTaskProgress(
  progress: { totalAnswered: number; totalRequired: number } | null,
) {
  const { TablesTaskMonitor } = await GetTablesTaskMonitorLazy();
  if (progress) {
    TablesTaskMonitor.applyStartingState(progress.totalAnswered, progress.totalRequired);
  } else {
    // Tables game has been request from something other than a task,
    // so re-apply the default settings (i.e. prevent a "task complete"
    // notification from showing in-game)
    TablesTaskMonitor.reset();
  }
}

export async function getCurrentClubName(
  featureFlags: Record<string, number | string | boolean | undefined> | undefined,
) {
  const { QuizAlgorithm } = await GetQuizAlgorithmLazy(featureFlags);
  await QuizAlgorithm.initialiseTargets();

  const { AbilityValidationGatekeeper } = await GetAbilityValidationGatekeeperLazy(featureFlags);
  await AbilityValidationGatekeeper.initialiseProgress(featureFlags);

  if (AbilityValidationGatekeeper.featureFlagIsEnabled()) {
    if (!AbilityValidationGatekeeper.hasClearedValidation()) {
      // If the player hasn't cleared Ability Validation yet, they
      // are in one of three states:
      if (
        AbilityValidationGatekeeper.isAwaiting100ClubAttempt() &&
        !QuizAlgorithm.playerHasCleared100ClubFirstPass()
      ) {
        // The player is allowed to play 100 Club, but has not
        // cleared First Pass yet
        return 'Find Your Level';
      }
      if (!QuizAlgorithm.playerHasCleared100ClubFirstPass()) {
        // The player has not yet reached a state where they are
        // allowed to try 100 Club
        return 'Practice';
      }
      // Otherwise, the club they are in is the one they are put in
      // if they fail First Pass: 20 Club Gold
      const clubName = QuizAlgorithm.getCurrentClubName();
      // "20 Club Gold" is really awkward to explain, so just write
      // "20 Club" for simplicity
      if (clubName.substring(0, '20 Club'.length) === '20 Club') {
        return '20 Club';
      }
    }
    // If the player has cleared Ability Validation, just show their club name
    // as normal
  }

  // Check if student hasn't done First Pass yet
  if (!QuizAlgorithm.playerHasCleared100ClubFirstPass()) {
    return 'Find Your Level';
  }

  // The student can answer all questions within 3 seconds
  if (QuizAlgorithm.playerHasCompletedSpeedChallenge()) {
    return 'Full Knowledge';
  }
  // The student can answer all questions
  // and is being trained to answer them faster
  if (QuizAlgorithm.playerIsInSpeedChallengeClub()) {
    return 'Speed Challenge';
  }
  // The student is on 100 Club (unbeaten) or below
  return QuizAlgorithm.getCurrentClubName();
}

export async function getTodaysTokenProgress() {
  const { DailyTokens } = await GetDailyTokensLazy();
  await DailyTokens.initialiseProgress();
  return DailyTokens.getTodaysTokenProgress();
}

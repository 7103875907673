import { SessionDetailsResponse } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/queryClient';
import { useAPI } from 'context/api';
import { sessionQueryKey, useSession } from 'queries/session';

// TutorialKey is an enum of all tutorial keys that sw2 cares about
export enum TutorialKey {
  FTQWelcome = 'SW2-FTQWelcome',
  FTQNavigation = 'SW2-FTQNavigation',
  FTQNextQuestion = 'SW2-FTQNextQuestion',
  FTQEnterAllAnswers = 'SW2-FTQEnterAllAnswers',
  FTQNextAnswer = 'SW2-FTQNextAnswer',
  FTQFinishEnteringAnswers = 'SW2-FTQFinishEnteringAnswers',
  FTQTaskItemFastTracked = 'SW2-FTQTaskItemFastTracked',
  FTQTaskItemCorrect = 'SW2-FTQTaskItemCorrect',
  FTQTaskItemIncorrect = 'SW2-FTQTaskItemIncorrect',
  FTQTaskItemStillToDo = 'SW2-FTQTaskItemStillToDo',
  WacOnboardingVideo = 'SW2-WacOnboardingVideo',
  WacAccuracy = 'SW2-WacAccuracy',
  AssessmentFixupOnboardingVideo = 'SW2-AssessmentFixupOnboardingVideo',
  AssessmentRevisionOnboardingVideo = 'SW2-AssessmentRevisionOnboardingVideo',
  XPHasChanged = 'SW2-XPHasChanged',
}

// useMarkTutorialComplete returns a mutation that marks the given tutorial keys as complete.
export const useMarkTutorialComplete = () => {
  const { swworkerClient } = useAPI();
  return useMutation({
    mutationKey: ['tutorials'],
    mutationFn: (tutorialKeys: TutorialKey[]) =>
      swworkerClient.updateTutorials({ tutorialsCompleted: tutorialKeys }).response,
    onSuccess(_, variables) {
      // update the session data
      queryClient.setQueryData(sessionQueryKey, (session: SessionDetailsResponse | undefined) => {
        if (!session?.session) {
          return session;
        }
        const tutorialsCompleted = [...session.session.tutorialsCompleted];
        variables.forEach(tutorialKey => {
          if (!tutorialsCompleted.includes(tutorialKey)) {
            tutorialsCompleted.push(tutorialKey);
          }
        });
        return { ...session, session: { ...session.session, tutorialsCompleted } };
      });
    },
  });
};

// useIsTutorialComplete returns true if the given tutorialKey has been marked as complete.
export const useIsTutorialComplete = (tutorialKey: TutorialKey) => {
  const { data: session } = useSession();
  return session?.session?.tutorialsCompleted?.includes(tutorialKey) ?? false;
};

import { TaskCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { getNowTimestamp } from '@sparx/react-utils';
import { useSubmitQuizAnswers } from 'queries/ftq';
import { useNavigate } from 'react-router-dom';
import { isFTQQuizComplete, isFTQQuizSkipped } from 'utils/ftqtask';
import { makeFTQPath, useLQDPath } from 'utils/paths';
import { isFTQTask } from 'utils/taskTypes';

export const isQuizDuplicateResultError = (error: unknown): boolean =>
  String(error).includes('QuizDuplicateResult');

export const useLQDFastTrackQuizTask = (task: TaskCompletion | undefined) => {
  const { parentPath } = useLQDPath();
  const navigate = useNavigate();

  const {
    mutate: submitQuizAnswers,
    isLoading: isSkippingFTQ,
    error: submitQuizAnswersError,
  } = useSubmitQuizAnswers();

  const startFTQ = () => navigate(makeFTQPath(parentPath, task?.packageID, task?.taskIndex));

  const skipFTQ = () =>
    submitQuizAnswers({
      task: {
        packageID: task?.packageID ?? '',
        taskIndex: task?.taskIndex ?? 0,
        taskItemIndex: 0,
        taskState: 0,
      },
      skipFTQ: true,
      timestamp: getNowTimestamp(),
    });

  const ftqAvailable =
    task && isFTQTask(task) && !isFTQQuizSkipped(task) && !isFTQQuizComplete(task);
  const showFTQSelection = ftqAvailable && !isQuizDuplicateResultError(submitQuizAnswersError);

  return {
    startFTQ,
    skipFTQ,
    showFTQSelection,
    isSkippingFTQ,
  };
};

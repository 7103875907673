import 'core-js/features/array/at';

import * as Sentry from '@sentry/react';
import { ColourOverlay } from '@sparx/accessibility';
import { ThemeProvider } from '@sparx/design/context';
import { MinClientVersionManager } from '@sparx/min-client-version';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Header } from 'app/Header';
import { AnalyticsProvider } from 'components/analytics-provider';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { LoggedOutDialog } from 'components/logged-out-dialog/LoggedOutDialog';
import { NotificationsManager } from 'components/notifications-manager';
import { MaxWidth, Page } from 'components/page/Page';
import { PageViewAnalytics } from 'components/page-view-analytics';
import { QueryAnalytics } from 'components/query-analytics/QueryAnalytics';
import { SuspenseRoute } from 'components/suspense-route/SuspenseRoute';
import { TrainingBanner } from 'components/training/TrainingBanner';
import { UniqueLoginHandler } from 'components/unique-login-handler/UniqueLoginHandler';
import { PromotedWhatsNewModal } from 'components/whats-new';
import { APIProvider, useAPI } from 'context/api';
import { AppStateProvider } from 'context/AppStateProvider/AppStateProvider';
import { ServerStreamingProvider } from 'context/serverstreaming/provider';
import { TrainingProvider } from 'context/training';
import { useSession } from 'queries/session';
import { lazy, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { useCheckFeatureFlagChecksum, useFeatureFlags } from 'utils/feature-flags';
import { SENTRY_SCHOOL_DISPLAY_NAME, SENTRY_SCHOOL_NAME } from 'utils/sentry';
import { staticAssetsURL } from 'utils/urls';
import {
  AssessmentLQD,
  assessmentLQDRoute,
  AssessmentResults,
  assessmentResultsRoute,
  AssessmentRevision,
  assessmentRevisionRoute,
  AssessmentsOverview,
} from 'views/assessments';
import { ChooseModeView } from 'views/choose-mode/ChooseModeView';
import { ErrorPage, SimpleErrorPage, SimpleErrorPageWithAnalytics } from 'views/error/ErrorPage';
import { FastTrackQuizView } from 'views/fast-track-quiz/FastTrackQuiz';
import { FeedbackView } from 'views/feedback/FeedbackView';
import {
  ilLQDRoute,
  IndependentLearningTopicView,
  IndependentLearningView,
  strandRoute,
  StrandView,
  topicRoute,
} from 'views/independent-learning';
import { LeaderboardPage } from 'views/leaderboards/Leaderboard';
import { LQDContainer } from 'views/lqd/LQD';
import { PackageListView } from 'views/package-list/PackageListView';
import { ResourceHub } from 'views/resource-hub/ResourceHub';
import { LevelUpDialog } from 'views/rewards/level-up/LevelUpDialog';
import { RewardsView } from 'views/rewards/RewardsView';
import { SettingsPage } from 'views/settings-page';
import { TetherView } from 'views/tether/TetherView';
import { VideoTaskView } from 'views/video-task/VideoTaskView';
import { WhatsNewDisplay } from 'views/whats-new-display';

import { MultiplayerAlertModal } from '../views/package-list/multiplayer-alert-modal/multiplayer-alert-modal';
import appStyles from './App.module.css';
import navigationStyles from './NavigationFrame/NavigationFrame.module.css';
import { queryClient } from './queryClient';
import { useInitializeApp } from './useInitializeApp';

// Lazy load the game-container and game-select views so the game bundle is only loaded when needed
const GameContainerView = lazy(() => import('views/games/GameContainerView'));
const TimesTablesTaskView = lazy(() => import('views/times-tables-task/TimesTablesTaskView'));

const router = createBrowserRouter(
  [
    {
      element: (
        <UniqueLoginHandler>
          {/* Note: TrainingProvider is not in the AppStateProvider so that it can benefit from SuspenseRoute's
              loading spinner and error handling as well as know about the training search parameter in the URL
              from the routing provider */}
          <TrainingProvider>
            <PageViewAnalytics />
            <TrainingBanner />
            <Header />
            <Outlet />
          </TrainingProvider>
        </UniqueLoginHandler>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          errorElement: <ErrorPage withBackground />,
          children: [
            {
              path: '/',
              element: <ChooseModeView />,
            },
            {
              path: '/homework',
              element: (
                <>
                  <NotificationsManager />
                  <PromotedWhatsNewModal />
                  <PackageListView />
                  <MultiplayerAlertModal />
                </>
              ),
            },
            {
              path: '/package/:packageID/task/:taskIndex/*',
              element: <LQDContainer />,
            },
            {
              path: '/package/:packageID/task/:taskIndex/ftq/*',
              element: <FastTrackQuizView />,
            },
            {
              path: '/package/:packageID/videoTask/:taskIndex/*',
              element: <VideoTaskView />,
            },
            {
              path: '/package/:packageID/timestablestask/:taskIndex/*',
              element: (
                <Suspense
                  fallback={
                    <LoadingSpinnerWithAnalytics
                      componentName={'TimesTablesTaskView bundle'}
                      displayName={'Times Tables'}
                      showLongTimeLoadingMessage={true}
                      showLoadingMessage={true}
                      sendLoadCompleteEvent={true}
                      sendLongTimeLoadingEvent={true}
                    />
                  }
                >
                  <TimesTablesTaskView />
                </Suspense>
              ),
            },
            {
              path: '/games/:gameId/package/:packageID/task/:taskIndex/*',
              element: (
                <Suspense
                  fallback={
                    <LoadingSpinnerWithAnalytics
                      componentName={'GameContainerView bundle'}
                      displayName={'Times Tables'}
                      showLongTimeLoadingMessage={true}
                      showLoadingMessage={true}
                      sendLoadCompleteEvent={true}
                      sendLongTimeLoadingEvent={true}
                    />
                  }
                >
                  <GameContainerView />
                </Suspense>
              ),
            },
            {
              path: '/tethering',
              element: <TetherView />,
            },
            {
              path: `/independentlearning/${ilLQDRoute}`,
              element: <LQDContainer />,
            },
            {
              path: '/independentlearning/',
              element: <IndependentLearningView />,
            },
            {
              path: '/independentlearning/*',
              element: (
                <Page>
                  <div className={navigationStyles.Content}>
                    <MaxWidth>
                      <Outlet />
                    </MaxWidth>
                  </div>
                </Page>
              ),
              children: [
                {
                  path: strandRoute,
                  element: (
                    <SuspenseRoute timeLongLoadTimeApp={'StrandView'}>
                      <StrandView />
                    </SuspenseRoute>
                  ),
                },
                {
                  path: topicRoute,
                  element: (
                    <SuspenseRoute timeLongLoadTimeApp={'IndependentLearningTopicView'}>
                      <IndependentLearningTopicView />
                    </SuspenseRoute>
                  ),
                },
              ],
            },
            {
              path: '/assessments/*',
              element: (
                <Page>
                  <div className={navigationStyles.Content}>
                    <SuspenseRoute timeLongLoadTimeApp={'AssessmentsView'}>
                      <Outlet />
                    </SuspenseRoute>
                  </div>
                </Page>
              ),
              children: [
                {
                  path: '',
                  element: <AssessmentsOverview />,
                },
                {
                  path: assessmentResultsRoute,
                  element: <AssessmentResults />,
                },
                {
                  path: assessmentRevisionRoute,
                  element: <AssessmentRevision />,
                },
                {
                  path: assessmentLQDRoute,
                  element: <AssessmentLQD />,
                },
              ],
            },
            {
              path: '/rewards',
              element: (
                <SuspenseRoute timeLongLoadTimeApp={'RewardsView'}>
                  <RewardsView />
                </SuspenseRoute>
              ),
            },
            {
              path: '/resourcehub',
              element: <ResourceHub />,
            },
            {
              path: '/whatsnew/:whatsNewID',
              element: <WhatsNewDisplay />,
            },
            {
              path: '/settings',
              element: <SettingsPage />,
            },
            {
              path: '/leaderboard',
              element: <LeaderboardPage />,
            },
          ],
        },
        {
          path: '/feedback',
          element: <FeedbackView />,
        },
      ],
    },
  ],
  {
    basename: window.__sparxweb.serveRoot,
  },
);

const environment = window.__sparxweb.environment;
const sentryReplayFlag = window.__sparxweb.featureFlags['sparxweb2-sentry-replay'];

Sentry.init({
  dsn: 'https://d2dc2bac1b464055b458508004b0841e@o1086305.ingest.sentry.io/4505392233447424',
  tracePropagationTargets: ['localhost', /^\//],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: ['localhost', /^\//, /https?:\/\/.*\.sparxmaths\.uk/],
      networkRequestHeaders: ['Content-Type', 'X-Grpc-Web'],
      networkResponseHeaders: [
        'Content-Type',
        'Cf-Cache-Status',
        'Cf-Ray',
        'Grpc-Message',
        'Grpc-Status',
      ],
    }),
  ],

  // limit rate of transactions to 0.1% in production
  tracesSampleRate: environment === 'spx001' ? 0.001 : 1,

  // only sample 1% of errors in production
  sampleRate: environment === 'spx001' ? 0.01 : 1,

  // Only turn on error or full session replay if the flag is set
  replaysSessionSampleRate: 0, // Triggered manually below
  replaysOnErrorSampleRate: sentryReplayFlag === 'error' ? 1 : 0,

  release: import.meta.env.VITE_REACT_APP_VERSION,
  environment,
  // Disable Sentry on dev envs
  enabled: environment !== 'development',

  // Exclude 'ResizeObserver' errors as these are safe to ignore
  // Exclude 'PendingWAC' errors as these are an expected part of the the qa flow
  // Exclude 'LoadFailed' errors as we think that they're out of our control, e.g. network issues.
  ignoreErrors: [
    'ResizeObserver',
    'PendingWAC',
    'Load failed',
    'Failed to fetch', // This is thrown all the time when the user is offline
    'AsyncGameCallAborted', // This is thrown all the time in the games and is not a real error
    'SessionInactive', // This thrown when the user goes back to the app after and hour and causes them to be logged out
    "(evaluating 'a.L')", // Google translate error when the user is logged out
  ],
});
Sentry.setTag(SENTRY_SCHOOL_NAME, `schools/${window.__sparxweb.schoolID}`);
Sentry.setTag(SENTRY_SCHOOL_DISPLAY_NAME, window.__sparxweb.schoolName);

// Start sentry session replay if flag is enabled
if (sentryReplayFlag === 'session') {
  console.log('Starting session replay...');
  const replay = Sentry.getCurrentHub().getIntegration(Sentry.Replay);
  replay?.start();
}

const releaseDateTimeToISO = (dt: string): string => {
  const date = `${dt.substring(0, 4)}-${dt.substring(4, 6)}-${dt.substring(6, 8)}`;
  const time = `${dt.substring(9, 11)}:${dt.substring(11, 13)}:${dt.substring(13, 15)}`;
  return `${date} ${time}`;
};
const releaseDateTime = import.meta.env.VITE_REACT_APP_RELEASE_DATETIME;
const buildTimestamp = releaseDateTime ? releaseDateTimeToISO(releaseDateTime) : 'unavailable';

const App = () => {
  useCheckFeatureFlagChecksum();

  const sendEvent = useAnalytics();
  const { loggedOut } = useAPI();

  const featureFlags = useFeatureFlags();
  const settingsPageFF = featureFlags.getBooleanFlag('sparxweb2-settings-page', true);

  const { data: session, isLoading: isSessionLoading } = useSession();

  // Create analytics after load for first contentful paint and largest contentful paint
  useEffect(() => {
    try {
      new PerformanceObserver(entryList => {
        for (const entry of entryList.getEntriesByName('first-contentful-paint')) {
          sendEvent({
            category: 'loading',
            action: `FCP`,
            labels: {
              millis: entry.startTime.toString(),
            },
          });
        }
      }).observe({ type: 'paint', buffered: true });
      new PerformanceObserver(entryList => {
        for (const entry of entryList.getEntries()) {
          sendEvent({
            category: 'loading',
            action: `LCP`,
            labels: {
              millis: entry.startTime.toString(),
            },
          });
        }
      }).observe({ type: 'largest-contentful-paint', buffered: true });
    } catch (e) {
      console.error('Performance API not present');
    }
  }, [sendEvent]);

  const defaultOverlayColour = '#FFFFFF';
  const overlayColourKey = 'settings.overlay_colour';
  const overlayColour =
    (settingsPageFF && session?.student?.labels[overlayColourKey]) || defaultOverlayColour;

  return (
    <>
      {!isSessionLoading && <ColourOverlay hexColour={overlayColour} />}
      <LoggedOutDialog isOpen={loggedOut} />
      <LevelUpDialog />
      <QueryAnalytics />
      <MinClientVersionManager
        assetURL={staticAssetsURL + '/assets'}
        clientType="swclient2"
        buildTimestamp={buildTimestamp}
        sendAnalytics={(action: string, category: 'other') => sendEvent({ action, category })}
      />
      <SuspenseRoute>
        <RouterProvider router={router} />
      </SuspenseRoute>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const AppWrapper = () => {
  useInitializeApp();

  return (
    <ThemeProvider themeStyles={appStyles}>
      <ErrorBoundary fallback={<SimpleErrorPage withBackground />}>
        <QueryClientProvider client={queryClient}>
          <APIProvider>
            <ServerStreamingProvider>
              <AnalyticsProvider>
                <ErrorBoundary fallback={<SimpleErrorPageWithAnalytics withBackground />}>
                  <AppStateProvider>
                    <App />
                  </AppStateProvider>
                </ErrorBoundary>
              </AnalyticsProvider>
            </ServerStreamingProvider>
          </APIProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default AppWrapper;

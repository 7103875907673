import classNames from 'classnames';
import { Video } from 'components/video/Video';
import { WhatsNewEntryContent } from 'components/whats-new/types';
import { Fragment } from 'react';

import styles from './Entry.module.css';

type WhatsNewEntryBodyProps = {
  body: WhatsNewEntryContent[];
  disableVideoForwardSeeking?: boolean;
};

export const WhatsNewEntryBody = ({ body, disableVideoForwardSeeking }: WhatsNewEntryBodyProps) => {
  // TODO: implement other content types, commented out in the WhatsNewEntryContent type definition
  // this should probably end up being mostly a copy of:
  // teacherportal/tpclient2/src/components/whatsnew/page/Entry.tsx
  return (
    <div className={styles.WhatsNewContainer}>
      {body.map((content, contentIdx) => {
        return (
          <Fragment key={contentIdx}>
            {content.videos?.map(video => (
              <div
                className={classNames(styles.VideoContainer, styles.WhatsNewBlock)}
                key={video.name}
              >
                <Video
                  analyticsCategory="other"
                  analyticsVideoName={`whats new ${video.name}`}
                  videoUrl={video.urls.videoUrl}
                  posterUrl={video.urls.posterUrl}
                  subtitleUrl={video.urls.subtitleUrl}
                  showPlayButton={true}
                  disableForwardSeeking={disableVideoForwardSeeking}
                />
              </div>
            ))}
            {content.text && (
              <div className={classNames(styles.WhatsNewBlock, styles.WhatsNewText)}>
                {content.text?.map((text, textIdx) => (
                  <p className={styles.Text} key={`${contentIdx}-text${textIdx}`}>
                    {text}
                  </p>
                ))}
              </div>
            )}
            {content.images?.map((img, imgIdx) => (
              <img
                key={`${contentIdx}-text${imgIdx}`}
                src={img.src}
                alt={img.alt}
                role={img.alt ? 'img' : 'presentation'}
                className={classNames(styles.Image, styles.WhatsNewBlock)}
              />
            ))}
          </Fragment>
        );
      })}
    </div>
  );
};

import { TaskCompletion, TaskItemCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { TaskItemStatus } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { checkInput, SparxQuestion } from '@sparx/question';
import { Button } from '@sparx/sparx-design/components';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { ChevronLeft, ChevronRight } from '@sparx/sparx-design/icons';
import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import { LoadingSpinnerWithAnalytics } from 'components/loading/LoadingSpinnerWithAnalytics';
import { useKeyboardMode } from 'context/keyboardmode';
import { useLandscapeModal } from 'context/landscapemodal';
import { useTutorialSpotlight } from 'context/tutorialspotlight';
import { AnimatePresence, motion } from 'framer-motion';
import { useAbortQuiz } from 'queries/ftq';
import { usePackageTasks, useTaskItems } from 'queries/packages';
import { TutorialKey } from 'queries/tutorials';
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { isFTQQuizComplete, isFTQQuizSkipped } from 'utils/ftqtask';
import { makeTaskPath, useFTQPath } from 'utils/paths';
import { GetCalculatorAllowed } from 'utils/question';
import { isFTQTask } from 'utils/taskTypes';
import { ConfirmationDialog, SubmittedDialog } from 'views/fast-track-quiz/dialogs';
import { FTQResultView } from 'views/fast-track-quiz/FTQResult';
import { Navbar } from 'views/fast-track-quiz/navbar';
import { FTQStep, useFastTrackQuiz } from 'views/fast-track-quiz/useFastTrackQuiz';
import BottomBar from 'views/lqd/bottom-bar';
import { QuestionAnswerLayout } from 'views/question/QuestionAnswerLayout';
import { QuestionInfo } from 'views/question/QuestionInfo';

import styles from './FastTrackQuiz.module.css';
import { useFTQTutorialSteps } from './useFTQTutorialSteps';

// FastTrackQuizView is the entry point for the fast track quiz. It handles fetching the task and
// task items and navigation though the items and steps of the quiz.
export const FastTrackQuizView = () => {
  const { parentPath, packageID, taskIndex } = useFTQPath();

  const navigate = useNavigate();

  // callback to navigate to the lqd task
  const goToLQDTask = () => {
    navigate(makeTaskPath(parentPath, packageID, taskIndex));
  };

  const {
    data: tasks,
    isLoading: tasksIsLoading,
    isError: tasksIsError,
  } = usePackageTasks(packageID || '');

  const task = useMemo(() => tasks?.find(t => t.taskIndex === taskIndex), [tasks, taskIndex]);

  const {
    data: allTaskItems,
    isLoading: taskItemsIsLoading,
    isError: taskItemsIsError,
  } = useTaskItems(packageID, taskIndex);

  const allFTQItems = useMemo(
    () => allTaskItems?.filter(item => itemIsQuizCandidate(item)),
    [allTaskItems],
  );

  // filter to just ftq items to show in quiz
  const incompleteFTQItems = useMemo(
    () => allFTQItems?.filter(item => item.status != TaskItemStatus.DONE),
    [allFTQItems],
  );

  // track which task item we're on, which step of the quiz we are on, and the last task item (for
  // animation)
  const [ftqStep, setFTQStep] = useState<FTQStep>(FTQStep.QUESTION);
  const [taskItemIndex, setTaskItemIndexState] = useState(incompleteFTQItems?.[0]?.taskItemIndex);
  const lastTaskItemIndexRef = useRef<number>();

  // setTaskItemIndex calls the state update function for taskItemIndex, and also updates the
  // lastTaskItemIndexRef.
  const setTaskItemIndex = useCallback(
    (u: React.SetStateAction<number | undefined>) => {
      setTaskItemIndexState(c => {
        lastTaskItemIndexRef.current = c;
        if (typeof u === 'function') {
          return u(c);
        } else {
          return u;
        }
      });
    },
    [setTaskItemIndexState],
  );

  // set ftqTaskItemIndex to the first one if its undefined
  useEffect(() => {
    if (taskItemIndex === undefined) {
      setTaskItemIndex(incompleteFTQItems?.[0]?.taskItemIndex);
    }
  }, [incompleteFTQItems, ftqStep, taskItemIndex, setTaskItemIndex]);

  // callback to navigate to the first task item in the answer state
  const goToAnswerStep = () => {
    setTaskItemIndex(incompleteFTQItems?.[0]?.taskItemIndex);
    setFTQStep(FTQStep.ANSWER);
  };

  // shouldAbortQuiz is a ref so we can use it in the use effect without depending on it
  const shouldAbortQuiz = useRef(true);
  shouldAbortQuiz.current = ftqStep === FTQStep.QUESTION || ftqStep === FTQStep.ANSWER;

  // mutation to abort the quiz
  const { mutate: abortQuiz } = useAbortQuiz();

  // if we leave the quiz before submitting, abort the quiz
  useEffect(
    () => () => {
      if (shouldAbortQuiz.current) {
        if (packageID && taskIndex) {
          abortQuiz({
            task: {
              packageID: packageID,
              taskIndex: taskIndex,
              taskItemIndex: 0,
              taskState: 0,
            },
          });
        }
      }
    },
    [abortQuiz, packageID, taskIndex],
  );

  // if ftq isn't available, or has been completed or skipped, redirect to task
  if (
    task &&
    (isFTQQuizComplete(task) || isFTQQuizSkipped(task) || !isFTQTask(task)) &&
    ftqStep !== FTQStep.SUBMITTING &&
    ftqStep !== FTQStep.SUBMITTED &&
    ftqStep !== FTQStep.RESULT
  ) {
    return <Navigate to={makeTaskPath(parentPath, packageID, taskIndex)} />;
  }

  let content: ReactNode;
  // show result screen
  if (ftqStep === FTQStep.RESULT && allFTQItems && task) {
    content = <FTQResultView ftqItems={allFTQItems} goToTask={goToLQDTask} />;
  } else if (tasksIsLoading || taskItemsIsLoading || taskItemIndex === undefined) {
    content = (
      <LoadingSpinnerWithAnalytics
        componentName="FastTrackQuizView"
        sendLongTimeLoadingEvent={true}
      />
    );
  } else if (
    !packageID ||
    !taskIndex ||
    tasksIsError ||
    taskItemsIsError ||
    !allFTQItems ||
    !incompleteFTQItems ||
    // if we are here, the tasks have loaded, so no task is an error
    !task
  ) {
    throw new Error('Error in fast track quiz');
  } else {
    content = (
      <FTQImpl
        packageID={packageID}
        taskIndex={taskIndex}
        taskItemIndex={taskItemIndex}
        setTaskItemIndex={setTaskItemIndex}
        lastTaskItemIndex={lastTaskItemIndexRef}
        ftqStep={ftqStep}
        setFTQStep={setFTQStep}
        allFTQItems={allFTQItems}
        task={task}
        goToAnswerStep={goToAnswerStep}
      />
    );
  }

  return (
    <>
      {ftqStep !== FTQStep.RESULT && (
        <Navbar
          ftqStep={ftqStep}
          ftqTaskItems={allFTQItems}
          taskItemIndex={taskItemIndex}
          setTaskItemIndex={setTaskItemIndex}
        />
      )}
      <div className={styles.BackgroundContainer}>{content}</div>
    </>
  );
};

type FTQImplProps = {
  packageID: string;
  taskIndex: number;
  taskItemIndex: number;
  setTaskItemIndex: (taskItemIndex: number | undefined) => void;
  lastTaskItemIndex: MutableRefObject<number | undefined>;
  ftqStep: FTQStep;
  task: TaskCompletion;
  allFTQItems: TaskItemCompletion[];
  setFTQStep: (ftqStep: FTQStep) => void;
  goToAnswerStep: () => void;
};

// FTQImpl handles the activity logic for the fast track quiz, and displays the activity.
// It also:
// - renders the bottom bar, handling logic for when the quiz can be moved to the next step.
// - renders the dialog for when the quiz is submitted, but not yet complete.
// TODO: The split seems slightly illogical and possibly merging this with its parent would be better.

const FTQImpl = ({
  packageID,
  taskIndex,
  taskItemIndex,
  setTaskItemIndex,
  lastTaskItemIndex,
  allFTQItems,
  ftqStep,
  task,
  setFTQStep,
  goToAnswerStep,
}: FTQImplProps) => {
  const isSmall = useBreakpoint('sm');
  const isMedium = useBreakpoint('md');
  const sendEvent = useAnalytics();
  const { tutorialIsShowing } = useTutorialSpotlight();

  const {
    input,
    setInput,
    onSubmitQuizAnswers,
    isError,
    isLoading,
    step,
    isSubmitQuizAnswersLoading,
  } = useFastTrackQuiz(packageID, taskIndex, taskItemIndex, ftqStep, setFTQStep);

  // work out indices of incomplete items, so we can determine where next and previous navigate to
  const incompleteQuizItemsIndices = useMemo(
    () =>
      allFTQItems?.reduce<number[]>((acc, item) => {
        if (item.status !== TaskItemStatus.DONE) {
          acc.push(item.taskItemIndex);
        }
        return acc;
      }, []),
    [allFTQItems],
  );

  const isQuestion = ftqStep === FTQStep.QUESTION;
  const isAnswer = ftqStep === FTQStep.ANSWER;

  // track which task items have been viewed, and so we can prevent navigation to the next step
  // until all items have been viewed
  const [allTaskItemsViewed, setAllTaskItemsViewed] = useState(false);
  const [taskItemsViewed, setTaskItemsViewed] = useState<Record<number, boolean>>({});
  const [taskItemsWithValidInput, setTaskItemsWithValidInput] = useState<Record<number, boolean>>(
    {},
  );

  // when the input changes, update the taskItemsWithValidInput record
  useEffect(() => {
    setTaskItemsWithValidInput(c => ({ ...c, [taskItemIndex]: checkInput(input) }));
  }, [input, taskItemIndex]);

  const allTaskItemsHaveValidInput = incompleteQuizItemsIndices.every(
    i => taskItemsWithValidInput[i],
  );

  const firstUnviewedTaskItem = incompleteQuizItemsIndices.find(i => !taskItemsViewed[i]);

  const firstTaskItemWithInvalidInput = incompleteQuizItemsIndices.find(
    i => !taskItemsWithValidInput[i],
  );

  const currentItemPosition = incompleteQuizItemsIndices.indexOf(taskItemIndex);
  const previousTaskItemIndex =
    currentItemPosition > 0 ? incompleteQuizItemsIndices[currentItemPosition - 1] : undefined;
  const nextTaskItemIndex =
    // if the current item is out of range, return undefined
    currentItemPosition < 0 || currentItemPosition >= incompleteQuizItemsIndices.length
      ? undefined
      : // if the current item is not the last in the task, return the next item
        currentItemPosition < incompleteQuizItemsIndices.length - 1
        ? incompleteQuizItemsIndices[currentItemPosition + 1]
        : // if it is, return the first unviewed item for question mode
          isQuestion
          ? firstUnviewedTaskItem
          : // and the first task item with invalid input for answer mode
            isAnswer
            ? firstTaskItemWithInvalidInput
            : undefined;

  // when we switch to question or answer steps, clear everything
  useEffect(() => {
    if (isQuestion || isAnswer) {
      setAllTaskItemsViewed(false);
      setTaskItemsViewed({ [incompleteQuizItemsIndices[0]]: true });
    }
  }, [isQuestion, isAnswer, incompleteQuizItemsIndices]);

  // when we switch taskItemIndex, mark that its been seen.
  useEffect(() => {
    setTaskItemsViewed(c => ({ ...c, [taskItemIndex]: true }));
  }, [taskItemIndex]);
  // once all items have been viewed, setAllTaskItemsViewed
  useEffect(() => {
    if (incompleteQuizItemsIndices.every(i => taskItemsViewed[i])) {
      setAllTaskItemsViewed(true);
    }
  }, [incompleteQuizItemsIndices, taskItemsViewed]);

  // state to control the confirmation dialog. It is created here and passed down into the
  // confirmation dialog component, and the setState is needs to be used in the button clicks
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const [animating, setAnimating] = useState(false);
  const { showTutorials } = useTutorialSpotlight();

  const questionTutorialSteps = useFTQTutorialSteps([
    TutorialKey.FTQNavigation,
    TutorialKey.FTQNextQuestion,
  ]);
  const finishQuestionTutorialKeys = useFTQTutorialSteps([TutorialKey.FTQEnterAllAnswers]);
  const answerTutorialKeys = useFTQTutorialSteps([TutorialKey.FTQNextAnswer]);
  const finishAnswerTutorialKeys = useFTQTutorialSteps([TutorialKey.FTQFinishEnteringAnswers]);
  useEffect(() => {
    switch (true) {
      case isQuestion && !allTaskItemsViewed:
        showTutorials(questionTutorialSteps, { disabled: animating });
        break;
      case isQuestion && allTaskItemsViewed:
        showTutorials(finishQuestionTutorialKeys, { disabled: animating });
        break;
      case isAnswer && !allTaskItemsViewed:
        showTutorials(answerTutorialKeys, { disabled: animating });
        break;
      case isAnswer && allTaskItemsViewed:
        showTutorials(finishAnswerTutorialKeys, { disabled: animating });
        break;
    }
  }, [
    allTaskItemsViewed,
    animating,
    answerTutorialKeys,
    finishAnswerTutorialKeys,
    finishQuestionTutorialKeys,
    isAnswer,
    isQuestion,
    questionTutorialSteps,
    showTutorials,
  ]);

  // show landscape modal if scale is less than 0.5
  const landscapeModal = useLandscapeModal();
  const onScaleChange = (value: number) => {
    if (value < 0.5) {
      landscapeModal.trigger();
    }
  };

  // use keyboard mode to allow enter to submit
  const { enabled: isKeyboardMode } = useKeyboardMode();

  const inputValid = checkInput(input);

  const confirmationDialog = (
    <ConfirmationDialog
      dialogOpen={confirmationDialogOpen}
      setDialogOpen={setConfirmationDialogOpen}
      ftqStep={ftqStep}
      goToAnswerStep={goToAnswerStep}
      onSubmitQuizAnswers={(onSuccess: () => void) =>
        onSubmitQuizAnswers(packageID, taskIndex, onSuccess)
      }
      isSubmitQuizAnswersLoading={isSubmitQuizAnswersLoading}
    />
  );
  let content: ReactNode;
  if (isError) {
    throw new Error('Error in fast track quiz');
  } else if (isLoading || !input || !step?.layout) {
    content = (
      <>
        <LoadingSpinnerWithAnalytics componentName="FTQImpl" sendLongTimeLoadingEvent={true} />
        {confirmationDialog}
      </>
    );
  } else {
    content = (
      <>
        <SubmittedDialog
          ftqStep={ftqStep}
          setFTQStep={setFTQStep}
          setTaskItemIndex={setTaskItemIndex}
          task={task}
          allFTQItems={allFTQItems}
        />
        {confirmationDialog}
        <KeyboardShortcuts
          enterFunc={(e: KeyboardEvent) => {
            // if we are in keyboard mode, we don't want to trigger the enter key to do anything
            // special as it will prevent some other interactions (e.g. it will be impossible to
            // change a valid slots answer with just the keyboard)
            if (isKeyboardMode || tutorialIsShowing || isLoading) {
              return;
            }
            switch (ftqStep) {
              case FTQStep.QUESTION:
                if (allTaskItemsViewed) {
                  // prevent the enter press immediately closing the dialog
                  if (!confirmationDialogOpen) {
                    e.preventDefault();
                  }
                  setConfirmationDialogOpen(true);
                } else {
                  // prevent default so that pressing enter doesn't click focussed buttons
                  e.preventDefault();
                  setTaskItemIndex(nextTaskItemIndex);
                }
                break;
              case FTQStep.ANSWER:
                // if the input isnt valid, do nothing
                if (!inputValid) {
                  return;
                }
                // if it is valid, go to the next task item or if they all have a valid input, open
                // the submit confirmation dialog
                if (!allTaskItemsHaveValidInput) {
                  setTaskItemIndex(nextTaskItemIndex);
                } else {
                  if (!confirmationDialogOpen) {
                    e.preventDefault();
                  }
                  setConfirmationDialogOpen(true);
                }
                break;
              default:
                break;
            }
          }}
        />
        <QuestionInfo calculatorAllowed={GetCalculatorAllowed(step)} showBanner={false} />

        <div className={styles.QuestionWrapper}>
          {isQuestion ? (
            <SparxQuestion
              layout={step?.layout}
              input={input}
              setInput={setInput}
              mode="question"
              fontSize="30"
              lineHeight="1.2em"
              centered={true}
              onScaleChange={onScaleChange}
              sendAnalyticEvent={(action, labels) =>
                sendEvent({ category: 'question', action, labels })
              }
            />
          ) : (
            <SparxQuestion
              layout={step?.layout}
              input={input}
              setInput={setInput}
              mode="answer"
              fontSize="30"
              lineHeight="1.2em"
              centered={true}
              onScaleChange={onScaleChange}
              keyboardMode={isKeyboardMode}
              sendAnalyticEvent={(action, labels) =>
                sendEvent({ category: 'question', action, labels })
              }
            />
          )}
        </div>
      </>
    );
  }
  const bottomBar = (
    <BottomBar>
      {previousTaskItemIndex ? (
        <Button
          onClick={() => {
            setTaskItemIndex(previousTaskItemIndex || 0);
            sendEvent({
              category: 'ftq',
              action: 'clicked previous',
              labels: { ftqStep: ftqStep, ftqTaskItemIndex: taskItemIndex },
            });
          }}
          className={styles.BottomBarButton}
          isDisabled={isLoading}
        >
          <ChevronLeft />
          {!isSmall && <span>Previous</span>}
        </Button>
      ) : (
        <div></div>
      )}
      <Button
        data-tutorial-spotlight-key={
          isQuestion ? TutorialKey.FTQEnterAllAnswers : TutorialKey.FTQFinishEnteringAnswers
        }
        variant="contained"
        isDisabled={!allTaskItemsViewed || isLoading}
        onClick={() => {
          setConfirmationDialogOpen(true);
          sendEvent({
            category: 'ftq',
            action: `clicked ${ftqStep === FTQStep.QUESTION ? 'enter' : 'submit'} answers`,
            labels: { ftqStep: ftqStep, ftqTaskItemIndex: taskItemIndex },
          });
        }}
      >
        {isQuestion
          ? `Enter${!isMedium ? ' all' : ''} answers `
          : `Finish${!isSmall ? ' entering answers' : 'ed'}`}
      </Button>
      {/* only show the next button if its going to a next question, not if it would just be
      going back to a previously unviewed or unanswered question  */}
      {(nextTaskItemIndex || 0) > incompleteQuizItemsIndices[currentItemPosition] ? (
        <Button
          data-tutorial-spotlight-key={
            isQuestion ? TutorialKey.FTQNextQuestion : TutorialKey.FTQNextAnswer
          }
          onClick={() => {
            setTaskItemIndex(nextTaskItemIndex);
            sendEvent({
              category: 'ftq',
              action: 'clicked next',
              labels: { ftqStep: ftqStep, ftqTaskItemIndex: taskItemIndex },
            });
          }}
          className={styles.BottomBarButton}
          isDisabled={isLoading}
        >
          {!isSmall && <span>Next</span>}
          <ChevronRight />
        </Button>
      ) : (
        <div></div>
      )}
    </BottomBar>
  );

  const key = `${taskItemIndex}-${
    ftqStep === FTQStep.SUBMITTED || ftqStep === FTQStep.SUBMITTING ? FTQStep.ANSWER : ftqStep
  }`;

  const reverse = taskItemIndex < (lastTaskItemIndex.current || 0);

  return (
    <AnimatePresence mode="popLayout" custom={reverse}>
      <motion.div
        key={key}
        className={styles.QAContainer}
        variants={{
          enter: {
            x: reverse ? '-100%' : '100%',
          },
          exit: (reverse: boolean) => ({
            x: reverse ? '100%' : '-100%',
          }),
        }}
        animate={{ x: '0%' }}
        initial="enter"
        exit="exit"
        transition={{ duration: 0.4 }}
        onAnimationStart={() => setAnimating(true)}
        onAnimationComplete={() => setAnimating(false)}
      >
        <QuestionAnswerLayout content={content} bottomBar={bottomBar} />
      </motion.div>
    </AnimatePresence>
  );
};

// should be kept up to date with TaskItemType in sparxweb/fasttrackquiz/labels.go
enum FTQTaskItemType {
  NoTaskItemType = '',
  QuizCandidate = 'quiz_candidate',
  BadgeCandidate = 'badge_candidate',
  HideCandidate = 'hide_candidate',
  ExtensionItem = 'ext_item',
}

const FTQItemLabel = 'ftq_item';

const itemIsQuizCandidate = (item: TaskItemCompletion) =>
  item.labels[FTQItemLabel] === FTQTaskItemType.QuizCandidate;

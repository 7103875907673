import classNames from 'classnames';
import lockedGameImage from 'images/game-select-menu/locked-game-icon.png';
import { FunctionComponent } from 'react';

import {
  targetCountIsSufficient,
  TimesTablesGameParameters,
  tokenCountIsSufficient,
  tokenRequirementForGame,
} from '../game-parameters';
import styles from './GameSelectButton.module.css';

export interface IGameSelectButtonProps {
  onSelect: (enoughTokens: boolean, enoughTargets: boolean) => void;
  gameId: string;
  // enabled is true if the set of allowed games for this user (as defined by feature flags)
  // includes this game
  enabled: boolean;
  tokenCount: number;
  targetCount: number;
  usePrimaryUnlockRates: boolean;
  isSeleniumModeOn: boolean;
}

export const GameSelectButton: FunctionComponent<IGameSelectButtonProps> = ({
  onSelect,
  gameId,
  tokenCount,
  targetCount,
  usePrimaryUnlockRates,
  isSeleniumModeOn,
}) => {
  const gameData = TimesTablesGameParameters[gameId] || { name: gameId };

  // Check if this question set is allowed for this game
  // (during Selenium tests games should not be locked due to lack of tokens)
  const enoughTokens =
    tokenCountIsSufficient(gameId, tokenCount, usePrimaryUnlockRates) || isSeleniumModeOn;
  const enoughTargets = targetCountIsSufficient(gameId, targetCount) || isSeleniumModeOn;

  const baseClasses = classNames(styles.BtnMenuItem, styles.GameButton, styles.PgGame);

  const tokenRequirement = tokenRequirementForGame(gameId, usePrimaryUnlockRates);
  const tokenIsPlural = tokenRequirement !== 1;
  const tokensWord = tokenIsPlural ? 'tokens' : 'token';
  let gameTitle = gameData.name;
  if (!enoughTargets) {
    gameTitle = 'Available when you have more targets';
  }
  if (!enoughTokens) {
    gameTitle = 'Unlock at ' + tokenRequirement + ' ' + tokensWord;
  }

  const titleClass = classNames(styles.GameTitle, !enoughTargets && styles.LockedTitle);

  const imageDiv = enoughTokens ? (
    gameData.image ? (
      <img className={styles.GameImg} src={gameData.image} />
    ) : null
  ) : (
    <img className={styles.GameImg} src={lockedGameImage} />
  );

  return (
    <div
      key={gameId}
      className={classNames(baseClasses, !enoughTokens && styles.Locked)}
      onClick={() => onSelect(enoughTokens, enoughTargets)}
    >
      {imageDiv}
      <div className={styles.GameTitleHolder}>
        <h2 className={titleClass}>{gameTitle}</h2>
      </div>
      <div className="pg-lock-overlay">
        <i className="fa fa-lock" />
      </div>
      <div className={styles.BackgroundContainer} />
    </div>
  );
};

import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { makeHomePath, useLQDPath } from 'utils/paths';

import { navIcons } from './icons';
import { NavItem } from './NavigationFrame';

export const useNavLinks = (options?: {
  selectedPackageType?: string;
  setSelectedPackageType?: (packageType: string) => void;
}) => {
  const { selectedPackageType, setSelectedPackageType } = options || {};
  const sendEvent = useAnalytics();
  const navigate = useNavigate();

  const { isIndependentLearning } = useLQDPath();

  const links = [
    {
      name: 'Compulsory',
      type: 'homework',
      icons: navIcons.compulsory,
      isActive: selectedPackageType === 'homework',
    },
    {
      name: 'XP Boost',
      type: 'optional-homework',
      icons: navIcons.xpBoost,
      isActive: selectedPackageType === 'optional-homework',
    },
    {
      name: 'Target',
      type: 'targets',
      icons: navIcons.targets,
      isActive: selectedPackageType === 'targets',
    },
    {
      name: 'Independent Learning',
      icons: navIcons.independentLearning,
      to: '/independentlearning',
      isActive: isIndependentLearning,
    },
  ];

  const onClickLink = ({
    packageType,
    analyticsTarget,
    to,
  }: {
    packageType?: string;
    analyticsTarget?: string;
    to?: string;
  }) => {
    if (to) {
      navigate(to);
    } else {
      navigate(makeHomePath({ packageType }));
    }
    if (packageType && setSelectedPackageType) {
      setSelectedPackageType(packageType);
    }
    sendEvent({
      action: `clicked ${analyticsTarget} nav link`,
      category: 'package list',
    });
    return;
  };

  const navLinks: NavItem[] = links
    .filter(l => {
      // if there is no filter property on the link object don't remove it
      if (!('filter' in l)) {
        return true;
      }
      // otherwise check the filter property value
      return l?.filter;
    })
    .map(l => ({
      text: l.name,
      icons: l.icons,
      isActive: l.isActive,
      onClick: () =>
        onClickLink({ packageType: l.type, analyticsTarget: l.name.toLowerCase(), to: l.to }),
      disableOnboardingLock: l.type === 'homework',
    }));

  return navLinks;
};

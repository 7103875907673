import { usePackage, useTaskItems } from 'queries/packages';
import { useMemo } from 'react';
import { useFeatureFlags } from 'utils/feature-flags';

export const useIsQuestionSwapsEnabled = (
  packageID: string,
  taskIndex: number,
  taskItemIndex: number,
  answerStatus: string,
) => {
  // we will definitely have the package by the time we need this, so no need to check for
  // loading - we just wont return the mutation until we have data
  const { data: pkg } = usePackage(packageID);
  const featureFlags = useFeatureFlags();

  const { data: taskItems } = useTaskItems(packageID, taskIndex);
  const taskItem = useMemo(
    () => taskItems?.find(ti => ti.taskItemIndex === taskItemIndex),
    [taskItems, taskItemIndex],
  );
  const swapAllowed =
    // only available if the feature flag is on
    featureFlags.getBooleanFlag('sparxweb2-question-swaps', false) &&
    // only available for compulsory homeworks, which have a packageType of 'homework', see sparxweb/pkgtools/helpers.go
    pkg?.packageType === 'homework' &&
    // only available if the answer was incorrect
    (answerStatus === 'FAILURE' || answerStatus === 'SEEKHELP') &&
    // not available if the task item is itself a swap in
    !taskItem?.swappedInForIndex;
  return swapAllowed;
};

import {
  TaskCompletion as Task,
  TaskItemCompletion as TaskItem,
  TaskItemStatus as TaskItem_TaskItemStatus,
} from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { RewardGroup_Type } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { makeTaskName } from '@sparx/resource-names';
import { Button } from '@sparx/sparx-design/components';
import { Flag, Skip, Tick } from '@sparx/sparx-design/icons';
import { TextWithMaths } from '@sparx/text-with-maths';
import { KeyboardShortcuts } from 'app/KeyboardShortcuts';
import classNames from 'classnames';
import { NextXPLevel } from 'components/next-xp-level';
import { useRewards } from 'context/rewards';
import { motion } from 'framer-motion';
import { usePackage } from 'queries/packages';
import { getUsersNamesFromSession, useSession } from 'queries/session';
import { useSetStudentRewardsQuery } from 'queries/studentrewards';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isPkgOrTaskComplete } from 'utils/package';
import {
  makeHomePath,
  makePackageCompletionPath,
  makeTaskPath,
  makeTimesTablesTaskPath,
  makeVideoTaskPath,
  useLQDPath,
} from 'utils/paths';
import { isTimesTablesTask, isVideoTask } from 'utils/taskTypes';
import { QuestionAnswerLayout } from 'views/question/QuestionAnswerLayout';

import BottomBar from '../bottom-bar';
import styles from './Summary.module.css';

interface ISummaryProps {
  tasks: Task[];
  taskIndex: number;
  taskItems: TaskItem[];
  customHomePath?: string;
}

export const Summary: FunctionComponent<ISummaryProps> = ({
  tasks,
  taskIndex,
  taskItems,
  customHomePath,
}) => {
  const navigate = useNavigate();
  const { parentPath, isIndependentLearning, packageID } = useLQDPath();
  const { data: pkg } = usePackage(packageID || '');
  const task = tasks[taskIndex - 1];

  const showCompleteScreen = pkg && pkg.packageType === 'homework' && isPkgOrTaskComplete(pkg);
  const completeScreenPath = makePackageCompletionPath(parentPath, packageID, taskIndex);

  const nextTask =
    taskIndex < tasks.length
      ? tasks.slice(taskIndex, tasks.length).find(task => !isPkgOrTaskComplete(task))
      : undefined;
  const { data: session } = useSession();
  const studentName = getUsersNamesFromSession(session, 'firstOnly');
  const isComplete = isPkgOrTaskComplete(task);

  const { activityRewards, setRewardsPending } = useRewards();
  const taskRewards = useMemo(() => {
    const taskName = makeTaskName(task.packageID, taskIndex);
    return activityRewards[taskName];
  }, [activityRewards, task.packageID, taskIndex]);
  const xpEarned = useMemo(() => {
    return taskRewards ? taskRewards.rewards.reduce((acc, r) => acc + r.xPoints, 0) : 0;
  }, [taskRewards]);
  const xpState = taskRewards?.xpStateAfterApplying;

  const setStudentRewards = useSetStudentRewardsQuery();
  useEffect(() => {
    setStudentRewards(xpState);
  }, [setStudentRewards, xpState]);

  useEffect(() => {
    if (xpState) {
      setRewardsPending(existingPending => ({
        ...existingPending,
        [RewardGroup_Type.TASK]: false,
      }));
    }
  }, [xpState, setRewardsPending]);

  const numTaskItemsDone = taskItems.filter(i => i.status === TaskItem_TaskItemStatus.DONE).length;

  const numTaskItemsTotal = taskItems.filter(
    i => i.status !== TaskItem_TaskItemStatus.BADGED,
  ).length;

  const numTaskItemsBadged = taskItems.filter(
    i => i.status === TaskItem_TaskItemStatus.BADGED,
  ).length;

  let badgedText: React.ReactNode;
  if (numTaskItemsBadged > 1) {
    badgedText = (
      <p className={styles.BadgedText}>
        <Skip className={styles.SkipIcon} />
        {numTaskItemsBadged} questions Fast Tracked
      </p>
    );
  } else if (numTaskItemsBadged === 1) {
    badgedText = (
      <p className={styles.BadgedText}>
        <Skip className={styles.SkipIcon} />
        {numTaskItemsBadged} question Fast Tracked
      </p>
    );
  }
  let progressSection: React.ReactNode;
  let motivationSection: React.ReactNode;
  if (!isComplete) {
    progressSection = (
      <>
        <div className={classNames(styles.Progress, styles.SummaryProgress)}>
          <span className={styles.SummaryProgressCounts}>
            {numTaskItemsDone}/{numTaskItemsTotal}
          </span>
          <span className={styles.SummaryProgressText}>Complete</span>
        </div>
        {badgedText}
      </>
    );
    const seekHelpCount = taskItems.filter(
      i => i.status === TaskItem_TaskItemStatus.SEEKHELP,
    ).length;
    motivationSection =
      seekHelpCount > 0 ? (
        <div className={styles.SeekHelpSection}>
          <div className={styles.SeekHelpImageContainer}>
            <Flag variant="White" className={styles.SeekHelpImage} />
          </div>
          <p className={styles.SeekHelpHeading}>
            You need help with {seekHelpCount} {seekHelpCount > 1 ? 'questions' : 'question'} in
            this task.
          </p>
          <p className={styles.SeekHelpMessage}>
            Complete the rest of your homework and then get help with this question from your
            teacher before the homework deadline.
          </p>
        </div>
      ) : (
        <div className={styles.MotivationSection}>
          <p className={styles.MotivationMessage}>{`Keep going ${studentName}! 💪`}</p>
        </div>
      );
  } else {
    progressSection = (
      <motion.div
        animate={
          xpEarned > 0 && {
            scaleX: [1, 0.95, 1],
            transition: { duration: 0.3, delay: 0.6 },
          }
        }
        className={styles.ProgressContainer}
      >
        <Tick variant="White" className={styles.CorrectImage} />
        {xpEarned > 0 && (
          <motion.div
            initial={{ width: '0' }}
            animate={{
              width: 'auto',
            }}
            transition={{ duration: 0.5, delay: 0.2, ease: [0.3, 0.4, 0.5, 2] }}
            className={styles.XPTextContainer}
          >
            <span className={styles.XPText}>
              {xpEarned}
              <span className={styles.SmallerText}> XP</span>
            </span>
          </motion.div>
        )}
      </motion.div>
    );
    motivationSection = (
      <>
        <div className={styles.MotivationSection}>
          {badgedText}
          <p className={styles.AllCorrect}>
            {numTaskItemsTotal} out of {numTaskItemsTotal} correct
          </p>
          <p className={styles.MotivationMessage}>{`Great work, ${studentName}! 👍`}</p>
        </div>
        {xpState?.closeToNextLevel && <NextXPLevel rewards={xpState} />}
      </>
    );
  }

  const homePath = customHomePath || parentPath || makeHomePath({ packageID: task.packageID });
  const getTaskPath = () => {
    if (!isComplete) {
      return makeTaskPath(parentPath, task.packageID, taskIndex);
    }

    if (!nextTask) {
      return undefined;
    }

    if (isTimesTablesTask(nextTask)) {
      return makeTimesTablesTaskPath(nextTask.packageID, nextTask.taskIndex);
    }
    if (isVideoTask(nextTask)) {
      return makeVideoTaskPath(parentPath, nextTask.packageID, nextTask.taskIndex);
    }
    return makeTaskPath(parentPath, nextTask.packageID, nextTask.taskIndex);
  };
  const taskPath = getTaskPath();

  const content = (
    <>
      <KeyboardShortcuts
        enterFunc={() => {
          navigate(showCompleteScreen ? completeScreenPath : taskPath || homePath);
        }}
        source="lqd-summary"
      />
      <div className={styles.SummaryContainer}>
        <p className={styles.SummaryTitle}>
          <TextWithMaths text={task.title} />
        </p>
        {progressSection}
        {motivationSection}
        <div className={styles.Buttons}>
          {showCompleteScreen ? (
            <Button
              size="md"
              as={Link}
              to={completeScreenPath}
              variant="contained"
              // BOOL-3095 - page event removed to reduce Cloudflare traffic
              // onClick={() => {
              //   sendEvent({ action: 'clicked to completion screen from summary', category: 'lqd' });
              // }}
            >
              Continue
            </Button>
          ) : (
            <Button
              size="md"
              as={Link}
              to={homePath}
              // BOOL-3095 - page event removed to reduce Cloudflare traffic
              // onClick={() => {
              //   sendEvent({ action: 'clicked back to task list from summary', category: 'lqd' });
              // }}
            >
              Back to {isIndependentLearning ? 'topic' : 'task list'}
            </Button>
          )}
          {taskPath && (
            <Button
              size="md"
              as={Link}
              to={taskPath}
              variant="contained"
              // BOOL-3095 - page event removed to reduce Cloudflare traffic
              // onClick={() => {
              //   if (isComplete) {
              //     sendEvent({ action: 'clicked next task from summary', category: 'lqd' });
              //   } else {
              //     sendEvent({ action: 'clicked keep going from summary', category: 'lqd' });
              //   }
              // }}
            >
              {isComplete ? 'Next task' : 'Keep going'}
            </Button>
          )}
        </div>
      </div>
    </>
  );

  return <QuestionAnswerLayout content={content} bottomBar={<BottomBar />} />;
};

import { Tick } from '@sparx/sparx-design/icons';
import classNames from 'classnames';

import styles from './TrainingBanner.module.css';

/**
 * StepComplete shows extra completion information for a step or summary step
 */

interface IStepCompleteProps {
  // Title of the complete section
  title: string;
  // Text of the complete section
  text?: string;
}

export const StepComplete = ({ title, text }: IStepCompleteProps) => (
  <div className={styles.TrainingModeStepCompletion}>
    <div>
      <div className={styles.TrainingModeStepCompleteTitle}>
        <div className={styles.TrainingModeStepCompleteIcon}>
          <Tick
            variant="White"
            className={classNames(styles.TrainingModeCheckIcon, styles.TrainingModeCheckAnimation)}
          />
        </div>
        {title}
      </div>
      {text && <p className={styles.TrainingModeStepCompleteText}>{text}</p>}
    </div>
  </div>
);

import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskItemCompletion, TaskItemStatus } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Button } from '@sparx/sparx-design/components';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { ReactComponent as CrossCircle } from 'images/CrossCircle.svg';
import { ReactComponent as DashCircle } from 'images/DashCircle.svg';
import { ReactComponent as TickCircle } from 'images/TickCircle.svg';
import { ReactNode } from 'react';
import BottomBar from 'views/lqd/bottom-bar';
import { QuestionAnswerLayout } from 'views/question/QuestionAnswerLayout';

import styles from './FastTrackQuiz.module.css';

type FTQResultProps = {
  ftqItems: TaskItemCompletion[];
  goToTask: () => void;
};

// FTQResultView shows the results of the quiz, once the quiz has been completed, and a link to allow
// the user to continue to the rest of the task
export const FTQResultView = ({ ftqItems, goToTask }: FTQResultProps) => {
  const isSmall = useBreakpoint('sm');
  const content = (
    <div className={styles.ResultsWrapper}>
      <p className={styles.ResultsTitle}>Fast Track result</p>
      <div className={styles.ResultCardsContainer}>
        {ftqItems?.map((ti, i) => {
          let statusIcon: ReactNode;
          switch (ti.status) {
            case TaskItemStatus.DONE:
              statusIcon = <TickCircle />;
              break;
            case TaskItemStatus.WRONG:
              statusIcon = <CrossCircle />;
              break;
            case TaskItemStatus.UNATTEMPTED:
              statusIcon = <DashCircle />;
              break;
          }
          return (
            <div className={styles.ResultCard} key={ti.taskItemIndex}>
              <span>Q{i + 1}.</span>
              {statusIcon}
            </div>
          );
        })}
      </div>
      <p className={styles.ResultsBody}>
        {ftqItems.every(ti => ti.status === TaskItemStatus.DONE)
          ? 'Great job! Continue this task to finish any remaining questions'
          : 'Continue to work through the questions in this task and correct any mistakes'}
      </p>
    </div>
  );
  const bottomBar = (
    <BottomBar>
      <div />
      <div />
      <Button
        variant="contained"
        onClick={goToTask}
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
      >
        {`Continue${!isSmall ? ' task' : ''}`}
      </Button>
    </BottomBar>
  );

  return (
    <div className={styles.QAContainer}>
      <QuestionAnswerLayout content={content} bottomBar={bottomBar} />
    </div>
  );
};

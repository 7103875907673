import { useLocation } from 'react-router-dom';

import { validateUUID } from './uuid';

// Fake package id used for times tables when doing Primary Times Tables when there is no homework package
export const timesTablesNoTaskPrimaryPackageID = 'no-task';
// Fake package id used for times tables when doing secondary Times Tables when there is no homework package as part
// of independent learning
export const timesTablesNoTaskIndependentLearningPackageID = 'independentlearning';

export const makeTaskPath = (
  parentPath: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
) => `${parentPath || ''}/package/${packageID}/task/${taskIndex}`;

export const makeTimesTablesTaskPath = (
  packageID: string | undefined,
  taskIndex: number | undefined,
) => `/package/${packageID}/timestablestask/${taskIndex}`;

export const makeVideoTaskPath = (
  parentPath: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
) => `${parentPath || ''}/package/${packageID}/videotask/${taskIndex}`;

export const makeTaskItemPath = (
  parentPath: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
  i: number,
) => `${makeTaskPath(parentPath, packageID, taskIndex)}/item/${i}`;

export const makeTaskSummaryPath = (
  parentPath: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
) => `${makeTaskPath(parentPath, packageID, taskIndex)}/summary`;

export const makePackageCompletionPath = (
  parentPath: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
) => `${makeTaskPath(parentPath, packageID, taskIndex)}/complete`;

export const makeTaskItemWACPath = (
  parentPath: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
  i: number,
) => `${makeTaskPath(parentPath, packageID, taskIndex)}/item/${i}/wac`;

export const makeFTQPath = (
  parentPath: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
) => `${makeTaskPath(parentPath, packageID, taskIndex)}/ftq`;

export const makeHomePath = ({
  packageID,
  packageType,
}: {
  packageID?: string;
  packageType?: string;
}) => {
  if (packageID) {
    return `/homework/?open_package_id=${packageID}`;
  }
  if (packageType) {
    return `/homework/?open_package_type=${packageType}`;
  }
  return '';
};

export const makeGamesPath = (
  gameID: string | undefined,
  packageID: string | undefined,
  taskIndex: number | undefined,
) => `/games/${gameID}${makeTaskPath('', packageID, taskIndex)}`;

export type LQDPathData = {
  isLQDPath: boolean;
  // parentPath if the LHS of the path before /package/...
  // using this allows LQD to work on other paths eg. in independent learning
  parentPath?: string;
  packageID: string;
  isIndependentLearning: boolean;
  taskIndex?: number;
  taskItemIndex?: number;
  isSummary?: boolean;
  isCompletionScreen: boolean;
  isWAC: boolean;
};

export const getLQDPath = (path: string) => {
  const re =
    /(.+)?\/package\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/task\/(\d+)(?:\/(?:item\/(\d+)|(summary))?)?/;
  const reRes = path.match(re);
  const data: LQDPathData = {
    isLQDPath: !!reRes,
    parentPath: reRes?.[1],
    packageID: validateUUID(reRes?.[2]),
    isIndependentLearning: path.startsWith('/independentlearning'),
    taskIndex: parseInt(reRes?.[3] || ''),
    taskItemIndex: parseInt(reRes?.[4] || ''),
    isSummary: !!reRes?.[5],
    isCompletionScreen: path.includes('/complete'),
    isWAC: path.includes('/wac'),
  };
  return data;
};

// TODO: Tidy this up - copied from MTP
// TODO: this also needs to match wacs (see makeTaskItemWACPath)
// useLQDPath checks the apps url and returns some information about lqd if the app is displaying it
// taskIndex and taskItemIndex are the indices shown in the url, e.g. 1 indexed
export const useLQDPath = () => {
  const path = useLocation().pathname;
  return getLQDPath(path);
};

type TimesTablesPathData = {
  isTimesTablesPath: boolean;
  packageID?: string;
  isPrimaryNoPackage: boolean;
  isIndependentLearningNoPackage: boolean;
};

export const getTimesTablesPath = (path: string) => {
  const re = /\/package\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;
  const reRes = path.match(re);
  const data: TimesTablesPathData = {
    isTimesTablesPath: path.includes('/timestablestask/'),
    packageID: reRes?.[1],
    isPrimaryNoPackage: path.includes(`/package/${timesTablesNoTaskPrimaryPackageID}`),
    isIndependentLearningNoPackage: path.includes(
      `/package/${timesTablesNoTaskIndependentLearningPackageID}`,
    ),
  };
  return data;
};

type VideoTaskPathData = {
  isVideoTaskPath: boolean;
  parentPath?: string;
  packageID?: string;
};

export const getVideoTaskPath = (path: string) => {
  const re =
    /(.+)?\/package\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/videotask\/(\d+)/;
  const reRes = path.match(re);
  const data: VideoTaskPathData = {
    isVideoTaskPath: !!reRes,
    parentPath: reRes?.[1],
    packageID: reRes?.[2],
  };
  return data;
};

type GamePathData = {
  isGamePath: boolean;
  gameName?: string;
  packageId?: string;
  taskIndex?: number;
  isPractice: boolean;
};
export const useGamePath = () => {
  const path = useLocation().pathname;
  const components = path.split('/');
  const data: GamePathData = {
    isGamePath: path.includes('/games/'),
    isPractice: path.includes('/practice'),
  };
  for (let i = 0; i < components.length - 1; i++) {
    if (components[i] === 'games') {
      data.gameName = components[i + 1];
    }
    if (components[i] === 'package') {
      data.packageId = components[i + 1];
    }
    if (components[i] === 'task') {
      data.taskIndex = parseInt(components[i + 1]);
    }
  }
  return data;
};

export const getFTQPath = (path: string) => {
  const re =
    /(.+)?\/package\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/task\/(\d+)\/ftq/;
  const reRes = path.match(re);
  const data = {
    isFTQPath: !!reRes,
    parentPath: reRes?.[1],
    packageID: reRes?.[2],
    taskIndex: reRes?.[3] ? parseInt(reRes[3]) : undefined,
  };
  return data;
};

export const useFTQPath = () => {
  const path = useLocation().pathname;
  return getFTQPath(path);
};

export const useILPath = () => {
  const path = useLocation().pathname;
  return {
    isILPath: path.startsWith('/independentlearning'),
  };
};

export const useAssessmentsPath = () => {
  const path = useLocation().pathname;
  return {
    isAssessmentsPath: path.startsWith('/assessments'),
  };
};

import { Notification } from '@sparx/api/apis/sparx/notifications/notifications/v1/notifications';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import {
  getLastSeenIndex,
  lastSeenWhatsNewEntryIDKey,
  makeEntryID,
  useLastSeenEntryId,
  useValidWhatsNewEntries,
} from 'components/whats-new/hooks';
import { useCreateNotifications } from 'queries/notifications/mutations';
import { useUserID } from 'queries/session';
import { useUpdateStudentLabels } from 'queries/studentlabels';
import { useEffect } from 'react';

// useCreateWhatsNewNotifications creates notifications for any unseen whats new posts for which
// the user does not already have a notification
export const useCreateWhatsNewNotifications = (
  existingNotifications: Notification[] | undefined,
) => {
  const userID = useUserID();
  const whatsNewEntries = useValidWhatsNewEntries();
  const lastSeenId = useLastSeenEntryId();
  const lastSeenIndex = lastSeenId ? getLastSeenIndex(whatsNewEntries, lastSeenId) : -1;
  const { mutate: createNotifications } = useCreateNotifications();

  const { mutate: updateStudentLabels } = useUpdateStudentLabels();

  // for a new user, update the last seen entry id to this one, so they don't see any older than this
  useEffect(() => {
    if (!lastSeenId && whatsNewEntries.length > 0) {
      updateStudentLabels({
        [lastSeenWhatsNewEntryIDKey]: makeEntryID(whatsNewEntries[0]),
      });
    }
  }, [lastSeenId, updateStudentLabels, whatsNewEntries]);

  useEffect(() => {
    if (existingNotifications === undefined) {
      return;
    }
    if (lastSeenIndex <= 0) {
      return;
    }
    if (userID === '') {
      return;
    }

    const existingNotificationsMap: Record<string, Notification> = existingNotifications.reduce(
      (map, notification) => {
        map[notification.name] = notification;
        return map;
      },
      {} as Record<string, Notification>,
    );

    const notificationOwnerName = `notificationowners/${userID}`;

    const unseenEntries = whatsNewEntries.slice(0, lastSeenIndex);

    const expectedNotifications: Notification[] = unseenEntries
      .filter(entry => entry.notification !== undefined)
      .map(entry => {
        const notificationID = 'WhatsNew-' + entry.notification?.id;
        return {
          name: `${notificationOwnerName}/notifications/${notificationID}`,
          read: false,
          // show the notification for 60 days
          expiryTime: Timestamp.fromDate(new Date(Date.now() + 1000 * 60 * 60 * 24 * 60)),
        };
      });

    const notificationsToCreate = expectedNotifications.filter(
      notification => existingNotificationsMap[notification.name] === undefined,
    );
    if (notificationsToCreate.length > 0) {
      createNotifications(notificationsToCreate);
    }
  }, [whatsNewEntries, existingNotifications, createNotifications, userID, lastSeenIndex]);
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/search/v1/search.proto" (package "sparx.content.search.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.content.search.v1.Query
 */
export interface Query {
  /**
   * Query text
   *
   * @generated from protobuf field: string query = 1;
   */
  query: string;
  /**
   * Max. number of results to return (defaults to 10, maximum 100)
   *
   * @generated from protobuf field: int32 max_results = 2;
   */
  maxResults: number;
  /**
   * If true, do not populate the Matches field for each MatchingResource in
   * the Result.
   *
   * @generated from protobuf field: bool omit_matches = 3;
   */
  omitMatches: boolean;
  /**
   * List of filters to apply to the results
   *
   * @generated from protobuf field: sparx.content.search.v1.Filter filter = 4;
   */
  filter?: Filter;
}
/**
 * @generated from protobuf message sparx.content.search.v1.Filter
 */
export interface Filter {
  /**
   * Only return resources of this type
   *
   * @generated from protobuf field: string resource_type = 1;
   */
  resourceType: string;
  /**
   * Only return resources with this ancestor
   *
   * @generated from protobuf field: string ancestor_resource_name = 2;
   */
  ancestorResourceName: string;
  /**
   * Include resources that are deleted
   *
   * @generated from protobuf field: bool include_deleted = 3;
   */
  includeDeleted: boolean;
  /**
   * Only return resources with a score higher than this
   *
   * @generated from protobuf field: float min_score = 4;
   */
  minScore: number;
  /**
   * Only return resources with this tag.
   *
   * @generated from protobuf field: string tag = 5;
   */
  tag: string;
}
/**
 * @generated from protobuf message sparx.content.search.v1.Result
 */
export interface Result {
  /**
   * List of resources that match the query (ordered)
   *
   * @generated from protobuf field: repeated sparx.content.search.v1.MatchingResource matching_resources = 1;
   */
  matchingResources: MatchingResource[];
}
/**
 * @generated from protobuf message sparx.content.search.v1.MatchingResource
 */
export interface MatchingResource {
  /**
   * The name of the matching resource
   *
   * @generated from protobuf field: string resource_name = 1;
   */
  resourceName: string;
  /**
   * The title of the matching resource
   *
   * @generated from protobuf field: string resource_display_name = 2;
   */
  resourceDisplayName: string;
  /**
   * Overall score of the matching resource (depends on the matches found below)
   *
   * @generated from protobuf field: float score = 3;
   */
  score: number;
  /**
   * Matches found, can be on this resource or associated resources (e.g. a
   * curriculum strand associated with a topic)
   *
   * @generated from protobuf field: repeated sparx.content.search.v1.Match matches = 4;
   */
  matches: Match[];
}
/**
 * @generated from protobuf message sparx.content.search.v1.Match
 */
export interface Match {
  /**
   * The actual occurrence in the resource field that was found (e.g.
   * "Algebra")
   *
   * @generated from protobuf field: string occurrence = 1;
   */
  occurrence: string;
  /**
   * The name of the resource that matches
   *
   * @generated from protobuf field: string resource_name = 2;
   */
  resourceName: string;
  /**
   * The field containing the occurrence found
   *
   * @generated from protobuf field: string resource_field = 3;
   */
  resourceField: string;
  /**
   * The score (bigger for exact match, max. 1)
   *
   * @generated from protobuf field: float score = 4;
   */
  score: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Query$Type extends MessageType<Query> {
  constructor() {
    super('sparx.content.search.v1.Query', [
      { no: 1, name: 'query', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'max_results', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'omit_matches', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'filter', kind: 'message', T: () => Filter },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.search.v1.Query
 */
export const Query = new Query$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
  constructor() {
    super('sparx.content.search.v1.Filter', [
      {
        no: 1,
        name: 'resource_type',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'ancestor_resource_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'include_deleted',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 4, name: 'min_score', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 5, name: 'tag', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.search.v1.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Result$Type extends MessageType<Result> {
  constructor() {
    super('sparx.content.search.v1.Result', [
      {
        no: 1,
        name: 'matching_resources',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => MatchingResource,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.search.v1.Result
 */
export const Result = new Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchingResource$Type extends MessageType<MatchingResource> {
  constructor() {
    super('sparx.content.search.v1.MatchingResource', [
      {
        no: 1,
        name: 'resource_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'resource_display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'score', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 4,
        name: 'matches',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Match,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.search.v1.MatchingResource
 */
export const MatchingResource = new MatchingResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Match$Type extends MessageType<Match> {
  constructor() {
    super('sparx.content.search.v1.Match', [
      { no: 1, name: 'occurrence', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'resource_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'resource_field',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'score', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.search.v1.Match
 */
export const Match = new Match$Type();
/**
 * @generated ServiceType for protobuf service sparx.content.search.v1.Search
 */
export const Search = new ServiceType('sparx.content.search.v1.Search', [
  {
    name: 'Search',
    options: {
      'google.api.http': { get: '/v1/search' },
      'sparx.api.auth': {
        action: 'search',
        resource: 'content',
        domain: 'content',
      },
    },
    I: Query,
    O: Result,
  },
]);

import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { positionToAddon } from '../Medals/Medals';
import styles from './Leaderboard.module.css';
import { LeaderboardUserWithFullName } from './LeaderboardTable';

export const LeaderboardRow = ({
  user,
  currentUserPositiveNoun,
  navigate,
}: {
  user: LeaderboardUserWithFullName;
  currentUserPositiveNoun: string | undefined;
  navigate: (() => void) | undefined;
}) => {
  const {
    isCurrentUser,
    rank,
    xp,
    userDisplayData: leaderboardDisplayData,
    firstName,
    fullName,
  } = user;

  const currentUserRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (isCurrentUser && currentUserRef.current) {
      currentUserRef.current?.scrollIntoView({ block: 'center' });
    }
  }, [isCurrentUser]);

  const positiveNoun =
    isCurrentUser && currentUserPositiveNoun
      ? currentUserPositiveNoun
      : leaderboardDisplayData?.positiveNoun;

  const hasXP = xp > 0;
  const displayName = `${firstName} the ${positiveNoun}`;
  const primaryName = fullName || displayName;
  const secondaryName = fullName ? displayName : undefined;

  const showTrophy = hasXP && rank <= 3;

  return (
    <div
      className={classNames({
        [styles.Row]: true,
        [styles.RowYou]: isCurrentUser,
      })}
      ref={isCurrentUser ? currentUserRef : undefined}
      onClick={navigate}
    >
      <div
        className={classNames({
          [styles.RowInformation]: true,
          [styles.NoXP]: !hasXP && !isCurrentUser,
          [styles.Clickable]: navigate !== undefined,
        })}
      >
        <div
          className={classNames({ [styles.Position]: true, [styles.Numerical]: !showTrophy })}
          data-digits={rank ? rank.toString().length : 1}
        >
          {showTrophy ? (
            positionToAddon[rank]
          ) : (
            <span className={styles.PositionNumber}>{hasXP ? rank : '-'}</span>
          )}
        </div>
        <div className={styles.Name}>
          {primaryName}
          {secondaryName && (
            <>
              &nbsp;
              <span className={styles.SecondaryName}>({secondaryName})</span>
            </>
          )}
        </div>
        <div className={styles.Experience}>
          {xp.toLocaleString('en-GB')} <span className={styles.SRP}>XP</span>
        </div>
      </div>
    </div>
  );
};

import { Strand } from '@sparx/api/apis/sparx/content/v2/curriculum';
import algebraIcon from 'images/StrandIconAlgebra.svg';
import calculusIcon from 'images/StrandIconCalculus.svg';
import fundamentalsRatioIcon from 'images/StrandIconFundamentalsRatioProportion.svg';
import geometryIcon from 'images/StrandIconGeometry.svg';
import geometryPositionIcon from 'images/StrandIconGeometryPositionDirection.svg';
import matricesIcon from 'images/StrandIconMatrices.svg';
import measurementIcon from 'images/StrandIconMeasurement.svg';
import numberIcon from 'images/StrandIconNumber.svg';
import numberFractionsIcon from 'images/StrandIconNumberFractions.svg';
import numberPlaceValueIcon from 'images/StrandIconNumberPlaceValue.svg';
import probabilityIcon from 'images/StrandIconProbability.svg';
import ratioIcon from 'images/StrandIconRatioProportion.svg';
import statisticsIcon from 'images/StrandIconStatistics.svg';

type StrandMeta = {
  key: string; // For info only
  displayName?: string; // override the title.  TODO: remove
  icon?: string;
};

// Map a strand name or key to strand metadata, containing useful info to
// display the curriculum strand list in IL.  Eventually only the icons should
// be used.  ATM displayName is sometimes used to override the display name.
const strandMetadata: Record<string, StrandMeta> = {
  // General (KS3, GCSE, IGCSE)
  '2i4f1ycf9': {
    key: 'Number',
    icon: numberIcon,
  },
  c9eou4c2w: {
    key: 'Algebra',
    icon: algebraIcon,
  },
  eh6mqlz7d: {
    key: 'Ratio, Proportion and Rates of Change',
    displayName: 'Ratio and Proportion',
    icon: ratioIcon,
  },
  rs61mzz30: {
    key: 'Geometry',
    icon: geometryIcon,
  },
  '5lby08pch': {
    key: 'Probability',
    icon: probabilityIcon,
  },
  '8llw43gmm': {
    key: 'Statistics',
    icon: statisticsIcon,
  },
  // IGCSE special cases
  'curriculums/c9d3e71a-61ef-11ea-8f38-b7688b60ae65/strands/8llw43gmm': {
    key: 'Probability and Statistics',
    icon: probabilityIcon,
  },
  'curriculums/3d40dbc4-6392-11ea-a0aa-1fd9434aceb0/strands/5lby08pch': {
    key: 'Probability and Statistics',
    icon: probabilityIcon,
  },
  'curriculum/3ccf1dee-6451-11ea-8897-fb1f763ac88f/strands/8llw43gmm': {
    key: 'Probability and Statistics',
    icon: probabilityIcon,
  },
  'curriculums/afbad78e-647a-11ea-9cf4-97765456a8bc/strands/5lby08pch': {
    key: 'Probability and Statistics',
    icon: probabilityIcon,
  },
  // Fundamentals
  '9ciaa1cjl': {
    key: 'Number - Number and Place Value',
    icon: numberPlaceValueIcon,
  },
  '6tjh658a2': {
    key: 'Number - Addition, Subtraction, Multiplication and Division',
    icon: numberIcon,
  },
  dd2tnzcdt: {
    key: 'Number - Fractions',
    icon: numberFractionsIcon,
  },
  yjshej3tp: {
    key: 'Ratio and Proportion',
    icon: fundamentalsRatioIcon,
  },
  sppsoy6aw: {
    key: 'Algebra',
    icon: algebraIcon,
  },
  guxntx02v: {
    key: 'Measurement',
    icon: measurementIcon,
  },
  whtf4n367: {
    key: 'Geometry - Properties of Shape',
    icon: geometryIcon,
  },
  '30lyvwomm': {
    key: 'Geometry - Position and Direction',
    icon: geometryPositionIcon,
  },
  '5av90b79i': {
    key: 'Statistics',
    icon: statisticsIcon,
  },
  // Further Maths - shares Number, Algebra and Geometry with KS3/GCSE/IGCSE and adds some unique strands.
  '1o6n4wb1d': {
    key: 'Coordinate Geometry',
    // Same icon as "Geometry - Position and Direction".
    icon: geometryPositionIcon,
  },
  e8i9zsyik: {
    key: 'Calculus',
    icon: calculusIcon,
  },
  ui4ewdk5f: {
    key: 'Matrix Transformations',
    icon: matricesIcon,
  },
};

/**
 * Return the strand key from the strand name
 *
 * @param {string} strandName
 * @returns {(string | undefined)}
 */
const strandKey = (strandName: string): string | undefined => {
  const m = strandName.match(/.*\/strands\/([^/]+)$/);
  return m ? m[1] : undefined;
};

/**
 * Find the strand metadata associated with the given strand.
 *
 * At the moment the map above is used, eventually it could be
 * worked out from data contained in the strand itself.
 *
 * @param {string} strand
 * @returns {(StrandMeta | undefined)}
 */
export const getStrandMetadata = (strand?: Strand): StrandMeta | undefined => {
  if (!strand) {
    return undefined;
  }
  // First check the full name
  const meta = strandMetadata[strand.name];
  if (meta !== undefined) {
    return meta;
  }
  // Then just the strand key, as cloned curriculums share strand keys
  const key = strandKey(strand.name);
  return key !== undefined ? strandMetadata[key] : undefined;
};

import { Task } from '@sparx/api/sparxweb/swmsg/sparxweb';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { getHundredClubAvailability } from './100-club-access';
import { GameButtonGrid } from './game-button-grid/game-button-grid';
import {
  gameRequiresTokens,
  TimesTablesGameParameters,
  tokenCountIsSufficient,
  tokenRequirementForGame,
} from './game-parameters';
import { MultiplayerGameBanner } from './multiplayer-game-banner/multiplayer-game-banner';
import {
  getAbilityValidationAllows100Club,
  getAbilityValidationIsEnabled,
  getCompletedSpecialAssessments,
  getCurrentClubName,
  getDailyTokenCount,
  getHasCleared100ClubFirstPass,
  getHasClearedTaLRequirementsToday,
  getHasCompletedAllClubs,
  getHasPendingSpeedChallenge,
  getHasPendingTalkAndLearn,
  getPackageIdsWithPracticeDone,
  getTargets,
  getTodaysTokenProgress,
} from './progress';

export interface ITokenProgress {
  dailyTokenCount: number;
  requiredAnswersPerDay: number;
  totalAnsweredToday: number;
}

/*
  Used by the game-select screen to define which games should be available
  and what text to show in alerts when trying to access something locked
  (i.e. to tell the player why they were not allowed access)
 */
export interface IAvailabilityState {
  // true when the student should be able to access the special assessment
  useSpecialAssessment: boolean;
  // true when the task is one where a special assessment is part of the task, even if
  // the player doesn't need to do it (e.g. because they've already completed it)
  taskIsSpecialAssessment: boolean;

  hasCompletedSpecialAssessment: boolean;
  isSpecialAssessmentOnly: boolean;
  hasDoneSpecialAssessmentPractice: boolean;

  gamesAllowed: boolean;
  hundredClubAllowed?: boolean;
  hundredClubMessage?: string;
  gameLockedAdvice?: string;
  hundredClubLockedAdvice?: string;
}

/**
 * Returns the linear-gradient css string for a task item progress bar using a status with
 * percentComplete and complete.
 */
export const getFillGradientStyle = (
  totalAnswered: number,
  totalRequired: number,
  fillColour: string,
) => {
  const backgroundColor = `rgba(0,0,0,0%)`;
  const fillPercentage = Math.floor((100 * totalAnswered) / totalRequired) + '%';

  return (
    'linear-gradient(to right, ' +
    fillColour +
    ' 0%, ' +
    fillColour +
    ' ' +
    fillPercentage +
    ', ' +
    backgroundColor +
    ' ' +
    fillPercentage +
    ', ' +
    backgroundColor +
    ' 100%)'
  );
};

// The different kinds of primary tasks that can be set:
export const SPECIAL_ASSESSMENT_STATE = {
  // normal TT practice
  NONE: 'NONE',
  // a special assessment for Sparx to run trials comparing student ability
  ASSESSMENT_ONLY: 'ASSESSMENT_ONLY',
  // start with a special assessment and move on to normal TT practice
  // when the assessment has been done
  ASSESSMENT_PLUS_NORMAL_USE: 'ASSESSMENT_PLUS_NORMAL_USE',
};

export function sortGamesByTokens(
  gameIdA: string,
  gameIdB: string,
  usePrimaryUnlockRates: boolean,
) {
  const hasTokensA = gameRequiresTokens(gameIdA);
  const hasTokensB = gameRequiresTokens(gameIdB);

  // Prioritise any game that does not have a token requirement
  if (!hasTokensA) {
    return -1;
  }
  if (!hasTokensB) {
    return 1;
  }

  const requirementA = tokenRequirementForGame(gameIdA, usePrimaryUnlockRates);
  const requirementB = tokenRequirementForGame(gameIdB, usePrimaryUnlockRates);

  if (requirementA < requirementB) {
    return -1;
  } else {
    return 1;
  }
}

// Returns a dictionary of game grid DOM objects, keyed by the category of games
// (i.e. "games" and "exercises")
// The player will be able to click on the "Games" and "Exercises" tabs to see
// each of these grids on the game select screen
export function getGameGrids(
  gameIdsByCategory: Record<string, string[]>,
  availability: IAvailabilityState,
  targets: string[],
  onGameSelected: (gameId: string) => void,
  onAlertRequested: (gameId: string, message: string) => void,
  tokenCount: number,
  featureFlags: Record<string, number | string | boolean | undefined>,
  usePrimaryUnlockRates: boolean,
) {
  const grids: Record<string, JSX.Element> = {};
  const keys: string[] = Object.keys(gameIdsByCategory);
  for (const key of keys) {
    if (gameIdsByCategory[key].length > 0 && key !== 'multiplayer') {
      grids[key] = (
        <GameButtonGrid
          gameIds={gameIdsByCategory[key]}
          onSelectGame={gameId => availability.gamesAllowed && onGameSelected(gameId)}
          showLockedAlert={(gameId, message) => onAlertRequested(gameId || '', message || '')}
          currentTargetCount={targets.length}
          gamesAllowed={availability.gamesAllowed}
          featureFlags={featureFlags}
          selectedIndex={keys.indexOf(key)}
          tokenCount={tokenCount}
          usePrimaryUnlockRates={usePrimaryUnlockRates}
        />
      );
    }
  }
  return grids;
}

/*
  Returns the number of days between two JS Dates.
  Used to determine what kind of description to show next to the
  date in the multiplayer game text box.
  E.g. if the start date is <7 days away it begins "on Tuesday", but
       if the start date is >=7 days away it begins "on Tuesday 14th Jan"
 */
function daysBetween(startDate: Date, endDate: Date) {
  // The number of milliseconds in all UTC days
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours
  const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;
}

/*
  Takes the string provided by the feature flag discovery-upcoming-multiplayer-session-notification
  and returns a version of it with the dynamic components replaced with what's current.

  The feature flag body can contain dynamic components wrapped with @ symbols. Where it does,
  these can be replaced with information that may change day-to-day. For example:
    - "Play our game @DATE:2024-10-02@" will read:
      - "Play our game today" on 2nd Oct 2024
      - "Play our game tomorrow" on 1st Oct 2024
      - "Play our game on Wednesday" on any other day
 */
export function convertMultiplayerMessagingText(sourceText: string) {
  const components: string[] = sourceText.split('@');
  const convertedComponents: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    if (i % 2 == 0) {
      convertedComponents.push(component);
    } else {
      if (component.startsWith('DATE:')) {
        const dateText = component.substring('DATE:'.length);
        const sourceDate = new Date(dateText);
        const dateNow = new Date();
        const dateTomorrow = new Date();
        dateTomorrow.setDate(dateTomorrow.getDate() + 1);

        const isToday = dateNow.toDateString() === sourceDate.toDateString();
        const isTomorrow = dateTomorrow.toDateString() === sourceDate.toDateString();
        if (isToday) {
          convertedComponents.push('today');
        } else if (isTomorrow) {
          convertedComponents.push('tomorrow');
        } else {
          const dayOfWeek = sourceDate.toLocaleDateString('en-GB', { weekday: 'long' });
          convertedComponents.push(`on ${dayOfWeek}`);
        }
      }
    }
  }

  return convertedComponents.join(' ');
}

/*
  Returns true if the multiplayer game will be available at a point in the future.

  The available date is calculated from the feature flag `discovery-multiplayer-game-available-dates`
 */
export function canAccessMultiplayerGameInFuture(
  featureFlags: Record<string, number | string | boolean | undefined>,
) {
  const featureFlag = featureFlags['discovery-multiplayer-game-available-dates'] || '';
  const featureFlagAsString = typeof featureFlag === 'string' ? featureFlag : '';
  const availableDatesStrings = featureFlagAsString.split(',');

  // determine when multiplayer game availability starts
  let openingDate = new Date(0);
  if (availableDatesStrings.length > 1) {
    openingDate = new Date(availableDatesStrings[0]);
  }

  // detect if the above date is in the future
  const dateNow = new Date();
  return dateNow.toISOString() < openingDate.toISOString();
}

/*
  Returns a div containing the button to access the multiplayer game, with
  accompanying text that says when it will become available (or when it
  will be available until)

  The available date is calculated from the feature flag `discovery-multiplayer-game-available-dates`
 */
export function getMultiplayerGameAccessButton(
  onGameSelected: () => void,
  onAlertRequested: (gameId: string, message: string) => void,
  featureFlags: Record<string, number | string | boolean | undefined>,
) {
  const featureFlag = featureFlags['discovery-multiplayer-game-available-dates'] || '';
  const featureFlagAsString = typeof featureFlag === 'string' ? featureFlag : '';
  const availableDatesStrings = featureFlagAsString.split(',');
  const dateBounds = [new Date(0), new Date(0)];
  if (availableDatesStrings.length > 1) {
    dateBounds[0] = new Date(availableDatesStrings[0]);
    dateBounds[1] = new Date(availableDatesStrings[1]);
  }

  const dateBoundsAsMoments = [moment(dateBounds[0]), moment(dateBounds[1])];

  const dateNow = new Date();
  const startsToday = dateNow.toDateString() === dateBounds[0].toDateString();
  const endsToday = dateNow.toDateString() === dateBounds[1].toDateString();
  const daysBetweenNowAndStart = daysBetween(dateNow, dateBounds[0]);
  const startsThisWeek = daysBetweenNowAndStart < 7;
  const daysBetweenNowAndEnd = daysBetween(dateNow, dateBounds[1]);
  const endsThisWeek = daysBetweenNowAndEnd < 7;

  const startTimeWithoutMinutes = dateBoundsAsMoments[0].format('ha');
  const startTimeWithMinutes = dateBoundsAsMoments[0].format('h:mma');
  const startTimeString =
    startTimeWithMinutes.indexOf('00') !== -1 ? startTimeWithoutMinutes : startTimeWithMinutes;

  const endTimeWithoutMinutes = dateBoundsAsMoments[1].format('ha');
  const endTimeWithMinutes = dateBoundsAsMoments[1].format('h:mma');
  const endTimeString =
    endTimeWithMinutes.indexOf('00') !== -1 ? endTimeWithoutMinutes : endTimeWithMinutes;

  const startDayString = startsToday
    ? 'today'
    : startsThisWeek
      ? dateBoundsAsMoments[0].format('dddd')
      : dateBoundsAsMoments[0].format('dddd Do MMMM');

  const endDayString = endsToday
    ? 'today'
    : endsThisWeek
      ? dateBoundsAsMoments[1].format('dddd')
      : dateBoundsAsMoments[1].format('dddd Do MMMM');
  const endDayIsSameAsStartDay =
    dateBoundsAsMoments[0].format('dddd Do MMMM') === dateBoundsAsMoments[1].format('dddd Do MMMM');

  let attractString = '';
  let shouldBeAvailable = false;
  if (dateNow >= dateBounds[0] && dateNow <= dateBounds[1]) {
    attractString = `Join a multiplayer tournament before the game ends at ${endTimeString} ${endDayString}`;
    shouldBeAvailable = true;
  }
  if (dateNow < dateBounds[0]) {
    if (endDayIsSameAsStartDay) {
      attractString = `Try out our new multiplayer game from ${startTimeString} to ${endTimeString} ${endDayString}`;
    } else {
      attractString = `Try out our new multiplayer game from ${startTimeString} ${startDayString} to ${endTimeString} ${endDayString}`;
    }
  }
  if (dateNow > dateBounds[1]) {
    attractString = `The multiplayer game is now unavailable. Come again soon!`;
  }
  return (
    <div>
      <MultiplayerGameBanner
        allowAccess={shouldBeAvailable}
        supportingText={attractString}
        onClickGameButton={() => {
          if (shouldBeAvailable) {
            onGameSelected();
          } else {
            onAlertRequested(
              'MultiplayerTestGame',
              dateNow < dateBounds[0]
                ? 'The tournament is not open yet'
                : 'The tournament is now closed',
            );
          }
        }}
      />
    </div>
  );
}

/*
    Returns true if and only if there is a game, allowed in this school, which
        1) the student has enough tokens to play, and
        2) will spawn TaL questions
    Use this to prevent locking 100 Club if there is no way a student could
    empty their "pending TaL" list
    @param tokenCount - the number of tokens the player has
    @param gameIdList - the ids of the games allowed in this school
 */
export function playerCanDoPendingTaL(
  tokenCount: number,
  gameIdList: string[] | null,
  usePrimaryUnlockRates: boolean,
) {
  const gameKeys: string[] = gameIdList || Object.keys(TimesTablesGameParameters);
  return gameKeys.some(
    key =>
      TimesTablesGameParameters[key]?.usesPendingTaL &&
      tokenCountIsSufficient(key, tokenCount, usePrimaryUnlockRates),
  );
}

const asyncGetClubName = async (
  featureFlags: Record<string, number | string | boolean | undefined>,
  setCurrentClubName: (name: string | undefined) => void,
) => {
  const clubName = await getCurrentClubName(featureFlags);
  setCurrentClubName(clubName);
};

export function useCurrentClubName(
  featureFlags: Record<string, number | string | boolean | undefined>,
) {
  const [currentClubName, setCurrentClubName] = useState<string | undefined>(undefined);

  useEffect(() => {
    asyncGetClubName(featureFlags, setCurrentClubName);
  }, [featureFlags]);

  return [currentClubName];
}

const asyncGetTargets = async (
  featureFlags: Record<string, number | string | boolean | undefined>,
  setTargets: (targets: string[]) => void,
) => {
  const newTargets = await getTargets(featureFlags);
  setTargets(newTargets);
};

export function useTargets(featureFlags: Record<string, number | string | boolean | undefined>) {
  const [targets, setTargets] = useState<string[]>([]);

  useEffect(() => {
    asyncGetTargets(featureFlags, setTargets);
  }, [featureFlags]);

  return [targets];
}

/*
  Gets the current progress on daily tokens:
  - dailyTokenCount: number - how many tokens have been earned in total
  - requiredAnswersPerDay: number - how many questions must be answered correctly in a day to get a token
  - totalAnsweredToday: number - how many questions have been answered correctly today

  The more daily tokens have been earned, the more games will be available
 */
export function useDailyTokenProgresss() {
  const [dailyTokenProgress, setDailyTokenProgress] = useState<ITokenProgress | undefined>(
    undefined,
  );

  const asyncGetter = async () => {
    const dailyTokenCount = await getDailyTokenCount();
    const dailyTokenProgress = await getTodaysTokenProgress();
    setDailyTokenProgress({
      dailyTokenCount,
      requiredAnswersPerDay: dailyTokenProgress.required,
      totalAnsweredToday: dailyTokenProgress.answeredToday,
    });
  };

  useEffect(() => {
    asyncGetter();
  }, []);

  return [dailyTokenProgress];
}

/*
  Returns a list of games that should be allowed to be shown to this user.
  We can set 'discovery-enabled-games': '*' in the feature flags to allow all.
  The games will be sorted with the lowest token requirement first
 */
export function getEnabledGameList(
  featureFlags: Record<string, number | string | boolean | undefined>,
  usePrimaryUnlockRates: boolean,
) {
  const enabledGamesList =
    typeof featureFlags['discovery-enabled-games'] === 'string'
      ? `${featureFlags['discovery-enabled-games']}`.split(',')
      : [];
  const enabledAll = enabledGamesList.length === 0 || enabledGamesList.indexOf('*') !== -1;

  let expectedGames = Object.keys(TimesTablesGameParameters);
  // Filter out games that are not enabled:
  expectedGames = expectedGames.filter(game => enabledAll || enabledGamesList.indexOf(game) !== -1);
  const sortFunction = (gameIdA: string, gameIdB: string) =>
    sortGamesByTokens(gameIdA, gameIdB, usePrimaryUnlockRates);
  const sortedGames = expectedGames.sort(sortFunction);
  return sortedGames;
}

function getAllGamesAreEnabled(
  featureFlags: Record<string, number | string | boolean | undefined>,
  usePrimaryUnlockRates: boolean,
) {
  const enabledGamesList = getEnabledGameList(featureFlags, usePrimaryUnlockRates);
  return enabledGamesList.length === 0 || enabledGamesList.indexOf('*') !== -1;
}

function getSpecialAssessmentState(task: Task) {
  // special assessment cannot take place if this is the "no homeworks set yet"
  // holding screen used in primary. In this case, `tasks` will be undefined

  // TODO: replace the below with something that looks for a value
  //       being set, rather than checking the title text

  const specialAssessmentLabel = task.labels?.specialAssessment;

  // If the title contains "Assessment" then this is a special assessment
  if (specialAssessmentLabel === 'assessmentOnly') {
    return SPECIAL_ASSESSMENT_STATE.ASSESSMENT_ONLY;
  }

  if (specialAssessmentLabel === 'assessmentAndPractice') {
    return SPECIAL_ASSESSMENT_STATE.ASSESSMENT_PLUS_NORMAL_USE;
  }

  return SPECIAL_ASSESSMENT_STATE.NONE;
}

/*
  Gets the details that tell you whether games should be able to be accessed:
  - hundredClubAllowed: boolean - player should be able to do a 100 club quiz
  - gamesAllowed: boolean - player should be able to click on game tiles e.g. Sticker Collector
  - hundredClubMessage: string - text to show next to 100 Club (e.g. say why the player can/cannot
                                 access 100 Club)
  - gameLockedAdvice: string - text that should be shown to the player if they try to click on a game
                               when games are locked
  - hundredClubLockedAdvice: string - text that should be shown to the player if they try to click on a game
                                      when 100 Club is locked
  - useSpecialAssessment: boolean - when true, this is a special assessment task. The games should only be made
                                    available when the 100 Club assessment has been done.
  - hasCompletedSpecialAssessment: boolean - when true, the player has done their 100 Club special assessment
                                             and (if this is an "and Practice" task) be able to access the
                                             games and 100 Club as normal
  - isSpecialAssessmentOnly: boolean - when true, the player should only be able to access 100 Club in this
                                       task, and they should not be shown their token count or clubs
 */
async function getAvailability(
  featureFlags: Record<string, number | string | boolean | undefined>,
  usePrimaryUnlockRates: boolean,
  // If task is null we are on the no-task view. If it is undefined, it has not loaded yet
  task: Task | undefined | null,
  packageID: string | undefined,
) {
  if (task === undefined || !packageID) {
    return undefined;
  }

  const targets = await getTargets(featureFlags);
  const hasClearedFirstPass = await getHasCleared100ClubFirstPass(featureFlags);
  const hasPendingTalkAndLearn = await getHasPendingTalkAndLearn(featureFlags);
  const hasClearedTaLRequirementsToday = await getHasClearedTaLRequirementsToday(featureFlags);
  const hasCompletedAllClubs = await getHasCompletedAllClubs(featureFlags);
  const hasPendingSpeedChallenge = await getHasPendingSpeedChallenge(featureFlags);
  const tokenCount = await getDailyTokenCount();
  const allGamesAreEnabled = getAllGamesAreEnabled(featureFlags, usePrimaryUnlockRates);
  const enabledGamesList = getEnabledGameList(featureFlags, usePrimaryUnlockRates);
  const abilityValidationAllows100Club = await getAbilityValidationAllows100Club(featureFlags);
  const completedSpecialAssessments = await getCompletedSpecialAssessments();
  const packageIdsWithPracticeDone = await getPackageIdsWithPracticeDone();
  const abilityValidationIsEnabled = await getAbilityValidationIsEnabled(featureFlags);

  const specialAssessmentState = task
    ? getSpecialAssessmentState(task)
    : SPECIAL_ASSESSMENT_STATE.NONE;
  const taskIsSpecialAssessment = specialAssessmentState !== SPECIAL_ASSESSMENT_STATE.NONE;

  // Determine whether the student should be able to access games/100Club based on their current state,
  // and any supporting text that should show to explain why this choice was made
  const availabilityFromHundredClubState = getHundredClubAvailability(
    targets,
    hasClearedFirstPass,
    hasPendingTalkAndLearn,
    hasClearedTaLRequirementsToday,
    hasCompletedAllClubs,
    hasPendingSpeedChallenge,
    playerCanDoPendingTaL(
      tokenCount,
      allGamesAreEnabled ? null : enabledGamesList,
      usePrimaryUnlockRates,
    ),
    abilityValidationAllows100Club,
    abilityValidationIsEnabled,
  );
  const availability = {
    useSpecialAssessment: false,
    hasCompletedSpecialAssessment: false,
    isSpecialAssessmentOnly: false,
    taskIsSpecialAssessment,
    hasDoneSpecialAssessmentPractice: false,

    gamesAllowed: availabilityFromHundredClubState.gamesAllowed,
    hundredClubAllowed: availabilityFromHundredClubState.hundredClubAllowed,
    hundredClubMessage: availabilityFromHundredClubState.hundredClubMessage,
    gameLockedAdvice: availabilityFromHundredClubState.gameLockedAdvice || undefined,
    hundredClubLockedAdvice: availabilityFromHundredClubState.hundredClubLockedAdvice || undefined,
  };

  // In the case of a special assessment, set the availability settings for 100 Club and games
  // based on
  //  (1) Has the player finished the assessment?
  //  (2) Should the player be allowed to access the games after they have finished?
  if (taskIsSpecialAssessment) {
    const hasCompletedSpecialAssessment =
      completedSpecialAssessments && completedSpecialAssessments.indexOf(packageID) !== -1;
    availability.hasCompletedSpecialAssessment = hasCompletedSpecialAssessment;
    availability.hasDoneSpecialAssessmentPractice =
      packageIdsWithPracticeDone && packageIdsWithPracticeDone.indexOf(packageID) !== -1;

    if (specialAssessmentState === SPECIAL_ASSESSMENT_STATE.ASSESSMENT_ONLY) {
      availability.hundredClubAllowed = !hasCompletedSpecialAssessment;
      availability.hundredClubMessage = availability.hundredClubAllowed
        ? 'You must do the 100 Club Check to complete this task'
        : 'You have completed this task';
      availability.useSpecialAssessment = true;
      availability.isSpecialAssessmentOnly = true;
    }
    if (specialAssessmentState === SPECIAL_ASSESSMENT_STATE.ASSESSMENT_PLUS_NORMAL_USE) {
      if (!hasCompletedSpecialAssessment) {
        availability.hundredClubAllowed = true;
        availability.hundredClubMessage =
          'You must do the 100 Club Check before you can play the games';
        availability.useSpecialAssessment = true;
      }
    }
  }
  if (featureFlags['sparxweb-selenium-mode']) {
    availability.hundredClubAllowed = true;
    availability.gamesAllowed = true;
    availability.hundredClubMessage = 'SELENIUM MODE';
  }

  return availability;
}

const asyncGetAvailability = async (
  task: Task | null | undefined,
  packageID: string,
  featureFlags: Record<string, number | string | boolean | undefined>,
  usePrimaryUnlockRates: boolean,
  setAvailability: (availability: IAvailabilityState | undefined) => void,
) => {
  const returnedAvailability = await getAvailability(
    featureFlags,
    usePrimaryUnlockRates,
    task,
    packageID,
  );
  setAvailability(returnedAvailability);
};

/*
  A hook to get whether or not the student should be able to access 100 Club and/or games,
  by doing async calls for their times tables progress
 */
export function useHundredClubAvailability(
  // If task is null we are on the no-task view. If it is undefined, it has not loaded yet
  task: Task | undefined | null,
  packageID: string,
  featureFlags: Record<string, number | string | boolean | undefined>,
  usePrimaryUnlockRates: boolean,
) {
  const [availability, setAvailability] = useState<IAvailabilityState | undefined>(undefined);

  useEffect(() => {
    asyncGetAvailability(task, packageID, featureFlags, usePrimaryUnlockRates, setAvailability);
  }, [task, packageID, featureFlags]);

  return [availability];
}

import { TaskCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Chip, ChipColourVariant, ChipStyleVariant } from '@sparx/sparx-design/components/Chip';
import { ChevronRight, Tick } from '@sparx/sparx-design/icons';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { swclient2Event } from 'utils/analytics';
import { getPkgOrTaskCompletionPercentage, isPkgOrTaskComplete } from 'utils/package';
import { makeTaskPath, makeTimesTablesTaskPath, makeVideoTaskPath } from 'utils/paths';
import { isTimesTablesTask, isVideoTask } from 'utils/taskTypes';

import styles from './Task.module.css';
interface TaskProps {
  task: TaskCompletion;
  packageID: string;
  isSmallScreen: boolean;
  sendEvent: (event: swclient2Event) => void;
}

/**
 * One of the tasks in a package shown after the package is opened
 * @param task
 * @param packageID
 * @param isSmallScreen
 * @param sendEvent
 * @constructor
 */
export const Task = ({ task, packageID, isSmallScreen, sendEvent }: TaskProps) => {
  const taskCompletionPercentage = getPkgOrTaskCompletionPercentage(task);
  const taskCompleted = isPkgOrTaskComplete(task);
  const taskStarted = 0 < taskCompletionPercentage && taskCompletionPercentage < 100;

  let taskChipText = 'Start';
  let taskChipColour: ChipColourVariant = 'Interactable';
  let taskChipStyle: ChipStyleVariant = 'Filled';
  let taskChipIcon = <ChevronRight className={classNames(styles.TaskChipIcon, styles.Chevron)} />;
  if (taskStarted) {
    taskChipText = 'Continue';
  } else if (taskCompleted) {
    taskChipText = 'Complete';
    taskChipStyle = 'Outlined';
    taskChipColour = 'Complete';
    taskChipIcon = (
      <Tick variant="DarkGreen" className={classNames(styles.TaskChipIcon, styles.Tick)} />
    );
  }

  const getTaskPath = () => {
    if (isTimesTablesTask(task)) {
      return makeTimesTablesTaskPath(packageID, task.taskIndex);
    }
    if (isVideoTask(task)) {
      return makeVideoTaskPath('', packageID, task.taskIndex);
    }
    return makeTaskPath('', packageID, task.taskIndex);
  };
  const taskPath = getTaskPath();

  const Content = () => (
    <>
      <div className={styles.TaskLeft}>
        <div className={styles.TaskTitle}>
          <TextWithMaths text={task.title} />
        </div>

        <div
          className={classNames(styles.TaskCompletionPercentage, {
            [styles.Started]: taskStarted,
            [styles.Completed]: taskCompleted,
          })}
        >
          {taskCompletionPercentage}%
        </div>
      </div>
      <div className={styles.TaskRight}>
        <Chip
          className={styles.TaskChip}
          styleVariant={taskChipStyle}
          colourVariant={taskChipColour}
          size={isSmallScreen ? 'sm' : 'md'}
        >
          {taskChipText}
          {taskChipIcon}
        </Chip>
      </div>
    </>
  );

  const isTaskLocked = isPkgOrTaskComplete(task) && !isTimesTablesTask(task) && !isVideoTask(task);

  return isTaskLocked ? (
    <div className={classNames(styles.Task, accessibilityStyles.FocusTarget)}>
      <Content />
    </div>
  ) : (
    <Link
      className={classNames(styles.Task, styles.TaskClickable, accessibilityStyles.FocusTarget)}
      to={taskPath}
      key={task.taskIndex}
      onClick={() =>
        sendEvent({
          action: 'clicked task',
          category: 'package list',
        })
      }
      tabIndex={0}
    >
      <Content />
    </Link>
  );
};

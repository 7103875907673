import { PackageCompletion as Package } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { getPkgOrTaskCompletionPercentage, sortPackages } from 'utils/package';
import {
  getEquivalentHomeworkPackageType,
  isNonTrainingHomeworkPackageType,
  isTrainingPackageType,
} from 'utils/training';

/**
 * Returns the linear-gradient css string for a task item progress bar using a status with
 * percentComplete and complete.
 */
export const getFillGradientStyle = (
  totalAnswered: number,
  totalRequired: number,
  fillColour: string,
) => {
  const backgroundColor = `rgba(0,0,0,0%)`;
  const fillPercentage = Math.floor((100 * totalAnswered) / totalRequired) + '%';

  return (
    'linear-gradient(to right, ' +
    fillColour +
    ' 0%, ' +
    fillColour +
    ' ' +
    fillPercentage +
    ', ' +
    backgroundColor +
    ' ' +
    fillPercentage +
    ', ' +
    backgroundColor +
    ' 100%)'
  );
};

export const getFilteredSortedPackages = (
  allPackages: Package[] | undefined,
  selectedPackageType: string,
  showOnboardingPackages: boolean,
  onboardingLockEnabled: boolean,
  trainingEnabled: boolean,
) => {
  /**
   * Returns whether a package type matches the type in a package filter. If training is enabled, the training packages
   * will match the filters for the normal homework types, whereas normal homework packages will be filtered out
   */
  const packageTypeMatchesFilter = (pkgType: string, filterPkgType: string) => {
    if (trainingEnabled) {
      if (isTrainingPackageType(pkgType)) {
        return getEquivalentHomeworkPackageType(pkgType) === filterPkgType;
      } else if (isNonTrainingHomeworkPackageType(pkgType)) {
        return false;
      }
    }
    return pkgType === filterPkgType;
  };

  if (!allPackages) {
    return [];
  }

  const result = allPackages
    .filter(p => packageTypeMatchesFilter(p.packageType, selectedPackageType))
    .sort(sortPackages);

  if (!showOnboardingPackages) {
    return result;
  }

  const onboardingPackage = allPackages.find(p => p.packageType === 'onboarding');
  if (!onboardingPackage) {
    return result;
  }

  const hasInProgressPackages = result.some(
    p => getPkgOrTaskCompletionPercentage(p) > 0 || p.hasBeenComplete,
  );

  if (!onboardingLockEnabled && (hasInProgressPackages || onboardingPackage.hasBeenComplete)) {
    result.push(onboardingPackage);
  } else {
    result.unshift(onboardingPackage);
  }

  return result;
};

export function packageIdIsLocked(packageId: string, packages: Package[]) {
  const activePackageData = getActivePackageData(packages);

  for (const lockedPkg of activePackageData.lockedPackages) {
    if (lockedPkg.packageID === packageId) {
      return true;
    }
  }
  return false;
}

/*
  Determine settings for what packages should show as available
    - Packages that end this week, or up to two days ago, are priority packages
      These are the packages that are this week's homework, or last week's homework if you're in
      a two-day grace period to finish it late.
    - When a package is completed it is locked, unless it's the most recent package, which is always
      unlocked.
    - If any of the priority packages are incomplete, all other packages should be locked.
    - If all of the priority packages are complete, all other incomplete packages become unlocked.
 */
export function getActivePackageData(packages: Package[]) {
  // TODO: filter out non-TT quizzes - otherwise there is a risk that a user could
  //       be locked out of TT use a quiz to do that cannot be completed.
  //       This mechanic currently assumes Primary pupils *only* get TT tasks

  // Sort the packageIDs by start date then map to packages
  const sortedPackages = packages.sort((pkgA: Package, pkgB: Package) => {
    return (pkgA.startDate?.seconds || 0) < (pkgB.startDate?.seconds || 0) ? 1 : -1;
  });

  // Calculate the date the current week started on
  // We will compare the end-dates of the packages, and those that end
  // this week will be available to play if incomplete
  const currentDate = new Date();
  // Calculate the date of the most recent Sunday, or two days ago (whichever is earlier)
  const dayOfMonthOfWeekStart = currentDate.getDate() - Math.max(2, currentDate.getDay());
  const dateOfWeekStartWithHours = new Date(currentDate.setDate(dayOfMonthOfWeekStart));
  const dateOfWeekStart = new Date(dateOfWeekStartWithHours.setHours(0, 0, 0));

  // Given a package ID, determine if that package ends this week or two days ago
  const packageIsThisWeek = (pkg: Package) => {
    const endDateText = (pkg.endDate?.seconds || 0) * 1000;
    if (endDateText) {
      const endDate = new Date(endDateText);
      return endDate > dateOfWeekStart;
    } else {
      return false;
    }
  };

  // Given a package ID, return true if the student has completed that package
  const packageIsComplete = (pkg: Package) => {
    return (pkg.completion?.progress['C'] || 0) >= (pkg.completion?.size || 100);
  };

  // priorityPackages is the set of packages due this week, two days ago, or in the future
  const priorityPackages = sortedPackages.filter((pkg: Package) => packageIsThisWeek(pkg));
  const completePriorityPackages = priorityPackages.filter((pkg: Package) =>
    packageIsComplete(pkg),
  );
  // True if and only if all packages due after the beginning of this week are complete
  // If this is true, old incomplete homeworks will be unlocked
  const currentWeekComplete = completePriorityPackages.length === priorityPackages.length;

  // get the ID of the most recent homework which is currently in-date.
  // If none exists, get the ID of the most recent homework
  // Daily tokens earned will be allocated to this homework.
  const packageIsActiveToday = (pkg: Package) => {
    const endDateText = (pkg.endDate?.seconds || 0) * 1000;
    const startDateText = (pkg.startDate?.seconds || 0) * 1000;
    if (endDateText && startDateText) {
      const startDate = new Date(startDateText).getTime();
      const endDate = new Date(endDateText).getTime();
      const now = new Date().getTime();
      return startDate <= now && now <= endDate;
    } else {
      return false;
    }
  };
  // because pkgs is sorted by date we know index 0 is the most recent
  // package
  const packagesActiveToday = sortedPackages.filter((pkg: Package) => packageIsActiveToday(pkg));
  const activePackage =
    packagesActiveToday.length > 0
      ? packagesActiveToday[0]
      : sortedPackages.length > 0
        ? sortedPackages[0]
        : null;
  // Determine which homework this package belongs to
  // Daily tokens earned will be allocated to this homework.

  // When daily tokens are earned they are flagged with data connecting them to the most recent
  // package. This way the tpclient2 client can show teachers (on the hand-in screen) how many
  // tokens have been earned during a given homework period.
  let midPointDate = null;
  if (activePackage?.startDate && activePackage?.endDate) {
    // The data we use to flag them is the date midway between the startpoint and endpoint of the
    // most recent package. The tp2 hand-in screen will show a token as counting towards daily token
    // count for a given homework if this date lies between its own startpoint and endpoint.
    const newMillieconds =
      Math.floor((activePackage?.startDate.seconds + activePackage?.endDate.seconds) / 2) * 1000;
    midPointDate = new Date(newMillieconds).toISOString();
  }
  const dailyTokenHomeworkIdentifier = midPointDate || 'no-homework';

  const unlockedPackages = sortedPackages.filter((pkg: Package) => {
    const currentState = {
      activeToday: packageIsActiveToday(pkg),
      incompleteAndOkayed: !packageIsComplete(pkg) && currentWeekComplete,
      first: sortedPackages.indexOf(pkg) === 0,
    };
    return currentState.activeToday || currentState.incompleteAndOkayed || currentState.first;
  });
  const lockedPackages = sortedPackages.filter(
    (pkg: Package) => unlockedPackages.indexOf(pkg) === -1,
  );

  return {
    currentWeekComplete,
    activePackage,
    dailyTokenHomeworkIdentifier,
    sortedPackages,
    unlockedPackages,
    lockedPackages,
  };
}

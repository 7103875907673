import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { createContext, PropsWithChildren } from 'react';

export const PackageContext = createContext<PackageCompletion | undefined>(undefined);

/**
 * Provides a package context for accessing information about the current package.
 */
export const PackageProvider = ({
  pkg,
  children,
}: PropsWithChildren<{ pkg: PackageCompletion }>) => {
  return <PackageContext.Provider value={pkg}>{children}</PackageContext.Provider>;
};

// for the alert modal
import * as Dialog from '@radix-ui/react-dialog';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

import styles from './AlertModal.module.css';

interface IAlertModalProps {
  gameTitle: string;
  messages: string[];
  targets: string[] | undefined;
  shouldShow: boolean;
  onClick: () => void;
}

export const AlertModal: FunctionComponent<IAlertModalProps> = ({
  gameTitle,
  messages,
  targets,
  shouldShow,
  onClick,
}) => {
  const clickCallback = () => {
    if (shouldShow) {
      onClick();
    }
  };

  const messageDivs = [];
  for (const message of messages) {
    const boldComponents = message.split('*');
    let shouldBeBold = message.startsWith('*');
    const spans = [];
    for (let i = 0; i < boldComponents.length; i++) {
      const component = boldComponents[i];
      spans.push(
        <span className={shouldBeBold ? styles.BoldText : ''} key={`boldComponent_${i}`}>
          {component}
        </span>,
      );
      shouldBeBold = !shouldBeBold;
    }
    messageDivs.push(
      <div className={styles.AlertBody} key={`line_${messages.indexOf(message)}`}>
        {spans}
      </div>,
    );
  }

  const targetDivs = targets?.map(target => {
    return (
      <div className={styles.TimesTablesTarget} key={target}>
        {target.replaceAll(' ', '\xa0')}
      </div>
    );
  });

  return (
    <Dialog.Root open={shouldShow} modal={true}>
      <Dialog.Portal>
        <Dialog.Overlay className={dialogStyles.DialogOverlay} onClick={clickCallback} />
        <Dialog.Content
          className={classnames(dialogStyles.DialogContent, dialogStyles.ContentHeight)}
        >
          <Dialog.Title className={classnames(dialogStyles.DialogTitle, styles.AlertHeading)}>
            {gameTitle}
          </Dialog.Title>
          {messageDivs}
          {targets && <div className={styles.TimesTablesTargetHolder}>{targetDivs}</div>}
          <div className={styles.AlertButtonHolder}>
            <span className={styles.AlertButton} onClick={clickCallback}>
              Continue
            </span>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { TaskItemCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import {
  Activity,
  ActivityStatus,
  ActivityType,
  GapEvaluation,
  QuestionActivity,
  TetherQuestion,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { checkInput, IInput, onlyKeepCorrectAnswers, useLayoutSteps } from '@sparx/question';
import { useLandscapeModal } from 'context/landscapemodal';
import { AnimatePresence } from 'framer-motion';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { isWACError } from 'utils/errors';
import { useFeatureFlags } from 'utils/feature-flags';
import { getLQDPath, makeTaskItemWACPath, useLQDPath } from 'utils/paths';
import {
  getPlaceholdersFromInput,
  getRefsInParts,
  useFillFirstChancePartRefsCorrectRef,
} from 'views/answer/utils';
import { CorrectQuestionOverlay } from 'views/correct-question-overlay/CorrectQuestionOverlay';
import { ErrorPage } from 'views/error/ErrorPage';
import { QuestionAndAnswer } from 'views/question-and-answer/QuestionAndAnswer';

import {
  activityDebug,
  buildAnswerAction,
  buildDismissAction,
  buildViewAction,
  useActivityAction,
  useNewActivity,
} from '../../queries/activity';
import { Answer } from '../answer/Answer';
import { Question, QuestionLoading } from '../question/Question';
import styles from './Activity.module.css';

interface IActivityDisplayProps {
  packageID: string;
  taskIndex: number;
  taskItemIndex: number;
  packageType: string;

  // Props to pass down to result component:

  // Whether the current task item is the last one
  isLastItem: boolean;

  // Whether the current task is complete
  isTaskComplete: boolean;

  // URL to get to the current task item
  currentTaskItemPath: string;

  // URL to get to the next task item, or the summary page if on the last task item
  nextTaskItemPath: string;

  // URL to get to the previous task item, undefined if on the first task item, or summary page
  previousTaskItemPath: string | undefined;

  // Whether the current item is on a second / third (etc) chance
  isItemOnNonInitialChance: boolean;

  // Whether the current task item is on its final chance
  isTaskItemOnFinalChance: boolean;

  // Whether the current task item has ever been attempted
  isItemAttempted: boolean;

  // Whether the current task item has been swapped
  isItemSwapped: boolean;

  // Whether to show the correct question overlay rather than render the question
  showCorrectQuestionOverlay: boolean;

  // Whether to hide the bookwork code for questions (they are hidden when doing assseesment fix-up)
  hideBookworkCodes: boolean;

  // The number of swaps used in the current package
  numSwapsUsed: number;

  // The current task item
  currentTaskItem?: TaskItemCompletion;
}

type Page = 'question' | 'answer';

export const ActivityDisplay = ({
  packageID,
  taskIndex,
  taskItemIndex,
  packageType,
  isLastItem,
  isTaskComplete,
  nextTaskItemPath,
  currentTaskItemPath,
  previousTaskItemPath,
  isItemOnNonInitialChance,
  isTaskItemOnFinalChance,
  isItemAttempted,
  isItemSwapped,
  showCorrectQuestionOverlay,
  hideBookworkCodes,
  numSwapsUsed,
  currentTaskItem,
}: IActivityDisplayProps) => {
  const { parentPath } = useLQDPath();
  const featureFlags = useFeatureFlags();
  const multiPartMarkingFlagEnabled = featureFlags.getBooleanFlag('sparxweb2-enable-mpa', false);

  // Refs needed for multi-part marking and second-chance prefilling. Defined at this
  // level so they persist across mounts of the ActivityDisplayImpl component. (But
  // not across different task items, as this component is unmounted then.)
  // Excuse their dumb names, I wanted to make it clear that they're refs.

  // Ref to the gap evaluations from the first chance attempt.
  const firstChanceGapEvaluationsRef = useRef<Record<string, GapEvaluation> | undefined>(undefined);
  // Ref to the input from the first chance attempt. Used to prefill the second-chance.
  const firstChanceInputRef = useRef<IInput | undefined>(undefined);
  // Set of (content) refs for gaps that were in a correct part on the first-chance attempt.
  // Used to filter out incorrect parts from the stored first-chance input,
  // to prefill the second-chance.
  const firstChancePartRefsCorrectRef = useRef(new Set<string>());

  const {
    mutate: getNewActivity,
    data: activity,
    isLoading,
    isError,
    error,
  } = useNewActivity(ActivityType.QUESTION, packageID, taskIndex, taskItemIndex);

  // Track the initial load so we can render the loading spinner before loading has started. Without
  // doing this, the initial render can briefly show the error page before the loading has started.
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // when packageID, taskIndex or taskItemIndex changes, get a new activity
  useEffect(() => {
    if (packageID && taskIndex && taskItemIndex && taskItemIndex >= 0) {
      getNewActivity();
      setInitialLoadComplete(true);
    }
  }, [getNewActivity, packageID, taskIndex, taskItemIndex]);

  if (isError) {
    if (isWACError(error)) {
      return <Navigate to={makeTaskItemWACPath(parentPath, packageID, taskIndex, taskItemIndex)} />;
    }
    return <p>Error: {String(error)}</p>;
  }
  if (
    !initialLoadComplete ||
    isLoading ||
    !packageID ||
    !taskIndex ||
    !taskItemIndex ||
    taskItemIndex === 0
  ) {
    return <QuestionLoading />;
  }

  if (activity?.payload.oneofKind !== 'question') {
    return <ErrorPage withCard />;
  }

  if (showCorrectQuestionOverlay) {
    return (
      <CorrectQuestionOverlay
        packageID={packageID}
        taskIndex={taskIndex}
        taskItemIndex={taskItemIndex}
        packageType={packageType}
        nextTaskItemPath={nextTaskItemPath}
        isTaskComplete={isTaskComplete}
      />
    );
  }

  return (
    <ActivityDisplayImpl
      packageID={packageID}
      taskIndex={taskIndex}
      taskItemIndex={taskItemIndex}
      key={activity.activityIndex}
      activity={activity}
      question={activity.payload.question}
      isLastItem={isLastItem}
      isTaskComplete={isTaskComplete}
      nextTaskItemPath={nextTaskItemPath}
      currentTaskItemPath={currentTaskItemPath}
      previousTaskItemPath={previousTaskItemPath}
      isItemOnNonInitialChance={isItemOnNonInitialChance}
      isTaskItemOnFinalChance={isTaskItemOnFinalChance}
      retryTask={getNewActivity}
      isItemAttempted={isItemAttempted}
      isItemSwapped={isItemSwapped}
      hideBookworkCodes={hideBookworkCodes}
      firstChanceGapEvaluationsRef={
        multiPartMarkingFlagEnabled ? firstChanceGapEvaluationsRef : undefined
      }
      firstChanceInputRef={multiPartMarkingFlagEnabled ? firstChanceInputRef : undefined}
      firstChancePartRefsCorrectRef={
        multiPartMarkingFlagEnabled ? firstChancePartRefsCorrectRef : undefined
      }
      numSwapsUsed={numSwapsUsed}
      currentTaskItem={currentTaskItem}
    />
  );
};

export const TetheringActivityDisplay: FunctionComponent<{
  tetherId: string;
  tetherData: TetherQuestion;
  retryTask: () => void;
}> = ({ tetherId, tetherData, retryTask }) => {
  const activity: Activity = useMemo(
    () => ({
      activityIndex: tetherData.activityIndex,
      activityType: ActivityType.QUESTION,
      payload: {
        oneofKind: 'question',
        question: {
          questionIndex: tetherData.questionIndex,
          questionSpec: tetherData.questionSpec,
          bookworkCode: '---',
          videoID: '',
          displayWacTutorial: false,
          skillID: tetherData.skillID,
          questionID: tetherData.questionID,
          multiPartMarking: tetherData.multiPartMarking,
        },
      },
      status: ActivityStatus.ACTIVE,
    }),
    [tetherData],
  );

  if (activity.payload.oneofKind !== 'question') {
    return <p>Unknown activity type: {activity.payload.oneofKind}</p>;
  }

  return (
    <ActivityDisplayImpl
      packageID=""
      taskIndex={0}
      taskItemIndex={0}
      // This is important as otherwise the component will keep the same state from the previous render
      key={tetherId}
      activity={activity}
      question={activity.payload.question}
      isLastItem={false}
      isTaskComplete={false}
      currentTaskItemPath=""
      nextTaskItemPath=""
      isItemOnNonInitialChance={false}
      isTaskItemOnFinalChance={false}
      retryTask={retryTask}
      isItemAttempted={false}
      isItemSwapped={false}
      hideBookworkCodes={false}
      numSwapsUsed={0}
    />
  );
};

const ActivityDisplayImpl = ({
  packageID,
  taskIndex,
  taskItemIndex,
  activity,
  question,
  isLastItem,
  isTaskComplete,
  nextTaskItemPath,
  currentTaskItemPath,
  previousTaskItemPath,
  isItemOnNonInitialChance,
  isTaskItemOnFinalChance,
  retryTask,
  isItemAttempted,
  isItemSwapped,
  hideBookworkCodes,
  firstChanceGapEvaluationsRef,
  firstChanceInputRef,
  firstChancePartRefsCorrectRef,
  numSwapsUsed,
  currentTaskItem,
}: {
  packageID: string;
  taskIndex: number;
  taskItemIndex: number;
  activity: Activity;
  question: QuestionActivity;
  isLastItem: boolean;
  isTaskComplete: boolean;
  currentTaskItemPath: string;
  nextTaskItemPath: string;
  previousTaskItemPath?: string;
  isItemOnNonInitialChance: boolean;
  isTaskItemOnFinalChance: boolean;
  retryTask: () => void;
  isItemAttempted: boolean;
  isItemSwapped: boolean;
  hideBookworkCodes: boolean;
  firstChanceGapEvaluationsRef?: React.MutableRefObject<Record<string, GapEvaluation> | undefined>;
  firstChanceInputRef?: React.MutableRefObject<IInput | undefined>;
  firstChancePartRefsCorrectRef?: React.MutableRefObject<Set<string>>;
  numSwapsUsed: number;
  currentTaskItem?: TaskItemCompletion;
}) => {
  const featureFlags = useFeatureFlags();
  const showCombinedQuestionAnswer = featureFlags.getBooleanFlag(
    'sparxweb2-use-combined-question-and-answer',
    false,
  );

  const [page, setPage] = useState<Page>('question');
  const layout = useLayoutSteps(question.questionSpec)[0];

  // parse the part refs from the layout
  const partRefs = useMemo(() => {
    return getRefsInParts(layout?.layout);
  }, [layout?.layout]);

  useEffect(() => {
    // Check if we're in second chance, and if not, clear the previous
    // correctPartRefs and gap evaluations
    if (
      !isItemOnNonInitialChance &&
      firstChancePartRefsCorrectRef &&
      firstChanceGapEvaluationsRef &&
      firstChanceInputRef
    ) {
      firstChancePartRefsCorrectRef.current = new Set<string>();
      firstChanceGapEvaluationsRef.current = undefined;
      firstChanceInputRef.current = undefined;
    }
  }, [
    activity,
    firstChancePartRefsCorrectRef,
    firstChanceGapEvaluationsRef,
    isItemOnNonInitialChance,
    firstChanceInputRef,
  ]);

  // If, on mount, we are in second-chance, and we have a first chance input and gap evaluations,
  // remove the incorrect parts from the input and use the remaining correct parts to prefill
  // the second-chance input (otherwise we use the default input)
  const [input, setInput] = useState(
    (isItemOnNonInitialChance &&
      firstChanceInputRef?.current &&
      firstChancePartRefsCorrectRef?.current &&
      onlyKeepCorrectAnswers(firstChanceInputRef.current, firstChancePartRefsCorrectRef.current)) ||
      layout?.input,
  );

  const [placeHolders, setPlaceHolders] = useState<Dictionary<string, string> | undefined>(
    undefined,
  );

  // create activity mutations
  const { mutate: viewActivity } = useActivityAction({ useErrorBoundary: true });
  const { mutate: dismissActivity } = useActivityAction({ useErrorBoundary: true });
  const {
    data: submitAnswerData,
    isLoading: isSubmittingAnswer,
    isSuccess: isAnswerSubmitted,
    mutate: submitAnswerActivity,
  } = useActivityAction({
    onSuccess: r => {
      // for first attempt, store the input so we we can use it for prefilling second chance
      // and gap evaluations so we can use them for multi-part marking
      if (
        !isTaskItemOnFinalChance &&
        input &&
        firstChanceInputRef &&
        firstChanceGapEvaluationsRef
      ) {
        firstChanceInputRef.current = input;
        firstChanceGapEvaluationsRef.current = r.response?.gapEvaluations;
      }

      // hintGivenAndActive is true if a hint has been given for the activity, and its status is still
      // active, so it is ready for another submission on the same activity, and we should show the
      // hint ui elements.
      const hintGivenAndActive = r?.response?.hints && r.response.status === 'ACTIVE';

      // if we have been given a hint, then work out the placeholders from the current input, and then
      // reset it back to empty
      // todo: this won't play nicely with multipart answering, as it will clear all the inputs, but we
      // aren't supporting multipart hints at the moment.
      if (input && hintGivenAndActive) {
        setPlaceHolders(getPlaceholdersFromInput(input, r.response?.hints));
        setInput(layout?.input);
      }
    },
  });

  const isSubmittingAnswerRef = useRef(false);
  isSubmittingAnswerRef.current = isSubmittingAnswer;
  const isAnswerSubmittedRef = useRef(false);
  isAnswerSubmittedRef.current = isAnswerSubmitted;

  // call view activity on mount. The activity will be dismissed when a new one is loaded, or
  // on dismount if we are loading a non-question url
  useEffect(() => {
    console.log(activityDebug(activity.activityType, 'MUTATE to VIEW', activity.status));
    viewActivity(buildViewAction(activity, question));
    return () => {
      const { isLQDPath, isSummary } = getLQDPath(window.location.pathname);
      if (isSummary || !isLQDPath) {
        if (!isSubmittingAnswerRef.current && !isAnswerSubmittedRef.current) {
          console.log(activityDebug(activity.activityType, 'MUTATE to DISMISS', activity.status));
          dismissActivity(buildDismissAction(activity, question));
        }
      }
    };
  }, [activity, question, viewActivity, dismissActivity]);

  const [submitError, setSubmitError] = useState(false);

  const inputValid = checkInput(input);

  const submitAnswer = useCallback(() => {
    if (!input) {
      return;
    }
    console.log(activityDebug(activity.activityType, 'MUTATE to ANSWER', activity.status));
    submitAnswerActivity(buildAnswerAction(input, activity, question), {
      onError: () => setSubmitError(true),
    });
  }, [input, activity, submitAnswerActivity, question]);

  const hintGivenAndActive =
    submitAnswerData?.response?.hints && submitAnswerData.response.status === 'ACTIVE';

  const enableSubmitAnswer =
    !submitError && inputValid && !isSubmittingAnswer && (!isAnswerSubmitted || hintGivenAndActive);

  // fill the first chance part refs correct ref with the refs of the parts that were correct when
  // we get gap evaluations back from submitting an answer. This will allow the inpout to be
  // pre-filled with the correct parts when we are on a subsequent attempt.
  useFillFirstChancePartRefsCorrectRef(
    firstChancePartRefsCorrectRef,
    submitAnswerData?.response?.gapEvaluations,
    partRefs,
  );

  // disabledSubmitAnswerAnalytics are the conditions that disable the submit answer button, and
  // are temporarily being sent to bq for debugging purposes
  const disabledSubmitAnswerAnalytics = {
    notSubmitError: !submitError,
    inputValid: inputValid,
    notSubmittingAnswer: !isSubmittingAnswer,
    notSubmittedAnswer: !isAnswerSubmitted,
    packageID: packageID,
    taskIndex: taskIndex,
    taskItemIndex: taskItemIndex,
  };

  const landscapeModal = useLandscapeModal();

  const onScaleChange = (value: number) => {
    if (value < 0.5) {
      landscapeModal.trigger();
    }
  };

  if (!layout || !input) {
    return <QuestionLoading />;
  }
  return (
    <div className={styles.Activity}>
      <AnimatePresence mode="wait" initial={false} custom={page === 'question'}>
        {showCombinedQuestionAnswer ? (
          <QuestionAndAnswer
            activity={activity}
            question={question}
            input={input}
            layout={layout}
            setInput={setInput}
            previousTaskItemPath={previousTaskItemPath}
            isItemOnNonInitialChance={isItemOnNonInitialChance}
            isItemAttempted={isItemAttempted}
            isItemSwapped={isItemSwapped}
            hideBookworkCodes={hideBookworkCodes}
            onScaleChange={onScaleChange}
            currentTaskItem={currentTaskItem}
            submitAnswerResponse={submitAnswerData?.response}
            firstChanceGapEvaluationsRef={firstChanceGapEvaluationsRef}
            placeholderMap={placeHolders}
            partRefs={partRefs}
            isLastItem={isLastItem}
            isTaskComplete={isTaskComplete}
            currentTaskItemPath={currentTaskItemPath}
            nextTaskItemPath={nextTaskItemPath}
            retryTask={retryTask}
            numSwapsUsed={numSwapsUsed}
            submitError={submitError}
            submitAnswer={enableSubmitAnswer ? submitAnswer : undefined}
          />
        ) : page === 'question' ? (
          <Question
            key="question"
            activity={activity}
            question={question}
            input={input}
            layout={layout}
            setInput={setInput}
            goToAnswer={() => setPage('answer')}
            previousTaskItemPath={previousTaskItemPath}
            isItemOnNonInitialChance={isItemOnNonInitialChance}
            isItemAttempted={isItemAttempted}
            isItemSwapped={isItemSwapped}
            hideBookworkCodes={hideBookworkCodes}
            onScaleChange={onScaleChange}
            currentTaskItem={currentTaskItem}
          />
        ) : (
          <Answer
            key="answer"
            activity={activity}
            question={question}
            input={input}
            layout={layout}
            setInput={setInput}
            goToQuestion={() => setPage('question')}
            submitAnswer={enableSubmitAnswer ? submitAnswer : undefined}
            submitAnswerResponse={submitAnswerData?.response}
            submitError={submitError}
            isLastItem={isLastItem}
            isTaskComplete={isTaskComplete}
            currentTaskItemPath={currentTaskItemPath}
            nextTaskItemPath={nextTaskItemPath}
            isItemOnNonInitialChance={isItemOnNonInitialChance}
            retryTask={retryTask}
            hideBookworkCodes={hideBookworkCodes}
            onScaleChange={onScaleChange}
            disabledSubmitAnswerAnalytics={disabledSubmitAnswerAnalytics}
            firstChanceGapEvaluationsRef={firstChanceGapEvaluationsRef}
            numSwapsUsed={numSwapsUsed}
            currentTaskItem={currentTaskItem}
            placeholderMap={placeHolders}
            partRefs={partRefs}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export const getCookie = (cookieName: string) => {
  const beforeCookie = '(?:(?:^|.*;\\s*)';
  const afterCookie = '\\s*\\=\\s*([^;]*).*$)|^.*$';
  return document.cookie.replace(new RegExp(beforeCookie + cookieName + afterCookie), '$1');
};

const CURRENT_COOKIE_PREFERENCES_COOKIE_VERSION = 4;
const COOKIE_PREFERENCES_COOKIE_NAME = 'cookie_preferences';
export const getCookiePreferences = () => {
  const cookie = getCookie(COOKIE_PREFERENCES_COOKIE_NAME);
  if (!cookie) {
    return null;
  }
  const parsedCookie = JSON.parse(cookie);
  if (parsedCookie.version !== CURRENT_COOKIE_PREFERENCES_COOKIE_VERSION) {
    return null;
  }
  return {
    GA: !!parsedCookie.GA,
    Hotjar: !!parsedCookie.Hotjar,
    PT: !!parsedCookie.PT,
  };
};

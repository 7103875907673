import { WhatsNewEntry } from 'components/whats-new/types';
import threeChances from 'images/whats-new/three_chances.png';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FlagValue } from 'utils/feature-flags';
import { makeStaticVideoUrls } from 'utils/urls';

// use whats new entries returns all whats new entries.
// New posts should appear at the top of the list, a lot of logic relies on this.
export const useWhatsNewEntries = () =>
  useMemo<WhatsNewEntry[]>(
    () => [
      {
        title: '🎉 Improvements to XP 🏆',
        body: [
          {
            text: [
              <>
                <strong>Reduced XP levels:</strong> We've reduced the XP levels from 10,000 XP to
                1,000 XP and made it feel more rewarding when you do level-up.
              </>,
              <>
                <strong>Earn bonus XP:</strong> You can earn extra XP for completing your homework,
                having good bookwork accuracy, and more!
              </>,
              <>
                <strong>Updated Rewards page:</strong> You can track how much XP you have, what
                level you are, and how much XP you need to level-up from the updated{' '}
                <Link to="/rewards">Rewards page.</Link>
              </>,
              <>
                <strong>Coming Soon - Leaderboards:</strong> Keep an eye out for the new
                Leaderboards that will let you compare the XP you have earned against other people
                in your class and school.
              </>,
            ],
          },
        ],
        promoted: {
          body: [
            {
              text: [
                <>
                  <strong>Reduced XP levels:</strong> We've reduced the XP levels from 10,000 XP to
                  1,000 XP and made it feel more rewarding when you do level-up.
                </>,
                <>
                  <strong>Earn bonus XP:</strong> You can earn extra XP for completing your
                  homework, having good bookwork accuracy, and more!
                </>,
                <>
                  <strong>Updated Rewards page:</strong> You can track how much XP you have, what
                  level you are, and how much XP you need to level-up from the updated{' '}
                  <Link to="/rewards">Rewards page.</Link>
                </>,
                <>
                  <strong>Coming Soon - Leaderboards:</strong> Keep an eye out for the new
                  Leaderboards that will let you compare the XP you have earned against other people
                  in your class and school.
                </>,
              ],
            },
          ],
        },
        date: '2024-09-02',
        featureFlag: 'new-xp-system',
        notification: {
          id: '2024-09-02xp',
          title: '🎉 Improvements to XP 🏆',
          message: "We've made some changes to XP. Click here to find out more!",
          linkToWhatsNew: true,
        },
      },
      {
        title: 'Swapping tricky questions',
        body: [
          {
            videos: [
              {
                name: 'Swapping tricky questions',
                urls: makeStaticVideoUrls('assets/whats-new/sw2/swaps_introduction'),
              },
            ],
          },
        ],
        promoted: {
          body: [
            {
              videos: [
                {
                  name: 'Swapping tricky questions',
                  urls: makeStaticVideoUrls('assets/whats-new/sw2/swaps_introduction'),
                },
              ],
            },
          ],
        },
        notification: {
          id: '2024-06-06-swaps',
          title: 'Swapping tricky questions',
          message:
            'You can now swap up to three questions that you find tricky for slightly easier questions in your compulsory homework. Click here to find out more!',
          linkToWhatsNew: true,
        },
        date: '2024-06-06',
        featureFlag: 'sparxweb2-question-swaps',
      },
      {
        title: 'Three tries on each question',
        body: [
          {
            images: [
              {
                src: threeChances,
              },
            ],
          },
          {
            text: [
              "We get how frustrating it can be when a question changes just as you're about to solve it.",
              "We’ve made a change, you've now got three tries before we switch it to a different version of the same question.",
              "We've done this to make sure you have an extra chance to check your workings and a fairer chance of solving it.",
              "Don't worry if the question changes after three tries - it's all part of the learning journey! Plus, we'll give you a heads-up when that happens.",
            ],
          },
        ],
        promoted: {
          body: [
            {
              images: [
                {
                  src: threeChances,
                },
              ],
            },
            {
              text: [
                "We get how frustrating it can be when a question changes just as you're about to solve it.",
                "We’ve made a change, you've now got three tries before we switch it to a different version of the same question.",
                "We've done this to make sure you have an extra chance to check your workings and a fairer chance of solving it.",
                "Don't worry if the question changes after three tries - it's all part of the learning journey! Plus, we'll give you a heads-up when that happens.",
              ],
            },
          ],
        },
        notification: {
          id: '2024-04-15-three-tries',
          title: 'NEW: Three tries on each question',
          message:
            'We’ve made a change to give you three tries on each question. Click here to find out more!',
          linkToWhatsNew: true,
        },
        date: '2024-04-15',
        featureFlag: 'sparxweb2-three-attempts',
      },
      {
        title: 'Bookwork check changes',
        subtitle: 'Watch this video to find out what has changed with bookwork checks',
        body: [
          {
            videos: [
              {
                name: 'Bookwork check changes',
                urls: makeStaticVideoUrls('assets/whats-new/sw2/bw-check-changes'),
              },
            ],
          },
        ],
        promoted: {
          body: [
            {
              videos: [
                {
                  name: 'Bookwork check changes',
                  urls: makeStaticVideoUrls('assets/whats-new/sw2/bw-check-changes'),
                },
              ],
            },
          ],
        },
        notification: {
          id: '2023-11-01-bookwork-check-changes',
          message: "Exciting update: We've changed bookwork checks. Click here to find out more!",
          title: 'Bookwork checks have changed 📚',
          linkToWhatsNew: true,
        },
        date: '2023-11-01',
        promotedEndDate: '2024-03-22',
        featureFlag: {
          key: 'sparxweb-wac-method',
          showEntry: (flagValue: FlagValue) =>
            typeof flagValue === 'string' && flagValue === 'ask_answer_any_type_not_incorrect',
        },
      },
    ],
    [],
  );

import { TopicSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { TopicLinkType } from '@sparx/api/apis/sparx/content/v2/curriculum_enums';
import { AssignedTopic } from '@sparx/api/apis/sparx/revision/v1/revision';
import { curriculumNameFromName } from '@sparx/resource-names';
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'components/accordion/Accordion';
import iconArrowLeft from 'images/icon-arrow-left.svg';
import iconArrowRight from 'images/icon-arrow-right.svg';
import { useState } from 'react';
import { useAnalytics } from 'utils/analytics';

import { useGetAssignedTopic } from '../hooks';
import { SubstrandTopic } from '../strand/SubstrandTopic';
import { getLearningPathProgressScore, IProgress } from '../utils';
import styles from './IndependentLearningTopicView.module.css';

interface ITopicLinksProps {
  topic: Topic;
  topicSummariesMap: Map<string, TopicSummary>;
  learningPathProgress: Map<string, IProgress>;
  learningPathSpecName?: string;
  substrandName: string;
}

/**
 * Topic Links for an Independent Learning Topic. Called "Building Blocks" to the student we present
 * topics linked to the chosen one, in the same curriculum, that are either prerequisites or next
 * topics so the student can access them easily
 * @param topic The topic being viewed that we need to find links for
 * @param topicSummariesMap A map of all topic summaries which includes each one's linked topics
 * @param learningPathProgress Progress for each learning path, needed to calculate the progress for
 * each linked topic
 * @param name of the substrand the topic is part of
 */

export const TopicLinks = ({
  topic,
  topicSummariesMap,
  learningPathProgress,
  learningPathSpecName,
  substrandName,
}: ITopicLinksProps) => {
  const sendEvent = useAnalytics();
  const [openLinks, setOpenLinks] = useState<string[]>([]);
  const getAssignedTopic = useGetAssignedTopic();

  /**
   * Handler for when building blocks are expanded or collapsed, including sending a page event
   * @param value
   */
  const onValueChange = (value: string[]) => {
    setOpenLinks(value);

    sendEvent({
      category: 'revision',
      action: 'building blocks click',
      labels: {
        topic: topic.name,
        open: !!value.length,
      },
    });
  };

  const prerequisites: TopicSummary[] = [];
  const nextTopics: TopicSummary[] = [];
  for (const link of topic.topicLinks) {
    if (
      curriculumNameFromName(link.sourceTopicName) !== curriculumNameFromName(link.targetTopicName)
    ) {
      continue;
    }
    const targetTopic = topicSummariesMap.get(link.targetTopicName);
    if (!targetTopic?.topic) {
      continue;
    }
    switch (link.type) {
      case TopicLinkType.PREREQUISITE:
        if (link.sourceTopicName !== topic.name) {
          break;
        }
        prerequisites.push(targetTopic);
        break;
      case TopicLinkType.SUBSEQUENT:
        if (link.sourceTopicName !== topic.name) {
          break;
        }
        nextTopics.push(targetTopic);
        break;
    }
  }

  if (prerequisites.length === 0 && nextTopics.length === 0) {
    return (
      <div className={styles.NoTopicLinksMessage}>
        Sorry, there are no building blocks available for this topic.
      </div>
    );
  }

  return (
    <AccordionRoot value={openLinks} onValueChange={onValueChange}>
      <AccordionItem value="building-blocks">
        <AccordionTrigger className={styles.TopicLinksAccordionTrigger}>
          <div>{openLinks.length ? 'Hide' : 'Show'} building blocks</div>
        </AccordionTrigger>
        <AccordionContent>
          <LinksContainer
            header="Develop skills to help you with this topic"
            icon={iconArrowLeft}
            topicSummaries={prerequisites}
            getAssignedTopic={getAssignedTopic}
            learningPathProgress={learningPathProgress}
            learningPathSpecName={learningPathSpecName}
            substrandName={substrandName}
          />
          <LinksContainer
            header="What can you do next?"
            icon={iconArrowRight}
            topicSummaries={nextTopics}
            getAssignedTopic={getAssignedTopic}
            learningPathProgress={learningPathProgress}
            learningPathSpecName={learningPathSpecName}
            substrandName={substrandName}
          />
        </AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  );
};

interface ILinksContainerProps {
  header: string;
  icon: string;
  topicSummaries: TopicSummary[];
  getAssignedTopic: (topicName: string) => AssignedTopic | undefined;
  learningPathProgress: Map<string, IProgress>;
  learningPathSpecName?: string;
  substrandName: string;
}

/**
 * LinksContainer Wraps a group of TopicLinks, in the form of SubstrandTopic rows.
 * @param header The header for the section
 * @param icon Icon to display next to the header
 * @param topicSummaries The list of topics to display
 * @param getAssignedTopic Function to call for each topic to see if we show the "Seen in homework"
 * chip for that topic
 * @param learningPathProgress Progress for each learning path, needed to calculate the progress for
 * each linked topic
 * @param learningPathSpecName Name of the current Learning Path spec, used to find the current
 * topic path.
 * @param name of the substrand the topic is part of
 */
const LinksContainer = ({
  header,
  icon,
  topicSummaries,
  getAssignedTopic,
  learningPathProgress,
  learningPathSpecName,
  substrandName,
}: ILinksContainerProps) => {
  if (!topicSummaries.length) {
    return null;
  }

  return (
    <div className={styles.TopicLinksSectionContainer}>
      <div>
        <img aria-label="" className={styles.TopicLinksAccordionIcon} src={icon} />
        <span>{header}</span>
      </div>
      <div>
        {topicSummaries.map(topicSummary => {
          // Work out the progress to show on the linked topic, within the currently selected learning path
          const topic = topicSummary.topic;
          const learningPaths = topicSummary.learningPaths;
          const topicPath = learningPaths.find(path => path.specName === learningPathSpecName);
          const progress = topicPath && learningPathProgress.get(topicPath.name);
          const score = progress && getLearningPathProgressScore(progress);
          const topicProgress = score
            ? {
                score,
                learningPath: topicPath,
              }
            : undefined;
          return (
            topic && (
              <SubstrandTopic
                key={topic.name}
                topic={topic}
                seenInHomework={!!getAssignedTopic(topic.name)}
                topicProgress={topicProgress}
                substrandName={substrandName}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

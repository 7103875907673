import { PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { TextWithMaths } from '@sparx/text-with-maths';
import { usePackageTasks } from 'queries/packages';

import { IndependentLearningTask } from './IndependentLearningTask';
import styles from './IndependentLearningTopicView.module.css';

interface IIndependentLearningPackageProps {
  packageCompletion: PackageCompletion;
}

/**
 * IndependentLearningPackage contains a package title and list of tasks within that package.
 * @param packageCompletion Information about the student's completion of the Package, such as
 * number of questions correct, incorrect, etc.
 * @param packageData Information about the package, such as tasks and task items found within it.
 */

export const IndependentLearningPackage = ({
  packageCompletion,
}: IIndependentLearningPackageProps) => {
  const { data: tasks } = usePackageTasks(packageCompletion.packageID);
  if (!tasks) {
    return null;
  }
  return (
    <div className={styles.IndependentLearningPackage}>
      <div className={styles.IndependentLearningPackageTitle}>
        <TextWithMaths text={packageCompletion.title} />
      </div>
      <ol className={styles.IndependentLearningTasks}>
        {tasks.map(task => (
          <IndependentLearningTask key={task.taskIndex} taskCompletion={task} />
        ))}
      </ol>
    </div>
  );
};

import { Completion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Button } from '@sparx/sparx-design/components';
import { Tooltip } from '@sparx/sparx-design/components/tooltip/Tooltip';
import { VideoIcon } from '@sparx/sparx-design/icons';
import { useTutorialSpotlight } from 'context/tutorialspotlight';
import { TutorialStep } from 'context/tutorialspotlight/provider';
import { TutorialKey, useIsTutorialComplete, useMarkTutorialComplete } from 'queries/tutorials';
import { useEffect, useRef } from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { useAnalytics } from 'utils/analytics';
import { getBookworkDialIcon, getBookworkLevel } from 'utils/bookworkAccuracy';

import styles from './BookworkAccuracy.module.css';

const TemplateOverride = (props: TooltipRenderProps) => {
  const { tooltipProps, primaryProps } = props;
  const { mutate: markTutorialComplete } = useMarkTutorialComplete();

  useEffect(() => {
    // once this tooltip is shown, mark it as complete
    markTutorialComplete([TutorialKey.WacAccuracy]);
  }, [markTutorialComplete]);

  return (
    <div ref={tooltipProps.ref} className={styles.JoyrideTooltip}>
      <p>
        Your Bookwork Check accuracy is now shown here. Remember the more checks you get correct,
        the fewer you'll have to do.{' '}
      </p>
      <div>
        <div className={styles.TooltipButtonContainer}>
          <Button
            className={styles.TooltipButton}
            variant="contained"
            onClick={primaryProps.onClick}
          >
            {primaryProps.title}
          </Button>
          <Button
            // can't use a Link here because the tutorial provider is outside the router
            as={'a'}
            // this won't work in dev because the root on prod is /student but in dev it's /
            href={'/student/whatsnew/2023-11-01-bookwork-check-changes'}
            className={styles.TooltipButton}
            variant="plain"
          >
            <VideoIcon /> Watch the video
          </Button>
        </div>
      </div>
    </div>
  );
};

const wacAccuracyTooltip: TutorialStep = {
  tutorialKey: TutorialKey.WacAccuracy,
  content: undefined, //overwritten by templateOverride
  placement: 'top',
  disableOverlayClose: false,
  disableOverlay: false,
};

export const BookworkAccuracy = ({ completion }: { completion?: Completion }) => {
  const tooltipOpenedTime = useRef<number | undefined>(undefined);
  const sendEvent = useAnalytics();
  const bookworkLevel = getBookworkLevel(completion);
  const dialIcon = getBookworkDialIcon(bookworkLevel);

  const wacAccuracyTooltipViewed = useIsTutorialComplete(TutorialKey.WacAccuracy);

  const size = completion?.size || 0;
  const completeCount = completion?.progress['C'] || 0;
  let completionStatus = 'part complete';
  if (completeCount === 0) {
    completionStatus = 'not started';
  } else if (completeCount === size) {
    completionStatus = 'complete';
  }

  const { showTutorials } = useTutorialSpotlight();

  useEffect(() => {
    if (!wacAccuracyTooltipViewed) {
      showTutorials([wacAccuracyTooltip], {
        tooltipComponentOverride: TemplateOverride,
        scrollOffset: 200,
      });
    }
  }, [showTutorials, wacAccuracyTooltipViewed]);

  return (
    <Tooltip
      position="top"
      content="This shows how well you have done on your bookwork checks. Remember that doing well with your bookwork means you will get fewer checks."
      onOpenChange={open => {
        if (open) {
          tooltipOpenedTime.current = new Date().getTime();
          sendEvent({
            action: 'opened package list view bookwork accuracy tooltip',
            category: 'package list',
            labels: {
              bookworkLevel: bookworkLevel,
              homeworkStatus: completionStatus,
            },
          });
        } else {
          sendEvent({
            action: 'closed package list view bookwork accuracy tooltip',
            category: 'package list',
            labels: {
              timeOpen: (new Date().getTime() - (tooltipOpenedTime.current || 0)) / 1000,
            },
          });
          tooltipOpenedTime.current = undefined;
        }
      }}
    >
      <div
        className={styles.BookworkAccuracy}
        data-tutorial-spotlight-key={TutorialKey.WacAccuracy}
      >
        <span className={styles.BookworkAccuracyText}>Bookwork</span>
        <img className={styles.BookworkAccuracyIcon} src={dialIcon} width={40} />
      </div>
    </Tooltip>
  );
};
